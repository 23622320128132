const getResponse = ({
  can = true,
  msg = '',
  keyStep = undefined,
  numberStep = undefined
}) => ({
  can,
  msg,
  keyStep: keyStep || undefined,
  numberStep: numberStep || undefined
})

// const solidarityHealthFundStepToValidate = [
//   {
//     keyStep: 'SIGN-PURCHASE-ORDER-SOLIDARITY-HEALTH-FUND',
//     msg: 'Una vez este completo el paso de "Orden de compra firmada" no es posible editar datos posteriores al paso.'
//   }
// ]

const canEdit = {
  HospitalAccommodation: null,
  SolidarityHealthFund: null,
  InjuredInLinesOfServiceKit: null,
  FireSupport: null,
  DisasterSupport: null,
  SupportInCaseOfDeathOfMember: null,
  NewbornBenefit: null,
  PreUniversityScholarships: null,
  WorkshopsAndTraining: null,
  TechnicalProfessionalScholarships: null,
  ScholarshipsApplicantOfficer: null,
  ScholarshipsApplicantOfficerDac: null,
  UniversityScholarships: null
}

// eslint-disable-next-line import/prefer-default-export
export const getCanEdit = ({ steps = [], typeProgram = null }) => {
  const arrStepToValidate = canEdit[typeProgram] || []
  let response = getResponse({ can: true, msg: '' })
  let shouldBreak = false
  arrStepToValidate?.forEach(({ keyStep, msg }) => {
    if (shouldBreak) return
    const reversedSteps = steps.slice().reverse()
    const find = reversedSteps?.find((step) => step?.key === keyStep)

    if (find) {
      response = getResponse({
        can: !find?.complete,
        numberStep: find.step,
        keyStep,
        msg
      })
    }

    if (!response.can) {
      shouldBreak = true
    }
  })

  return response
}
