import axios from '../Axios'

const module = '/users'

export const getUsers = async (filtersUsers, page = 0, limit = 6) => {
  const { search, startDate, endDate, rol } = filtersUsers
  const { data } = await axios.get(
    `${module}/?search=${search}&page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&rol=${rol}`
  )
  return data
}

export const getUsersById = async (userId) => {
  const { data } = await axios.get(`${module}/${userId}`)
  return data
}

export const createNewUser = async (formData) => {
  const { data } = await axios.post(`${module}/`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
  return data
}

export const updateUser = async ({ userId, formData }) => {
  const { data } = await axios.patch(`${module}/${userId}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
  return data
}

export const deleteUser = async (userId) => {
  const { data } = await axios.delete(`${module}/${userId}`)
  return data
}
