import { createIcon } from '@chakra-ui/react'
/* https://freeicons.io/ui-essentials-3/ui-essentials-notification-alarm-bell-icon-44563 */
/* eslint-disable import/prefer-default-export */
const ViewIcon = createIcon({
  displayName: 'ViewIcon',
  viewBox: '0 0 32 32',
  path: [
    <path
      class="st0"
      d="M29,16c0,0-5.8,8-13,8S3,16,3,16s5.8-8,13-8S29,16,29,16z"
      fill="#fff"
    />,
    <circle class="st0" cx="16" cy="16" r="4" fill="#82b378" />
  ]
})

export default ViewIcon
