import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { Grid, GridItem, useToast, Flex } from '@chakra-ui/react'
import {
  UploadFile,
  CustomToast,
  BarFileName,
  Button,
  InputComtrol,
  Select
} from '../UI'
import { uploadSchema } from './Schema'
import { useMutatePartner } from '../../hooks/Partners'
import { GetFormData, Files, GetNotification, Months, Years } from '../../Utils'
import DownloadTemplateFile from './DownloadTemplateFile'

const FormUpload = ({ refetch }) => {
  const [monthsList, setMonthsList] = useState([])
  const [yearsList, setYearsList] = useState([])
  const toast = useToast()
  const { mutate, isLoading, reset } = useMutatePartner('UPLOAD-MASIVE')

  const initialValues = {
    month: '',
    year: '',
    uploadFileNames: [],
    uploadFile: null
  }

  const formik = useFormik({
    initialValues,
    validationSchema: uploadSchema,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    onSubmit: (values) => {
      const formData = GetFormData(values, ['uploadFile'], ['uploadFileNames'])

      const test = true
      if (test) {
        mutate(formData, {
          onSuccess: (response) => {
            refetch()
            reset()
            formik.resetForm()
            GetNotification.basic(
              response?.success?.message ||
                'El archivo fue cargado, será notificado una vez el proceso haya culminado',
              'Aceptar'
            )
          },
          onError: (err) => {
            const response = JSON.parse(err.request.response)
            GetNotification.basic(
              'Error',
              'Aceptar',
              response?.error?.message || 'Error al Cargar archivo',
              'error'
            )
          }
        })
      }
    }
  })

  const deleteFile = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles: resultFiles } =
      getArrayNamesAndArrayFiles(
        fileName,
        formik.values.uploadFileNames,
        formik.values.uploadFile
      )

    formik.setValues({
      ...formik.values,
      uploadFileNames: resultNames,
      uploadFile: resultFiles
    })
  }

  const handleChangeFile = (e) => {
    if (!e.currentTarget.files) {
      return
    }
    const { getListNamesAndListFiles } = Files

    const { newListNames: newFilesNames, newListFiles: newFiles } =
      getListNamesAndListFiles(e.currentTarget, [], [])

    formik.setValues({
      ...formik.values,
      uploadFileNames: newFilesNames,
      uploadFile: newFiles
    })
    e.target.value = null
  }

  useEffect(() => {
    if (formik.isSubmitting && Object.keys(formik.errors).length > 0) {
      Object.keys(formik.errors).forEach((key) => {
        toast({
          duration: 2000,
          isClosable: true,
          position: 'top-right',
          render: ({ onClose }) => (
            <CustomToast
              text={formik.errors[key]}
              showSpinner={false}
              status={'error'}
              onClose={onClose}
            />
          )
        })
      })
    }
  }, [formik.errors])

  useEffect(() => {
    const all_months = ['']
    Months.getArrayNamesMonths().forEach((month) => {
      all_months.push(month)
    })
    setMonthsList(all_months)

    const all_years = ['']
    Years.getYears(2021).forEach((year) => {
      all_years.push(year.toString())
    })
    setYearsList(all_years)
  }, [])

  const handleChangeYear = (value) => {
    formik.setFieldValue('year', value)
  }

  const handleChangeMonth = (value) => {
    formik.setFieldValue('month', value)
  }

  return (
    <Grid
      templateColumns={{
        lg: 'repeat(3, 1fr)',
        md: 'repeat(3, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={10}
    >
      <GridItem>
        <InputComtrol
          isRequired={true}
          id={'year'}
          label={'Año'}
          error={Boolean(formik.errors.year)}
          errorText={formik.errors.year}
        >
          <Select
            bg={'white'}
            list={yearsList}
            value={formik.values.year}
            onChange={handleChangeYear}
          />
        </InputComtrol>
        <InputComtrol
          id={'month'}
          label={'Mes'}
          error={Boolean(formik.errors.month)}
          errorText={formik.errors.month}
        >
          <Select
            bg={'white'}
            list={monthsList}
            value={formik.values.month}
            onChange={handleChangeMonth}
          />
        </InputComtrol>
      </GridItem>
      <GridItem color={'green.500'}>
        <UploadFile
          id={'uploadFileNames'}
          p={5}
          onChange={(e) => handleChangeFile(e)}
          error={Boolean(formik.errors.uploadFileNames)}
          errorText={formik.errors.uploadFileNames}
          multiple={false}
        />
      </GridItem>
      <GridItem>
        <Flex direction={'column'} gap={1} my={2}>
          {formik?.values?.uploadFileNames?.map((item) => (
            <BarFileName
              key={item?.name}
              fileName={item?.name}
              fileUrl={item?.url}
              onDelete={() => deleteFile(item?.name)}
            />
          ))}
        </Flex>
        <Flex direction={'column'} gap={2}>
          <Button
            text={'Cargar archivo'}
            px={10}
            onClick={formik.handleSubmit}
            isLoading={isLoading}
            disabled={isLoading}
          />
          <DownloadTemplateFile />
          <DownloadTemplateFile otherYear={true} selectedYear={2021} />
        </Flex>
      </GridItem>
    </Grid>
  )
}

export default FormUpload
