/* eslint-disable import/prefer-default-export */
import { useMutation, useQueryClient } from 'react-query'

import {
  createNewProgram,
  updateProgram
} from '../../api/programs/solidarityHealthFund'

const key = 'solidarityHealthFund'

const getAction = (type) => {
  if (type === 'CREATE') return createNewProgram
  if (type === 'UPDATE') return updateProgram
  return null
}

export function useMutateProgram(type) {
  const queryClient = useQueryClient()
  const actionFn = getAction(type)
  return useMutation(actionFn, {
    onSuccess: () => {
      queryClient.invalidateQueries([key])
    }
  })
}
