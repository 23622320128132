import Layout from '../components/Layout/Layout'
import Reportability from '../pages/Reportability'
/* --- */
import Can from '../auth/Can'
import Forbidden from '../pages/Forbidden'
import Login from '../pages/Login'

const rootName = 'reportability'

const reportabilityRoutes = [
  {
    path: `/${rootName}`,
    key: 'REPORTABILITY',
    exact: true,
    component: ({ authenticated }) => (
      <Can
        availableTo={[
          'ADMINISTRADOR',
          'DEPTO_CONTROL_GESTION_SOCIAL',
          'GERENCIA'
        ]}
        yes={() => (
          <Layout>
            <Reportability />
          </Layout>
        )}
        no={() => (authenticated ? <Forbidden /> : <Login />)}
      />
    )
  }
]
export default reportabilityRoutes
