import * as Yup from 'yup'
/* eslint-disable import/prefer-default-export */
export const steepOneSchema = Yup.object().shape({
  topic: Yup.string().required('El tema es requerido'),
  startDate: Yup.string().required('La fecha de inicio es requerida'),
  endDate: Yup.string().required('La fecha de finalización es requerida'),
  description: Yup.string().required('La descripción es requerida'),
  modality: Yup.string().required('La modalidad es requerida'),
  schedules: Yup.array()
    .min(1, 'Debe seleccionar al menos un horario')
    .required('Debe seleccionar al menos un horario'),
  zone: Yup.string()
  // prefecture: Yup.string().required('La prefectura es requerida'),
  // unit: Yup.string().required('La unidad es requerida')
})

export const steepTwoSchema = Yup.object().shape({
  fullNameMonitor: Yup.string()
    .max(40, 'El nombre no debe exceder los 40 caracteres')
    .required('El nombre del monitor es requerido'),
  rutMonitor: Yup.string()
    .min(11, 'El Rut no es valido')
    .max(12, 'El Rut no es valido')
    .required('El Rut del monitor es requerido'),
  birthDateMonitor: Yup.string().required(
    'La fecha de nacimiento del monitor es requerida'
  ),
  addressMonitor: Yup.string().required(
    'La dirección del monitor es requerida'
  ),
  phonePrimaryMonitor: Yup.string()
    .min(8, 'El telefono no es valido')
    .max(9, 'El telefono no es valido')
    .required('El teléfono principal del monitor es requerido'),
  phoneSecondaryMonitor: Yup.string()
    .min(8, 'El telefono no es valido')
    .max(9, 'El telefono no es valido'),
  emailMonitor: Yup.string().required('El email del monitor es requerido'),
  certificateMonitorName: Yup.array()
    .min(1, 'Debe ingresar al menos 1 documento')
    .required('El documento de respaldo es requerido'),
  /* -bank data- */
  isRequiredAccountNumber: Yup.boolean(),
  isFreeProgram: Yup.boolean(),
  accountNumber: Yup.string()
    // .when('isRequiredAccountNumber', {
    //   is: true,
    //   then: Yup.string().required('El número de cuenta es requerido')
    // })
    .when('isFreeProgram', {
      is: true,
      then: Yup.string()
    }),
  accountBackupName: Yup.array(),
  typeAccount: Yup.string()
    .required('El tipo de cuenta es requerido')
    .when('isFreeProgram', {
      is: true,
      then: Yup.string()
    }),
  specificAccount: Yup.boolean(),
  typeAccountOther: Yup.string().when('specificAccount', {
    is: true,
    then: Yup.string().required('El tipo de cuenta es requerido')
  }),
  bank: Yup.string().required('El banco es requerido').when('isFreeProgram', {
    is: true,
    then: Yup.string()
  }),
  specificBank: Yup.boolean(),
  bankOther: Yup.string().when('specificBank', {
    is: true,
    then: Yup.string().required('El banco es requerido')
  })
})
