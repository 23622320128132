import React from 'react'
import AlterProcess from './AlterProcess'
import ButtonOptions from './ButtonOptions'
import { CondicionalModal } from '../../../../Utils'

const RenderBottonsOptions = ({
  formik,
  buttonsProps,
  buttonsPropsExtra,
  currentStep,
  program
}) => {
  const { requiredProposedState, canChangeState } = CondicionalModal
  const selectedEdit = () => {
    formik.setValues({
      ...formik.values,
      paymentVerification: formik.values.isRequiredPayment ? 'EDIT' : '',
      validationOffice: formik.values.isRequiredValidationOffice ? 'EDIT' : '',
      officeStatus: formik.values.isRequiredOfficeStatus ? 'EDIT' : '',
      verificationOfDocuments: formik.values.isRequiredVerificationOfDocuments
        ? 'EDIT'
        : '',
      purchaseOrderVerification: formik.values
        .isRequiredPurchaseOrderVerification
        ? 'EDIT'
        : ''
    })
  }

  const selectedApproved = () => {
    formik.setValues({
      ...formik.values,
      paymentVerification: formik.values.isRequiredPayment ? 'APPROVED' : '',
      validationOffice: formik.values.isRequiredValidationOffice
        ? 'APPROVED'
        : '',
      officeStatus: formik.values.isRequiredOfficeStatus ? 'APPROVED' : '',
      verificationOfDocuments: formik.values.isRequiredVerificationOfDocuments
        ? 'APPROVED'
        : '',
      purchaseOrderVerification: formik.values
        .isRequiredPurchaseOrderVerification
        ? 'APPROVED'
        : ''
    })
  }

  const selectedApprovedState = () => {
    formik.setValues({
      ...formik.values,
      proposedState: requiredProposedState.includes(currentStep?.key)
        ? 'APPROVED'
        : '',
      state: canChangeState.includes(currentStep?.key)
        ? 'APPROVED'
        : formik.values.state
    })
  }
  const selectedRejectedState = () => {
    formik.setValues({
      ...formik.values,
      proposedState: requiredProposedState.includes(currentStep?.key)
        ? 'REJECTED'
        : '',
      state: canChangeState.includes(currentStep?.key)
        ? 'REJECTED'
        : formik.values.state
    })
  }

  const selectedApprovedPrimaryState = () => {
    if (currentStep?.key === 'RESIGNATION-OR-LOSS-AUTHORIZATION-RESIGNATION') {
      const validateFinalState = () => {
        const { completedSemesters, state } =
          formik.values /* validation for thecnical professional program */

        if (program.history.state === 'DESISTED') {
          return 'DESISTED'
        }

        if (
          completedSemesters > 0 &&
          canChangeState.includes(currentStep?.key)
        ) {
          return 'REVOKED'
        }

        if (
          completedSemesters === 0 &&
          canChangeState.includes(currentStep?.key)
        ) {
          return 'REJECTED'
        }

        return state
      }

      formik.setValues({
        ...formik.values,
        state: validateFinalState()
      })
      return
    }

    formik.setValues({
      ...formik.values,
      state: canChangeState.includes(currentStep?.key)
        ? 'APPROVED'
        : formik.values.state
    })
  }

  const selectedRejectedPrimaryState = () => {
    if (currentStep?.key === 'RESIGNATION-OR-LOSS-AUTHORIZATION-RESIGNATION') {
      formik.setValues({
        ...formik.values,
        state: canChangeState.includes(currentStep?.key)
          ? program?.state
          : program?.state
      })
      return
    }

    const validateFinalState = () => {
      const { completedSemesters, state } =
        formik.values /* validation for thecnical professional program */

      if (completedSemesters > 0 && canChangeState.includes(currentStep?.key)) {
        return 'REVOKED'
      }

      if (
        completedSemesters === 0 &&
        canChangeState.includes(currentStep?.key)
      ) {
        return 'REJECTED'
      }

      return state
    }

    formik.setValues({
      ...formik.values,
      state: validateFinalState()
    })
  }

  const selectedNotAuthorized = () => {
    formik.setValues({
      ...formik.values,
      orderAuthorization: 'NOT-AUTHORIZED',
      isReuiredAlterProcess: true
    })
  }
  const selectedAutorized = () => {
    formik.setValues({
      ...formik.values,
      orderAuthorization: 'AUTORIZED',
      isReuiredAlterProcess: false
    })
  }
  const selectedEditProcess = () => {
    formik.setValues({
      ...formik.values,
      alterProcess: 'EDIT',
      orderAuthorization: 'EDIT',
      proposedState: 'EDIT'
    })
  }
  const selectedBackProcess = () => {
    formik.setValues({
      ...formik.values,
      alterProcess: 'BACK',
      state: program?.state
    })
  }
  const selectedFinishProcess = () => {
    formik.setValues({
      ...formik.values,
      alterProcess: 'FINISH',
      state:
        formik.values?.completedSemesters > 0
          ? 'REVOKED'
          : 'REJECTED' /* completedSemesters, field only in Technical professsional */
    })
  }

  const selectedFinish = () => {
    formik.setValues({
      ...formik.values,
      responseQuestionNewSemesters: 'FINALIZED'
    })
  }

  const selectedAddNewSemester = () => {
    formik.setValues({
      ...formik.values,
      responseQuestionNewSemesters: 'ADD-NEW-SEMESTERS'
    })
  }

  return (
    <>
      {formik.values.isRequiredQuestionNewSemesters && (
        <ButtonOptions
          onClickLeft={selectedFinish}
          onClickRight={selectedAddNewSemester}
          value={formik.values.responseQuestionNewSemesters}
          error={formik.errors.responseQuestionNewSemesters}
          buttonsProps={buttonsProps}
        />
      )}
      {formik.values.isRequiredPurchaseOrderVerification && (
        <ButtonOptions
          onClickLeft={selectedEdit}
          onClickRight={selectedApproved}
          value={formik.values.purchaseOrderVerification}
          error={formik.errors.purchaseOrderVerification}
          buttonsProps={buttonsProps}
        />
      )}
      {formik.values.isRequiredPayment && (
        <ButtonOptions
          onClickLeft={selectedEdit}
          onClickRight={selectedApproved}
          value={formik.values.paymentVerification}
          error={formik.errors.paymentVerification}
          buttonsProps={buttonsProps}
        />
      )}
      {formik.values.isRequiredValidationOffice && (
        <ButtonOptions
          onClickLeft={selectedEdit}
          onClickRight={selectedApproved}
          value={formik.values.validationOffice}
          error={formik.errors.validationOffice}
          buttonsProps={buttonsProps}
        />
      )}
      {formik.values.isRequiredVerificationOfDocuments && (
        <ButtonOptions
          onClickLeft={selectedEdit}
          onClickRight={selectedApproved}
          value={formik.values.verificationOfDocuments}
          error={formik.errors.verificationOfDocuments}
          buttonsProps={buttonsProps}
        />
      )}
      {formik.values.isRequiredProposedState && (
        <ButtonOptions
          onClickLeft={selectedRejectedState}
          onClickRight={selectedApprovedState}
          onClickExtraButton={selectedEditProcess}
          value={formik.values.proposedState}
          error={formik.errors.proposedState}
          buttonsProps={buttonsProps}
        />
      )}
      {formik.values.canChangeState && (
        <ButtonOptions
          onClickLeft={selectedRejectedPrimaryState}
          onClickRight={selectedApprovedPrimaryState}
          value={formik.values.state}
          error={formik.errors.state}
          buttonsProps={buttonsProps}
        />
      )}
      {formik.values.isRequiredOfficeStatus && (
        <ButtonOptions
          onClickLeft={selectedEdit}
          onClickRight={selectedApproved}
          value={formik.values.officeStatus}
          error={formik.errors.officeStatus}
          buttonsProps={buttonsProps}
        />
      )}
      {formik.values.isRequiredAuthorization && (
        <>
          {formik.values.orderAuthorization !== 'NOT-AUTHORIZED' ? (
            <ButtonOptions
              onClickLeft={selectedNotAuthorized}
              onClickRight={selectedAutorized}
              onClickExtraButton={selectedEditProcess}
              value={formik.values.orderAuthorization}
              error={formik.errors.orderAuthorization}
              buttonsProps={buttonsProps}
            />
          ) : (
            <AlterProcess
              currentState={formik.values.orderAuthorization}
              onClickLeft={selectedBackProcess}
              onClickRight={selectedFinishProcess}
              value={formik.values.alterProcess}
              error={formik.errors.alterProcess}
              buttonsPropsExtra={buttonsPropsExtra}
            />
          )}
        </>
      )}
    </>
  )
}

export default RenderBottonsOptions
