import * as Yup from 'yup'
/* eslint-disable import/prefer-default-export */
export const schema = Yup.object().shape({
  isExtension: Yup.boolean(),
  isExit: Yup.boolean(),
  isRequiredExtensionDays: Yup.boolean(),
  extensionDays: Yup.string().when('isRequiredExtensionDays', {
    is: true,
    then: Yup.string().required('Este campo es requerido')
  }),
  totalDays: Yup.number().when('isExit', {
    is: true,
    then: Yup.number().required('Este campo es requerido')
  }),
  newExitDate: Yup.string().required('El campo es requerido'),
  isRequiredDescription: Yup.boolean(),
  descriptions: Yup.string().when('isRequiredDescription', {
    is: true,
    then: Yup.string().required('El campo es requerido')
  }),
  isRequiredDocuments: Yup.boolean(),
  documentsNames: Yup.array().when('isRequiredDocuments', {
    is: true,
    then: Yup.array().required('El campo es requerido')
  })
})
