import React from 'react'
import { Box, Flex, Icon, Text, Tooltip } from '@chakra-ui/react'
import { RejectedIcon } from '../Icons'
import Roles from '../../resources/Roles'

const CardNotAllowed = ({ currentStep, ...props }) => (
  <Tooltip
    hasArrow
    label={`Paso no permitido: ${currentStep?.title}`}
    bg={'red.500'}
    placement={'start-start'}
  >
    <Flex
      bg={
        'linear-gradient(215deg, rgba(0,238,179,0.09877454399728647) 0%, rgba(255,255,255,1) 100%)'
      }
      direction={'column'}
      align={'center'}
      justify={'center'}
      borderRadius={8}
      gap={2}
      py={8}
      px={4}
      my={3}
      mx={10}
      h={'250px'}
      minW={{
        md: '250px'
      }}
      {...props}
    >
      <Box>
        <Icon as={RejectedIcon} color={'red.500'} fontSize={'4xl'} />
        <Text fontWeight={700} color={'red.500'} fontSize={'lg'}>
          Acción no permitida
        </Text>
        <Text fontWeight={700} color={'blackAlpha.900'} fontSize={'sm'}>
          Esta acción sólo puede ser ejecutada por:
        </Text>
      </Box>
      <Box>
        {currentStep?.permissions?.map((item) => (
          <Text fontWeight={700} color={'blackAlpha.900'} fontSize={'sm'}>
            <b>◦ {Roles.find((rol) => rol.key === item).name}</b>
          </Text>
        ))}
      </Box>
    </Flex>
  </Tooltip>
)

export default CardNotAllowed
