import React, { useState } from 'react'
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Flex,
  Text,
  Tooltip
} from '@chakra-ui/react'
import Button from './Button'

const MenuSelect = ({
  familyMemberList,
  label,
  onClick,
  onClickAddMember = null,
  error = null
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const hadleAction = () => {
    if (onClickAddMember) {
      onClickAddMember()
    }
    onClick('ADD_FAMILY_MEMBER')
  }

  return (
    <Menu isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <MenuButton w={'100%'} onClick={() => setIsOpen(!isOpen)}>
        <Flex
          direction={'column'}
          textAlign={'left'}
          border={!error ? 'solid 1px lightGrey' : 'solid 1px red'}
          borderRadius={5}
          minH={'40px'}
          justify={'center'}
          px={2}
        >
          <Text fontWeight={700} color={'green.500'} fontSize={'md'}>
            {label?.label}
          </Text>
          {label?.subLabel && (
            <Text fontWeight={700} fontSize={'xs'}>
              {label?.subLabel}
            </Text>
          )}
        </Flex>
      </MenuButton>
      <Tooltip
        label={'Haz Click en un familiar o en el Botón "Agregar familiar"'}
        hasArrow
        placement={'left-end'}
        bg={'green.500'}
        isOpen={isOpen}
      >
        <MenuList color={'green.500'} px={1} overflowY={'scroll'} maxH={300}>
          {familyMemberList?.map((item) => (
            <MenuItem
              key={item?._id}
              _hover={{ bg: 'green.50', borderRadius: 10 }}
              border={'solid 1px #2e7423'}
              borderRadius={10}
              my={1}
              onClick={() => onClick(item)}
            >
              <Flex direction={'column'} textAlign={'left'}>
                <Text fontWeight={700} color={'green.500'} fontSize={'md'}>
                  {item?.fullName || 'Sin nombre asignado'}
                </Text>
                <Text fontWeight={700} fontSize={'xs'}>
                  {item?.relationship || 'Sin relación asignada'}
                </Text>
              </Flex>
            </MenuItem>
          ))}
          <MenuItem>
            <Button
              text={'Agregar familiar'}
              variant={'outline'}
              bg={'white'}
              bghover={'beige.100'}
              color={'green.100'}
              borderColor={'green.500'}
              py={2}
              mx={0}
              w={'100%'}
              onClick={hadleAction}
            />
          </MenuItem>
        </MenuList>
      </Tooltip>
    </Menu>
  )
}

export default MenuSelect
