import * as Yup from 'yup'
/* eslint-disable import/prefer-default-export */
export const steepOneSchema = Yup.object().shape({
  receptionDate: Yup.date().required('La fecha de recepción es requerida'),
  isPartnerZone: Yup.boolean(),
  zone: Yup.string().required('La zona es requerida'),
  prefecture: Yup.string().required('La prefectura es requerida'),
  unit: Yup.string().required('La unidad es requerida'),
  dateOfDisaster: Yup.date().required('La fecha de la catástrofe es requerida'),
  damagedProperty: Yup.string().required('La propiedad dañada es requerida'),
  civilStatus: Yup.string().required('El estado civil es requerido'),
  numberChildren: Yup.string().required('El número de hijos es requerido'),
  alternativePhone: Yup.string()
    .min(8, 'El número de teléfono alternativo debe tener 8 dígitos')
    .max(9, 'El número de teléfono alternativo debe tener 9 dígitos'),
  /* partner data */
  partnerRut: Yup.string()
    .min(11, 'El Rut no es valido')
    .max(12, 'El Rut no es valido'),
  partnerPhone: Yup.string()
    .min(8, 'El telefono no es valido')
    .max(9, 'El telefono no es valido')
    .required('El telefono es requerido'),
  partnerGrade: Yup.string(),
  partnerCode: Yup.string()
    .min(3, 'El código no puede tener menos de 3 caracteres')
    .max(8, 'El código no puede tener más de 8 caracteres')
    .test(
      'last-caracter',
      'El ultimo caracter del código debe ser una letra',
      (value) => {
        if (!value) {
          return true
        }
        const lastChar = value?.slice(-1)
        return /^[a-zA-Z]+$/.test(lastChar)
      }
    )
    .test(
      'start-code',
      'Los primeros caracteres antes del guión del código deben ser números',
      (value) => {
        if (!value) {
          return true
        }
        const valueArray = value.split('-')
        const lengthFirstChar = valueArray[0].length
        const firstChar = value.slice(0, lengthFirstChar)
        return /^[0-9]+$/.test(firstChar)
      }
    ),
  partnerEndowment: Yup.string()
})

export const steepTwoSchema = Yup.object().shape({
  isPartnerAffected: Yup.boolean(),
  fullName: Yup.string().when('isPartnerAffected', {
    is: false,
    then: Yup.string()
      .max(40, 'El nombre no puede tener más de 40 caracteres')
      .required('El nombre completo es requerido')
  }),

  relationship: Yup.string().when('isPartnerAffected', {
    is: false,
    then: Yup.string().required('El parentesco es requerido')
  }),
  phoneDirectMember: Yup.string().when('isPartnerAffected', {
    is: false,
    then: Yup.string()
      .min(8, 'El telefono no es valido')
      .max(9, 'El telefono no es valido')
      .required('El teléfono directo es requerido')
  }),
  description: Yup.string().required('La descripción es requerida')
})

export const steepThreeSchema = Yup.object().shape({
  policePartName: Yup.array()
    .min(1, 'Se requieren al menos un archivo')
    .required('La parte policial es requerido'),
  lastSalarySettlementName: Yup.array()
    .min(1, 'Se requieren al menos un archivo')
    .required('La Última liquidación es requerido'),
  unitCertificateName: Yup.array()
    .min(1, 'Se requieren al menos un archivo')
    .required('El certificado de la unidad es requerido'),
  objectListName: Yup.array()
    .min(1, 'Se requieren al menos un archivo')
    .required('La lista de objetos es requerida'),
  evidencePhotosName: Yup.array()
    .min(1, 'Se requieren al menos un archivo')
    .required('Las Fotos son requeridas'),
  minutaName: Yup.array()
    .min(1, 'Se requieren al Menos 1 Documento')
    .required('La minuta es requerida'),
  observations: Yup.string()
})
