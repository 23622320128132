import { React } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Tr,
  Td,
  Icon,
  Grid,
  GridItem,
  Flex,
  Avatar,
  Tooltip,
  Text
} from '@chakra-ui/react'
import { Table, Spinner, Alert } from '../UI'
import { RedirectIcon } from '../Icons'
import { FormatDate } from '../../Utils'
import GetStatusCard from '../../Utils/GetStatusCard'
import OptionsMenu from './OptionsMenu'
import { usePrograms } from '../../hooks/Programs'
import WithoutResults from '../WithoutResults'
import { updatePage } from '../../store/slices/programs'

const TablePrograms = () => {
  const { user, isLoggedIn } = useSelector((state) => state.auth)
  const { filters, page } = useSelector((state) => state.programs)
  const history = useHistory()
  const dispatch = useDispatch()
  const limit = 6
  const {
    data: programs,
    isLoading,
    isFetching,
    isError
  } = usePrograms(
    {
      ...filters,
      type: isLoggedIn ? JSON.stringify(user.userType) : null
    },
    page,
    limit
  )

  const headers = [
    '#',
    'fecha de solicitud',
    'programas',
    'nombre socio',
    'nombre becado',
    'código de funcionario',
    'estado de solicitud',
    'ver solicitud',
    'Opciones'
  ]

  const toProgramDetail = (program) => {
    history.push(`/programs/view/${program._id}/${program.type}`)
  }

  const validateFullName = (name) => {
    if (!name) {
      return `No Aplica`
    }
    return name
  }

  const validateHasIncompleteSteps = (historyProgram, state) => {
    let hasIncompleteSteps = []
    if (historyProgram?.length > 0) {
      hasIncompleteSteps = historyProgram[0]?.filter(
        (step) => step?.incompleteStep === true
      )
    }
    if (hasIncompleteSteps.length > 0) {
      return (
        <Flex position={'relative'} display={'block'}>
          {GetStatusCard.programTable(state)}
          <Tooltip
            hasArrow
            label={'Tienes Pasos Incompletos'}
            bg={'red.500'}
            fontSize={'md'}
          >
            <Avatar
              position={'absolute'}
              top={'-5px'}
              right={'-5px'}
              bg={'red.500'}
              borderRadius={'50%'}
              size={'xs'}
              name={'!'}
            />
          </Tooltip>
        </Flex>
      )
    }
    return GetStatusCard.programTable(state)
  }

  const setPage = (pageIndex) => {
    dispatch(updatePage(pageIndex))
  }

  return (
    <>
      {programs?.currentPages?.length === 0 && (
        <WithoutResults type={'PROGRAMS-TABLE'} />
      )}
      {programs?.currentPages?.length > 0 && (
        <Grid templateColumns={'repeat(1, 1fr)'} w={'100%'}>
          <Flex w={'100%'} justify={'flex-end'} py={3}>
            <Spinner size={'lg'} show={isLoading || isFetching} />
          </Flex>
          {isError && (
            <Flex w={'100%'} justify={'flex-end'} py={3}>
              <Alert status={'error'} message={'Error al Cargar los Datos'} />
            </Flex>
          )}
          <GridItem
            overflowX={'auto'}
            display={{
              lg: 'table',
              md: 'break-word',
              sm: 'break-word'
            }}
            css={{
              '&::-webkit-scrollbar': {
                width: '4px'
              },
              '&::-webkit-scrollbar-track': {
                width: '4px'
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#2E7423',
                borderRadius: '30px'
              }
            }}
          >
            <Table
              headers={headers}
              totalRecords={programs?.totalRecords}
              setPage={setPage}
              page={page}
            >
              {programs?.currentPages?.map((program, index) => (
                <Tr key={program._id}>
                  <Td fontWeight={700} color={'grey.500'} textAlign={'center'}>
                    {index + 1 + page * limit}
                  </Td>
                  <Td color={'grey.500'}>
                    {FormatDate(program?.createdAt, 'short')}
                  </Td>
                  <Td fontWeight={700} color={'grey.500'} textAlign={'center'}>
                    {program?.topic ? (
                      <Tooltip
                        label={`Taller: ${program?.topic} - ${program?.programName}`}
                        hasArrow
                        bg={'green.500'}
                      >
                        <Text noOfLines={1}>
                          Taller: {program?.topic} - {program?.programName}
                        </Text>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        label={`${program?.programName}`}
                        hasArrow
                        bg={'green.500'}
                      >
                        <Text>{program?.programName}</Text>
                      </Tooltip>
                    )}
                  </Td>
                  <Td color={'grey.500'}>
                    {validateFullName(program?.partner?.name)}
                  </Td>
                  <Td color={'grey.500'}>
                    {program?.postulantName || 'No Aplica'}
                  </Td>
                  <Td color={'grey.500'}>
                    {program?.partner?.code || 'No Aplica'}
                  </Td>
                  <Td>
                    {validateHasIncompleteSteps(
                      program?.history,
                      program?.state
                    )}
                  </Td>
                  <Td textAlign={'center'}>
                    <Icon
                      fontSize="26"
                      as={RedirectIcon}
                      _hover={{ cursor: 'pointer' }}
                      onClick={() => toProgramDetail(program)}
                    />
                  </Td>
                  <Td textAlign={'center'}>
                    <OptionsMenu program={program} />
                  </Td>
                </Tr>
              ))}
            </Table>
          </GridItem>
        </Grid>
      )}
    </>
  )
}

export default TablePrograms
