import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { Flex, Icon } from '@chakra-ui/react'

import {
  DashboardIcon,
  PartnersIcon,
  ProgramsIcon,
  UsersIcon,
  ReportabilityIcon,
  NotificationsIcon
} from './MenuIcons'

const linkItems = [
  {
    key: 'menu_item_dashboard',
    to: '/dashboard',
    title: 'Dashboard',
    icon: DashboardIcon,
    availableTo: [
      'ADMINISTRADOR',
      'ABASTECIMIENTO',
      'ASISTENTE_SOCIAL',
      'ASESOR_JURIDICO',
      'GERENCIA',
      'PRESIDENCIA',
      'FINANZAS',
      'DEPTO_CONTROL_GESTION_SOCIAL',
      'ADMINISTRATIVE-MULTIPLE-PROGRAMS',
      'ADMINISTRATIVE-HOSPITAL-ACCOMMODATION',
      'ADMINISTRATIVE-INUJRED-IN-LINES-OF-SERVICE-KIT',
      'ADMINISTRATIVE-WORKSHOPS-AND-TRAINING',
      'ADMINISTRATIVE-NEWBORN-BENEFIT',
      'REVISION'
    ]
  },
  {
    key: 'menu_item_partners',
    to: '/partners',
    title: 'Socios',
    icon: PartnersIcon,
    availableTo: [
      'ADMINISTRADOR',
      'DEPTO_CONTROL_GESTION_SOCIAL',
      'FINANZAS',
      'ABASTECIMIENTO',
      'ASISTENTE_SOCIAL',
      'ASESOR_JURIDICO',
      'GERENCIA',
      'PRESIDENCIA',
      'ADMINISTRATIVE-MULTIPLE-PROGRAMS',
      'ADMINISTRATIVE-HOSPITAL-ACCOMMODATION',
      'ADMINISTRATIVE-INUJRED-IN-LINES-OF-SERVICE-KIT',
      'ADMINISTRATIVE-WORKSHOPS-AND-TRAINING',
      'ADMINISTRATIVE-NEWBORN-BENEFIT',
      'REVISION'
    ]
  },
  {
    key: 'menu_item_programs',
    to: '/programs',
    title: 'Programas',
    icon: ProgramsIcon,
    availableTo: [
      'ADMINISTRADOR',
      'ABASTECIMIENTO',
      'ASISTENTE_SOCIAL',
      'ASESOR_JURIDICO',
      'GERENCIA',
      'PRESIDENCIA',
      'FINANZAS',
      'DEPTO_CONTROL_GESTION_SOCIAL',
      'ADMINISTRATIVE-MULTIPLE-PROGRAMS',
      'ADMINISTRATIVE-HOSPITAL-ACCOMMODATION',
      'ADMINISTRATIVE-INUJRED-IN-LINES-OF-SERVICE-KIT',
      'ADMINISTRATIVE-WORKSHOPS-AND-TRAINING',
      'ADMINISTRATIVE-NEWBORN-BENEFIT',
      'REVISION'
    ]
  },
  {
    key: 'menu_item_users',
    to: '/users',
    title: 'Usuarios',
    icon: UsersIcon,
    availableTo: ['ADMINISTRADOR', 'GERENCIA']
  },
  {
    key: 'menu_item_reportability',
    to: '/reportability',
    title: 'Reportabilidad',
    icon: ReportabilityIcon,
    availableTo: ['ADMINISTRADOR', 'DEPTO_CONTROL_GESTION_SOCIAL', 'GERENCIA']
  },
  {
    key: 'menu_item_notifications',
    to: '/notifications',
    title: 'Notificaciones',
    icon: NotificationsIcon,
    availableTo: [
      'ADMINISTRADOR',
      'ASISTENTE_SOCIAL',
      'ADMINISTRATIVE-NEWBORN-BENEFIT',
      'ADMINISTRATIVE-INUJRED-IN-LINES-OF-SERVICE-KIT',
      'ADMINISTRATIVE-HOSPITAL-ACCOMMODATION',
      'ADMINISTRATIVE-MULTIPLE-PROGRAMS'
    ]
  }
]

const Menu = () => {
  const { user } = useSelector((state) => state.auth)
  const { pathname } = useLocation()
  return linkItems
    .filter((link) => link?.availableTo?.includes(user?.role))
    .map((item) => (
      <Link key={item.key} to={item.to}>
        <Flex
          align="center"
          p="4"
          mx="4"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          fontWeight="bold"
          color={pathname === item.to ? 'white' : '#365531'}
          bg={pathname === item.to ? '#82B378' : 'transparent'}
          _hover={{
            bg: '#82B378',
            color: 'white'
          }}
          my={1}
        >
          {item.icon && (
            <Icon
              mr="4"
              fontSize="22"
              as={item.icon}
              color={pathname === item.to ? 'white' : '#365531'}
            />
          )}
          {item.title}
        </Flex>
      </Link>
    ))
}

export default Menu
