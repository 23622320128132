import React from 'react'
import { Grid, GridItem, Text } from '@chakra-ui/react'
import PartnerInformation from './PartnerInformation'
import ApplicationInformation from './ApplicationInformation'
import InformationAffected from './InformationAffected'
import AdditionalInformation from './AdditionalInformation'
import Documents from './Documents'
import RenderFlowDocuments from '../RenderFlowDocuments'
import { SectionAccordion } from '../../UI'

const FireSupport = ({ program }) => {
  const renderViewTitle = (title) => (
    <Text
      fontWeight={700}
      bg={'green.100'}
      color={'white'}
      fontSize={20}
      borderRadius={8}
      px={5}
      py={1}
      my={4}
    >
      {title}
    </Text>
  )

  return (
    <Grid templateColumns={'repeat(1, minmax(0, 1fr))'} gap={2}>
      <PartnerInformation program={program} />
      <GridItem>
        {renderViewTitle('Información de la solicitud')}
        <ApplicationInformation program={program} />
      </GridItem>
      <GridItem>
        {renderViewTitle('Información de individualización de afectado')}
        <InformationAffected program={program} />
      </GridItem>
      <GridItem>
        {renderViewTitle('Observaciones sobre la solicitud')}
        <AdditionalInformation program={program} />
      </GridItem>
      <GridItem>
        {renderViewTitle('Documentación adjunta')}
        <Documents program={program} />
      </GridItem>
      <SectionAccordion title={'Documentos agregados dentro del flujo'}>
        <GridItem>
          <RenderFlowDocuments program={program} />
        </GridItem>
      </SectionAccordion>
    </Grid>
  )
}

export default FireSupport
