import React from 'react'
import {
  FormControl,
  FormLabel,
  Input,
  Flex,
  Icon,
  Text
} from '@chakra-ui/react'
import { AddFileIcon } from '../Icons'

/* eslint arrow-body-style: ["error", "always"] */
const UploadFile = ({
  onChange = null,
  /* accept = 'image/*', */
  direction = 'column',
  sizeIcon = 80,
  p = 10,
  id = 'file',
  multiple = true,
  error,
  errorText
}) => {
  return (
    <FormControl mb={3}>
      <FormLabel htmlFor={id} minW={'100%'}>
        <Flex
          align={'center'}
          justify={'center'}
          direction={direction}
          _hover={{ cursor: 'pointer', bg: 'beige.500' }}
          borderRadius={10}
          border={'2px dotted'}
          color={!error ? 'green.100' : 'red.500'}
          p={p}
        >
          <Icon
            m="2"
            fontSize={sizeIcon}
            as={AddFileIcon}
            fill={!errorText ? 'green.100' : 'red.500'}
          />
          <Text
            fontWeight="700"
            fontSize="20px"
            color={!errorText ? 'green.100' : 'red.500'}
          >
            {!errorText ? 'Subir Archivo' : errorText}
          </Text>
        </Flex>
      </FormLabel>
      <Input
        onChange={onChange}
        /* accept={accept} */
        id={id}
        name={id}
        type={'file'}
        multiple={multiple}
        hidden
      />
    </FormControl>
  )
}

export default UploadFile
