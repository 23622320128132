import { createIcon } from '@chakra-ui/react'
/* https://freeicons.io/ui-essentials-3/ui-essentials-notification-alarm-bell-icon-44563 */
/* eslint-disable import/prefer-default-export */
const EmailIcon = createIcon({
  displayName: 'EmailIcon',
  viewBox: '0 0 14 12',
  path: [
    <path
      d="M10.6113 0H3.38905C2.57894 0.000866274 1.80226 0.317215 1.22942 0.879636C0.656585 1.44206 0.334378 2.20462 0.333496 3L0.333496 9C0.334378 9.79538 0.656585 10.5579 1.22942 11.1204C1.80226 11.6828 2.57894 11.9991 3.38905 12H10.6113C11.4214 11.9991 12.1981 11.6828 12.7709 11.1204C13.3437 10.5579 13.6659 9.79538 13.6668 9V3C13.6659 2.20462 13.3437 1.44206 12.7709 0.879636C12.1981 0.317215 11.4214 0.000866274 10.6113 0ZM10.6113 1.63636C10.9482 1.63746 11.273 1.75994 11.5241 1.98055L7.91294 5.526C7.66767 5.756 7.34155 5.88434 7.00239 5.88434C6.66322 5.88434 6.3371 5.756 6.09183 5.526L2.48072 1.98055C2.73063 1.76093 3.05366 1.63853 3.38905 1.63636H10.6113ZM10.6113 10.3636H3.38905C3.0207 10.3636 2.66743 10.22 2.40696 9.96424C2.14649 9.70851 2.00016 9.36166 2.00016 9V3.82745L4.91072 6.68509C5.18503 6.95453 5.51072 7.16827 5.86919 7.3141C6.22765 7.45992 6.61187 7.53498 6.99989 7.53498C7.3879 7.53498 7.77212 7.45992 8.13059 7.3141C8.48905 7.16827 8.81474 6.95453 9.08905 6.68509L12.0002 3.82745V9C12.0002 9.36166 11.8538 9.70851 11.5934 9.96424C11.3329 10.22 10.9796 10.3636 10.6113 10.3636Z"
      fill="#2E7423"
    />
  ]
})

export default EmailIcon
