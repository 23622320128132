import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Flex, useToast } from '@chakra-ui/react'
import { Step, Steps, useSteps } from 'chakra-ui-steps'
import { Button, CustomToast } from '../../UI'
import StepOne from './StepOne'
import StepTwo from './StepTwo'
import StepThree from './StepThree'
import { useMutateProgram } from '../../../hooks/Programs/DisasterSupport'
import { GetFormData, GetNotification } from '../../../Utils'

const DisasterSupport = ({ partner, program = null }) => {
  const toast = useToast()
  const refStepOne = useRef(null)
  const refStepTwo = useRef(null)
  const refStepThree = useRef(null)
  const { nextStep, prevStep, activeStep } = useSteps({
    initialStep: 0
  })

  const history = useHistory()
  const [stepOneForm, setStepOneForm] = useState({
    partnerPhone: partner?.phone,
    partnerRut: partner?.rut,
    partnerGrade: partner?.grade,
    partnerCode: partner?.code,
    partnerZone: partner?.zone,
    partnerPrefecture: partner?.prefecture,
    partnerEndowment: partner?.endowment
  })
  const [stepTwoForm, setStepTwoForm] = useState({})
  const [stepThreeForm, setStepThreeForm] = useState({})
  const [finalForm, setFinalForm] = useState({
    partnerId: partner?._id
  })
  const [state, setState] = useState(null)
  const { mutate, isLoading, reset } = useMutateProgram('CREATE')
  const {
    mutate: mutateUpdate,
    isLoading: isLoadingUpdate,
    reset: resetUpdate
  } = useMutateProgram('UPDATE')

  const steps = [
    {
      label: 1,
      content: (
        <StepOne
          stepOneForm={stepOneForm}
          setStepOneForm={setStepOneForm}
          refStepOne={refStepOne}
          next={nextStep}
        />
      )
    },
    {
      label: 2,
      content: (
        <StepTwo
          stepTwoForm={stepTwoForm}
          setStepTwoForm={setStepTwoForm}
          refStepTwo={refStepTwo}
          next={nextStep}
        />
      )
    },
    {
      label: 3,
      content: (
        <StepThree
          stepThreeForm={stepThreeForm}
          setStepThreeForm={setStepThreeForm}
          refStepThree={refStepThree}
          state={state}
        />
      )
    }
  ]

  const handleNext = () => {
    if (activeStep === 0) {
      refStepOne.current()
    }
    if (activeStep === 1) {
      refStepTwo.current()
    }
    if (activeStep === 2) {
      refStepThree.current()
    }
  }

  useEffect(() => {
    const beneficiariesHelped = []
    if (stepTwoForm?.isPartnerAffected) {
      beneficiariesHelped.push('Socio(a)')
    }
    if (!stepTwoForm?.isPartnerAffected) {
      beneficiariesHelped.push(stepTwoForm?.relationship)
    }

    setFinalForm({
      ...finalForm,
      ...stepOneForm,
      ...stepTwoForm,
      ...stepThreeForm,
      beneficiariesHelped
    })
  }, [stepThreeForm])

  useEffect(() => {
    // si tiene mas valores aparte de los ID de partner
    // y los el paso activo es el 2
    if (Object.keys(finalForm).length > 1 && activeStep === 2) {
      if (!program) {
        const formData = GetFormData(
          finalForm,
          [
            'policePart',
            'lastSalarySettlement',
            'unitCertificate',
            'objectList',
            'evidencePhotos',
            'aditionalDocuments',
            'minuta'
          ],
          [
            'policePartName',
            'lastSalarySettlementName',
            'unitCertificateName',
            'objectListName',
            'evidencePhotosName',
            'aditionalDocumentsName',
            'minutaName',
            'beneficiariesHelped'
          ]
        )

        mutate(formData, {
          onSuccess: () => {
            reset()
            GetNotification.basic('Programa registrado con Éxito', 'Aceptar')
            history.push('/programs')
          },
          onError: (err) => {
            console.log(err)
            GetNotification.basic(
              'Error',
              'Aceptar',
              'Error al Registrar programa',
              'error'
            )
          }
        })
      }

      if (program) {
        const { _id: programId } = program
        const formData = GetFormData(
          finalForm,
          [
            'policePart',
            'lastSalarySettlement',
            'unitCertificate',
            'objectList',
            'evidencePhotos',
            'aditionalDocuments',
            'minuta'
          ],
          [
            'policePartName',
            'lastSalarySettlementName',
            'unitCertificateName',
            'objectListName',
            'evidencePhotosName',
            'aditionalDocumentsName',
            'minutaName',
            'beneficiariesHelped'
          ]
        )
        mutateUpdate(
          { programId, formData },
          {
            onSuccess: () => {
              resetUpdate()
              GetNotification.basic('Programa actualizado con Éxito', 'Aceptar')
              history.push('/programs')
            },
            onError: (err) => {
              console.log(err)
              GetNotification.basic(
                'Error',
                'Aceptar',
                'Error al actualizar programa',
                'error'
              )
            }
          }
        )
      }
    }
  }, [finalForm])

  useEffect(() => {
    if (program) {
      setStepOneForm({
        receptionDate: program.receptionDate,
        isPartnerZone: program?.isPartnerZone,
        zone: program?.zone,
        prefecture: program.prefecture,
        unit: program.unit,
        dateOfDisaster: program.dateOfDisaster,
        damagedProperty: program.damagedProperty,
        civilStatus: program.civilStatus,
        numberChildren: program.numberChildren,
        alternativePhone: program.alternativePhone,
        /* partner field */
        partnerPhone: program?.partner.phone,
        partnerRut: program?.partner.rut,
        partnerGrade: program?.partner.grade,
        partnerCode: program?.partner.code,
        partnerZone: program?.partner.zone,
        partnerPrefecture: program?.partner.prefecture,
        partnerEndowment: program?.partner.endowment
      })
      setStepTwoForm({
        isPartnerAffected: program.isPartnerAffected,
        fullName: program.fullName,
        relationship: program.relationship,
        phoneDirectMember: program.phoneDirectMember,
        description: program.description
      })
      setStepThreeForm({
        policePartName: program.policePart,
        lastSalarySettlementName: program.lastSalarySettlement,
        unitCertificateName: program.unitCertificate,
        objectListName: program.objectList,
        evidencePhotosName: program.evidencePhotos,
        aditionalDocumentsName: program.aditionalDocuments,
        minutaName: program.minuta,
        observations: program.observations
      })
    }
  }, [program])

  useEffect(() => {
    if (isLoading || isLoadingUpdate) {
      toast({
        duration: 9000 * 9000,
        isClosable: true,
        position: 'bottom-right',
        render: ({ onClose }) => (
          <CustomToast
            text={'Espere por favor...'}
            status={'info'}
            onClose={onClose}
          />
        )
      })
      return
    }
    toast.closeAll()
  }, [isLoading, isLoadingUpdate])

  return (
    <Flex direction={'column'}>
      <Steps
        activeStep={activeStep}
        orientation="horizontal"
        responsive
        mb={{
          lg: 5,
          md: 0
        }}
      >
        {steps.map(({ label, content }) => (
          <Step key={label}>{content}</Step>
        ))}
      </Steps>
      <Flex justify={'flex-end'} mt={4}>
        <Button
          text={'Regresar'}
          bg={'green.50'}
          color={'green.500'}
          disabled={activeStep === 0 || isLoading || isLoadingUpdate}
          onClick={prevStep}
        />
        {activeStep === steps.length - 1 && (
          <Button
            text={'Guardar como incompleta'}
            bg={'green.50'}
            color={'green.500'}
            disabled={activeStep === 0 || isLoading || isLoadingUpdate}
            onClick={() => {
              setState('INCOMPLETE')
              handleNext()
            }}
            isLoading={isLoading || isLoadingUpdate}
          />
        )}
        <Button
          text={activeStep === steps.length - 1 ? 'Finalizar' : 'Siguiente'}
          onClick={() => {
            setState('INREVIEW')
            handleNext()
          }}
          isLoading={isLoading || isLoadingUpdate}
          disabled={isLoading || isLoadingUpdate}
        />
      </Flex>
    </Flex>
  )
}

export default DisasterSupport
