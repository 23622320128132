import React from 'react'
import {
  Accordion as AccordionCha,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Icon
} from '@chakra-ui/react'
import { AccordionIcon } from '../Icons'
import SquareButton from './SquareButton'

const Accordion = ({ children }) => (
  <AccordionCha allowToggle>
    <AccordionItem>
      <AccordionButton>
        <SquareButton>
          <Icon fontSize="26" as={AccordionIcon} />
        </SquareButton>
      </AccordionButton>

      <AccordionPanel pb={4}>{children}</AccordionPanel>
    </AccordionItem>
  </AccordionCha>
)

export default Accordion
