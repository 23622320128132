import React from 'react'
import { Button } from '@chakra-ui/react'

const SquareButton = ({
  bg = 'green.100',
  bghover = 'green.500',
  color = '#FFFFFF',
  disabled = false,
  children,
  onClick,
  ...props
}) => (
  <Button
    variant="outline"
    bg={bg}
    _hover={{ bg: bghover }}
    color={color}
    mx={0}
    p={0}
    disabled={disabled}
    onClick={onClick}
    {...props}
  >
    {children}
  </Button>
)

export default SquareButton
