import { useToast } from '@chakra-ui/react'
import { Button, CustomToast } from '../UI'
import { useMutatePartner } from '../../hooks/PartnersSync'

const DownloadTemplateFile = ({ otherYear = false, selectedYear = null }) => {
  const data = otherYear
    ? { oldYear: otherYear, year: selectedYear }
    : { oldYear: otherYear }

  const toast = useToast()

  const { mutate, isLoading, reset } = useMutatePartner(
    'DOWNLOAD-TEMPLATE-FILE'
  )

  const generateTemplateFile = () => {
    toast({
      duration: 9000 * 9000,
      isClosable: true,
      position: 'bottom-right',
      render: ({ onClose }) => (
        <CustomToast
          text={'Espere por favor...'}
          status={'info'}
          onClose={onClose}
        />
      )
    })
    mutate(data, {
      onSuccess: () => {
        toast.closeAll()
        reset()
        toast({
          duration: 5000,
          isClosable: true,
          position: 'bottom-right',
          render: ({ onClose }) => (
            <CustomToast
              text={'Descarga exitosa'}
              status={'success'}
              showSpinner={false}
              onClose={onClose}
            />
          )
        })
      },
      onError: (err) => {
        toast.closeAll()
        reset()
        toast({
          duration: 5000,
          isClosable: true,
          position: 'bottom-right',
          render: ({ onClose }) => (
            <CustomToast
              text={'Error al descargar reporte'}
              status={'error'}
              showSpinner={false}
              onClose={onClose}
            />
          )
        })
        console.log(err)
      }
    })
  }

  return (
    <Button
      text={`Descargar Template ${
        otherYear ? `para el año ${selectedYear}` : ''
      }`}
      px={10}
      onClick={() => generateTemplateFile()}
      isLoading={isLoading}
      disabled={isLoading}
    />
  )
}
export default DownloadTemplateFile
