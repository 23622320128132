import { createIcon } from '@chakra-ui/react'
/* https://freeicons.io/ui-essentials-3/ui-essentials-notification-alarm-bell-icon-44563 */
/* eslint-disable import/prefer-default-export */
const LockIcon = createIcon({
  displayName: 'LockIcon',
  viewBox: '0 0 21 24',
  path: [
    <path
      d="M17.5444 8.50955V7.04435C17.5444 3.15386 14.3906 0 10.5001 0C6.60957 0 3.45576 3.15386 3.45576 7.0443V8.5095C1.60493 9.45402 0.439032 11.3556 0.436768 13.4335V18.4652C0.440117 21.5206 2.91616 23.9967 5.97157 24H15.0286C18.084 23.9966 20.5601 21.5206 20.5634 18.4652V13.4335C20.5611 11.3556 19.3952 9.45402 17.5444 8.50955ZM10.5001 3.01899C12.7232 3.01899 14.5254 4.82119 14.5254 7.0443V7.89868H6.47475V7.0443C6.47475 4.82119 8.27695 3.01899 10.5001 3.01899ZM17.5444 18.4652C17.5444 19.8546 16.418 20.981 15.0286 20.981H5.97162C4.58217 20.981 3.45581 19.8546 3.45581 18.4652V13.4335C3.45581 12.0441 4.58217 10.9177 5.97162 10.9177H15.0286C16.418 10.9177 17.5444 12.0441 17.5444 13.4335V18.4652Z"
      fill="white"
    />
  ]
})

export default LockIcon
