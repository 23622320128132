const getYears = (startYear = 2022) => {
  const currentYear = new Date().getFullYear()
  const years = []
  while (startYear <= currentYear) {
    years.push(startYear)
    startYear += 1
  }
  return years
}

export default { getYears }
