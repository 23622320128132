import * as Yup from 'yup'
/* eslint-disable import/prefer-default-export */
export const steepOneSchema = Yup.object().shape({
  receptionDate: Yup.string().required('La fecha de recepción es requerida'),
  isPartnerZone: Yup.boolean(),
  zone: Yup.string().required('La zona es requerida'),
  prefecture: Yup.string().required('La prefectura es requerida'),
  unit: Yup.string().required('La unidad es requerida'),
  dateOfDeath: Yup.string().required('Deceso es requerido'),
  observations: Yup.string(),
  /* partner data */
  partnerRut: Yup.string()
    .min(11, 'El Rut no es valido')
    .max(12, 'El Rut no es valido'),
  partnerPhone: Yup.string()
    .min(8, 'El telefono no es valido')
    .max(9, 'El telefono no es valido'),
  partnerGrade: Yup.string(),
  partnerCode: Yup.string()
    .min(3, 'El código no puede tener menos de 3 caracteres')
    .max(8, 'El código no puede tener más de 8 caracteres')
    .test(
      'last-caracter',
      'El ultimo caracter del código debe ser una letra',
      (value) => {
        if (!value) {
          return true
        }
        const lastChar = value?.slice(-1)
        return /^[a-zA-Z]+$/.test(lastChar)
      }
    )
    .test(
      'start-code',
      'Los primeros caracteres antes del guión del código deben ser números',
      (value) => {
        if (!value) {
          return true
        }
        const valueArray = value.split('-')
        const lengthFirstChar = valueArray[0].length
        const firstChar = value.slice(0, lengthFirstChar)
        return /^[0-9]+$/.test(firstChar)
      }
    ),
  partnerZone: Yup.string(),
  partnerPrefecture: Yup.string(),
  partnerEndowment: Yup.string()
})

export const itemStepTwoSchema = Yup.object().shape({
  fullName: Yup.string().required('Nombre es requerido'),
  rut: Yup.string()
    .min(11, 'El Rut no es valido')
    .max(12, 'El Rut no es valido')
    .required('Rut es requerido'),
  phone: Yup.string()
    .min(8, 'El telefono no es valido')
    .max(9, 'El telefono no es valido')
    .required('Teléfono es requerido'),
  email: Yup.string().email('Ingrese un Email Valido'),
  relationship: Yup.string().required('Parentesco es requerido'),
  birthCertificateNames: Yup.array().when('relationship', {
    is: (relationship) => relationship === 'Hijo' || relationship === 'Hija',
    then: Yup.array().min(1, 'Debe adjuntar al menos un certificado'),
    otherwise: Yup.array()
  }),
  marriageCertificateNames: Yup.array().when('relationship', {
    is: (relationship) => relationship === 'Cónyuge',
    then: Yup.array().min(1, 'Debe adjuntar al menos un certificado'),
    otherwise: Yup.array()
  }),
  /* bank data */
  usePreviouslyLoadedData: Yup.boolean(),
  addresseeName: Yup.string()
    .max(40, 'El nombre no debe exceder los 40 caracteres')
    .required('Nombre del destinatario es requerido'),
  addresseeRut: Yup.string()
    .min(11, 'El Rut no es valido')
    .max(12, 'El Rut no es valido')
    .required('Rut es requerido'),
  isRequiredAccountNumber: Yup.boolean(),
  addresseeAccountNumber: Yup.string().when('isRequiredAccountNumber', {
    is: true,
    then: Yup.string().required('Numero de cuenta es requerido')
  }),
  addresseeAccountBackupNames: Yup.array(),
  addresseeTypeAccount: Yup.string().required('Tipo de cuenta es requerido'),
  specificAccount: Yup.boolean(),
  addresseeTypeAccountOther: Yup.string().when('specificAccount', {
    is: true,
    then: Yup.string().required('Tipo de cuenta es requerido')
  }),
  addresseeBank: Yup.string().required('Banco es requerido'),
  specificBank: Yup.boolean(),
  addresseeBankOther: Yup.string().when('specificBank', {
    is: true,
    then: Yup.string().required('Banco es requerido')
  }),
  addresseeEmail: Yup.string().email('Ingrese un Email Valido'),
  addresseePhone: Yup.string()
    .min(8, 'El teléfono no es valido')
    .max(9, 'El teléfono no es valido')
    .required('Teléfono es requerido')
})

export const stepThreeSchema = Yup.object().shape({
  deathCertificateName: Yup.array()
    .min(1, 'Debe adjuntar el certificado de defunción')
    .required('Debe adjuntar el certificado de defunción'),
  salarySettlementName: Yup.array()
    .min(1, 'Debe adjuntar el certificado de defunción')
    .required('Debe adjuntar la liquidación de sueldo'),
  additionalDocumentsName: Yup.array()
})
