import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { Box, Flex, Text, useDisclosure, useToast } from '@chakra-ui/react'
import { CustomToast, TextAreaComtrol } from '../../../UI'
import ModalHistory from '../../ModalHistory'
import { useMutateHistoryProgram } from '../../../../hooks/HistoryProgram'
import { GetNotification, GetFormData, GetKey } from '../../../../Utils'
import { useTypeModal } from '../../../../hooks/TypeModal'
import { schema } from './Schema'
import { SendIcon } from '../../../Icons'
import RenderAdditionalCheck from '../SendWsp/RenderAdditionalCheck'

const typeProgramAllowed = ['SupportInCaseOfDeathOfMember']

const RegisterNotification = ({ currentStep, getColor, program }) => {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { title, subTitle, succesMessage, errorMessage, titleObservation } =
    useTypeModal(currentStep?.key)
  const {
    mutate: sendWsp,
    isLoading,
    reset
  } = useMutateHistoryProgram('REGISTER-NOTIFICATION')
  const initialValues = {
    stepProgram: currentStep?.step,
    completedSemesters: program?.completedSemesters || 0,
    message: '',
    program: program?._id,
    keyHistory: GetKey.getNextStep(
      currentStep?.key,
      program?.type,
      program?.history
    ),
    state: program?.state,
    isRequiredDelegateZone: true,
    isRequiredSocialAssistant: true,
    delegateZone: false,
    socialAssistant: false,
    delegateZoneDate: '',
    socialAssistantDate: '',
    isRequiredSendOnlyWsp: false
  }

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    validateOnChange: false,
    onSubmit: (values) => {
      const formData = GetFormData(values)

      sendWsp(formData, {
        onSuccess: () => {
          reset()
          formik.resetForm()
          onClose()
          GetNotification.basic(succesMessage, 'Aceptar')
        },
        onError: (err) => {
          console.log(err)
          const response = err?.response?.data

          GetNotification.basic(
            'Error',
            'Aceptar',
            response?.type === 'validateLastStep'
              ? response.message
              : errorMessage,
            'error'
          )
        }
      })
    }
  })

  useEffect(() => {
    if (isLoading) {
      toast({
        duration: 9000 * 9000,
        isClosable: true,
        position: 'bottom-right',
        render: ({ onClose: onCloseT }) => (
          <CustomToast
            text={'Espere por favor...'}
            status={'info'}
            onClose={onCloseT}
          />
        )
      })
      return
    }
    toast.closeAll()
  }, [isLoading])

  return (
    <ModalHistory
      title={title}
      iconButton={SendIcon}
      colors={getColor}
      disable={currentStep?.complete}
      isLoading={isLoading}
      onClick={formik.handleSubmit}
      onClose={onClose}
      isOpen={isOpen}
      onOpen={onOpen}
    >
      <Flex align={'center'} justify={'center'} direction={'column'} gap={5}>
        {subTitle && <Text>{subTitle}</Text>}
        <Box w={'100%'}>
          {/* --- */}
          <Flex direction={'column'} gap={2}>
            <Flex direction={'column'}>
              <Text fontSize={'sm'} marginRight={'auto'}>
                Dejar Registro de notificación a:
              </Text>
            </Flex>
          </Flex>
          {/* --- */}

          <RenderAdditionalCheck
            formik={formik}
            typeProgramAllowed={typeProgramAllowed}
            program={program}
          />
          <Box minW={'100%'} pt={4}>
            <TextAreaComtrol
              id={'message'}
              label={`${titleObservation || 'Observaciones'}:`}
              value={formik.values.message}
              isRequired={false}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.message)}
              errorText={formik.errors.message}
            />
          </Box>
        </Box>
      </Flex>
    </ModalHistory>
  )
}

export default RegisterNotification
