import React from 'react'
import { addDays } from 'date-fns'
import { Flex, Checkbox } from '@chakra-ui/react'
import { CustomDatePicker, InputComtrol } from '../../../UI'

const RenderAdditionalCheck = ({ formik, typeProgramAllowed, program }) => {
  const handleChangeDelegateZoneDate = (date) => {
    formik.setFieldValue('delegateZoneDate', date)
  }
  const handleChangeSocialAssistantDate = (date) => {
    formik.setFieldValue('socialAssistantDate', date)
  }

  return (
    <Flex direction={'column'} py={2}>
      {(typeProgramAllowed.includes(program?.type) &&
        program.state === 'APPROVED') ||
      (program?.type === 'SolidarityHealthFund' &&
        program?.state === 'REJECTED') ? (
        <>
          <Flex
            direction={'column'}
            gap={2}
            border={'1px solid #82b378'}
            borderRadius={8}
            my={2}
            p={2}
          >
            {formik.values.isRequiredDelegateZone &&
              formik.values.completedSemesters === 0 && (
                <Checkbox
                  css={{ borderColor: 'green' }}
                  id={'delegateZone'}
                  size="md"
                  colorScheme="green"
                  value={formik.values.delegateZone}
                  isChecked={formik.values.delegateZone}
                  onChange={formik.handleChange}
                >
                  ¿Ha sido informado la delegada zonal?
                </Checkbox>
              )}
            {formik.values.delegateZone &&
              formik.values.completedSemesters === 0 && (
                <InputComtrol
                  id={'delegateZoneDate'}
                  label={'Fecha del evento:'}
                  isRequired={false}
                >
                  <CustomDatePicker
                    placeholder={'DD/MM/AAAA'}
                    currentDate={formik.values.delegateZoneDate}
                    error={Boolean(formik.errors.delegateZoneDate)}
                    errorText={formik.errors.delegateZoneDate}
                    onChange={(date) => handleChangeDelegateZoneDate(date)}
                    maxDate={addDays(new Date(), 0)}
                  />
                </InputComtrol>
              )}

            {formik.values.isRequiredSocialAssistant &&
              formik.values.completedSemesters === 0 && (
                <>
                  <Checkbox
                    css={{ borderColor: 'green' }}
                    id={'socialAssistant'}
                    size="md"
                    colorScheme="green"
                    value={formik.values.socialAssistant}
                    isChecked={formik.values.socialAssistant}
                    onChange={formik.handleChange}
                  >
                    ¿Ha sido informado la asistente social?
                  </Checkbox>
                  {formik.values.socialAssistant && (
                    <InputComtrol
                      id={'socialAssistantDate'}
                      label={'Fecha del evento: (opcional)'}
                      isRequired={false}
                    >
                      <CustomDatePicker
                        placeholder={'DD/MM/AAAA'}
                        currentDate={formik.values.socialAssistantDate}
                        error={Boolean(formik.errors.socialAssistantDate)}
                        errorText={formik.errors.socialAssistantDate}
                        onChange={(date) =>
                          handleChangeSocialAssistantDate(date)
                        }
                        maxDate={addDays(new Date(), 0)}
                      />
                    </InputComtrol>
                  )}
                </>
              )}

            {formik.values.completedSemesters > 0 && (
              <Checkbox
                css={{ borderColor: 'green' }}
                id={'continueStudies'}
                size="md"
                colorScheme="green"
                value={formik.values.continueStudies}
                isChecked={formik.values.continueStudies}
                onChange={formik.handleChange}
              >
                ¿Tendrá Continuidad de Estudios?
              </Checkbox>
            )}
          </Flex>
        </>
      ) : (
        <></>
      )}

      {formik.values.isRequiredSendOnlyWsp && (
        <Checkbox
          css={{ borderColor: 'green' }}
          id={'sendOnlyWsp'}
          size="md"
          colorScheme="green"
          value={formik.values.sendOnlyWsp}
          isChecked={formik.values.sendOnlyWsp}
          onChange={formik.handleChange}
        >
          ¿Desea descartar el envío por correo Electrónico?
        </Checkbox>
      )}
    </Flex>
  )
}

export default RenderAdditionalCheck
