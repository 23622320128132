import React from 'react'
import { Grid, GridItem, Text } from '@chakra-ui/react'
import { InputComtrol, TextAreaComtrol, CardFilePreview } from '../../UI'
import { FormatDate } from '../../../Utils'

const ApplicationInformation = ({ program }) => (
  <>
    <Grid
      templateColumns={{
        lg: 'repeat(2, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem>
        <InputComtrol
          label={'Fecha de recepción de documentos'}
          value={
            program?.receptionDate ? FormatDate(program?.receptionDate) : ''
          }
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Zona de postulación'}
          value={program?.zone}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
      <GridItem>
        <InputComtrol
          label={'Fecha de entrega'}
          value={FormatDate(program?.deliveryDate)}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Alta repartición / Repartición'}
          value={program?.prefecture}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
    </Grid>
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <InputComtrol
        label={'Unidad'}
        value={program?.unit}
        isRequired={false}
        readOnly={true}
      />
    </Grid>
    <Grid
      templateColumns={{
        lg: 'repeat(2, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem>
        <InputComtrol
          label={'Región'}
          value={program?.region}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
      <GridItem>
        <InputComtrol
          label={'Tipo de kit'}
          value={program?.typeKit}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
    </Grid>
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem>
        <TextAreaComtrol
          label={'Observaciones'}
          value={program?.observations}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
    </Grid>
    {program?.documents.length > 0 && (
      <>
        <Text>Documentos</Text>
        <Grid
          templateColumns={{
            lg: 'repeat(1, 1fr)',
            md: 'repeat(1, 1fr)',
            sm: 'repeat(1, 1fr)'
          }}
          gap={4}
          pb={8}
        >
          {program?.documents.map((item) => (
            <GridItem>
              <CardFilePreview name={item?.name} url={item?.url} />
            </GridItem>
          ))}
        </Grid>
      </>
    )}
  </>
)

export default ApplicationInformation
