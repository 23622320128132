import React from 'react'
import { useHistory } from 'react-router-dom'
import { Flex, Text } from '@chakra-ui/react'
import { AddFileWhiteIcon } from '../Icons'
import { Button } from '../UI'

const WithoutResults = ({ type }) => {
  const history = useHistory()

  const redirectToCreatePartner = () => {
    history.push('/partners/create')
  }

  const redirectToCreatePartnerNotValidate = () => {
    history.push('/partners/create/not-validated')
  }

  return (
    <>
      {type === 'SEARCH-PARTNERS-FROM-MODAL-CREATE-PROGRAM' && (
        <Flex
          direction={'column'}
          justify={'center'}
          align={'center'}
          my={5}
          gap={2}
        >
          <>
            <Text fontWeight={700} color={'green.100'} fontSize={'2xl'}>
              No hay resultados
            </Text>
            <Text color={'green.100'} fontSize={'md'}>
              Es posible que el funcionario no esté asociado a la corporación
            </Text>
            <Text fontWeight={700} color={'green.100'} fontSize={'md'}>
              Puedes realizar la inscripción o crear la solicitud sin ser socio
            </Text>
          </>
          <Flex gap={2}>
            <Button
              icon={AddFileWhiteIcon}
              text={'Agregar socio'}
              onClick={redirectToCreatePartner}
            />
            <Button
              icon={AddFileWhiteIcon}
              text={'Crear solicitud sin ser socio'}
              onClick={redirectToCreatePartnerNotValidate}
            />
          </Flex>
        </Flex>
      )}
      {type === 'ALERTS' && (
        <Flex justify={'center'} align={'center'}>
          <Text fontWeight={700} color={'green.100'} fontSize={'2xl'}>
            No hay notificaciones para mostrar.
          </Text>
        </Flex>
      )}
      {type === 'PROGRAMS-TABLE' && (
        <Flex
          direction={'column'}
          justify={'center'}
          align={'center'}
          my={5}
          gap={2}
        >
          <>
            <Text fontWeight={700} color={'green.100'} fontSize={'2xl'}>
              No hay resultados
            </Text>
          </>
        </Flex>
      )}
      {type === 'PARTNERS-TABLE' && (
        <Flex
          direction={'column'}
          justify={'center'}
          align={'center'}
          my={5}
          gap={2}
        >
          <>
            <Text fontWeight={700} color={'green.100'} fontSize={'2xl'}>
              No hay resultados
            </Text>
          </>
        </Flex>
      )}
      {type === 'SYNC-TABLE' && (
        <Flex
          direction={'column'}
          justify={'center'}
          align={'center'}
          my={5}
          gap={2}
        >
          <>
            <Text fontWeight={700} color={'green.100'} fontSize={'2xl'}>
              No hay resultados
            </Text>
          </>
        </Flex>
      )}
      {type === 'USERS-TABLE' && (
        <Flex
          direction={'column'}
          justify={'center'}
          align={'center'}
          my={5}
          gap={2}
        >
          <>
            <Text fontWeight={700} color={'green.100'} fontSize={'2xl'}>
              No hay resultados
            </Text>
          </>
        </Flex>
      )}
      {type === 'DASHBOARD-TABLE' && (
        <Flex
          direction={'column'}
          justify={'center'}
          align={'center'}
          my={5}
          gap={2}
        >
          <>
            <Text fontWeight={700} color={'green.100'} fontSize={'2xl'}>
              No hay resultados
            </Text>
          </>
        </Flex>
      )}
    </>
  )
}

export default WithoutResults
