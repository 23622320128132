import React from 'react'
import { Icon, Flex, Box, Text } from '@chakra-ui/react'
import { CalendarIcon, PoliceCapIcon, UsersIcon } from '../Icons'
import { FormatDate } from '../../Utils'
import GetStatusCard from '../../Utils/GetStatusCard'

const CardProgramInfo = ({ createdAt, fullName, state = null }) => (
  <Flex
    gap={2}
    justify={'space-between'}
    direction={{
      lg: 'row',
      md: 'column',
      sm: 'column'
    }}
    minW={'100%'}
  >
    <Flex align={'center'} gap={2}>
      <Icon fontSize={30} as={CalendarIcon} color={'green.100'} />
      <Box>
        <Text>Solicitud creada</Text>
        <Text fontWeight={700}>{FormatDate(Date(createdAt))}</Text>
      </Box>
    </Flex>
    <Flex align={'center'} gap={2}>
      <Icon fontSize={30} as={state ? PoliceCapIcon : UsersIcon} />
      <Box>
        <Text>Solicitado por</Text>
        <Text fontWeight={700}>{fullName}</Text>
      </Box>
    </Flex>
    {state && GetStatusCard.partner(state)}
  </Flex>
)

export default CardProgramInfo
