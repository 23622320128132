import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { Box, Checkbox, Flex, useToast } from '@chakra-ui/react'
import {
  BarFileName,
  InputComtrol,
  TextAreaComtrol,
  UploadFile,
  CustomToast
} from '../../../UI'
import { useMutateHistoryProgram } from '../../../../hooks/HistoryProgram'
import {
  GetNotification,
  GetFormData,
  CondicionalModal,
  Files,
  Schedule,
  ReasonList
} from '../../../../Utils'
import { useTypeModal } from '../../../../hooks/TypeModal'

import { schema } from './Schema'
import VoucherIntructive from '../../ActionStep/OtherProcesses/VoucherIntructive'
import GiftcardsProof from '../../ActionStep/OtherProcesses/GiftcardsProof'
import DocumentsUniversityOrder from '../../ActionStep/OtherProcesses/DocumentsUniversityOrder'
import DocumentsSolidarityOrder from '../../ActionStep/OtherProcesses/DocumentsSolidarityOrder'
import RenderGeneralDocument from '../../ActionStep/OtherProcesses/RenderGeneralDocument'
import RequestPaymentMonitors from '../../ActionStep/OtherProcesses/RequestPaymentMonitors'
import RenderInputRequired from '../../ActionStep/OtherProcesses/RenderInputRequired'
import RenderVouchersRequired from '../../ActionStep/OtherProcesses/RenderVouchersRequired'
import RenderDebtCertificateRequired from '../../ActionStep/OtherProcesses/RenderDebtCertificateRequired'
import RenderCheckRequired from '../../ActionStep/OtherProcesses/RenderCheckRequired'
import ButtonFotter from './ButtonFotter'
import RenderArrayDescriptions from '../../ActionStep/OtherProcesses/RenderArrayDescriptions'
import RenderValidationStep from '../../ActionStep/OtherProcesses/RenderValidationStep'

const OtherProcessesEdit = ({
  currentStep,
  program,
  additionalData,
  onCloseEdit
}) => {
  const toast = useToast()
  const {
    requiredCommission,
    requiredDescriptions,
    requiredDocuments,
    requiredDate,
    requiredGiftActivate,
    requiredPayment,
    requiredAuthorization,
    requiredProposedState,
    requiredAmount,
    requiredOfficeStatus,
    requiredVoucher,
    requiredInstructive,
    requiredEntryDate,
    requiredBackupFeeAmount,
    requiredScholarshipCommission,
    requiredBankData,
    requiredOffice,
    requiredInvoice,
    requiredTariffDifferenceDocuments,
    requiredTransferredAmount,
    requiredUsedAmount,
    requiredPaymentMonitors,
    requiredVerificationOfDocuments,
    requiredMoreDescriptions,
    requiredReportedEmail,
    requiredChangeState,
    requiredAmountCancel,
    requiredPurchaseDetail,
    requiredActa,
    requiredReason,
    requiredEeceptionDate,
    requiredBoxPurchase,
    requiredBoxPurchaseTwo,
    requiredPurchaseRequestLetter,
    requiredQuotesReceived,
    requiredDebtCertificate,
    requiredPurchaseRequestLetterSigned,
    requiredCheckVouchersSend,
    requiredDateSubmissionReceipts,
    requiredDateSubmissionReceiptsArr,
    requiredDateCertificateRequest,
    requiredDateCertificateReception,
    requiredDocumentsSentMedicalInstitution,
    requiredDateSend
  } = CondicionalModal
  const {
    /* actionKey, */
    succesMessage,
    errorMessage,
    titleDocument,
    titleObservation,
    validation
  } = useTypeModal(currentStep?.key)

  const { mutate, isLoading, reset } =
    useMutateHistoryProgram(`OTHER-PROCESSES-EDIT`)

  const getTypeProcess = () => {
    const additionalInformation = program?.additionalInformation.filter(
      (item) => item.key === 'COMMISSION-PREPARATION-SOLIDARITY-HEALTH-FUND'
    )

    if (additionalInformation.length > 0) {
      return {
        medicines: additionalInformation[0]?.medicines,
        supplies: additionalInformation[0]?.supplies,
        tariffDifference: additionalInformation[0]?.tariffDifference,
        milkFormula: additionalInformation[0]?.milkFormula,
        others: additionalInformation[0]?.others,
        service: additionalInformation[0]?.service,
        otherService: additionalInformation[0]?.otherService,
        commission: additionalInformation[0]?.commission
      }
    }

    return {
      medicines: false,
      supplies: false,
      tariffDifference: false,
      milkFormula: false,
      others: false,
      service: null,
      otherService: null,
      commission: null
    }
  }

  const typeProcess = getTypeProcess()

  const initialValues = {
    /* data General */
    stepProgram: currentStep?.step,
    isRequiredDescriptions: false,
    descriptions: '',
    isRequiredMoreDescriptions: false,
    moreDescriptions: [],
    isRequiredDocuments: false,
    documentsNames: [],
    documents: null,
    program: program?._id,
    state: program?.state,
    key: currentStep?.key,
    /* end data General */

    /* data NewbornBenefit */
    isRequiredDate: false,
    dateDelivery: '',
    isRequiredGiftCardActive: false,
    giftcardActive: false,
    /* end data NewbornBenefit */

    /* data university, pre-university, Technical-professional, Support In Case Of Death Of Member */
    isRequiredPayment: false,
    paymentVerification: '',
    /* end data university, pre-university, Technical-professional, Support In Case Of Death Of Member */

    /* data university, pre-universitary, Technical-professional, applicant-Officer */
    isRequiredAuthorization: false,
    orderAuthorization: '',
    isRequiredAmount: false,
    amount: '',
    isReuiredAlterProcess: false,
    alterProcess: '',
    /* end data university, pre-universitary, Technical-professional, applicant-Officer */

    /* data Disaster-Support */
    isRequiredProposedState: false,
    proposedState: '',
    isRequiredOfficeStatus: false,
    officeStatus: '',
    /* end data Disaster-Support */

    /* data DisasterSupport */
    isRequiredVoucher: false,
    voucherName: [],
    voucher: null,
    stockOfficeName: [],
    stockOffice: null,
    deliveryProofName: [],
    deliveryProof: null,
    isRequiredInstructive: false,
    instructiveName: [],
    instructive: null,
    /* end data DisasterSupport */

    /* data university Scholarships */
    isRequiredBackupFeeAmount: false,
    backupFeeAmountName: [],
    backupFeeAmount: null,
    isRequiredScholarshipCommission: false,
    scholarshipCommissionName: [],
    scholarshipCommission: null,
    isRequiredBankData: false,
    bankDataName: [],
    bankData: null,

    /* end data university Scholarships */

    /* data Solidarity Health Fund */
    isRequiredBoxPurchase: false,
    boxPurchaseName: [],
    boxPurchase: null,
    isRequiredPurchaseRequestLetter: false,
    purchaseRequestLetterName: [],
    commitmentLetterName: [],
    purchaseRequestLetter: null,
    isRequiredQuotesReceived: false,
    quotesReceivedName: [],
    quotesReceived: null,
    isRequiredPurchaseRequestLetterSigned: false,
    purchaseRequestLetterSignedName: [],
    purchaseRequestLetterSigned: null,
    isRequiredCheckVouchersSend: false,
    vouchersSend: false,
    invoiceRequest: false,
    isRequiredDateSubmissionReceipts: false,
    dateSubmissionReceipts: '',
    isRequiredDateSubmissionReceiptsArr: false,
    dateSubmissionReceiptsArr: [],
    isRequiredDateCertificateRequest: false,
    dateCertificateRequest: '',
    isRequiredDateCertificateReception: false,
    dateCertificateReception: '',
    isRequiredDocumentsSentMedicalInstitution: false,
    documentsSentMedicalInstitutionName: [],
    documentsSentMedicalInstitution: null,
    isRequiredDateSend: false,
    dateSend: '',
    isRequiredDebtCertificate: false,
    debtCertificateName: [],
    debtCertificate: null,
    commitmentLetter: null,
    isRequiredBoxPurchaseTwo: false,
    boxPurchaseTwoName: [],
    boxPurchaseTwo: null,
    /* end data Solidarity Health Fund */

    /* data fss, incendio, catastrofe */
    isRequiredUsedAmount: false,
    usedAmount: '',
    /* end data fss, incendio, catastrofe */

    /* data university, pre-university, Technical Professional Scholarships */
    isRequiredTransferredAmount: false,
    transferredAmount: '',
    isRequiredOffice: false,
    officeName: [],
    office: null,
    isRequiredInvoice: false,
    invoiceName: [],
    invoice: null,
    isRequiredReportedEmail: false,
    reportedEmailName: [],
    reportedEmail: null,
    isRequiredAmountCancel: false,
    amountCancelName: [],
    amountCancel: null,
    isRequiredPurchaseDetail: false,
    purchaseDetailName: [],
    purchaseDetail: null,
    isRequiredActa: false,
    actaName: [],
    acta: null,
    /* end data university, pre-university, Technical Professional Scholarships */

    /* data Monitor -WorkshopsAndTraining */
    isRequiredPaymentMonitors: false,
    classScheduleNames: [],
    classSchedule: null,
    feeBillNames: [],
    feeBill: null,
    attendanceSheetNames: [],
    attendanceSheet: null,
    isRequiredVerificationOfDocuments: false,
    verificationOfDocuments: '',
    /* end data Monitor -WorkshopsAndTraining */

    /* data hospital accommodation */
    isRequiredEntryDate: false,
    entryDate: '',
    totalDays: undefined,
    entryHour: Schedule.hours.at(0),
    entryMinute: Schedule.minutes.at(0),
    entryPeriod: Schedule.periods.at(0),
    isExtension: false,
    isExit: false,
    extensionDays: '',
    newExitDate: '',
    exitHour: Schedule.hours.at(0),
    exitMinute: Schedule.minutes.at(0),
    exitPeriod: Schedule.periods.at(0),
    /* end hospital accommodation */

    /* data resignation or loss */
    isRequiredReason: false,
    reason: ReasonList.at(0),
    isRequiredReceptionDate: false,
    receptionDate: '',
    /* end data resignation or loss */

    canChangeState: false,

    isRequiredCommission: false,
    commission: '',

    isRequiredTariffDifferenceDocuments: false,
    tariffDifferenceDocumentsNames: [],
    tariffDifferenceDocuments: null
  }
  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    validateOnChange: true,
    onSubmit: (values) => {
      const { _id: additionalDataId } = additionalData

      const clearAmountValue = Boolean(
        requiredTransferredAmount.includes(currentStep.key)
      )

      if (clearAmountValue) {
        values.amount = ''
      }

      if (values.dateSubmissionReceiptsArr.length > 0) {
        values.dateSubmissionReceiptsArr =
          values.dateSubmissionReceiptsArr?.map((item) => item.date)
      }

      const formData = GetFormData(
        values,
        [
          'documents',
          'voucher',
          'stockOffice',
          'deliveryProof',
          'instructive',
          'backupFeeAmount',
          'scholarshipCommission',
          'bankData',
          'boxPurchase',
          'boxPurchaseTwo',
          'purchaseRequestLetter',
          'quotesReceived',
          'debtCertificate',
          'commitmentLetter',
          'purchaseRequestLetterSigned',
          'documentsSentMedicalInstitution',
          'office',
          'invoice',
          'tariffDifferenceDocuments',
          'classSchedule',
          'feeBill',
          'attendanceSheet',
          'amountCancel',
          'purchaseDetail',
          'acta',
          'reportedEmail'
        ],
        [
          'moreDescriptions',
          'dateSubmissionReceiptsArr',
          'documentsNames',
          'voucherName',
          'instructiveName',
          'backupFeeAmountName',
          'scholarshipCommissionName',
          'classScheduleNames',
          'feeBillNames',
          'attendanceSheetNames',
          'bankDataName',
          'officeName',
          'invoiceName',
          'tariffDifferenceDocumentsNames',
          'boxPurchaseName',
          'boxPurchaseTwoName',
          'purchaseRequestLetterName',
          'quotesReceivedName',
          'debtCertificateName',
          'commitmentLetterName',
          'purchaseRequestLetterSignedName',
          'documentsSentMedicalInstitutionName',
          'amountCancelName',
          'purchaseDetailName',
          'actaName',
          'reportedEmailName'
        ]
      )
      const test = true
      if (test) {
        mutate(
          { additionalDataId, formData },
          {
            onSuccess: () => {
              reset()
              formik.resetForm()
              onCloseEdit()
              GetNotification.basic(succesMessage, 'Aceptar')
            },
            onError: (err) => {
              console.log(err)
              GetNotification.basic('Error', 'Aceptar', errorMessage, 'error')
            }
          }
        )
      }
    }
  })

  const deleteFile = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.documentsNames,
      formik.values.documents
    )

    formik.setValues({
      ...formik.values,
      documentsNames: resultNames,
      documents: resultListFiles
    })
  }

  const handleChangeDocuments = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.documentsNames,
      formik.values.documents
    )

    formik.setValues({
      ...formik.values,
      documentsNames: newListNames,
      documents: newListFiles
    })
    e.target.value = null
  }

  const deleteCommitmentLetterFile = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.commitmentLetterName,
      formik.values.commitmentLetter
    )

    formik.setValues({
      ...formik.values,
      commitmentLetterName: resultNames,
      commitmentLetter: resultListFiles
    })
  }

  const handleChangeCommitmentLetter = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.commitmentLetterName,
      formik.values.commitmentLetter
    )

    formik.setValues({
      ...formik.values,
      commitmentLetterName: newListNames,
      commitmentLetter: newListFiles
    })
    e.target.value = null
  }

  useEffect(() => {
    if (currentStep && additionalData) {
      const stepValidate =
        currentStep?.key ===
          'VERIFICATION-DELIVERY-OFFICE-SOLIDARITY-HEALTH-FUND' ||
        currentStep?.key === 'SIGN-PURCHASE-ORDER-SOLIDARITY-HEALTH-FUND' ||
        currentStep?.key === 'ADD-DEBT-CERTIFICATE-TWO-SOLIDARITY-HEALTH-FUND'

      formik.resetForm(initialValues)

      /* --- */
      formik.setValues({
        ...initialValues,
        currentKeyHistory: additionalData?.key,
        commission: additionalData?.commission || '',
        descriptions: additionalData?.descriptions || '',
        moreDescriptions: additionalData?.moreDescriptions || [],
        documentsNames: additionalData?.documents || [],
        dateDelivery: additionalData?.dateDelivery || '',
        paymentVerification: additionalData?.paymentVerification || '',
        orderAuthorization: additionalData?.orderAuthorization || '',
        amount: additionalData?.amount || '',
        alterProcess: additionalData?.alterProcess || '',
        proposedState: additionalData?.proposedState || '',
        officeStatus: additionalData?.officeStatus || '',
        voucherName: additionalData?.voucher || [],
        stockOfficeName: additionalData?.stockOffice || [],
        deliveryProofName: additionalData?.deliveryProof || [],
        instructiveName: additionalData?.instructive || [],
        reportedEmailName: additionalData?.reportedEmail || [],
        backupFeeAmountName: additionalData?.backupFeeAmount || [],
        scholarshipCommissionName: additionalData?.scholarshipCommission || [],
        bankDataName: additionalData?.bankData || [],
        boxPurchaseName: additionalData?.boxPurchase || [],
        boxPurchaseTwoName: additionalData?.boxPurchaseTwo || [],
        purchaseRequestLetterName: additionalData?.purchaseRequestLetter || [],
        quotesReceivedName: additionalData?.quotesReceived || [],
        debtCertificateName: additionalData?.debtCertificate || [],
        commitmentLetterName: additionalData?.commitmentLetter || [],
        purchaseRequestLetterSignedName:
          additionalData?.purchaseRequestLetterSigned || [],
        vouchersSend: additionalData?.vouchersSend || false,
        invoiceRequest: additionalData?.invoiceRequest || false,
        dateSubmissionReceipts: additionalData?.dateSubmissionReceipts || '',
        dateSubmissionReceiptsArr:
          additionalData?.dateSubmissionReceiptsArr?.map((item) => ({
            id: Math.random().toString(),
            date: item
          })) || '',
        dateSend: additionalData?.dateSend || '',
        dateCertificateRequest: additionalData?.dateCertificateRequest || '',
        dateCertificateReception:
          additionalData?.dateCertificateReception || '',
        documentsSentMedicalInstitutionName:
          additionalData?.documentsSentMedicalInstitution || [],
        officeName: additionalData?.office || [],
        invoiceName: additionalData?.invoice || [],
        tariffDifferenceDocumentsNames:
          additionalData?.tariffDifferenceDocuments || [],
        isRequiredTariffDifferenceDocuments:
          (requiredTariffDifferenceDocuments.includes(currentStep?.key) &&
            typeProcess.medicines &&
            typeProcess.tariffDifference &&
            typeProcess.service === 'Dipreca') ||
          (requiredTariffDifferenceDocuments.includes(currentStep?.key) &&
            typeProcess.supplies &&
            typeProcess.tariffDifference &&
            typeProcess.service === 'Dipreca'),
        transferredAmount: additionalData?.transferredAmount || '',
        usedAmount: additionalData?.usedAmount || '',
        classScheduleNames: additionalData?.classSchedule || [],
        feeBillNames: additionalData?.feeBill || [],
        attendanceSheetNames: additionalData?.attendanceSheet || [],
        verificationOfDocuments: additionalData?.verificationOfDocuments || '',
        amountCancelName: additionalData?.amountCancel || [],
        purchaseDetailName: additionalData?.purchaseDetail || [],
        actaName: additionalData?.acta || [],
        entryDate: additionalData?.entryDate || '',
        entryHour: additionalData?.entryHour || '',
        entryMinute: additionalData?.entryMinute || '',
        entryPeriod: additionalData?.entryPeriod || '',
        isExtension: additionalData?.isExtension || false,
        isExit: additionalData?.isExit || false,
        totalDays: additionalData?.totalDays || null,
        extensionDays: additionalData?.extensionDays || '',
        newExitDate: additionalData?.newExitDate || '',
        exitHour: additionalData?.exitHour || '',
        exitMinute: additionalData?.exitMinute || '',
        exitPeriod: additionalData?.exitPeriod || '',
        reason: additionalData?.reason || '',
        receptionDate: additionalData?.receptionDate || '',
        /* --- */
        isRequiredDate: requiredDate.includes(currentStep?.key),
        isRequiredGiftCardActive: requiredGiftActivate.includes(
          currentStep?.key
        ),
        isRequiredDescriptions: requiredDescriptions.includes(currentStep?.key),
        isRequiredDocuments: Boolean(
          requiredDocuments.includes(currentStep?.key) ||
            additionalData?.documents?.length > 0
        ),
        isRequiredPayment: requiredPayment.includes(currentStep?.key),
        isRequiredAuthorization: requiredAuthorization.includes(
          currentStep?.key
        ),
        isRequiredProposedState: requiredProposedState.includes(
          currentStep?.key
        ),
        isRequiredAmount: requiredAmount.includes(currentStep?.key),
        isRequiredOfficeStatus: requiredOfficeStatus.includes(currentStep?.key),
        isRequiredVoucher: requiredVoucher.includes(currentStep?.key),
        isRequiredInstructive: requiredInstructive.includes(currentStep?.key),
        isRequiredBackupFeeAmount: requiredBackupFeeAmount.includes(
          currentStep?.key
        ),
        isRequiredScholarshipCommission: requiredScholarshipCommission.includes(
          currentStep?.key
        ),
        isRequiredBankData: requiredBankData.includes(currentStep?.key),
        isRequiredBoxPurchase: requiredBoxPurchase.includes(currentStep?.key),
        isRequiredBoxPurchaseTwo: requiredBoxPurchaseTwo.includes(
          currentStep?.key
        ),
        isRequiredPurchaseRequestLetter: requiredPurchaseRequestLetter.includes(
          currentStep?.key
        ),
        isRequiredQuotesReceived:
          (requiredQuotesReceived.includes(currentStep?.key) &&
            !typeProcess.tariffDifference &&
            typeProcess.service !== 'Dipreca') ||
          (requiredQuotesReceived.includes(currentStep?.key) &&
            typeProcess.tariffDifference &&
            typeProcess.service === 'Otras') ||
          (!stepValidate &&
            (typeProcess.medicines || typeProcess.supplies) &&
            typeProcess.tariffDifference &&
            typeProcess.service === 'Dipreca'),
        isRequiredDebtCertificate: requiredDebtCertificate.includes(
          currentStep?.key
        ),
        isRequiredPurchaseRequestLetterSigned:
          requiredPurchaseRequestLetterSigned.includes(currentStep?.key),
        isRequiredCheckVouchersSend: requiredCheckVouchersSend.includes(
          currentStep?.key
        ),
        isRequiredOffice: requiredOffice.includes(currentStep?.key),
        isRequiredInvoice: requiredInvoice.includes(currentStep?.key),
        isRequiredTransferredAmount: requiredTransferredAmount.includes(
          currentStep?.key
        ),
        isRequiredUsedAmount: requiredUsedAmount.includes(currentStep?.key),
        isRequiredPaymentMonitors: requiredPaymentMonitors.includes(
          currentStep?.key
        ),
        isRequiredVerificationOfDocuments:
          requiredVerificationOfDocuments.includes(currentStep?.key),
        canChangeState: requiredChangeState.includes(currentStep?.key),
        isRequiredMoreDescriptions: requiredMoreDescriptions.includes(
          currentStep?.key
        ),
        isRequiredReportedEmail: requiredReportedEmail.includes(
          currentStep?.key
        ),
        isRequiredAmountCancel: requiredAmountCancel.includes(currentStep?.key),
        isRequiredPurchaseDetail: requiredPurchaseDetail.includes(
          currentStep?.key
        ),
        isRequiredActa: requiredActa.includes(currentStep?.key),
        isRequiredEntryDate: requiredEntryDate.includes(currentStep?.key),
        isRequiredReason: requiredReason.includes(currentStep?.key),
        isRequiredReceptionDate: requiredEeceptionDate.includes(
          currentStep?.key
        ),
        isRequiredDateSubmissionReceipts:
          requiredDateSubmissionReceipts.includes(currentStep?.key),
        isRequiredDateSubmissionReceiptsArr:
          requiredDateSubmissionReceiptsArr.includes(currentStep?.key),
        isRequiredDateCertificateRequest:
          requiredDateCertificateRequest.includes(currentStep?.key),
        isRequiredDateCertificateReception:
          requiredDateCertificateReception.includes(currentStep?.key),
        isRequiredDocumentsSentMedicalInstitution:
          requiredDocumentsSentMedicalInstitution.includes(currentStep?.key),
        isRequiredDateSend: requiredDateSend.includes(currentStep?.key),
        isRequiredCommission: requiredCommission.includes(currentStep?.key)
      })
    }
  }, [currentStep, additionalData])

  useEffect(() => {
    if (isLoading) {
      toast({
        duration: 9000 * 9000,
        isClosable: true,
        position: 'bottom-right',
        render: ({ onClose: onCloseT }) => (
          <CustomToast
            text={'Espere por favor...'}
            status={'info'}
            onClose={onCloseT}
          />
        )
      })
      return
    }
    toast.closeAll()
  }, [isLoading])

  return (
    <Flex align={'center'} justify={'center'} direction={'column'} gap={5}>
      <Box w={'100%'}>
        {validation && (
          <RenderValidationStep
            program={program}
            formik={formik}
            validation={validation}
          />
        )}
        {formik.values.isRequiredCommission && (
          <InputComtrol
            isRequired={formik.values.isRequiredCommission}
            id={'commission'}
            label={'Comisión'}
            type={'text'}
            value={formik.values.commission}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.commission)}
            errorText={formik.errors.commission}
          />
        )}
        <RenderInputRequired formik={formik} program={program} />
        <RenderVouchersRequired formik={formik} />
        <RenderDebtCertificateRequired
          formik={formik}
          typeProcess={typeProcess}
          currentStepKey={currentStep?.key}
        />
        {formik.values.isRequiredPaymentMonitors && (
          <RequestPaymentMonitors formik={formik} />
        )}
        <DocumentsUniversityOrder formik={formik} />
        <DocumentsSolidarityOrder
          formik={formik}
          program={program}
          typeProcess={typeProcess}
          currentStepKey={currentStep?.key}
        />
        <RenderGeneralDocument formik={formik} program={program} />
        {(formik.values.isRequiredVoucher ||
          formik.values.isRequiredInstructive) && (
          <VoucherIntructive formik={formik} />
        )}
        {currentStep?.key === 'GIFTCARD-ACTIVATION-NEWBORN-BENEFIT' && (
          <GiftcardsProof formik={formik} />
        )}

        {/* General Info */}
        {formik.values.commitmentLetterName &&
          formik.values.commitmentLetterName.length > 0 && (
            <>
              <InputComtrol
                id={'commitmentLetterName'}
                label="Carta de compromiso"
                isRequired={false}
              >
                <UploadFile
                  id={'commitmentLetterName'}
                  direction={'row'}
                  sizeIcon={30}
                  p={5}
                  multiple={true}
                  onChange={(e) => handleChangeCommitmentLetter(e)}
                />
              </InputComtrol>
              <Flex direction={'column'} gap={1}>
                {formik.values.commitmentLetterName.map((item) => (
                  <BarFileName
                    key={item?.name}
                    fileName={item?.name}
                    fileUrl={item?.url}
                    onDelete={() => deleteCommitmentLetterFile(item?.name)}
                  />
                ))}
              </Flex>
            </>
          )}
        {formik.values.isRequiredDocuments && (
          <>
            <InputComtrol
              id={'documentsNames'}
              label={titleDocument || 'Añadir documento'}
              isRequired={formik.values.isRequiredDocuments}
            >
              <UploadFile
                id={'documentsNames'}
                direction={'row'}
                sizeIcon={30}
                p={5}
                multiple={true}
                onChange={(e) => handleChangeDocuments(e)}
                error={Boolean(formik.errors.documentsNames)}
                errorText={formik.errors.documentsNames}
              />
            </InputComtrol>
            <Flex direction={'column'} gap={1}>
              {formik.values.documentsNames.map((item) => (
                <BarFileName
                  key={item?.name}
                  fileName={item?.name}
                  fileUrl={item?.url}
                  onDelete={() => deleteFile(item?.name)}
                />
              ))}
            </Flex>
          </>
        )}

        <TextAreaComtrol
          label={titleObservation || 'Observaciones (opcional)'}
          id={'descriptions'}
          isRequired={false}
          value={formik.values.descriptions}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.descriptions)}
          errorText={formik.errors.descriptions}
        />

        {formik.values.isRequiredMoreDescriptions && (
          <RenderArrayDescriptions formik={formik} />
        )}
        <RenderCheckRequired formik={formik} />
        {!requiredDocuments.includes(currentStep?.key) &&
          currentStep?.key !==
            'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND' &&
          currentStep?.key !==
            'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND-EDIT-SOLIDARITY-HEALTH-FUND' && (
            <Checkbox
              css={{ borderColor: 'green' }}
              id={'isRequiredDocuments'}
              size="md"
              colorScheme="green"
              value={formik.values.isRequiredDocuments}
              isChecked={formik.values.isRequiredDocuments}
              onChange={formik.handleChange}
            >
              ¿Desea adjuntar documentos adicionales?
            </Checkbox>
          )}
        {/* end General Info */}
      </Box>
      <ButtonFotter
        onClose={onCloseEdit}
        isLoading={isLoading}
        onClick={formik.handleSubmit}
      />
    </Flex>
  )
}

export default OtherProcessesEdit
