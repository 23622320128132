import React from 'react'
import { Grid, GridItem, Text } from '@chakra-ui/react'
import { CardFilePreview } from '../../UI'

const Documents = ({ program }) => (
  <>
    <Text fontWeight={700} fontSize={'xl'}>
      Última liquidación de sueldo
    </Text>
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
      py={3}
    >
      {program?.lastSalaryPayment.map((item) => (
        <GridItem>
          <CardFilePreview name={item?.name} url={item.url} />
        </GridItem>
      ))}
    </Grid>

    <Text fontWeight={700} fontSize={'xl'}>
      Copia de tipcar
    </Text>
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
      py={3}
    >
      {program?.tipcar.map((item) => (
        <GridItem>
          <CardFilePreview name={item?.name} url={item.url} />
        </GridItem>
      ))}
    </Grid>
  </>
)

export default Documents
