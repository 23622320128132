import { createIcon } from '@chakra-ui/react'
/* https://freeicons.io/ui-essentials-3/ui-essentials-notification-alarm-bell-icon-44563 */
/* eslint-disable import/prefer-default-export */
const EditIcon = createIcon({
  displayName: 'EditIcon',
  viewBox: '0 0 18 17',
  path: [
    <path
      d="M3.06185 14.5625H4.29102L11.291 7.54165L10.0618 6.33331L3.06185 13.3333V14.5625ZM15.5827 6.35415L11.2493 2.02081L12.3327 0.93748C12.7493 0.520813 13.2596 0.305536 13.8635 0.291647C14.468 0.277758 14.9993 0.493036 15.4577 0.93748L16.7077 2.18748C17.1521 2.63193 17.3674 3.14915 17.3535 3.73915C17.3396 4.3297 17.1174 4.83331 16.6868 5.24998L15.5827 6.35415ZM2.12435 16.75C1.76324 16.75 1.46129 16.6283 1.21852 16.385C0.975182 16.1422 0.853516 15.8472 0.853516 15.5V12.9375C0.853516 12.7569 0.884905 12.5903 0.947682 12.4375C1.0099 12.2847 1.10352 12.1528 1.22852 12.0416L10.0618 3.20831L14.3952 7.54165L5.60352 16.3958C5.47852 16.5208 5.33602 16.6111 5.17602 16.6666C5.01657 16.7222 4.84657 16.75 4.66602 16.75H2.12435ZM10.6868 6.93748L10.0618 6.33331L11.291 7.54165L10.6868 6.93748Z"
      fill={'currentColor' || '#82B378'}
    />
  ]
})

export default EditIcon
