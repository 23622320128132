import { useQuery, useMutation, useQueryClient } from 'react-query'
import {
  getUsers,
  getUsersById,
  createNewUser,
  updateUser,
  deleteUser
} from '../api/Users'

const key = 'users'

const getAction = (type) => {
  if (type === 'CREATE') return createNewUser
  if (type === 'UPDATE') return updateUser
  if (type === 'DELETE') return deleteUser
  return null
}

export function useUsers(filtersUsers, page, limit, props = {}) {
  return useQuery(
    [key, filtersUsers, page, limit],
    () => getUsers(filtersUsers, page, limit),
    {
      ...props
    }
  )
}

export function useUser(partnerId) {
  return useQuery([key, partnerId], () => getUsersById(partnerId), {
    refetchOnWindowFocus: false
  })
}

export function useMutateUser(type) {
  const queryClient = useQueryClient()
  const actionFn = getAction(type)
  return useMutation(actionFn, {
    onSuccess: () => {
      queryClient.invalidateQueries([key])
    }
  })
}
