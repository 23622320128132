import { createIcon } from '@chakra-ui/react'
/* eslint-disable import/prefer-default-export */
const DetailsIcon = createIcon({
  displayName: 'DetailsIcon',
  viewBox: '0 0 36 36',
  path: [
    <path
      d="M32,6H4A2,2,0,0,0,2,8V28a2,2,0,0,0,2,2H32a2,2,0,0,0,2-2V8A2,2,0,0,0,32,6Zm0,22H4V8H32Z"
      class="clr-i-outline clr-i-outline-path-1"
    ></path>,
    <path
      d="M9,14H27a1,1,0,0,0,0-2H9a1,1,0,0,0,0,2Z"
      class="clr-i-outline clr-i-outline-path-2"
    ></path>,
    <path
      d="M9,18H27a1,1,0,0,0,0-2H9a1,1,0,0,0,0,2Z"
      class="clr-i-outline clr-i-outline-path-3"
    ></path>,
    <path
      d="M9,22H19a1,1,0,0,0,0-2H9a1,1,0,0,0,0,2Z"
      class="clr-i-outline clr-i-outline-path-4"
    ></path>,
    <rect x="0" y="0" width="36" height="36" fill-opacity="0" />
  ]
})

export default DetailsIcon
