import React from 'react'
import { Grid, GridItem } from '@chakra-ui/react'
import { InputComtrol, TextAreaComtrol } from '../../UI'

const BeneficiaryInformation = ({ program }) => (
  <>
    <Grid
      templateColumns={{
        lg: 'repeat(2, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem>
        <InputComtrol
          label={'Nombre completo del postulante'}
          value={program?.postulantName}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Promedio general'}
          value={program?.score}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Teléfono de contacto (postulante)'}
          addictionType={'phone'}
          value={program?.phonePostulant}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Grado'}
          value={program?.grade}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Postulación F.U.A.S.'}
          value={program?.fuasRequest}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
      <GridItem>
        <InputComtrol
          label={'Antigüedad dentro del escuadrón'}
          value={program?.antiquity || 'No proporcionado'}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Nombre del comandante de sección'}
          value={program?.commandersName || 'No proporcionado'}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Domicilio'}
          value={program?.addressPostulant}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Correo electrónico'}
          value={program?.emailPostulant}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Unidad'}
          value={program?.unitPostulant}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
    </Grid>
    <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
      <GridItem>
        <InputComtrol
          label={'Estado de arancel de mensualidad'}
          value={program?.monthlyFeeStatement}
          isRequired={false}
          readOnly={true}
        />
        <TextAreaComtrol
          label={
            'Opinión académica y de conducta por parte del comandante de sección'
          }
          value={program?.academicOpinion || 'No hay opinión'}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
    </Grid>
  </>
)

export default BeneficiaryInformation
