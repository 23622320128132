import React from 'react'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton
} from '@chakra-ui/react'
import Button from './Button'

const CustomModal = ({
  isOpen,
  onClose,
  title,
  children,
  onClick,
  disableButtons,
  isLoading,
  size = 'xl',
  iconButton,
  showButtons = true,
  ...props
}) => (
  <Modal
    isCentered
    onClose={onClose}
    isOpen={isOpen}
    motionPreset="slideInBottom"
    size={size}
  >
    <ModalOverlay />
    <ModalContent {...props}>
      <ModalHeader fontSize={26} textAlign={'center'} color={'green.500'}>
        {title}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody overflowY={'scroll'} maxH={600} py={4}>
        {children}
      </ModalBody>
      {showButtons && (
        <ModalFooter>
          <Button
            text={'Cancelar'}
            bg={'beige.500'}
            color={'green.500'}
            bghover={'beige.600'}
            onClick={onClose}
            disabled={disableButtons || isLoading}
            isLoading={isLoading}
          />
          <Button
            icon={iconButton}
            text={'Aceptar'}
            bg={'green.500'}
            onClick={onClick}
            disabled={disableButtons || isLoading}
            isLoading={isLoading}
          />
        </ModalFooter>
      )}
    </ModalContent>
  </Modal>
)

export default CustomModal
