import React from 'react'
import { Grid, GridItem, Text } from '@chakra-ui/react'
import { InputComtrol, CardFilePreview } from '../../UI'
import { FormatDate } from '../../../Utils'
import { NODATA } from '../../../constans'

const BeneficiaryInformation = ({ program }) => (
  <>
    <Grid
      templateColumns={{
        lg: 'repeat(2, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem>
        <InputComtrol
          label={'Nombre completo del postulante'}
          value={program?.postulantName}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Fecha de nacimiento'}
          value={
            program?.dateOfBirth ? FormatDate(program?.dateOfBirth) : NODATA
          }
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Promedio general Educ. Media'}
          value={program?.postulantAverage}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Teléfono de contacto (postulante)'}
          addictionType={'phone'}
          value={program?.postulantPhone}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
      <GridItem>
        <InputComtrol
          label={'Colegio donde estudia'}
          value={program?.schoolName}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Edad'}
          value={program?.postulantAge}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Domicilio'}
          value={program?.postulantAdress}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Correo electrónico'}
          value={program?.postulantEmail}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
    </Grid>
    <Text fontWeight={700}>
      Certificados de concentración de notas de 1°, 2° y 3° medio
    </Text>
    <Grid templateColumns={'repeat(1, 1fr)'} gap={4}>
      {program?.postulantScore.map((item) => (
        <GridItem>
          <CardFilePreview key={item?.name} name={item?.name} url={item?.url} />
        </GridItem>
      ))}
    </Grid>
    <Text fontWeight={700} mt={2}>
      Certificado de nacimiento con indicación de nombres de ambos padres.
    </Text>
    <Grid templateColumns={'repeat(1, 1fr)'} gap={4}>
      {program?.postulantCertificate.map((item) => (
        <GridItem>
          <CardFilePreview key={item?.name} name={item?.name} url={item?.url} />
        </GridItem>
      ))}
    </Grid>
  </>
)

export default BeneficiaryInformation
