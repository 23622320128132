/* eslint-disable import/prefer-default-export */
import { useQuery } from 'react-query'

import { getDiscountsByPartner } from '../api/Discounts'

const key = 'discounts'

export function useDiscounts(filtersDiscounts, page, limit, props = {}) {
  return useQuery(
    [key, filtersDiscounts, page, limit],
    () => getDiscountsByPartner(filtersDiscounts, page, limit),
    {
      ...props
    }
  )
}
