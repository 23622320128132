/* eslint-disable import/prefer-default-export */
import { saveAs } from 'file-saver'
import axios from '../../Axios'
import { module } from './module'

export const getPrograms = async (filtersPrograms, page = 0, limit = 6) => {
  const {
    search,
    category,
    startDate,
    endDate,
    state,
    type,
    program,
    typeCreation,
    step
  } = filtersPrograms
  const { data } = await axios.get(
    `${module}/?page=${page}&limit=${limit}&search=${search}&category=${category}&startDate=${startDate}&endDate=${endDate}&state=${state}&type=${type}&program=${program}&typeCreation=${typeCreation}&step=${step}`
  )
  return data
}

export const getProgramHistory = async (filtersPrograms) => {
  const { data } = await axios.get(`${module}/steps`, {
    params: {
      program: filtersPrograms
    }
  })
  return data
}

export const getProgramById = async (programId, type = null) => {
  const { data } = await axios.get(`${module}/${programId}/?type=${type}`)
  return data
}

export const getProgramsByPartnerPaginate = async (
  filtersFamilyMembers,
  page = 0,
  limit = 6
) => {
  const { id, order } = filtersFamilyMembers
  const { data } = await axios.get(
    `${module}/by-partner/?id=${id}&page=${page}&limit=${limit}&order=${order}`
  )
  return data
}

export const generateTemplateUploadMassiveProgram = async (body) => {
  const response = await axios.post(
    `${module}/get-template-upload-massive`,
    body,
    {
      responseType: 'arraybuffer'
    }
  )
  const dirtyFileName = response.headers['content-disposition']
  const regex =
    /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/
  const fileName = dirtyFileName.match(regex)[3]

  const blob = new Blob([response.data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  })
  saveAs(blob, fileName)

  return response
}
