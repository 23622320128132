import { Box, Drawer, DrawerContent, useDisclosure } from '@chakra-ui/react'
import SidebarContent from '../Navigation/SidebarContent'
import MobileNav from '../Navigation/MobileNav'
import NavigationHeader from '../NavigationHeader'

const Layout = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Box minH="95vh" minW={'95vw'} bg="#FFF">
      <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
      >
        <DrawerContent
          borderTopRightRadius="40"
          borderBottomRightRadius="40"
          bgColor="#F1ECE2"
        >
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <MobileNav display={{ base: 'flex', md: 'none' }} onOpen={onOpen} />
      <NavigationHeader />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {children}
      </Box>
    </Box>
  )
}

export default Layout
