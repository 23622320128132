import React from 'react'
import { Text, Flex } from '@chakra-ui/react'
import Spinner from './Spinner'

const CustomToast = ({ text, status, onClose, showSpinner = true }) => {
  const getColors = () => {
    if (status === 'success') {
      return {
        color: '#2E7423',
        background: '#DFF2BF'
      }
    }
    if (status === 'error') {
      return {
        color: '#7d3d33',
        background: '#FFCDCD'
      }
    }
    if (status === 'warning') {
      return {
        color: '#FF9633',
        background: '#FFF8DC'
      }
    }
    if (status === 'info') {
      return {
        color: '#00769C',
        background: '#D1F1FF'
      }
    }
    return {
      color: '#2E7423',
      background: '#DFF2BF'
    }
  }

  return (
    <Flex
      mx={3}
      px={4}
      py={2}
      color={getColors().color}
      bg={getColors().background}
      borderRadius={10}
      align="center"
      justify="center"
      gap={2}
      onClick={onClose}
    >
      {showSpinner && (
        <Spinner
          size={'xs'}
          thickness={'2px'}
          color={getColors().color}
          show={true}
        />
      )}
      <Text fontWeight={700}>{text}</Text>
    </Flex>
  )
}

export default CustomToast
