import React from 'react'
import { Flex, Icon, Text } from '@chakra-ui/react'
import { PlusIcon } from '../Icons'

const ButtonDotted = ({
  w = {
    lg: '50%',
    md: '100%',
    sm: '100%'
  },
  p = 5,
  icon = PlusIcon,
  text,
  disabled = false,
  onClick
}) => (
  <Flex
    w={w}
    align={'center'}
    justify={'center'}
    _hover={
      !disabled
        ? { cursor: 'pointer', bg: 'beige.500' }
        : { cursor: 'not-allowed' }
    }
    borderRadius={20}
    border={'2px dotted'}
    color={'green.100'}
    p={p}
    gap={2}
    onClick={!disabled ? onClick : null}
  >
    <Icon as={icon} />
    <Text fontWeight={700}>{text}</Text>
  </Flex>
)

export default ButtonDotted
