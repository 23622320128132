import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Flex, Text } from '@chakra-ui/react'
import { Button, CustomModal, InputComtrol, TextAreaComtrol } from '../../UI'
import { EditIcon } from '../../Icons'
import GetStatusCard from '../../../Utils/GetStatusCard'
import { CondicionalModal } from '../../../Utils'
import { useAdditionalInformation } from '../../../hooks/AdditionalInformation'
import { useTypeModalView, useTypeModal } from '../../../hooks/TypeModal'
import AvatarNameUser from '../AvatarNameUser'
import RenderDocument from './RenderDocument'
import RernderSelectedOption from './RernderSelectedOption'
import RenderCurrentStateStep from './RenderCurrentStateStep'
import OtherProcessesEdit from './OtherProcessesEdit'
import RenderMoreDescriptions from './RenderMoreDescriptions'
import RenderInput from './RenderInput'
import RenderCheck from './RenderCheck'
import RenderCommission from '../ActionStep/OtherProcesses/RenderCommission'
import {
  transferredAmountName,
  usedAmountName
} from '../ActionStep/OtherProcesses/RenderInputRequired'
import { getCanEdit } from './OtherProcessesEdit/canEdit'

const DetailsAdditionalInformations = ({
  currentStep,
  program,
  isOpen,
  onClose
}) => {
  const { user } = useSelector((state) => state.auth)
  const canEditData = getCanEdit({
    steps: program?.steps,
    typeProgram: program?.type
  })
  const { requiredTransferredAmount, requiredUsedAmount } = CondicionalModal
  const [modalPage, setModalPage] = useState(1)
  const TRANSFERREDAMOUNTLABEL =
    transferredAmountName[currentStep?.key] || 'Monto transferido'
  const USEDAMOUNTLABEL =
    usedAmountName[currentStep?.key] || 'Monto transferido'
  const getTitleInvoice = () => {
    if (
      currentStep?.key ===
      'INFORM-THE-PAYMENT-MADE-MULTI-SOLIDARITY-HEALTH-FUND'
    ) {
      return 'Certificado de pago enviado por Dipreca'
    }
    return 'Factura'
  }

  const getTitleQuotesReceived = () => {
    const additionalInformation = program?.additionalInformation.filter(
      (item) => item.key === 'ADD-DEBT-CERTIFICATE'
    )
    if (additionalInformation.length > 0) {
      return 'Acta resolución de comisión'
    }

    const additionalInformation2 = program?.additionalInformation.filter(
      (item) => item.key === 'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND'
    )
    if (additionalInformation2.length > 0) {
      const additionalInformation3 = program?.additionalInformation.filter(
        (item) => item.key === 'COMMISSION-PREPARATION-SOLIDARITY-HEALTH-FUND'
      )
      if (
        additionalInformation3.length > 0 &&
        additionalInformation3[0].tariffDifference &&
        additionalInformation3[0].service !== 'Dipreca'
      ) {
        return 'Certificado de deuda'
      }
    }

    return 'Cotizaciones recibidas'
  }

  const { title, titleDocument, titleObservation } = useTypeModalView(
    currentStep?.key
  )
  const { title: titleEdit, isEditable } = useTypeModal(currentStep?.key)
  const { data, isLoading, isFetching } = useAdditionalInformation(
    program?._id,
    currentStep?.key,
    currentStep?.step
  )
  const translateKeys = {
    'NOT-AUTHORIZED': 'No autorizado',
    AUTORIZED: 'Autorizado',
    BACK: 'Retroceder proceso',
    FINISH: 'Finalizar proceso',
    APPROVED: 'Aprobado',
    REJECTED: 'Rechazado',
    EDIT: 'A corregir',
    'ADD-NEW-SEMESTERS': 'Continuar con el siguiente semestre',
    FINALIZED: 'Finalizar proceso'
  }

  const getTypeProcess = () => {
    const additionalInformation = program?.additionalInformation.filter(
      (item) => item.key === 'COMMISSION-PREPARATION-SOLIDARITY-HEALTH-FUND'
    )

    if (additionalInformation.length > 0) {
      return {
        medicines: additionalInformation[0]?.medicines,
        supplies: additionalInformation[0]?.supplies,
        tariffDifference: additionalInformation[0]?.tariffDifference,
        milkFormula: additionalInformation[0]?.milkFormula,
        others: additionalInformation[0]?.others,
        service: additionalInformation[0]?.service,
        otherService: additionalInformation[0]?.otherService,
        commission: additionalInformation[0]?.commission
      }
    }

    return {
      medicines: false,
      supplies: false,
      tariffDifference: false,
      milkFormula: false,
      others: false,
      service: null,
      otherService: null,
      commission: null
    }
  }

  const typeProcess = getTypeProcess()

  return (
    <>
      {!isLoading && !isFetching && (
        <CustomModal
          isOpen={isOpen}
          onClose={onClose}
          onClick={onClose}
          title={modalPage === 1 ? title : titleEdit}
          size={'2xl'}
          showButtons={false}
        >
          {modalPage === 1 && (
            <>
              <Flex gap={4} direction={'column'} pb={10}>
                <AvatarNameUser
                  name={data?.createby?.name}
                  lastName={data?.createby?.lastName}
                  dateNotification={data?.createdAt}
                />
                <Flex gap={4} align={'center'}>
                  <Text fontWeight={700}>Status de solicitud</Text>
                  {GetStatusCard.program(program?.state)}
                </Flex>
                {currentStep?.incompleteStep && <RenderCurrentStateStep />}
                {data?.officeStatus && (
                  <RernderSelectedOption
                    title={'Estado de Oficio'}
                    variable={data?.officeStatus}
                  />
                )}

                {data?.orderAuthorization && (
                  <RernderSelectedOption
                    title={'Estado de Autorización'}
                    variable={data?.orderAuthorization}
                  />
                )}
                {data?.paymentVerification && (
                  <RernderSelectedOption
                    title={'Pago'}
                    variable={data?.paymentVerification}
                  />
                )}
                {data?.validationOffice && (
                  <RernderSelectedOption
                    title={'Validación de Oficio'}
                    variable={data?.validationOffice}
                  />
                )}
                {data?.verificationOfDocuments && (
                  <RernderSelectedOption
                    title={'Verificacion de documentos'}
                    variable={data?.verificationOfDocuments}
                  />
                )}
                {data?.proposedState && (
                  <RernderSelectedOption
                    title={'Sugerencia de estado'}
                    variable={data?.proposedState}
                  />
                )}
                {data?.amount && (
                  <RenderInput title={'Monto Aprobado:'} value={data?.amount} />
                )}
                {data?.reason && (
                  <RenderInput
                    title={'Razón de Terminación de postulación'}
                    value={data?.reason}
                  />
                )}
                {data?.alterProcess && (
                  <Flex gap={4} align={'center'}>
                    <Text fontWeight={700}>Paso seleccionado:</Text>
                    {translateKeys[data?.alterProcess]}
                  </Flex>
                )}
                {data?.responseQuestionNewSemesters && (
                  <Flex gap={4} align={'center'}>
                    <Text fontWeight={700}>Paso seleccionado:</Text>
                    {translateKeys[data?.responseQuestionNewSemesters]}
                  </Flex>
                )}

                {data?.newExitDate && (
                  <>
                    <RenderInput
                      title={'Fecha de Salida'}
                      value={data?.newExitDate}
                      type={'date'}
                    />
                    {data?.exitHour && (
                      <Flex gap={2} direction={'column'}>
                        <Text fontWeight={700}>Hora</Text>
                        {`${data?.exitHour} : ${data?.exitMinute} ${data?.exitPeriod}`}
                      </Flex>
                    )}
                  </>
                )}
                {data?.extensionDays && (
                  <RenderInput
                    title={'Días de extensión'}
                    value={data?.extensionDays}
                  />
                )}
                {data?.key ===
                  'FIRST-EXTENSION-OR-OUTLET-HOSPITAL-ACCOMMODATION' ||
                  (data?.key ===
                    'ANY-EXTENSION-OR-OUTLET-HOSPITAL-ACCOMMODATION' && (
                    <RenderCheck
                      title={'¿Solicitud de Extensión?'}
                      value={data?.isExtension}
                    />
                  ))}
                {data?.key ===
                  'COMMISSION-PREPARATION-SOLIDARITY-HEALTH-FUND' && (
                  <>
                    <RenderInput title={'Comisión'} value={data?.commission} />
                    <Text fontWeight={700}>Proceso</Text>
                    <RenderCheck
                      title={'Medicamentos'}
                      value={data?.medicines}
                    />
                    <RenderCheck title={'Insumos'} value={data?.supplies} />
                    <RenderCheck
                      title={'Formula lactea'}
                      value={data?.milkFormula}
                    />
                    <RenderCheck
                      title={'Diferencia de arancel'}
                      value={data?.tariffDifference}
                    />
                    <RenderCheck title={'Otros'} value={data?.others} />
                    {data?.tariffDifference && (
                      <RenderInput title={'Servicio'} value={data?.service} />
                    )}
                    {data?.service && data?.service === 'Otras' && (
                      <RenderInput
                        title={'Otro servicio'}
                        value={data?.otherService}
                      />
                    )}
                  </>
                )}
                {data?.key ===
                  'ALL-INVOICES-AND-TRADES-SOLIDARITY-HEALTH-FUND' &&
                  typeProcess.others && (
                    <>
                      <RenderCommission program={program} />
                      <Text fontWeight={700}>Proceso</Text>
                      <RenderCheck
                        title={'Medicamentos'}
                        value={data?.medicines}
                      />
                      <RenderCheck title={'Insumos'} value={data?.supplies} />
                      <RenderCheck
                        title={'Formula lactea'}
                        value={data?.milkFormula}
                      />
                      <RenderCheck
                        title={'Diferencia de arancel'}
                        value={data?.tariffDifference}
                      />
                      <RenderCheck
                        title={'Prestaciones asistenciales'}
                        value={data?.assistanceBenefits}
                      />
                      <RenderCheck title={'Otros'} value={data?.others} />
                      {data?.tariffDifference && (
                        <RenderInput title={'Servicio'} value={data?.service} />
                      )}
                      {data?.service && data?.service === 'Otras' && (
                        <RenderInput
                          title={'Otro servicio'}
                          value={data?.otherService}
                        />
                      )}
                    </>
                  )}

                {data?.key ===
                  'INFORM-THE-PAYMENT-MADE-SOLIDARITY-HEALTH-FUND' && (
                  <>
                    <RenderCheck
                      title={'Comprobantes enviados'}
                      value={data?.vouchersSend}
                    />
                    <RenderCheck
                      title={'Se solicitó factura'}
                      value={data?.invoiceRequest}
                    />
                  </>
                )}

                {data?.dateSubmissionReceipts && (
                  <RenderInput
                    title={'Fecha de envío de comprobantes'}
                    value={data?.dateSubmissionReceipts}
                    type={'date'}
                  />
                )}
                {data?.dateSubmissionReceiptsArr && (
                  <>
                    {data?.dateSubmissionReceiptsArr?.map((item, index) => (
                      <RenderInput
                        key={index}
                        title={`Fecha de envío de comprobantes ${index + 1}`}
                        value={item}
                        type={'date'}
                      />
                    ))}
                  </>
                )}

                {data?.dateSend && (
                  <RenderInput
                    title={'Fecha de envío'}
                    value={data?.dateSend}
                    type={'date'}
                  />
                )}

                {data?.dateCertificateRequest && (
                  <RenderInput
                    title={'Fecha de solicitud de certificado'}
                    value={data?.dateCertificateRequest}
                    type={'date'}
                  />
                )}

                {data?.dateCertificateReception && (
                  <RenderInput
                    title={'Fecha de recepción de certificado'}
                    value={data?.dateCertificateReception}
                    type={'date'}
                  />
                )}

                {data?.dateDelivery && (
                  <RenderInput
                    title={'Fecha de Entrega'}
                    value={data?.dateDelivery}
                    type={'date'}
                  />
                )}
                {data?.entryDate && (
                  <>
                    <RenderInput
                      title={'Fecha de Entrada'}
                      value={data?.entryDate}
                      type={'date'}
                    />
                    <Flex gap={2} direction={'column'}>
                      <Text fontWeight={700}>Hora</Text>
                      {`${data?.entryHour} : ${data?.entryMinute} ${data?.entryPeriod}`}
                    </Flex>
                  </>
                )}
                {data?.totalDays && (
                  <RenderInput
                    title={'Total de días hospedado'}
                    value={data?.totalDays}
                    type={'number'}
                  />
                )}

                {requiredTransferredAmount?.includes(data?.key) && (
                  <Flex gap={1} direction={'column'}>
                    <Text fontWeight={700}>{TRANSFERREDAMOUNTLABEL}</Text>
                    <InputComtrol
                      isRequired={false}
                      value={`$  ${data?.transferredAmount}`}
                      readOnly={true}
                    />
                  </Flex>
                )}
                {requiredUsedAmount?.includes(data?.key) && (
                  <Flex gap={1} direction={'column'}>
                    <Text fontWeight={700}>{USEDAMOUNTLABEL}</Text>
                    <InputComtrol
                      isRequired={false}
                      value={`$  ${data?.usedAmount}`}
                      readOnly={true}
                    />
                  </Flex>
                )}
                {data?.giftcardActive && (
                  <RenderCheck
                    title={'¿La giftcard se encuentra activa?'}
                    value={data?.giftcardActive}
                  />
                )}

                {data?.commitmentLetter?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Carta de  compromiso'}
                    arrayDocuments={data?.commitmentLetter}
                  />
                )}
                {data?.depositReceipt?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Recepción deposito'}
                    arrayDocuments={data?.depositReceipt}
                  />
                )}
                {data?.reportedEmail?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Correos respaldados'}
                    arrayDocuments={data?.reportedEmail}
                  />
                )}
                {data?.voucher?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Comprobante de depósito'}
                    arrayDocuments={data?.voucher}
                  />
                )}
                {data?.stockOffice?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Oficio inventario'}
                    arrayDocuments={data?.stockOffice}
                  />
                )}
                {data?.deliveryProof?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Cuadro demostrativo de entrega'}
                    arrayDocuments={data?.deliveryProof}
                  />
                )}
                {data?.documents?.length > 0 && (
                  <RenderDocument
                    titleDocument={titleDocument || 'Documentos adjuntos'}
                    arrayDocuments={data?.documents}
                  />
                )}
                {data?.classSchedule?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Calendario de clases'}
                    arrayDocuments={data?.classSchedule}
                  />
                )}
                {data?.feeBill?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Boleta de honorarios'}
                    arrayDocuments={data?.feeBill}
                  />
                )}
                {data?.attendanceSheet?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Planilla de asistencia'}
                    arrayDocuments={data?.attendanceSheet}
                  />
                )}
                {data?.instructive?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Instructivo de rendición'}
                    arrayDocuments={data?.instructive}
                  />
                )}
                {data?.amountCancel?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Documento de monto a cancelar'}
                    arrayDocuments={data?.amountCancel}
                  />
                )}
                {data?.purchaseDetail?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Documento de detalle de compra'}
                    arrayDocuments={data?.purchaseDetail}
                  />
                )}

                {data?.backupFeeAmount?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Correo de respaldo con el monto de arancel'}
                    arrayDocuments={data?.backupFeeAmount}
                  />
                )}
                {data?.scholarshipCommission?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Acta de comisión de becas'}
                    arrayDocuments={data?.scholarshipCommission}
                  />
                )}
                {data?.bankData?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Documento con los datos bancarios'}
                    arrayDocuments={data?.bankData}
                  />
                )}
                {data?.office?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Oficio'}
                    arrayDocuments={data?.office}
                  />
                )}
                {data?.invoice?.length > 0 && (
                  <RenderDocument
                    titleDocument={getTitleInvoice()}
                    arrayDocuments={data?.invoice}
                  />
                )}
                {data?.tariffDifferenceDocuments?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Certificado de pago enviado por Dipreca'}
                    arrayDocuments={data?.tariffDifferenceDocuments}
                  />
                )}
                {data?.acta?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Acta de comisión'}
                    arrayDocuments={data?.acta}
                  />
                )}
                {data?.boxPurchase?.length > 0 && (
                  <RenderDocument
                    titleDocument={
                      typeProcess.tariffDifference &&
                      typeProcess.service === 'Dipreca'
                        ? 'Cuadro Dipreca'
                        : 'Cuadro de compra'
                    }
                    arrayDocuments={data?.boxPurchase}
                  />
                )}
                {data?.boxPurchaseTwo?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Cuadros de compra'}
                    arrayDocuments={data?.boxPurchaseTwo}
                  />
                )}
                {data?.purchaseRequestLetter?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Oficio de solicitud de orden de compra'}
                    arrayDocuments={data?.purchaseRequestLetter}
                  />
                )}
                {data?.quotesReceived?.length > 0 && (
                  <RenderDocument
                    titleDocument={getTitleQuotesReceived()}
                    arrayDocuments={data?.quotesReceived}
                  />
                )}
                {data?.debtCertificate?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Certificados de deudas'}
                    arrayDocuments={data?.debtCertificate}
                  />
                )}
                {data?.purchaseRequestLetterSigned?.length > 0 && (
                  <RenderDocument
                    titleDocument={
                      'Oficio de solicitud de orden de compra firmada'
                    }
                    arrayDocuments={data?.purchaseRequestLetterSigned}
                  />
                )}
                {data?.documentsSentMedicalInstitution?.length > 0 && (
                  <RenderDocument
                    titleDocument={
                      typeProcess.tariffDifference &&
                      typeProcess.service === 'Dipreca'
                        ? 'Certificados de deuda enviado por Dipreca'
                        : 'Documentos enviados por la institución médica'
                    }
                    arrayDocuments={data?.documentsSentMedicalInstitution}
                  />
                )}
                <Flex gap={4} direction={'column'}>
                  <TextAreaComtrol
                    label={titleObservation || 'Observaciones'}
                    value={data?.descriptions || 'No hay observaciones'}
                    isRequired={false}
                    readOnly={true}
                  />
                </Flex>
                {data?.moreDescriptions?.length > 0 && (
                  <RenderMoreDescriptions
                    moreDescriptions={data?.moreDescriptions}
                  />
                )}
                {currentStep?.blockedStep && (
                  <Text fontWeight={700} color="orange.500">
                    Ya no se permite editar este paso para agregar mas
                    documentos.
                  </Text>
                )}
              </Flex>
              {isEditable && user.role !== 'REVISION' && (
                <Flex justify={'flex-end'} mb={2}>
                  <Button
                    icon={EditIcon}
                    text={currentStep?.incompleteStep ? 'Completar' : 'Editar'}
                    py={2}
                    px={2}
                    onClick={() => setModalPage(2)}
                    disabled={
                      currentStep?.blockedStep ||
                      (!canEditData?.can &&
                        canEditData?.keyStep !== currentStep.key &&
                        canEditData?.numberStep > currentStep.step)
                    }
                  />
                </Flex>
              )}
              {!canEditData.can &&
                canEditData?.keyStep !== currentStep.key &&
                canEditData?.numberStep > currentStep.step && (
                  <Text fontWeight={700} color="orange.500">
                    {canEditData.msg}
                  </Text>
                )}
            </>
          )}
          {modalPage === 2 && (
            <OtherProcessesEdit
              currentStep={currentStep}
              program={program}
              additionalData={data}
              onCloseEdit={onClose}
            />
          )}
        </CustomModal>
      )}
    </>
  )
}

export default DetailsAdditionalInformations
