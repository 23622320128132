import React from 'react'
import { Flex, Text, Icon, Grid, GridItem, Tooltip } from '@chakra-ui/react'
import { WspIcon, EmailIcon } from '../../../Icons'

const EmailWspContainer = ({ program, formik }) => {
  const showPostulantByProgram = [
    'PreUniversityScholarships',
    'TechnicalProfessionalScholarships',
    'UniversityScholarships'
  ]

  const renderItem = (sendTo) => (
    <>
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        w={'100%'}
      >
        <GridItem>
          <Text fontWeight={700} fontSize={'sm'} marginRight={'auto'}>
            Enviar a {sendTo}
          </Text>
        </GridItem>
        <GridItem>
          <Flex w={'100%'} align={'center'} gap={2}>
            <Flex direction={'column'} justify={'flex-start'} w={'50%'}>
              <Flex align={'center'} gap={1}>
                <Icon as={EmailIcon} size={'sm'} />
                <Text fontWeight={700} fontSize={'sm'} color={'green.500'}>
                  Correo electrónico
                </Text>
              </Flex>
              {sendTo === 'Socio' && (
                <Text fontSize={'xs'} color={'grey.500'}>
                  {program?.partner?.email || 'No disponible'}
                </Text>
              )}
              {sendTo === 'Postulante' && (
                <Text fontSize={'xs'} color={'grey.500'}>
                  {program?.email || program?.postulantEmail || 'No disponible'}
                </Text>
              )}
            </Flex>

            {/* --- */}

            <Flex direction={'column'} justify={'flex-start'} w={'50%'}>
              <Flex align={'center'} gap={1}>
                <Icon as={WspIcon} size={'sm'} />
                <Text fontWeight={700} fontSize={'sm'} color={'green.500'}>
                  SMS
                </Text>
              </Flex>
              {sendTo === 'Socio' && (
                <>
                  <Text fontSize={'xs'} color={'grey.500'}>
                    {program?.partner?.phone
                      ? `+569 ${program?.partner?.phone}`
                      : 'No disponible'}{' '}
                  </Text>
                  <Text fontSize={'xs'} color={'grey.500'}>
                    {program?.alternativePhone
                      ? `+569 ${program?.alternativePhone}`
                      : 'No disponible'}
                  </Text>
                </>
              )}
              {/* --- */}
              {sendTo === 'Postulante' && (
                <>
                  <Text fontSize={'xs'} color={'grey.500'}>
                    {program?.phonePostulant || program?.postulantPhone
                      ? `+569 ${
                          program?.phonePostulant || program?.postulantPhone
                        }`
                      : 'No disponible'}{' '}
                  </Text>
                  <Text fontSize={'xs'} color={'grey.500'}>
                    {program?.alternativePhonePostulant ||
                    program?.alternativePhone
                      ? `+569 ${
                          program?.alternativePhone || program?.alternativePhone
                        }`
                      : 'No disponible'}
                  </Text>
                </>
              )}
            </Flex>
          </Flex>
        </GridItem>
      </Grid>
    </>
  )

  return (
    <Tooltip
      hasArrow
      label={`Lista de correos vacía, favor actualizar datos del socio.`}
      bg={'yellow.500'}
      placement={'top'}
      isOpen={formik.values.emails === '[]' && !formik.values.sendOnlyWsp}
    >
      <Flex direction={'column'} gap={2}>
        <Flex direction={'column'}>
          <Text fontWeight={700} fontSize={'xl'} marginRight={'auto'}>
            Mensaje a enviar
          </Text>
          <Text fontSize={'sm'} marginRight={'auto'}>
            Vía SMS y correo electrónico
          </Text>
        </Flex>
        {renderItem('Socio')}
        {showPostulantByProgram.includes(program.type) &&
          renderItem('Postulante')}
      </Flex>
    </Tooltip>
  )
}

export default EmailWspContainer
