import React, { useEffect } from 'react'
import { addDays } from 'date-fns'
import { useFormik } from 'formik'
import { Grid, GridItem, Text } from '@chakra-ui/react'
import { InputComtrol, CustomDatePicker, Select } from '../../UI'
import {
  ClearFormatMoney,
  CohabitantsList,
  FormatMoney,
  GetAge,
  ScoreAvg,
  YearOfStudy
} from '../../../Utils'
import { steepTwoSchema } from './Schemas'

const StepTwo = ({ stepTwoForm, setStepTwoForm, refStepTwo, next }) => {
  const initialValues = {
    postulantName: '',
    phonePostulant: '',
    dateOfBirth: '',
    currentInstitution: '',
    institutionToStudy: '',
    currentYear: YearOfStudy.at(0),
    annualFee: '',
    addressPostulant: '',
    email: '',
    age: '',
    institutionCareer: '',
    scoreAverage: ScoreAvg.at(0),
    livesWith: CohabitantsList.at(0),
    isRequiredSpecification: false,
    specification: ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: steepTwoSchema,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: (values) => {
      setStepTwoForm({
        ...values
      })
      next()
    }
  })

  const handleChangeDateOfBirth = (date) => {
    formik.setValues({
      ...formik.values,
      dateOfBirth: date,
      age: GetAge(date).toString() || ''
    })
  }

  const handleChangeLivesWith = (value) => {
    formik.setValues({
      ...formik.values,
      livesWith: value,
      isRequiredSpecification: value === 'Otro'
    })
  }

  const formatAnnualFee = () => {
    const format = FormatMoney(ClearFormatMoney(formik.values.annualFee))
    formik.setFieldValue('annualFee', format)
  }

  const handleChangeScoreAverage = (value) => {
    formik.setFieldValue('scoreAverage', value)
  }

  const handleChangeCurrentYear = (value) => {
    formik.setFieldValue('currentYear', value)
  }

  useEffect(() => {
    refStepTwo.current = formik.handleSubmit
  }, [])

  useEffect(() => {
    formik.setValues({
      postulantName: stepTwoForm.postulantName || '',
      phonePostulant: stepTwoForm.phonePostulant || '',
      dateOfBirth: stepTwoForm.dateOfBirth || '',
      currentInstitution: stepTwoForm.currentInstitution || '',
      institutionToStudy: stepTwoForm.institutionToStudy || '',
      currentYear: stepTwoForm.currentYear || YearOfStudy.at(0),
      annualFee: stepTwoForm.annualFee || '',
      addressPostulant: stepTwoForm.addressPostulant || '',
      email: stepTwoForm.email || '',
      age: stepTwoForm.age || '',
      institutionCareer: stepTwoForm.institutionCareer || '',
      scoreAverage: stepTwoForm.scoreAverage || ScoreAvg.at(0),
      livesWith: stepTwoForm.livesWith || CohabitantsList.at(0),
      isRequiredSpecification: Boolean(stepTwoForm.specification),
      specification: stepTwoForm.specification || ''
    })
  }, [stepTwoForm])

  return (
    <>
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
        <GridItem>
          <Text fontWeight={700} color={'green.500'} fontSize={'xl'}>
            Información del postulante a beca
          </Text>
          <InputComtrol
            id={'postulantName'}
            label={'Nombre completo'}
            value={formik.values.postulantName}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.postulantName)}
            errorText={formik.errors.postulantName}
          />
        </GridItem>
      </Grid>
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          <InputComtrol
            id={'phonePostulant'}
            label={'Teléfono'}
            type={'number'}
            addictionType={'phone'}
            value={formik.values.phonePostulant}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.phonePostulant)}
            errorText={formik.errors.phonePostulant}
          />
          <InputComtrol id={'dateOfBirth'} label={'Fecha de nacimiento'}>
            <CustomDatePicker
              placeholder={'DD/MM/AAAA'}
              currentDate={formik.values.dateOfBirth}
              error={Boolean(formik.errors.dateOfBirth)}
              errorText={formik.errors.dateOfBirth}
              onChange={handleChangeDateOfBirth}
              maxDate={addDays(new Date(), 0)}
            />
          </InputComtrol>
          <InputComtrol
            id={'currentInstitution'}
            label={'Institución donde estudia'}
            value={formik.values.currentInstitution}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.currentInstitution)}
            errorText={formik.errors.currentInstitution}
          />
        </GridItem>
        <GridItem>
          <InputComtrol
            id={'email'}
            label={'Email'}
            type={'email'}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.email)}
            errorText={formik.errors.email}
          />
          <InputComtrol
            id={'age'}
            label={'Edad'}
            type={'number'}
            value={formik.values.age}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.age)}
            errorText={formik.errors.age}
            disabled={true}
          />
          <InputComtrol
            id={'institutionCareer'}
            label={'Carrera'}
            value={formik.values.institutionCareer}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.institutionCareer)}
            errorText={formik.errors.institutionCareer}
          />
        </GridItem>
      </Grid>
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
        <GridItem>
          <InputComtrol
            id={'institutionToStudy'}
            label={'Sede en que desea estudiar'}
            value={formik.values.institutionToStudy}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.institutionToStudy)}
            errorText={formik.errors.institutionToStudy}
          />
        </GridItem>
      </Grid>
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          <InputComtrol id={'currentYear'} label={'Semestre que cursa'}>
            <Select
              bg={'white'}
              list={YearOfStudy}
              value={formik.values.currentYear}
              onChange={handleChangeCurrentYear}
            />
          </InputComtrol>
          <InputComtrol
            id={'annualFee'}
            label={'Arancel anual'}
            value={formik.values.annualFee}
            type={'currency'}
            onChange={formik.handleChange}
            onBlur={formatAnnualFee}
            error={Boolean(formik.errors.annualFee)}
            errorText={formik.errors.annualFee}
          />
        </GridItem>
        <GridItem>
          <InputComtrol id={'scoreAverage'} label={'Promedio de notas'}>
            <Select
              bg={'white'}
              list={ScoreAvg}
              value={formik.values.scoreAverage}
              onChange={handleChangeScoreAverage}
            />
          </InputComtrol>
        </GridItem>
      </Grid>
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
        <GridItem>
          <InputComtrol
            id={'addressPostulant'}
            label={'Domicilio'}
            value={formik.values.addressPostulant}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.addressPostulant)}
            errorText={formik.errors.addressPostulant}
          />
          <InputComtrol label={'¿Con quién vive?'}>
            <Select
              list={CohabitantsList}
              value={formik.values.livesWith}
              onChange={handleChangeLivesWith}
              bg={'white'}
            />
          </InputComtrol>
          {formik.values.isRequiredSpecification && (
            <InputComtrol
              id={'specification'}
              label={'Especifique'}
              value={formik.values.specification}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.specification)}
              errorText={formik.errors.specification}
            />
          )}
        </GridItem>
      </Grid>
    </>
  )
}

export default StepTwo
