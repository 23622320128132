import React from 'react'
import { Checkbox, Flex } from '@chakra-ui/react'

const RenderCheckRequired = ({ formik }) => (
  <Flex direction={'column'} gap={2} my={2}>
    {formik.values.isRequiredGiftCardActive && (
      <Checkbox
        css={{ borderColor: 'green' }}
        id={'giftcardActive'}
        size="md"
        colorScheme="green"
        value={formik.values.giftcardActive}
        isChecked={formik.values.giftcardActive}
        onChange={formik.handleChange}
      >
        ¿La giftcard se encuentra activa?
      </Checkbox>
    )}
  </Flex>
)

export default RenderCheckRequired
