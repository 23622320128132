import { Flex, Text } from '@chakra-ui/react'
import { Step, Steps, useSteps } from 'chakra-ui-steps'
import React from 'react'
import { useTypeModal } from '../../../../../hooks/TypeModal'
import { ArrowUpIcon, CircleIcon } from '../../../../Icons'
import StepItem from './StepItem'

const Confirmation = ({ stepsTitlesProgram, currentStep }) => {
  const { questionConfirm, detailsConfirm } = useTypeModal(currentStep?.key)
  const { activeStep } = useSteps({
    initialStep: 0
  })

  const steps = [
    {
      label: 1,
      opacity: 1,
      icon: ArrowUpIcon,
      content: <StepItem title={stepsTitlesProgram.currentStep} />
    },
    {
      label: 2,
      icon: CircleIcon,
      opacity: 0.5,
      content: <StepItem title={stepsTitlesProgram.lastStep} />
    }
  ]

  return (
    <Flex
      direction={'column'}
      align={'center'}
      justify={'center'}
      textAlign={'center'}
      gap={2}
    >
      <Text fontWeight={700} fontSize={'22px'} color={'green.500'}>
        {questionConfirm}
      </Text>
      <Text fontSize={'18px'}>{detailsConfirm}</Text>
      <Flex direction={'column'} justify={'center'} my={3} px={7}>
        <Steps
          activeStep={activeStep}
          orientation="vertical"
          responsive
          mb={{
            lg: 5,
            md: 0
          }}
          colorScheme={'green'}
        >
          {steps.map(({ label, content, icon, opacity }) => (
            <Step key={label} label={content} opacity={opacity} icon={icon} />
          ))}
        </Steps>
      </Flex>
    </Flex>
  )
}

export default Confirmation
