import { createIcon } from '@chakra-ui/react'
/* https://freeicons.io/ui-essentials-3/ui-essentials-notification-alarm-bell-icon-44563 */
/* eslint-disable import/prefer-default-export */
const AddFileWhiteIcon = createIcon({
  displayName: 'AddFileWhiteIcon',
  viewBox: '0 0 30 30',

  path: [
    <path
      d="M20.6252 18.125C20.6252 18.6223 20.4276 19.0992 20.076 19.4509C19.7244 19.8025 19.2475 20 18.7502 20H16.8752V21.875C16.8752 22.3723 16.6776 22.8492 16.326 23.2008C15.9744 23.5525 15.4975 23.75 15.0002 23.75C14.5029 23.75 14.026 23.5525 13.6744 23.2008C13.3227 22.8492 13.1252 22.3723 13.1252 21.875V20H11.2502C10.7529 20 10.276 19.8025 9.92436 19.4509C9.57273 19.0992 9.37518 18.6223 9.37518 18.125C9.37518 17.6277 9.57273 17.1508 9.92436 16.7992C10.276 16.4476 10.7529 16.25 11.2502 16.25H13.1252V14.375C13.1252 13.8777 13.3227 13.4008 13.6744 13.0492C14.026 12.6976 14.5029 12.5 15.0002 12.5C15.4975 12.5 15.9744 12.6976 16.326 13.0492C16.6776 13.4008 16.8752 13.8777 16.8752 14.375V16.25H18.7502C19.2475 16.25 19.7244 16.4476 20.076 16.7992C20.4276 17.1508 20.6252 17.6277 20.6252 18.125ZM27.5002 10.1963V23.125C27.4982 24.9478 26.7732 26.6953 25.4843 27.9842C24.1955 29.2731 22.4479 29.998 20.6252 30H9.37518C7.55243 29.998 5.8049 29.2731 4.51602 27.9842C3.22713 26.6953 2.50217 24.9478 2.50018 23.125V6.87502C2.50217 5.05227 3.22713 3.30474 4.51602 2.01586C5.8049 0.726976 7.55243 0.00201009 9.37518 2.48763e-05H17.3039C18.2071 -0.00241271 19.1018 0.174313 19.9363 0.519974C20.7707 0.865634 21.5283 1.37336 22.1652 2.01377L25.4864 5.33503C26.1268 5.97192 26.6346 6.72951 26.9802 7.56394C27.3259 8.39838 27.5026 9.29308 27.5002 10.1963ZM23.7502 23.125V11.25H18.7502C18.0871 11.25 17.4513 10.9866 16.9824 10.5178C16.5136 10.049 16.2502 9.41307 16.2502 8.75002V3.75002H9.37518C8.54638 3.75002 7.75153 4.07926 7.16547 4.66532C6.57942 5.25137 6.25018 6.04622 6.25018 6.87502V23.125C6.25018 23.9538 6.57942 24.7487 7.16547 25.3347C7.75153 25.9208 8.54638 26.25 9.37518 26.25H20.6252C21.454 26.25 22.2488 25.9208 22.8349 25.3347C23.4209 24.7487 23.7502 23.9538 23.7502 23.125Z"
      fill="#FFFFFF"
      stroke="#FFFFFF"
      strokeWidth="1.5"
    />
  ]
})

export default AddFileWhiteIcon
