import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Flex, Button, Icon } from '@chakra-ui/react'
import SquareButton from './SquareButton'
import { LeftArrowIcon } from '../Icons'

const BackButton = ({ showText = true }) => {
  const history = useHistory()
  return (
    <Flex mb={6}>
      <Link onClick={history.goBack}>
        {showText ? (
          <Button
            bg={'beige.500'}
            _hover={{ bg: 'beige.600' }}
            color={'green.500'}
            borderRadius={12}
            leftIcon={<Icon mr="4" fontSize="26" as={LeftArrowIcon} />}
          >
            Regresar
          </Button>
        ) : (
          <SquareButton bg={'white.100'} bghover={'grey.100'}>
            <Icon as={LeftArrowIcon} />
          </SquareButton>
        )}
      </Link>
    </Flex>
  )
}

export default BackButton
