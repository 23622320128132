import React from 'react'
import { Grid, GridItem, Text, Flex } from '@chakra-ui/react'
import TrainingInformation from './TrainingInformation'
import MonitorInformation from './MonitorInformation'
import MonitorBankDataInformation from './MonitorBankDataInformation'
import ParticipantsList from './ParticipantsList'
import RenderFlowDocuments from '../RenderFlowDocuments'
import { SectionAccordion } from '../../UI'

const WorkshopsAndTraining = ({ program }) => {
  const renderViewTitle = (title, icon) => (
    <Flex
      bg={'green.100'}
      borderRadius={8}
      justify={'space-between'}
      align={'center'}
      color={'white'}
      fontSize={20}
      px={5}
      py={1}
      my={4}
      w={'-webkit-fill-available'}
    >
      <Text fontWeight={700}>{title}</Text>
      {icon}
    </Flex>
  )

  return (
    <Grid templateColumns={'repeat(1, minmax(0, 1fr))'} gap={2}>
      <GridItem>
        {renderViewTitle('Información del taller o capacitación')}
        <TrainingInformation program={program} />
      </GridItem>
      {program?.monitorData && (
        <GridItem>
          {renderViewTitle('Información del monitor')}
          <MonitorInformation program={program} />
        </GridItem>
      )}
      {program?.bankDataMonitor && (
        <GridItem>
          {renderViewTitle('Información bancaria del monitor')}
          <MonitorBankDataInformation program={program} />
        </GridItem>
      )}
      <SectionAccordion title={'Lista de socios y cónyuges inscritos'}>
        <GridItem>
          <ParticipantsList program={program} />
        </GridItem>
      </SectionAccordion>
      <SectionAccordion title={'Documentos agregados dentro del flujo'}>
        <GridItem>
          <RenderFlowDocuments program={program} />
        </GridItem>
      </SectionAccordion>
    </Grid>
  )
}

export default WorkshopsAndTraining
