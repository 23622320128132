import React, { useEffect, useState } from 'react'
import { Grid, GridItem } from '@chakra-ui/react'
import { ProgramsList } from '../../../../Utils'
import ProgramBarChart from './ProgramBarChart'

const initialDataPerPrograms = ProgramsList?.map((item) => ({
  type: item?.key,
  name: item?.name,
  totalFiltered: 0,
  totalGeneral: 0,
  totalRest: 0,
  percentage: '0%',
  gap: 0
}))

const Metrics = ({ data }) => {
  const [dataPerProgram, setDataPerProgram] = useState(initialDataPerPrograms)

  useEffect(() => {
    if (data?.data) {
      const dataformated =
        data?.data?.length > 0
          ? data?.data?.map((item) => ({
              ...item,
              gap: 0.2
            }))
          : initialDataPerPrograms
      console.log(dataformated)
      setDataPerProgram(dataformated)
    }
  }, [data])

  return (
    <Grid
      templateColumns={'repeat(1, minmax(0, 1fr))'}
      h={{
        lg: '400px',
        md: '400px',
        sm: '400px'
      }}
      gap={2}
    >
      <GridItem>
        <ProgramBarChart data={dataPerProgram} />
      </GridItem>
    </Grid>
  )
}

export default Metrics
