import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { Flex, Icon, Text, useToast } from '@chakra-ui/react'
import {
  Button,
  InputComtrol,
  TextAreaComtrol,
  Alert,
  Spinner,
  CustomToast
} from '../UI'
import { EditIcon, CheckIcon } from '../Icons'
import { schema } from './Schema'
import {
  useNotifications,
  useMutateNotifications
} from '../../hooks/Notifications'
import { GetFormData, GetNotification } from '../../Utils'

const CardFormNotification = ({ type, programKey }) => {
  const toast = useToast()
  const { data, isLoading, isFetching, isError } = useNotifications({
    programKey,
    type
  })
  const {
    mutate,
    isLoading: isLoadingMutate,
    reset
  } = useMutateNotifications('CREATE-AND-UPDATE')

  const initialValues = {
    affair: '',
    message: '',
    toCreate: false,
    type: '',
    programKey: ''
  }

  const formik = useFormik({
    initialValues,
    validateOnMount: false,
    validateOnChange: false,
    validationSchema: schema,
    onSubmit: (values) => {
      const formData = GetFormData(values)

      mutate(formData, {
        onSuccess: () => {
          GetNotification.basic(
            `Notificación ${
              values.toCreate === 'CREATE' ? 'creada' : 'actualizada'
            } correctamente`,
            'Aceptar'
          )
          reset()
        },
        onError: (err) => {
          console.log(err)
          GetNotification.basic(
            'Error',
            'Aceptar',
            `Error al ${
              values.toCreate === 'CREATE' ? 'crear' : 'actualizar'
            } la notificación`,
            'error'
          )
        }
      })
    }
  })

  const getTitle = () => {
    if (type === 'INREVIEW') {
      return {
        title: 'Solicitud en revisión',
        color: 'orange.100'
      }
    }
    if (type === 'APPROVED') {
      return {
        title: 'Solicitud aprobada',
        color: 'green.100'
      }
    }
    if (type === 'REJECTED') {
      return {
        title: 'Solicitud rechazada',
        color: 'red.100'
      }
    }
    return ''
  }

  useEffect(() => {
    if (data) {
      if (
        data?.notifications?.type === type &&
        data?.typeProgram === programKey
      ) {
        formik.setValues({
          ...initialValues,
          affair: data?.notifications?.affair || '',
          message: data?.notifications?.message || '',
          type,
          programKey,
          toCreate: false
        })
        return
      }
      formik.setValues({
        ...initialValues,
        type,
        programKey,
        toCreate: true
      })
    }
    formik.setValues({
      ...initialValues,
      type,
      programKey,
      toCreate: true
    })
  }, [data, programKey])

  useEffect(() => {
    if (isLoadingMutate) {
      toast({
        duration: 9000 * 9000,
        isClosable: true,
        position: 'bottom-right',
        render: ({ onClose }) => (
          <CustomToast
            text={'Espere por favor...'}
            status={'info'}
            onClose={onClose}
          />
        )
      })
      return
    }
    toast.closeAll()
  }, [isLoadingMutate])

  return (
    <>
      {isError && (
        <Flex justify={'center'} align={'center'}>
          <Alert status={'error'} message={'Error al cargar los datos'} />
        </Flex>
      )}

      {!isLoading && !isFetching ? (
        <Flex
          direction={'column'}
          gap={2}
          justify={'flex-start'}
          border={'1px solid #82b378'}
          borderRadius={8}
          p={4}
        >
          <Text fontSize={'xs'} color={'grey.500'}>
            Notificación
          </Text>
          <Text fontSize={'lg'} fontWeight={700} color={getTitle()?.color}>
            {getTitle()?.title}
          </Text>
          <InputComtrol
            id={'affair'}
            label={'Asunto'}
            isRequired={false}
            value={formik.values.affair}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.affair)}
            errorText={formik.errors.affair}
          />
          <TextAreaComtrol
            id={'message'}
            label={'Mensaje'}
            isRequired={false}
            value={formik.values.message}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.message)}
            errorText={formik.errors.message}
          />
          <Flex justify={'flex-end'}>
            {formik.values.toCreate && (
              <Button
                text={'Guardar'}
                rightIcon={<Icon as={CheckIcon} color={'white'} />}
                onClick={formik.handleSubmit}
                isLoading={isLoadingMutate}
                disabled={isLoadingMutate}
              />
            )}

            {!formik.values.toCreate && (
              <Button
                text={'Editar'}
                rightIcon={<Icon as={EditIcon} color={'white'} />}
                onClick={formik.handleSubmit}
                isLoading={isLoadingMutate}
                disabled={isLoadingMutate}
              />
            )}
          </Flex>
        </Flex>
      ) : (
        <Flex justify={'center'} align={'center'}>
          <Flex>
            <Spinner size={'xl'} show={true} />
          </Flex>
        </Flex>
      )}
    </>
  )
}

export default CardFormNotification
