import React from 'react'
import { Grid, GridItem } from '@chakra-ui/react'
import { InputComtrol } from '../../UI'
import { FormatDate } from '../../../Utils'

const ApplicationInformation = ({ program }) => (
  <>
    <Grid
      templateColumns={{
        lg: 'repeat(2, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem>
        <InputComtrol
          label={'Fecha de recepción de solicitud'}
          value={FormatDate(program?.createdAt)}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Fecha de la catástrofe'}
          value={FormatDate(program?.dateOfDisaster)}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Estado civil'}
          value={program?.civilStatus}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
      <GridItem>
        <InputComtrol
          label={'Residencia siniestrada'}
          value={program?.damagedProperty}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Nº de hijos'}
          value={program?.numberChildren}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
    </Grid>
    <Grid
      templateColumns={{
        lg: 'repeat(2, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem>
        <InputComtrol
          label={'Zona de postulación'}
          value={program?.zone}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
      <GridItem>
        <InputComtrol
          label={'Alta repartición / Repartición'}
          value={program?.prefecture}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
    </Grid>
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem>
        <InputComtrol
          label={'Unidad'}
          value={program?.unit}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
    </Grid>
  </>
)

export default ApplicationInformation
