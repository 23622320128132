import React, { useState, useEffect } from 'react'
import { Select as SelectCha } from '@chakra-ui/react'
import Spinner from './Spinner'

const Select2 = ({
  placeHolder,
  list = [],
  bg = 'beige.500',
  onChange,
  value = '',
  disabled = false,
  isLoading = false
}) => {
  const [selected, setSelected] = useState(null)
  const handleChange = (e) => {
    const currentValue = e?.target?.value
    onChange(currentValue)
  }

  const getValue = () => {
    if (value || value === '') {
      const i = list.findIndex((item) => item.key === value)
      return list[i]?.key
    }
    return list[-1]?.key
  }

  useEffect(() => {
    setSelected(getValue())
  }, [value])

  return (
    <SelectCha
      bg={bg}
      focusBorderColor={'beige.500'}
      placeholder={placeHolder}
      color={'green.500'}
      fontWeight={600}
      disabled={list.length === 0 || disabled}
      onChange={(e) => handleChange(e)}
      value={selected}
      icon={isLoading && <Spinner size="xs" thickness="2px" show={true} />}
    >
      {list.map((item) => (
        <option
          style={{ background: '#F1ECE2', color: '#2E7423' }}
          key={item?.key}
          value={item?.key}
        >
          {item?.name}
        </option>
      ))}
    </SelectCha>
  )
}

export default Select2
