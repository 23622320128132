import { Circle, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
  Label
} from 'recharts'

/* const getValueToRest = (value) => {
  const parseValue = parseInt(value, 10)

  if (parseValue === 0) return -10
  if (parseValue === 1) return 12
  return -20
} */

const renderCustomizedLabel = (props) => {
  const { x, y, width, value } = props

  /* const valueToRest = getValueToRest(value) */

  /* const radius = valueToRest */
  return (
    <text
      x={x + width / 2}
      y={20}
      fill=""
      textAnchor="middle"
      dominantBaseline="middle"
    >
      {value}
    </text>
  )
}

const tickFormatter = (value) => {
  const limit = 5
  if (value.length < limit) return value
  return `${value.substring(0, limit)}...`
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Flex
        bg={'white'}
        p={2}
        border={'1px solid #cccccc'}
        direction={'column'}
        textAlign={'center'}
        className="custom-tooltip"
      >
        <Text fontWeight={700}>{`${label}`}</Text>
        <Flex gap={2} justify="center" alignItems="center">
          <Circle size="10px" bg="#82B378" />
          <Text>{`Total filtrado: ${payload[0]?.payload?.totalFiltered}`}</Text>
        </Flex>
        <Flex gap={2} justify="center" alignItems="center">
          <Circle size="10px" bg="#D9D9D9" />
          <Text>{`Registros fuera de filtro: ${
            parseInt(payload[0]?.payload?.totalGeneral, 10) -
            parseInt(payload[0]?.payload?.totalFiltered, 10)
          }`}</Text>
        </Flex>
        <Flex gap={2} justify="center" alignItems="center">
          <Circle size="10px" bg="" />
          <Text>{`Total de registros: ${payload[0]?.payload?.totalGeneral}`}</Text>
        </Flex>
        <Text>{`Porcentaje: ${payload[0]?.payload?.percentage}`}</Text>
      </Flex>
    )
  }

  return null
}

const ProgramBarChart = ({ data }) => {
  console.log(data)
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart width={400} height={400} data={data} barSize={20}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" width={150} tickFormatter={tickFormatter} />

        <Tooltip content={<CustomTooltip />} />
        <Bar
          dataKey="totalFiltered"
          stackId="a"
          fill="#82B378"
          radius={8}
        ></Bar>
        <Bar dataKey="gap" stackId="a" fill="transparent" />
        <Bar dataKey="totalRest" stackId="a" fill="#D9D9D9" radius={8}>
          <LabelList dataKey="totalGeneral" content={renderCustomizedLabel} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}

export default ProgramBarChart
