import { createIcon } from '@chakra-ui/react'
/* eslint-disable import/prefer-default-export */
const OptionsIcons = createIcon({
  displayName: 'OptionsIcons',
  viewBox: '0 0 25 24',
  path: [
    <path
      d="M22 14.5C23.3807 14.5 24.5 13.3807 24.5 12C24.5 10.6193 23.3807 9.5 22 9.5C20.6193 9.5 19.5 10.6193 19.5 12C19.5 13.3807 20.6193 14.5 22 14.5Z"
      fill="#2E7423"
      stroke="#2E7423"
      strokeWidth="2"
    />,
    <path
      d="M12.5 14.5C13.8807 14.5 15 13.3807 15 12C15 10.6193 13.8807 9.5 12.5 9.5C11.1193 9.5 10 10.6193 10 12C10 13.3807 11.1193 14.5 12.5 14.5Z"
      fill="#2E7423"
      stroke="#2E7423"
      strokeWidth="2"
    />,
    <path
      d="M2.99998 14.5C4.38069 14.5 5.49997 13.3807 5.49997 12C5.49997 10.6193 4.38069 9.5 2.99998 9.5C1.61928 9.5 0.5 10.6193 0.5 12C0.5 13.3807 1.61928 14.5 2.99998 14.5Z"
      fill="#2E7423"
      stroke="#2E7423"
      strokeWidth="2"
    />
  ]
})

export default OptionsIcons
