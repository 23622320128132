/* eslint-disable import/prefer-default-export */
import axios from '../Axios'

const module = '/alerts'

export const getAlerts = async (page) => {
  const { data } = await axios.get(`${module}/?page=${page}`)
  return data
}

export const updateAlert = async (alertId) => {
  console.log('alertId', alertId)
  const { data } = await axios.get(`${module}/update/${alertId}`)
  return data
}

export const updateTotalAlert = async () => {
  const { data } = await axios.get(`${module}/total-update/`)
  return data
}
