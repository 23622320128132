import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import { stepThreeSchema } from './Schemas'
import { BarFileName, UploadFile } from '../../UI'
import { Files } from '../../../Utils'

const StepThree = ({
  refStepThree,
  stepThreeForm,
  setStepThreeForm,
  state
}) => {
  const initialValues = {
    deathCertificateName: [],
    deathCertificate: null,
    salarySettlementName: [],
    salarySettlement: null,
    additionalDocumentsName: [],
    additionalDocuments: null
  }

  const formik = useFormik({
    initialValues,
    validationSchema: stepThreeSchema,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: (values) => {
      if (!state) {
        setStepThreeForm({
          ...values
        })
      }
      if (state) {
        setStepThreeForm({
          ...values,
          state
        })
      }
    }
  })

  const deleteDeathCertificate = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles: resultTipcars } =
      getArrayNamesAndArrayFiles(
        fileName,
        formik.values.deathCertificateName,
        formik.values.deathCertificate
      )

    formik.setValues({
      ...formik.values,
      deathCertificateName: resultNames,
      deathCertificate: resultTipcars
    })
  }

  const deleteSalarySettlement = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles: resultTipcars } =
      getArrayNamesAndArrayFiles(
        fileName,
        formik.values.salarySettlementName,
        formik.values.salarySettlement
      )

    formik.setValues({
      ...formik.values,
      salarySettlementName: resultNames,
      salarySettlement: resultTipcars
    })
  }

  const deleteAdditionalDocuments = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles: resultTipcars } =
      getArrayNamesAndArrayFiles(
        fileName,
        formik.values.additionalDocumentsName,
        formik.values.additionalDocuments
      )

    formik.setValues({
      ...formik.values,
      additionalDocumentsName: resultNames,
      additionalDocuments: resultTipcars
    })
  }

  const handleChangeDeathCertificate = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.deathCertificateName,
      formik.values.deathCertificate
    )
    formik.setValues({
      ...formik.values,
      deathCertificateName: newListNames,
      deathCertificate: newListFiles
    })
    e.target.value = null
  }

  const handleChangeSalarySettlement = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.salarySettlementName,
      formik.values.salarySettlement
    )

    formik.setValues({
      ...formik.values,
      salarySettlementName: newListNames,
      salarySettlement: newListFiles
    })
    e.target.value = null
  }

  const handleChangeAdditionalDocuments = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.additionalDocumentsName,
      formik.values.additionalDocuments
    )

    formik.setValues({
      ...formik.values,
      additionalDocumentsName: newListNames,
      additionalDocuments: newListFiles
    })
    e.target.value = null
  }

  useEffect(() => {
    formik.setValues({
      deathCertificateName: stepThreeForm.deathCertificateName || [],
      deathCertificate: stepThreeForm.deathCertificate || null,
      salarySettlementName: stepThreeForm.salarySettlementName || [],
      salarySettlement: stepThreeForm.salarySettlement || null,
      additionalDocumentsName: stepThreeForm.additionalDocumentsName || [],
      additionalDocuments: stepThreeForm.additionalDocuments || null
    })
  }, [stepThreeForm])

  useEffect(() => {
    refStepThree.current = formik.handleSubmit
  }, [])

  return (
    <>
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          {/* -- */}
          <Text fontSize={'md'} color={'green.500'}>
            Certificado de defunción
          </Text>
          <UploadFile
            id={'deathCertificateName'}
            direction={'row'}
            sizeIcon={30}
            p={5}
            onChange={(e) => handleChangeDeathCertificate(e)}
            error={Boolean(formik.errors.deathCertificateName)}
            errorText={formik.errors.deathCertificateName}
          />
          <Flex direction={'column'} gap={1}>
            {formik.values.deathCertificateName.map((item) => (
              <BarFileName
                key={item?.name}
                fileName={item?.name}
                fileUrl={item?.url}
                onDelete={() => deleteDeathCertificate(item?.name)}
              />
            ))}
          </Flex>
        </GridItem>
        <GridItem>
          {/* -- */}
          <Text fontSize={'md'} color={'green.500'}>
            Liquidación de sueldo
          </Text>
          <UploadFile
            id={'salarySettlementName'}
            direction={'row'}
            sizeIcon={30}
            p={5}
            onChange={(e) => handleChangeSalarySettlement(e)}
            error={Boolean(formik.errors.salarySettlementName)}
            errorText={formik.errors.salarySettlementName}
          />
          <Flex direction={'column'} gap={1}>
            {formik.values.salarySettlementName.map((item) => (
              <BarFileName
                key={item?.name}
                fileName={item?.name}
                fileUrl={item?.url}
                onDelete={() => deleteSalarySettlement(item?.name)}
              />
            ))}
          </Flex>
        </GridItem>
      </Grid>
      {/* --- */}
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
        <GridItem>
          <Text fontSize={'md'} color={'green.500'}>
            Documentos adicionales
          </Text>
          <UploadFile
            id={'additionalDocumentsName'}
            direction={'row'}
            sizeIcon={30}
            p={5}
            onChange={(e) => handleChangeAdditionalDocuments(e)}
            error={Boolean(formik.errors.additionalDocumentsName)}
            errorText={formik.errors.additionalDocumentsName}
          />
          <Flex direction={'column'} gap={1}>
            {formik.values.additionalDocumentsName.map((item) => (
              <BarFileName
                key={item?.name}
                fileName={item?.name}
                fileUrl={item?.url}
                onDelete={() => deleteAdditionalDocuments(item?.name)}
              />
            ))}
          </Flex>
        </GridItem>
      </Grid>
    </>
  )
}

export default StepThree
