import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  Button,
  Text,
  Flex
} from '@chakra-ui/react'
import { Alert, Button as BtnUI, Spinner } from '../UI'
import { AlertIcon, ReplayIcon } from '../Icons'
import AlertItem from './AlertItem'
import {
  useAlerts,
  useUpdateAlerts,
  useInvalidateAlerts
} from '../../hooks/Alerts'
import WithoutResults from '../WithoutResults'

const AlertsContainer = () => {
  const [page, setPage] = useState(0)
  const [alertsAccumlated, setAlertsAccumlated] = useState([])
  const { data, isLoading, isFetching, isError, refetch } = useAlerts(page)
  const { mutate } = useUpdateAlerts('UPDATE-TOTAL')
  const { user } = useSelector((state) => state.auth)
  useInvalidateAlerts(user, setPage)

  const updateTotalAlerts = () => {
    if (data.alerts.notDisplayed > 0) {
      mutate('', {
        onError: () => {
          console.log('Error in update alert')
        }
      })
    }
  }

  useEffect(() => {
    if (data?.alerts?.currentAlerts.length > 0 && page === 0) {
      setAlertsAccumlated(data.alerts.currentAlerts)
    }

    if (data?.alerts?.currentAlerts.length > 0 && page > 0) {
      setAlertsAccumlated([...alertsAccumlated, ...data.alerts.currentAlerts])
    }
  }, [data])

  return (
    <Menu>
      <MenuButton
        as={Button}
        bg={'white'}
        _active={{ bg: 'white' }}
        _hover={{ bg: 'white' }}
        position={'relative'}
        onClick={updateTotalAlerts}
      >
        <Icon fontSize={'2xl'} as={AlertIcon} />
        {data?.alerts?.notDisplayed > 0 && (
          <Flex
            position={'absolute'}
            top={'-5px'}
            right={'5px'}
            bg={'red.500'}
            borderRadius={'50%'}
            maxW={'25px'}
            maxH={'25px'}
            p={3}
            justify={'center'}
            align={'center'}
            fontSize={'xs'}
          >
            {data.alerts.notDisplayed}
          </Flex>
        )}
      </MenuButton>
      <MenuList
        w={{
          lg: 'xl',
          md: 'md',
          sm: 'sm'
        }}
        mr={{
          lg: 0,
          md: 5,
          sm: 5
        }}
        borderRadius={12}
        border={'1px dotted #82B378'}
        boxShadow={'2xl'}
        p={4}
        zIndex={999}
      >
        <Flex justify={'space-between'}>
          <Text
            noOfLines={1}
            fontWeight={700}
            color={'black'}
            fontSize={'xl'}
            mb={4}
          >{`Notificaciones`}</Text>
          <Flex gap={2}>
            {(isLoading || isFetching) && <Spinner size={'md'} show={true} />}
            <Icon
              fontSize={'2xl'}
              as={ReplayIcon}
              color={'green.100'}
              _hover={{ color: 'green.500', cursor: 'pointer' }}
              onClick={refetch}
            />
          </Flex>
        </Flex>

        {isError && (
          <Flex w={'100%'} align={'center'} justify={'center'} py={3}>
            <Alert
              status={'error'}
              message={'Error al Cargar las notificaciones'}
            />
          </Flex>
        )}
        <Flex
          direction={'column'}
          maxH={{
            lg: 'lg',
            md: 'md',
            sm: 'sm'
          }}
          overflowY={'auto'}
          css={{
            '&::-webkit-scrollbar': {
              width: '4px'
            },
            '&::-webkit-scrollbar-track': {
              width: '4px'
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#2E7423',
              borderRadius: '30px'
            }
          }}
        >
          {user && (
            <>
              {alertsAccumlated.length > 0 ? (
                <>
                  {alertsAccumlated.map((item) => (
                    <MenuItem
                      minH="48px"
                      key={item?._id}
                      _focus={{ bg: 'white' }}
                      borderBottom={'1px solid #D5D5D5'}
                    >
                      <AlertItem alert={item} user={user} />
                    </MenuItem>
                  ))}
                  <Flex justify={'center'} align={'center'} mt={4}>
                    <BtnUI
                      text={'Ver más'}
                      bg={'grey.100'}
                      bghover={'grey.500'}
                      color={'black'}
                      onClick={() => setPage(page + 1)}
                      disabled={
                        alertsAccumlated.length >= data?.alerts?.totalAlerts ||
                        data?.alerts?.currentAlerts?.length === 0 ||
                        isLoading ||
                        isFetching
                      }
                      isLoading={isLoading || isFetching}
                    />
                  </Flex>
                </>
              ) : (
                <WithoutResults type={'ALERTS'} />
              )}
            </>
          )}
        </Flex>
      </MenuList>
    </Menu>
  )
}

export default AlertsContainer
