const banksList = [
  'Banco Estado',
  'Banco de Chile / Edwards',
  'Banco de Crédito e Inversiones (BCI)',
  'Banco Bice',
  'HSBC Bank',
  'Banco Santander',
  'Itaú / Corpbanca',
  'Banco Security',
  'Scotiabank',
  'Scotiabank Azul (Ex BBVA)',
  'Banco Falabella',
  'Banco Ripley',
  'Banco Consorcio',
  'Banco BTG Pactual Chile',
  'Banco Internacional',
  'Otro'
]

export default banksList
