import { createIcon } from '@chakra-ui/react'
/* https://freeicons.io/ui-essentials-3/ui-essentials-notification-alarm-bell-icon-44563 */
/* eslint-disable import/prefer-default-export */
const SendIcon = createIcon({
  displayName: 'SendIcon',
  viewBox: '0 0 20 21',
  path: [
    <path
      d="M19.1663 1.33503C18.4771 0.636298 17.4723 0.349816 16.5183 0.580134L3.78017 3.25725C1.36114 3.6152 -0.30967 5.8664 0.0482865 8.28539C0.187446 9.22584 0.625625 10.0965 1.29799 10.7687L2.66361 12.1351C2.72755 12.1982 2.76328 12.2845 2.76278 12.3743V14.8939C2.76461 16.4614 4.03486 17.7317 5.60237 17.7335H8.122C8.21195 17.7335 8.29827 17.7692 8.36197 17.8327L9.72759 19.1983C10.5533 20.0295 11.6763 20.4973 12.848 20.4981C13.3316 20.4977 13.812 20.4186 14.2703 20.264C15.8517 19.7388 17.0009 18.3654 17.239 16.7162L19.9161 4.018C20.1566 3.054 19.8717 2.03461 19.1663 1.33503ZM2.60116 7.02252C2.82761 6.3229 3.43869 5.8177 4.16843 5.72689C4.19788 5.72298 4.22702 5.71799 4.25592 5.71189L15.356 3.37891L5.26245 13.4666V12.3743C5.26429 11.6207 4.96418 10.8978 4.42926 10.367L3.06527 9.00142C2.53992 8.48759 2.35901 7.71637 2.60116 7.02252ZM14.7844 16.2446C14.7777 16.2737 14.7736 16.3029 14.7694 16.332C14.6247 17.3875 13.6518 18.1258 12.5964 17.9811C12.1794 17.9239 11.7926 17.7319 11.4949 17.4344L10.1301 16.0696C9.59905 15.5345 8.87588 15.2344 8.12204 15.2364H7.0297L17.1207 5.14283L14.7844 16.2446Z"
      fill="white"
    />
  ]
})

export default SendIcon
