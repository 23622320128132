import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { Box, Flex, useDisclosure } from '@chakra-ui/react'
import { Button, CustomModal, InputComtrol } from '../UI'
import { forgotPasswordSchema } from './schema'
import { GetFormData, GetNotification } from '../../Utils'
import { useMutateActionPassword } from '../../hooks/resetPassword'

const ForgotPassword = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { mutate, isLoading, reset } =
    useMutateActionPassword('FORGOT-PASSWORD')

  const initialValues = {
    email: '',
    repeatEmail: ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values) => {
      const formData = GetFormData(values)
      mutate(formData, {
        onSuccess: () => {
          reset()
          formik.resetForm()
          GetNotification.basic(
            'Éxito',
            'Aceptar',
            'Restablecimiento de contraseña enviada al correo electrónico',
            'success'
          )
          onClose()
        },
        onError: (err) => {
          const response = JSON.parse(err.request.response)
          GetNotification.basic(
            'Error',
            'Aceptar',
            response?.message || 'Error al solicitar cambio de contraseña',
            'error'
          )
        }
      })
    }
  })

  return (
    <>
      <Box ml={'auto'}>
        <Link to="#" onClick={onOpen}>
          ¿Olvidaste tu contraseña?
        </Link>
      </Box>
      <CustomModal
        title={'¿Olvidaste tu contraseña?'}
        showButtons={false}
        isOpen={isOpen}
        onClose={onClose}
      >
        <Flex direction={'column'} gap={2} p={8}>
          <InputComtrol
            id={'email'}
            label={'Correo electrónico'}
            type={'email'}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.email)}
            errorText={formik.errors.email}
          />
          <InputComtrol
            id={'repeatEmail'}
            label={'Repetir correo electrónico'}
            type={'email'}
            value={formik.values.repeatEmail}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.repeatEmail)}
            errorText={formik.errors.repeatEmail}
          />
          <Button
            mx={0}
            text={'Solicitar cambio de contraseña'}
            onClick={formik.handleSubmit}
            disabled={!formik.isValid}
            isLoading={isLoading}
          />
        </Flex>
      </CustomModal>
    </>
  )
}
export default ForgotPassword
