import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { Flex } from '@chakra-ui/react'
import { schema } from './Schema'
import {
  InputComtrol,
  UploadFile,
  CustomModal,
  TextAreaComtrol,
  BarFileName
} from '../../UI'
import { Files, GetFormData, GetNotification } from '../../../Utils'
import { useMutatePartner } from '../../../hooks/Partners'

const ResignationModal = ({ isOpen, onClose, currentId }) => {
  const { mutate, isLoading, reset } = useMutatePartner('RESIGNATION')
  const initialValues = {
    _id: '',
    resignationDescription: '',
    resignationDocument: null,
    resignationDocumentNames: []
  }

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: (values) => {
      const partnerId = currentId

      const formData = GetFormData(values, ['resignationDocument'])

      mutate(
        { partnerId, formData },
        {
          onSuccess: () => {
            reset()
            GetNotification.basic('Registro de renuncia exitoso', 'Aceptar')
            onClose()
          },
          onError: () => {
            GetNotification.basic(
              'Error',
              'Aceptar',
              'error al registrar la renuncia',
              'error'
            )
          }
        }
      )
    }
  })

  const deletefile = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.resignationDocumentNames,
      formik.values.resignationDocument
    )

    formik.setValues({
      ...formik.values,
      resignationDocumentNames: resultNames,
      resignationDocument: resultListFiles
    })
  }

  const handleChangeFile = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.resignationDocumentNames,
      formik.values.resignationDocument
    )

    formik.setValues({
      ...formik.values,
      resignationDocumentNames: newListNames,
      resignationDocument: newListFiles
    })
    e.target.value = null
  }

  useEffect(() => {
    formik.resetForm()
    if (isOpen) {
      formik.setValues({
        ...formik.values,
        _id: currentId
      })
    }
  }, [isOpen, currentId])

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      title={'Registrar Motivo de Renuncia'}
      size={'2xl'}
      onClick={formik.handleSubmit}
      isLoading={isLoading}
      isDisabled={isLoading}
    >
      <Flex gap={4} direction={'column'} pb={10}>
        <InputComtrol
          label={'Adjunta Documentación (Opcional)'}
          isRequired={false}
        >
          <UploadFile
            direction={'row'}
            sizeIcon={30}
            p={5}
            onChange={(e) => {
              handleChangeFile(e)
            }}
            id={'resignationDocumentNames'}
            error={Boolean(formik.errors.resignationDocumentNames)}
            errorText={formik.errors.resignationDocumentNames}
          />
          <Flex direction={'column'} gap={1}>
            {formik.values.resignationDocumentNames.map((item) => (
              <BarFileName
                key={item?.name}
                fileName={item?.name}
                fileUrl={item?.url}
                onDelete={() => deletefile(item?.name)}
              />
            ))}
          </Flex>
        </InputComtrol>
        <TextAreaComtrol
          id={'resignationDescription'}
          isRequired={false}
          label={'Motivo de Renuncia'}
          value={formik.values.resignationDescription}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.resignationDescription)}
          errorText={formik.errors.resignationDescription}
        />
      </Flex>
    </CustomModal>
  )
}

export default ResignationModal
