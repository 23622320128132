import React from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { ButtonDotted, CustomDatePicker, SquareButton } from '../../../UI'

const RenderArrayDateSubmissionReceipts = ({ formik }) => {
  const AddDate = () => {
    formik.setValues({
      ...formik.values,
      dateSubmissionReceiptsArr: [
        ...formik.values.dateSubmissionReceiptsArr,
        {
          id: Math.random().toString(),
          date: ''
        }
      ]
    })
  }

  const handleChangeDateSubmissionReceipts = (date, id) => {
    if (date) {
      const newDates = formik.values.dateSubmissionReceiptsArr?.map(
        (dateItem) => {
          if (dateItem?.id === id) {
            return {
              ...dateItem,
              date: date || ''
            }
          }
          return dateItem
        }
      )

      formik.setValues({
        ...formik.values,
        dateSubmissionReceiptsArr: newDates
      })
    }
  }

  const deleteDate = (id) => {
    const newDates = formik.values.dateSubmissionReceiptsArr?.filter(
      (dateItem) => dateItem.id !== id
    )

    formik.setValues({
      ...formik.values,
      dateSubmissionReceiptsArr: newDates
    })
  }

  return (
    <Flex direction="column" gap={2}>
      {formik.values.dateSubmissionReceiptsArr.map((date, index) => (
        <Flex gap={2} justify="center" align="center">
          <CustomDatePicker
            key={date.id}
            placeholder={'Fecha de envío de comprobantes'}
            currentDate={date.date}
            onChange={(dateSelected) =>
              handleChangeDateSubmissionReceipts(dateSelected, date.id)
            }
            error={Boolean(
              formik.errors.dateSubmissionReceiptsArr?.[index]?.date
            )}
            errorText={formik.errors.dateSubmissionReceiptsArr?.[index]?.date}
          />
          <Flex justify={'flex-end'}>
            <SquareButton onClick={() => deleteDate(date.id)} size={'xs'}>
              x
            </SquareButton>
          </Flex>
        </Flex>
      ))}
      {formik?.errors?.dateSubmissionReceiptsArr &&
        typeof formik?.errors?.dateSubmissionReceiptsArr === 'string' && (
          <Flex justify={'center'}>
            <Text color="red.500">
              {formik?.errors?.dateSubmissionReceiptsArr}
            </Text>
          </Flex>
        )}
      <Flex justify={'flex-end'}>
        <ButtonDotted text={'Agregar Fecha'} p={1} onClick={AddDate} />
      </Flex>
    </Flex>
  )
}

export default RenderArrayDateSubmissionReceipts
