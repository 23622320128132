import React from 'react'
import { Grid, GridItem, Text } from '@chakra-ui/react'
import PartnerInformation from './PartnerInformation'
import ApplicationInformation from './ApplicationInformation'
import PostulantInformation from './PostulantInformation'
import AdditionalInformations from './AdditionalInformations'
import Documents from './Documents'
import RenderFlowDocuments from '../RenderFlowDocuments'
import { SectionAccordion } from '../../UI'
import { haveDocuments } from '../../../Utils'

const UniversityScholarships = ({ program }) => {
  const renderViewTitle = (title) => (
    <Text
      fontWeight={700}
      bg={'green.100'}
      color={'white'}
      fontSize={20}
      borderRadius={8}
      px={5}
      py={1}
      my={4}
    >
      {title}
    </Text>
  )

  const documentsInProgram = haveDocuments(
    [
      'birthCertificate',
      'scoreCertificate',
      'socialReport',
      'parentsLastSalary',
      'motivationLetter',
      'applicationSignedByPartner',
      'curricularMesh'
    ],
    program
  )

  return (
    <Grid templateColumns={'repeat(1, minmax(0, 1fr))'} gap={2}>
      <GridItem>
        {renderViewTitle('Información del socio')}
        <PartnerInformation program={program} />
      </GridItem>
      <GridItem>
        {renderViewTitle('Información de la solicitud')}
        <ApplicationInformation program={program} />
      </GridItem>
      <GridItem>
        {renderViewTitle('Información del solicitante')}
        <PostulantInformation program={program} />
      </GridItem>
      {program?.aditionalDocuments.length > 0 || program?.observations ? (
        <GridItem>
          {renderViewTitle('Observaciones de la solicitud')}
          <AdditionalInformations program={program} />
        </GridItem>
      ) : null}
      <GridItem>
        {documentsInProgram && renderViewTitle('Documentos')}
        <Documents program={program} />
      </GridItem>
      <SectionAccordion title={'Documentos agregados dentro del flujo'}>
        <GridItem>
          <RenderFlowDocuments program={program} />
        </GridItem>
      </SectionAccordion>
    </Grid>
  )
}

export default UniversityScholarships
