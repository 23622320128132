import * as Yup from 'yup'
/* eslint-disable import/prefer-default-export */
export const familyMemberSchema = Yup.object().shape({
  fullName: Yup.string().required('El nombre es Requerido')
})

export const familyMemberEditSchema = Yup.object().shape({
  fullName: Yup.string().required('El nombre es Requerido'),
  rut: Yup.string()
    .min(11, 'El Rut no es valido')
    .max(12, 'El Rut no es valido')
    .required('Rut es requerido'),
  phone: Yup.string()
    .min(8, 'El telefono no es valido')
    .max(9, 'El telefono no es valido')
    .required('Teléfono es requerido'),
  email: Yup.string()
    .email('Ingrese un Email Valido')
    .required('Email es requerido'),
  birthDate: Yup.string().required('Fecha de nacimiento es Requerida')
})
