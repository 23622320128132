import React from 'react'
import { useParams } from 'react-router-dom'
import { Grid, GridItem } from '@chakra-ui/react'
import { Alert, BackButton, Progress } from '../../../../components/UI'
import WorkshopsAndTraining from '../../../../components/FormContainerPrograms/WorkshopsAndTraining'
import { useProgram } from '../../../../hooks/Programs'

const Edit = () => {
  const { programId } = useParams()
  const {
    data: program,
    isLoading,
    isFetching,
    isError
  } = useProgram(programId, 'WorkshopsAndTraining')

  return (
    <>
      {isError && (
        <Alert status={'error'} message={'Error al cargar los Datos'} />
      )}
      {!isLoading || !isFetching ? (
        <Grid
          templateColumns={{
            lg: 'repeat(2, 15% 85%)',
            md: 'repeat(1, 1fr)',
            sm: 'repeat(1, 1fr)'
          }}
          gap={2}
        >
          <GridItem>
            <BackButton />
          </GridItem>
          <GridItem>
            <WorkshopsAndTraining program={program} />
          </GridItem>
        </Grid>
      ) : (
        <Progress />
      )}
    </>
  )
}

export default Edit
