/* eslint-disable import/prefer-default-export */
import { saveAs } from 'file-saver'
import axios from '../Axios'

const module = '/partners-sync'

export const getPartnersSync = async (
  filtersPartnersSync,
  page = 0,
  limit = 6
) => {
  const { search, month, year } = filtersPartnersSync
  const { data } = await axios.get(
    `${module}/?search=${search}&month=${month}&year=${year}&page=${page}&limit=${limit}`
  )
  return data
}

export const generateTemplateFile = async (body) => {
  const response = await axios.post(`${module}/generate-template-file`, body, {
    responseType: 'arraybuffer'
  })
  const dirtyFileName = response.headers['content-disposition']
  const regex =
    /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/
  const fileName = dirtyFileName.match(regex)[3]

  const blob = new Blob([response.data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  })
  saveAs(blob, fileName)

  return response
}
