import React, { useEffect, useState } from 'react'
import { Grid, GridItem } from '@chakra-ui/react'
import { Months } from '../../../../Utils'
import MonthAreaChart from './MonthAreaChart'
import AmountAndCount from './AmountAndCount'

const initialDataMonth = Months?.getArrayNamesMonths()?.map((item) => ({
  month: item?.substring(0, 3),
  total: 0
}))

const Metrics = ({ data }) => {
  const [dataMonth, setDataMonth] = useState(initialDataMonth)

  useEffect(() => {
    if (data?.totalByMonth) {
      const monthformated =
        data?.totalByMonth?.length > 0
          ? data?.totalByMonth?.map((item) => ({
              month: Months?.getNameMonths(item?.monthIndex)?.substring(0, 3),
              total: item?.total
            }))
          : initialDataMonth
      setDataMonth(monthformated)
    }
  }, [data])

  return (
    <Grid
      templateColumns={{
        lg: 'repeat(2, 70% 30%)',
        md: 'repeat(1, 100%)',
        sm: 'repeat(1, 100%)'
      }}
      h={{
        lg: '400px',
        md: '400px',
        sm: '400px'
      }}
      gap={2}
    >
      <GridItem>
        <MonthAreaChart data={dataMonth} />
      </GridItem>
      <GridItem>
        <AmountAndCount data={data} />
      </GridItem>
    </Grid>
  )
}

export default Metrics
