import * as Yup from 'yup'
/* eslint-disable import/prefer-default-export */
export const userSchema = Yup.object().shape({
  name: Yup.string()
    .max(20, 'El nombre no puede tener más de 20 caracteres')
    .required('El nombre es requerido'),
  lastName: Yup.string()
    .max(20, 'El apellido no puede tener más de 20 caracteres')
    .required('El apellido es requerido'),
  email: Yup.string()
    .email('Ingrese un Email Valido')
    .required('El Correo es Requerido'),
  password: Yup.string().required('La Contraseña es Requerida'),
  role: Yup.string().required('El Rol es Requerido'),
  phone: Yup.string()
    .min(8, 'El telefono no es valido')
    .max(9, 'El telefono no es valido')
})

export const userEditSchema = Yup.object().shape({
  name: Yup.string()
    .max(10, 'El nombre no puede tener más de 10 caracteres')
    .required('El nombre es Requerido'),
  lastName: Yup.string()
    .max(10, 'El apellido no puede tener más de 10 caracteres')
    .required('El Apellido es Requerido'),
  email: Yup.string()
    .email('Ingrese un Email Valido')
    .required('El Correo es Requerido'),
  role: Yup.string().required('El Rol es Requerido'),
  phone: Yup.string()
    .min(8, 'El telefono no es valido')
    .max(9, 'El telefono no es valido')
})
