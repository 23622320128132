import React from 'react'
import { Grid, GridItem, Text } from '@chakra-ui/react'
import { CardFilePreview } from '../../UI'

const Documents = ({ program }) => (
  <>
    <Text>Últimas 3 liquidaciones de sueldo</Text>
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={4}
      pb={8}
    >
      {program?.salarySettlements.map((item) => (
        <GridItem>
          <CardFilePreview name={item?.name} url={item?.url} />
        </GridItem>
      ))}
    </Grid>
    <Text>Certificado médico o epicrisis</Text>
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={4}
      pb={8}
    >
      {program?.medicalCertificate.map((item) => (
        <GridItem>
          <CardFilePreview name={item?.name} url={item?.url} />
        </GridItem>
      ))}
    </Grid>
    <Text>Documentación de institución</Text>
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={4}
      pb={8}
    >
      {program?.institutionDocumentation.map((item) => (
        <GridItem>
          <CardFilePreview name={item?.name} url={item?.url} />
        </GridItem>
      ))}
    </Grid>
    <Text>Solicitud personal del socio(a)</Text>
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={4}
      pb={8}
    >
      {program?.partnerPersonalRequest.map((item) => (
        <GridItem>
          <CardFilePreview name={item?.name} url={item?.url} />
        </GridItem>
      ))}
    </Grid>
    <Text>Receta médica</Text>
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      {program?.prescription.map((item) => (
        <GridItem>
          <CardFilePreview name={item.name} url={item.url} />
        </GridItem>
      ))}
    </Grid>
    <Text>Informe social</Text>
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      {program?.socialReport.map((item) => (
        <GridItem>
          <CardFilePreview name={item.name} url={item.url} />
        </GridItem>
      ))}
    </Grid>
    <Text>Cotización</Text>
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      {program?.quotation.map((item) => (
        <GridItem>
          <CardFilePreview name={item.name} url={item.url} />
        </GridItem>
      ))}
    </Grid>
    <Text>
      Certificado de deuda o historial informe detalle de prestaciones médicas
    </Text>
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      {program?.debtCertificate.map((item) => (
        <GridItem>
          <CardFilePreview name={item.name} url={item.url} />
        </GridItem>
      ))}
    </Grid>
    <Text>Autorización para solicitar deuda a Dipreca</Text>
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      {program?.authorizationToRequestDebt.map((item) => (
        <GridItem>
          <CardFilePreview name={item.name} url={item.url} />
        </GridItem>
      ))}
    </Grid>
    {/* <Text>Oficio remisor</Text>
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      {program?.senderOffice.map((item) => (
        <GridItem>
          <CardFilePreview name={item.name} url={item.url} />
        </GridItem>
      ))}
    </Grid> */}
  </>
)

export default Documents
