import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import {
  Box,
  Checkbox,
  Flex,
  useDisclosure,
  useToast,
  Button,
  HStack,
  FormControl,
  FormLabel,
  Switch
} from '@chakra-ui/react'
import {
  BarFileName,
  InputComtrol,
  TextAreaComtrol,
  UploadFile,
  CustomToast
} from '../../../UI'
import ModalHistory from '../../ModalHistory'
import { useMutateHistoryProgram } from '../../../../hooks/HistoryProgram'
import {
  GetNotification,
  GetFormData,
  GetKey,
  CondicionalModal,
  Files,
  Schedule,
  FormatMoney
} from '../../../../Utils'
import { useTypeModal } from '../../../../hooks/TypeModal'
import { openResignationModal } from '../../../../store/slices/modal'

import { schema } from './Schema'
import VoucherIntructive from './VoucherIntructive'
import DocumentsUniversityOrder from './DocumentsUniversityOrder'
import DocumentsSolidarityOrder from './DocumentsSolidarityOrder'
import RenderGeneralDocument from './RenderGeneralDocument'
import RequestPaymentMonitors from './RequestPaymentMonitors'
import RenderBottonsOptions from './RenderBottonsOptions'
import RenderInputRequired from './RenderInputRequired'
import RenderVouchersRequired from './RenderVouchersRequired'
import RenderDebtCertificateRequired from './RenderDebtCertificateRequired'
import SwitchSkipStep from './SwitchSkipStep'
import RenderArrayDescriptions from './RenderArrayDescriptions'
import RenderPreviewPastStep from './RenderPreviewPastStep'
import RenderCheckProcessRequired from './RenderCheckProcessRequired'
import RenderCheckRequired from './RenderCheckRequired'
import RenderSelectServiceRequired from './RenderSelectServiceRequired'
import RenderCommission from './RenderCommission'
import RenderValidationStep from './RenderValidationStep'

const OtherProcesses = ({ currentStep, getColor, program }) => {
  const toast = useToast()
  const dispatch = useDispatch()
  const {
    requiredPurchaseOrderVerification,
    requiredDescriptions,
    requiredDocuments,
    requiredDate,
    requiredGiftActivate,
    requiredCheckProcess,
    requiredCommission,
    requiredPayment,
    requiredVadiationOffice,
    requiredAuthorization,
    requiredProposedState,
    requiredAmount,
    requiredOfficeStatus,
    requiredVoucher,
    requiredInstructive,
    requiredEntryDate,
    requiredBackupFeeAmount,
    requiredScholarshipCommission,
    requiredBankData,
    requiredOffice,
    requiredInvoice,
    requiredTariffDifferenceDocuments,
    requiredTransferredAmount,
    requiredUsedAmount,
    requiredPaymentMonitors,
    requiredVerificationOfDocuments,
    requiredMoreDescriptions,
    requiredReportedEmail,
    requiredChangeState,
    canImcompleteStep,
    canDesistProgram,
    requiredBoxPurchase,
    requiredBoxPurchaseTwo,
    requiredPurchaseRequestLetter,
    requiredPurchaseRequestLetterSigned,
    requiredQuotesReceived,
    requiredDebtCertificate,
    requiredDateSubmissionReceipts,
    requiredDateSubmissionReceiptsArr,
    requiredCheckVouchersSend,
    requiredDateCertificateRequest,
    requiredDateCertificateReception,
    requiredDocumentsSentMedicalInstitution,
    requiredDateSend,
    requiredQuestion
  } = CondicionalModal
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    title,
    actionKey,
    succesMessage,
    errorMessage,
    titleDocument,
    titleObservation,
    buttonsProps,
    buttonsPropsExtra,
    keyToSearch,
    validation
  } = useTypeModal(currentStep?.key)

  const { mutate, isLoading, reset } = useMutateHistoryProgram(actionKey)

  const getTypeProcess = () => {
    const additionalInformation = program?.additionalInformation.filter(
      (item) => item.key === 'COMMISSION-PREPARATION-SOLIDARITY-HEALTH-FUND'
    )

    if (additionalInformation.length > 0) {
      return {
        medicines: additionalInformation[0]?.medicines,
        supplies: additionalInformation[0]?.supplies,
        tariffDifference: additionalInformation[0]?.tariffDifference,
        milkFormula: additionalInformation[0]?.milkFormula,
        others: additionalInformation[0]?.others,
        service: additionalInformation[0]?.service,
        otherService: additionalInformation[0]?.otherService,
        commission: additionalInformation[0]?.commission
      }
    }

    return {
      medicines: false,
      supplies: false,
      tariffDifference: false,
      milkFormula: false,
      others: false,
      service: null,
      otherService: null,
      commission: null
    }
  }

  const typeProcess = getTypeProcess()

  const initialValues = {
    /* data General */
    stepProgram: currentStep?.step,
    isRequiredDescriptions: false,
    descriptions: '',
    isRequiredMoreDescriptions: false,
    moreDescriptions: [],
    isRequiredDocuments: false,
    documentsNames: [],
    documents: null,
    program: program?._id,
    currentKeyHistory: currentStep.key,
    keyHistory:
      GetKey.getNextStep(
        currentStep?.key,
        program?.type,
        program?.history,
        getTypeProcess()
      ) || GetKey.PostChangeStatus[program.type] /* es el siguiente paso */,
    state: program?.state,
    /* end data General */

    /* data NewbornBenefit */
    isRequiredDate: false,
    dateDelivery: '',
    isRequiredGiftCardActive: false,
    giftcardActive: false,
    /* end data NewbornBenefit */

    /* data university, pre-university, Technical-professional, Support In Case Of Death Of Member */
    isRequiredPayment: false,
    paymentVerification: '',
    /* end data university, pre-university, Technical-professional, Support In Case Of Death Of Member */

    /* data university, pre-universitary, Technical-professional, applicant-Officer */
    isRequiredAuthorization: false,
    orderAuthorization: '',
    isRequiredAmount: false,
    amount: '',
    isReuiredAlterProcess: false,
    alterProcess: '',
    isRequiredQuestionNewSemesters: false,
    responseQuestionNewSemesters: '',
    completedSemesters: program?.completedSemesters || 0,
    /* end data university, pre-universitary, Technical-professional, applicant-Officer */

    /* data Disaster-Support */
    isRequiredProposedState: false,
    proposedState: '',
    isRequiredOfficeStatus: false,
    officeStatus: '',
    /* end data Disaster-Support */

    /* data DisasterSupport */
    isRequiredVoucher: false,
    voucherName: [],
    voucher: null,
    isRequiredInstructive: false,
    instructiveName: [],
    instructive: null,
    /* end data DisasterSupport */

    /* data university Scholarships */
    isRequiredBackupFeeAmount: false,
    backupFeeAmountName: [],
    backupFeeAmount: null,
    isRequiredScholarshipCommission: false,
    scholarshipCommissionName: [],
    scholarshipCommission: null,
    isRequiredBankData: false,
    bankDataName: [],
    bankData: null,
    isRequiredPurchaseOrderVerification: false,
    purchaseOrderVerification: '',
    /* end data university Scholarships */

    /* data fss, incendio, catastrofe */
    isRequiredUsedAmount: false,
    usedAmount: '',
    /* end data fss, incendio, catastrofe */
    /* data university, pre-university, Technical Professional Scholarships */
    isRequiredTransferredAmount: false,
    transferredAmount: '',
    isRequiredOffice: false,
    officeName: [],
    office: null,
    isRequiredInvoice: false,
    invoiceName: [],
    invoice: null,
    isRequiredValidationOffice: false,
    validationOffice: '',
    isRequiredReportedEmail: false,
    reportedEmail: '',
    /* end data university, pre-university, Technical Professional Scholarships */

    /* data Monitor -WorkshopsAndTraining */
    isRequiredPaymentMonitors: false,
    classScheduleNames: [],
    classSchedule: null,
    feeBillNames: [],
    feeBill: null,
    attendanceSheetNames: [],
    attendanceSheet: null,
    isRequiredVerificationOfDocuments: false,
    verificationOfDocuments: '',
    /* end data Monitor -WorkshopsAndTraining */

    /* data hospital accommodation */
    isRequiredEntryDate: false,
    entryDate: '',
    entryHour: Schedule.hours.at(0),
    entryMinute: Schedule.minutes.at(0),
    entryPeriod: Schedule.periods.at(0),
    /* end hospital accommodation */

    /* data Solidarity Health Fund */
    isRequiredCommission: false,
    commission: '',
    isRequiredCheckProcessActive: false,
    medicines: false,
    supplies: false,
    tariffDifference: false,
    milkFormula: false,
    others: false,
    assistanceBenefits: false,
    someSelectedProcess: Boolean(
      currentStep?.key !== 'COMMISSION-PREPARATION-SOLIDARITY-HEALTH-FUND' ||
        currentStep?.key !== 'ALL-INVOICES-AND-TRADES-SOLIDARITY-HEALTH-FUND'
    ),
    isRequiredService: false,
    service: '',
    isRequiredOtherService: false,
    otherService: '',

    isRequiredTariffDifferenceDocuments: false,
    tariffDifferenceDocumentsNames: [],
    tariffDifferenceDocuments: null,

    isRequiredBoxPurchase: false,
    boxPurchaseName: [],
    boxPurchase: null,
    isRequiredPurchaseRequestLetter: false,
    purchaseRequestLetterName: [],
    purchaseRequestLetter: null,
    isRequiredQuotesReceived: false,
    quotesReceivedName: [],
    quotesReceived: null,
    isRequiredPurchaseRequestLetterSigned: false,
    purchaseRequestLetterSignedName: [],
    purchaseRequestLetterSigned: null,

    isRequiredCheckVouchersSend: false,
    vouchersSend: false,
    invoiceRequest: false,
    isRequiredDateSubmissionReceipts: false,
    dateSubmissionReceipts: '',
    isRequiredDateSubmissionReceiptsArr: false,
    dateSubmissionReceiptsArr: [],

    isRequiredDateCertificateRequest: false,
    dateCertificateRequest: '',
    isRequiredDateCertificateReception: false,
    dateCertificateReception: '',
    isRequiredDocumentsSentMedicalInstitution: false,
    documentsSentMedicalInstitutionName: [],
    documentsSentMedicalInstitution: null,

    isRequiredDateSend: false,
    dateSend: '',

    isRequiredDebtCertificate: false,
    debtCertificateName: [],
    debtCertificate: null,

    isRequiredBoxPurchaseTwo: false,
    boxPurchaseTwoName: [],
    boxPurchaseTwo: null,
    /* end data Solidarity Health Fund */

    canChangeState: false,
    canImcompleteStep: false,
    canDesistProgram: false,
    incompleteStep: false,
    desistProgram: false,
    blockedStep: false
  }
  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    validateOnChange: true,
    onSubmit: (values) => {
      const clearAmountValue = Boolean(
        requiredTransferredAmount?.includes(currentStep.key)
      )

      if (clearAmountValue) {
        values.amount = ''
      }

      if (values.dateSubmissionReceiptsArr.length > 0) {
        values.dateSubmissionReceiptsArr =
          values.dateSubmissionReceiptsArr?.map((item) => item.date)
      }

      const formData = GetFormData(
        values,
        [
          'documents',
          'voucher',
          'instructive',
          'backupFeeAmount',
          'scholarshipCommission',
          'bankData',
          'boxPurchase',
          'boxPurchaseTwo',
          'purchaseRequestLetter',
          'quotesReceived',
          'debtCertificate',
          'purchaseRequestLetterSigned',
          'documentsSentMedicalInstitution',
          'office',
          'invoice',
          'tariffDifferenceDocuments',
          'classSchedule',
          'feeBill',
          'attendanceSheet'
        ],
        ['moreDescriptions', 'dateSubmissionReceiptsArr']
      )
      const send = true

      if (!send) {
        console.log(values)
      }

      if (send) {
        mutate(formData, {
          onSuccess: () => {
            reset()
            formik.resetForm()
            onClose()
            GetNotification.basic(succesMessage, 'Aceptar')
          },
          onError: (err) => {
            const response = err?.response?.data

            GetNotification.basic(
              'Error',
              'Aceptar',
              response?.type === 'validateLastStep'
                ? response.message
                : errorMessage,
              'error'
            )
          }
        })
      }
    }
  })

  useEffect(() => {
    if (
      formik.values.others &&
      currentStep?.key === 'COMMISSION-PREPARATION-SOLIDARITY-HEALTH-FUND'
    ) {
      formik.setValues({
        ...formik.values,
        medicines: false,
        supplies: false,
        tariffDifference: false,
        milkFormula: false,
        service: '',
        otherService: '',
        assistanceBenefits: false
      })
    }
  }, [
    formik.values.others,
    formik.values.medicines,
    formik.values.supplies,
    formik.values.tariffDifference,
    formik.values.milkFormula,
    formik.values.assistanceBenefits
  ])

  const deleteFile = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.documentsNames,
      formik.values.documents
    )

    formik.setValues({
      ...formik.values,
      documentsNames: resultNames,
      documents: resultListFiles
    })
  }

  const handleChangeDocuments = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.documentsNames,
      formik.values.documents
    )

    formik.setValues({
      ...formik.values,
      documentsNames: newListNames,
      documents: newListFiles
    })
    e.target.value = null
  }

  const getTransferredAmountForNewbornBenefit = () => {
    if (currentStep?.key === 'GIFTCARD-RECEPTION') {
      const nroOfNewborns = program?.newbornList?.length
      return FormatMoney((40000 * nroOfNewborns).toString())
    }
    return ''
  }

  useEffect(() => {
    formik.setFieldValue(
      'isRequiredDocuments',
      Boolean(formik.values.purchaseOrderVerification === 'APPROVED')
    )
  }, [formik.values.purchaseOrderVerification])

  useEffect(() => {
    if (isOpen) {
      formik.resetForm(initialValues)

      /* --- */
      formik.setValues({
        ...initialValues,
        isRequiredDate: requiredDate.includes(currentStep?.key),
        isRequiredGiftCardActive: requiredGiftActivate.includes(
          currentStep?.key
        ),
        isRequiredCheckProcessActive:
          (requiredCheckProcess.includes(currentStep?.key) &&
            currentStep?.key ===
              'COMMISSION-PREPARATION-SOLIDARITY-HEALTH-FUND') ||
          (requiredCheckProcess.includes(currentStep?.key) &&
            typeProcess.others),
        isRequiredCommission: requiredCommission.includes(currentStep?.key),
        isRequiredCheckVouchersSend: requiredCheckVouchersSend.includes(
          currentStep?.key
        ),
        isRequiredDescriptions: requiredDescriptions.includes(currentStep?.key),
        isRequiredDocuments: requiredDocuments.includes(currentStep?.key),
        isRequiredPayment: requiredPayment.includes(currentStep?.key),
        isRequiredAuthorization: requiredAuthorization.includes(
          currentStep?.key
        ),
        isRequiredProposedState: requiredProposedState.includes(
          currentStep?.key
        ),
        isRequiredAmount: requiredAmount.includes(currentStep?.key),
        isRequiredQuestionNewSemesters: requiredQuestion.includes(
          currentStep?.key
        ),
        isRequiredOfficeStatus: requiredOfficeStatus.includes(currentStep?.key),
        isRequiredVoucher: requiredVoucher.includes(currentStep?.key),
        isRequiredInstructive: requiredInstructive.includes(currentStep?.key),
        isRequiredBackupFeeAmount: requiredBackupFeeAmount.includes(
          currentStep?.key
        ),
        isRequiredScholarshipCommission: requiredScholarshipCommission.includes(
          currentStep?.key
        ),
        isRequiredBankData: requiredBankData.includes(currentStep?.key),
        isRequiredOffice: requiredOffice.includes(currentStep?.key),
        isRequiredInvoice: requiredInvoice.includes(currentStep?.key),
        isRequiredTariffDifferenceDocuments:
          requiredTariffDifferenceDocuments.includes(currentStep?.key) &&
          (typeProcess.medicines || typeProcess.supplies) &&
          typeProcess.tariffDifference &&
          typeProcess.service === 'Dipreca',
        isRequiredTransferredAmount: requiredTransferredAmount.includes(
          currentStep?.key
        ),
        transferredAmount: getTransferredAmountForNewbornBenefit(),
        isRequiredUsedAmount: requiredUsedAmount.includes(currentStep?.key),
        isRequiredPaymentMonitors: requiredPaymentMonitors.includes(
          currentStep?.key
        ),
        isRequiredVerificationOfDocuments:
          requiredVerificationOfDocuments.includes(currentStep?.key),
        canChangeState: requiredChangeState.includes(currentStep?.key),
        canImcompleteStep: canImcompleteStep.includes(currentStep?.key),
        canDesistProgram: canDesistProgram[program.type]
          ? canDesistProgram[program.type].includes(currentStep?.key)
          : false,
        isRequiredMoreDescriptions: requiredMoreDescriptions.includes(
          currentStep?.key
        ),
        isRequiredValidationOffice: requiredVadiationOffice.includes(
          currentStep?.key
        ),
        isRequiredReportedEmail: requiredReportedEmail.includes(
          currentStep?.key
        ),
        isRequiredEntryDate: requiredEntryDate.includes(currentStep?.key),
        isRequiredBoxPurchase: requiredBoxPurchase.includes(currentStep?.key),
        isRequiredBoxPurchaseTwo: requiredBoxPurchaseTwo.includes(
          currentStep?.key
        ),
        isRequiredPurchaseRequestLetter: requiredPurchaseRequestLetter.includes(
          currentStep?.key
        ),
        isRequiredQuotesReceived:
          (requiredQuotesReceived.includes(currentStep?.key) &&
            !typeProcess.tariffDifference &&
            typeProcess.service !== 'Dipreca') ||
          (requiredQuotesReceived.includes(currentStep?.key) &&
            typeProcess.tariffDifference &&
            typeProcess.service === 'Otras') ||
          (requiredQuotesReceived.includes(currentStep?.key) &&
            (typeProcess.medicines || typeProcess.supplies) &&
            typeProcess.tariffDifference &&
            typeProcess.service === 'Dipreca'),
        isRequiredDebtCertificate: requiredDebtCertificate.includes(
          currentStep?.key
        ),
        isRequiredPurchaseRequestLetterSigned:
          requiredPurchaseRequestLetterSigned.includes(currentStep?.key),
        isRequiredDateSubmissionReceipts:
          requiredDateSubmissionReceipts.includes(currentStep?.key),
        isRequiredDateSubmissionReceiptsArr:
          requiredDateSubmissionReceiptsArr.includes(currentStep?.key),
        isRequiredDateCertificateRequest:
          requiredDateCertificateRequest.includes(currentStep?.key),
        isRequiredDateCertificateReception:
          requiredDateCertificateReception.includes(currentStep?.key),
        isRequiredDocumentsSentMedicalInstitution:
          requiredDocumentsSentMedicalInstitution.includes(currentStep?.key),
        isRequiredDateSend: requiredDateSend.includes(currentStep?.key),
        isRequiredPurchaseOrderVerification:
          requiredPurchaseOrderVerification.includes(currentStep?.key),
        isFreeProgram: program?.isFreeProgram
      })
    }
  }, [isOpen])

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      isRequiredDocuments:
        !formik.values.incompleteStep && !formik.values.desistProgram
    })
  }, [formik.values.incompleteStep, formik.values.desistProgram])

  useEffect(() => {
    if (
      formik.values.documentsNames.length > 0 &&
      !formik.values.isRequiredDocuments
    ) {
      formik.setValues({
        ...formik.values,
        documentsNames: [],
        documents: null
      })
    }
  }, [formik.values.isRequiredDocuments])

  useEffect(() => {
    if (isLoading) {
      toast({
        duration: 9000 * 9000,
        isClosable: true,
        position: 'bottom-right',
        render: ({ onClose: onCloseT }) => (
          <CustomToast
            text={'Espere por favor...'}
            status={'info'}
            onClose={onCloseT}
          />
        )
      })
      return
    }
    toast.closeAll()
  }, [isLoading])

  useEffect(() => {
    if (
      isOpen &&
      currentStep?.key === 'COMMISSION-PREPARATION-SOLIDARITY-HEALTH-FUND'
    ) {
      const { supplies, tariffDifference, medicines, milkFormula, others } =
        formik.values

      const validateProcess = Boolean(
        supplies || tariffDifference || medicines || milkFormula || others
      )

      formik.setFieldValue('someSelectedProcess', validateProcess)
    }

    if (
      isOpen &&
      currentStep?.key === 'ALL-INVOICES-AND-TRADES-SOLIDARITY-HEALTH-FUND' &&
      typeProcess.others
    ) {
      const {
        supplies,
        tariffDifference,
        medicines,
        milkFormula,
        others,
        assistanceBenefits
      } = formik.values

      const validateProcess = Boolean(
        supplies ||
          tariffDifference ||
          medicines ||
          milkFormula ||
          others ||
          assistanceBenefits
      )

      formik.setFieldValue('someSelectedProcess', validateProcess)
    }
  }, [
    formik.values.supplies,
    formik.values.tariffDifference,
    formik.values.medicines,
    formik.values.milkFormula,
    formik.values.others,
    formik.values.assistanceBenefits,
    isOpen
  ])

  return (
    <ModalHistory
      title={title}
      colors={getColor}
      disable={currentStep?.complete}
      isLoading={isLoading}
      onClick={formik.handleSubmit}
      onClose={onClose}
      isOpen={isOpen}
      onOpen={onOpen}
    >
      <Flex align={'center'} justify={'center'} direction={'column'} gap={5}>
        <Box w={'100%'}>
          <HStack spacing={4}>
            {formik.values.canDesistProgram && (
              <Button
                colorScheme="red"
                onClick={() => {
                  onClose()
                  dispatch(openResignationModal())
                }}
              >
                Desistir
              </Button>
            )}
            {formik.values.canImcompleteStep && (
              <SwitchSkipStep formik={formik} />
            )}
          </HStack>
          {(currentStep?.key ===
            'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND' ||
            currentStep?.key ===
              'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND-EDIT-SOLIDARITY-HEALTH-FUND') && (
            <FormControl
              display="flex"
              alignItems="center"
              justifyContent={'flex-end'}
              mb={4}
            >
              <FormLabel
                htmlFor="blockedStep"
                mb="0"
                fontSize={16}
                fontWeight={600}
                color={'green.100'}
              >
                Bloquear recepción de documentos
              </FormLabel>
              <Switch
                id="blockedStep"
                colorScheme={'green'}
                values={formik.values.blockedStep}
                onChange={formik.handleChange}
              />
            </FormControl>
          )}
          <RenderBottonsOptions
            formik={formik}
            buttonsProps={buttonsProps}
            buttonsPropsExtra={buttonsPropsExtra}
            currentStep={currentStep}
            program={program}
          />
          {keyToSearch && (
            <RenderPreviewPastStep
              program={program}
              keyToSearch={keyToSearch}
            />
          )}
          {validation && (
            <RenderValidationStep
              program={program}
              formik={formik}
              validation={validation}
            />
          )}

          <RenderInputRequired formik={formik} />
          <RenderVouchersRequired formik={formik} />
          <RenderDebtCertificateRequired
            formik={formik}
            typeProcess={typeProcess}
            currentStepKey={currentStep?.key}
          />
          {formik.values.isRequiredPaymentMonitors && (
            <RequestPaymentMonitors formik={formik} program={program} />
          )}
          <DocumentsUniversityOrder formik={formik} />
          <DocumentsSolidarityOrder
            formik={formik}
            program={program}
            typeProcess={typeProcess}
            currentStepKey={currentStep?.key}
          />
          <RenderGeneralDocument formik={formik} program={program} />
          {(formik.values.isRequiredVoucher ||
            formik.values.isRequiredInstructive) && (
            <VoucherIntructive formik={formik} />
          )}
          {formik.values.isRequiredCommission && (
            <InputComtrol
              isRequired={formik.values.isRequiredCommission}
              id={'commission'}
              label={'Comisión'}
              type={'text'}
              value={formik.values.commission}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.commission)}
              errorText={formik.errors.commission}
            />
          )}
          {currentStep?.key ===
            'ALL-INVOICES-AND-TRADES-SOLIDARITY-HEALTH-FUND' && (
            <RenderCommission program={program} />
          )}
          <RenderCheckProcessRequired
            formik={formik}
            stepKey={currentStep?.key}
          />
          <RenderSelectServiceRequired formik={formik} />
          {formik.values.service === 'Otras' && (
            <InputComtrol
              isRequired={formik.values.isRequiredOtherService}
              id={'otherService'}
              label={'Otro servicio'}
              type={'text'}
              value={formik.values.otherService}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.otherService)}
              errorText={formik.errors.otherService}
            />
          )}
          {/* General Info */}
          {(formik.values.isRequiredDocuments ||
            currentStep?.key ===
              'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND' ||
            currentStep?.key ===
              'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND-EDIT-SOLIDARITY-HEALTH-FUND' ||
            currentStep?.key ===
              'NOTIFICATION-ACT-SIGNED-BY-PARTNER-AND-SCHOLARSHIP-RECIPIENT-UNIVERSITY') && (
            <>
              <InputComtrol
                id={'documentsNames'}
                label={titleDocument || 'Añadir documento (opcional)'}
                isRequired={formik.values.isRequiredDocuments}
              >
                <UploadFile
                  id={'documentsNames'}
                  direction={'row'}
                  sizeIcon={30}
                  p={5}
                  multiple={true}
                  onChange={(e) => handleChangeDocuments(e)}
                  error={Boolean(formik.errors.documentsNames)}
                  errorText={formik.errors.documentsNames}
                />
              </InputComtrol>
              <Flex direction={'column'} gap={1}>
                {formik.values.documentsNames.map((item) => (
                  <BarFileName
                    key={item?.name}
                    fileName={item?.name}
                    fileUrl={item?.url}
                    onDelete={() => deleteFile(item?.name)}
                  />
                ))}
              </Flex>
            </>
          )}

          <TextAreaComtrol
            label={titleObservation || 'Observaciones (opcional)'}
            id={'descriptions'}
            isRequired={false}
            value={formik.values.descriptions}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.descriptions)}
            errorText={formik.errors.descriptions}
          />

          {formik.values.isRequiredMoreDescriptions && (
            <RenderArrayDescriptions formik={formik} />
          )}

          <RenderCheckRequired formik={formik} />

          {!requiredDocuments.includes(currentStep?.key) &&
            currentStep?.key !==
              'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND' &&
            currentStep?.key !==
              'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND-EDIT-SOLIDARITY-HEALTH-FUND' &&
            currentStep?.key !== 'STOCK-RECIEVED-NEWBORN-BENEFIT' && (
              <Checkbox
                css={{ borderColor: 'green' }}
                id={'isRequiredDocuments'}
                size="md"
                colorScheme="green"
                value={formik.values.isRequiredDocuments}
                onChange={formik.handleChange}
              >
                ¿Desea adjuntar documentos adicionales?
              </Checkbox>
            )}
          {/* end General Info */}
        </Box>
      </Flex>
    </ModalHistory>
  )
}

export default OtherProcesses
