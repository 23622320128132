/* eslint-disable import/prefer-default-export */
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

/* redux */
import { useDispatch } from 'react-redux'
import { loginUser, me } from '../store/slices/auth'

import { login, get_authentication } from '../api/login'

import { CustomToast } from '../components/UI'

const { useToast } = require('@chakra-ui/react')

export function useMutateLogin() {
  const dispatch = useDispatch()
  const history = useHistory()
  const toast = useToast()
  return useMutation(login, {
    onSuccess: (data) => {
      dispatch(loginUser(data))
      history.push('/')
      toast({
        duration: 9000,
        isClosable: true,
        position: 'bottom-right',
        render: ({ onClose }) => (
          <CustomToast
            text={`Bienvenido ${data.user.name || ''} ${
              data.user.lastName || ''
            }`}
            status={'success'}
            showSpinner={false}
            onClose={onClose}
          />
        )
      })
    }
  })
}

export function useMutateMe() {
  const dispatch = useDispatch()
  return useMutation(get_authentication, {
    onSuccess: (data) => {
      dispatch(me(data))
    }
  })
}
/* export function useMutateMe() {
  const dispatch = useDispatch()
  return useMutation(get_authentication, {
    onSuccess: (data) => {
      dispatch(me(data))
    },
    onError: (err) => {
      console.log(err)
      dispatch(logoutUser())
    }
  })
} */
