import React from 'react'
import { Flex } from '@chakra-ui/react'
import AvatarNameUser from '../AvatarNameUser'
import GetStatusCard from '../../../Utils/GetStatusCard'

const FinalStatus = ({ currentStep, program }) => (
  <>
    {
      <Flex direction={'column'} gap={2} justify={'center'} align={'center'}>
        {GetStatusCard.programTable(program.state)}
        <AvatarNameUser
          name={currentStep?.createdBy?.name}
          lastName={currentStep?.createdBy?.lastName}
          dateNotification={currentStep?.date}
        />
      </Flex>
    }
  </>
)

export default FinalStatus
