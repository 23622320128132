import { createSlice } from '@reduxjs/toolkit'

export const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    resignationOrLossModalOpen: false
  },
  reducers: {
    openResignationModal: (state) => {
      state.resignationOrLossModalOpen = true
    },
    closeResignationModal: (state) => {
      state.resignationOrLossModalOpen = false
    }
  }
})
export const { openResignationModal, closeResignationModal } =
  modalSlice.actions

export default modalSlice.reducer
