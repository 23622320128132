import React from 'react'
import { FormControl, FormLabel, Switch } from '@chakra-ui/react'

const SwitchSkipStep = ({ formik }) => (
  <FormControl
    display="flex"
    alignItems="center"
    justifyContent={'flex-end'}
    mb={4}
  >
    <FormLabel
      htmlFor="incompleteStep"
      mb="0"
      fontSize={16}
      fontWeight={600}
      color={'green.100'}
    >
      ¿Desea dejar este paso como incompleto?
    </FormLabel>
    <Switch
      id="incompleteStep"
      colorScheme={'green'}
      values={formik.values.incompleteStep}
      onChange={formik.handleChange}
    />
  </FormControl>
)

export default SwitchSkipStep
