import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  useToast,
  Tooltip
} from '@chakra-ui/react'
import { CopyElementIcon } from '../Icons'
import CustomToast from './CustomToast'

const InputComtrol = ({
  isRequired = true,
  id,
  label,
  placeholder,
  type = 'text',
  value,
  readOnly = false,
  disabled = false,
  onChange,
  children,
  error = false,
  errorText = '',
  addictionType = null,
  onBlur = null,
  ...props
}) => {
  const toast = useToast()
  /* const exceptThisSymbols = ['e', 'E', '+', '-'] */
  const alphabet = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z'
  ]
  const alphabetM = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z'
  ]
  const symbols = [
    '-',
    '+',
    '/',
    '*',
    '(',
    ')',
    ' ',
    ';',
    ':',
    '_',
    '@',
    '#',
    '$',
    '%',
    '&',
    '^',
    '~',
    '`',
    '"',
    "'",
    '{',
    '}',
    '[',
    ']',
    '|',
    '\\',
    '<',
    '>',
    '?',
    '¿',
    '¡',
    '!',
    '¬',
    '¦',
    '§',
    '¨',
    '©',
    '®',
    'ª',
    'º',
    'µ',
    '¶',
    '·',
    '=',
    '≠',
    '.',
    ','
  ]

  const createTypeInput = () => {
    let union = null
    if (type === 'number') {
      union = Array.from(new Set([...alphabet, ...alphabetM, ...symbols]))
      return union
    }
    if (type === 'currency') {
      union = Array.from(new Set([...alphabet, ...alphabetM, ...symbols]))
      union = union.filter((item) => item !== '.' && item !== ',')
      return union
    }
    if (type === 'credit-card') {
      union = Array.from(new Set([...alphabet, ...alphabetM, ...symbols]))
      union = union.filter((item) => item !== '-')
      return union
    }
    if (type === 'email') {
      union = ['ñ', 'Ñ', 'ç', 'Ç', 'ü', 'Ü', 'ö', 'Ö', 'ä', 'Ä', 'ß', 'ÿ', '€']

      return union
    }
    return union
  }

  const getType = () => {
    if (type === 'number') {
      return null
    }
    if (type === 'credit-card') {
      return null
    }
    return type
  }

  const getPlaceholder = () => {
    if (errorText) return errorText
    if (placeholder) return placeholder
    if (label) return label

    return ''
  }

  const notifyCopy = () => {
    toast({
      duration: 2000,
      isClosable: true,
      position: 'top-right',
      render: ({ onClose }) => (
        <CustomToast
          text={'Valor copiado al portapapeles'}
          showSpinner={false}
          status={'info'}
          onClose={onClose}
        />
      )
    })
  }

  const getInputType = (input) => {
    if (addictionType === 'phone') {
      return (
        <InputGroup {...props}>
          <InputLeftAddon children="(+56)" bg={'beige.100'} />
          {input}
        </InputGroup>
      )
    }
    if (addictionType === 'copy') {
      return (
        <InputGroup {...props}>
          {input}
          <CopyToClipboard text={value}>
            <InputRightAddon
              bg={'beige.100'}
              _hover={{ cursor: 'pointer' }}
              onClick={notifyCopy}
            >
              <Icon as={CopyElementIcon} />
            </InputRightAddon>
          </CopyToClipboard>
        </InputGroup>
      )
    }
    return input
  }

  return (
    <Tooltip hasArrow label={errorText} bg="red.600" borderRadius={4}>
      <FormControl isRequired={isRequired} mb={3}>
        <FormLabel htmlFor={id}>{label}</FormLabel>
        {!children
          ? getInputType(
              <Input
                id={id}
                name={id}
                focusBorderColor={'green.100'}
                placeholder={getPlaceholder()}
                _placeholder={errorText && { color: '#C53030' }}
                type={getType()}
                value={value || ''}
                onChange={onChange}
                autoComplete={'off'}
                isInvalid={error}
                readOnly={readOnly}
                disabled={disabled}
                onBlur={onBlur}
                onKeyDown={
                  createTypeInput()
                    ? (e) =>
                        createTypeInput().includes(e.key) && e.preventDefault()
                    : null
                }
                {...props}
              />
            )
          : children}
      </FormControl>
    </Tooltip>
  )
}

export default InputComtrol
