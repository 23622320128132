const config = {
  prod: {
    API_BASE: 'https://api.corporaciondeayuda.cl/api/v1'
  },
  test: {
    API_BASE: 'https://api-test.corporaciondeayuda.cl/api/v1'
  },
  dev: {
    API_BASE: 'http://localhost/api/v1'
  }
}

export default config
