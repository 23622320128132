/* eslint-disable import/prefer-default-export */
import { useMutation, useQuery } from 'react-query'

import { generateTemplateFile, getPartnersSync } from '../api/PartnersSync'

const key = 'partners-sync'

const getAction = (type) => {
  if (type === 'DOWNLOAD-TEMPLATE-FILE') return generateTemplateFile
  return null
}

export function usePartnersSync(filtersPartnersSync, page, limit, props = {}) {
  return useQuery(
    [key, filtersPartnersSync, page, limit],
    () => getPartnersSync(filtersPartnersSync, page, limit),
    {
      ...props
    }
  )
}

export function useMutatePartner(type) {
  const actionFn = getAction(type)
  return useMutation(actionFn, {
    onSuccess: () => {
      /* queryClient.invalidateQueries([key]) */
    }
  })
}
