import React, { useEffect, useState } from 'react'
import { MenuSelect, Spinner } from '../UI'
import { useFamilyMembers } from '../../hooks/FamilyMembers'

const SelectFamilyMember = ({
  onChange,
  familyMemberId,
  partnerId,
  setHasSpouse,
  addNewFamilyMember,
  error
}) => {
  const { data, isLoading, isError } = useFamilyMembers(partnerId)
  const [label, setLabel] = useState({
    label: '<Seleccione>',
    subLabel: null
  })

  useEffect(() => {
    if (data) {
      const exists = data?.filter(
        (familyMember) => familyMember?.relationship === 'Cónyuge'
      ).length

      setHasSpouse(exists > 0)
    }
  }, [data])

  useEffect(() => {
    if (!familyMemberId && !addNewFamilyMember) {
      setLabel({
        label: '<Seleccione>',
        subLabel: null
      })
      return
    }
    if (addNewFamilyMember) {
      setLabel({
        label: 'Agregar familiar',
        subLabel: null
      })
      return
    }

    const currentMember = data?.find((item) => item._id === familyMemberId)

    setLabel({
      label: currentMember
        ? currentMember?.fullName || 'Sin nombre asignado'
        : '<Seleccione>',
      subLabel: currentMember
        ? currentMember?.relationship || 'Sin relación asignada'
        : null
    })
  }, [familyMemberId, data, addNewFamilyMember])

  return (
    <>
      {isError && <p>{error}</p>}

      {!isLoading ? (
        <MenuSelect
          familyMemberList={data}
          familyMemberId={familyMemberId}
          addNewFamilyMember={addNewFamilyMember}
          label={label}
          onClick={(member) => onChange(member)}
          error={error}
        />
      ) : (
        <Spinner size={'md'} show={true} />
      )}
    </>
  )
}

export default SelectFamilyMember
