import React, { useEffect, useState } from 'react'
import { Flex, Grid, GridItem } from '@chakra-ui/react'
import { Months, RelationshipList3 } from '../../../../Utils'
import MonthLineChart from './MonthLineChart'
import TotalsPieChart from './TotalsPieChart'

const relationshipList = {
  'Socio(a)': 0,
  Otro: 0,
  Padre: 0
}
RelationshipList3.forEach((item) => {
  relationshipList[item] = 0
})
const initialDataMonth = Months?.getArrayNamesMonths()?.map((item) => ({
  ...relationshipList,
  month: item?.substring(0, 3)
}))

const initialDataPie = []
Object.keys(relationshipList).forEach((key) => {
  initialDataPie.push({
    name: key,
    value: 0
  })
})

const Metrics = ({ data }) => {
  const [dataMonth, setDataMonth] = useState(initialDataMonth)
  const [dataPie, setDataPie] = useState(initialDataPie)

  useEffect(() => {
    if (data?.dataByMonth) {
      const monthformated =
        data?.dataByMonth?.length > 0
          ? data?.dataByMonth?.map((item) => ({
              ...item,
              month: item?.month?.substring(0, 3)
            }))
          : initialDataMonth
      setDataMonth(monthformated)
    }

    const dataPieFormated = []
    // eslint-disable-next-line
    const { dataByMonth, year, zone, beneficiary, ...rest } = data

    Object.keys(rest).forEach((key) => {
      dataPieFormated.push({
        name: key,
        value: rest[key]
      })
    })
    setDataPie(dataPieFormated)
  }, [data])

  return (
    <Grid
      templateColumns={{
        lg: 'repeat(2, 70% 30%)',
        md: 'repeat(1, 100%)',
        sm: 'repeat(1, 100%)'
      }}
      h={{
        lg: '400px',
        md: '400px',
        sm: '400px'
      }}
      gap={2}
    >
      <GridItem>
        <MonthLineChart data={dataMonth} relationshipList={relationshipList} />
      </GridItem>
      {data?.totalRecords > 0 ? (
        <GridItem>
          <TotalsPieChart
            data={dataPie?.filter((i) => i?.name !== 'totalRecords')}
            total={
              dataPie?.find((item) => item?.name === 'totalRecords')?.value || 0
            }
          />
        </GridItem>
      ) : (
        <GridItem>
          <Flex justify={'center'} align={'center'} h={'100%'}>
            Sin resultados
          </Flex>
        </GridItem>
      )}
    </Grid>
  )
}

export default Metrics
