/* eslint-disable import/prefer-default-export */
import { useQuery } from 'react-query'
import { getDataToDashboard } from '../api/dashboard'

const key = 'dashboard'

export function useDashboard(filters) {
  return useQuery([key, filters], () => getDataToDashboard(filters), {
    refetchOnWindowFocus: false
  })
}
