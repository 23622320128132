import React from 'react'
import { Grid, GridItem, Text } from '@chakra-ui/react'
import { TextAreaComtrol, CardFilePreview } from '../../UI'

const AdditionalInformation = ({ program }) => (
  <>
    <Grid templateColumns={'repeat(1, 1fr)'}>
      {program?.observations && (
        <GridItem>
          <TextAreaComtrol
            isRequired={false}
            label={'Observaciones'}
            value={program?.observations}
            readOnly={true}
          />
        </GridItem>
      )}
    </Grid>
    {/* --- */}
    <Text fontWeight={600}>Documentos respaldatorios</Text>
    <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
      {program?.backupDocuments?.map((item) => (
        <GridItem>
          <CardFilePreview name={item.name} url={item.url} />
        </GridItem>
      ))}
    </Grid>
  </>
)

export default AdditionalInformation
