import { Flex, Grid, GridItem } from '@chakra-ui/react'
import { addDays } from 'date-fns'
import React from 'react'
import { Files, Schedule } from '../../../../Utils'
import {
  BarFileName,
  CustomDatePicker,
  InputComtrol,
  TextAreaComtrol,
  UploadFile,
  Select
} from '../../../UI'

const ExitForm = ({ formik, program }) => {
  const handleChangeNewExitDate = (date) => {
    if (date) {
      formik.setValues({
        ...formik.values,
        newExitDate: date
      })
    }
  }

  const handleChangeStartHour = (value) => {
    formik.setValues({
      ...formik.values,
      exitHour: value
    })
  }

  const handleChangeStartMinutes = (value) => {
    formik.setValues({
      ...formik.values,
      exitMinute: value
    })
  }

  const handleChangeStartPeriod = (value) => {
    formik.setValues({
      ...formik.values,
      exitPeriod: value
    })
  }

  const deleteFile = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.documentsNames,
      formik.values.documents
    )

    formik.setValues({
      ...formik.values,
      documentsNames: resultNames,
      documents: resultListFiles
    })
  }

  const handleChangeDocuments = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.documentsNames,
      formik.values.documents
    )

    formik.setValues({
      ...formik.values,
      documentsNames: newListNames,
      documents: newListFiles
    })
    e.target.value = null
  }

  return (
    <>
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          <InputComtrol id={'newExitDate'} label={'Fecha de salida'}>
            <CustomDatePicker
              placeholder={'DD/MM/YYYY'}
              currentDate={formik.values.newExitDate}
              onChange={(date) => handleChangeNewExitDate(date)}
              error={Boolean(formik.errors.newExitDate)}
              errorText={formik.errors.newExitDate}
              minDate={addDays(
                new Date(
                  program?.isRequiredExtension
                    ? program?.extensionDate
                    : program?.exitDate
                ),
                0
              )}
            />
          </InputComtrol>
        </GridItem>
        <GridItem>
          <InputComtrol label={'Hora'}>
            <Flex gap={1} align={'center'}>
              <Select
                list={Schedule.hours}
                value={formik.values.exitHour}
                bg={'white'}
                onChange={(value) => handleChangeStartHour(value)}
              />
              {':'}
              <Select
                list={Schedule.minutes}
                value={formik.values.exitMinute}
                bg={'white'}
                onChange={(value) => handleChangeStartMinutes(value)}
              />
              <Select
                list={Schedule.periods}
                value={formik.values.exitPeriod}
                bg={'white'}
                onChange={(value) => handleChangeStartPeriod(value)}
              />
            </Flex>
          </InputComtrol>
        </GridItem>
      </Grid>
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2} mb={4}>
        <GridItem>
          <InputComtrol
            id={'totalDays'}
            label={'Total de días hospedado'}
            type="number"
            isRequired={true}
            value={formik.values.totalDays}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.totalDays)}
            errorText={formik.errors.totalDays}
          />
        </GridItem>
        <GridItem>
          <TextAreaComtrol
            id={'descriptions'}
            label={'Observaciones (opcional)'}
            isRequired={formik.values.isRequiredDescription}
            value={formik.values.descriptions}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.descriptions)}
            errorText={formik.errors.descriptions}
          />
        </GridItem>
        <GridItem>
          <InputComtrol
            id={'documentsNames'}
            label={'Agrega documentación (opcional)'}
            isRequired={formik.values.isRequiredDocuments}
          >
            <UploadFile
              id={'documentsNames'}
              direction={'row'}
              sizeIcon={30}
              p={5}
              multiple={true}
              onChange={(e) => handleChangeDocuments(e)}
              error={Boolean(formik.errors.documentsNames)}
              errorText={formik.errors.documentsNames}
            />
          </InputComtrol>
          <Flex direction={'column'} gap={1}>
            {formik.values.documentsNames.map((item) => (
              <BarFileName
                key={item?.name}
                fileName={item?.name}
                fileUrl={item?.url}
                onDelete={() => deleteFile(item?.name)}
              />
            ))}
          </Flex>
        </GridItem>
      </Grid>
    </>
  )
}

export default ExitForm
