import React from 'react'
import { Flex, Text, Textarea } from '@chakra-ui/react'
import { CustomModal } from '../../UI'
import GetStatusCard from '../../../Utils/GetStatusCard'
import { useNotificationsSent } from '../../../hooks/NotificationsSent'
import { useTypeModalView } from '../../../hooks/TypeModal'
import AvatarNameUser from '../AvatarNameUser'
import RenderAdditionalCheck from './RenderAdditionalCheck'

const DetailsNotifyRegistration = ({
  currentStep,
  program,
  isOpen,
  onClose
}) => {
  const { title, notifiedBy, titleObservation } = useTypeModalView(
    currentStep?.key
  )
  const { data, isLoading, isFetching } = useNotificationsSent(
    program?._id,
    currentStep?.key,
    currentStep?.step
  )

  return (
    <>
      {!isLoading && !isFetching && (
        <CustomModal
          isOpen={isOpen}
          onClose={onClose}
          onClick={onClose}
          title={title}
          size={'2xl'}
          showButtons={false}
        >
          <Flex gap={4} direction={'column'} pb={10}>
            <AvatarNameUser
              name={data?.createby?.name}
              lastName={data?.createby?.lastName}
              dateNotification={data?.createdAt}
            />
            <Flex gap={4}>
              <Text fontWeight={700}>Notificado vía</Text>
              <Text>{notifiedBy}</Text>
            </Flex>
            <Flex gap={4} align={'center'}>
              <Text fontWeight={700}>Status de solicitud</Text>
              {GetStatusCard.program(program?.state)}
            </Flex>
            <RenderAdditionalCheck program={program} data={data} />
            <Flex gap={4} direction={'column'}>
              <Text fontWeight={700}>
                {titleObservation || 'Mensaje enviado'}
              </Text>
              <Textarea focusBorderColor={'green.500'} readOnly={true}>
                {data?.message}
              </Textarea>
            </Flex>
          </Flex>
        </CustomModal>
      )}
    </>
  )
}

export default DetailsNotifyRegistration
