import React from 'react'
import { Grid, GridItem, Text } from '@chakra-ui/react'
import { CardFilePreview } from '../../UI'

const ReasonRequest = ({ program }) => (
  <>
    <Grid templateColumns={'repeat(1, 1fr)'} gap={1} my={1}>
      {program?.reasonApplication?.map((item) => (
        <GridItem>
          <Text fontWeight={700} fontSize={'xl'}>
            Motivo de la solicitud
          </Text>
          <CardFilePreview name={item?.name} url={item?.url} />
        </GridItem>
      ))}
    </Grid>
    <Grid templateColumns={'repeat(1, 1fr)'} gap={1} my={1}>
      {program?.socialReport?.map((item) => (
        <GridItem>
          <Text fontWeight={700} fontSize={'xl'}>
            Formulario Social
          </Text>
          <CardFilePreview name={item?.name} url={item?.url} />
        </GridItem>
      ))}
    </Grid>
    {program?.additionalDocuments.length > 0 && (
      <Grid templateColumns={'repeat(1, 1fr)'} gap={1} my={1}>
        <Text fontWeight={700} fontSize={'xl'}>
          Documentación adicional
        </Text>
        {program?.additionalDocuments?.map((item) => (
          <GridItem>
            <CardFilePreview name={item?.name} url={item?.url} />
          </GridItem>
        ))}
      </Grid>
    )}
  </>
)

export default ReasonRequest
