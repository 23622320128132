import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { Box, Flex, useDisclosure, useToast } from '@chakra-ui/react'
import {
  Alert,
  Button,
  CustomToast,
  TextAreaComtrol,
  BarFileName,
  InputComtrol,
  UploadFile
} from '../../UI'
import ModalHistory from '../ModalHistory'
import { useMutateHistoryProgram } from '../../../hooks/HistoryProgram'
import GetStatusCard from '../../../Utils/GetStatusCard'
import { GetNotification, GetFormData, GetKey, Files } from '../../../Utils'

const FINAL_STEP_FILES = {
  PreUniversityScholarships: {
    documentsName: 'commitmentLetterNames',
    documents: 'commitmentLetter',
    inputLabel: 'Carta de compromiso'
  },
  FireSupport: {
    documentsName: 'depositReceiptNames',
    documents: 'depositReceipt',
    inputLabel: 'Recepción deposito',
    descriptionLabel: 'Información'
  },
  DisasterSupport: {
    documentsName: 'depositReceiptNames',
    documents: 'depositReceipt',
    inputLabel: 'Recepción deposito',
    descriptionLabel: 'Información'
  }
}

const FinalRemark = ({ currentStep, getColor, program }) => {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    mutate: endProcess,
    isLoading,
    reset
  } = useMutateHistoryProgram('OTHER-PROCESSES')

  const newState = {
    REJECTED: 'COMPLETED-REJECTED',
    APPROVED: 'COMPLETED-APPROVED',
    REVOKED: 'COMPLETED-REVOKED',
    DESISTED: 'DESISTED'
  }

  const initialValues = {
    stepProgram: currentStep?.step,
    descriptions: '',
    program: program?._id,
    keyHistory: GetKey.getFinalKey[program?.type],
    state: newState[program?.state],
    commitmentLetterNames: [],
    commitmentLetter: [],
    depositReceiptNames: [],
    depositReceipt: []
  }

  const documentsDataSquema = FINAL_STEP_FILES[program?.type]

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      const filesPerProgram = Object.values(FINAL_STEP_FILES).map(
        (item) => item.documents
      )
      const formData = GetFormData(values, [...filesPerProgram])

      endProcess(formData, {
        onSuccess: () => {
          reset()
          formik.resetForm()
          onClose()
          GetNotification.basic('Proceso finalizado con Éxito', 'Aceptar')
        },
        onError: (err) => {
          GetNotification.basic(
            'Error',
            'Aceptar',
            'Error finalizar Proceso',
            'error'
          )
          throw new Error(err)
        }
      })
    }
  })

  useEffect(() => {
    if (isLoading) {
      toast({
        duration: 9000 * 9000,
        isClosable: true,
        position: 'bottom-right',
        render: ({ onClose: onCloseT }) => (
          <CustomToast
            text={'Espere por favor...'}
            status={'info'}
            onClose={onCloseT}
          />
        )
      })
      return
    }
    toast.closeAll()
  }, [isLoading])

  useEffect(() => {
    if (isOpen) {
      formik.resetForm()
    }
  }, [isOpen])

  const deleteFile = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values[documentsDataSquema.documentsName],
      formik.values[documentsDataSquema.documents]
    )

    formik.setValues({
      ...formik.values,
      [documentsDataSquema.documentsName]: resultNames,
      [documentsDataSquema.documents]: resultListFiles
    })
  }

  const handleChangeDocuments = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values[documentsDataSquema.documentsName],
      formik.values[documentsDataSquema.documents]
    )

    formik.setValues({
      ...formik.values,
      [documentsDataSquema.documentsName]: newListNames,
      [documentsDataSquema.documents]: newListFiles
    })
    e.target.value = null
  }

  return (
    <ModalHistory
      title={'Finalizar Proceso'}
      colors={getColor}
      disable={currentStep?.complete}
      isLoading={isLoading}
      onClick={formik.handleSubmit}
      onClose={onClose}
      isOpen={isOpen}
      onOpen={onOpen}
      showButtons={Boolean(!program?.stepsIncomplete?.length > 0)}
    >
      <Flex align={'center'} justify={'center'} direction={'column'} gap={5}>
        {!program?.stepsIncomplete?.length > 0 ? (
          <>
            <Flex gap={8} align={'center'} justify={'center'} w={'100%'}>
              {GetStatusCard.program(newState[program?.state])}
            </Flex>
            {Object.keys(FINAL_STEP_FILES).includes(program.type) && (
              <>
                <InputComtrol
                  id={documentsDataSquema?.documentsName}
                  label={documentsDataSquema?.inputLabel}
                  isRequired={false}
                >
                  <UploadFile
                    id={documentsDataSquema?.documentsName}
                    direction={'row'}
                    sizeIcon={30}
                    p={5}
                    multiple={true}
                    onChange={(e) => handleChangeDocuments(e)}
                  />
                </InputComtrol>
                <Flex gap={1}>
                  {formik.values[documentsDataSquema.documentsName]?.map(
                    (item) => (
                      <BarFileName
                        key={item?.name}
                        fileName={item?.name}
                        fileUrl={item?.url}
                        onDelete={() => deleteFile(item?.name)}
                      />
                    )
                  )}
                </Flex>
              </>
            )}
            <Box w={'100%'}>
              <TextAreaComtrol
                id={'descriptions'}
                label={
                  documentsDataSquema?.descriptionLabel ||
                  'Descripción (Opcional)'
                }
                isRequired={false}
                value={formik.values.descriptions}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.descriptions)}
                errorText={formik.errors.descriptions}
              />
            </Box>
          </>
        ) : (
          <>
            <Alert
              status={'warning'}
              message={
                'Actualmente tienes pasos incompletos dentro del flujo, favor completarlos para poder finalizar el proceso.'
              }
            />
            <Button text={'Aceptar'} onClick={onClose} />
          </>
        )}
      </Flex>
    </ModalHistory>
  )
}

export default FinalRemark
