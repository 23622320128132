import { Redirect } from 'react-router-dom'
import Layout from '../components/Layout/Layout'
/* --- */
import partnersRoutes from './partners'
import programsRoutes from './programs'
import usersRoutes from './users'
import notificationsRoutes from './notifications'
import reportabilityRoutes from './reportability'
/* --- */
import Login from '../pages/Login'
import Dashboard from '../pages/Dashboard'
import ResetPassword from '../pages/ResetPassword'

const routes = [
  {
    path: '/',
    key: 'INDEX',
    exact: true,
    component: () => <Redirect to="/dashboard" />
  },
  {
    path: '/dashboard',
    key: 'DASHBOARD',
    exact: true,
    component: ({ authenticated }) =>
      authenticated ? (
        <Layout>
          <Dashboard />
        </Layout>
      ) : (
        <Redirect to="/Login" />
      )
  },
  {
    path: '/login',
    key: 'LOGIN',
    exact: true,
    component: ({ authenticated }) =>
      authenticated ? <Redirect to="/dashboard" /> : <Login />
  },
  {
    path: '/new-password/:resetToken',
    key: 'LOGIN',
    exact: true,
    component: ({ authenticated }) =>
      authenticated ? <Redirect to="/dashboard" /> : <ResetPassword />
  },
  ...partnersRoutes,
  ...programsRoutes,
  ...usersRoutes,
  ...notificationsRoutes,
  ...reportabilityRoutes
]
export default routes
