import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import { useSelector } from 'react-redux'
import { addDays } from 'date-fns'
import {
  Grid,
  GridItem,
  Flex,
  Text,
  List,
  ListItem,
  useToast
} from '@chakra-ui/react'
import {
  InputComtrol,
  CustomDatePicker,
  UploadFile,
  Button,
  Select,
  CustomToast,
  BarFileName,
  CardFilePreview,
  TextAreaComtrol
} from '../UI'
import { partnerSchema } from './Schema'
import { useMutatePartner } from '../../hooks/Partners'
import {
  ZonesTwoList,
  FormatRut,
  GetFormData,
  GetNotification,
  GradesCarabineros,
  Files,
  FormatCode
} from '../../Utils'

const availableTo = [
  'ADMINISTRADOR',
  'ASISTENTE_SOCIAL',
  'DEPTO_CONTROL_GESTION_SOCIAL',
  'FINANZAS',
  'ABASTECIMIENTO'
]

const FormCreatePartners = ({ type, partner = null }) => {
  const toast = useToast()
  const history = useHistory()
  const { mutate, isLoading, reset } = useMutatePartner(type)
  const { user } = useSelector((state) => state.auth)

  const initialValues = {
    name: '',
    rut: '',
    code: '',
    grade: GradesCarabineros.at(0),
    zone: ZonesTwoList.getRegions().at(0),
    prefecture: ZonesTwoList.getCommunes(ZonesTwoList.getRegions().at(0)).at(0),
    endowment: '',
    insertDate: '',
    phone: '',
    email: '',
    tipcarsNames: [],
    tipcars: null,
    registrationFormNames: [],
    registrationForm: null,
    applicationNames: [],
    application: null,
    resignationDocumentNames: [],
    resignationDocument: null,
    resignationDescription: '',
    state: ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: partnerSchema,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    onSubmit: (values) => {
      const formData = GetFormData(
        values,
        ['tipcars', 'application', 'resignationDocument', 'registrationForm'],
        [
          'tipcarsNames',
          'applicationNames',
          'resignationDocumentNames',
          'registrationFormNames'
        ]
      )

      if (type === 'CREATE') {
        mutate(formData, {
          onSuccess: () => {
            reset()
            formik.resetForm()
            GetNotification.basic('Socio creado con Éxito', 'Aceptar')
            history.push('/partners')
          },
          onError: (err) => {
            const response = JSON.parse(err.request.response)
            GetNotification.basic(
              'Error',
              'Aceptar',
              response?.error?.message || 'Error al Crear socio',
              'error'
            )
          }
        })
      }
      if (type === 'UPDATE') {
        const { _id: partnerId } = partner

        GetNotification.question(
          '¿Seguro que deseas actualizar el socio?',
          () =>
            mutate(
              { partnerId, formData },
              {
                onSuccess: () => {
                  reset()
                  GetNotification.basic(
                    'Socio Actualizado con Éxito',
                    'Aceptar'
                  )
                  history.push('/partners')
                },
                onError: (err) => {
                  const response = JSON.parse(err.request.response)
                  console.log(response)
                  GetNotification.basic(
                    'Error',
                    'Aceptar',
                    response?.error?.message || 'Error al Actualizar socio',
                    'error'
                  )
                }
              }
            ),
          null,
          '',
          'warning'
        )
      }
    }
  })

  const deleteResignationDocument = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.resignationDocumentNames,
      formik.values.resignationDocument
    )

    formik.setValues({
      ...formik.values,
      resignationDocumentNames: resultNames,
      resignationDocument: resultListFiles
    })
  }

  const handleChangeResignationDocument = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.resignationDocumentNames,
      formik.values.resignationDocument
    )

    formik.setValues({
      ...formik.values,
      resignationDocumentNames: newListNames,
      resignationDocument: newListFiles
    })
    e.target.value = null
  }

  const handleValidatePartner = () => {
    formik.setValues({
      ...formik.values,
      state: 'ACTIVE'
    })
    formik.handleSubmit()
  }

  useEffect(() => {
    if (isLoading) {
      toast({
        duration: 9000 * 9000,
        isClosable: true,
        position: 'bottom-right',
        render: ({ onClose }) => (
          <CustomToast
            text={'Espere por favor...'}
            status={'info'}
            onClose={onClose}
          />
        )
      })
    }
    if (!isLoading) {
      toast.closeAll()
    }
  }, [isLoading])

  const handleChangeRut = () => {
    if (formik.values.rut) {
      const formatedRut = FormatRut(formik.values.rut)
      formik.setFieldValue('rut', formatedRut)
    }
  }

  const handleChangeDate = (date) => {
    formik.setFieldValue('insertDate', date)
  }

  const handleChangegrade = (value) => {
    formik.setFieldValue('grade', value)
  }

  const formatCode = () => {
    if (formik.values.code) {
      const formatedCode = FormatCode(formik.values.code)
      formik.setFieldValue('code', formatedCode)
    }
  }

  const deleteTipcars = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles: resultTipcars } =
      getArrayNamesAndArrayFiles(
        fileName,
        formik.values.tipcarsNames,
        formik.values.tipcars
      )

    formik.setValues({
      ...formik.values,
      tipcarsNames: resultNames,
      tipcars: resultTipcars
    })
  }

  const deleteRegistrationForm = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.registrationFormNames,
      formik.values.registrationForm
    )

    formik.setValues({
      ...formik.values,
      registrationFormNames: resultNames,
      registrationForm: resultListFiles
    })
  }

  const handleChangeTipcars = (e) => {
    if (!e.currentTarget.files) {
      return
    }
    const { getListNamesAndListFiles } = Files

    const { newListNames: newTipcarsNames, newListFiles: newTipcars } =
      getListNamesAndListFiles(
        e.currentTarget,
        formik.values.tipcarsNames,
        formik.values.tipcars
      )

    formik.setValues({
      ...formik.values,
      tipcarsNames: newTipcarsNames,
      tipcars: newTipcars
    })
    e.target.value = null
  }

  const handleChangeRegistrationForm = (e) => {
    if (!e.currentTarget.files) {
      return
    }
    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.registrationFormNames,
      formik.values.registrationForm
    )

    formik.setValues({
      ...formik.values,
      registrationFormNames: newListNames,
      registrationForm: newListFiles
    })
    e.target.value = null
  }

  useEffect(() => {
    if (type === 'UPDATE' && partner) {
      formik.setValues({
        name: partner?.name || '',
        rut: partner?.rut || '',
        code: partner?.code || '',
        grade: partner?.grade || GradesCarabineros.at(0),
        zone: partner?.zone || ZonesTwoList.getRegions().at(0),
        prefecture:
          partner?.prefecture ||
          ZonesTwoList.getCommunes(ZonesTwoList.getRegions().at(0)).at(0),
        endowment: partner?.endowment || '',
        insertDate: partner?.insertDate || '',
        phone: partner?.phone || '',
        email: partner?.email || '',
        tipcarsNames: partner?.tipcars || [],
        applicationNames: partner?.application || [],
        registrationFormNames: partner?.registrationForm || [],
        registrationForm: null,
        tipcars: null,
        application: null,
        state: partner?.state || '',
        resignationDocumentNames: partner?.resignationDocument || [],
        resignationDocument: null,
        resignationDescription: partner?.resignationDescription || ''
      })
    }
  }, [type])

  useEffect(() => {
    if (formik.isSubmitting && Object.keys(formik.errors).length > 0) {
      Object.keys(formik.errors).forEach((key) => {
        toast({
          duration: 2000,
          isClosable: true,
          position: 'top-right',
          render: ({ onClose }) => (
            <CustomToast
              text={formik.errors[key]}
              showSpinner={false}
              status={'error'}
              onClose={onClose}
            />
          )
        })
      })
    }
  }, [formik.errors])

  const handleChangeZone = (value) => {
    formik.setFieldValue('zone', value)
    formik.setFieldValue('prefecture', ZonesTwoList.getCommunes(value).at(0))
  }

  const handleChangePrefecture = (value) => {
    formik.setFieldValue('prefecture', value)
  }

  return (
    <Grid
      templateColumns={{
        lg: 'repeat(2, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem w={'100%'} color={'green.500'}>
        {type === 'UPDATE' && (
          <Text fontWeight={700} fontSize={22} color={'green.500'} mb={4}>
            INFORMACIÓN
          </Text>
        )}
        <Flex mb={3} align={{ lg: 'row', md: 'column', sm: 'column' }} gap={2}>
          <InputComtrol
            id={'name'}
            label={'Nombre'}
            value={formik.values.name}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.name)}
            errorText={formik.errors.name}
          />
        </Flex>
        {/* --- */}
        <Flex mb={3} align={{ lg: 'row', md: 'column', sm: 'column' }} gap={2}>
          <InputComtrol
            id={'rut'}
            label={'Rut'}
            value={formik.values.rut}
            onChange={formik.handleChange}
            onBlur={handleChangeRut}
            error={Boolean(formik.errors.rut)}
            errorText={formik.errors.rut}
          />
          <InputComtrol
            id={'code'}
            label={'Código Func. ej: 001001-D'}
            value={formik.values.code}
            onBlur={formatCode}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.code)}
            errorText={formik.errors.code}
          />
        </Flex>
        {/* --- */}
        <InputComtrol id={'grade'} label={'Grado'}>
          <Select
            bg={'white'}
            list={GradesCarabineros}
            value={formik.values.grade}
            onChange={handleChangegrade}
          />
        </InputComtrol>
        {/* --- */}
        <InputComtrol
          id={'zone'}
          label={'Zona'}
          error={Boolean(formik.errors.zone)}
          errorText={formik.errors.zone}
        >
          <Select
            bg={'white'}
            list={ZonesTwoList.getRegions()}
            value={formik.values.zone}
            onChange={handleChangeZone}
          />
        </InputComtrol>
        <InputComtrol
          id={'prefecture'}
          label={'Alta repartición / Repartición'}
          error={Boolean(formik.errors.prefecture)}
          errorText={formik.errors.prefecture}
        >
          <Select
            bg={'white'}
            list={ZonesTwoList.getCommunes(formik.values.zone)}
            value={formik.values.prefecture}
            onChange={handleChangePrefecture}
          />
        </InputComtrol>
        <InputComtrol
          isRequired={false}
          id={'endowment'}
          label={'Unidad'}
          value={formik.values.endowment}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.endowment)}
          errorText={formik.errors.endowment}
        />
        {/* --- */}
        <Flex mb={3} align={{ lg: 'row', md: 'column', sm: 'column' }} gap={2}>
          <InputComtrol id={'insertDate'} label={'Fec. Ingreso o Contratación'}>
            <CustomDatePicker
              placeholder={'Ingreso o Contratación'}
              currentDate={formik.values.insertDate}
              onChange={(date) => {
                handleChangeDate(date)
              }}
              error={Boolean(formik.errors.insertDate)}
              errorText={formik.errors.insertDate}
              maxDate={addDays(new Date(), 0)}
            />
          </InputComtrol>

          <InputComtrol
            isRequired={false}
            id={'phone'}
            label={'Teléfono'}
            addictionType={'phone'}
            type={'number'}
            value={formik.values.phone}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.phone)}
            errorText={formik.errors.phone}
          />
        </Flex>
        {/* --- */}
        <InputComtrol
          isRequired={false}
          id={'email'}
          label={'Correo Electrónico'}
          type={'email'}
          value={formik.values.email}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.email)}
          errorText={formik.errors.email}
        />
      </GridItem>
      <GridItem w={'100%'}>
        <Flex direction={'column'} mb={3}>
          <Text fontWeight={700} fontSize={22} color={'green.500'}>
            ADJUNTAR DOCUMENTOS
          </Text>
          <List spacing={3} ml={{ lg: 3, md: 3, sm: 0 }} color={'grey.500'}>
            <ListItem>&#9679; Personal uniformado: Imagen Tipcar</ListItem>
            <ListItem>
              &#9679; Personal CPR: Fotocopia Cédula de Identidad o Tipcar
            </ListItem>
          </List>
        </Flex>

        <UploadFile
          id={'tipcarsNames'}
          p={5}
          onChange={(e) => handleChangeTipcars(e)}
          error={Boolean(formik.errors.tipcarsNames)}
          errorText={formik.errors.tipcarsNames}
        />
        <Flex direction={'column'} gap={1} my={2}>
          {formik?.values?.tipcarsNames?.map((item) => (
            <BarFileName
              key={item?.name}
              fileName={item?.name}
              fileUrl={item?.url}
              onDelete={() => deleteTipcars(item?.name)}
            />
          ))}
        </Flex>
        {/* --- */}
        <Flex direction={'column'} mb={3}>
          <List spacing={3} ml={{ lg: 3, md: 3, sm: 0 }} color={'grey.500'}>
            <ListItem>&#9679; Ficha de inscripción</ListItem>
          </List>
        </Flex>

        <UploadFile
          id={'registrationFormNames'}
          p={5}
          onChange={(e) => handleChangeRegistrationForm(e)}
          error={Boolean(formik.errors.registrationFormNames)}
          errorText={formik.errors.registrationFormNames}
        />
        <Flex direction={'column'} gap={1} my={2}>
          {formik?.values?.registrationFormNames?.map((item) => (
            <BarFileName
              key={item?.name}
              fileName={item?.name}
              fileUrl={item?.url}
              onDelete={() => deleteRegistrationForm(item?.name)}
            />
          ))}
        </Flex>

        {/* --- */}
        {/* {partner?.state === 'NOT-VALIDATED' && (
          <>
            <Flex direction={'column'} mb={3}>
              <List spacing={3} ml={{ lg: 3, md: 3, sm: 0 }} color={'grey.500'}>
                <ListItem>&#9679; Documento de postulación </ListItem>
              </List>
            </Flex>
            <Flex direction={'column'} gap={2} mb={3}>
              {formik?.values?.applicationNames.map((item) => (
                <CardFilePreview
                  key={item.url}
                  name={item.name}
                  url={item.url}
                />
              ))}
            </Flex>
          </>
        )} */}
        <Flex justify={'flex-end'} align={'end'}>
          {partner?.state !== 'NOT-VALIDATED' && (
            <Button
              text={type === 'CREATE' ? 'Guardar' : 'Actualizar'}
              px={10}
              onClick={formik?.handleSubmit}
              isLoading={isLoading}
              disabled={isLoading || !availableTo.includes(user.role)}
            ></Button>
          )}
          {partner?.state === 'NOT-VALIDATED' && (
            <>
              <Button
                text={'Validar y Guardar'}
                px={10}
                onClick={handleValidatePartner}
                isLoading={isLoading}
                disabled={isLoading || !availableTo.includes(user.role)}
              />
            </>
          )}
        </Flex>
      </GridItem>
      {/* --- */}
      {partner?.state === 'RESIGNED' && (
        <GridItem
          w={'100%'}
          p={2}
          border={'2px dotted #82b378'}
          borderRadius={8}
        >
          <Text mb={2} color={'green.100'}>
            Registro de Renuncia:
          </Text>
          <hr />
          <InputComtrol
            label={'Adjunta Documentación (Opcional)'}
            isRequired={false}
          >
            <UploadFile
              direction={'row'}
              sizeIcon={30}
              p={5}
              onChange={(e) => {
                handleChangeResignationDocument(e)
              }}
              id={'resignationDocumentNames'}
              error={Boolean(formik.errors.resignationDocumentNames)}
              errorText={formik.errors.resignationDocumentNames}
            />
            <Flex direction={'column'} gap={1}>
              {formik.values.resignationDocumentNames?.map((item) => (
                <BarFileName
                  key={item?.name}
                  fileName={item?.name}
                  fileUrl={item?.url}
                  onDelete={() => deleteResignationDocument(item?.name)}
                />
              ))}
            </Flex>
          </InputComtrol>

          <TextAreaComtrol
            id={'resignationDescription'}
            isRequired={false}
            label={'Motivo de Renuncia'}
            value={formik.values.resignationDescription}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.resignationDescription)}
            errorText={formik.errors.resignationDescription}
          />
        </GridItem>
      )}
    </Grid>
  )
}

export default FormCreatePartners
