import * as Yup from 'yup'
/* eslint-disable import/prefer-default-export */
export const steepOneSchema = Yup.object().shape({
  receptionDate: Yup.string().required(
    'La fecha de recepción de la solicitud es requerida'
  ),
  isPartnerZone: Yup.boolean(),
  zone: Yup.string().required('La zona es requerida'),
  prefecture: Yup.string().required('La prefectura es requerida'),
  unit: Yup.string().required('La unidad es requerida'),
  civilStatus: Yup.string().required('El estado civil es requerido'),
  region: Yup.string().required('La región es requerida'),
  commune: Yup.string().required('La comuna es requerida'),
  address: Yup.string().required('La dirección es requerida'),
  residenceCondition: Yup.string().required(
    'Las condiciones de residencia son requeridas'
  ),
  alternativePhone: Yup.string()
    .min(8, 'El telefono no es valido')
    .max(9, 'El telefono no es valido'),
  /* partner data */
  partnerRut: Yup.string()
    .min(11, 'El Rut no es valido')
    .max(12, 'El Rut no es valido'),
  partnerPhone: Yup.string()
    .min(8, 'El telefono no es valido')
    .max(9, 'El telefono no es valido')
    .required('El telefono es requerido'),
  partnerGrade: Yup.string(),
  partnerCode: Yup.string()
    .min(3, 'El código no puede tener menos de 3 caracteres')
    .max(8, 'El código no puede tener más de 8 caracteres')
    .test(
      'last-caracter',
      'El ultimo caracter del código debe ser una letra',
      (value) => {
        if (!value) {
          return true
        }
        const lastChar = value?.slice(-1)
        return /^[a-zA-Z]+$/.test(lastChar)
      }
    )
    .test(
      'start-code',
      'Los primeros caracteres antes del guión del código deben ser números',
      (value) => {
        if (!value) {
          return true
        }
        const valueArray = value.split('-')
        const lengthFirstChar = valueArray[0].length
        const firstChar = value.slice(0, lengthFirstChar)
        return /^[0-9]+$/.test(firstChar)
      }
    ),
  partnerZone: Yup.string(),
  partnerPrefecture: Yup.string(),
  partnerEndowment: Yup.string()
})

export const steepTwoSchema = Yup.object().shape({
  postulantName: Yup.string()
    .max(40, 'El nombre debe tener menos de 40 caracteres')
    .required('El nombre es requerido'),
  phonePostulant: Yup.string()
    .min(8, 'El telefono no es valido')
    .max(9, 'El telefono no es valido')
    .required('El teléfono es requerido'),
  dateOfBirth: Yup.string().required('La fecha de nacimiento es requerida'),
  currentInstitution: Yup.string().required(
    'La institución actual es requerida'
  ),
  institutionToStudy: Yup.string().required('La institución es requerida'),
  currentYear: Yup.string().required('El año actual es requerido'),
  annualFee: Yup.string().required('El Arancel anual es requerido'),
  addressPostulant: Yup.string().required('El domicilio es requerido'),
  email: Yup.string()
    .email('Ingrese un Email Valido')
    .required('El correo electrónico es requerido'),
  age: Yup.string().required('La edad es requerida'),
  institutionCareer: Yup.string().required(
    'La carrera universitaria es requerida'
  ),
  scoreAverage: Yup.string().required('El promedio de notas es requerido'),

  livesWith: Yup.string().required('El campo es requerido'),
  isRequiredSpecification: Yup.boolean(),
  specification: Yup.string().when('isRequiredSpecification', {
    is: true,
    then: Yup.string().required('La especificación es requerida')
  })
})

export const steepThreeSchema = Yup.object().shape({
  birthCertificateName: Yup.array()
    .required('El archivo es requerido')
    .min(1, 'Es necesario al menos un archivo'),
  scoreCertificateName: Yup.array()
    .required('El archivo es requerido')
    .min(1, 'Es necesario al menos un archivo'),
  parentsLastSalaryNames: Yup.array()
    .required('El archivo es requerido')
    .min(1, 'Es necesario al menos un archivo'),
  motivationLetterName: Yup.array()
    .required('El archivo es requerido')
    .min(1, 'Es necesario al menos un archivo'),
  applicationSignedByPartnerName: Yup.array()
    .required('El archivo es requerido')
    .min(1, 'Es necesario al menos un archivo'),
  scoreCertificateSantoTomasName: Yup.array(),
  socialReportName: Yup.array()
    .required('El archivo es requerido')
    .min(1, 'Es necesario al menos un archivo')
})

export const steepFourSchema = Yup.object().shape({
  additionalDocumentsNames: Yup.array(),
  observations: Yup.string()
})
