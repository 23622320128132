import React, { useState } from 'react'
import {
  Box,
  Flex,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { Alert, CustomPaginate, Search, Spinner } from '../../../UI'
import { usePartnersWithFamilyMembers } from '../../../../hooks/Partners'
import PartnerItem from './PartnerItem'

const SearchBar = ({ formik }) => {
  const initialFilters = {
    page: 0,
    limit: 3,
    search: '',
    relationship: 'Cónyuge'
  }
  const [filters, setFilters] = useState(initialFilters)
  const {
    data: partners,
    isLoading,
    isFetching,
    isError
  } = usePartnersWithFamilyMembers(filters, filters.page, filters.limit)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleChangePartner = (e) => {
    setFilters({
      ...filters,
      search: e.target.value
    })
  }

  return (
    <>
      <Popover
        returnFocusOnClose={false}
        isOpen={isOpen}
        placement={'bottom'}
        closeOnBlur={false}
      >
        <PopoverTrigger>
          <Box tabIndex={'0'} p={1} w={'100%'}>
            <Search
              currentValue={filters.search}
              onChange={handleChangePartner}
              showBtn={false}
              onFocus={onOpen}
            />
            {filters.search === '' && (
              <Text fontSize={'md'} color={'green.100'} textAlign={'center'}>
                Buscar socio por nombre, apellido, rut o código
              </Text>
            )}
          </Box>
        </PopoverTrigger>
        <PopoverContent
          boxShadow={'dark-lg'}
          w={{
            lg: '6xl',
            md: 'lg',
            sm: 'md'
          }}
          borderColor={'grey.100'}
        >
          <PopoverHeader>
            <Flex justify={'space-between'} mr={6}>
              <Text fontWeight={500}>Resultados:</Text>
              <Spinner size={'sm'} show={isLoading || isFetching} />
            </Flex>
          </PopoverHeader>
          <PopoverCloseButton onClick={onClose} />
          <PopoverBody>
            {isError && (
              <Flex w={'100%'} justify={'flex-end'} py={3}>
                <Alert status={'error'} message={'Error al Cargar los Datos'} />
              </Flex>
            )}
            <Flex
              direction={'column'}
              justify={'center'}
              align={'center'}
              gap={1}
              py={5}
            >
              {!isError &&
                !isLoading &&
                !isFetching &&
                partners?.currentPages?.map((item) => (
                  <PartnerItem key={item._id} partner={item} formik={formik} />
                ))}
              {!isError &&
                !isLoading &&
                !isFetching &&
                partners?.totalRecords > 0 && (
                  <Flex justify={'center'}>
                    <CustomPaginate
                      totalRecords={partners?.totalRecords}
                      page={filters.page}
                      setPage={(page) => setFilters({ ...filters, page })}
                    />
                  </Flex>
                )}
            </Flex>
            {(partners?.totalRecords <= 0 || !partners) && (
              <Flex justify={'center'} align={'center'}>
                Sin Resultados
              </Flex>
            )}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  )
}

export default SearchBar
