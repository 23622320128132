import React, { useState } from 'react'
import {
  Grid,
  GridItem,
  Text,
  Flex,
  Icon,
  Box,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  Tooltip
} from '@chakra-ui/react'
import { useReportability } from '../../hooks/Reportability'
import { Button, InputComtrol, Select, Spinner } from '../UI'
import { GradesCarabineros, Months } from '../../Utils'
import { SearchIcon } from '../Icons'
import AveragePerYearAndMonthSkeleton from '../SkeletonToPage/AveragePerYearAndMonthSkeleton'

const AveragePerYearAndMonth = () => {
  const {
    data: arrayYears,
    isLoading: isLoadingYears,
    isFetching: isFetchingYears
  } = useReportability('YEARS-PROGRAMS', ['APPROVED', 'COMPLETED-APPROVED'])
  const [filters, setFilters] = useState({
    grade: ['TODOS', ...GradesCarabineros].at(0),
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    monthName: Months.getNameMonths(new Date().getMonth() + 1)
  })
  const [finalFilters, setFinalFilters] = useState({ ...filters })
  const { data, isLoading, isFetching } = useReportability(
    'AVG-YEAR-MONTH',
    finalFilters
  )

  const handleChangeSelect = (value, type) => {
    if (type === 'YEAR') {
      setFilters({
        ...filters,
        year: value
      })
    }
    if (type === 'GRADE') {
      setFilters({
        ...filters,
        grade: value
      })
    }
    if (type === 'MONTH') {
      setFilters({
        ...filters,
        month: Months.getNumberMonth(value),
        monthName: Months.getNameMonths(Months.getNumberMonth(value))
      })
    }
  }

  const searchAvg = () => {
    setFinalFilters({ ...filters })
  }

  const formatValue = (value) => {
    if (value?.length > 15) {
      return `${value.substring(0, 15)}...`
    }
    return value
  }

  const CustomTooltip = ({ label, children }) => (
    <Tooltip hasArrow label={label} bg={'#d68c49'}>
      {children}
    </Tooltip>
  )

  return (
    <Grid templateColumns={'repeat(1, minmax(0, 1fr))'} gap={2}>
      <GridItem>
        <Text
          fontSize={'2xl'}
          color={'green.500'}
          fontWeight={700}
          noOfLines={1}
        >
          Montos promedio por mes
        </Text>
      </GridItem>
      <GridItem>
        <Flex align={'center'} justify={'space-between'} gap={2}>
          <Flex
            w={{
              lg: '50%',
              md: '75%',
              sm: '75%'
            }}
            gap={2}
          >
            <InputComtrol id={'grade'} label={'Grado'} isRequired={false}>
              <Select
                list={['TODOS', ...GradesCarabineros]}
                value={filters.grade}
                bg={'white'}
                onChange={(value) => handleChangeSelect(value, 'GRADE')}
              />
            </InputComtrol>
            <InputComtrol label={'Año'} isRequired={false}>
              <Select
                list={arrayYears}
                value={filters.year}
                bg={'white'}
                onChange={(value) => handleChangeSelect(value, 'YEAR')}
                disabled={isFetchingYears || isLoadingYears}
              />
            </InputComtrol>

            <InputComtrol label={'Mes'} isRequired={false}>
              <Select
                list={Months.getArrayNamesMonths()}
                value={filters.monthName}
                bg={'white'}
                onChange={(value) => handleChangeSelect(value, 'MONTH')}
                disabled={isFetchingYears || isLoadingYears}
              />
            </InputComtrol>
          </Flex>
          <Box>
            <Button
              text={'Buscar'}
              rightIcon={<Icon as={SearchIcon} color={'white'} />}
              isLoading={isLoading || isFetching}
              onClick={searchAvg}
            />
          </Box>
        </Flex>
      </GridItem>
      <GridItem
        w={{
          base: '50%',
          lg: '50%',
          md: '100%',
          sm: '100%'
        }}
      >
        <Flex gap={2}>
          <Flex
            borderRadius={8}
            bg={'green.50'}
            p={2}
            w={'50%'}
            justify={'center'}
            align={'center'}
          >
            {!isLoading && !isFetching ? (
              <Text
                fontSize={'xl'}
                color={'green.500'}
                fontWeight={700}
                noOfLines={1}
              >
                {data?.grade}
              </Text>
            ) : (
              <Spinner size={'md'} show={true} />
            )}
          </Flex>
          <Flex
            borderRadius={8}
            bg={'green.50'}
            p={2}
            w={'50%'}
            justify={'center'}
            align={'center'}
          >
            {!isLoading && !isFetching ? (
              <Text
                fontSize={'xl'}
                color={'green.500'}
                fontWeight={700}
                noOfLines={1}
              >
                {data?.year}
              </Text>
            ) : (
              <Spinner size={'md'} show={true} />
            )}
          </Flex>
          <Flex
            borderRadius={8}
            bg={'green.50'}
            p={2}
            w={'50%'}
            justify={'center'}
            align={'center'}
          >
            {!isLoading && !isFetching ? (
              <Text
                fontSize={'xl'}
                color={'green.500'}
                fontWeight={700}
                noOfLines={1}
              >
                {Months.getNameMonths(data?.month)}
              </Text>
            ) : (
              <Spinner size={'md'} show={true} />
            )}
          </Flex>
        </Flex>
      </GridItem>
      {!isLoading && !isFetching ? (
        <GridItem>
          <Flex
            direction={'column'}
            justify={'center'}
            align={'center'}
            borderRadius={12}
            boxShadow={'lg'}
            p={4}
            gap={2}
          >
            <Text fontSize={'lg'} fontWeight={700} noOfLines={1}>
              Promedio {Months.getNameMonths(data?.month) || ''}{' '}
              {data?.year || ''}
            </Text>
            <CustomTooltip label={`Promedio: ${data?.avgformat || 0}`}>
              <Text fontSize={'4xl'} fontWeight={700} noOfLines={1}>
                $ {formatValue(data?.avgformat) || 0} clp
              </Text>
            </CustomTooltip>
            <RangeSlider
              defaultValue={[0, 50]}
              isReadOnly={true}
              colorScheme={'green'}
            >
              <RangeSliderTrack>
                <RangeSliderFilledTrack />
              </RangeSliderTrack>
              <RangeSliderThumb index={0} bg={'green.500'} />
              <RangeSliderThumb index={1} bg={'green.500'} />
            </RangeSlider>
            <Flex justify={'space-between'} w={'100%'}>
              <CustomTooltip label={`Mín: ${data?.minformat || 0}`}>
                <Flex
                  direction={'column'}
                  justify={'center'}
                  align={'center'}
                  gap={1}
                >
                  <Text fontSize={'xs'} fontWeight={700} noOfLines={1}>
                    Mín
                  </Text>

                  <Text fontSize={'md'} fontWeight={700} noOfLines={1}>
                    ${formatValue(data?.minformat) || 0}
                  </Text>
                </Flex>
              </CustomTooltip>
              <CustomTooltip label={`Máx: ${data?.maxformat || 0}`}>
                <Flex
                  direction={'column'}
                  justify={'center'}
                  align={'center'}
                  gap={1}
                >
                  <Text fontSize={'xs'} fontWeight={700} noOfLines={1}>
                    Máx
                  </Text>
                  <Text fontSize={'md'} fontWeight={700} noOfLines={1}>
                    ${formatValue(data?.maxformat) || 0}
                  </Text>
                </Flex>
              </CustomTooltip>
            </Flex>
          </Flex>
        </GridItem>
      ) : (
        <AveragePerYearAndMonthSkeleton />
      )}
    </Grid>
  )
}

export default AveragePerYearAndMonth
