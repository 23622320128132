import React from 'react'
import { Flex, Text, Icon } from '@chakra-ui/react'
import { CheckIcon, MinusIcon, RejectedRedIcon } from '../../Icons'

const translateKeys = {
  'NOT-AUTHORIZED': 'No autorizado',
  AUTORIZED: 'Autorizado',
  BACK: 'Retroceder proceso',
  FINISH: 'Finalizar proceso',
  APPROVED: 'Aprobado',
  REJECTED: 'Rechazado',
  EDIT: 'A corregir'
}
const translateStatus = {
  'NOT-AUTHORIZED': {
    color: 'red',
    icon: RejectedRedIcon
  },
  AUTORIZED: {
    color: 'green',
    icon: CheckIcon
  },
  APPROVED: {
    color: 'green',
    icon: CheckIcon
  },
  REJECTED: {
    color: 'red',
    icon: RejectedRedIcon
  },
  EDIT: {
    color: 'orange',
    icon: MinusIcon
  }
}

const RernderSelectedOption = ({ title, variable }) => (
  <Flex gap={4} align={'center'}>
    <Text fontWeight={700}>{title}:</Text>

    <Flex
      gap={2}
      bg={`${translateStatus[variable].color}.50`}
      color={`${translateStatus[variable].color}.500`}
      justify={'center'}
      align={'center'}
      borderRadius={10}
      p={2}
    >
      <Icon as={translateStatus[variable].icon} />
      {translateKeys[variable]}
    </Flex>
  </Flex>
)

export default RernderSelectedOption
