import * as Yup from 'yup'
/* eslint-disable import/prefer-default-export */
export const resetPasswordSchema = Yup.object().shape({
  password: Yup.string().required('Este campo es requerido'),
  repeatPassword: Yup.string()
    .required('Este campo es requerido')
    .when('password', (password, schema) =>
      schema.test({
        test: (repeatPassword) => password === repeatPassword,
        message: 'Las contraseñas no coinciden'
      })
    ),
  resetToken: Yup.string().required()
})
