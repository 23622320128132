import React from 'react'
import { Flex } from '@chakra-ui/react'

const InfoCard = ({
  direction = 'column',
  bg = 'green.50',
  color = 'green.500',
  py = 2,
  px = 2,
  my = 0,
  opacity = 1,
  borderColor = '#82B378',
  children
}) => (
  <Flex
    direction={direction}
    border={`solid 1px ${borderColor}`}
    align={'center'}
    justify={'center'}
    textAlign={'center'}
    bg={bg}
    color={color}
    borderRadius={10}
    py={py}
    px={px}
    my={my}
    opacity={opacity}
  >
    {children}
  </Flex>
)

export default InfoCard
