import React from 'react'
import {
  Flex,
  InputGroup,
  InputLeftElement,
  Input,
  Icon
} from '@chakra-ui/react'
import { SearchIcon, SearchWhiteIcon } from '../Icons'
import SquareButton from './SquareButton'

const Search = ({
  currentValue,
  onChange,
  onClick,
  showBtn = true,
  ...props
}) => (
  <Flex gap={2}>
    <InputGroup>
      <InputLeftElement
        pointerEvents={'none'}
        children={<Icon my={2} fontSize={26} as={SearchIcon} />}
      />
      <Input
        focusBorderColor={'green.500'}
        borderColor={'green.100'}
        type={'text'}
        placeholder={'Buscar'}
        value={currentValue}
        onChange={onChange}
        {...props}
      />
    </InputGroup>
    {showBtn && (
      <SquareButton onClick={onClick} bg={'green.500'} bghover={'green.100'}>
        <Icon my={2} fontSize={26} as={SearchWhiteIcon} />
      </SquareButton>
    )}
  </Flex>
)

export default Search
