import React, { useState, useCallback } from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'

const MonthBarChart = ({ data, relationshipList }) => {
  const keysArray = Object.keys(relationshipList)
  const relationshipOpacity = {}
  const COLORS = {
    'Socio(a)': '#F56767',
    Madre: '#2e7423',
    Padre: '#22B378',
    'Hijo(a)': '#18A0FB',
    'Nieto(a)': '#4A75CB',
    Cónyuge: '#D1AEFF',
    'Hermano(a)': '#8408cc',
    Otro: '#5e5e5c'
  }

  Object?.keys(relationshipList)?.forEach((item) => {
    relationshipOpacity[item] = 0.4
  })
  const [opacity, setOpacity] = useState(relationshipOpacity)
  const [position, setPosition] = useState('1')

  const getPosition = (key) => {
    if (key) {
      const currentPosition = keysArray.indexOf(key) + 1
      return currentPosition?.toString() || ''
    }
    return ''
  }

  const handleMouseEnter = useCallback(
    (o) => {
      const { dataKey } = o

      setOpacity({ ...opacity, [dataKey]: 1 })
      setPosition(getPosition(dataKey))
    },
    [opacity, setOpacity]
  )

  const handleMouseLeave = useCallback(
    (o) => {
      const { dataKey } = o
      setOpacity({ ...opacity, [dataKey]: 0.4 })
    },
    [opacity, setOpacity]
  )

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart width={400} height={400} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" />
        <YAxis />
        <Tooltip />
        <Legend
          iconType="circle"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
        {Object?.keys(relationshipList)?.map((key) => (
          <Line
            key={key}
            type="monotone"
            dataKey={key}
            strokeOpacity={opacity[key]}
            stroke={COLORS[key]}
            activeDot={{ r: 8 }}
            strokeWidth={opacity[key] === 1 ? 8 : 4}
            strokeDasharray={position}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  )
}

export default MonthBarChart
