import React from 'react'
import { Flex, Grid, GridItem } from '@chakra-ui/react'
import { InputComtrol, TextAreaComtrol } from '../../../UI'
import { ClearFormatMoney, FormatMoney } from '../../../../Utils'

const HealthForm = ({ formik }) => {
  const formatMonthlyExpenditure = () => {
    const format = FormatMoney(
      ClearFormatMoney(formik.values.monthlyExpenditure)
    )
    formik.setFieldValue('monthlyExpenditure', format)
  }

  return (
    <Flex
      direction={'column'}
      border={'solid 1px #2E7423'}
      borderRadius={18}
      p={6}
      my={1}
      gap={1}
    >
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          <InputComtrol
            id={'doctorName'}
            label={'Médico tratante'}
            isRequired={false}
            value={formik.values.doctorName}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.doctorName)}
            errorText={formik.errors.doctorName}
          />
          <InputComtrol
            id={'hospitalName'}
            label={'Hospital y/o clínica donde se trata'}
            isRequired={false}
            value={formik.values.hospitalName}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.hospitalName)}
            errorText={formik.errors.hospitalName}
          />
          <InputComtrol
            id={'monthlyExpenditure'}
            label={'Gasto mensual'}
            placeholder={'$'}
            type={'currency'}
            value={formik.values.monthlyExpenditure}
            onChange={formik.handleChange}
            onBlur={formatMonthlyExpenditure}
            error={Boolean(formik.errors.monthlyExpenditure)}
            errorText={formik.errors.monthlyExpenditure}
          />
        </GridItem>
        <GridItem>
          <InputComtrol
            id={'diagnostic'}
            label={'Diagnostico médico'}
            isRequired={false}
            value={formik.values.diagnostic}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.diagnostic)}
            errorText={formik.errors.diagnostic}
          />
          <InputComtrol
            id={'evolutionTime'}
            label={'Tiempo evolución enfermedad'}
            isRequired={false}
            value={formik.values.evolutionTime}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.evolutionTime)}
            errorText={formik.errors.evolutionTime}
          />
        </GridItem>
      </Grid>

      <TextAreaComtrol
        id={'observation'}
        label={'Observaciones (opcional)'}
        isRequired={false}
        value={formik.values.observation}
        onChange={formik.handleChange}
        error={Boolean(formik.errors.observation)}
        errorText={formik.errors.observation}
      />
    </Flex>
  )
}

export default HealthForm
