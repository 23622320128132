import * as Yup from 'yup'
/* eslint-disable import/prefer-default-export */
export const schema = Yup.object().shape({
  descriptions: Yup.string(),
  isRequiredDate: Yup.boolean(),
  dateDelivery: Yup.string().when('isRequiredDate', {
    is: true,
    then: Yup.string().required('La fecha de entrega es requerida')
  }),
  isRequiredDocuments: Yup.boolean(),
  documentsNames: Yup.array().when('isRequiredDocuments', {
    is: true,
    then: Yup.array()
      .min(1, 'Se requieren al Menos 1 documento')
      .required('Se requieren al Menos 1 documento')
  }),
  isRequiredPayment: Yup.boolean(),
  paymentVerification: Yup.string().when('isRequiredPayment', {
    is: true,
    then: Yup.string().required('La verificación de pago es requerida')
  }),
  isRequiredAuthorization: Yup.boolean(),
  orderAuthorization: Yup.string().when('isRequiredAuthorization', {
    is: true,
    then: Yup.string().required('La autorización de orden es requerida')
  }),
  isReuiredAlterProcess: Yup.boolean(),
  alterProcess: Yup.string().when('isReuiredAlterProcess', {
    is: true,
    then: Yup.string().required('El proceso de alteración es requerido')
  }),
  isRequiredProposedState: Yup.boolean(),
  proposedState: Yup.string().when('isRequiredProposedState', {
    is: true,
    then: Yup.string().required('El estado propuesto es requerido')
  }),
  isRequiredAmount: Yup.boolean(),
  amount: Yup.string().when('isRequiredAmount', {
    is: true,
    then: Yup.string().required('El monto es requerido')
  }),
  isRequiredOfficeStatus: Yup.boolean(),
  officeStatus: Yup.string().when('isRequiredOfficeStatus', {
    is: true,
    then: Yup.string().required('validación de estado es requerido')
  }),
  isRequiredVoucher: Yup.boolean(),
  voucherName: Yup.array().when('isRequiredVoucher', {
    is: true,
    then: Yup.array()
      .min(1, 'Se requieren al Menos 1 documento')
      .required('El documento es requerido')
  }),
  isRequiredInstructive: Yup.boolean(),
  instructiveName: Yup.array().when('isRequiredInstructive', {
    is: true,
    then: Yup.array()
      .min(1, 'Se requieren al Menos 1 documento')
      .required('El documento es requerido')
  }),
  isRequiredTransferredAmount: Yup.boolean(),
  transferredAmount: Yup.string().when('isRequiredTransferredAmount', {
    is: true,
    then: Yup.string().required('El monto transferido es requerido')
  }),
  isRequiredBackupFeeAmount: Yup.boolean(),
  backupFeeAmountName: Yup.array().when('isRequiredBackupFeeAmount', {
    is: true,
    then: Yup.array()
      .min(1, 'Se requieren al Menos 1 documento')
      .required('El docuemnto es requerido')
  }),
  isRequiredScholarshipCommission: Yup.boolean(),
  scholarshipCommissionName: Yup.array().when(
    'isRequiredScholarshipCommission',
    {
      is: true,
      then: Yup.array()
        .min(1, 'Se requieren al Menos 1 documento')
        .required('El docuemnto es requerido')
    }
  ),
  isRequiredBankData: Yup.boolean(),
  bankDataName: Yup.array().when('isRequiredBankData', {
    is: true,
    then: Yup.array()
      .min(1, 'Se requieren al Menos 1 documento')
      .required('El docuemnto es requerido')
  }),
  isRequiredOffice: Yup.boolean(),
  officeName: Yup.array().when(['isRequiredOffice', 'validationOffice'], {
    is: (isRequiredOffice, validationOffice) =>
      isRequiredOffice === true && validationOffice !== 'EDIT',
    then: Yup.array()
      .min(1, 'Se requieren al Menos 1 documento')
      .required('El docuemnto es requerido')
  }),
  isRequiredInvoice: Yup.boolean(),
  invoiceName: Yup.array().when('isRequiredInvoice', {
    is: true,
    then: Yup.array()
  }),
  isRequiredValidationOffice: Yup.boolean(),
  validationOffice: Yup.string().when('isRequiredValidationOffice', {
    is: true,
    then: Yup.string().required('La validación del officio es requerida')
  }),
  /* data Monitor  */
  isRequiredPaymentMonitors: Yup.boolean(),
  classScheduleNames: Yup.array().when('isRequiredPaymentMonitors', {
    is: true,
    then: Yup.array()
      .min(1, 'Se requieren al Menos 1 documento')
      .required('El docuemnto es requerido')
  }),
  feeBillNames: Yup.array().when('isRequiredPaymentMonitors', {
    is: true,
    then: Yup.array()
      .min(1, 'Se requieren al Menos 1 documento')
      .required('El docuemnto es requerido')
  }),
  attendanceSheetNames: Yup.array().when('isRequiredPaymentMonitors', {
    is: true,
    then: Yup.array()
      .min(1, 'Se requieren al Menos 1 documento')
      .required('El docuemnto es requerido')
  }),
  isRequiredReportedEmail: Yup.boolean(),
  reportedEmailName: Yup.array().when('isRequiredReportedEmail', {
    is: true,
    then: Yup.array()
      .min(1, 'Se requieren al Menos 1 documento')
      .required('El docuemnto es requerido')
  }),
  /* end data Monitor  */
  isRequiredAmountCancel: Yup.boolean(),
  amountCancelName: Yup.array().when('isRequiredAmountCancel', {
    is: true,
    then: Yup.array()
      .min(1, 'Se requieren al Menos 1 documento')
      .required('El docuemnto es requerido')
  }),
  isRequiredPurchaseDetail: Yup.boolean(),
  purchaseDetailName: Yup.array().when('isRequiredPurchaseDetail', {
    is: true,
    then: Yup.array()
      .min(1, 'Se requieren al Menos 1 documento')
      .required('El docuemnto es requerido')
  }),
  isRequiredActa: Yup.boolean(),
  actaName: Yup.array().when('isRequiredActa', {
    is: true,
    then: Yup.array()
      .min(1, 'Se requieren al Menos 1 documento')
      .required('El docuemnto es requerido')
  }),
  canImcompleteStep: Yup.boolean(),
  incompleteStep: Yup.boolean()
})
