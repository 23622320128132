import { createIcon } from '@chakra-ui/react'
/* https://freeicons.io/ui-essentials-3/ui-essentials-notification-alarm-bell-icon-44563 */
/* eslint-disable import/prefer-default-export */
const DocIcon = createIcon({
  displayName: 'DocIcon',
  viewBox: '0 0 17 22',
  path: [
    <path
      d="M2.67344 21.4754C1.92344 21.4754 1.29444 21.2211 0.786438 20.7124C0.277771 20.2044 0.0234375 19.5754 0.0234375 18.8254V3.17539C0.0234375 2.42539 0.277771 1.79606 0.786438 1.28739C1.29444 0.779391 1.92344 0.525391 2.67344 0.525391H9.59844C9.94844 0.525391 10.2861 0.592057 10.6114 0.725391C10.9361 0.858724 11.2234 1.05039 11.4734 1.30039L16.1984 6.02539C16.4484 6.27539 16.6401 6.56272 16.7734 6.88739C16.9068 7.21272 16.9734 7.55039 16.9734 7.90039V18.8254C16.9734 19.5754 16.7191 20.2044 16.2104 20.7124C15.7024 21.2211 15.0734 21.4754 14.3234 21.4754H2.67344ZM9.32344 3.17539H2.67344V18.8254H14.3234V8.17539H10.6484C10.2818 8.17539 9.96944 8.04606 9.71144 7.78739C9.45277 7.52939 9.32344 7.21706 9.32344 6.85039V3.17539ZM2.67344 3.17539V6.85039C2.67344 7.21706 2.67344 7.52939 2.67344 7.78739C2.67344 8.04606 2.67344 8.17539 2.67344 8.17539V3.17539V6.85039C2.67344 7.21706 2.67344 7.52939 2.67344 7.78739C2.67344 8.04606 2.67344 8.17539 2.67344 8.17539V18.8254V3.17539Z"
      fill={'currentColor' || '#82B378'}
    />
  ]
})

export default DocIcon
