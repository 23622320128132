import * as Yup from 'yup'
import { ClearFormatMoney } from '../../../../Utils'
/* eslint-disable import/prefer-default-export */
export const schema = Yup.object().shape({
  currentKeyHistory: Yup.string().required(),
  key: Yup.string().required('Este campo es requerido'),
  isRequiredDescriptions: Yup.boolean(),
  descriptions: Yup.string().when('isRequiredDescriptions', {
    is: true,
    then: Yup.string().required('La observación es requerida')
  }),
  isRequiredMoreDescriptions: Yup.boolean(),
  moreDescriptions: Yup.array(),
  isRequiredDate: Yup.boolean(),
  dateDelivery: Yup.string().when('isRequiredDate', {
    is: true,
    then: Yup.string().required('La fecha de entrega es requerida')
  }),
  isRequiredDocuments: Yup.boolean(),
  documentsNames: Yup.array().when('isRequiredDocuments', {
    is: true,
    then: Yup.array()
      .min(1, 'Se requieren al Menos 1 documento')
      .required('Se requieren al Menos 1 documento')
  }),
  isRequiredPayment: Yup.boolean(),
  paymentVerification: Yup.string().when('isRequiredPayment', {
    is: true,
    then: Yup.string().required('La verificación de pago es requerida')
  }),
  isRequiredAuthorization: Yup.boolean(),
  orderAuthorization: Yup.string().when('isRequiredAuthorization', {
    is: true,
    then: Yup.string().required('La autorización de orden es requerida')
  }),
  isReuiredAlterProcess: Yup.boolean(),
  alterProcess: Yup.string().when('isReuiredAlterProcess', {
    is: true,
    then: Yup.string().required('El proceso de alteración es requerido')
  }),
  isRequiredProposedState: Yup.boolean(),
  proposedState: Yup.string().when('isRequiredProposedState', {
    is: true,
    then: Yup.string().required('El estado propuesto es requerido')
  }),
  isRequiredAmount: Yup.boolean(),
  amount: Yup.string().when('isRequiredAmount', {
    is: true,
    then: Yup.string().required('El monto es requerido')
  }),
  isRequiredOfficeStatus: Yup.boolean(),
  officeStatus: Yup.string().when('isRequiredOfficeStatus', {
    is: true,
    then: Yup.string().required('validación de estado es requerido')
  }),
  isRequiredVoucher: Yup.boolean(),
  voucherName: Yup.array().when('isRequiredVoucher', {
    is: true,
    then: Yup.array()
      .min(1, 'Se requieren al Menos 1 documento')
      .required('El documento es requerido')
  }),
  isRequiredInstructive: Yup.boolean(),
  instructiveName: Yup.array().when('isRequiredInstructive', {
    is: true,
    then: Yup.array()
      .min(1, 'Se requieren al Menos 1 documento')
      .required('El documento es requerido')
  }),
  isRequiredTransferredAmount: Yup.boolean(),
  transferredAmount: Yup.string().when('isRequiredTransferredAmount', {
    is: true,
    then: Yup.string()
      .required('El monto transferido es requerido')
      .test(
        'valid-for-sc-si-fss',
        'El monto transferido no puede ser mayor al valor estimado de ayuda',
        (value, context) => {
          const { amount, currentKeyHistory } = context.parent

          const isKeyOfffs = Boolean(
            currentKeyHistory === 'USED-AMOUNT-SOLIDARITY-HEALTH-FUND'
          )

          const amountClean = ClearFormatMoney(amount || '0')
          const usedAmountClean = ClearFormatMoney(value || '0')

          let isValid = true

          if (!isKeyOfffs) {
            isValid =
              !amountClean ||
              !usedAmountClean ||
              parseFloat(amountClean) >= parseFloat(usedAmountClean)
          }

          return isValid
        }
      )
  }),
  isRequiredUsedAmount: Yup.boolean(),
  usedAmount: Yup.string().when('isRequiredUsedAmount', {
    is: true,
    then: Yup.string().required('El monto es requerido')
  }),
  isRequiredBackupFeeAmount: Yup.boolean(),
  backupFeeAmountName: Yup.array().when('isRequiredBackupFeeAmount', {
    is: true,
    then: Yup.array()
      .min(1, 'Se requieren al Menos 1 documento')
      .required('El docuemnto es requerido')
  }),
  isRequiredScholarshipCommission: Yup.boolean(),
  scholarshipCommissionName: Yup.array().when(
    'isRequiredScholarshipCommission',
    {
      is: true,
      then: Yup.array()
        .min(1, 'Se requieren al Menos 1 documento')
        .required('El docuemnto es requerido')
    }
  ),
  isRequiredBankData: Yup.boolean(),
  bankDataName: Yup.array(),
  isRequiredOfficeUniversity: Yup.boolean(),
  officeUniversityName: Yup.array().when('isRequiredOfficeUniversity', {
    is: true,
    then: Yup.array()
      .min(1, 'Se requieren al Menos 1 documento')
      .required('El docuemnto es requerido')
  }),
  isRequiredInvoiceUniversity: Yup.boolean(),
  invoiceUniversityName: Yup.array().when('isRequiredInvoiceUniversity', {
    is: true,
    then: Yup.array()
  }),
  isRequiredReportedEmail: Yup.boolean(),
  reportedEmailName: Yup.array().when('isRquiredReportedEmail', {
    is: true,
    then: Yup.array()
      .min(1, 'Se requieren al Menos 1 documento')
      .required('El documento es requerido')
  }),
  /* data Monitor  */
  isRequiredPaymentMonitors: Yup.boolean(),
  classScheduleNames: Yup.array().when('isRequiredPaymentMonitors', {
    is: true,
    then: Yup.array()
      .min(1, 'Se requieren al Menos 1 documento')
      .required('El docuemnto es requerido')
  }),
  feeBillNames: Yup.array().when('isRequiredPaymentMonitors', {
    is: true,
    then: Yup.array()
      .min(1, 'Se requieren al Menos 1 documento')
      .required('El docuemnto es requerido')
  }),
  attendanceSheetNames: Yup.array().when('isRequiredPaymentMonitors', {
    is: true,
    then: Yup.array()
      .min(1, 'Se requieren al Menos 1 documento')
      .required('El docuemnto es requerido')
  }),
  canImcompleteStep: Yup.boolean(),
  incompleteStep: Yup.boolean(),
  /* end data Monitor  */
  isRequiredAmountCancel: Yup.boolean(),
  amountCancelName: Yup.array().when('isRequiredAmountCancel', {
    is: true,
    then: Yup.array()
      .min(1, 'Se requieren al Menos 1 documento')
      .required('El docuemnto es requerido')
  }),
  isRequiredPurchaseDetail: Yup.boolean(),
  purchaseDetailName: Yup.array().when('isRequiredPurchaseDetail', {
    is: true,
    then: Yup.array()
      .min(1, 'Se requieren al Menos 1 documento')
      .required('El docuemnto es requerido')
  }),
  isRequiredActa: Yup.boolean(),
  actaName: Yup.array().when('isRequiredActa', {
    is: true,
    then: Yup.array()
      .min(1, 'Se requieren al Menos 1 documento')
      .required('El docuemnto es requerido')
  }),
  isRequiredEntryDate: Yup.boolean(),
  entryDate: Yup.string().when('isRequiredEntryDate', {
    is: true,
    then: Yup.string().required('La fecha de entrada es requerida')
  }),
  entryHour: Yup.string().when('isRequiredEntryDate', {
    is: true,
    then: Yup.string().required('La hora de entrada es requerida')
  }),
  entryMinute: Yup.string().when('isRequiredEntryDate', {
    is: true,
    then: Yup.string().required('El minuto de entrada es requerido')
  }),
  entryPeriod: Yup.string().when('isRequiredEntryDate', {
    is: true,
    then: Yup.string().required('El periodo de entrada es requerido')
  }),
  isExtension: Yup.boolean(),
  isExit: Yup.boolean(),
  extensionDays: Yup.string().when('isExtension', {
    is: true,
    then: Yup.string().required('El número de días de extensión es requerido')
  }),
  exitHour: Yup.string().when('isExit', {
    is: true,
    then: Yup.string().required('La hora de salida es requerida')
  }),
  exitMinute: Yup.string().when('isExit', {
    is: true,
    then: Yup.string().required('El minuto de salida es requerido')
  }),
  exitPeriod: Yup.string().when('isExit', {
    is: true,
    then: Yup.string().required('El periodo de salida es requerido')
  }),
  newExitDate: Yup.date().when(['isExit', 'isExtension'], {
    is: (isExit, isExtension) => isExit || isExtension,
    then: Yup.date().required('La fecha de salida es requerida')
  }),
  isRequiredReason: Yup.boolean(),
  reason: Yup.string().when('isRequiredReason', {
    is: true,
    then: Yup.string().required('La razón es requerida')
  }),
  isRequiredReceptionDate: Yup.boolean(),
  receptionDate: Yup.string().when('isRequiredReceptionDate', {
    is: true,
    then: Yup.string().required('La fecha de recepción es requerida')
  }),
  isRequiredDateSubmissionReceiptsArr: Yup.boolean(),
  dateSubmissionReceiptsArr: Yup.array().when(
    'isRequiredDateSubmissionReceiptsArr',
    {
      is: true,
      then: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.string().required('El ID es requerido'), // Ajusta según el tipo de ID
            date: Yup.string().required('La fecha es requerida')
          })
        )
        .required('Al menos un elemento en el array es requerido')
    }
  ),
  isRequiredBoxPurchase: Yup.boolean(),
  boxPurchaseName: Yup.array().when('isRequiredBoxPurchase', {
    is: true,
    then: Yup.array()
      .min(1, 'Se requieren al Menos 1 documento')
      .required('El documento es requerido')
  }),
  isRequiredBoxPurchaseTwo: Yup.boolean(),
  boxPurchaseTwoName: Yup.array().when('isRequiredBoxPurchaseTwo', {
    is: true,
    then: Yup.array()
      .min(1, 'Se requieren al Menos 1 documento')
      .required('El documento es requerido')
  }),
  isRequiredPurchaseRequestLetter: Yup.boolean(),
  purchaseRequestLetterName: Yup.array().when(
    'isRequiredPurchaseRequestLetter',
    {
      is: true,
      then: Yup.array()
        .min(1, 'Se requieren al Menos 1 documento')
        .required('El documento es requerido')
    }
  ),
  isRequiredQuotesReceived: Yup.boolean(),
  quotesReceivedName: Yup.array().when('isRequiredQuotesReceived', {
    is: true,
    then: Yup.array()
      .min(1, 'Se requieren al Menos 1 documento')
      .required('El documento es requerido')
  }),
  isRequiredDebtCertificate: Yup.boolean(),
  debtCertificateName: Yup.array().when('isRequiredDebtCertificate', {
    is: true,
    then: Yup.array()
      .min(1, 'Se requieren al Menos 1 documento')
      .required('El documento es requerido')
  }),
  isRequiredPurchaseRequestLetterSigned: Yup.boolean(),
  purchaseRequestLetterSignedName: Yup.array().when(
    'isRequiredPurchaseRequestLetterSigned',
    {
      is: true,
      then: Yup.array()
        .min(1, 'Se requieren al Menos 1 documento')
        .required('El documento es requerido')
    }
  ),
  isRequiredDateSubmissionReceipts: Yup.boolean(),
  dateSubmissionReceipts: Yup.string().when(
    'isRequiredDateSubmissionReceipts',
    {
      is: true,
      then: Yup.string().required(
        'La fecha de envío de comprobantes es requerida'
      )
    }
  ),
  isRequiredDateSend: Yup.boolean(),
  dateSend: Yup.string().when('isRequiredDateSend', {
    is: true,
    then: Yup.string().required('La fecha de envío es requerida')
  }),
  isRequiredDateCertificateRequest: Yup.boolean(),
  dateCertificateRequest: Yup.string().when(
    'isRequiredDateCertificateRequest',
    {
      is: true,
      then: Yup.string().required(
        'La fecha de solicitud de certificado es requerida'
      )
    }
  ),
  isRequiredDateCertificateReception: Yup.boolean(),
  dateCertificateReception: Yup.string().when(
    'isRequiredDateCertificateReception',
    {
      is: true,
      then: Yup.string().required(
        'La fecha de recepción de certificado es requerida'
      )
    }
  ),
  isRequiredDocumentsSentMedicalInstitution: Yup.boolean(),
  documentsSentMedicalInstitutionName: Yup.array().when(
    'isRequiredDocumentsSentMedicalInstitution',
    {
      is: true,
      then: Yup.array()
        .min(1, 'Se requieren al Menos 1 documento')
        .required('El documento es requerido')
    }
  ),
  isRequiredTariffDifferenceDocuments: Yup.boolean(),
  tariffDifferenceDocumentsNames: Yup.array().when(
    'isRequiredTariffDifferenceDocuments',
    {
      is: true,
      then: Yup.array()
        .min(1, 'Se requieren al Menos 1 documento')
        .required('Se requieren al Menos 1 documento')
    }
  )
})
