/* eslint-disable import/prefer-default-export */
import axios from '../Axios'

const module = '/history-program'

export const sendWsp = async (formData) => {
  const { data } = await axios.post(`${module}/send-wsp`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
  return data
}

export const registerNotification = async (formData) => {
  const { data } = await axios.post(
    `${module}/register-notification`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
  )
  return data
}

export const otherProcessesHistory = async (formData) => {
  const { data } = await axios.post(`${module}/other-processes`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
  return data
}

export const otherProcessesHistoryEdit = async ({
  additionalDataId,
  formData
}) => {
  const { data } = await axios.patch(
    `${module}/other-processes/${additionalDataId}`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
  )
  return data
}

export const multiProgramProcesses = async (formData) => {
  const { data } = await axios.post(
    `${module}/multi-program-processes`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
  )
  return data
}

export const resignationOrLossRequest = async (formData) => {
  const { data } = await axios.post(
    `${module}/resignation-or-loss-request`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
  )
  return data
}

export const getProgramsSameStep = async (params) => {
  const { type, state, currentStep, startDate, endDate } = params
  const { data } = await axios.get(
    `${module}/multi-program-processes?type=${type}&state=${state}&currentStep=${currentStep}&startDate=${startDate}&endDate=${endDate}`
  )
  return data
}

export const deleteProgram = async (programId) => {
  const { data } = await axios.delete(`${module}/delete-program/${programId}`)
  return data
}
