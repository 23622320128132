const typeCreationList = [
  {
    key: 'ALL',
    name: 'TODOS'
  },
  {
    key: 'NORMAL',
    name: 'Registro manual'
  },
  {
    key: 'HISTORY',
    name: 'Registro histórico'
  }
]

export default typeCreationList
