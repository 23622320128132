import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import { UploadFile, BarFileName } from '../../UI'
import { stepFiveSchema } from './Schemas'
import { Files } from '../../../Utils'

const StepFive = ({ refStepFive, stepFiveForm, setStepFiveForm, state }) => {
  const initialValues = {
    reasonApplicationName: [],
    reasonApplication: null,
    socialReportName: [],
    socialReport: null,
    additionalDocumentsName: [],
    additionalDocuments: null
  }

  const formik = useFormik({
    initialValues,
    validationSchema: stepFiveSchema,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: (values) => {
      if (!state) {
        setStepFiveForm({
          ...values
        })
      }
      if (state) {
        setStepFiveForm({
          ...values,
          state
        })
      }
    }
  })

  const deleteFile = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.reasonApplicationName,
      formik.values.reasonApplication
    )

    formik.setValues({
      ...formik.values,
      reasonApplicationName: resultNames,
      reasonApplication: resultListFiles
    })
  }

  const deleteAdditionalDocuments = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.additionalDocumentsName,
      formik.values.additionalDocuments
    )

    formik.setValues({
      ...formik.values,
      additionalDocumentsName: resultNames,
      additionalDocuments: resultListFiles
    })
  }

  const deleteSocialReport = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.socialReportName,
      formik.values.socialReport
    )

    formik.setValues({
      ...formik.values,
      socialReportName: resultNames,
      socialReport: resultListFiles
    })
  }

  const handleChangeReason = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.reasonApplicationName,
      formik.values.reasonApplication
    )

    formik.setValues({
      ...formik.values,
      reasonApplicationName: newListNames,
      reasonApplication: newListFiles
    })
    e.target.value = null
  }

  const handleAdditionalDocuments = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.additionalDocumentsName,
      formik.values.additionalDocuments
    )

    formik.setValues({
      ...formik.values,
      additionalDocumentsName: newListNames,
      additionalDocuments: newListFiles
    })
    e.target.value = null
  }

  const handleSocialReport = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.socialReportName,
      formik.values.socialReport
    )

    formik.setValues({
      ...formik.values,
      socialReportName: newListNames,
      socialReport: newListFiles
    })
    e.target.value = null
  }

  useEffect(() => {
    refStepFive.current = formik.handleSubmit
  }, [])

  useEffect(() => {
    formik.setValues({
      reasonApplicationName: stepFiveForm?.reasonApplicationName || [],
      reasonApplication: stepFiveForm?.reasonApplication || null,
      socialReportName: stepFiveForm?.socialReportName || [],
      socialReport: stepFiveForm?.socialReport || null,
      additionalDocumentsName: stepFiveForm?.additionalDocumentsName || [],
      additionalDocuments: stepFiveForm?.additionalDocuments || null
    })
  }, [stepFiveForm])

  return (
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem>
        <Text fontWeight={700} color={'green.500'} fontSize={'xl'}>
          Motivo de la postulación
        </Text>
        <Text fontSize={'md'} color={'green.500'}>
          Detallar y adjuntar el motivo de la postulación mediante una solicitud
          redactada por el socio y carta motivacional del postulante
          (manuscrita).
        </Text>
        <UploadFile
          direction={'row'}
          sizeIcon={30}
          p={5}
          onChange={(e) => {
            handleChangeReason(e)
          }}
          id={'reasonApplicationName'}
          error={Boolean(formik.errors.reasonApplicationName)}
          errorText={formik.errors.reasonApplicationName}
        />
        <Flex direction={'column'} gap={1}>
          {formik.values.reasonApplicationName.map((item) => (
            <BarFileName
              key={item?.name}
              fileName={item?.name}
              fileUrl={item?.url}
              onDelete={() => deleteFile(item?.name)}
            />
          ))}
        </Flex>
      </GridItem>
      {/* --- */}
      <GridItem>
        <Text fontWeight={700} color={'green.500'} fontSize={'xl'}>
          Formulario social
        </Text>
        <UploadFile
          direction={'row'}
          sizeIcon={30}
          p={5}
          onChange={(e) => {
            handleSocialReport(e)
          }}
          id={'socialReportName'}
          error={Boolean(formik.errors.socialReportName)}
          errorText={formik.errors.socialReportName}
        />
        <Flex direction={'column'} gap={1}>
          {formik.values.socialReportName.map((item) => (
            <BarFileName
              key={item?.name}
              fileName={item?.name}
              fileUrl={item?.url}
              onDelete={() => deleteSocialReport(item?.name)}
            />
          ))}
        </Flex>
      </GridItem>
      {/* --- */}
      <GridItem>
        <Text fontWeight={700} color={'green.500'} fontSize={'xl'}>
          Documentos adicionales (opcionales)
        </Text>
        <UploadFile
          direction={'row'}
          sizeIcon={30}
          p={5}
          onChange={(e) => {
            handleAdditionalDocuments(e)
          }}
          id={'additionalDocumentsName'}
          error={Boolean(formik.errors.additionalDocumentsName)}
          errorText={formik.errors.additionalDocumentsName}
        />
        <Flex direction={'column'} gap={1}>
          {formik.values.additionalDocumentsName.map((item) => (
            <BarFileName
              key={item?.name}
              fileName={item?.name}
              fileUrl={item?.url}
              onDelete={() => deleteAdditionalDocuments(item?.name)}
            />
          ))}
        </Flex>
      </GridItem>
    </Grid>
  )
}

export default StepFive
