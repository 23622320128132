import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import {
  UploadFile,
  BarFileName,
  TextAreaComtrol,
  InputComtrol
} from '../../UI'
import { steepThreeSchema } from './Schemas'
import { Files } from '../../../Utils'

const StepThree = ({
  stepThreeForm,
  setStepThreeForm,
  refStepThree,
  state
}) => {
  const initialValues = {
    policePartName: [],
    policePart: null,
    lastSalarySettlementName: [],
    lastSalarySettlement: null,
    unitCertificateName: [],
    unitCertificate: null,
    objectListName: [],
    objectList: null,
    evidencePhotosName: [],
    evidencePhotos: null,
    minutaName: [],
    minuta: null,
    aditionalDocumentsName: [],
    aditionalDocuments: null,
    observations: ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: steepThreeSchema,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: (values) => {
      setStepThreeForm({
        ...values,
        state
      })
    }
  })

  const deletePolicePart = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.policePartName,
      formik.values.policePart
    )

    formik.setValues({
      ...formik.values,
      policePartName: resultNames,
      policePart: resultListFiles
    })
  }

  const deleteMinuta = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.minutaName,
      formik.values.minuta
    )

    formik.setValues({
      ...formik.values,
      minutaName: resultNames,
      minuta: resultListFiles
    })
  }

  const deleteUnitCertificate = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.unitCertificateName,
      formik.values.unitCertificate
    )

    formik.setValues({
      ...formik.values,
      unitCertificateName: resultNames,
      unitCertificate: resultListFiles
    })
  }

  const deleteLastSalarySettlement = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.lastSalarySettlementName,
      formik.values.lastSalarySettlement
    )

    formik.setValues({
      ...formik.values,
      lastSalarySettlementName: resultNames,
      lastSalarySettlement: resultListFiles
    })
  }

  const deleteObjectList = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.objectListName,
      formik.values.objectList
    )

    formik.setValues({
      ...formik.values,
      objectListName: resultNames,
      objectList: resultListFiles
    })
  }

  const deleteEvidencePhotos = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.evidencePhotosName,
      formik.values.evidencePhotos
    )

    formik.setValues({
      ...formik.values,
      evidencePhotosName: resultNames,
      evidencePhotos: resultListFiles
    })
  }

  const deleteAditionalDocuments = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.aditionalDocumentsName,
      formik.values.aditionalDocuments
    )

    formik.setValues({
      ...formik.values,
      aditionalDocumentsName: resultNames,
      aditionalDocuments: resultListFiles
    })
  }

  const handleChangePolicePart = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.policePartName,
      formik.values.policePart
    )

    formik.setValues({
      ...formik.values,
      policePartName: newListNames,
      policePart: newListFiles
    })
    e.target.value = null
  }

  const handleChangeMinuta = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.minutaName,
      formik.values.minuta
    )

    formik.setValues({
      ...formik.values,
      minutaName: newListNames,
      minuta: newListFiles
    })
    e.target.value = null
  }

  const handleChangeUnitCertificate = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.unitCertificateName,
      formik.values.unitCertificate
    )
    formik.setValues({
      ...formik.values,
      unitCertificateName: newListNames,
      unitCertificate: newListFiles
    })
    e.target.value = null
  }

  const handleChangeLastSalarySettlement = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.lastSalarySettlementName,
      formik.values.lastSalarySettlement
    )
    formik.setValues({
      ...formik.values,
      lastSalarySettlementName: newListNames,
      lastSalarySettlement: newListFiles
    })
    e.target.value = null
  }

  const handleChangeObjectList = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.objectListName,
      formik.values.objectList
    )

    formik.setValues({
      ...formik.values,
      objectListName: newListNames,
      objectList: newListFiles
    })
    e.target.value = null
  }

  const handleChangeEvidencePhotos = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.evidencePhotosName,
      formik.values.evidencePhotos
    )

    formik.setValues({
      ...formik.values,
      evidencePhotosName: newListNames,
      evidencePhotos: newListFiles
    })
    e.target.value = null
  }

  const handleChangeAditionalDocuments = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.aditionalDocumentsName,
      formik.values.aditionalDocuments
    )

    formik.setValues({
      ...formik.values,
      aditionalDocumentsName: newListNames,
      aditionalDocuments: newListFiles
    })
    e.target.value = null
  }

  useEffect(() => {
    refStepThree.current = formik.handleSubmit
  }, [])

  useEffect(() => {
    formik.setValues({
      policePartName: stepThreeForm.policePartName || [],
      policePart: stepThreeForm.policePart || null,
      lastSalarySettlementName: stepThreeForm.lastSalarySettlementName || [],
      lastSalarySettlement: stepThreeForm.lastSalarySettlement || null,
      unitCertificateName: stepThreeForm.unitCertificateName || [],
      unitCertificate: stepThreeForm.unitCertificate || null,
      objectListName: stepThreeForm.objectListName || [],
      objectList: stepThreeForm.objectList || null,
      evidencePhotosName: stepThreeForm.evidencePhotosName || [],
      evidencePhotos: stepThreeForm.evidencePhotos || null,
      aditionalDocumentsName: stepThreeForm.aditionalDocumentsName || [],
      aditionalDocuments: stepThreeForm.aditionalDocuments || null,
      minutaName: stepThreeForm.minutaName || [],
      minuta: stepThreeForm.minuta || null,
      observations: stepThreeForm.observations || ''
    })
  }, [stepThreeForm])

  return (
    <>
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          <Text fontWeight={700} color={'green.500'} fontSize={'xl'}>
            Documentación y observaciones
          </Text>
          <InputComtrol label={'Añadir parte policial'}>
            <UploadFile
              direction={'row'}
              sizeIcon={30}
              p={5}
              onChange={(e) => {
                handleChangePolicePart(e)
              }}
              id={'policePartName'}
              error={Boolean(formik.errors.policePartName)}
              errorText={formik.errors.policePartName}
            />
            <Flex direction={'column'} gap={1}>
              {formik.values.policePartName.map((item) => (
                <BarFileName
                  key={item?.name}
                  fileName={item?.name}
                  fileUrl={item?.url}
                  onDelete={() => deletePolicePart(item?.name)}
                />
              ))}
            </Flex>
          </InputComtrol>
          <InputComtrol
            label={
              'Certificado de la unidad dando cuenta del domicilio del afectado'
            }
          >
            <UploadFile
              direction={'row'}
              sizeIcon={30}
              p={5}
              onChange={(e) => {
                handleChangeUnitCertificate(e)
              }}
              id={'unitCertificateName'}
              error={Boolean(formik.errors.unitCertificateName)}
              errorText={formik.errors.unitCertificateName}
            />
            <Flex direction={'column'} gap={1}>
              {formik.values.unitCertificateName.map((item) => (
                <BarFileName
                  key={item?.name}
                  fileName={item?.name}
                  fileUrl={item?.url}
                  onDelete={() => deleteUnitCertificate(item?.name)}
                />
              ))}
            </Flex>
          </InputComtrol>
        </GridItem>
        <GridItem>
          <Text fontWeight={700} opacity={0} fontSize={'xl'}>
            ---
          </Text>
          <InputComtrol label={'Última liquidación de sueldo'}>
            <UploadFile
              direction={'row'}
              sizeIcon={30}
              p={5}
              onChange={(e) => {
                handleChangeLastSalarySettlement(e)
              }}
              id={'lastSalarySettlementName'}
              error={Boolean(formik.errors.lastSalarySettlementName)}
              errorText={formik.errors.lastSalarySettlementName}
            />
            <Flex direction={'column'} gap={1}>
              {formik.values.lastSalarySettlementName.map((item) => (
                <BarFileName
                  key={item?.name}
                  fileName={item?.name}
                  fileUrl={item?.url}
                  onDelete={() => deleteLastSalarySettlement(item?.name)}
                />
              ))}
            </Flex>
          </InputComtrol>
          <InputComtrol
            label={
              'Relación de especies siniestradas, con valores confeccionada por el socio'
            }
          >
            <UploadFile
              direction={'row'}
              sizeIcon={30}
              p={5}
              onChange={(e) => {
                handleChangeObjectList(e)
              }}
              id={'objectListName'}
              error={Boolean(formik.errors.objectListName)}
              errorText={formik.errors.objectListName}
            />
            <Flex direction={'column'} gap={1}>
              {formik.values.objectListName.map((item) => (
                <BarFileName
                  key={item?.name}
                  fileName={item?.name}
                  fileUrl={item?.url}
                  onDelete={() => deleteObjectList(item?.name)}
                />
              ))}
            </Flex>
          </InputComtrol>
        </GridItem>
      </Grid>
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
        <GridItem>
          <InputComtrol
            label={'Fotos de evidencia de la residencia siniestrada'}
          >
            <UploadFile
              direction={'row'}
              multiple={true}
              sizeIcon={30}
              p={5}
              onChange={(e) => {
                handleChangeEvidencePhotos(e)
              }}
              id={'evidencePhotosName'}
              error={Boolean(formik.errors.evidencePhotosName)}
              errorText={formik.errors.evidencePhotosName}
            />
            <Flex direction={'column'} gap={1}>
              {formik.values.evidencePhotosName.map((item) => (
                <BarFileName
                  key={item?.name}
                  fileName={item?.name}
                  fileUrl={item?.url}
                  onDelete={() => deleteEvidencePhotos(item?.name)}
                />
              ))}
            </Flex>
          </InputComtrol>
        </GridItem>
        <GridItem>
          <InputComtrol label={'Minuta'}>
            <UploadFile
              direction={'row'}
              multiple={true}
              sizeIcon={30}
              p={5}
              onChange={(e) => {
                handleChangeMinuta(e)
              }}
              id={'minutaName'}
              error={Boolean(formik.errors.minutaName)}
              errorText={formik.errors.minutaName}
            />
            <Flex direction={'column'} gap={1}>
              {formik.values.minutaName.map((item) => (
                <BarFileName
                  key={item?.name}
                  fileName={item?.name}
                  fileUrl={item?.url}
                  onDelete={() => deleteMinuta(item?.name)}
                />
              ))}
            </Flex>
          </InputComtrol>
        </GridItem>
        <GridItem>
          <InputComtrol
            isRequired={false}
            label={'Documentos adicionales (opcional)'}
          >
            <UploadFile
              direction={'row'}
              multiple={true}
              sizeIcon={30}
              p={5}
              onChange={(e) => {
                handleChangeAditionalDocuments(e)
              }}
              id={'aditionalDocumentsName'}
              error={Boolean(formik.errors.aditionalDocumentsName)}
              errorText={formik.errors.aditionalDocumentsName}
            />
            <Flex direction={'column'} gap={1}>
              {formik.values.aditionalDocumentsName.map((item) => (
                <BarFileName
                  key={item?.name}
                  fileName={item?.name}
                  fileUrl={item?.url}
                  onDelete={() => deleteAditionalDocuments(item?.name)}
                />
              ))}
            </Flex>
          </InputComtrol>
        </GridItem>
      </Grid>
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
        <GridItem>
          <TextAreaComtrol
            id={'observations'}
            isRequired={false}
            label={'Observaciones de la solicitud (opcional)'}
            value={formik.values.observations}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.observations)}
            errorText={formik.errors.observations}
          />
        </GridItem>
      </Grid>
    </>
  )
}

export default StepThree
