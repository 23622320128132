import { createIcon } from '@chakra-ui/react'
/* https://freeicons.io/ui-essentials-3/ui-essentials-notification-alarm-bell-icon-44563 */
/* eslint-disable import/prefer-default-export */
const CalendarIcon = createIcon({
  displayName: 'CalendarIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      d="M17.4167 3.66667H17V3.25C17 2.91848 16.8683 2.60054 16.6339 2.36612C16.3995 2.1317 16.0815 2 15.75 2V2C15.4185 2 15.1005 2.1317 14.8661 2.36612C14.6317 2.60054 14.5 2.91848 14.5 3.25V3.66667H9.5V3.25C9.5 2.91848 9.3683 2.60054 9.13388 2.36612C8.89946 2.1317 8.58152 2 8.25 2V2C7.91848 2 7.60054 2.1317 7.36612 2.36612C7.1317 2.60054 7 2.91848 7 3.25V3.66667H6.58333C5.36776 3.66667 4.20197 4.14955 3.34243 5.00909C2.48289 5.86864 2 7.03442 2 8.25L2 17.4167C2 18.6322 2.48289 19.798 3.34243 20.6576C4.20197 21.5171 5.36776 22 6.58333 22H17.4167C18.6322 22 19.798 21.5171 20.6576 20.6576C21.5171 19.798 22 18.6322 22 17.4167V8.25C22 7.03442 21.5171 5.86864 20.6576 5.00909C19.798 4.14955 18.6322 3.66667 17.4167 3.66667ZM17.4167 19.5H6.58333C6.0308 19.5 5.5009 19.2805 5.11019 18.8898C4.71949 18.4991 4.5 17.9692 4.5 17.4167V10.3333H19.5V17.4167C19.5 17.9692 19.2805 18.4991 18.8898 18.8898C18.4991 19.2805 17.9692 19.5 17.4167 19.5Z"
      fill={'currentColor' || '#3C3C3C'}
    />
  ]
})

export default CalendarIcon
