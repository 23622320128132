/* eslint-disable import/prefer-default-export */
import { useQuery, useQueryClient, useMutation } from 'react-query'
import {
  getOneNotification,
  createAndUpdateNotification
} from '../api/Notifications'

const key = 'notifications'

const getAction = (type) => {
  if (type === 'CREATE-AND-UPDATE') return createAndUpdateNotification
  return null
}

export function useNotifications(query) {
  return useQuery([key, query], () => getOneNotification(query), {
    refetchOnWindowFocus: false
  })
}

export function useMutateNotifications(type) {
  const queryClient = useQueryClient()
  const actionFn = getAction(type)
  return useMutation(actionFn, {
    onSuccess: () => {
      queryClient.invalidateQueries([key])
    }
  })
}
