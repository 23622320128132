import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Flex, useToast } from '@chakra-ui/react'
import { Step, Steps, useSteps } from 'chakra-ui-steps'
import { Button, CustomToast } from '../../UI'
import { CreateNewFile, GetFormData, GetNotification } from '../../../Utils'
import { useMutateProgram } from '../../../hooks/Programs/PreUniversityScholarships'
import StepOne from './StepOne'
import StepTwo from './StepTwo'
import StepThree from './StepThree'
import StepFour from './StepFour'
import StepFive from './StepFive'

const PreUniversityScholarships = ({ partner, program = null }) => {
  const toast = useToast()
  const history = useHistory()
  const { nextStep, prevStep, activeStep } = useSteps({
    initialStep: 0
  })
  const refStepOne = useRef(null)
  const refStepTwo = useRef(null)
  const refStepThree = useRef(null)
  const refStepFour = useRef(null)
  const refStepFive = useRef(null)
  const [stepOneForm, setStepOneForm] = useState({
    partnerPhone: partner?.phone,
    partnerRut: partner?.rut,
    partnerGrade: partner?.grade,
    partnerCode: partner?.code,
    partnerZone: partner?.zone,
    partnerPrefecture: partner?.prefecture,
    partnerEndowment: partner?.endowment
  })
  const [stepTwoForm, setStepTwoForm] = useState({})
  const [stepThreeForm, setStepThreeForm] = useState([])
  const [stepFourForm, setStepFourForm] = useState({})
  const [stepFiveForm, setStepFiveForm] = useState({})
  const [finalForm, setFinalForm] = useState({
    partnerId: partner?._id
  })
  const [state, setState] = useState(null)
  const { mutate, isLoading, reset } = useMutateProgram('CREATE')
  const {
    mutate: mutateUpdate,
    isLoading: isLoadingUpdate,
    reset: resetUpdate
  } = useMutateProgram('UPDATE')

  const steps = [
    {
      label: 1,
      content: (
        <StepOne
          stepOneForm={stepOneForm}
          setStepOneForm={setStepOneForm}
          refStepOne={refStepOne}
          next={nextStep}
        />
      )
    },
    {
      label: 2,
      content: (
        <StepTwo
          stepTwoForm={stepTwoForm}
          setStepTwoForm={setStepTwoForm}
          refStepTwo={refStepTwo}
          next={nextStep}
        />
      )
    },
    {
      label: 3,
      content: (
        <StepThree
          stepThreeForm={stepThreeForm}
          setStepThreeForm={setStepThreeForm}
          refStepThree={refStepThree}
          next={nextStep}
        />
      )
    },
    {
      label: 4,
      content: (
        <StepFour
          stepFourForm={stepFourForm}
          setStepFourForm={setStepFourForm}
          refStepFour={refStepFour}
          next={nextStep}
        />
      )
    },
    {
      label: 5,
      content: (
        <StepFive
          stepFiveForm={stepFiveForm}
          setStepFiveForm={setStepFiveForm}
          refStepFive={refStepFive}
          state={state}
        />
      )
    }
  ]

  const handleNext = () => {
    console.log(activeStep)
    if (activeStep === 0) {
      refStepOne.current()
    }
    if (activeStep === 1) {
      refStepTwo.current()
    }
    if (activeStep === 2) {
      refStepThree.current()
    }
    if (activeStep === 3) {
      refStepFour.current()
    }
    if (activeStep === 4) {
      refStepFive.current()
    }
  }

  useEffect(() => {
    const stepThreeData = []
    const arrayDocumentsStepThree = []
    const beneficiariesHelped = []

    if (stepThreeForm?.length > 0) {
      stepThreeForm.forEach((item) => {
        const { memberSwornDeclaration, _id } = item
        if (memberSwornDeclaration) {
          if (Object.keys(memberSwornDeclaration).length > 0) {
            Object.values(memberSwornDeclaration).forEach((doc) => {
              const result = CreateNewFile(doc, _id)
              arrayDocumentsStepThree.push(result)
            })
          }
        }
      })

      stepThreeForm.forEach((item) => {
        // eslint-disable-next-line
        const { memberSwornDeclaration, ...rest } = item
        stepThreeData.push(rest)
      })
    }

    beneficiariesHelped.push('Hijo(a)')

    setFinalForm({
      ...finalForm,
      ...stepOneForm,
      ...stepTwoForm,
      stepThreeData, // stepThreeForm sin documentos
      arrayDocumentsStepThree, // los documentos del paso 3
      ...stepFourForm,
      ...stepFiveForm,
      beneficiariesHelped
    })
  }, [stepFiveForm])

  useEffect(() => {
    // si tiene mas valores aparte de los IDS de partner y usuario
    // y los el paso activo es el 5
    const test = true
    console.log(finalForm)
    if (test) {
      if (
        Object.keys(finalForm).length > 2 &&
        stepThreeForm.length > 0 &&
        activeStep === 4
      ) {
        if (!program) {
          const formData = GetFormData(
            finalForm,
            [
              'postulantScore',
              'postulantCertificate',
              'arrayDocumentsStepThree',
              'salarySettlements',
              'reasonApplication',
              'socialReport',
              'additionalDocuments'
            ],
            [
              'postulantScoreName',
              'postulantCertificateName',
              'salarySettlementsNames',
              'reasonApplicationName',
              'socialReportName',
              'additionalDocumentsName',
              'stepThreeData',
              'beneficiariesHelped'
            ]
          )

          mutate(formData, {
            onSuccess: () => {
              reset()
              GetNotification.basic('Programa registrado con Éxito', 'Aceptar')
              history.push('/programs')
            },
            onError: (err) => {
              console.log(err)
              GetNotification.basic(
                'Error',
                'Aceptar',
                'Error al Registrar programa',
                'error'
              )
            }
          })
        }
        if (program) {
          const { _id: programId } = program
          const formData = GetFormData(
            finalForm,
            [
              'postulantScore',
              'postulantCertificate',
              'arrayDocumentsStepThree',
              'salarySettlements',
              'reasonApplication',
              'socialReport',
              'additionalDocuments'
            ],
            [
              'postulantScoreName',
              'postulantCertificateName',
              'salarySettlementsNames',
              'reasonApplicationName',
              'socialReportName',
              'additionalDocumentsName',
              'stepThreeData',
              'beneficiariesHelped'
            ]
          )
          mutateUpdate(
            { programId, formData },
            {
              onSuccess: () => {
                resetUpdate()
                GetNotification.basic(
                  'Programa actualizado con Éxito',
                  'Aceptar'
                )
                history.push('/programs')
              },
              onError: (err) => {
                console.log(err)
                GetNotification.basic(
                  'Error',
                  'Aceptar',
                  'Error al actualizar programa',
                  'error'
                )
              }
            }
          )
        }
      }
    }
  }, [finalForm])

  useEffect(() => {
    if (program) {
      setStepOneForm({
        receptionDate: program?.receptionDate,
        isPartnerZone: program?.isPartnerZone,
        zone: program?.zone,
        prefecture: program?.prefecture,
        unit: program?.unit,
        region: program?.region,
        commune: program?.commune,
        address: program?.address,
        alternativePhone: program?.alternativePhone,
        civilStatus: program?.civilStatus,
        residenceCondition: program?.residenceCondition,
        membersFamily: program?.membersFamily,
        loadsFamily: program?.loadsFamily,
        /* partner field */
        partnerPhone: program?.partner.phone,
        partnerRut: program?.partner.rut,
        partnerGrade: program?.partner.grade,
        partnerCode: program?.partner.code,
        partnerZone: program?.partner.zone,
        partnerPrefecture: program?.partner.prefecture,
        partnerEndowment: program?.partner.endowment
      })
      setStepTwoForm({
        postulantName: program?.postulantName,
        postulantDateOfBirth: program?.postulantDateOfBirth,
        postulantAverage: program?.postulantAverage,
        postulantPhone: program?.postulantPhone,
        postulantScoreName: program?.postulantScore,
        schoolName: program?.schoolName,
        postulantAge: program?.postulantAge,
        postulantAdress: program?.postulantAdress,
        postulantEmail: program?.postulantEmail,
        postulantCertificateName: program?.postulantCertificate
      })

      setStepThreeForm(
        program?.familyGroupBackground.map((item) => ({
          ...item,
          memberSwornDeclarationName: item.memberSwornDeclaration || [],
          memberSwornDeclaration: null
        }))
      )
      setStepFourForm({
        salatyPartner: program?.salatyPartner,
        salarySpouse: program?.salarySpouse,
        otherSalaty: program?.otherSalaty,
        totalExpensesGroup: program?.totalExpensesGroup,
        salarySettlementsNames: program?.salarySettlements
      })
      setStepFiveForm({
        reasonApplicationName: program?.reasonApplication,
        socialReportName: program?.socialReport,
        additionalDocumentsName: program?.additionalDocuments
      })
    }
  }, [program])

  useEffect(() => {
    if (isLoading || isLoadingUpdate) {
      toast({
        duration: 9000 * 9000,
        isClosable: true,
        position: 'bottom-right',
        render: ({ onClose }) => (
          <CustomToast
            text={'Espere por favor...'}
            status={'info'}
            onClose={onClose}
          />
        )
      })
      return
    }
    toast.closeAll()
  }, [isLoading, isLoadingUpdate])

  return (
    <Flex direction={'column'}>
      <Steps
        activeStep={activeStep}
        orientation="horizontal"
        responsive
        mb={{
          lg: 5,
          md: 0
        }}
      >
        {steps.map(({ label, content }) => (
          <Step key={label}>{content}</Step>
        ))}
      </Steps>
      <Flex justify={'flex-end'} mt={4}>
        <Button
          text={'Regresar'}
          bg={'green.50'}
          color={'green.500'}
          disabled={activeStep === 0 || isLoading || isLoadingUpdate}
          onClick={prevStep}
        />
        {activeStep === steps.length - 1 && (
          <Button
            text={'Guardar como incompleta'}
            bg={'green.50'}
            color={'green.500'}
            disabled={activeStep === 0 || isLoading || isLoadingUpdate}
            isLoading={isLoading || isLoadingUpdate}
            onClick={() => {
              setState('INCOMPLETE')
              handleNext()
            }}
          />
        )}
        <Button
          text={activeStep === steps.length - 1 ? 'Finalizar' : 'Siguiente'}
          isLoading={isLoading || isLoadingUpdate}
          disabled={isLoading || isLoadingUpdate}
          onClick={() => {
            setState('INREVIEW')
            handleNext()
          }}
        />
      </Flex>
    </Flex>
  )
}

export default PreUniversityScholarships
