import React from 'react'
import { Grid, GridItem, Text } from '@chakra-ui/react'
import { CardFilePreview } from '../../UI'

const Documents = ({ program }) => (
  <>
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      {program?.birthCertificate.map((item) => (
        <GridItem py={3}>
          <Text fontWeight={700} fontSize={'xl'}>
            Certificado de nacimiento
          </Text>
          <CardFilePreview name={item?.name} url={item.url} />
        </GridItem>
      ))}
    </Grid>

    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      {program?.scoreCertificate.map((item) => (
        <GridItem py={1}>
          <Text fontWeight={700} fontSize={'xl'}>
            Certificado de notas
          </Text>
          <CardFilePreview name={item?.name} url={item.url} />
        </GridItem>
      ))}
    </Grid>

    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      {program?.socialReport.map((item) => (
        <GridItem py={1}>
          <Text fontWeight={700} fontSize={'xl'}>
            Informe social
          </Text>
          <CardFilePreview name={item?.name} url={item.url} />
        </GridItem>
      ))}
    </Grid>
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      {program?.parentsLastSalary.map((item) => (
        <GridItem py={1}>
          <Text fontWeight={700} fontSize={'xl'}>
            Liquidaciones de sueldo de los padres
          </Text>
          <CardFilePreview name={item?.name} url={item.url} />
        </GridItem>
      ))}
    </Grid>
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      {program?.motivationLetter.map((item) => (
        <GridItem py={1}>
          <Text fontWeight={700} fontSize={'xl'}>
            Carta de motivación
          </Text>
          <CardFilePreview name={item?.name} url={item.url} />
        </GridItem>
      ))}
    </Grid>

    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      {program?.applicationSignedByPartner.map((item) => (
        <GridItem py={1}>
          <Text fontWeight={700} fontSize={'xl'}>
            Solicitud de postulación suscrita por el socio
          </Text>
          <CardFilePreview name={item?.name} url={item.url} />
        </GridItem>
      ))}
    </Grid>

    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      {program?.curricularMesh?.map((item) => (
        <GridItem py={1}>
          <Text fontWeight={700} fontSize={'xl'}>
            Malla curricular
          </Text>
          <CardFilePreview name={item?.name} url={item.url} />
        </GridItem>
      ))}
    </Grid>
  </>
)

export default Documents
