import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { Grid, GridItem, Text, Flex } from '@chakra-ui/react'
import { TextAreaComtrol, UploadFile, BarFileName } from '../../UI'
import { stepThreeSchema } from './Schemas'
import { Files } from '../../../Utils'

const StepThree = ({
  stepThreeForm,
  setStepThreeForm,
  refStepThree,
  state
}) => {
  const initialValues = {
    observations: '',
    backupDocumentsNames: [],
    backupDocuments: null
  }

  const formik = useFormik({
    initialValues,
    validationSchema: stepThreeSchema,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: (values) => {
      setStepThreeForm({
        ...values,
        state
      })
    }
  })

  const deleteBackupDocuments = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.backupDocumentsNames,
      formik.values.backupDocuments
    )

    formik.setValues({
      ...formik.values,
      backupDocumentsNames: resultNames,
      backupDocuments: resultListFiles
    })
  }

  const handleChangeBackupDocuments = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.backupDocumentsNames,
      formik.values.backupDocuments
    )

    formik.setValues({
      ...formik.values,
      backupDocumentsNames: newListNames,
      backupDocuments: newListFiles
    })
    e.target.value = null
  }

  useEffect(() => {
    refStepThree.current = formik.handleSubmit
  }, [])

  useEffect(() => {
    formik.setValues({
      observations: stepThreeForm?.observations || '',
      backupDocumentsNames: stepThreeForm?.backupDocumentsNames || [],
      backupDocuments: stepThreeForm?.backupDocuments || null
    })
  }, [stepThreeForm])

  return (
    <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
      <GridItem>
        <Text fontWeight={700} color={'green.500'} fontSize={'xl'}>
          Información adicional
        </Text>
        <TextAreaComtrol
          id={'observations'}
          isRequired={false}
          label={'Observaciones (opcional)'}
          value={formik.values.observations}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.observations)}
          errorText={formik.errors.observations}
        />
      </GridItem>
      <GridItem>
        <Text fontSize={'md'} color={'green.500'}>
          Documentación respaldaría
        </Text>
        <UploadFile
          id={'backupDocumentsNames'}
          direction={'row'}
          sizeIcon={30}
          p={5}
          onChange={(e) => handleChangeBackupDocuments(e)}
          error={Boolean(formik.errors.backupDocumentsNames)}
          errorText={formik.errors.backupDocumentsNames}
        />
        <Flex direction={'column'} gap={1}>
          {formik.values.backupDocumentsNames.map((item) => (
            <BarFileName
              key={item?.name}
              fileName={item?.name}
              fileUrl={item?.url}
              onDelete={() => deleteBackupDocuments(item?.name)}
            />
          ))}
        </Flex>
      </GridItem>
    </Grid>
  )
}

export default StepThree
