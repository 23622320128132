import React from 'react'
import { useHistory } from 'react-router-dom'
import { Tr, Td, Icon, Grid, GridItem } from '@chakra-ui/react'
import { Table } from '../UI'
import { RedirectIcon } from '../Icons'
import { FormatDate } from '../../Utils'
import GetStatusCard from '../../Utils/GetStatusCard'
import WithoutResults from '../WithoutResults'

const headers = [
  '#',
  'Fecha',
  'Programa',
  'Socios',
  'Rut',
  'Código de funcionario',
  'Status del socio',
  'Ver solicitud'
]

const TableDashboard = ({ data, filters, updateFilters }) => {
  const history = useHistory()
  const validateFullName = (name) => {
    if (!name) {
      return `No Aplica`
    }
    return name
  }

  const toProgramDetail = (program) => {
    history.push(`/programs/view/${program._id}/${program.type}`)
  }

  return (
    <>
      {data?.programToApprove?.currentPages?.length === 0 && (
        <WithoutResults type={'DASHBOARD-TABLE'} />
      )}
      {data?.programToApprove?.currentPages?.length > 0 && (
        <Grid templateColumns={'repeat(1, 1fr)'} w={'100%'}>
          <GridItem
            overflowX={'auto'}
            display={{
              lg: 'table',
              md: 'break-word',
              sm: 'break-word'
            }}
            css={{
              '&::-webkit-scrollbar': {
                width: '4px'
              },
              '&::-webkit-scrollbar-track': {
                width: '4px'
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#2E7423',
                borderRadius: '30px'
              }
            }}
          >
            <Table
              headers={headers}
              totalRecords={data?.programToApprove?.totalRecords}
              setPage={(page) => updateFilters({ ...filters, page })}
              page={filters?.page}
            >
              {data?.programToApprove?.currentPages?.map((program, index) => (
                <Tr key={program?._id}>
                  <Td fontWeight={700} color={'grey.500'} textAlign={'center'}>
                    {index + 1 + filters.page * filters.limit}
                  </Td>
                  <Td color={'grey.500'}>
                    {FormatDate(program?.createdAt, 'short')}
                  </Td>
                  <Td color={'grey.500'}>{program?.programName}</Td>
                  <Td color={'grey.500'}>
                    {validateFullName(program?.partner?.name)}
                  </Td>
                  <Td color={'grey.500'}>
                    {program?.partner?.rut || 'No Aplica'}
                  </Td>
                  <Td color={'grey.500'}>
                    {program?.partner?.code || 'No Aplica'}
                  </Td>
                  <Td>{GetStatusCard.programTable(program?.state)}</Td>
                  <Td textAlign={'center'}>
                    <Icon
                      fontSize="26"
                      as={RedirectIcon}
                      _hover={{ cursor: 'pointer' }}
                      onClick={() => toProgramDetail(program)}
                    />
                  </Td>
                </Tr>
              ))}
            </Table>
          </GridItem>
        </Grid>
      )}
    </>
  )
}

export default TableDashboard
