import React from 'react'
import { Grid, GridItem } from '@chakra-ui/react'
import { TextAreaComtrol } from '../../UI'

const AdditionalInformation = ({ program }) => (
  <>
    <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
      {program?.observations !== 'Sin Observaiones' && (
        <GridItem py={3}>
          <TextAreaComtrol
            isRequired={false}
            label={'Observaciones'}
            focusBorderColor={'green.100'}
            value={program?.observations || 'Sin Observaiones'}
            readOnly={true}
          ></TextAreaComtrol>
        </GridItem>
      )}
    </Grid>
  </>
)

export default AdditionalInformation
