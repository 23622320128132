import React from 'react'
import { Grid, GridItem, Tr, Td, Flex } from '@chakra-ui/react'
import { Table, Spinner, Alert } from '../UI'
import { ProgramsList, FormatDate } from '../../Utils'
import GetStatusCard from '../../Utils/GetStatusCard'

import WithoutResults from '../WithoutResults'

const TablePrograms = ({
  page,
  setPage,
  limit,
  programsSync,
  isLoading,
  isFetching,
  isError
}) => {
  const headers = ['#', 'Programa', 'Fecha', 'Status']

  const getProgramName = (key) => {
    const program = ProgramsList?.find((item) => item?.key === key)

    return program?.name
  }

  return (
    <>
      {programsSync?.currentPages?.length === 0 && (
        <WithoutResults type={'SYNC-TABLE'} />
      )}
      {programsSync?.currentPages?.length > 0 && (
        <>
          <Grid templateColumns={'repeat(1, 1fr)'} w={'100%'}>
            <Flex w={'100%'} justify={'flex-end'} py={3}>
              <Spinner size={'lg'} show={isLoading || isFetching} />
            </Flex>
            {isError && (
              <Flex w={'100%'} justify={'flex-end'} py={3}>
                <Alert status={'error'} message={'Error al Cargar los Datos'} />
              </Flex>
            )}
            <GridItem
              overflowX={'auto'}
              display={{
                lg: 'table',
                md: 'break-word',
                sm: 'break-word'
              }}
              css={{
                '&::-webkit-scrollbar': {
                  width: '4px'
                },
                '&::-webkit-scrollbar-track': {
                  width: '4px'
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#2E7423',
                  borderRadius: '30px'
                }
              }}
            >
              <Table
                headers={headers}
                totalRecords={programsSync?.totalRecords}
                page={page}
                setPage={setPage}
              >
                {programsSync?.currentPages?.map((item, index) => (
                  <Tr key={item._id}>
                    <Td
                      fontWeight={700}
                      color={'grey.500'}
                      // textAlign={'center'}
                    >
                      {index + 1 + page * limit}
                    </Td>
                    <Td color={'grey.500'} w={'25%'}>
                      {getProgramName(item?.type)}
                    </Td>
                    <Td color={'grey.500'} w={'25%'}>
                      {FormatDate(Date(item?.createdAt))}
                    </Td>
                    <Td w={'25%'}>{GetStatusCard.sync(item?.state)}</Td>
                  </Tr>
                ))}
              </Table>
            </GridItem>
          </Grid>
        </>
      )}
    </>
  )
}

export default TablePrograms
