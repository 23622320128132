import * as Yup from 'yup'
/* eslint-disable import/prefer-default-export */
export const uploadSchema = Yup.object().shape({
  month: Yup.string().required('El mes es Requerido'),
  year: Yup.string().required('El año es Requerido'),
  uploadFileNames: Yup.array()
    .min(1, 'Se requiere 1 archivo por carga')
    .max(1, 'Máximo un archivo por carga')
    .required('El archivo de carga es Requerido')
})
