import React from 'react'
import { Grid, GridItem, Text } from '@chakra-ui/react'
import { CardFilePreview, TextAreaComtrol } from '../../UI'

const AdditionalInformations = ({ program }) => (
  <>
    <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
      {program?.observations && (
        <GridItem py={3}>
          <TextAreaComtrol
            label={'Observaciones de la solicitud'}
            value={program?.observations}
            isRequired={false}
            readOnly={true}
          />
        </GridItem>
      )}
    </Grid>
    {program?.aditionalDocuments && (
      <>
        <Text fontWeight={700} fontSize={'xl'}>
          Documentos adicionales
        </Text>
        <Grid
          templateColumns={{
            lg: 'repeat(1, 1fr)',
            md: 'repeat(1, 1fr)',
            sm: 'repeat(1, 1fr)'
          }}
          gap={2}
        >
          {program?.aditionalDocuments.map((item) => (
            <GridItem>
              <CardFilePreview name={item.name} url={item.url} />
            </GridItem>
          ))}
        </Grid>
      </>
    )}
  </>
)

export default AdditionalInformations
