/* eslint-disable import/prefer-default-export */
import { useQuery, useMutation, useQueryClient } from 'react-query'

import {
  generateTemplateFile,
  getProgramsSync,
  uploadMasivePrograms
} from '../api/ProgramsSync'
import { generateTemplateUploadMassiveProgram } from '../api/programs'

const key = 'programs-sync'

const getAction = (type) => {
  if (type === 'UPLOAD-MASIVE') return uploadMasivePrograms
  if (type === 'DOWNLOAD-TEMPLATE-FILE') return generateTemplateFile
  if (type === 'GET-TEMPLATE-UPLOAD-MASIVE')
    return generateTemplateUploadMassiveProgram
  return null
}

export function useProgramsSync(filtersProgramsSync, page, limit, props = {}) {
  return useQuery(
    [key, filtersProgramsSync, page, limit],
    () => getProgramsSync(filtersProgramsSync, page, limit),
    {
      ...props
    }
  )
}

export function useMutateProgram(type) {
  const queryClient = useQueryClient()
  const actionFn = getAction(type)
  return useMutation(actionFn, {
    onSuccess: () => {
      queryClient.invalidateQueries([key])
    }
  })
}
