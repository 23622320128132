import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  Menu as MenuCha,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  Button
} from '@chakra-ui/react'
import { OptionsIcons } from '../Icons'
import { useMutatePartner } from '../../hooks/Partners'
import { GetNotification } from '../../Utils'

const availableTo = [
  'ADMINISTRADOR',
  'ASISTENTE_SOCIAL',
  'DEPTO_CONTROL_GESTION_SOCIAL',
  'FINANZAS',
  'ABASTECIMIENTO'
]

const OptionsMenu = ({ partner, onOpen, setCurrentId }) => {
  const history = useHistory()
  const { mutate } = useMutatePartner('DELETE')
  const { user } = useSelector((state) => state.auth)

  const handleDelete = () => {
    const { _id } = partner
    mutate(_id, {
      onSuccess: () => {
        GetNotification.basic('Socio eliminado con Éxito', 'Aceptar')
      },
      onError: () => {
        GetNotification.basic(
          'Error',
          'Aceptar',
          'Error al eliminar socio',
          'error'
        )
      }
    })
  }

  const handleResignation = () => {
    setCurrentId(partner?._id)
    onOpen()
  }

  const optionsMenu = [
    {
      text: 'Ver Perfil',
      function: () =>
        history.push(`/partners/profile/${partner?._id}/Perfil de Socio`),
      disabled: false
    },
    {
      text: 'Familiares registrados',
      function: () =>
        history.push(`/partners/profile/${partner?._id}/Familiares`),
      disabled: false
    },
    {
      text: 'Histórico de Programas',
      function: () =>
        history.push(
          `/partners/profile/${partner?._id}/Histórico de Programas`
        ),
      disabled: false
    },
    {
      text: 'Historial de descuentos',
      function: () =>
        history.push(`/partners/profile/${partner?._id}/Descuentos`),
      disabled: false
    },
    {
      text: 'Solicita programa',
      function: () => history.push(`/programs/create/${partner?._id}`),
      disabled: false
    },
    {
      text: 'Registrar Renuncia',
      function: () =>
        GetNotification.question(
          '¿Seguro que deseas registrar la renuncia de este socio?',
          () => handleResignation(),
          null,
          '',
          'warning'
        ),
      disabled: partner.state === 'RESIGNED' || !availableTo.includes(user.role)
    },
    {
      text: 'Eliminar',
      function: () =>
        GetNotification.question(
          '¿Seguro que deseas eliminar este socio?',
          () => handleDelete(),
          null,
          '',
          'warning'
        ),
      disabled: !availableTo.includes(user.role)
    }
  ]

  return (
    <MenuCha isLazy>
      <MenuButton>
        <Icon mr="4" fontSize="26" as={OptionsIcons} />
      </MenuButton>
      <MenuList borderRadius={20} py={3} px={3} color={'green.500'}>
        {optionsMenu.map((item) => (
          <MenuItem key={item.text} p={0} _hover={{ bg: 'transparents' }}>
            <Button
              w={'100%'}
              bg={'transparent'}
              justifyContent={'flex-start'}
              borderRadius={20}
              _hover={{ bg: 'green.50' }}
              onClick={item?.function}
              mb={1}
              disabled={item.disabled}
            >
              {item?.text}
            </Button>
          </MenuItem>
        ))}
      </MenuList>
    </MenuCha>
  )
}

export default OptionsMenu
