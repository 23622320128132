/* eslint-disable import/prefer-default-export */
import { useMutation, useQueryClient } from 'react-query'

import { forgotPassword, resetPassword } from '../api/resetPassword'

const key = 'actions/password'

const mutationDict = {
  'FORGOT-PASSWORD': forgotPassword,
  'RESET-PASSWORD': resetPassword
}

export function useMutateActionPassword(type) {
  const queryClient = useQueryClient()
  const actionFn = mutationDict[type]
  return useMutation(actionFn, {
    onSuccess: () => {
      queryClient.invalidateQueries([key])
    }
  })
}
