/* eslint-disable import/prefer-default-export */
import { useQuery } from 'react-query'
import { getOneAdditional } from '../api/AdditionalInformation'

const key = 'additionalInformation'

export function useAdditionalInformation(programId, keyStep, step) {
  return useQuery(
    [key, programId, keyStep, step],
    () => getOneAdditional(programId, keyStep, step),
    {
      refetchOnWindowFocus: false
    }
  )
}
