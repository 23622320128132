import React from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { CardFilePreview } from '../../UI'

const RenderDocument = ({ titleDocument, arrayDocuments }) => (
  <Flex gap={4} direction={'column'}>
    <Text fontWeight={700}>{titleDocument}</Text>
    {arrayDocuments?.map((item) => (
      <CardFilePreview key={item?.url} name={item?.name} url={item?.url} />
    ))}
  </Flex>
)

export default RenderDocument
