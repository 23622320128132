import React from 'react'
import { Grid, GridItem, Text } from '@chakra-ui/react'
import PartnerInformation from './PartnerInformation'
import NewBornList from './NewBornList'
import Documents from './Documents'
import RenderFlowDocuments from '../RenderFlowDocuments'
import { SectionAccordion } from '../../UI'

const NewbornBenefit = ({ program }) => {
  const renderViewTitle = (title) => (
    <Text
      fontWeight={700}
      bg={'green.100'}
      color={'white'}
      fontSize={20}
      borderRadius={8}
      px={5}
      py={1}
      my={4}
    >
      {title}
    </Text>
  )

  return (
    <Grid templateColumns={'repeat(1, minmax(0, 1fr))'} gap={2}>
      <GridItem>
        <PartnerInformation program={program} />
      </GridItem>
      <GridItem>
        {renderViewTitle('Lista de Hijo/s Nacidos')}
        <NewBornList program={program} />
      </GridItem>
      <GridItem>
        {renderViewTitle('Documentación Entregada')}
        <Documents program={program} />
      </GridItem>
      <SectionAccordion title={'Documentos agregados dentro del flujo'}>
        <GridItem>
          <RenderFlowDocuments program={program} />
        </GridItem>
      </SectionAccordion>
    </Grid>
  )
}

export default NewbornBenefit
