const objMonths = {
  Enero: 1,
  Febrero: 2,
  Marzo: 3,
  Abril: 4,
  Mayo: 5,
  Junio: 6,
  Julio: 7,
  Agosto: 8,
  Septiembre: 9,
  Octubre: 10,
  Noviembre: 11,
  Diciembre: 12
}

const getNumberMonth = (month) => objMonths[month]

const getArrayNamesMonths = () => Object.keys(objMonths)

const getNameMonths = (numberMonth) =>
  Object.keys(objMonths).find(
    (key) =>
      objMonths[key] === parseInt(numberMonth, 10) ||
      objMonths[key] === numberMonth
  )

export default {
  getNumberMonth,
  getArrayNamesMonths,
  getNameMonths
}
