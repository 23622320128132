import React from 'react'
import io from 'socket.io-client'
import config from '../config'

const getBaseUrl = () => {
  if (process.env.REACT_APP_NODE_ENV === 'production') {
    return config.prod.API_BASE
  }
  if (process.env.REACT_APP_NODE_ENV === 'test') {
    return config.test.API_BASE
  }
  return config.dev.API_BASE
}

export const socket = io(getBaseUrl().replace('/api/v1', ''))
export const SocketContext = React.createContext()
