import React, { useState, useEffect } from 'react'
import {
  Tabs,
  Box,
  Grid,
  GridItem,
  TabList as TabListCha,
  Tab,
  Hide,
  Show,
  Text,
  Tooltip
} from '@chakra-ui/react'
import BackButton from './BackButton'
import Accordion from './Accordion'

const TabList = ({
  goBack = false,
  options = [],
  children,
  orientation = 'vertical',
  bg = 'beige.500',
  bgHover = 'beige.600',
  color = 'green.500',
  optionDefault = false,
  getIndex
}) => {
  const [tabIndex, setTabIndex] = useState(0)

  const handleTabsChange = (index) => {
    setTabIndex(index)
    if (getIndex) {
      getIndex(index)
    }
  }

  useEffect(() => {
    if (optionDefault) {
      const index = options.indexOf(optionDefault)
      setTabIndex(index)
    }
  }, [])

  return (
    <>
      <Show below="md">
        <Grid gap={2}>
          <Accordion>
            {goBack && (
              <Box>
                <BackButton />
              </Box>
            )}
            {options.map((item, index) => (
              <GridItem
                key={item}
                _hover={{
                  bg: options.at(tabIndex) === item ? 'green.50' : bgHover,
                  cursor: 'pointer'
                }}
                bg={options.at(tabIndex) === item ? 'green.100' : bg}
                fontWeight={500}
                color={color}
                borderRadius={8}
                py={5}
                textAlign={'center'}
                mb={2}
                onClick={() => {
                  setTabIndex(index)
                  if (getIndex) {
                    getIndex(index)
                  }
                }}
              >
                {item}
              </GridItem>
            ))}
          </Accordion>
        </Grid>
      </Show>
      <Tabs
        index={tabIndex}
        onChange={handleTabsChange}
        orientation={orientation}
        variant="soft-rounded"
        colorScheme="green"
      >
        <TabListCha
          gap={2}
          borderRight={{
            lg: 'solid #D3D3D3'
          }}
          pr={{
            lg: 2
          }}
          h={'calc(100% - 100px)'}
        >
          <Hide below={'md'}>
            {goBack && (
              <Box>
                <BackButton />
              </Box>
            )}

            {options.map((item, index) => (
              <Tab
                key={index}
                bg={bg}
                _hover={{ bg: bgHover }}
                color={color}
                borderRadius={8}
                py={5}
              >
                <Tooltip label={item} placement="top-start" bg={'blue.100'}>
                  <Text fontSize="md" fontWeight={700} noOfLines={1}>
                    {item}
                  </Text>
                </Tooltip>
              </Tab>
            ))}
          </Hide>
        </TabListCha>
        {children}
      </Tabs>
    </>
  )
}

export default TabList
