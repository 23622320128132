import { Box, Flex, IconButton, Text, useDisclosure } from '@chakra-ui/react'
import { BsFillInfoCircleFill as InfoIcon } from 'react-icons/bs'
import { CustomModal } from '../UI'

const allowedValues = [
  {
    column: 'FECHAS',
    values: '01_02_2021, 02_2021',
    description:
      'Solo se permiten fechas completas en el formato DD_MM_AAAA. Si no se tiene el día, se puede proporcionar solo el mes y el año.'
  },
  {
    column: 'BENEFICIARIO / RELACION_BENEFICIARIO',
    values: 'SOCIO / MADRE / PADRE / HIJO / NIETO / CONYUGE / HERMANO / OTRO',
    description:
      'Relaciones permitidas para la columna BENEFICIARIO / RELACION_BENEFICIARIO.'
  },
  {
    column: 'CODIGO_SOCIO',
    values: '000000-Q',
    description:
      'Valor permitido para la columna CODIGO_SOCIO tiene que tener una longitud de 7 caracteres y puede o no incluir el carácter "-", y este código debe estar previamente registrado.'
  },
  {
    column: 'ESTADO',
    values: 'FINALIZADA APROBADA / FINALIZADA RECHAZADA',
    description:
      'Valores permitidos para la columna ESTADO. Debe ser "FINALIZADA APROBADA" o "FINALIZADA RECHAZADA". Si no se proporciona ningún valor, se considerará como "FINALIZADA RECHAZADA".'
  }
]

const InfoColumnTemplate = () => {
  const { isOpen, onToggle } = useDisclosure()
  return (
    <>
      <Flex justify="end" pr={2}>
        <IconButton
          borderRadius={'50%'}
          variant="outline"
          colorScheme="green"
          aria-label="infor columnas"
          icon={<InfoIcon />}
          onClick={onToggle}
        />
      </Flex>
      <CustomModal
        isOpen={isOpen}
        onClose={onToggle}
        title={'Información general de columnas'}
        showButtons={false}
      >
        <Flex direction="column" gap={2}>
          {allowedValues.map(({ column, values, description }) => (
            <Flex
              direction="column"
              gap={2}
              justify="center"
              align="center"
              borderRadius={8}
              border="1px dotted #48BB78"
              p={2}
            >
              <Box bg="green.50" w="100%" borderRadius={8}>
                <Text textAlign="center" fontWeight={700}>
                  {column}
                </Text>
              </Box>
              {values && (
                <Box>
                  <Text>Formatos ej: {values}</Text>
                </Box>
              )}
              {description && (
                <Box>
                  <Text textAlign="center">{description}</Text>
                </Box>
              )}
            </Flex>
          ))}
        </Flex>
      </CustomModal>
    </>
  )
}

export default InfoColumnTemplate
