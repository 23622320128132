import React from 'react'
import { Grid, GridItem } from '@chakra-ui/react'
import { BarFileName, InputComtrol, UploadFile } from '../../../UI'
import { Files } from '../../../../Utils'

const RequestPaymentMonitors = ({ formik, program }) => {
  const deleteClassSchedule = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.classScheduleNames,
      formik.values.classSchedule
    )

    formik.setValues({
      ...formik.values,
      classScheduleNames: resultNames,
      classSchedule: resultListFiles
    })
  }

  const deleteFeeBill = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.feeBillNames,
      formik.values.feeBill
    )

    formik.setValues({
      ...formik.values,
      feeBillNames: resultNames,
      feeBill: resultListFiles
    })
  }

  const deleteAttendanceSheet = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.attendanceSheetNames,
      formik.values.attendanceSheet
    )

    formik.setValues({
      ...formik.values,
      attendanceSheetNames: resultNames,
      attendanceSheet: resultListFiles
    })
  }

  const handleChangeClassSchedule = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.classScheduleNames,
      formik.values.classSchedule
    )

    formik.setValues({
      ...formik.values,
      classScheduleNames: newListNames,
      classSchedule: newListFiles
    })
    e.target.value = null
  }

  const handleChangeFeeBill = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.feeBillNames,
      formik.values.feeBill
    )

    formik.setValues({
      ...formik.values,
      feeBillNames: newListNames,
      feeBill: newListFiles
    })
    e.target.value = null
  }

  const handleChangeAttendanceSheet = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.attendanceSheetNames,
      formik.values.attendanceSheet
    )

    formik.setValues({
      ...formik.values,
      attendanceSheetNames: newListNames,
      attendanceSheet: newListFiles
    })
    e.target.value = null
  }

  return (
    <>
      <Grid
        templateColumns={
          !program.isFreeProgram
            ? {
                lg: 'repeat(2, 1fr)',
                md: 'repeat(1, 1fr)',
                sm: 'repeat(1, 1fr)'
              }
            : 'repeat(1, 1fr)'
        }
        gap={2}
      >
        <GridItem>
          <InputComtrol label={'Añade calendario de clases'}>
            <UploadFile
              id={'classScheduleNames'}
              direction={'row'}
              sizeIcon={30}
              p={5}
              onChange={(e) => handleChangeClassSchedule(e)}
              error={Boolean(formik.errors.classScheduleNames)}
              errorText={formik.errors.classScheduleNames}
            />
          </InputComtrol>
          {formik.values.classScheduleNames.map((item) => (
            <BarFileName
              key={item?.name}
              fileName={item?.name}
              fileUrl={item?.url}
              onDelete={() => deleteClassSchedule(item?.name)}
            />
          ))}
        </GridItem>
        {/* --- */}

        {!program.isFreeProgram && (
          <GridItem>
            <InputComtrol label={'Añade boleta de honorarios'}>
              <UploadFile
                id={'feeBillNames'}
                direction={'row'}
                sizeIcon={30}
                p={5}
                onChange={(e) => handleChangeFeeBill(e)}
                error={Boolean(formik.errors.feeBillNames)}
                errorText={formik.errors.feeBillNames}
              />
            </InputComtrol>
            {formik.values.feeBillNames.map((item) => (
              <BarFileName
                key={item?.name}
                fileName={item?.name}
                fileUrl={item?.url}
                onDelete={() => deleteFeeBill(item?.name)}
              />
            ))}
          </GridItem>
        )}
      </Grid>
      {/* --- */}
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
        <GridItem>
          <InputComtrol label={'Añade planilla de asistencia'}>
            <UploadFile
              id={'attendanceSheetNames'}
              direction={'row'}
              sizeIcon={30}
              p={5}
              onChange={(e) => handleChangeAttendanceSheet(e)}
              error={Boolean(formik.errors.attendanceSheetNames)}
              errorText={formik.errors.attendanceSheetNames}
            />
          </InputComtrol>
          {formik.values.attendanceSheetNames.map((item) => (
            <BarFileName
              key={item?.name}
              fileName={item?.name}
              fileUrl={item?.url}
              onDelete={() => deleteAttendanceSheet(item?.name)}
            />
          ))}
        </GridItem>
      </Grid>
    </>
  )
}

export default RequestPaymentMonitors
