import React from 'react'
import { Grid, GridItem } from '@chakra-ui/react'
import { InputComtrol, TextAreaComtrol } from '../../UI'

const PartnerInformation = ({ program }) => (
  <>
    <Grid
      templateColumns={{
        lg: 'repeat(2, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem>
        <InputComtrol
          label={'Región'}
          value={program?.region}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Dirección'}
          value={program?.address}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Parentesco con el aspirante'}
          value={program?.relationship}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
      <GridItem>
        <InputComtrol
          label={'Comuna'}
          value={program?.commune}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Estado civil'}
          value={program?.civilStatus}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
    </Grid>
    <Grid
      templateColumns={{
        lg: 'repeat(2, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem>
        <InputComtrol
          label={'Zona de postulación'}
          value={program?.zone}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
      <GridItem>
        <InputComtrol
          label={'Alta repartición / Repartición'}
          value={program?.prefecture}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
    </Grid>
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem>
        <InputComtrol
          label={'Unidad'}
          value={program?.unit}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
    </Grid>
    <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
      <GridItem>
        <InputComtrol
          label={'Condición de residencia'}
          value={program?.residenceCondition}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
      <GridItem>
        <TextAreaComtrol
          label={'Observación de residencia '}
          value={program?.observations || 'No hay observaciones'}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
    </Grid>
  </>
)

export default PartnerInformation
