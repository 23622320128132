import { Flex, Text } from '@chakra-ui/react'
import { CardFilePreview } from '../../../UI'

const RenderInfoItem = ({ type = 'text', label = null, value = null }) => (
  <Flex direction="column" gap={2} justify="center" align="center">
    {label && <Text fontWeight={700}>{label}:</Text>}
    {type === 'text' && <Text>{value}</Text>}
    {type === 'file' && <CardFilePreview name={value?.name} url={value?.url} />}
  </Flex>
)

export default RenderInfoItem
