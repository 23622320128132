import React, { useState, useEffect } from 'react'
import { Grid, GridItem, Tr, Td, Flex, useDisclosure } from '@chakra-ui/react'
import { Table, Spinner, Alert } from '../UI'
import GetStatusCard from '../../Utils/GetStatusCard'
import OptionsMenu from './OptionsMenu'
import { usePartners } from '../../hooks/Partners'
import WithoutResults from '../WithoutResults'
import ResignationModal from './ResignationModal'

const TablePartners = ({ filtersPartners }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [currentId, setCurrentId] = useState(null)
  const [page, setPage] = useState(0)
  const limit = 6
  const {
    data: partners,
    isLoading,
    isFetching,
    isError
  } = usePartners(filtersPartners, page, limit)
  const headers = [
    '#',
    'Nombres y apellidos',
    'Rut',
    'Código',
    'Grado',
    'Status del Socio',
    'Opciones'
  ]

  useEffect(() => {
    setPage(0)
  }, [filtersPartners])

  return (
    <>
      {partners?.currentPages?.length === 0 && (
        <WithoutResults type={'PARTNERS-TABLE'} />
      )}
      {partners?.currentPages?.length > 0 && (
        <>
          <Grid templateColumns={'repeat(1, 1fr)'} w={'100%'}>
            <Flex w={'100%'} justify={'flex-end'} py={3}>
              <Spinner size={'lg'} show={isLoading || isFetching} />
            </Flex>
            {isError && (
              <Flex w={'100%'} justify={'flex-end'} py={3}>
                <Alert status={'error'} message={'Error al Cargar los Datos'} />
              </Flex>
            )}
            <GridItem
              overflowX={'auto'}
              display={{
                lg: 'table',
                md: 'break-word',
                sm: 'break-word'
              }}
              css={{
                '&::-webkit-scrollbar': {
                  width: '4px'
                },
                '&::-webkit-scrollbar-track': {
                  width: '4px'
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#2E7423',
                  borderRadius: '30px'
                }
              }}
            >
              <Table
                headers={headers}
                totalRecords={partners?.totalRecords}
                page={page}
                setPage={setPage}
              >
                {partners?.currentPages?.map((item, index) => (
                  <Tr key={item._id}>
                    <Td
                      fontWeight={700}
                      color={'grey.500'}
                      textAlign={'center'}
                    >
                      {index + 1 + page * limit}
                    </Td>
                    <Td fontWeight={700} color={'grey.500'}>
                      {item?.name}
                    </Td>
                    <Td color={'grey.500'}>{item?.rut}</Td>
                    <Td color={'grey.500'}>{item?.code}</Td>
                    <Td color={'grey.500'}>{item?.grade}</Td>
                    <Td>{GetStatusCard.partner(item?.state)}</Td>
                    <Td textAlign={'center'}>
                      <OptionsMenu
                        partner={item}
                        onOpen={onOpen}
                        setCurrentId={setCurrentId}
                      />
                    </Td>
                  </Tr>
                ))}
              </Table>
            </GridItem>
          </Grid>
          <ResignationModal
            isOpen={isOpen}
            onClose={onClose}
            currentId={currentId}
          />
        </>
      )}
    </>
  )
}

export default TablePartners
