import React from 'react'
import { Flex } from '@chakra-ui/react'
import { Button } from '../../../UI'

const ButtonFotter = ({ onClose, isLoading, onClick }) => (
  <Flex justify={'flex-end'} w={'full'}>
    <Button
      text={'Cancelar'}
      bg={'beige.500'}
      color={'green.500'}
      bghover={'beige.600'}
      onClick={onClose}
      disabled={isLoading}
      isLoading={isLoading}
    />
    <Button
      text={'Actualizar'}
      bg={'green.500'}
      onClick={onClick}
      disabled={isLoading}
      isLoading={isLoading}
    />
  </Flex>
)

export default ButtonFotter
