import { useFormik } from 'formik'
import { useParams } from 'react-router-dom'
import { Flex, Text } from '@chakra-ui/react'
import { GetFormData, GetNotification } from '../../Utils'
import { Button, InputComtrol } from '../UI'
import { resetPasswordSchema } from './schema'
import { useMutateActionPassword } from '../../hooks/resetPassword'

const ResetPasswordForm = () => {
  const { mutate, isLoading, reset } = useMutateActionPassword('RESET-PASSWORD')
  const { resetToken } = useParams()

  const initialValues = {
    password: '',
    repeatPassword: '',
    resetToken
  }

  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values) => {
      const formData = GetFormData(values)
      mutate(formData, {
        onSuccess: () => {
          reset()
          formik.resetForm()
          GetNotification.question(
            'Éxito',
            () => window.location.replace('/login'),
            () => {},
            'Contraseña actualizada correctamente, Revise su correo electrónico para más detalles',
            'success',
            false
          )
        },
        onError: (err) => {
          const response = JSON.parse(err.request.response)
          GetNotification.basic(
            'Error',
            'Aceptar',
            response?.message || 'Error al cambiar la contraseña',
            'error'
          )
        }
      })
    }
  })

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      height="100vh"
      bg={'baige.50'}
    >
      <Flex
        direction={'column'}
        gap={2}
        p={8}
        borderRadius={8}
        boxShadow={'2xl'}
      >
        <Text fontWeight={700} fontSize={'3xl'} color={'green.500'}>
          Restaurar contraseña
        </Text>
        <InputComtrol
          id={'password'}
          label={'Nueva contraseña'}
          type={'password'}
          value={formik.values.password}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.password)}
          errorText={formik.errors.password}
        />
        <InputComtrol
          id={'repeatPassword'}
          label={'Repetir Contraseña'}
          type={'password'}
          value={formik.values.repeatPassword}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.repeatPassword)}
          errorText={formik.errors.repeatPassword}
        />
        <Button
          mx={0}
          text={'Restaurar'}
          onClick={formik.handleSubmit}
          disabled={!formik.isValid}
          isLoading={isLoading}
        />
      </Flex>
    </Flex>
  )
}
export default ResetPasswordForm
