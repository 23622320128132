import { Avatar, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { FormatDate } from '../../Utils'

const AvatarNameUser = ({ name, lastName, dateNotification }) => (
  <Flex gap={2}>
    <Avatar bg={'grey.100'} color={'green.500'} name={`${name} ${lastName}`} />
    <Flex direction={'column'}>
      <Text fontWeight={700}>{`${name} ${lastName}`}</Text>

      <Text>
        {dateNotification ? FormatDate(dateNotification, 'short') : ''}
      </Text>
    </Flex>
  </Flex>
)

export default AvatarNameUser
