import React, { useEffect, useState } from 'react'
import { Select as SelectCha } from '@chakra-ui/react'

const Select = ({
  placeHolder,
  list = [],
  bg = 'beige.500',
  onChange,
  value = ''
}) => {
  const [selected, setSelected] = useState('')
  const handleChange = (e) => {
    const currentValue = e?.target?.value
    onChange(currentValue)
  }

  const getValue = () => {
    if (value || value === '') {
      const i = list.findIndex((item) => item === value)
      return list[i]
    }
    return list[-1]
  }
  useEffect(() => {
    const newValue = getValue()
    setSelected(newValue)
  }, [value])

  return (
    <SelectCha
      bg={bg}
      focusBorderColor={'beige.500'}
      placeholder={placeHolder}
      color={'green.500'}
      fontWeight={600}
      disabled={list.length === 0}
      onChange={(e) => handleChange(e)}
      value={selected}
    >
      {list.map((item) => (
        <option
          style={{ background: '#F1ECE2', color: '#2E7423' }}
          key={item}
          value={item}
        >
          {item}
        </option>
      ))}
    </SelectCha>
  )
}

export default Select
