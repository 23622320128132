/* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */

const scoreAvg = () => {
  const arr = []

  for (let i = 10; i <= 70; i++) {
    arr.push(i)
  }

  const range = arr.map((n) => (n / 10).toString())
  return range
}

const result = scoreAvg()

export default result
