import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Box, Flex, Spacer, Text, Avatar } from '@chakra-ui/react'
import getUserType from '../../Utils/GetUserType'
import AlertsContainer from '../AlertsContainer'

const NavigationHeader = (props) => {
  const history = useHistory()
  const { user } = useSelector((state) => state.auth)

  const handleClick = () => {
    history.push('/users/profile/1')
  }
  return (
    <Flex
      as={'nav'}
      align={'end'}
      justify={'flex-end'}
      wrap={'wrap'}
      padding={3}
      color={'white'}
      flexDirection={'column'}
      {...props}
    >
      <Spacer />
      <Box
        display={'flex'}
        mt={{ base: 4, md: 0 }}
        justify={'center'}
        alignItems={'center'}
        gap={2}
      >
        <AlertsContainer />

        <Flex
          gap={2}
          align={'center'}
          _hover={{ cursor: 'pointer' }}
          onClick={handleClick}
        >
          <Avatar
            borderRadius={5}
            size={'sm'}
            bg={'green.100'}
            color={'white'}
            name={`${user?.name} ${user?.lastName}`}
          />
          <Box
            display={'flex'}
            flexDirection={'column'}
            justify={'center'}
            alignItems={'strat'}
            me={{ lg: 20, md: 20, sm: 0 }}
          >
            <Text fontWeight="700" fontSize="14px" color={'green.500'}>
              {`${user?.name} ${user?.lastName}`}
            </Text>
            <Text fontWeight="50" fontSize="12px" color={'green.100'}>
              {getUserType(user?.role)}
            </Text>
          </Box>
        </Flex>
      </Box>
    </Flex>
  )
}

export default NavigationHeader
