import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Flex, useToast } from '@chakra-ui/react'
import { Step, Steps, useSteps } from 'chakra-ui-steps'
import { Button, CustomToast } from '../../UI'
import StepOne from './StepOne'
import { useMutateProgram } from '../../../hooks/Programs/InjuredInLinesOfServiceKit'
import { GetFormData, GetNotification } from '../../../Utils'

const InjuredInLinesOfServiceKit = ({ partner, program = null }) => {
  const toast = useToast()
  const refStepOne = useRef(null)
  const { nextStep, prevStep, activeStep } = useSteps({
    initialStep: 0
  })

  const history = useHistory()
  const [stepOneForm, setStepOneForm] = useState({
    /* partner field */
    partnerId: partner._id,
    partnerPhone: partner?.phone,
    partnerRut: partner?.rut,
    partnerGrade: partner?.grade,
    partnerCode: partner?.code,
    partnerZone: partner?.zone,
    partnerPrefecture: partner?.prefecture,
    partnerEndowment: partner?.endowment
    /* - */
  })
  const [finalForm, setFinalForm] = useState({
    partnerId: partner?._id
  })
  const [state, setState] = useState(null)
  const [update, setUpdate] = useState(false)
  const { mutate, isLoading, reset } = useMutateProgram('CREATE')
  const {
    mutate: mutateUpdate,
    isLoading: isLoadingUpdate,
    reset: resetUpdate
  } = useMutateProgram('UPDATE')

  const steps = [
    {
      label: 1,
      content: (
        <StepOne
          stepOneForm={stepOneForm}
          setStepOneForm={setStepOneForm}
          refStepOne={refStepOne}
          state={state}
          next={nextStep}
        />
      )
    }
  ]

  const handleNext = () => {
    if (activeStep === 0) {
      refStepOne.current()
      setUpdate(true)
    }
  }

  useEffect(() => {
    const beneficiariesHelped = ['Socio(a)']

    setFinalForm({
      ...finalForm,
      ...stepOneForm,
      beneficiariesHelped
    })
  }, [stepOneForm])

  useEffect(() => {
    if (Object.keys(finalForm).length > 9 && activeStep === 0) {
      if (!program) {
        const formData = GetFormData(
          finalForm,
          ['documents', 'aditionalDocuments'],
          ['documentsName', 'aditionalDocumentsName', 'beneficiariesHelped']
        )

        mutate(formData, {
          onSuccess: () => {
            reset()
            GetNotification.basic('Programa registrado con Éxito', 'Aceptar')
            history.push('/programs')
          },
          onError: (err) => {
            console.log(err)
            GetNotification.basic(
              'Error',
              'Aceptar',
              'Error al Registrar programa',
              'error'
            )
          }
        })
      }

      if (program && update) {
        const { _id: programId } = program
        const formData = GetFormData(
          finalForm,
          ['documents', 'aditionalDocuments'],
          ['documentsName', 'aditionalDocumentsName', 'beneficiariesHelped']
        )
        mutateUpdate(
          { programId, formData },
          {
            onSuccess: () => {
              resetUpdate()
              GetNotification.basic('Programa actualizado con Éxito', 'Aceptar')
              history.push('/programs')
            },
            onError: (err) => {
              console.log(err)
              GetNotification.basic(
                'Error',
                'Aceptar',
                'Error al actualizar programa',
                'error'
              )
            }
          }
        )
      }
    }
  }, [finalForm])

  useEffect(() => {
    if (program) {
      setStepOneForm({
        alternativePhone: program?.alternativePhone,
        isPartnerZone: program?.isPartnerZone,
        zone: program?.zone,
        prefecture: program?.prefecture,
        unit: program?.unit,
        receptionDate: program?.receptionDate,
        deliveryDate: program.deliveryDate,
        region: program.region,
        typeKit: program?.typeKit,
        observations: program.observations,
        documentsName: program.documents?.map((item) => item),
        /* partner field */
        partnerPhone: program?.partner.phone,
        partnerRut: program?.partner.rut,
        partnerGrade: program?.partner.grade,
        partnerCode: program?.partner.code,
        partnerZone: program?.partner.zone,
        partnerPrefecture: program?.partner.prefecture,
        partnerEndowment: program?.partner.endowment
        /* - */
      })
    }
  }, [program])

  useEffect(() => {
    if (isLoading || isLoadingUpdate) {
      toast({
        duration: 9000 * 9000,
        isClosable: true,
        position: 'bottom-right',
        render: ({ onClose }) => (
          <CustomToast
            text={'Espere por favor...'}
            status={'info'}
            onClose={onClose}
          />
        )
      })
      return
    }
    toast.closeAll()
  }, [isLoading, isLoadingUpdate])

  return (
    <Flex direction={'column'}>
      <Steps
        activeStep={activeStep}
        orientation="horizontal"
        responsive
        mb={{
          lg: 5,
          md: 0
        }}
      >
        {steps.map(({ label, content }) => (
          <Step key={label}>{content}</Step>
        ))}
      </Steps>
      <Flex justify={'flex-end'} mt={4}>
        <Button
          text={'Regresar'}
          bg={'green.50'}
          color={'green.500'}
          disabled={activeStep === 0 || isLoading || isLoadingUpdate}
          onClick={prevStep}
        />
        {activeStep === steps.length - 2 && (
          <Button
            text={'Guardar como incompleta'}
            bg={'green.50'}
            color={'green.500'}
            disabled={activeStep === 0 || isLoading || isLoadingUpdate}
            onClick={() => {
              setState('INCOMPLETE')
              handleNext()
            }}
            isLoading={isLoading || isLoadingUpdate}
          />
        )}
        <Button
          text={activeStep === steps.length - 1 ? 'Finalizar' : 'Siguiente'}
          onClick={() => {
            setState('APPROVED')
            handleNext()
          }}
          isLoading={isLoading || isLoadingUpdate}
          disabled={isLoading || isLoadingUpdate}
        />
      </Flex>
    </Flex>
  )
}

export default InjuredInLinesOfServiceKit
