import React from 'react'
import { addDays } from 'date-fns'
import { Flex, Text } from '@chakra-ui/react'
import { CustomDatePicker } from '../../../../UI'

const Filters = ({ filters, setFilters }) => {
  const handleChangeStartDate = (date) => {
    setFilters({
      ...filters,
      startDate: date
    })
  }

  const handleChangeEndDate = (date) => {
    setFilters({
      ...filters,
      endDate: date
    })
  }
  return (
    <Flex
      gap={4}
      direction={{
        lg: 'row',
        md: 'column',
        sm: 'column'
      }}
    >
      <Flex direction={'column'} gap={2} align={'flex-start'}>
        <Text whiteSpace={'nowrap'} fontWeight={700}>
          Fecha desde:
        </Text>
        <CustomDatePicker
          placeholder={'DD/MM/AAAA'}
          currentDate={filters.startDate}
          onChange={(date) => {
            handleChangeStartDate(date)
          }}
          maxDate={addDays(new Date(), 0)}
        />
      </Flex>
      <Flex direction={'column'} gap={2} align={'flex-start'}>
        <Text whiteSpace={'nowrap'} fontWeight={700}>
          Fecha hasta:
        </Text>
        <CustomDatePicker
          placeholder={'DD/MM/AAAA'}
          currentDate={filters.endDate}
          onChange={(date) => {
            handleChangeEndDate(date)
          }}
          maxDate={addDays(new Date(), 1)}
        />
      </Flex>
    </Flex>
  )
}

export default Filters
