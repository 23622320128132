import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { Grid, GridItem, Text } from '@chakra-ui/react'
import { ButtonDotted } from '../../../UI'
import BeneficiaryItem from './BeneficiaryItem'

const StepTwo = ({
  partner,
  stepTwoForm,
  setStepTwoForm,
  refStepTwo,
  next
}) => {
  const initialValues = {
    beneficiaries: []
  }

  const formik = useFormik({
    initialValues,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: (values) => {
      setStepTwoForm({
        ...values
      })
      next()
    }
  })

  const addBeneficiary = () => {
    formik.setValues({
      ...formik.values,
      beneficiaries: [
        ...formik.values.beneficiaries,
        {
          ID_CARD: Math.random().toString(),
          _id: '',
          isValid: false,
          toCreate: false
        }
      ]
    })
  }

  const isValidFormItems = () => {
    const isInvalid = formik.values.beneficiaries.filter(
      (item) => item.isValid === false
    ).length
    return Boolean(!isInvalid > 0)
  }

  const handleSubmit = () => {
    if (isValidFormItems() && formik.values.beneficiaries.length > 0) {
      formik.submitForm()
    }
  }

  useEffect(() => {
    refStepTwo.current = handleSubmit
  }, [formik.values.beneficiaries])

  useEffect(() => {
    formik.setValues({
      beneficiaries: stepTwoForm.beneficiaries || []
    })
  }, [stepTwoForm])

  return (
    <>
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
        <GridItem>
          <Text fontWeight={700} color={'green.500'} fontSize={'xl'}>
            Beneficiarios
          </Text>
        </GridItem>

        {formik.values.beneficiaries.map((item) => (
          <GridItem key={item.ID_CARD}>
            <BeneficiaryItem
              formik={formik}
              beneficiary={item}
              partner={partner}
            />
          </GridItem>
        ))}
        <GridItem>
          <ButtonDotted text={'Añadir beneficiario'} onClick={addBeneficiary} />
        </GridItem>
      </Grid>
    </>
  )
}

export default StepTwo
