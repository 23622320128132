import React from 'react'
import { Grid, GridItem, Text } from '@chakra-ui/react'
import PartnerInformation from './PartnerInformation'
import BeneficiaryInformation from './BeneficiaryInformation'
import FamilyBackground from './FamilyBackground'
import EconomicBackgroundFamily from './EconomicBackgroundFamily'
import ReasonRequest from './ReasonRequest'
import ApplicationInformation from './ApplicationInformation'
import RenderFlowDocuments from '../RenderFlowDocuments'
import { SectionAccordion } from '../../UI'
import { haveDocuments } from '../../../Utils'

const PreUniversityScholarships = ({ program }) => {
  const renderViewTitle = (title) => (
    <Text
      fontWeight={700}
      bg={'green.100'}
      color={'white'}
      fontSize={20}
      borderRadius={8}
      px={5}
      py={1}
      my={4}
    >
      {title}
    </Text>
  )

  const documentsInProgram = haveDocuments(
    ['reasonApplication', 'socialReport', 'additionalDocuments'],
    program
  )

  return (
    <Grid templateColumns={'repeat(1, minmax(0, 1fr))'} gap={2}>
      <GridItem>
        <PartnerInformation program={program} />
      </GridItem>
      <GridItem>
        {renderViewTitle('Información de la solicitud')}
        <ApplicationInformation program={program} />
      </GridItem>
      <GridItem>
        {renderViewTitle('Información del beneficiario')}
        <BeneficiaryInformation program={program} />
      </GridItem>
      <GridItem>
        {renderViewTitle('Antecedentes del grupo familiar')}
        <FamilyBackground program={program} />
      </GridItem>

      <GridItem>
        {renderViewTitle('Antecedentes económicos del grupo familiar')}
        <EconomicBackgroundFamily program={program} />
      </GridItem>

      <GridItem>
        {documentsInProgram && renderViewTitle('Motivo de la solicitud')}
        <ReasonRequest program={program} />
      </GridItem>
      <SectionAccordion title={'Documentos agregados dentro del flujo'}>
        <GridItem>
          <RenderFlowDocuments program={program} />
        </GridItem>
      </SectionAccordion>
    </Grid>
  )
}

export default PreUniversityScholarships
