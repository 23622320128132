import React from 'react'
import { Grid, GridItem, Text } from '@chakra-ui/react'
import { CardFilePreview } from '../../UI'

const Documents = ({ program }) => (
  <>
    <Text fontWeight={700} fontSize={'xl'}>
      Certificado de defunción
    </Text>
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      {program?.deathCertificate?.map((item) => (
        <GridItem py={3}>
          <CardFilePreview key={item.url} name={item?.name} url={item?.url} />
        </GridItem>
      ))}
    </Grid>
    <Text fontWeight={700} fontSize={'xl'}>
      Liquidación de sueldo
    </Text>
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      {program?.salarySettlement?.map((item) => (
        <GridItem py={3}>
          <CardFilePreview key={item.url} name={item?.name} url={item?.url} />
        </GridItem>
      ))}
    </Grid>
    {program?.additionalDocuments?.length > 0 && (
      <>
        <Text fontWeight={700} fontSize={'xl'}>
          Documentos adicionales
        </Text>
        <Grid
          templateColumns={{
            lg: 'repeat(1, 1fr)',
            md: 'repeat(1, 1fr)',
            sm: 'repeat(1, 1fr)'
          }}
          gap={2}
        >
          {program?.additionalDocuments?.map((item) => (
            <GridItem py={3}>
              <CardFilePreview
                key={item.url}
                name={item?.name}
                url={item?.url}
              />
            </GridItem>
          ))}
        </Grid>
      </>
    )}
  </>
)

export default Documents
