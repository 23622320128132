import React, { useState, useEffect } from 'react'
import { Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import { ButtonDotted, InfoCard } from '../../../UI'
import FamilyMemberCard from './FamilyMemberCard'
import { ClearFormatMoney, FormatMoney } from '../../../../Utils'

const StepThree = ({ refStepThree, stepThreeForm, setStepThreeForm, next }) => {
  const [members, setMembers] = useState([])
  const [totalExpenditure, setTotalExpenditure] = useState(0)

  const handleAddMember = () => {
    const memberTemplate = {
      _id: Date.now().toString(),
      memberName: '',
      memberAge: 0,
      memberRelationship: '',
      isFamilyBurden: '',
      memberActivity: '',
      memberIncomes: '',
      memberSwornDeclarationName: '',
      memberSwornDeclaration: null,
      /* --- health data --- */
      haveMedicalHistory: false,
      doctorName: '',
      hospitalName: '',
      monthlyExpenditure: '',
      diagnostic: '',
      evolutionTime: '',
      observation: '',
      updated: false
    }
    setMembers([...members, memberTemplate])
  }

  const validateEmptyMember = () => {
    if (members.length > 0) {
      const exists = members.find((item) => item.updated === false)
      return Boolean(exists)
    }
    return false
  }

  const handleDeleteMember = (_id) => {
    const index = members.findIndex((item) => item._id === _id)
    if (index !== -1) {
      const newMembers = [...members]
      newMembers.splice(index, 1)
      setMembers(newMembers)
    }
  }

  const handleUpdateMember = (id, values, isValid = true) => {
    const index = members.findIndex((item) => item._id === id)
    const newMembers = [...members]
    if (index !== -1 && isValid) {
      const updated = {
        _id: id,
        memberName: values.memberName,
        memberAge: values.memberAge,
        memberRelationship: values.memberRelationship,
        isFamilyBurden: values.isFamilyBurden,
        memberActivity: values.memberActivity,
        memberIncomes: values.memberIncomes,
        memberSwornDeclarationName: values.memberSwornDeclarationName,
        memberSwornDeclaration: values.memberSwornDeclaration,
        /* --- health data --- */
        haveMedicalHistory: values.haveMedicalHistory,
        doctorName: values.doctorName,
        hospitalName: values.hospitalName,
        monthlyExpenditure: values.monthlyExpenditure,
        diagnostic: values.diagnostic,
        evolutionTime: values.evolutionTime,
        observation: values.observation,
        state: values.state,
        updated: true
      }

      newMembers.splice(index, 1, updated)
      setMembers(newMembers)
    }
    if (index !== -1 && !isValid) {
      newMembers[index].updated = false
      setMembers(newMembers)
    }
  }

  const handleSubmit = () => {
    if (members.length > 0 && !validateEmptyMember()) {
      const filteredForms = members.filter((item) => item.updated === true)
      setStepThreeForm(filteredForms)
      next()
    }
  }

  useEffect(() => {
    refStepThree.current = handleSubmit

    if (members.length > 0) {
      const total = members.reduce(
        (acc, item) => acc + Number(ClearFormatMoney(item.monthlyExpenditure)),
        0
      )
      setTotalExpenditure(FormatMoney(total))
    }
  }, [members])

  useEffect(() => {
    if (stepThreeForm.length > 0) {
      setMembers(stepThreeForm)
    }
  }, [stepThreeForm])

  return (
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem>
        <Text fontWeight={700} color={'green.500'} fontSize={'xl'}>
          Antecedentes del grupo familiar
        </Text>
        {members.length > 0 && (
          <Flex justify={'flex-end'}>
            <InfoCard px={10}>
              <Text fontWeight={700}>
                Subtotal gastos médicos grupo familiar
              </Text>
              <Text fontWeight={700} fontSize={'2xl'}>
                $ {totalExpenditure}
              </Text>
            </InfoCard>
          </Flex>
        )}
        {members.map((item) => (
          <FamilyMemberCard
            key={item._id}
            _id={item._id}
            members={members}
            member={item}
            handleUpdateMember={handleUpdateMember}
            onClick={(_id) => handleDeleteMember(_id)}
          />
        ))}
        <ButtonDotted
          text={'Añadir integrante'}
          onClick={handleAddMember}
          disabled={validateEmptyMember()}
        />
      </GridItem>
    </Grid>
  )
}

export default StepThree
