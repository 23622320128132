import React, { useEffect } from 'react'
import { addDays } from 'date-fns'
import { useFormik } from 'formik'
import {
  Flex,
  Grid,
  GridItem,
  Text,
  useToast,
  Checkbox
} from '@chakra-ui/react'
import { steepTwoSchema } from './Schemas'
import {
  BarFileName,
  CustomDatePicker,
  CustomToast,
  InputComtrol,
  Select,
  UploadFile
} from '../../../UI'
import { AccountType, BanksList, Files, FormatRut } from '../../../../Utils'

const StepTwo = ({ stepTwoForm, setStepTwoForm, refStepTwo, state }) => {
  const toast = useToast()
  const initialValues = {
    fullNameMonitor: '',
    rutMonitor: '',
    birthDateMonitor: '',
    addressMonitor: '',
    phonePrimaryMonitor: '',
    phoneSecondaryMonitor: '',
    emailMonitor: '',
    certificateMonitorName: [],
    certificateMonitor: null,
    /* -bank data- */
    isRequiredAccountNumber: true,
    accountNumber: '',
    accountBackupName: [],
    accountBackup: null,
    typeAccount: AccountType.at(0),
    specificAccount: false,
    typeAccountOther: '',
    isFreeProgram: false,
    bank: BanksList.at(0),
    specificBank: false,
    bankOther: ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: steepTwoSchema,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: (values) => {
      if (!state) {
        setStepTwoForm({
          ...values
        })
      }
      if (state) {
        setStepTwoForm({
          ...values,
          state
        })
      }
    }
  })

  const handleChangeRut = () => {
    if (formik.values.rutMonitor) {
      const formatedRut = FormatRut(formik.values.rutMonitor)
      formik.setFieldValue('rutMonitor', formatedRut)
    }
  }

  const handleChangeBank = (value) => {
    if (value === BanksList.at(-1)) {
      formik.setValues({
        ...formik.values,
        bank: value,
        specificBank: true
      })
      return
    }
    formik.setValues({
      ...formik.values,
      bank: value,
      specificBank: false
    })
  }

  const handleChangeTypeAccount = (value) => {
    if (value === 'Cuenta Rut') {
      formik.setValues({
        ...formik.values,
        typeAccount: value,
        isRequiredAccountNumber: false
      })
      return
    }
    if (value === AccountType.at(-1)) {
      formik.setValues({
        ...formik.values,
        typeAccount: value,
        specificAccount: true,
        isRequiredAccountNumber: true
      })
      return
    }
    formik.setValues({
      ...formik.values,
      typeAccount: value,
      specificAccount: false,
      isRequiredAccountNumber: true
    })
  }

  const handleChangeBirthDateMonitor = (date) => {
    formik.setFieldValue('birthDateMonitor', date)
  }

  const deleteAccountBackup = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.accountBackupName,
      formik.values.accountBackup
    )

    formik.setValues({
      ...formik.values,
      accountBackupName: resultNames,
      accountBackup: resultListFiles
    })
  }

  const deleteCertificateMonitor = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.certificateMonitorName,
      formik.values.certificateMonitor
    )

    formik.setValues({
      ...formik.values,
      certificateMonitorName: resultNames,
      certificateMonitor: resultListFiles
    })
  }

  const handleChangeCertificateMonitor = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.certificateMonitorName,
      formik.values.certificateMonitor
    )

    formik.setValues({
      ...formik.values,
      certificateMonitorName: newListNames,
      certificateMonitor: newListFiles
    })
    e.target.value = null
  }

  const handleChangeAccountBackup = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.accountBackupName,
      formik.values.accountBackup
    )

    formik.setValues({
      ...formik.values,
      accountBackupName: newListNames,
      accountBackup: newListFiles
    })
    e.target.value = null
  }

  useEffect(() => {
    refStepTwo.current = formik.handleSubmit
  }, [])

  useEffect(() => {
    formik.setValues({
      fullNameMonitor: stepTwoForm.fullNameMonitor || '',
      rutMonitor: stepTwoForm.rutMonitor || '',
      birthDateMonitor: stepTwoForm.birthDateMonitor || '',
      addressMonitor: stepTwoForm.addressMonitor || '',
      phonePrimaryMonitor: stepTwoForm.phonePrimaryMonitor || '',
      phoneSecondaryMonitor: stepTwoForm.phoneSecondaryMonitor || '',
      emailMonitor: stepTwoForm.emailMonitor || '',
      certificateMonitorName: stepTwoForm.certificateMonitorName || [],
      certificateMonitor: stepTwoForm.certificateMonitor || null,
      /* -bank data- */
      isRequiredAccountNumber: Boolean(
        stepTwoForm.typeAccount !== 'Cuenta Rut'
      ),
      isFreeProgram: stepTwoForm.isFreeProgram,
      accountNumber: stepTwoForm.accountNumber || '',
      accountBackupName: stepTwoForm.accountBackupName || [],
      accountBackup: stepTwoForm.accountBackup || null,
      typeAccount: stepTwoForm.typeAccount || AccountType.at(0),
      specificAccount: Boolean(stepTwoForm.typeAccountOther) || false,
      typeAccountOther: stepTwoForm.typeAccountOther || '',
      bank: stepTwoForm.bank || BanksList.at(0),
      specificBank: Boolean(stepTwoForm.bankOther) || false,
      bankOther: stepTwoForm.bankOther || ''
    })
  }, [stepTwoForm])

  useEffect(() => {
    if (formik.isSubmitting && Object.keys(formik.errors).length > 0) {
      Object.keys(formik.errors).forEach((key) => {
        toast({
          duration: 2000,
          isClosable: true,
          position: 'top-right',
          render: ({ onClose }) => (
            <CustomToast
              text={formik.errors[key]}
              showSpinner={false}
              status={'error'}
              onClose={onClose}
            />
          )
        })
      })
    }
  }, [formik.errors])

  return (
    <>
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
        <GridItem>
          <Text fontWeight={700} color={'green.500'} fontSize={'xl'}>
            Información del monitor
          </Text>
          <InputComtrol
            id={'fullNameMonitor'}
            label={'Nombres y apellidos'}
            value={formik.values.fullNameMonitor}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.fullNameMonitor)}
            errorText={formik.errors.fullNameMonitor}
          />
        </GridItem>
      </Grid>
      {/* --- */}
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          <InputComtrol
            id={'rutMonitor'}
            label={'RUT'}
            value={formik.values.rutMonitor}
            onChange={formik.handleChange}
            onBlur={handleChangeRut}
            error={Boolean(formik.errors.rutMonitor)}
            errorText={formik.errors.rutMonitor}
          />
        </GridItem>
        <GridItem>
          <InputComtrol id={'birthDateMonitor'} label={'Fecha de nacimiento'}>
            <CustomDatePicker
              placeholder={'DD/MM/AAAA'}
              currentDate={formik.values.birthDateMonitor}
              onChange={(date) => handleChangeBirthDateMonitor(date)}
              error={Boolean(formik.errors.birthDateMonitor)}
              errorText={formik.errors.birthDateMonitor}
              maxDate={addDays(new Date(), 0)}
            />
          </InputComtrol>
        </GridItem>
      </Grid>
      {/* --- */}
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
        <GridItem></GridItem>
      </Grid>
      {/* --- */}
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
        <GridItem>
          <InputComtrol
            id={'addressMonitor'}
            label={'Dirección'}
            value={formik.values.addressMonitor}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.addressMonitor)}
            errorText={formik.errors.addressMonitor}
          />
        </GridItem>
      </Grid>
      {/* --- */}
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          <InputComtrol
            id={'phonePrimaryMonitor'}
            label={'Teléfono principal'}
            addictionType={'phone'}
            value={formik.values.phonePrimaryMonitor}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.phonePrimaryMonitor)}
            errorText={formik.errors.phonePrimaryMonitor}
          />
        </GridItem>
        <GridItem>
          <InputComtrol
            id={'phoneSecondaryMonitor'}
            isRequired={false}
            label={'Teléfono secundario'}
            addictionType={'phone'}
            value={formik.values.phoneSecondaryMonitor}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.phoneSecondaryMonitor)}
            errorText={formik.errors.phoneSecondaryMonitor}
          />
        </GridItem>
      </Grid>
      {/* --- */}
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
        <GridItem>
          <InputComtrol
            id={'emailMonitor'}
            label={'Correo electrónico'}
            type={'email'}
            value={formik.values.emailMonitor}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.emailMonitor)}
            errorText={formik.errors.emailMonitor}
          />
        </GridItem>
        <GridItem>
          <Text fontSize={'md'} color={'green.500'}>
            Añadir ficha del monitor
          </Text>
          <UploadFile
            id={'certificateMonitor'}
            direction={'row'}
            sizeIcon={30}
            p={5}
            onChange={(e) => handleChangeCertificateMonitor(e)}
            error={Boolean(formik.errors.certificateMonitorName)}
            errorText={formik.errors.certificateMonitorName}
          />
          <Flex direction={'column'} gap={1}>
            {formik.values.certificateMonitorName.map((item) => (
              <BarFileName
                key={item?.name}
                fileName={item?.name}
                fileUrl={item?.url}
                onDelete={() => deleteCertificateMonitor(item?.name)}
              />
            ))}
          </Flex>
        </GridItem>
      </Grid>
      {/* --- */}
      <Checkbox
        css={{ borderColor: 'green', marginBottom: 2 }}
        size="md"
        colorScheme="green"
        id={'isFreeProgram'}
        isChecked={formik.values.isFreeProgram}
        onChange={formik.handleChange}
      >
        ¿Servicio gratuito?
      </Checkbox>
      {!formik.values.isFreeProgram && (
        <Grid
          templateColumns={{
            lg: 'repeat(2, 1fr)',
            md: 'repeat(1, 1fr)',
            sm: 'repeat(1, 1fr)'
          }}
          gap={2}
        >
          <GridItem>
            <Text fontWeight={700} color={'green.500'} fontSize={'xl'}>
              Información bancaria
            </Text>
            <InputComtrol id={'bank'} label={'Banco del destinatario'}>
              <Select
                bg={'white'}
                list={BanksList}
                value={formik.values.bank}
                onChange={handleChangeBank}
              />
            </InputComtrol>
            {formik.values.specificBank && (
              <InputComtrol
                id={'bankOther'}
                label={'Especificar banco'}
                type={'text'}
                value={formik.values.bankOther}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.bankOther)}
                errorText={formik.errors.bankOther}
              />
            )}
          </GridItem>
          {/* - */}
          <GridItem>
            <Text fontSize={'xl'} opacity={0}>
              -----
            </Text>
            <InputComtrol id={'typeAccount'} label={'Tipo de Cuenta'}>
              <Select
                bg={'white'}
                list={AccountType}
                value={formik.values.typeAccount}
                onChange={handleChangeTypeAccount}
              />
            </InputComtrol>
            {formik.values.specificAccount && (
              <InputComtrol
                id={'typeAccountOther'}
                label={'Especificar tipo de cuenta'}
                type={'text'}
                value={formik.values.typeAccountOther}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.typeAccount)}
                errorText={formik.errors.typeAccount}
              />
            )}
          </GridItem>
        </Grid>
      )}
      {/* --- */}
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem></GridItem>
      </Grid>
      {/* --- */}
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
        {!formik.values.isFreeProgram &&
          formik.values.isRequiredAccountNumber && (
            <GridItem>
              <InputComtrol
                id={'accountNumber'}
                label={'Nº de cuenta'}
                value={formik.values.accountNumber}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.accountNumber)}
                errorText={formik.errors.accountNumber}
              />
            </GridItem>
          )}
        <GridItem>
          <Text fontSize={'md'} color={'green.500'}>
            Respaldo de cuenta del monitor (opcional)
          </Text>
          <UploadFile
            id={'accountBackupName'}
            direction={'row'}
            sizeIcon={30}
            p={5}
            onChange={(e) => handleChangeAccountBackup(e)}
            error={Boolean(formik.errors.accountBackupName)}
            errorText={formik.errors.accountBackupName}
          />
          <Flex direction={'column'} gap={1}>
            {formik.values.accountBackupName.map((item) => (
              <BarFileName
                key={item?.name}
                fileName={item?.name}
                fileUrl={item?.url}
                onDelete={() => deleteAccountBackup(item?.name)}
              />
            ))}
          </Flex>
        </GridItem>
      </Grid>
      {/* --- */}
    </>
  )
}

export default StepTwo
