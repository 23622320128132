import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { Grid, GridItem, Text, Checkbox, Flex } from '@chakra-ui/react'
import { Alert, ButtonDotted, InfoCard } from '../../../UI'
import { stepTwoSchema } from '../Schemas'
import FamilyMemberItem from './FamilyMemberItem'

const StepTwo = ({
  partner,
  stepOneForm,
  stepTwoForm,
  setStepTwoForm,
  refStepTwo,
  next
}) => {
  const [maxPeople, setMaxPeople] = useState(0)
  const initialValues = {
    patientId: '',
    isRequiredAdditionalGuest: false,
    isRequiredAddMoreGuest: false,
    additionalGuest: []
  }

  const formik = useFormik({
    initialValues,
    validationSchema: stepTwoSchema,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: (values) => {
      setStepTwoForm({
        ...values
      })
      next()
    }
  })

  const addMember = () => {
    formik.setValues({
      ...formik.values,
      additionalGuest: [
        ...formik.values.additionalGuest,
        {
          ID_CARD: Math.random().toString(),
          _id: Math.random().toString(),
          isValid: false,
          guestToCreate: false
        }
      ]
    })
  }

  const isValidFormItems = () => {
    const isInvalid = formik.values.additionalGuest.filter(
      (item) => item.isValid === false
    ).length
    return Boolean(!isInvalid > 0)
  }

  const handleSubmit = () => {
    if (isValidFormItems() || formik.values.additionalGuest.length === 0) {
      formik.submitForm()
    }
  }

  const getMaxPeople = () => {
    const { patientAge, patientFullName, isPatientPartner } = stepOneForm

    if (parseInt(patientAge, 10) <= 12 && !isPatientPartner) {
      return {
        cant: 3,
        patient: patientFullName
      }
    }
    return {
      cant: 2,
      patient: patientFullName
    }
  }

  useEffect(() => {
    refStepTwo.current = handleSubmit
  }, [formik.values.additionalGuest])

  useEffect(() => {
    if (formik.values.additionalGuest.length <= maxPeople.cant - 1) {
      formik.setValues({
        ...formik.values,
        isRequiredAddMoreGuest: false
      })
    }
  }, [formik.values.additionalGuest.length])

  useEffect(() => {
    if (!formik.values.isRequiredAdditionalGuest) {
      formik.setValues({
        ...formik.values,
        isRequiredAddMoreGuest: false,
        additionalGuest: []
      })
    }
  }, [formik.values.isRequiredAdditionalGuest])

  useEffect(() => {
    formik.setValues({
      patientId: stepOneForm.patientId || '',
      isRequiredAdditionalGuest: stepTwoForm.isRequiredAdditionalGuest || false,
      isRequiredAddMoreGuest: stepTwoForm.isRequiredAddMoreGuest || false,
      additionalGuest: stepTwoForm.additionalGuest || []
    })
    setMaxPeople(getMaxPeople())
  }, [stepTwoForm, stepOneForm])

  return (
    <>
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
        <GridItem>
          <Text fontWeight={700} color={'green.500'} fontSize={'xl'}>
            Información de familiares
          </Text>
        </GridItem>
        <GridItem>
          <Flex justify={'space-between'} align={'center'}>
            <Flex direction={'column'}>
              <Text color={'green.500'} fontSize={'sm'}>
                ● Número máximo de huéspedes: {maxPeople.cant} (Contando con el
                Paciente)
              </Text>
              <Text color={'green.500'} fontSize={'sm'}>
                ● Nombre del huésped: {maxPeople.patient}
              </Text>
            </Flex>
            <InfoCard px={10}>
              <Text fontWeight={700}>Total de huéspedes</Text>
              <Text fontWeight={700} fontSize={'2xl'}>
                {formik.values.additionalGuest.length + 1}
                {/* +1 contando al del paso anterio */}
              </Text>
            </InfoCard>
          </Flex>
          <Checkbox
            css={{ borderColor: 'green' }}
            id={'isRequiredAdditionalGuest'}
            size="md"
            colorScheme="green"
            isChecked={formik.values.isRequiredAdditionalGuest}
            value={formik.values.isRequiredAdditionalGuest}
            onChange={formik.handleChange}
            py={4}
            fontWeight={700}
          >
            ¿Desea Agregar más huéspedes?
          </Checkbox>
        </GridItem>

        {formik.values.additionalGuest?.map((item) => (
          <GridItem key={item?.ID_CARD}>
            <FamilyMemberItem formik={formik} member={item} partner={partner} />
          </GridItem>
        ))}
        {formik.values.additionalGuest.length + 1 === maxPeople.cant &&
          !formik.values.isRequiredAddMoreGuest && (
            <GridItem>
              <Alert
                status={'warning'}
                message={'Has Alcanzado el Máximo de huéspedes permitidos'}
              />
              <Checkbox
                css={{ borderColor: 'green' }}
                id={'isRequiredAddMoreGuest'}
                size="md"
                colorScheme="green"
                isChecked={formik.values.isRequiredAddMoreGuest}
                value={formik.values.isRequiredAddMoreGuest}
                onChange={formik.handleChange}
                pt={4}
                fontWeight={700}
              >
                ¿Desea Agregar aun más huéspedes?
              </Checkbox>
              <Text color={'green.500'} fontSize={'sm'}>
                Nota: Está acción sujeta a aprobación
              </Text>
            </GridItem>
          )}
        {formik.values.isRequiredAdditionalGuest && (
          <GridItem>
            <ButtonDotted
              text={'Añadir integrante'}
              onClick={addMember}
              disabled={
                formik.values.additionalGuest.length === maxPeople.cant - 1 &&
                !formik.values.isRequiredAddMoreGuest
              }
            />
          </GridItem>
        )}
      </Grid>
    </>
  )
}

export default StepTwo
