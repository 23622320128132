/* eslint-disable import/prefer-default-export */
import axios from '../Axios'

const module = '/family-member'

export const getFamilyMembersByPartner = async (partnerId) => {
  const { data } = await axios.get(`${module}/${partnerId}`)
  return data
}

export const getFamilyMembersByPartnerPaginate = async (
  filtersFamilyMembers,
  page = 0,
  limit = 6
) => {
  const { id, order } = filtersFamilyMembers
  const { data } = await axios.get(
    `${module}/?id=${id}&page=${page}&limit=${limit}&order=${order}`
  )
  return data
}

export const updateFamilyMember = async ({ familyMemberId, formData }) => {
  const { data } = await axios.patch(`${module}/${familyMemberId}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
  return data
}
