import React from 'react'
import { Checkbox, Grid, GridItem } from '@chakra-ui/react'
import { InputComtrol, TextAreaComtrol } from '../../UI'

const InformationAffected = ({ program }) => (
  <>
    <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
      <GridItem>
        <Checkbox
          css={{ borderColor: 'green' }}
          size="md"
          colorScheme="green"
          isChecked={program.isPartnerAffected}
          readOnly={true}
        >
          ¿El afectado es el Socio?
        </Checkbox>
      </GridItem>
      {!program.isPartnerAffected && (
        <GridItem>
          <InputComtrol
            label={'Nombres y apellidos'}
            value={program?.fullName}
            isRequired={false}
            readOnly={true}
          />
        </GridItem>
      )}
      <GridItem>
        <TextAreaComtrol
          label={'Descripción breve de la emergencia'}
          value={program?.description}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
    </Grid>
    {!program.isPartnerAffected && (
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          <InputComtrol
            label={'Parentesco'}
            value={program?.relationship}
            isRequired={false}
            readOnly={true}
          />
        </GridItem>
        <GridItem>
          <InputComtrol
            label={'Teléfono de contacto del familiar o pariente directo'}
            addictionType={'phone'}
            value={program?.phoneDirectMember}
            isRequired={false}
            readOnly={true}
          />
        </GridItem>
      </Grid>
    )}
  </>
)

export default InformationAffected
