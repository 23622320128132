import { extendTheme, theme as base } from '@chakra-ui/react'
import { StepsStyleConfig as Steps } from 'chakra-ui-steps'

const light = extendTheme({
  fonts: {
    heading: `Inter, ${base.fonts?.heading}`,
    body: `Inter, ${base.fonts?.body}`
  },
  styles: {
    global: {
      'html, body': {
        m: 0,
        p: 0,
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
          width: '8px'
        },
        '&::-webkit-scrollbar-track': {
          width: '6px'
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#2E7423',
          borderRadius: '24px'
        }
      }
    }
  },
  shadows: {
    outline: '0 0 0 3px green.500'
  },
  components: {
    Steps
  },
  colors: {
    green: {
      50: '#D0EECB',
      100: '#82B378',
      500: '#2E7423'
    },
    beige: {
      50: '#EEF5EF',
      100: '#FEF7EA',
      500: '#F1ECE2',
      600: '#e5ddcc'
    },
    grey: {
      50: '#EEEEEE',
      100: '#E5E5E5',
      500: '#9E9E9E'
    },
    orange: {
      50: '#FFF4DF',
      100: '#FAA86D',
      500: '#FAA86D'
    },
    red: {
      50: '#FFD4D4',
      100: '#F56767',
      500: '#F56767'
    },
    blue: {
      50: '#DFF0FF',
      100: '#4A75CB',
      500: '#4A75CB'
    }
  }
})

export default light
