const valuesToFormData = (values, iterableValues = [], toJson = []) => {
  const formData = new FormData()
  const clearValues = { ...values }

  Object.keys(clearValues).forEach((key) => {
    if (toJson.includes(key)) {
      clearValues[key] = JSON.stringify(clearValues[key])
    }

    if (clearValues[key] === null || clearValues[key]?.length === 0) {
      delete clearValues[key]
    }
  })

  Object.keys(clearValues).forEach((key) => {
    if (iterableValues.includes(key)) {
      Object.keys(clearValues[key]).forEach((key_) => {
        formData.append(`${key}`, clearValues[key][key_])
      })
    }
    if (!iterableValues.includes(key)) {
      formData.append(key, clearValues[key])
    }
  })
  return formData
}

export default valuesToFormData
