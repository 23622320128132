import React from 'react'
import { useFormik } from 'formik'
import { Grid, GridItem, Flex, Box, Image, Text } from '@chakra-ui/react'
import { InputComtrol, Button } from '../UI'
import { ArrowStepWhiteIcon } from '../Icons'
import Logo from '../../assets/images/logo.png'
import MadeWithLove from '../../assets/images/made with love by foreach.gif'
import { loginSchema } from './schema'
import { useMutateLogin } from '../../hooks/login'
import { GetFormData, GetNotification } from '../../Utils'
import ForgotPassword from './ForgotPassword'

const CardLogin = () => {
  const { mutate, isLoading, reset } = useMutateLogin()

  const initialValues = {
    email: '',
    password: ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values) => {
      const formData = GetFormData(values)
      mutate(formData, {
        onSuccess: () => {
          reset()
          formik.resetForm()
        },
        onError: (err) => {
          console.log(err)
          GetNotification.basic(
            'Error',
            'Aceptar',
            'Error credenciales invalidas',
            'error'
          )
        }
      })
    }
  })

  const forgotPassword = () => {
    alert('hola')
  }

  return (
    <Grid templateColumns={'repeat(1, 1fr)'}>
      <GridItem>
        <Flex
          bg={{
            lg: 'white',
            md: 'beige.100',
            sm: 'beige.100'
          }}
          boxShadow={'xl'}
          rounded={'2xl'}
          direction={'column'}
          justify={'center'}
          align={'center'}
          color={'green.500'}
          gap={9}
          px={10}
          py={10}
        >
          <Box w="max-content">
            <Image src={Logo} alt="logo" w={150} />
          </Box>
          <Flex direction={'column'} textAlign={'start'} w={'100%'}>
            <Text color={'green.100'}>Bienvenid@</Text>
            <Text fontWeight={700} fontSize={'3xl'} color={'green.500'}>
              Inicia Sesión
            </Text>
          </Flex>
          <InputComtrol
            id={'email'}
            label={'Correo Electrónico'}
            placeholder={'Nombre@mail.com'}
            type={'email'}
            value={formik.values.email}
            error={Boolean(formik.errors.email)}
            onChange={formik.handleChange}
            errorText={formik.errors.email}
          />
          <InputComtrol
            id={'password'}
            label={'Contraseña'}
            type={'password'}
            value={formik.values.password}
            onChange={formik.handleChange}
          />
          <ForgotPassword />
          <Box ml={'auto'}>
            <Button
              text={'Ingresar'}
              icon={ArrowStepWhiteIcon}
              disabled={!formik.isValid}
              onClick={formik.handleSubmit}
              isLoading={isLoading}
            />
          </Box>
          <Box w="max-content">
            <Image src={MadeWithLove} alt="logo" w={150} />
          </Box>
        </Flex>
      </GridItem>
    </Grid>
  )
}

export default CardLogin
