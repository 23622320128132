/* eslint-disable import/prefer-default-export */
import { useMutation, useQuery, useQueryClient } from 'react-query'

import {
  sendWsp,
  registerNotification,
  otherProcessesHistory,
  otherProcessesHistoryEdit,
  multiProgramProcesses,
  resignationOrLossRequest,
  getProgramsSameStep,
  deleteProgram
} from '../api/HistoryProgram'

/* const key = 'historyProgram' */
const keyProgramsSameStep = 'programsSameStep'
const keyProgram = 'Allprograms'

const getAction = (type) => {
  if (type === 'SEND-WSP') return sendWsp
  if (type === 'REGISTER-NOTIFICATION') return registerNotification
  if (type === 'OTHER-PROCESSES') return otherProcessesHistory
  if (type === 'OTHER-PROCESSES-EDIT') return otherProcessesHistoryEdit
  if (type === 'MULTI-PROGRAM-PROCESSES') return multiProgramProcesses
  if (type === 'RESIGNATION-OR-LOSS-REQUEST') return resignationOrLossRequest
  if (type === 'DELETE-HISTORY-AND-PROGRAM') return deleteProgram
  return null
}

export function useProgramsSameStep(params, props = {}) {
  return useQuery([keyProgramsSameStep, params], () =>
    getProgramsSameStep(params, {
      ...props
    })
  )
}

export function useMutateHistoryProgram(type) {
  const queryClient = useQueryClient()
  const actionFn = getAction(type)
  return useMutation(actionFn, {
    onSuccess: () => {
      queryClient.invalidateQueries([keyProgram])
    }
  })
}
