const accountType = [
  'Cuenta corriente',
  'Cuenta de ahorros',
  'Cuenta de vista',
  'Cuenta Rut',
  'Cuenta Chequera Electrónica',
  'Otro'
]

export default accountType
