/* eslint-disable import/prefer-default-export */
import { useQuery } from 'react-query'
import { getOneNotification } from '../api/NotificationsSent'

const key = 'notificationsSent'

export function useNotificationsSent(programId, keyStep, step) {
  return useQuery([key, programId, keyStep, step], () =>
    getOneNotification(programId, keyStep, step)
  )
}
