const CivilStatus = [
  'Soltero/a',
  'Casado/a',
  'Viudo/a',
  'Divorciado/a',
  'Separado/a',
  'Conviviente'
]

export default CivilStatus
