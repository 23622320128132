import React from 'react'
import { Flex, Text } from '@chakra-ui/react'

const CardData = ({
  title,
  totalRecords,
  amount,
  count,
  colorCard,
  typeCard,
  ...props
}) => (
  <>
    {typeCard === 'amount' && (
      <Flex
        direction={'column'}
        p={2}
        bg={colorCard}
        color={'#5A6059'}
        borderRadius={12}
        justify={'space-around'}
        h={'100%'}
        gap={2}
        py={2}
        px={4}
        {...props}
      >
        <Flex direction={'column'}>
          <Text fontWeight={700} fontSize={'2xl'} noOfLines={1}>
            {title}
          </Text>
          <Text fontSize={'xs'} noOfLines={1}>
            Total de beneficios entregados
          </Text>
        </Flex>
        <Flex direction={'column'} textAlign={'end'}>
          <Text fontWeight={700} fontSize={'4xl'} noOfLines={1}>
            {totalRecords}
          </Text>
          <Text fontWeight={700} fontSize={'md'} noOfLines={1}>
            ${amount}
          </Text>
        </Flex>
      </Flex>
    )}
    {/* --- */}
    {typeCard === 'count' && (
      <Flex
        direction={'column'}
        p={2}
        bg={'#F5F5F5'}
        borderRadius={12}
        gap={2}
        py={2}
        px={4}
        {...props}
      >
        <Flex direction={'column'} color={'#5A6059'}>
          <Text fontWeight={700} fontSize={'xl'}>
            {title}
          </Text>
        </Flex>
        <Flex direction={'column'} textAlign={'end'}>
          <Text fontWeight={700} fontSize={'4xl'} color={'#82B378'}>
            {count}
          </Text>
        </Flex>
      </Flex>
    )}
  </>
)

export default CardData
