import React from 'react'
import { FormControl, FormLabel, Textarea } from '@chakra-ui/react'

const TextAreaComtrol = ({
  isRequired = true,
  id,
  label,
  placeholder,
  value,
  readOnly = false,
  disabled = false,
  onChange,
  children,
  error = false,
  errorText = ''
}) => {
  const getPlaceholder = () => {
    if (errorText) return errorText
    if (placeholder) return placeholder
    if (label) return label

    return ''
  }

  return (
    <FormControl isRequired={isRequired} mb={3}>
      <FormLabel htmlFor={id}>{label}</FormLabel>
      {!children ? (
        <Textarea
          id={id}
          name={id}
          placeholder={getPlaceholder()}
          _placeholder={errorText && { color: '#C53030' }}
          focusBorderColor={'green.100'}
          size={'lg'}
          resize={'none'}
          value={value || ''}
          onChange={onChange}
          isInvalid={error}
          readOnly={readOnly}
          disabled={disabled}
        />
      ) : (
        children
      )}
    </FormControl>
  )
}

export default TextAreaComtrol
