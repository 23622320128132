import React from 'react'
import { Grid, GridItem, Checkbox, Flex, Text } from '@chakra-ui/react'
import { InputComtrol } from '../../../UI'

const RenderCheckProcessRequired = ({ formik, stepKey }) => (
  <Flex direction={'column'} gap={2} my={2}>
    {formik.values.isRequiredCheckProcessActive && (
      <>
        <InputComtrol
          id={'isRequiredCheckProcessActive'}
          label={
            stepKey === 'ALL-INVOICES-AND-TRADES-SOLIDARITY-HEALTH-FUND'
              ? 'Seleccione ayude otorgada'
              : 'Seleccione el proceso'
          }
          isRequired={formik.values.isRequiredCheckProcessActive}
          error={Boolean(formik.errors.someSelectedProcess)}
          errorText={formik.errors.someSelectedProcess}
        >
          <Grid
            templateColumns={{
              lg: 'repeat(2, 1fr)',
              md: 'repeat(1, 1fr)',
              sm: 'repeat(1, 1fr)'
            }}
            gap={2}
          >
            {stepKey === 'ALL-INVOICES-AND-TRADES-SOLIDARITY-HEALTH-FUND' && (
              <>
                <GridItem>
                  <Checkbox
                    css={{ borderColor: 'green' }}
                    id={'medicines'}
                    size="md"
                    colorScheme="green"
                    value={formik.values.medicines}
                    isChecked={formik.values.medicines}
                    onChange={formik.handleChange}
                  >
                    Medicamentos
                  </Checkbox>
                </GridItem>
                <GridItem>
                  <Checkbox
                    css={{ borderColor: 'green' }}
                    id={'supplies'}
                    size="md"
                    colorScheme="green"
                    value={formik.values.supplies}
                    isChecked={formik.values.supplies}
                    onChange={formik.handleChange}
                  >
                    Insumos
                  </Checkbox>
                </GridItem>
                <GridItem>
                  <Checkbox
                    css={{ borderColor: 'green' }}
                    id={'milkFormula'}
                    size="md"
                    colorScheme="green"
                    value={formik.values.milkFormula}
                    isChecked={formik.values.milkFormula}
                    onChange={formik.handleChange}
                  >
                    Formula lactea
                  </Checkbox>
                </GridItem>
                <GridItem>
                  <Checkbox
                    css={{ borderColor: 'green' }}
                    id={'tariffDifference'}
                    size="md"
                    colorScheme="green"
                    value={formik.values.tariffDifference}
                    isChecked={formik.values.tariffDifference}
                    onChange={formik.handleChange}
                  >
                    Diferencia de arancel
                  </Checkbox>
                </GridItem>

                <GridItem>
                  <Checkbox
                    css={{ borderColor: 'green' }}
                    id={'assistanceBenefits'}
                    size="md"
                    colorScheme="green"
                    value={formik.values.assistanceBenefits}
                    isChecked={formik.values.assistanceBenefits}
                    onChange={formik.handleChange}
                  >
                    Prestaciones asistenciales
                  </Checkbox>
                </GridItem>
              </>
            )}
            {(stepKey === 'COMMISSION-PREPARATION-SOLIDARITY-HEALTH-FUND' ||
              stepKey === 'ALL-INVOICES-AND-TRADES-SOLIDARITY-HEALTH-FUND') && (
              <GridItem>
                <Checkbox
                  css={{ borderColor: 'green' }}
                  id={'others'}
                  size="md"
                  colorScheme="green"
                  value={formik.values.others}
                  isChecked={formik.values.others}
                  onChange={formik.handleChange}
                >
                  Otros
                </Checkbox>
              </GridItem>
            )}
          </Grid>
        </InputComtrol>
        <Text textAlign={'center'} color={'red.500'}>
          {formik.errors.someSelectedProcess}
        </Text>
      </>
    )}
  </Flex>
)

export default RenderCheckProcessRequired
