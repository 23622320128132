import { createIcon } from '@chakra-ui/react'
/* https://freeicons.io/ui-essentials-3/ui-essentials-notification-alarm-bell-icon-44563 */
/* eslint-disable import/prefer-default-export */
const CopyElementIcon = createIcon({
  displayName: 'CopyElementIcon',
  viewBox: '0 0 20 23',

  path: [
    <path
      d="M7.99995 17.6492C7.26662 17.6492 6.64162 17.3909 6.12495 16.8742C5.60828 16.3576 5.34995 15.7326 5.34995 14.9992V3.34922C5.34995 2.61589 5.60828 1.99089 6.12495 1.47422C6.64162 0.957552 7.26662 0.699219 7.99995 0.699219H16.65C17.3833 0.699219 18.0083 0.957552 18.525 1.47422C19.0416 1.99089 19.2999 2.61589 19.2999 3.34922V14.9992C19.2999 15.7326 19.0416 16.3576 18.525 16.8742C18.0083 17.3909 17.3833 17.6492 16.65 17.6492H7.99995ZM7.99995 14.9992H16.65V3.34922H7.99995V14.9992ZM3.34995 22.2992C2.61662 22.2992 1.99162 22.0409 1.47495 21.5242C0.958284 21.0076 0.699951 20.3826 0.699951 19.6492V7.14922C0.699951 6.78255 0.829285 6.46989 1.08795 6.21122C1.34595 5.95322 1.65828 5.82422 2.02495 5.82422C2.39162 5.82422 2.70428 5.95322 2.96295 6.21122C3.22095 6.46989 3.34995 6.78255 3.34995 7.14922V19.6492H12.85C13.2166 19.6492 13.5293 19.7786 13.788 20.0372C14.046 20.2952 14.175 20.6076 14.175 20.9742C14.175 21.3409 14.046 21.6536 13.788 21.9122C13.5293 22.1702 13.2166 22.2992 12.85 22.2992H3.34995Z"
      fill="#82B378"
    />
  ]
})

export default CopyElementIcon
