const getFileNamesAndValues = (target) => {
  const { files } = target
  const resultNames = Object.keys(files).map((key) => ({
    name: files[key]?.name,
    url: null
  }))

  return { files, resultNames }
}

const getUnionArrayNames = (currentArray, newArray) => {
  const result = [...new Set([...currentArray, ...newArray])]
  return result
}

const getCurrentFilesAndLength = (currentobjectListFiles) => {
  const currentList =
    currentobjectListFiles !== null ? currentobjectListFiles : {}
  const totalFiles = Object.keys(currentList).length || 0

  return { currentList, totalFiles }
}

const getUnionObjectListFiles = (
  currentObjectListFiles,
  newObjectListFiles,
  totalFiles
) => {
  const renamesKey = {}
  Object.values(newObjectListFiles).map((value, index) => {
    const exists = Object.values(currentObjectListFiles).find(
      (v) => v.name === value.name
    )

    if (!exists) {
      renamesKey[totalFiles + index + 1] = value
    }
    return null
  })
  const finalObjectListFiles = Object.assign(currentObjectListFiles, renamesKey)
  return finalObjectListFiles
}

const getListNamesAndListFiles = (
  target,
  currentListNames,
  currentListFiles
) => {
  const { files, resultNames } = getFileNamesAndValues(target)

  const newListNames = getUnionArrayNames(currentListNames, resultNames)

  const { currentList, totalFiles } = getCurrentFilesAndLength(currentListFiles)

  const newListFiles = getUnionObjectListFiles(currentList, files, totalFiles)
  return { newListNames, newListFiles }
}

const getArrayNamesAndArrayFiles = (
  fileName,
  currentListNames,
  currentListFiles
) => {
  const resultNames = currentListNames.filter((item) => item.name !== fileName)

  let resultListFiles = {}
  if (currentListFiles) {
    Object.values(currentListFiles).map((value, index) => {
      if (value.name !== fileName) {
        resultListFiles[index] = value
      }
      return null
    })
  }
  resultListFiles =
    Object.keys(resultListFiles).length <= 0 ? null : resultListFiles
  return { resultNames, resultListFiles }
}

export default {
  getFileNamesAndValues,
  getUnionArrayNames,
  getCurrentFilesAndLength,
  getUnionObjectListFiles,
  getListNamesAndListFiles,
  getArrayNamesAndArrayFiles
}
