import React from 'react'
import { Flex, Text, Box } from '@chakra-ui/react'

const Heading = ({ title, sub, children }) => (
  <Flex
    w={'100%'}
    justify={'space-between'}
    align={{ lg: 'center', md: 'start', sm: 'start' }}
    direction={{ lg: 'row', md: 'column', sm: 'column' }}
    px={18}
    mb={4}
  >
    <Flex>
      <Box>
        <Text
          color={'green.500'}
          fontWeight={'700'}
          fontSize={'4xl'}
          ml={{ lg: 0, md: 2, sm: 2 }}
        >
          {title}
        </Text>
        {sub && (
          <Text
            color={'green.500'}
            fontWeight={'300'}
            fontSize={'1xl'}
            ml={{ lg: 0, md: 2, sm: 2 }}
          >
            {sub}
          </Text>
        )}
      </Box>
    </Flex>
    <Flex
      gap={{ lg: 0, md: 0, sm: 2 }}
      direction={{ lg: 'row', md: 'row', sm: 'column' }}
      spacing={4}
    >
      {children}
    </Flex>
  </Flex>
)

export default Heading
