import { useEffect } from 'react'
import { Checkbox, Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import { AccountType, BanksList, Files, FormatRut } from '../../../../Utils'
import { BarFileName, InputComtrol, Select, UploadFile } from '../../../UI'

const BankData = ({ formik, beneficiary }) => {
  const handleFormatRut = () => {
    if (formik.values.addresseeRut) {
      const formatedRut = FormatRut(formik.values.addresseeRut)
      formik.setFieldValue('addresseeRut', formatedRut)
    }
  }

  const handleChangeAddresseeBank = (value) => {
    if (value === BanksList.at(-1)) {
      formik.setValues({
        ...formik.values,
        addresseeBank: value,
        specificBank: true
      })
      return
    }
    formik.setValues({
      ...formik.values,
      addresseeBank: value,
      specificBank: false
    })
  }

  const handleChangeAddresseeTypeAccount = (value) => {
    if (value === 'Cuenta Rut') {
      formik.setValues({
        ...formik.values,
        addresseeTypeAccount: value,
        isRequiredAccountNumber: false
      })
      return
    }
    if (value === AccountType.at(-1)) {
      formik.setValues({
        ...formik.values,
        addresseeTypeAccount: value,
        specificAccount: true,
        isRequiredAccountNumber: true
      })
      return
    }
    formik.setValues({
      ...formik.values,
      addresseeTypeAccount: value,
      specificAccount: false,
      isRequiredAccountNumber: true
    })
  }

  const deleteFile = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.addresseeAccountBackupName,
      formik.values.addresseeAccountBackup
    )

    formik.setValues({
      ...formik.values,
      addresseeAccountBackupName: resultNames,
      addresseeAccountBackup: resultListFiles
    })
  }

  const handleChangeAddresseeAccountBackup = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.addresseeAccountBackupName,
      formik.values.addresseeAccountBackup
    )

    formik.setValues({
      ...formik.values,
      addresseeAccountBackupName: newListNames,
      addresseeAccountBackup: newListFiles
    })
    e.target.value = null
  }

  useEffect(() => {
    if (formik.values.usePreviouslyLoadedData) {
      formik.setValues({
        ...formik.values,
        addresseeName: formik.values?.fullName,
        addresseeRut: formik.values?.rut,
        addresseeEmail: formik.values?.email,
        addresseePhone: formik.values?.phone
      })
      return
    }
    formik.setValues({
      ...formik.values,
      addresseeName: '',
      addresseeRut: '',
      addresseeEmail: '',
      addresseePhone: ''
    })
  }, [formik.values.usePreviouslyLoadedData])

  useEffect(() => {
    formik.setFieldValue('usePreviouslyLoadedData', false)
  }, [
    formik.values?.fullName,
    formik.values?.rut,
    formik.values?.email,
    formik.values?.phone
  ])

  return (
    <>
      <Grid templateColumns={'repeat(1, 1fr)'} gap={1}>
        <GridItem>
          <Text fontWeight={700} color={'green.500'} fontSize={'xl'} mb={2}>
            Datos de Transferencia
          </Text>
        </GridItem>
        <GridItem>
          <Checkbox
            css={{ borderColor: 'green' }}
            id={'usePreviouslyLoadedData'}
            size="md"
            colorScheme="green"
            isChecked={formik.values.usePreviouslyLoadedData}
            value={formik.values.usePreviouslyLoadedData}
            onChange={formik.handleChange}
            py={4}
            fontWeight={700}
          >
            ¿Usar datos cargados previamente?
          </Checkbox>
        </GridItem>
      </Grid>
      {/* --- */}
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          <InputComtrol
            id={'addresseeName'}
            label={'Nombre completo del destinatario'}
            value={formik.values.addresseeName}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.addresseeName)}
            errorText={formik.errors.addresseeName}
          />
          <InputComtrol
            id={'addresseeEmail'}
            label={'Email del destinatario'}
            value={formik.values.addresseeEmail}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.addresseeEmail)}
            errorText={formik.errors.addresseeEmail}
            isRequired={false}
          />
        </GridItem>
        <GridItem>
          <InputComtrol
            id={`addresseeRut${beneficiary.ID_CARD}`}
            name={`addresseeRut`}
            label={'Rut del destinatario'}
            value={formik.values.addresseeRut}
            onChange={formik.handleChange}
            onBlur={handleFormatRut}
            error={Boolean(formik.errors.addresseeRut)}
            errorText={formik.errors.addresseeRut}
          />
          <InputComtrol
            id={'addresseePhone'}
            label={'Teléfono del destinatario'}
            type={'number'}
            addictionType={'phone'}
            value={formik.values.addresseePhone}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.addresseePhone)}
            errorText={formik.errors.addresseePhone}
          />
        </GridItem>
      </Grid>
      {formik.values.isRequiredAccountNumber && (
        <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
          <GridItem>
            <InputComtrol
              id={'addresseeAccountNumber'}
              label={'Número de cuenta del destinatario'}
              type={'number'}
              value={formik.values.addresseeAccountNumber}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.addresseeAccountNumber)}
              errorText={formik.errors.addresseeAccountNumber}
            />
          </GridItem>
        </Grid>
      )}
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          <InputComtrol id={'addresseeBank'} label={'Banco del destinatario'}>
            <Select
              bg={'white'}
              list={BanksList}
              value={formik.values.addresseeBank}
              onChange={handleChangeAddresseeBank}
            />
          </InputComtrol>
          {formik.values.specificBank && (
            <InputComtrol
              id={'addresseeBankOther'}
              label={'Especificar banco'}
              type={'text'}
              value={formik.values.addresseeBankOther}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.addresseeBankOther)}
              errorText={formik.errors.addresseeBankOther}
            />
          )}
        </GridItem>
        <GridItem>
          <InputComtrol id={'addresseeTypeAccount'} label={'Tipo de Cuenta'}>
            <Select
              bg={'white'}
              list={AccountType}
              value={formik.values.addresseeTypeAccount}
              onChange={handleChangeAddresseeTypeAccount}
            />
          </InputComtrol>
          {formik.values.specificAccount && (
            <InputComtrol
              id={'addresseeTypeAccountOther'}
              label={'Especificar tipo de cuenta'}
              type={'text'}
              value={formik.values.addresseeTypeAccountOther}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.addresseeTypeAccountOther)}
              errorText={formik.errors.addresseeTypeAccountOther}
            />
          )}
        </GridItem>
      </Grid>
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
        <GridItem>
          <Text fontSize={'md'} color={'green.500'}>
            Respaldo de cuenta del destinatario (opcional)
          </Text>
          <UploadFile
            id={`addresseeAccountBackup${beneficiary.ID_CARD}`}
            direction={'row'}
            sizeIcon={30}
            p={5}
            onChange={(e) => handleChangeAddresseeAccountBackup(e)}
            error={Boolean(formik.errors.addresseeAccountBackupName)}
            errorText={formik.errors.addresseeAccountBackupName}
          />
          <Flex direction={'column'} gap={1}>
            {formik.values.addresseeAccountBackupName.map((item) => (
              <BarFileName
                key={item?.name}
                fileName={item?.name}
                fileUrl={item?.url}
                onDelete={() => deleteFile(item?.name)}
              />
            ))}
          </Flex>
        </GridItem>
      </Grid>
    </>
  )
}

export default BankData
