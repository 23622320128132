import { Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import { addDays } from 'date-fns'
import moment from 'moment'
import React from 'react'
import { Files, FormatDate } from '../../../../Utils'
import {
  BarFileName,
  CustomDatePicker,
  InputComtrol,
  TextAreaComtrol,
  UploadFile
} from '../../../UI'
import { useTypeModal } from '../../../../hooks/TypeModal'
import RenderPreviewPastStep from '../OtherProcesses/RenderPreviewPastStep'

const ExtensionForm = ({ formik, program, currentStep }) => {
  const { keyToSearch } = useTypeModal(currentStep?.key)

  const handleChangeNewExitDate = (date) => {
    if (date) {
      const currentExitDate = moment(
        program?.isRequiredExtension
          ? program?.extensionDate
          : program?.exitDate
      )
      const newExit = moment(date)
      const diffDays = newExit.diff(currentExitDate, 'days')

      formik.setValues({
        ...formik.values,
        extensionDays: diffDays.toString(),
        newExitDate: date
      })
    }
  }

  const deleteFile = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.documentsNames,
      formik.values.documents
    )

    formik.setValues({
      ...formik.values,
      documentsNames: resultNames,
      documents: resultListFiles
    })
  }

  const handleChangeDocuments = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.documentsNames,
      formik.values.documents
    )

    formik.setValues({
      ...formik.values,
      documentsNames: newListNames,
      documents: newListFiles
    })
    e.target.value = null
  }

  return (
    <>
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
      >
        <GridItem
          ml={'auto'}
          mr={'auto'}
          border={'2px dotted #82B378'}
          borderRadius={8}
          p={4}
          mb={2}
        >
          <Flex justify={'center'} align={'center'} direction={'column'}>
            <Text>Fecha inicial de Entrada:</Text>
            <Text>{FormatDate(program.incomeDate)}</Text>
          </Flex>
        </GridItem>
        <GridItem
          ml={'auto'}
          mr={'auto'}
          border={'2px dotted #82B378'}
          borderRadius={8}
          p={4}
          mb={2}
        >
          <Flex justify={'center'} align={'center'} direction={'column'}>
            <Text>Fecha Inicial de Salida:</Text>
            <Text>{FormatDate(program.exitDate)}</Text>
          </Flex>
        </GridItem>
      </Grid>
      {program.isRequiredExtension && (
        <Grid templateColumns={'repeat(1, 1fr)'}>
          <GridItem
            ml={'auto'}
            mr={'auto'}
            border={'2px dotted #82B378'}
            borderRadius={8}
            p={4}
            mb={2}
          >
            <Flex justify={'center'} align={'center'} direction={'column'}>
              <Text>Última Fecha Actualizada de Salida:</Text>
              <Text>{FormatDate(program.extensionDate)}</Text>
            </Flex>
          </GridItem>
        </Grid>
      )}
      {keyToSearch && (
        <Grid templateColumns={'repeat(1, 1fr)'}>
          <GridItem>
            <RenderPreviewPastStep
              program={program}
              keyToSearch={keyToSearch}
            />
          </GridItem>
        </Grid>
      )}
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          <InputComtrol id={'newExitDate'} label={'Fecha de salida'}>
            <CustomDatePicker
              placeholder={'DD/MM/YYYY'}
              currentDate={formik.values.newExitDate}
              onChange={(date) => handleChangeNewExitDate(date)}
              error={Boolean(formik.errors.newExitDate)}
              errorText={formik.errors.newExitDate}
              minDate={addDays(
                new Date(
                  program?.isRequiredExtension
                    ? program?.extensionDate
                    : program?.exitDate
                ),
                0
              )}
            />
          </InputComtrol>
        </GridItem>
        <GridItem>
          <InputComtrol
            isRequired={false}
            id={'extensionDays'}
            label={'Días de extensión'}
            type={'number'}
            value={formik.values.extensionDays}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.extensionDays)}
            errorText={formik.errors.extensionDays}
            readOnly={true}
          />
        </GridItem>
      </Grid>
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2} mb={4}>
        <GridItem>
          <TextAreaComtrol
            id={'descriptions'}
            isRequired={formik.values.isRequiredDescription}
            label={
              formik.values.isRequiredDescription
                ? 'Argumente el motivo'
                : 'Argumente el motivo (Opcional)'
            }
            value={formik.values.descriptions}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.descriptions)}
            errorText={formik.errors.descriptions}
          />
        </GridItem>
        <GridItem>
          <InputComtrol
            id={'documentsNames'}
            label={
              formik.values.isRequiredDocuments
                ? 'Adjuntar documento'
                : 'Adjuntar documento (Opcional)'
            }
            isRequired={formik.values.isRequiredDocuments}
          >
            <UploadFile
              id={'documentsNames'}
              direction={'row'}
              sizeIcon={30}
              p={5}
              multiple={true}
              onChange={(e) => handleChangeDocuments(e)}
              error={Boolean(formik.errors.documentsNames)}
              errorText={formik.errors.documentsNames}
            />
          </InputComtrol>
          <Flex direction={'column'} gap={1}>
            {formik.values.documentsNames.map((item) => (
              <BarFileName
                key={item?.name}
                fileName={item?.name}
                fileUrl={item?.url}
                onDelete={() => deleteFile(item?.name)}
              />
            ))}
          </Flex>
        </GridItem>
      </Grid>
    </>
  )
}

export default ExtensionForm
