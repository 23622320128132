import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { Grid, GridItem, Text, Flex, Checkbox } from '@chakra-ui/react'
import { InputComtrol, InfoCard, UploadFile, BarFileName } from '../../UI'
import { stepFourSchema } from './Schemas'
import { ClearFormatMoney, Files, FormatMoney } from '../../../Utils'

const StepFour = ({ refStepFour, stepFourForm, setStepFourForm, next }) => {
  const [totalFamily, setTotalFamily] = React.useState(0)
  const [totalExpenses, setTotalExpenses] = React.useState(0)
  const initialValues = {
    salatyPartner: '',
    isSalatySpouse: false,
    salarySpouse: '',
    otherSalaty: '0',
    totalExpensesGroup: '',
    salarySettlementsNames: [],
    salarySettlements: null
  }

  const formik = useFormik({
    initialValues,
    validationSchema: stepFourSchema,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: (values) => {
      setStepFourForm({
        ...values
      })
      next()
    }
  })

  const deleteFile = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.salarySettlementsNames,
      formik.values.salarySettlements
    )

    formik.setValues({
      ...formik.values,
      salarySettlementsNames: resultNames,
      salarySettlements: resultListFiles
    })
  }

  const handleChangeSalarySettlements = (e) => {
    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.salarySettlementsNames,
      formik.values.salarySettlements
    )
    formik.setValues({
      ...formik.values,
      salarySettlementsNames: newListNames,
      salarySettlements: newListFiles
    })
    e.target.value = null
  }

  const handleChangeIsSalarySpouse = (e) => {
    const isChecked = e.target.checked
    formik.setValues({
      ...formik.values,
      isSalatySpouse: isChecked,
      salarySpouse: isChecked ? formik.values.salarySpouse : ''
    })
  }

  const formatSalatyPartner = () => {
    const format = FormatMoney(ClearFormatMoney(formik.values.salatyPartner))
    formik.setFieldValue('salatyPartner', format)
  }

  const formatSalarySpouse = () => {
    const format = FormatMoney(ClearFormatMoney(formik.values.salarySpouse))
    formik.setFieldValue('salarySpouse', format)
  }

  const formatOtherSalaty = () => {
    const format = FormatMoney(ClearFormatMoney(formik.values.otherSalaty))
    formik.setFieldValue('otherSalaty', format)
  }

  const formatTotalExpensesGroup = () => {
    const format = FormatMoney(
      ClearFormatMoney(formik.values.totalExpensesGroup)
    )
    formik.setFieldValue('totalExpensesGroup', format)
  }

  useEffect(() => {
    let total = 0
    Object.keys(formik.values).forEach((key) => {
      if (
        key === 'salatyPartner' ||
        key === 'salarySpouse' ||
        key === 'otherSalaty'
      ) {
        if (formik.values[key] !== '') {
          total += parseFloat(ClearFormatMoney(formik.values[key]))
        }
      }
    })
    setTotalFamily(FormatMoney(total))
  }, [
    formik.values.salatyPartner,
    formik.values.salarySpouse,
    formik.values.otherSalaty
  ])

  useEffect(() => {
    if (formik.values.totalExpensesGroup !== '') {
      let total = 0
      if (ClearFormatMoney(totalFamily) > 0) {
        total =
          ClearFormatMoney(totalFamily) -
          parseFloat(ClearFormatMoney(formik.values.totalExpensesGroup))
      }
      if (
        ClearFormatMoney(totalFamily) <
        ClearFormatMoney(formik.values.totalExpensesGroup)
      ) {
        total = 0
      }
      setTotalExpenses(FormatMoney(total))
    } else {
      setTotalExpenses(0)
    }
  }, [totalFamily, totalExpenses, formik.values.totalExpensesGroup])

  useEffect(() => {
    refStepFour.current = formik.handleSubmit
  }, [])

  useEffect(() => {
    formik.setValues({
      salatyPartner: stepFourForm?.salatyPartner || '',
      isSalatySpouse: Boolean(stepFourForm?.salarySpouse),
      salarySpouse: stepFourForm?.salarySpouse || '',
      otherSalaty: stepFourForm?.otherSalaty || '0',
      totalExpensesGroup: stepFourForm?.totalExpensesGroup || '',
      salarySettlementsNames: stepFourForm?.salarySettlementsNames || [],
      salarySettlements: stepFourForm?.salarySettlements || null
    })
  }, [stepFourForm])

  return (
    <Grid
      templateColumns={{
        lg: 'repeat(2, 70% 30%)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem>
        <Text fontWeight={700} color={'green.500'} fontSize={'xl'}>
          Antecedentes económicos del grupo familiar
        </Text>
        <InputComtrol
          id={'salatyPartner'}
          label={'Alcance líquido del funcionario'}
          placeholder={'$'}
          type={'currency'}
          value={formik.values.salatyPartner}
          onChange={formik.handleChange}
          onBlur={formatSalatyPartner}
          error={Boolean(formik.errors.salatyPartner)}
          errorText={formik.errors.salatyPartner}
        />
        <Flex
          direction={'column'}
          bg={'beige.50'}
          borderRadius={8}
          gap={2}
          p={1}
        >
          <Checkbox
            css={{ borderColor: 'green' }}
            colorScheme="green"
            color={'green.500'}
            borderColor={'green.500'}
            size={'md'}
            fontWeight={700}
            isChecked={formik.values.isSalatySpouse}
            onChange={handleChangeIsSalarySpouse}
          >
            ¿El cónyuge Presenta ingresos?
          </Checkbox>
          {formik.values.isSalatySpouse && (
            <InputComtrol
              id={'salarySpouse'}
              label={'Ingresos de cónyuge'}
              placeholder={'$'}
              type={'currency'}
              value={formik.values.salarySpouse}
              onChange={formik.handleChange}
              onBlur={formatSalarySpouse}
              error={Boolean(formik.errors.salarySpouse)}
              errorText={formik.errors.salarySpouse}
            />
          )}
        </Flex>
        <InputComtrol
          id={'otherSalaty'}
          label={'Otros ingresos (arriendos, pensiones, otros)'}
          placeholder={'$'}
          type={'currency'}
          value={formik.values.otherSalaty}
          onChange={formik.handleChange}
          onBlur={formatOtherSalaty}
          error={Boolean(formik.errors.otherSalaty)}
          errorText={formik.errors.otherSalaty}
        />
        <InputComtrol
          id={'totalExpensesGroup'}
          label={
            <Flex direction={'column'}>
              <strong>Total egresos mensuales del grupo familiar.</strong>
              Gastos básicos y de manutención (alimentación, cuentas básicas,
              educación, salud, vivienda, otros)
            </Flex>
          }
          placeholder={'$'}
          type={'currency'}
          value={formik.values.totalExpensesGroup}
          onChange={formik.handleChange}
          onBlur={formatTotalExpensesGroup}
          error={Boolean(formik.errors.totalExpensesGroup)}
          errorText={formik.errors.totalExpensesGroup}
        />
        <Flex direction={'column'} gap={1}>
          <Text fontWeight={700} fontSize={'lg'} color={'green.500'}>
            Liquidaciones de sueldo de los padres
          </Text>
          <Text fontSize={'md'} color={'green.500'}>
            Adjunta 3 últimas liquidaciones de sueldo de los padres si ambos
            trabajan.
          </Text>
          <UploadFile
            direction={'row'}
            sizeIcon={30}
            p={5}
            multiple={true}
            onChange={(e) => handleChangeSalarySettlements(e)}
            error={Boolean(formik.errors.salarySettlementsNames)}
            errorText={formik.errors.salarySettlementsNames}
          />
          {formik.values.salarySettlementsNames.map((item) => (
            <BarFileName
              key={item?.name}
              fileName={item?.name}
              fileUrl={item?.url}
              onDelete={() => deleteFile(item?.name)}
            />
          ))}
        </Flex>
      </GridItem>

      <GridItem>
        <Text fontSize={'xl'} opacity={0}>
          -----
        </Text>
        <InfoCard px={10} py={10}>
          <Text fontWeight={700}>Total ingresos familiares</Text>
          <Text fontWeight={700} fontSize={'2xl'}>
            $ {totalFamily}
          </Text>
        </InfoCard>
        <InfoCard px={5} py={10} my={2}>
          <Text fontWeight={700}>Remanente mensual</Text>
          <Text fontWeight={700} fontSize={'2xl'}>
            $ {totalExpenses}
          </Text>
          <Text fontWeight={700} fontSize={'sm'}>
            Saldo a favor una vez pagadas todas sus responsabilidades y
            compromisos económicos y/o familiares
          </Text>
        </InfoCard>
      </GridItem>
    </Grid>
  )
}

export default StepFour
