import React from 'react'
import { Flex } from '@chakra-ui/react'
import CardFormNotification from './CardFormNotification'

const statesToEdit = ['INREVIEW', 'APPROVED', 'REJECTED']

const NotificationsForms = ({ programKey }) => (
  <>
    <Flex direction={'column'} gap={2}>
      {statesToEdit.map((state) => (
        <CardFormNotification
          key={state}
          type={state}
          programKey={programKey}
        />
      ))}
    </Flex>
  </>
)

export default NotificationsForms
