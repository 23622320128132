import React, { StrictMode } from 'react'
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as serviceWorker from './serviceWorker'
import { GetNotification } from './Utils'
import './App.css'

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: async (err) => {
      const code = await err.request.status
      if (code === 401 && window.location.pathname !== '/login') {
        /* aca puede ir el refresh token */
        window.localStorage.clear()
        GetNotification.warning(
          'Aviso',
          'OK',
          () => {
            window.location.href = '/'
          },
          'Su sesión ha expirado, por favor ingrese nuevamente'
        )
      }
    }
  })
})

ReactDOM.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
      <ReactQueryDevtools />
    </QueryClientProvider>
  </StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
