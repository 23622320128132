import React from 'react'
import { Grid, GridItem, Text } from '@chakra-ui/react'
import { CardFilePreview, InputComtrol } from '../../UI'

const MonitorBankDataInformation = ({ program }) => (
  <>
    <Grid
      templateColumns={{
        lg: 'repeat(2, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem>
        <InputComtrol
          label={'Nombre del monitor'}
          value={program?.bankDataMonitor?.name}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Teléfono del monitor'}
          addictionType={'phone'}
          value={program?.bankDataMonitor?.phone}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
      <GridItem>
        <InputComtrol
          label={'Rut del monitor'}
          value={program?.bankDataMonitor?.rut}
          addictionType={'copy'}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Correo electrónico del monitor'}
          value={program?.bankDataMonitor?.email}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
    </Grid>
    {/* --- */}
    <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
      <GridItem>
        <InputComtrol
          label={'Número de cuenta del monitor'}
          value={program?.bankDataMonitor?.accountNumber || 'No Aplica'}
          addictionType={
            program?.bankDataMonitor?.accountNumber ? 'copy' : 'none'
          }
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
    </Grid>
    {/* --- */}
    <Grid
      templateColumns={{
        lg: 'repeat(2, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem>
        <InputComtrol
          label={'Banco del monitor'}
          value={
            program?.bankDataMonitor?.bank !== 'Otro'
              ? program?.bankDataMonitor?.bank
              : program?.bankDataMonitor?.bankOther
          }
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
      <GridItem>
        <InputComtrol
          label={'Tipo de cuenta del monitor'}
          value={
            program?.bankDataMonitor?.typeAccount !== 'Otro'
              ? program?.bankDataMonitor?.typeAccount
              : program?.bankDataMonitor?.typeAccountOther
          }
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
    </Grid>
    <Text fontWeight={700} fontSize={'xl'}>
      Respaldo de la cuenta del monitor
    </Text>
    {program?.bankDataMonitor?.accountBackup.length > 0 && (
      <>
        <Grid templateColumns={'repeat(1, 1fr)'} gap={1}>
          {program?.bankDataMonitor?.accountBackup?.map((item) => (
            <GridItem py={3}>
              <CardFilePreview
                key={item.url}
                name={item?.name}
                url={item?.url}
              />
            </GridItem>
          ))}
        </Grid>
      </>
    )}
  </>
)

export default MonitorBankDataInformation
