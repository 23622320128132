/* eslint-disable import/prefer-default-export */
import axios from '../Axios'

const module = '/additional-information'

export const getOneAdditional = async (programId, key, step) => {
  const { data } = await axios.get(
    `${module}/get-one?programId=${programId}&key=${key}&step=${step}`
  )
  return data
}
