import React from 'react'
import { Grid, GridItem } from '@chakra-ui/react'
import { InputComtrol } from '../../UI'
import { FormatDate } from '../../../Utils'

const ApplicationInformation = ({ program }) => (
  <>
    <Grid
      templateColumns={{
        lg: 'repeat(2, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem>
        <InputComtrol
          label={'Fecha de registro del programa'}
          value={FormatDate(program?.createdAt)}
          isRequired={false}
          readOnly={true}
        />

        <InputComtrol
          label={'Región'}
          value={program?.region}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Dirección'}
          value={program?.address}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Estado civil'}
          value={program?.civilStatus}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Número de integrantes del grupo familiar'}
          value={program?.membersFamily}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>

      <GridItem>
        <InputComtrol
          label={'Fecha de recepción de solicitud'}
          value={FormatDate(program?.receptionDate)}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Comuna'}
          value={program?.commune}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Condición de residencia'}
          value={program?.residenceCondition}
          isRequired={false}
          readOnly={true}
        />

        <InputComtrol
          label={'Número de cargas familiares'}
          value={program?.loadsFamily}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
    </Grid>
    <Grid
      templateColumns={{
        lg: 'repeat(2, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem>
        <InputComtrol
          label={'Zona de postulación'}
          value={program?.zone}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
      <GridItem>
        <InputComtrol
          label={'Alta repartición / Repartición'}
          value={program?.prefecture}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
    </Grid>
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem>
        <InputComtrol
          label={'Unidad'}
          value={program?.unit}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
    </Grid>
  </>
)

export default ApplicationInformation
