import { createIcon } from '@chakra-ui/react'
/* eslint-disable import/prefer-default-export */
const LeftArrowIcon = createIcon({
  displayName: 'LeftArrowIcon',
  viewBox: '0 0 20 20',
  path: [
    <path
      d="M16.3544 8.38465H6.70777L9.38354 5.75808C9.69226 5.45519 9.86575 5.04432 9.86585 4.61587C9.86595 4.18742 9.69266 3.77647 9.38409 3.47343C9.07552 3.1704 8.65696 3.0001 8.22048 3C7.784 2.9999 7.36535 3.17001 7.05664 3.4729L3.12252 7.33467C2.40367 8.04223 2 9.00073 2 10C2 10.9993 2.40367 11.9578 3.12252 12.6653L7.05664 16.5271C7.36535 16.83 7.784 17.0001 8.22048 17C8.65696 16.9999 9.07552 16.8296 9.38409 16.5266C9.69266 16.2235 9.86595 15.8126 9.86585 15.3841C9.86575 14.9557 9.69226 14.5448 9.38354 14.2419L6.70777 11.6154H16.3544C16.7908 11.6154 17.2094 11.4452 17.518 11.1422C17.8266 10.8393 18 10.4284 18 10C18 9.57158 17.8266 9.16071 17.518 8.85777C17.2094 8.55483 16.7908 8.38465 16.3544 8.38465Z"
      fill="#2E7423"
      stroke="#2E7423"
    />
  ]
})

export default LeftArrowIcon
