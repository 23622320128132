import { createIcon } from '@chakra-ui/react'
/* eslint-disable import/prefer-default-export */
const ReplayIcon = createIcon({
  displayName: 'ReplayIcon',
  viewBox: '0 0 20 20',
  path: [
    <path
      d="M10 16.5C11.6667 16.5 13.0833 15.9167 14.25 14.75C15.4167 13.5833 16 12.1667 16 10.5C16 8.81667 15.396 7.396 14.188 6.238C12.9793 5.07933 11.5167 4.5 9.8 4.5L10.7 3.6C10.8833 3.41667 10.975 3.18333 10.975 2.9C10.975 2.61667 10.8833 2.38333 10.7 2.2C10.5167 2.01667 10.2833 1.925 10 1.925C9.71667 1.925 9.48333 2.01667 9.3 2.2L6.7 4.8C6.6 4.9 6.52933 5.00833 6.488 5.125C6.446 5.24167 6.425 5.36667 6.425 5.5C6.425 5.63333 6.446 5.75833 6.488 5.875C6.52933 5.99167 6.6 6.1 6.7 6.2L9.3 8.8C9.48333 8.98333 9.71267 9.079 9.988 9.087C10.2627 9.09567 10.5 9 10.7 8.8C10.8833 8.61667 10.9793 8.38733 10.988 8.112C10.996 7.83733 10.9083 7.6 10.725 7.4L9.85 6.5C11 6.5 11.9793 6.88733 12.788 7.662C13.596 8.43733 14 9.38333 14 10.5C14 11.6 13.6083 12.5417 12.825 13.325C12.0417 14.1083 11.1 14.5 10 14.5C9.05 14.5 8.21267 14.2043 7.488 13.613C6.76267 13.021 6.3 12.275 6.1 11.375C6.03333 11.125 5.90833 10.9167 5.725 10.75C5.54167 10.5833 5.33333 10.5 5.1 10.5C4.78333 10.5 4.525 10.6123 4.325 10.837C4.125 11.0623 4.05 11.3167 4.1 11.6C4.35 13.0167 5.025 14.1877 6.125 15.113C7.225 16.0377 8.51667 16.5 10 16.5ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6873 3.825 17.975 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.262667 12.6833 0 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31267 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.31233 6.1 0.787C7.31667 0.262333 8.61667 0 10 0C11.3833 0 12.6833 0.262333 13.9 0.787C15.1167 1.31233 16.175 2.025 17.075 2.925C17.975 3.825 18.6873 4.88333 19.212 6.1C19.7373 7.31667 20 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6873 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6873 13.9 19.212C12.6833 19.7373 11.3833 20 10 20Z"
      fill={'currentColor' || 'white'}
    />
  ]
})

export default ReplayIcon
