import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import { BarFileName, UploadFile, TextAreaComtrol } from '../../UI'
import { steepThreeSchema } from './Schemas'
import { Files } from '../../../Utils'

const StepThree = ({
  stepThreeForm,
  setStepThreeForm,
  refStepThree,
  state
}) => {
  const initialValues = {
    birthCertificateName: [],
    birthCertificate: null,
    scoreCertificateName: [],
    scoreCertificate: null,
    socialReportName: [],
    socialReport: null,
    aditionalDocumentsNames: [],
    aditionalDocuments: null,
    parentsLastSalaryName: [],
    parentsLastSalary: [],
    motivationLetterName: [],
    motivationLetter: null,
    applicationSignedByPartnerName: [],
    applicationSignedByPartner: null,
    curricularMeshName: [],
    curricularMesh: null,
    observations: ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: steepThreeSchema,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: (values) => {
      setStepThreeForm({
        ...values,
        state
      })
    }
  })

  const deleteApplicationSignedByPartner = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.applicationSignedByPartnerName,
      formik.values.applicationSignedByPartner
    )

    formik.setValues({
      ...formik.values,
      applicationSignedByPartnerName: resultNames,
      applicationSignedByPartner: resultListFiles
    })
  }

  const deleteCurricularMesh = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.curricularMeshName,
      formik.values.curricularMesh
    )

    formik.setValues({
      ...formik.values,
      curricularMeshName: resultNames,
      curricularMesh: resultListFiles
    })
  }

  const deleteMotivationLetter = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.motivationLetterName,
      formik.values.motivationLetter
    )

    formik.setValues({
      ...formik.values,
      motivationLetterName: resultNames,
      motivationLetter: resultListFiles
    })
  }

  const deleteParentsLastSalary = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.parentsLastSalaryName,
      formik.values.parentsLastSalary
    )

    formik.setValues({
      ...formik.values,
      parentsLastSalaryName: resultNames,
      parentsLastSalary: resultListFiles
    })
  }

  const deleteBirthCertificate = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.birthCertificateName,
      formik.values.birthCertificate
    )

    formik.setValues({
      ...formik.values,
      birthCertificateName: resultNames,
      birthCertificate: resultListFiles
    })
  }

  const deleteScoreCertificate = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.scoreCertificateName,
      formik.values.scoreCertificate
    )

    formik.setValues({
      ...formik.values,
      scoreCertificateName: resultNames,
      scoreCertificate: resultListFiles
    })
  }

  const deleteSocialReport = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.socialReportName,
      formik.values.socialReport
    )

    formik.setValues({
      ...formik.values,
      socialReportName: resultNames,
      socialReport: resultListFiles
    })
  }

  const deleteAditionalsDocuments = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.aditionalDocumentsNames,
      formik.values.aditionalDocuments
    )

    formik.setValues({
      ...formik.values,
      aditionalDocumentsNames: resultNames,
      aditionalDocuments: resultListFiles
    })
  }

  const handleChangeApplicationSignedByPartner = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.applicationSignedByPartnerName,
      formik.values.applicationSignedByPartner
    )

    formik.setValues({
      ...formik.values,
      applicationSignedByPartnerName: newListNames,
      applicationSignedByPartner: newListFiles
    })
    e.target.value = null
  }

  const handleChangeCurricularMesh = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.curricularMeshName,
      formik.values.curricularMesh
    )

    formik.setValues({
      ...formik.values,
      curricularMeshName: newListNames,
      curricularMesh: newListFiles
    })
    e.target.value = null
  }

  const handleChangeMotivationLetter = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.motivationLetterName,
      formik.values.motivationLetter
    )

    formik.setValues({
      ...formik.values,
      motivationLetterName: newListNames,
      motivationLetter: newListFiles
    })
    e.target.value = null
  }

  const handleChangeParentsLastSalary = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.parentsLastSalaryName,
      formik.values.parentsLastSalary
    )

    formik.setValues({
      ...formik.values,
      parentsLastSalaryName: newListNames,
      parentsLastSalary: newListFiles
    })
    e.target.value = null
  }

  const handleChangeBirthCertificate = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.birthCertificateName,
      formik.values.birthCertificate
    )

    formik.setValues({
      ...formik.values,
      birthCertificateName: newListNames,
      birthCertificate: newListFiles
    })
    e.target.value = null
  }

  const handleChangeScoreCertificate = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.scoreCertificateName,
      formik.values.scoreCertificate
    )

    formik.setValues({
      ...formik.values,
      scoreCertificateName: newListNames,
      scoreCertificate: newListFiles
    })
    e.target.value = null
  }

  const handleChangeSocialReport = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.socialReportName,
      formik.values.socialReport
    )

    formik.setValues({
      ...formik.values,
      socialReportName: newListNames,
      socialReport: newListFiles
    })
    e.target.value = null
  }

  const handleChangeAditionalsDocuments = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.aditionalDocumentsNames,
      formik.values.aditionalDocuments
    )

    formik.setValues({
      ...formik.values,
      aditionalDocumentsNames: newListNames,
      aditionalDocuments: newListFiles
    })
    e.target.value = null
  }

  useEffect(() => {
    refStepThree.current = formik.handleSubmit
  }, [])

  useEffect(() => {
    formik.setValues({
      birthCertificateName: stepThreeForm.birthCertificateName || [],
      birthCertificate: stepThreeForm.birthCertificate || null,
      scoreCertificateName: stepThreeForm.scoreCertificateName || [],
      scoreCertificate: stepThreeForm.scoreCertificate || null,
      socialReportName: stepThreeForm.socialReportName || [],
      socialReport: stepThreeForm.socialReport || null,
      parentsLastSalaryName: stepThreeForm.parentsLastSalaryName || [],
      parentsLastSalary: stepThreeForm.parentsLastSalary || null,
      motivationLetterName: stepThreeForm.motivationLetterName || [],
      motivationLetter: stepThreeForm.motivationLetter || null,
      aditionalDocumentsNames: stepThreeForm.aditionalDocumentsNames || [],
      aditionalDocuments: stepThreeForm.aditionalDocuments || null,
      applicationSignedByPartnerName:
        stepThreeForm.applicationSignedByPartnerName || [],
      applicationSignedByPartner:
        stepThreeForm.applicationSignedByPartner || null,
      curricularMeshName: stepThreeForm.curricularMeshName || [],
      curricularMesh: stepThreeForm.curricularMesh || null,
      observations: stepThreeForm.observations || ''
    })
  }, [stepThreeForm])

  return (
    <>
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          <Text fontWeight={700}>Certificado de nacimiento</Text>
          <UploadFile
            direction={'row'}
            sizeIcon={30}
            p={5}
            onChange={(e) => {
              handleChangeBirthCertificate(e)
            }}
            id={'birthCertificateName'}
            error={Boolean(formik.errors.birthCertificateName)}
            errorText={formik.errors.birthCertificateName}
          />
          <Flex direction={'column'} gap={1}>
            {formik.values.birthCertificateName.map((item) => (
              <BarFileName
                key={item?.name}
                fileName={item?.name}
                fileUrl={item?.url}
                onDelete={() => deleteBirthCertificate(item?.name)}
              />
            ))}
          </Flex>
          {/* - */}
          <Text fontWeight={700}>Certificado de notas</Text>
          <UploadFile
            direction={'row'}
            sizeIcon={30}
            p={5}
            onChange={(e) => {
              handleChangeScoreCertificate(e)
            }}
            id={'scoreCertificateName'}
            error={Boolean(formik.errors.scoreCertificateName)}
            errorText={formik.errors.scoreCertificateName}
          />
          <Flex direction={'column'} gap={1}>
            {formik.values.scoreCertificateName.map((item) => (
              <BarFileName
                key={item?.name}
                fileName={item?.name}
                fileUrl={item?.url}
                onDelete={() => deleteScoreCertificate(item?.name)}
              />
            ))}
          </Flex>
          {/* - */}
          <Text fontWeight={700}>Últimas 3 liquidación de sueldo</Text>
          <UploadFile
            direction={'row'}
            sizeIcon={30}
            p={5}
            onChange={(e) => {
              handleChangeParentsLastSalary(e)
            }}
            multiple={true}
            id={'parentsLastSalaryName'}
            error={Boolean(formik.errors.parentsLastSalaryName)}
            errorText={formik.errors.parentsLastSalaryName}
          />
          <Flex direction={'column'} gap={1}>
            {formik.values.parentsLastSalaryName.map((item) => (
              <BarFileName
                key={item?.name}
                fileName={item?.name}
                fileUrl={item?.url}
                onDelete={() => deleteParentsLastSalary(item?.name)}
              />
            ))}
          </Flex>
          {/* - */}
          <Text fontWeight={700}>
            Solicitud de postulación suscrita por el socio
          </Text>
          <UploadFile
            direction={'row'}
            sizeIcon={30}
            p={5}
            onChange={(e) => {
              handleChangeApplicationSignedByPartner(e)
            }}
            id={'applicationSignedByPartnerName'}
            error={Boolean(formik.errors.applicationSignedByPartnerName)}
            errorText={formik.errors.applicationSignedByPartnerName}
          />
          <Flex direction={'column'} gap={1}>
            {formik.values.applicationSignedByPartnerName.map((item) => (
              <BarFileName
                key={item?.name}
                fileName={item?.name}
                fileUrl={item?.url}
                onDelete={() => deleteApplicationSignedByPartner(item?.name)}
              />
            ))}
          </Flex>
        </GridItem>
        <GridItem>
          <Text fontWeight={700}>Formulario Social</Text>
          <UploadFile
            direction={'row'}
            sizeIcon={30}
            p={5}
            onChange={(e) => {
              handleChangeSocialReport(e)
            }}
            id={'socialReportName'}
            error={Boolean(formik.errors.socialReportName)}
            errorText={formik.errors.socialReportName}
          />
          <Flex direction={'column'} gap={1}>
            {formik.values.socialReportName.map((item) => (
              <BarFileName
                key={item?.name}
                fileName={item?.name}
                fileUrl={item?.url}
                onDelete={() => deleteSocialReport(item?.name)}
              />
            ))}
          </Flex>
          {/* - */}
          <Text fontWeight={700}>Documentos adicionales (opcional)</Text>
          <UploadFile
            direction={'row'}
            multiple={true}
            sizeIcon={30}
            p={5}
            onChange={(e) => {
              handleChangeAditionalsDocuments(e)
            }}
            error={Boolean(formik.errors.aditionalDocumentsNames)}
            errorText={formik.errors.aditionalDocumentsNames}
          />
          <Flex direction={'column'} gap={1}>
            {formik.values.aditionalDocumentsNames.map((item) => (
              <BarFileName
                key={item?.name}
                fileName={item?.name}
                fileUrl={item?.url}
                onDelete={() => deleteAditionalsDocuments(item?.name)}
              />
            ))}
          </Flex>
          {/* - */}
          <Text fontWeight={700}>
            Carta de motivación manuscrita (estudiante)
          </Text>
          <UploadFile
            direction={'row'}
            sizeIcon={30}
            p={5}
            onChange={(e) => {
              handleChangeMotivationLetter(e)
            }}
            id={'motivationLetterName'}
            error={Boolean(formik.errors.motivationLetterName)}
            errorText={formik.errors.motivationLetterName}
          />
          <Flex direction={'column'} gap={1}>
            {formik.values.motivationLetterName.map((item) => (
              <BarFileName
                key={item?.name}
                fileName={item?.name}
                fileUrl={item?.url}
                onDelete={() => deleteMotivationLetter(item?.name)}
              />
            ))}
          </Flex>
          {/* --- */}
          <Text fontWeight={700}>Malla curricular</Text>
          <UploadFile
            direction={'row'}
            sizeIcon={30}
            p={5}
            onChange={(e) => {
              handleChangeCurricularMesh(e)
            }}
            id={'curricularMeshName'}
            error={Boolean(formik.errors.curricularMeshName)}
            errorText={formik.errors.curricularMeshName}
          />
          <Flex direction={'column'} gap={1}>
            {formik.values.curricularMeshName.map((item) => (
              <BarFileName
                key={item?.name}
                fileName={item?.name}
                fileUrl={item?.url}
                onDelete={() => deleteCurricularMesh(item?.name)}
              />
            ))}
          </Flex>
        </GridItem>
      </Grid>
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
        <GridItem>
          <TextAreaComtrol
            id={'observations'}
            label={'Observaciones de la solicitud (opcional)'}
            value={formik.values.observations}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.observations)}
            errorText={formik.errors.observations}
          />
        </GridItem>
      </Grid>
    </>
  )
}
export default StepThree
