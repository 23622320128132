import { Grid, GridItem, Text } from '@chakra-ui/react'
import RenderInfoItem from './RenderInfoItem'
import { NODATA } from '../../../../constans'

const PROGRAM_DESC = 'Información relevante del beneficio'

const NewbornBenefit = ({ currentProgram = null }) => (
  <Grid templateAreas="repeat(1,1fr)" gap={2} textAlign="center">
    <GridItem borderBottom="1px solid" borderColor="green.500" mb={2}>
      <Text fontSize="lg" fontWeight={500}>
        {PROGRAM_DESC}:
      </Text>
    </GridItem>

    {currentProgram?.newbornList?.length > 0 ? (
      <GridItem>
        <Text>Listado de hijos:</Text>
      </GridItem>
    ) : (
      <GridItem>
        <Text>{NODATA}</Text>
      </GridItem>
    )}
    {currentProgram?.newbornList?.map((item, index) => (
      <GridItem
        border="1px solid"
        borderRadius={8}
        p={2}
        borderColor="green.500"
      >
        <RenderInfoItem label={`Hijo nro ${index + 1}`} value={item?.name} />
        {item?.birthCertificate?.length > 0 && (
          <Text>Certificado/s de nacimiento:</Text>
        )}
        {item?.birthCertificate.map((doc) => (
          <RenderInfoItem
            type="file"
            value={{
              name: doc.newName,
              url: doc.url
            }}
          />
        ))}
      </GridItem>
    ))}
  </Grid>
)

export default NewbornBenefit
