import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Grid,
  GridItem,
  Tr,
  Td,
  Flex,
  Icon,
  useDisclosure
} from '@chakra-ui/react'
import { Table, Spinner, Alert, CustomModal } from '../UI'
import { RedirectIcon } from '../Icons'
import { useFamilyMembersPaginate } from '../../hooks/FamilyMembers'
import { FormatDate } from '../../Utils'
import FormUpdateFamilyMember from '../FormUpdateFamilyMember'

const TableFamilyPartners = ({ filtersFamilyMembers }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [page, setPage] = useState(0)
  const [familyMemberSelected, setFamilyMemberSelected] = useState(null)
  const limit = 6
  const {
    data: familyMembersList,
    isLoading,
    isFetching,
    isError
  } = useFamilyMembersPaginate(filtersFamilyMembers, page, limit)
  const headers = [
    '#',
    'Fecha de Solicitud',
    'Parentesco',
    'Nombres y Apellidos',
    'Rut',
    'Información Completa',
    'Obtenido desde'
  ]

  const openEditFamilyMember = (familyMember) => {
    setFamilyMemberSelected(familyMember)
    onOpen()
  }

  return (
    <>
      <Grid templateColumns={'repeat(1, 1fr)'} w={'100%'}>
        <Flex w={'100%'} justify={'flex-end'} py={3}>
          <Spinner size={'lg'} show={isLoading || isFetching} />
        </Flex>
        {isError && (
          <Flex w={'100%'} justify={'flex-end'} py={3}>
            <Alert status={'error'} message={'Error al Cargar los Datos'} />
          </Flex>
        )}
        <GridItem
          overflowX={'auto'}
          display={{
            lg: 'table',
            md: 'break-word',
            sm: 'break-word'
          }}
          css={{
            '&::-webkit-scrollbar': {
              width: '4px'
            },
            '&::-webkit-scrollbar-track': {
              width: '6px'
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#2E7423',
              borderRadius: '20px'
            }
          }}
        >
          <Table
            headers={headers}
            totalRecords={familyMembersList?.totalRecords}
            setPage={setPage}
            page={page}
          >
            {familyMembersList?.currentPages?.map((item, index) => (
              <Tr key={item._id}>
                <Td fontWeight={700} color={'grey.500'} textAlign={'center'}>
                  {index + 1 + page * limit}
                </Td>
                <Td color={'grey.500'}>{FormatDate(`${item?.createdAt}`)}</Td>
                <Td color={'grey.500'}>{item?.relationship}</Td>
                <Td color={'grey.500'} fontWeight={700}>
                  {item?.fullName}
                </Td>
                <Td color={'grey.500'}>{item?.rut}</Td>
                <Td textAlign={'center'}>
                  <Icon
                    fontSize="26"
                    as={RedirectIcon}
                    _hover={{ cursor: 'pointer' }}
                    onClick={() => openEditFamilyMember(item)}
                  />
                </Td>
                <Td color={'grey.500'} textAlign={'center'}>
                  <Link style={{ textDecoration: 'underline' }}>
                    {item?.registeredFrom}
                  </Link>
                </Td>
              </Tr>
            ))}
          </Table>
        </GridItem>
      </Grid>
      {isOpen && (
        <CustomModal
          isOpen={isOpen}
          onClose={onClose}
          title={'Actualización de familiar'}
          size={'2xl'}
          showButtons={false}
        >
          <FormUpdateFamilyMember
            onClose={onClose}
            type={'UPDATE'}
            familyMember={familyMemberSelected}
          />
        </CustomModal>
      )}
    </>
  )
}

export default TableFamilyPartners
