import React from 'react'
import { Flex, Text, Box, Checkbox } from '@chakra-ui/react'
import {
  BarFileName,
  InputComtrol,
  TextAreaComtrol,
  UploadFile
} from '../../../../UI'
import { Files, CondicionalModal } from '../../../../../Utils'
import VoucherIntructive from './VoucherIntructive'
import GiftcardsProof from './GiftcardsProof'
import RenderBottonsOptions from '../../OtherProcesses/RenderBottonsOptions'
import RenderInputRequired from '../../OtherProcesses/RenderInputRequired'
import RenderGeneralDocument from '../../OtherProcesses/RenderGeneralDocument'
import RenderPreviewPastStep from '../../OtherProcesses/RenderPreviewPastStep'
import { useTypeModal } from '../../../../../hooks/TypeModal'
import SwitchSkipStep from '../../OtherProcesses/SwitchSkipStep'

const Form = ({ currentStep, program, formik }) => {
  const { requiredDocuments } = CondicionalModal
  const {
    title,
    titleDocument,
    titleObservation,
    buttonsProps,
    buttonsPropsExtra,
    keyToSearch
  } = useTypeModal(currentStep?.key)
  const deleteFile = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.documentsNames,
      formik.values.documents
    )

    formik.setValues({
      ...formik.values,
      documentsNames: resultNames,
      documents: resultListFiles
    })
  }

  const handleChangeDocuments = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.documentsNames,
      formik.values.documents
    )

    formik.setValues({
      ...formik.values,
      documentsNames: newListNames,
      documents: newListFiles
    })
    e.target.value = null
  }

  return (
    <Flex
      align={'center'}
      justify={'center'}
      direction={'column'}
      gap={5}
      w={'100%'}
    >
      <Text fontWeight={700} color={'green.500'} fontSize={26}>
        {title}
      </Text>
      <Box w={'100%'}>
        {formik.values.canImcompleteStep && <SwitchSkipStep formik={formik} />}
        <RenderBottonsOptions
          formik={formik}
          buttonsProps={buttonsProps}
          buttonsPropsExtra={buttonsPropsExtra}
          currentStep={currentStep}
          program={program}
        />
        {keyToSearch && (
          <RenderPreviewPastStep program={program} keyToSearch={keyToSearch} />
        )}
        <RenderInputRequired formik={formik} program={program} />
        {(formik.values.isRequiredVoucher ||
          formik.values.isRequiredInstructive) && (
          <VoucherIntructive formik={formik} />
        )}

        {currentStep?.key === 'GIFTCARD-ACTIVATION-NEWBORN-BENEFIT' && (
          <GiftcardsProof formik={formik} />
        )}

        <RenderGeneralDocument formik={formik} program={program} />

        {/* Genreal info */}
        {formik.values.isRequiredDocuments && (
          <>
            <InputComtrol
              id={'documentsNames'}
              label={
                /* requiredDocuments.includes(currentStep?.key) */
                titleDocument || 'Añadir documento (opcional)'
              }
              isRequired={requiredDocuments.includes(currentStep?.key)}
            >
              <UploadFile
                id={'documentsNames'}
                direction={'row'}
                sizeIcon={30}
                p={5}
                onChange={(e) => handleChangeDocuments(e)}
                error={Boolean(formik.errors.documentsNames)}
                errorText={formik.errors.documentsNames}
              />
            </InputComtrol>
            <Flex direction={'column'} gap={1}>
              {formik.values.documentsNames.map((item) => (
                <BarFileName
                  key={item?.name}
                  fileName={item?.name}
                  fileUrl={item?.url}
                  onDelete={() => deleteFile(item?.name)}
                />
              ))}
            </Flex>
          </>
        )}
        <TextAreaComtrol
          label={titleObservation || 'Observaciones (Opcional)'}
          id={'descriptions'}
          isRequired={false}
          value={formik.values.descriptions}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.descriptions)}
          errorText={formik.errors.descriptions}
        />
        {!requiredDocuments.includes(currentStep?.key) && (
          <Checkbox
            css={{ borderColor: 'green' }}
            id={'isRequiredDocuments'}
            size="md"
            colorScheme="green"
            value={formik.values.isRequiredDocuments}
            onChange={formik.handleChange}
          >
            ¿Desea adjuntar documentos adicionales?
          </Checkbox>
        )}
        {/* end Genreal info */}
      </Box>
    </Flex>
  )
}

export default Form
