import { React, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Flex,
  Grid,
  GridItem,
  Icon,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import {
  Heading,
  Search,
  TabListHeader,
  CustomModal,
  CustomPaginate,
  Spinner,
  Alert,
  Button
} from '../../components/UI'
import CardPartner from '../../components/UI/CardPartner'
import {
  AddFileWhiteIcon,
  AssistanceIcon,
  EducationIcon,
  HealthIcon
} from '../../components/Icons'
import TablePrograms from '../../components/TablePrograms'
import { usePartners } from '../../hooks/Partners'
import FiltersPrograms from '../../components/FiltersPrograms'
import WithoutResults from '../../components/WithoutResults'

const Programs = () => {
  const { user } = useSelector((state) => state.auth)
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const history = useHistory()

  /* table */
  const initialFiltersPrograms = {
    search: '',
    category: '',
    startDate: '',
    endDate: '',
    state: '',
    program: '',
    typeCreation: 'ALL'
  }
  const [filtersPrograms, setFiltersPrograms] = useState(initialFiltersPrograms)
  /* table */

  /* Modal */
  const [selectedItem, setSelectedItem] = useState(false)
  const [page, setPage] = useState(0)
  const limit = 2

  const initialFiltersPartners = {
    search: '',
    startDate: '',
    endDate: '',
    grade: 'Todos los Grados',
    from: 'PRIMARY-LIST',
    state: ''
  }

  const [filtersPartners, setFiltersPartners] = useState(initialFiltersPartners)
  const {
    data: partners,
    isLoading,
    isFetching,
    error,
    isIdle,
    refetch
  } = usePartners(filtersPartners, page, limit)
  /* Modal */

  const handleChange = (e) => {
    setFiltersPartners({ ...filtersPartners, search: e.target.value })
    setPage(0)
  }

  const redirectToCreate = () => {
    history.push(`/programs/create/${selectedItem}`)
  }

  const redirectToCreateWithoutPartner = () => {
    history.push(`/programs/create-workshops-and-training`)
  }

  const redirectToUploadHistory = () => {
    history.push(`/programs/upload`)
  }

  useEffect(() => {
    if (isOpen) {
      setPage(0)
      setFiltersPartners(initialFiltersPartners)
    }
  }, [isOpen])

  useEffect(() => {
    refetch()
  }, [filtersPartners.search, page])

  useEffect(() => {
    toast.closeAll()
  }, [])

  const optionsTabComptrol = [
    {
      component: (
        <Flex align={'center'} px={7} justify={'center'}>
          Todos
        </Flex>
      ),
      key: ''
    },
    {
      component: (
        <Flex align={'center'} px={7} justify={'center'}>
          <Icon mr={2} fontSize={23} as={AssistanceIcon} />
          Asistencia
        </Flex>
      ),
      key: 'ATTENDANCE'
    },
    {
      component: (
        <Flex align={'center'} px={7} justify={'center'}>
          <Icon mr={2} fontSize={23} as={EducationIcon} />
          Educación
        </Flex>
      ),
      key: 'EDUCATION'
    },
    {
      component: (
        <Flex align={'center'} px={7} justify={'center'}>
          <Icon mr={2} fontSize={23} as={HealthIcon} />
          Salud
        </Flex>
      ),
      key: 'HEALTH'
    }
  ]
  return (
    <>
      <Flex direction={'column'} px={10}>
        <Heading title={'PROGRAMAS'} />

        <Grid templateColumns={'repeat(1, 1fr)'} py={10}>
          <GridItem borderBottom={'1px solid #2E7423'} pb={4}>
            <FiltersPrograms>
              <Flex
                direction={{
                  lg: 'row',
                  md: 'column',
                  sm: 'column'
                }}
                gap={{
                  lg: 0,
                  md: 0,
                  sm: 2
                }}
              >
                <Button
                  icon={AddFileWhiteIcon}
                  text={'Crear taller o capacitación'}
                  onClick={redirectToCreateWithoutPartner}
                  disabled={
                    ![
                      'ADMINISTRADOR',
                      'ADMINISTRATIVE-WORKSHOPS-AND-TRAINING'
                    ].includes(user?.role)
                  }
                />
                <Button
                  icon={AddFileWhiteIcon}
                  text={'Crear Solicitud'}
                  onClick={onOpen}
                  disabled={
                    ![
                      'ADMINISTRADOR',
                      'ASISTENTE_SOCIAL',
                      'ADMINISTRATIVE-NEWBORN-BENEFIT',
                      'ADMINISTRATIVE-INUJRED-IN-LINES-OF-SERVICE-KIT',
                      'ADMINISTRATIVE-HOSPITAL-ACCOMMODATION',
                      'ADMINISTRATIVE-MULTIPLE-PROGRAMS'
                    ].includes(user?.role)
                  }
                />
                <Button
                  icon={AddFileWhiteIcon}
                  text={'Carga histórica'}
                  onClick={redirectToUploadHistory}
                  disabled={
                    ![
                      'ADMINISTRADOR',
                      'ASISTENTE_SOCIAL',
                      'ADMINISTRATIVE-NEWBORN-BENEFIT',
                      'ADMINISTRATIVE-INUJRED-IN-LINES-OF-SERVICE-KIT',
                      'ADMINISTRATIVE-HOSPITAL-ACCOMMODATION',
                      'ADMINISTRATIVE-MULTIPLE-PROGRAMS'
                    ].includes(user?.role)
                  }
                />
              </Flex>
            </FiltersPrograms>
          </GridItem>
          <GridItem>
            <TabListHeader options={optionsTabComptrol}>
              <TablePrograms />
            </TabListHeader>
          </GridItem>
        </Grid>
      </Flex>
      {isOpen && (
        <CustomModal
          isOpen={isOpen}
          onClose={onClose}
          disableButtons={!selectedItem}
          onClick={redirectToCreate}
          title={'Crear Solicitud'}
          size={'4xl'}
        >
          <Flex direction={'column'} align={'center'} gap={2}>
            <Text fontWeight={700} color={'grey.500'}>
              Buscar Socio
            </Text>
            <Flex justify={'flex-end'} w={'100%'}>
              <Spinner size={'lg'} show={isLoading || isFetching} />
            </Flex>
            <Flex justify={'center'}>
              <Search
                currentValue={filtersPartners?.search}
                onChange={handleChange}
                onClick={() => {
                  setPage(0)
                }}
                showBtn={false}
              />
            </Flex>
            {error && (
              <Alert status={'error'} message={'Error al cargar los Datos'} />
            )}
            {!isIdle && (
              <Flex direction={'column'} w={'100%'} gap={3}>
                {partners?.currentPages?.map((partner) => (
                  <CardPartner
                    key={partner._id}
                    partner={partner}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                  />
                ))}
                {partners?.totalRecords === 0 && (
                  <WithoutResults
                    type={'SEARCH-PARTNERS-FROM-MODAL-CREATE-PROGRAM'}
                  />
                )}
                {partners?.totalRecords > 0 && (
                  <CustomPaginate
                    totalRecords={partners?.totalRecords}
                    perPage={limit}
                    page={page}
                    setPage={setPage}
                    justify={'center'}
                  />
                )}
              </Flex>
            )}
          </Flex>
        </CustomModal>
      )}
    </>
  )
}

export default Programs
