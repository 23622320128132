import React from 'react'
import {
  Text,
  Flex,
  Accordion,
  AccordionPanel,
  AccordionItem,
  AccordionButton,
  AccordionIcon
} from '@chakra-ui/react'

const SectionAccordion = ({ title, children }) => (
  <Accordion allowMultiple>
    <AccordionItem>
      <AccordionButton p={0}>
        <Flex
          bg={'green.100'}
          borderRadius={8}
          justify={'space-between'}
          align={'center'}
          color={'white'}
          fontSize={20}
          px={5}
          py={1}
          my={4}
          w={'-webkit-fill-available'}
        >
          <Text fontWeight={700}>{title}</Text>
          <AccordionIcon bg={'green.500'} borderRadius={4} />
        </Flex>
      </AccordionButton>
      <AccordionPanel>{children}</AccordionPanel>
    </AccordionItem>
  </Accordion>
)

export default SectionAccordion
