import React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts'

const MonthBarChart = ({ data, color }) => (
  <ResponsiveContainer width="100%" height="100%">
    <BarChart width={400} height={400} data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="month" />
      <YAxis />
      <Tooltip />
      <Bar dataKey="total" stackId="a" fill={color} radius={8} />
    </BarChart>
  </ResponsiveContainer>
)

export default MonthBarChart
