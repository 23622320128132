import React from 'react'
import { Flex, Icon, Text } from '@chakra-ui/react'
import { Button } from '../../components/UI'
import { RejectedIcon } from '../../components/Icons'

const Forbidden = () => (
  <Flex
    w={'100vw'}
    h={'100vh'}
    justify={'center'}
    align={'center'}
    css={{
      background:
        'linear-gradient(152deg, rgba(46,116,35,1) 0%, rgba(229,221,204,1) 100%)'
    }}
  >
    <Flex direction={'column'} gap={2} justify={'center'} align={'center'}>
      <Icon as={RejectedIcon} color={'red.700'} fontSize={'6xl'} />
      <Text fontWeight={700} color={'red.700'} fontSize={'4xl'}>
        Acceso no permitido
      </Text>
      <Button
        text={'Volver al inicio'}
        onClick={() => window.location.replace('/')}
        bghover={'green.700'}
      />
    </Flex>
  </Flex>
)

export default Forbidden
