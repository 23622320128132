import React from 'react'
import { Alert } from '../../UI'

const RenderCurrentStateStep = () => (
  <Alert
    status={'warning'}
    message={
      '¡Este paso ha sido guardado, pero su información se encuentra incompleta!'
    }
  />
)

export default RenderCurrentStateStep
