import React from 'react'
import {
  Grid,
  GridItem,
  Flex,
  Text,
  Radio,
  Tooltip,
  Divider
} from '@chakra-ui/react'

const RadioSelect = ({ formik, initialValues }) => {
  const handleChange = (e) => {
    if (e.target.value === 'EXTENSION') {
      formik.setValues({
        ...initialValues,
        isExtension: true,
        isExit: false,
        isRequiredDescription: true,
        isRequiredExtensionDays: true
      })
    }
    if (e.target.value === 'EXIT') {
      formik.setValues({
        ...initialValues,
        isExtension: false,
        isExit: true,
        isRequiredDescription: false,
        isRequiredDocuments: false,
        isRequiredExtensionDays: false
      })
    }
  }

  return (
    <>
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={4}
        mx={20}
        mb={4}
      >
        <GridItem border={'2px solid #82B378'} borderRadius={8} p={4}>
          <Tooltip
            label={'Click para seleccionar'}
            hasArrow
            bg={'green.500'}
            placement={'right-start'}
            isOpen={!formik.values.isExtension}
          >
            <Flex justify={'space-between'}>
              <Text color={'green.500'} fontWeight={700}>
                Extensión
              </Text>

              <Radio
                colorScheme="green"
                value="EXTENSION"
                bg={'green.100'}
                defaultChecked
                onChange={handleChange}
                isChecked={formik.values.isExtension}
              />
            </Flex>
          </Tooltip>

          <Divider py={2} />
          <Text>El socio solicitó una extensión de hospedaje</Text>
        </GridItem>
        <GridItem border={'2px solid #82B378'} borderRadius={8} p={4}>
          <Tooltip
            label={'Click para seleccionar'}
            hasArrow
            bg={'green.500'}
            placement={'right-start'}
            isOpen={!formik.values.isExit}
          >
            <Flex justify={'space-between'}>
              <Text color={'green.500'} fontWeight={700}>
                Salida
              </Text>
              <Radio
                colorScheme="green"
                bg={'green.100'}
                value="EXIT"
                onChange={handleChange}
                isChecked={formik.values.isExit}
              />
            </Flex>
          </Tooltip>
          <Divider py={2} />
          <Text>Se realizó el proceso de salida correctamente</Text>
        </GridItem>
      </Grid>
    </>
  )
}

export default RadioSelect
