/* eslint-disable import/prefer-default-export */
import { useQuery, useMutation, useQueryClient } from 'react-query'

import {
  getFamilyMembersByPartner,
  getFamilyMembersByPartnerPaginate,
  updateFamilyMember
} from '../api/FamilyMembers'

const key = 'familyMembers'

const getAction = (type) => {
  if (type === 'UPDATE') return updateFamilyMember
  return null
}

export function useFamilyMembers(partnerId) {
  return useQuery([key, partnerId], () => getFamilyMembersByPartner(partnerId))
}

export function useFamilyMembersPaginate(
  filtersFamilyMembers,
  page,
  limit,
  props = {}
) {
  return useQuery(
    [key, filtersFamilyMembers, page, limit],
    () => getFamilyMembersByPartnerPaginate(filtersFamilyMembers, page, limit),
    {
      ...props
    }
  )
}

export function useMutateFamilyMember(type) {
  const queryClient = useQueryClient()
  const actionFn = getAction(type)
  return useMutation(actionFn, {
    onSuccess: () => {
      queryClient.invalidateQueries([key])
    }
  })
}
