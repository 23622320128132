import React from 'react'
import { Grid, GridItem, Text, Flex } from '@chakra-ui/react'
import { CardFilePreview, InputComtrol } from '../../UI'

const BeneficiariesData = ({ program }) => (
  <>
    {program?.beneficiaries?.map((item) => (
      <Flex
        direction={'column'}
        p={2}
        border={'1px solid #82b378'}
        borderRadius={8}
        mb={2}
      >
        <Grid
          templateColumns={{
            lg: 'repeat(2, 1fr)',
            md: 'repeat(1, 1fr)',
            sm: 'repeat(1, 1fr)'
          }}
          gap={2}
        >
          <GridItem>
            <InputComtrol
              label={'Nombre del beneficiario'}
              value={item?.fullName}
              isRequired={false}
              readOnly={true}
            />
            <InputComtrol
              label={'Teléfono del beneficiario'}
              addictionType={'phone'}
              value={item?.phone}
              isRequired={false}
              readOnly={true}
            />
            <InputComtrol
              label={'Parentesco con el Socio'}
              value={item?.relationship}
              isRequired={false}
              readOnly={true}
            />
          </GridItem>
          <GridItem>
            <InputComtrol
              label={'Rut del beneficiario'}
              value={item?.rut}
              addictionType={'copy'}
              isRequired={false}
              readOnly={true}
            />
            <InputComtrol
              label={'Correo electrónico del beneficiario'}
              value={item?.email}
              isRequired={false}
              readOnly={true}
            />
          </GridItem>
        </Grid>
        {/* --- */}
        <Grid
          templateColumns={'repeat(1, 1fr)'}
          gap={2}
          borderBlockEnd={'2px dotted #82b378'}
          mb={2}
        >
          <GridItem>
            {item?.birthCertificate?.length > 0 && (
              <>
                <Text fontWeight={700} fontSize={'xl'}>
                  Certificado de nacimiento
                </Text>
                <Grid templateColumns={'repeat(1, 1fr)'} gap={1}>
                  {item?.birthCertificate?.map((doc) => (
                    <GridItem py={3}>
                      <CardFilePreview
                        key={doc.url}
                        name={doc?.name}
                        url={doc?.url}
                      />
                    </GridItem>
                  ))}
                </Grid>
              </>
            )}

            {item?.marriageCertificate?.length > 0 && (
              <>
                <Text fontWeight={700} fontSize={'xl'}>
                  Certificado de Matrimonio
                </Text>
                <Grid templateColumns={'repeat(1, 1fr)'} gap={1}>
                  {item?.marriageCertificate?.map((doc) => (
                    <GridItem py={3}>
                      <CardFilePreview
                        key={doc.url}
                        name={doc?.name}
                        url={doc?.url}
                      />
                    </GridItem>
                  ))}
                </Grid>
              </>
            )}
          </GridItem>
        </Grid>
        {/* --- */}
        <Grid
          templateColumns={{
            lg: 'repeat(2, 1fr)',
            md: 'repeat(1, 1fr)',
            sm: 'repeat(1, 1fr)'
          }}
          gap={2}
        >
          <GridItem>
            <InputComtrol
              label={'Nombre del destinatario'}
              value={item?.addresseeName}
              isRequired={false}
              readOnly={true}
            />
            <InputComtrol
              label={'Teléfono del destinatario'}
              addictionType={'phone'}
              value={item?.addresseePhone}
              isRequired={false}
              readOnly={true}
            />
          </GridItem>
          <GridItem>
            <InputComtrol
              label={'Rut del destinatario'}
              value={item?.addresseeRut}
              addictionType={'copy'}
              isRequired={false}
              readOnly={true}
            />
            <InputComtrol
              label={'Correo electrónico del destinatario'}
              value={item?.addresseeEmail}
              isRequired={false}
              readOnly={true}
            />
          </GridItem>
        </Grid>
        {/* --- */}
        <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
          <GridItem>
            <InputComtrol
              label={'Número de cuenta del destinatario'}
              value={
                item?.isRequiredAccountNumber
                  ? item?.addresseeAccountNumber
                  : 'No Aplica'
              }
              addictionType={'copy'}
              isRequired={false}
              readOnly={true}
            />
          </GridItem>
        </Grid>
        {/* --- */}
        <Grid
          templateColumns={{
            lg: 'repeat(2, 1fr)',
            md: 'repeat(1, 1fr)',
            sm: 'repeat(1, 1fr)'
          }}
          gap={2}
        >
          <GridItem>
            <InputComtrol
              label={'Banco del destinatario'}
              value={
                !item?.specificBank
                  ? item?.addresseeBank
                  : item?.addresseeBankOther
              }
              isRequired={false}
              readOnly={true}
            />
          </GridItem>
          <GridItem>
            <InputComtrol
              label={'Tipo de cuenta del destinatario'}
              value={
                !item?.specificAccountType
                  ? item?.addresseeTypeAccount
                  : item?.addresseeTypeAccountOther
              }
              isRequired={false}
              readOnly={true}
            />
          </GridItem>
        </Grid>
        {item?.addresseeAccountBackup?.length > 0 && (
          <>
            <Text fontWeight={700} fontSize={'xl'}>
              Respaldo de la cuenta del destinatario
            </Text>
            <Grid templateColumns={'repeat(1, 1fr)'} gap={1}>
              {item?.addresseeAccountBackup?.map((doc) => (
                <GridItem py={3}>
                  <CardFilePreview
                    key={doc.url}
                    name={doc?.name}
                    url={doc?.url}
                  />
                </GridItem>
              ))}
            </Grid>
          </>
        )}
      </Flex>
    ))}
  </>
)

export default BeneficiariesData
