import React from 'react'
import { Grid, GridItem, Flex } from '@chakra-ui/react'
import { Files } from '../../../../Utils'
import { BarFileName, InputComtrol, UploadFile } from '../../../UI'

const RenderGeneralDocument = ({ formik, program }) => {
  const getTitleInvoice = () => {
    const steps = program?.steps.filter(
      (item) =>
        item.key === 'INFORM-THE-PAYMENT-MADE-TWO-SOLIDARITY-HEALTH-FUND'
    )
    if (steps?.length > 0) {
      return 'Añadir certificado, factura o boleta enviado por la institución'
    }

    const steps2 = program?.steps.filter(
      (item) =>
        item.key === 'INFORM-THE-PAYMENT-MADE-MULTI-SOLIDARITY-HEALTH-FUND'
    )
    if (steps2?.length > 0) {
      return 'Añadir certificado de pago enviado por Dipreca'
    }

    const steps3 = program?.steps.filter(
      (item) =>
        item.key === 'VERIFICATION-DELIVERY-OFFICE-SOLIDARITY-HEALTH-FUND'
    )
    if (steps3?.length > 0) {
      return 'Añade Facturas'
    }
    return 'Añade Factura'
  }

  const deleteTariffDifferenceDocuments = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.tariffDifferenceDocumentsNames,
      formik.values.tariffDifferenceDocuments
    )

    formik.setValues({
      ...formik.values,
      tariffDifferenceDocumentsNames: resultNames,
      tariffDifferenceDocuments: resultListFiles
    })
  }

  const deleteOffice = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.officeName,
      formik.values.office
    )

    formik.setValues({
      ...formik.values,
      officeName: resultNames,
      office: resultListFiles
    })
  }

  const deleteOfficeInvoice = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.invoiceName,
      formik.values.invoice
    )

    formik.setValues({
      ...formik.values,
      invoiceName: resultNames,
      invoice: resultListFiles
    })
  }

  const deleteAmountCancel = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.amountCancelName,
      formik.values.amountCancel
    )

    formik.setValues({
      ...formik.values,
      amountCancelName: resultNames,
      amountCancel: resultListFiles
    })
  }

  const deletePurchaseDetail = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.purchaseDetailName,
      formik.values.purchaseDetail
    )

    formik.setValues({
      ...formik.values,
      purchaseDetailName: resultNames,
      purchaseDetail: resultListFiles
    })
  }

  const deleteActa = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.actaName,
      formik.values.acta
    )

    formik.setValues({
      ...formik.values,
      actaName: resultNames,
      acta: resultListFiles
    })
  }

  const deleteReportedEmail = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.reportedEmailName,
      formik.values.reportedEmail
    )

    formik.setValues({
      ...formik.values,
      reportedEmailName: resultNames,
      reportedEmail: resultListFiles
    })
  }

  const handleChangeTariffDifferenceDocuments = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.tariffDifferenceDocumentsNames,
      formik.values.tariffDifferenceDocuments
    )

    formik.setValues({
      ...formik.values,
      tariffDifferenceDocumentsNames: newListNames,
      tariffDifferenceDocuments: newListFiles
    })
    e.target.value = null
  }

  const handleChangeOffice = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.officeName,
      formik.values.office
    )

    formik.setValues({
      ...formik.values,
      officeName: newListNames,
      office: newListFiles
    })
    e.target.value = null
  }

  const handleChangeInvoice = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.invoiceName,
      formik.values.invoice
    )

    formik.setValues({
      ...formik.values,
      invoiceName: newListNames,
      invoice: newListFiles
    })
    e.target.value = null
  }

  const handleChangeAmountCancel = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.amountCancelName,
      formik.values.amountCancel
    )

    formik.setValues({
      ...formik.values,
      amountCancelName: newListNames,
      amountCancel: newListFiles
    })
    e.target.value = null
  }

  const handleChangePurchaseDetail = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.purchaseDetailName,
      formik.values.purchaseDetail
    )

    formik.setValues({
      ...formik.values,
      purchaseDetailName: newListNames,
      purchaseDetail: newListFiles
    })
    e.target.value = null
  }

  const handleChangeReportedEmail = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.reportedEmailName,
      formik.values.reportedEmail
    )

    formik.setValues({
      ...formik.values,
      reportedEmailName: newListNames,
      reportedEmail: newListFiles
    })
    e.target.value = null
  }

  const handleChangeActa = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.actaName,
      formik.values.acta
    )

    formik.setValues({
      ...formik.values,
      actaName: newListNames,
      acta: newListFiles
    })
    e.target.value = null
  }

  return (
    <>
      <Grid templateColumns={'repeat(1, 1fr)'}>
        <GridItem>
          {formik.values.isRequiredTariffDifferenceDocuments && (
            <GridItem>
              <InputComtrol
                label={'Añadir certificado de pago enviado por Dipreca'}
              >
                <UploadFile
                  id={'tariffDifferenceDocumentsNames'}
                  direction={'row'}
                  sizeIcon={30}
                  p={5}
                  onChange={(e) => handleChangeTariffDifferenceDocuments(e)}
                  error={Boolean(formik.errors.tariffDifferenceDocumentsNames)}
                  errorText={formik.errors.tariffDifferenceDocumentsNames}
                />
              </InputComtrol>
              <Flex direction={'column'} gap={1}>
                {formik.values.tariffDifferenceDocumentsNames.map((item) => (
                  <BarFileName
                    key={item?.name}
                    fileName={item?.name}
                    fileUrl={item?.url}
                    onDelete={() => deleteTariffDifferenceDocuments(item?.name)}
                  />
                ))}
              </Flex>
            </GridItem>
          )}

          {formik.values.isRequiredOffice &&
            formik.values.validationOffice !== 'EDIT' && (
              <GridItem>
                <InputComtrol label={'Añade Oficio'}>
                  <UploadFile
                    id={'officeName'}
                    direction={'row'}
                    sizeIcon={30}
                    p={5}
                    onChange={(e) => handleChangeOffice(e)}
                    error={Boolean(formik.errors.officeName)}
                    errorText={formik.errors.officeName}
                  />
                </InputComtrol>
                <Flex direction={'column'} gap={1}>
                  {formik.values.officeName.map((item) => (
                    <BarFileName
                      key={item?.name}
                      fileName={item?.name}
                      fileUrl={item?.url}
                      onDelete={() => deleteOffice(item?.name)}
                    />
                  ))}
                </Flex>
              </GridItem>
            )}

          {formik.values.isRequiredInvoice && (
            <GridItem>
              <InputComtrol
                label={getTitleInvoice()}
                isRequired={formik.values.isRequiredInvoice}
              >
                <UploadFile
                  id={'invoiceName'}
                  direction={'row'}
                  sizeIcon={30}
                  p={5}
                  onChange={(e) => handleChangeInvoice(e)}
                  error={Boolean(formik.errors.invoiceName)}
                  errorText={formik.errors.invoiceName}
                />
              </InputComtrol>
              <Flex direction={'column'} gap={1}>
                {formik.values.invoiceName.map((item) => (
                  <BarFileName
                    key={item?.name}
                    fileName={item?.name}
                    fileUrl={item?.url}
                    onDelete={() => deleteOfficeInvoice(item?.name)}
                  />
                ))}
              </Flex>
            </GridItem>
          )}
          {/* --- */}
          {formik.values.isRequiredAmountCancel && (
            <GridItem>
              <InputComtrol label={'Añade documento con el monto a cancelar'}>
                <UploadFile
                  id={'amountCancelName'}
                  direction={'row'}
                  sizeIcon={30}
                  p={5}
                  onChange={(e) => handleChangeAmountCancel(e)}
                  error={Boolean(formik.errors.amountCancelName)}
                  errorText={formik.errors.amountCancelName}
                />
              </InputComtrol>
              <Flex direction={'column'} gap={1}>
                {formik.values.amountCancelName.map((item) => (
                  <BarFileName
                    key={item?.name}
                    fileName={item?.name}
                    fileUrl={item?.url}
                    onDelete={() => deleteAmountCancel(item?.name)}
                  />
                ))}
              </Flex>
            </GridItem>
          )}
          {formik.values.isRequiredPurchaseDetail && (
            <GridItem>
              <InputComtrol label={'Añade detalle de compra'}>
                <UploadFile
                  id={'purchaseDetailName'}
                  direction={'row'}
                  sizeIcon={30}
                  p={5}
                  onChange={(e) => handleChangePurchaseDetail(e)}
                  error={Boolean(formik.errors.purchaseDetailName)}
                  errorText={formik.errors.purchaseDetailName}
                />
              </InputComtrol>
              <Flex direction={'column'} gap={1}>
                {formik.values.purchaseDetailName.map((item) => (
                  <BarFileName
                    key={item?.name}
                    fileName={item?.name}
                    fileUrl={item?.url}
                    onDelete={() => deletePurchaseDetail(item?.name)}
                  />
                ))}
              </Flex>
            </GridItem>
          )}
          {formik.values.isRequiredActa && (
            <GridItem>
              <InputComtrol label={'Añade Acta de comisión'}>
                <UploadFile
                  id={'actaName'}
                  direction={'row'}
                  sizeIcon={30}
                  p={5}
                  onChange={(e) => handleChangeActa(e)}
                  error={Boolean(formik.errors.actaName)}
                  errorText={formik.errors.actaName}
                />
              </InputComtrol>
              <Flex direction={'column'} gap={1}>
                {formik.values.actaName.map((item) => (
                  <BarFileName
                    key={item?.name}
                    fileName={item?.name}
                    fileUrl={item?.url}
                    onDelete={() => deleteActa(item?.name)}
                  />
                ))}
              </Flex>
            </GridItem>
          )}
          {formik.values.isRequiredReportedEmail && (
            <GridItem>
              <InputComtrol label={'Añade Correo respaldatorio'}>
                <UploadFile
                  id={'reportedEmailName'}
                  direction={'row'}
                  sizeIcon={30}
                  p={5}
                  onChange={(e) => handleChangeReportedEmail(e)}
                  error={Boolean(formik.errors.reportedEmailName)}
                  errorText={formik.errors.reportedEmailName}
                />
              </InputComtrol>
              <Flex direction={'column'} gap={1}>
                {formik.values.reportedEmailName.map((item) => (
                  <BarFileName
                    key={item?.name}
                    fileName={item?.name}
                    fileUrl={item?.url}
                    onDelete={() => deleteReportedEmail(item?.name)}
                  />
                ))}
              </Flex>
            </GridItem>
          )}
        </GridItem>
      </Grid>
    </>
  )
}

export default RenderGeneralDocument
