import { useEffect, useState } from 'react'
import { Box } from '@chakra-ui/react'
import { Alert } from '../../../UI'

const RenderValidationStep = ({ program, formik, validation }) => {
  const [data, setData] = useState({})
  useEffect(() => {
    validation?.forEach((item) => {
      const key = item?.key
      const fieldToValidate = item?.fieldToValidate
      const errorMessage = item?.errorMessage

      const additionalInformation = program?.additionalInformation.filter(
        (step) => step.key === key
      )

      const value = additionalInformation?.at(-1)[fieldToValidate]
      // Para hacer la validacion en yup
      formik.setFieldValue('amount', value)

      setData((prev) => ({
        ...prev,
        [fieldToValidate]: {
          value,
          errorMessage: errorMessage?.replace('{}', value)
        }
      }))
    })
  }, [validation, formik.values])

  return (
    <Box py={2}>
      {Object.keys(data)?.map((key) => (
        <Alert key={key} status="warning" message={data[key]?.errorMessage} />
      ))}
    </Box>
  )
}

export default RenderValidationStep
