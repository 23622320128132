import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { Grid, GridItem, Text } from '@chakra-ui/react'
import { InputComtrol, Select, TextAreaComtrol } from '../../UI'
import { steepTwoSchema } from './Schemas'
import { GradesCarabineros, ScoreAvg } from '../../../Utils'

const monthlyFeeStatementList = ['Al día', 'Impago']
const fuasList = ['SI', 'NO']

const StepTwo = ({ stepTwoForm, setStepTwoForm, refStepTwo, next }) => {
  const initialValues = {
    postulantName: '',
    phonePostulant: '',
    addressPostulant: '',
    unitPostulant: '',
    emailPostulant: '',
    fuasRequest: fuasList.at(0),
    score: ScoreAvg.at(0),
    antiquity: '',
    commandersName: '',
    grade: GradesCarabineros.at(0),
    academicOpinion: '',
    monthlyFeeStatement: monthlyFeeStatementList.at(0)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: steepTwoSchema,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: (values) => {
      setStepTwoForm({
        ...values
      })
      next()
    }
  })

  const handleChangeFuas = (value) => {
    formik.setFieldValue('fuasRequest', value)
  }

  const handleChangeMonthlyFeeStatement = (value) => {
    formik.setFieldValue('monthlyFeeStatement', value)
  }

  const handleChangeGrade = (value) => {
    formik.setFieldValue('grade', value)
  }

  const handleChangeScore = (value) => {
    formik.setFieldValue('score', value)
  }

  useEffect(() => {
    refStepTwo.current = formik.handleSubmit
  }, [])

  useEffect(() => {
    formik.setValues({
      postulantName: stepTwoForm.postulantName || '',
      phonePostulant: stepTwoForm.phonePostulant || '',
      addressPostulant: stepTwoForm.addressPostulant || '',
      unitPostulant: stepTwoForm.unitPostulant || '',
      emailPostulant: stepTwoForm.emailPostulant || '',
      fuasRequest: stepTwoForm.fuasRequest || fuasList.at(0),
      score: stepTwoForm.score || ScoreAvg.at(0),
      antiquity: stepTwoForm.antiquity || '',
      commandersName: stepTwoForm.commandersName || '',
      grade: stepTwoForm.grade || GradesCarabineros.at(0),
      academicOpinion: stepTwoForm.academicOpinion || '',
      monthlyFeeStatement:
        stepTwoForm.monthlyFeeStatement || monthlyFeeStatementList.at(0)
    })
  }, [stepTwoForm])

  return (
    <>
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
        <GridItem>
          <Text fontWeight={700} color={'green.500'} fontSize={'xl'}>
            Información del postulante a beca
          </Text>
          <InputComtrol
            id={'postulantName'}
            label={'Nombre y apellido'}
            value={formik.values.postulantName}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.postulantName)}
            errorText={formik.errors.postulantName}
          />
        </GridItem>
      </Grid>
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
        mb={8}
      >
        <GridItem>
          <InputComtrol
            id={'phonePostulant'}
            label={'Teléfono'}
            type={'number'}
            addictionType={'phone'}
            value={formik.values.phonePostulant}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.phonePostulant)}
            errorText={formik.errors.phonePostulant}
          />
          <InputComtrol
            id={'addressPostulant'}
            label={'Dirección'}
            value={formik.values.addressPostulant}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.addressPostulant)}
            errorText={formik.errors.addressPostulant}
          />
          <InputComtrol
            id={'unitPostulant'}
            label={'Unidad'}
            value={formik.values.unitPostulant}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.unitPostulant)}
            errorText={formik.errors.unitPostulant}
          />
        </GridItem>
        <GridItem>
          <InputComtrol
            id={'emailPostulant'}
            label={'Correo electrónico'}
            value={formik.values.emailPostulant}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.emailPostulant)}
            errorText={formik.errors.emailPostulant}
          />
          <InputComtrol id={'fuasRequest'} label={'Postulación F.U.A.S.'}>
            <Select
              bg={'white'}
              list={fuasList}
              value={formik.values.fuasRequest}
              onChange={handleChangeFuas}
            />
          </InputComtrol>
        </GridItem>
      </Grid>
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
        mb={8}
      >
        <GridItem>
          <Text fontWeight={700} color={'green.500'} fontSize={'xl'}>
            Información académica
          </Text>
          <InputComtrol id={'score'} label={'Información académica'}>
            <Select
              bg={'white'}
              list={ScoreAvg}
              value={formik.values.score}
              onChange={handleChangeScore}
            />
          </InputComtrol>
          <InputComtrol
            id={'commandersName'}
            isRequired={false}
            label={'Nombre del comandante de sección'}
            value={formik.values.commandersName}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.commandersName)}
            errorText={formik.errors.commandersName}
          />
        </GridItem>
        <GridItem>
          <Text fontSize={'xl'} opacity={0}>
            -----
          </Text>
          <InputComtrol
            id={'antiquity'}
            isRequired={false}
            label={'Ubicación de antigüedad dentro del escuadrón'}
            value={formik.values.antiquity}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.antiquity)}
            errorText={formik.errors.antiquity}
          />
          <InputComtrol id={'grade'} label={'Grado'}>
            <Select
              bg={'white'}
              list={GradesCarabineros}
              value={formik.values.grade}
              onChange={handleChangeGrade}
            />
          </InputComtrol>
        </GridItem>
      </Grid>
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
        <GridItem>
          <TextAreaComtrol
            id={'academicOpinion'}
            isRequired={false}
            label={
              'Opinión académica y de conducta por parte del comandante de sección'
            }
            value={formik.values.academicOpinion}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.academicOpinion)}
            errorText={formik.errors.academicOpinion}
          />
        </GridItem>
        <GridItem>
          <InputComtrol
            id={'monthlyFeeStatement'}
            label={'Estado de arancel de mensualidad'}
          >
            <Select
              bg={'white'}
              list={monthlyFeeStatementList}
              value={formik.values.monthlyFeeStatement}
              onChange={handleChangeMonthlyFeeStatement}
            />
          </InputComtrol>
        </GridItem>
      </Grid>
    </>
  )
}

export default StepTwo
