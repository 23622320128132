import { Grid, GridItem, Text } from '@chakra-ui/react'
import RenderInfoItem from './RenderInfoItem'
import { NODATA } from '../../../../constans'

const PROGRAM_DESC = 'Información relevante del beneficio'

const ScholarshipsApplicantOfficerDac = ({ currentProgram = null }) => (
  <Grid templateAreas="repeat(1,1fr)" gap={2}>
    <GridItem borderBottom="1px solid" borderColor="green.500" mb={2}>
      <Text fontSize="lg" fontWeight={500}>
        {PROGRAM_DESC}:
      </Text>
    </GridItem>
    <GridItem>
      <RenderInfoItem
        label="Nombre completo del postulante a beca"
        value={currentProgram?.postulantName}
      />
    </GridItem>
    <GridItem>
      <RenderInfoItem
        label="Observaciones Registro principal"
        value={currentProgram?.observations || NODATA}
      />
    </GridItem>
  </Grid>
)

export default ScholarshipsApplicantOfficerDac
