import { createIcon } from '@chakra-ui/react'
/* https://freeicons.io/ui-essentials-3/ui-essentials-notification-alarm-bell-icon-44563 */
/* eslint-disable import/prefer-default-export */
const CanceledIcon = createIcon({
  displayName: 'CanceledIcon',
  viewBox: '0 0 16 16',
  path: [
    <path
      d="M6.58626 11.5193C6.40524 11.5193 6.24795 11.4525 6.11438 11.319C5.98082 11.1854 5.91419 11.0283 5.91451 10.8476C5.91419 10.6669 5.98082 10.5097 6.11438 10.3762L7.05719 9.43335L6.11438 8.49054C5.98082 8.35697 5.91403 8.19999 5.91403 8.0196C5.91403 7.83858 5.98082 7.68129 6.11438 7.54773C6.24795 7.41416 6.40508 7.34754 6.58579 7.34785C6.76649 7.34754 6.92363 7.41416 7.05719 7.54773L8 8.49054L8.94281 7.54773C9.07637 7.41416 9.23351 7.34754 9.41421 7.34785C9.59492 7.34754 9.75205 7.41416 9.88562 7.54773C10.0192 7.68129 10.086 7.83858 10.086 8.0196C10.086 8.19999 10.0192 8.35697 9.88562 8.49054L8.94281 9.43335L9.88562 10.3762C10.0192 10.5097 10.086 10.667 10.086 10.848C10.086 11.0284 10.0192 11.1854 9.88562 11.319C9.75205 11.4525 9.59492 11.5195 9.41421 11.5198C9.23351 11.5195 9.07637 11.4525 8.94281 11.319L8 10.3762L7.05719 11.319C6.92363 11.4525 6.76665 11.5193 6.58626 11.5193Z"
      fill="#82B378"
    />,
    <path
      d="M4.11628 14.9842C3.61628 14.9842 3.19694 14.8147 2.85828 14.4756C2.51916 14.1369 2.34961 13.7176 2.34961 13.2176V2.78424C2.34961 2.28424 2.51916 1.86469 2.85828 1.52558C3.19694 1.18691 3.61628 1.01758 4.11628 1.01758H8.73294C8.96628 1.01758 9.19139 1.06202 9.40828 1.15091C9.62472 1.2398 9.81628 1.36758 9.98294 1.53424L13.1329 4.68424C13.2996 4.85091 13.4274 5.04247 13.5163 5.25891C13.6052 5.4758 13.6496 5.70091 13.6496 5.93424V13.2176C13.6496 13.7176 13.4801 14.1369 13.1409 14.4756C12.8023 14.8147 12.3829 14.9842 11.8829 14.9842H4.11628ZM8.54961 2.78424H4.11628V13.2176H11.8829V6.11758H9.43294C9.1885 6.11758 8.98028 6.03136 8.80828 5.85891C8.63583 5.68691 8.54961 5.47869 8.54961 5.23424V2.78424ZM4.11628 2.78424V5.23424C4.11628 5.47869 4.11628 5.68691 4.11628 5.85891C4.11628 6.03136 4.11628 6.11758 4.11628 6.11758V2.78424V5.23424C4.11628 5.47869 4.11628 5.68691 4.11628 5.85891C4.11628 6.03136 4.11628 6.11758 4.11628 6.11758V13.2176V2.78424Z"
      fill="#82B378"
    />
  ]
})

export default CanceledIcon
