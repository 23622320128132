import React from 'react'
import { Flex } from '@chakra-ui/react'
import ReactPaginate from 'react-paginate'
import './styles.css'

const CustomPaginate = ({
  totalRecords = 0,
  page = -1,
  perPage = 6,
  setPage,
  justify = 'flex-end'
}) => {
  const pageCount = Math.ceil(totalRecords / perPage)
  const handlePageClick = ({ selected: selectedPage }) => {
    setPage(selectedPage)
  }

  return (
    <Flex justify={justify} textAlign={'center'} w={'100%'} mt={5} p={0}>
      <ReactPaginate
        forcePage={page}
        previousLabel={'← Anterior'}
        nextLabel={'Siguiente →'}
        pageCount={pageCount}
        onPageChange={handlePageClick}
        containerClassName={'pagination'}
        previousLinkClassName={'pagination__link'}
        nextLinkClassName={'pagination__link'}
        disabledClassName={'pagination__link--disabled'}
        activeClassName={'pagination__link--active'}
        pageRangeDisplayed={1}
      />
    </Flex>
  )
}

export default CustomPaginate
