import React from 'react'
import {
  Checkbox,
  Divider,
  Grid,
  GridItem,
  Spacer,
  Text
} from '@chakra-ui/react'
import { InputComtrol } from '../../UI'

const FamilyInformation = ({ program }) => (
  <>
    <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
      <GridItem>
        <Text fontWeight={600}>Huésped:</Text>
        <Checkbox
          css={{ borderColor: 'green' }}
          size="md"
          colorScheme="green"
          value={program?.isPatientPartner}
          isChecked={program?.isPatientPartner}
          py={2}
          readOnly={true}
        >
          ¿El huésped es el socio?
        </Checkbox>
      </GridItem>

      {!program?.isPatientPartner && (
        <GridItem border={'1px solid #82b378'} borderRadius={8} p={2}>
          <Grid templateColumns={'repeat(1, 1fr)'}>
            <GridItem>
              <InputComtrol
                label={'Nombres y apellidos'}
                isRequired={false}
                value={program?.patientFullName}
                readOnly={true}
              />
            </GridItem>
          </Grid>
          {/* --- */}
          <Grid
            templateColumns={{
              lg: 'repeat(2, 1fr)',
              md: 'repeat(1, 1fr)',
              sm: 'repeat(1, 1fr)'
            }}
            gap={2}
          >
            <GridItem>
              <InputComtrol
                label={'Edad'}
                isRequired={false}
                value={program?.patientAge}
                readOnly={true}
              />
            </GridItem>
            <GridItem>
              <InputComtrol
                label={'Parentesco'}
                isRequired={false}
                value={program?.patientRelationship}
                readOnly={true}
              />
            </GridItem>
          </Grid>
        </GridItem>
      )}
      <Spacer w="100%" border="1px dotted #d3d3d3" />
      {program?.additionalGuest.length > 0 ? (
        <>
          <GridItem>
            <Text fontWeight={600}>Huéspedes adicionales:</Text>
          </GridItem>
          <Divider />
          {program?.additionalGuest.map((item) => (
            <GridItem border={'1px solid #82b378'} borderRadius={8} p={2}>
              <>
                <Grid templateColumns={'repeat(1, 1fr)'}>
                  <GridItem>
                    <InputComtrol
                      label={'Nombres y apellidos'}
                      isRequired={false}
                      value={item?.fullName}
                      readOnly={true}
                    />
                  </GridItem>
                </Grid>
                {/* --- */}
                <Grid
                  templateColumns={{
                    lg: 'repeat(2, 1fr)',
                    md: 'repeat(1, 1fr)',
                    sm: 'repeat(1, 1fr)'
                  }}
                  gap={2}
                >
                  <GridItem>
                    <InputComtrol
                      label={'Edad'}
                      isRequired={false}
                      value={item?.age}
                      readOnly={true}
                    />
                  </GridItem>
                  <GridItem>
                    <InputComtrol
                      label={'Parentesco'}
                      isRequired={false}
                      value={item?.relationship}
                      readOnly={true}
                    />
                  </GridItem>
                </Grid>
              </>
            </GridItem>
          ))}
        </>
      ) : (
        <GridItem>
          <Text fontWeight={600}>Sin Huéspedes Adicionales Registrados.</Text>
        </GridItem>
      )}
    </Grid>
  </>
)

export default FamilyInformation
