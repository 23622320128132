import GetStatus from './GetStatus'

const getMessageDefault = (key, programName, state) => {
  const messageDefault = {
    'NOTIFY-REGISTRATION': `Su solicitud para el programa de ${programName} ha sido enviada a revisión.`,
    'NOTIFY-NEW-STATUS': `Su solicitud para el programa de ${programName} ha sido ${GetStatus.getStatus[state]}.`,
    'NOTIFY-NEW-STATUS-PHONE': `Su solicitud para el programa de ${programName} ha sido notificada via teléfono.`
  }
  return messageDefault[key]
}

const PostNotifyRegistration = {
  NewbornBenefit: 'EVALUATION-CONTROL-SOCIAL-MANAGEMENT-NEWBORN-BENEFIT',
  SupportInCaseOfDeathOfMember:
    'EVALUATION-CONTROL-SOCIAL-MANAGEMENT-DEATH-OF-MEMBER',
  PreUniversityScholarships: 'COMMISSION-PREPARATION-PRE-UNIVERSITY',
  UniversityScholarships: 'SOCIAL-ANALYSIS-UNIVERSITY',
  DisasterSupport: 'ADD-PROPOSAL-DISASTER-SUPPORT',
  FireSupport: 'ADD-PROPOSAL-FIRE-SUPPORT',
  TechnicalProfessionalScholarships:
    'COMMISSION-PREPARATION-TECHNICAL-PROFESSIONAL',
  ScholarshipsApplicantOfficer: 'COMMISSION-PREPARATION-APPLICANT-OFFICER',
  ScholarshipsApplicantOfficerDac:
    'COMMISSION-PREPARATION-APPLICANT-OFFICER-DAC'
}

const PostChangeStatus = {
  NewbornBenefit: 'VERIFICATION-CONTROL-SOCIAL-MANAGEMENT-NEWBORN-BENEFIT',
  SupportInCaseOfDeathOfMember:
    'VERIFICATION-EVALUATION-CONTROL-SOCIAL-MANAGEMENT-DEATH-OF-MEMBER',
  PreUniversityScholarships: 'NOTIFY-NEW-STATUS-PHONE',
  UniversityScholarships: 'NOTIFY-NEW-STATUS-PHONE',
  TechnicalProfessionalScholarships: 'NOTIFY-NEW-STATUS-PHONE',
  ScholarshipsApplicantOfficer: 'NOTIFY-NEW-STATUS',
  ScholarshipsApplicantOfficerDac: 'NOTIFY-NEW-STATUS',
  WorkshopsAndTraining: 'ADD-PARTNERS-AND-SPOUSES-WORKSHOPS-AND-TRAINING'
}

const PostNewStatusApproved = {
  // NewbornBenefit: 'GIFTCARD-DENOMINATION-REQUEST-NEWBORN-BENEFIT',
  NewbornBenefit: 'RE-UPLOAD-DENOMINATION-REQUEST-NEWBORN-BENEFIT',
  SupportInCaseOfDeathOfMember: 'REQUEST-PAYMENT-ONE',
  PreUniversityScholarships:
    'INFORM-PRE-UNIVERSITY-AND-REQUEST-PAYMENT-PRE-UNIVERSITY',
  UniversityScholarships:
    'NOTIFICATION-ACT-SIGNED-BY-PARTNER-AND-SCHOLARSHIP-RECIPIENT-UNIVERSITY',
  TechnicalProfessionalScholarships:
    'NOTIFICATION-ACT-SIGNED-BY-PARTNER-AND-SCHOLARSHIP-RECIPIENT-TECHNICAL-PROFESSIONAL',
  ScholarshipsApplicantOfficer: 'PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER',
  ScholarshipsApplicantOfficerDac:
    'PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER-DAC',
  InjuredInLinesOfServiceKit:
    'ADD-OFFICE-ORDER-INUJRED-IN-LINES-OF-SERVICE-KIT',
  DisasterSupport: 'END-PROCESS',
  FireSupport: 'END-PROCESS',
  SolidarityHealthFund: 'NOTIFY-NEW-STATUS-PHONE'
}
const PostNewStatusApprovedByPhone = {
  PreUniversityScholarships: 'NOTIFY-NEW-STATUS',
  UniversityScholarships: 'NOTIFY-NEW-STATUS',
  FireSupport: 'NOTIFY-NEW-STATUS',
  DisasterSupport: 'NOTIFY-NEW-STATUS',
  TechnicalProfessionalScholarships: 'NOTIFY-NEW-STATUS',
  SolidarityHealthFund: 'NOTIFY-NEW-STATUS'
}

const PostNewStatusApprovedForSemesters = {
  /* TechnicalProfessionalScholarships: 'AMOUNT-TO-CANCEL-NEW-SEMESTERS' se elimino por peticion de jira */
  TechnicalProfessionalScholarships: 'OFFICE-AND-INVOICE-NEW-SEMESTERS'
}

const PostNewStatusRejected = {
  NewbornBenefit: 'END-PROCESS',
  SupportInCaseOfDeathOfMember: 'END-PROCESS',
  PreUniversityScholarships: 'END-PROCESS',
  UniversityScholarships: 'END-PROCESS',
  FireSupport: 'END-PROCESS' /*  */,
  TechnicalProfessionalScholarships: 'END-PROCESS',
  ScholarshipsApplicantOfficer: 'END-PROCESS',
  ScholarshipsApplicantOfficerDac: 'END-PROCESS',
  SolidarityHealthFund: 'END-PROCESS'
}

const postReviewDocumentsForCommission = {
  ScholarshipsApplicantOfficer: 'EVALUATION-COMMISSION-APPLICANT-OFFICER',
  ScholarshipsApplicantOfficerDac:
    'EVALUATION-COMMISSION-APPLICANT-OFFICER-DAC',
  SolidarityHealthFund: 'EVALUATION-COMMISSION-SOLIDARITY-HEALTH-FUND'
}

const postEvaluationCommission = {
  SolidarityHealthFund: 'REVIEW-CONTRIBUTIONS-SOLIDARITY-HEALTH-FUND'
}

const postReviewContributionsSolidarityHealthFund = {
  SolidarityHealthFund: 'PAYMENT-MADE-SOLIDARITY-HEALTH-FUND'
}

const postNotifyRejectStatusPhone = {
  SolidarityHealthFund: 'REJECTED-SOLIDARITY-HEALTH-FUND'
}

const postReviewDocuments = {
  SolidarityHealthFund: 'NOTIFY-CONTACT-PARTNER-PHONE-SOLIDARITY-HEALTH-FUND'
}

const postNotifyContactPartnerPhone = {
  SolidarityHealthFund:
    'VERIFICATION-NOTIFY-CONTACT-PARTNER-PHONE-SOLIDARITY-HEALTH-FUND'
}

const postGiftcardDenominationRequest = {
  NewbornBenefit: 'RE-UPLOAD-DENOMINATION-REQUEST-NEWBORN-BENEFIT'
}

const postReUploadDenominationRequest = {
  NewbornBenefit: 'GIFTCARD-DELIVERY-NEWBORN-BENEFIT'
}

const PostGiftcardDelivery = {
  NewbornBenefit: 'GIFTCARD-RECEPTION-NEWBORN-BENEFIT'
}

const postReceptionGiftcard = {
  NewbornBenefit: 'GIFTCARD-ACTIVATION-NEWBORN-BENEFIT'
}

const postGiftCardActivation = {
  NewbornBenefit: 'STOCK-RECIEVED-NEWBORN-BENEFIT'
  // NewbornBenefit: 'END-PROCESS'
}

const postGiftCardStockRevieved = {
  // agregar nuevo paso
  NewbornBenefit: 'END-PROCESS'
}

const postRequestPaymentOne = {
  SupportInCaseOfDeathOfMember:
    'VERIFICATION-REQUEST-PAYMENT-ONE-DEATH-OF-MEMBER'
}

const postVerificationPaymentOne = {
  SupportInCaseOfDeathOfMember: 'AUTHORIZE-REQUEST-PAYMENT-ONE-DEATH-OF-MEMBER'
}
const postAuthorizationPaymentOne = {
  SupportInCaseOfDeathOfMember: 'VERIFICATION-PAYMENT-MADE-DEATH-OF-MEMBER'
}

const postPaymentOneMade = {
  SupportInCaseOfDeathOfMember: 'REGISTER-NOTIFICATION-DEATH-OF-MEMBER'
}

const postRegisterNotification = {
  SupportInCaseOfDeathOfMember: 'REQUEST-PAYMENT-TWO-DEATH-OF-MEMBER'
}

const postRequestPaymentTwo = {
  SupportInCaseOfDeathOfMember:
    'VERIFICATION-REQUEST-PAYMENT-TWO-DEATH-OF-MEMBER'
}

const postVerificationPaymentTwo = {
  SupportInCaseOfDeathOfMember: 'AUTHORIZE-REQUEST-PAYMENT-TWO-DEATH-OF-MEMBER'
}

const postAuthorizationPaymentTwo = {
  SupportInCaseOfDeathOfMember: 'VERIFICATION-PAYMENT-TWO-MADE-DEATH-OF-MEMBER'
}

const postPaymentTwoMade = {
  SupportInCaseOfDeathOfMember: 'REGISTER-NOTIFICATION-TWO-DEATH-OF-MEMBER'
}

const postRegisterNotificationTwo = {
  SupportInCaseOfDeathOfMember: 'END-PROCESS'
}

const postCommissionPreparation = {
  PreUniversityScholarships: 'EVALUATION-COMMISSION-PRE-UNIVERSITY',
  ScholarshipsApplicantOfficer:
    'REVIEW-DOCUMENTS-FOR-COMMISSION-APPLICANT-OFFICER',
  ScholarshipsApplicantOfficerDac:
    'REVIEW-DOCUMENTS-FOR-COMMISSION-APPLICANT-OFFICER-DAC'
}

const postCommissionPreparationTechnicalProfessional = {
  TechnicalProfessionalScholarships:
    'EVALUATION-COMMISSION-TECHNICAL-PROFESSIONAL'
}

const postCommissionPreparationTechnicalProfessionalNewsSemesters = {
  TechnicalProfessionalScholarships: 'EVALUATION-COMMISSION-NEW-SEMESTERS'
}

const postInformPreUniversityAndRequestPayment = {
  PreUniversityScholarships: 'PURCHASE-ORDER-REQUEST-PRE-UNIVERSITY'
}

const postPurshaseOrderRequest = {
  PreUniversityScholarships:
    'VERIFICATION-PURCHASE-ORDER-REQUEST-PRE-UNIVERSITY',
  ScholarshipsApplicantOfficer:
    'VERIFICATION-PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER',
  ScholarshipsApplicantOfficerDac:
    'VERIFICATION-PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER-DAC',
  SolidarityHealthFund:
    'VERIFICATION-PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND'
}

const postPurshaseOrderRequestTwo = {
  SolidarityHealthFund:
    'VERIFICATION-PURCHASE-ORDER-REQUEST-TWO-SOLIDARITY-HEALTH-FUND'
}

const postVerificationPurchaseOrderRequest = {
  PreUniversityScholarships: 'VERIFICATION-PAYMENT-MADE-PRE-UNIVERSITY',
  TechnicalProfessionalScholarships:
    'VERIFICATION-PAYMENT-MADE-TECHNICAL-PROFESSIONAL',
  ScholarshipsApplicantOfficer: 'VERIFICATION-PAYMENT-MADE-APPLICANT-OFFICER',
  ScholarshipsApplicantOfficerDac:
    'VERIFICATION-PAYMENT-MADE-APPLICANT-OFFICER-DAC'
}

const postVerificationPurchaseOrderRequestNewSemesters = {
  TechnicalProfessionalScholarships:
    'VERIFICATION-PAYMENT-MADE-TECHNICAL-PROFESSIONAL-NEW-SEMESTERS'
}

const postPurchaseOrderAutorization = {
  PreUniversityScholarships:
    'VERIFICATION-PURCHASE-ORDER-AUTORIZATION-PRE-UNIVERSITY'
}

const postCorrectRequest = {
  PreUniversityScholarships: 'PURCHASE-ORDER-AUTORIZATION-PRE-UNIVERSITY'
}

const postGeneratePurchaseOrderPreUniversity = {
  PreUniversityScholarships: 'SIGN-PURCHASE-ORDER-PRE-UNIVERSITY'
}

const postSignPurchaseOrderPreUniversity = {
  PreUniversityScholarships: 'VERIFICATION-SIGN-PURCHASE-ORDER-PRE-UNIVERSITY'
}

const postPaymentMadePreUniversity = {
  PreUniversityScholarships: 'TRANSFERRED-AMOUNT-PRE-UNIVERSITY'
}

const postTransferredAmountPreUniversity = {
  PreUniversityScholarships: 'REPORT-PAYMENT-REQUEST-INVOICE-PRE-UNIVERSITY'
}

const postReportPaymentRequestInvoicePreUniversity = {
  PreUniversityScholarships: 'ATTACH-INVOICE-BY-PRE-UNIVERSITY'
}

const postAttachInvoiceByPreUniversity = {
  PreUniversityScholarships: 'END-PROCESS'
}

const postOfficeForFinancesPreUniversity = {
  PreUniversityScholarships: 'VERIFICATION-OFFICE-FOR-FINANCES-PRE-UNIVERSITY'
}

const postVerificationOfficeForFinancesPreUniversity = {
  PreUniversityScholarships:
    'VERIFICATION-PAYMENT-MADE-OFFICE-FINANCES-PRE-UNIVERSITY'
}

const postAddProposal = {
  DisasterSupport: 'LEGAL-REPORT-DISASTER-SUPPORT',
  FireSupport: 'LEGAL-REPORT-FIRE-SUPPORT'
}

const postLegalReport = {
  DisasterSupport: 'VALIDATE-LEGAL-REPORT-DISASTER-SUPPORT',
  FireSupport: 'VALIDATE-LEGAL-REPORT-FIRE-SUPPORT'
}

const postManagementDetermination = {
  DisasterSupport: 'VALIDATE-MANAGEMENT-DETERMINATION-DISASTER-SUPPORT',
  FireSupport: 'VALIDATE-MANAGEMENT-DETERMINATION-FIRE-SUPPORT'
}

const postPresidencyDetermination = {
  DisasterSupport: 'VALIDATE-PRESIDENSY-DETERMINATION-DISASTER-SUPPORT',
  FireSupport: 'VALIDATE-PRESIDENSY-DETERMINATION-FIRE-SUPPORT'
}

const postInformServiceSocialDetermination = {
  DisasterSupport: 'OFFICE-WITH-RESOLUTION',
  FireSupport: 'OFFICE-WITH-RESOLUTION'
}

const postOfficeWithResolution = {
  DisasterSupport: 'AID-APPROVAL-DISASTER-SUPPORT',
  FireSupport: 'AID-APPROVAL-FIRE-SUPPORT'
}

const postAidApproval = {
  DisasterSupport: 'VALIDATE-AID-APPROVAL-DISASTER-SUPPORT',
  FireSupport: 'VALIDATE-AID-APPROVAL-FIRE-SUPPORT'
}

const postValidateAddProposal = {
  DisasterSupport: 'VALIDATE-AID-APPROVAL-RESPONSE-DISASTER-SUPPORT',
  FireSupport: 'VALIDATE-AID-APPROVAL-RESPONSE-FIRE-SUPPORT'
}

const postAidDeposit = {
  DisasterSupport: 'NOTIFY-TO-SOCIAL-SERVICE-DISASTER-SUPPORT',
  FireSupport: 'NOTIFY-TO-SOCIAL-SERVICE-FIRE-SUPPORT'
}

const postNotifyToSocialService = {
  DisasterSupport: 'END_PROCESS',
  FireSupport: 'END_PROCESS'
}

// const accountPerformance = {
//   DisasterSupport: 'CONFIRM-RECEIPT-SURRENDER-DISASTER-SUPPORT',
//   FireSupport: 'CONFIRM-RECEIPT-SURRENDER-FIRE-SUPPORT'
// }

// const postConfirmReceiptSurrender = {
//   DisasterSupport: 'USED-AMOUNT-DISASTER-SUPPORT',
//   FireSupport: 'USED-AMOUNT-FIRE-SUPPORT'
// }

const postUsedAmount = {
  DisasterSupport: 'END-PROCESS',
  FireSupport: 'END-PROCESS',
  SolidarityHealthFund: 'INFORM-THE-PAYMENT-MADE-SOLIDARITY-HEALTH-FUND'
}

const postNotificationActSignedByPartnerAndScholarshipRecipient = {
  UniversityScholarships: 'THE-UNIVERSITY-WAS-INFORMED-UNIVERSITY',
  /* TechnicalProfessionalScholarships: 'AMOUNT-TO-CANCEL-TECHNICAL-PROFESSIONAL'  se elimino por peticion en jira */
  TechnicalProfessionalScholarships: 'OFFICE-AND-INVOICE-TECHNICAL-PROFESSIONAL'
}

const postTheUniversityWasInformed = {
  UniversityScholarships: 'PURCHASE-ORDER-REQUEST-UNIVERSITY'
}

const postPurshaseOrderRequestUniversity = {
  UniversityScholarships: 'VERIFICATION-PURCHASE-ORDER-REQUEST-UNIVERSITY'
}

const postVerificationPurchaseOrderRequestUniversity = {
  UniversityScholarships: 'VERIFICATION-PAYMENT-MADE-UNIVERSITY'
}

const postVerificationPurchaseOrderRequestSolidarityHealthFund = {
  SolidarityHealthFund: 'VERIFICATION-PAYMENT-MADE-SOLIDARITY-HEALTH-FUND'
}

const postVerificationPurchaseOrderRequestTwoSolidarityHealthFund = {
  SolidarityHealthFund: 'VERIFICATION-PAYMENT-MADE-TWO-SOLIDARITY-HEALTH-FUND'
}

const postPurchaseOrderAutorizationSolidarityHealthFund = {
  SolidarityHealthFund:
    'VERIFICATION-PURCHASE-ORDER-AUTORIZATION-SOLIDARITY-HEALTH-FUND'
}

const postGeneratePurchaseOrderSolidarityHealthFund = {
  SolidarityHealthFund: 'SIGN-PURCHASE-ORDER-SOLIDARITY-HEALTH-FUND'
}

const postSignPurchaseOrderSolidarityHealthFund = {
  /* SolidarityHealthFund: 'PAYMENT-MADE-SOLIDARITY-HEALTH-FUND' */
  SolidarityHealthFund:
    'VERIFICATION=SIGN-PURCHASE-ORDER-SOLIDARITY-HEALTH-FUND'
}

const postSignPurchaseOrderMultiSolidarityHealthFund = {
  SolidarityHealthFund: 'PAYMENT-MADE-MULTI-SOLIDARITY-HEALTH-FUND'
}

const postPaymentMadeSolidarityHealthFund = {
  /* SolidarityHealthFund: 'INFORM-THE-PAYMENT-MADE-SOLIDARITY-HEALTH-FUND' */
  SolidarityHealthFund: 'NOTIFY-NEW-STATUS-PHONE'
}

const postPaymentMadeTwoSolidarityHealthFund = {
  SolidarityHealthFund: 'INFORM-THE-PAYMENT-MADE-TWO-SOLIDARITY-HEALTH-FUND'
}

const postPaymentMadeTwoMultiSolidarityHealthFund = {
  SolidarityHealthFund: 'TRANSFERRED-AMOUNT-SOLIDARITY-HEALTH-FUND'
}

const postTransferredAmountSolidarityHealthFund = {
  SolidarityHealthFund: 'INFORM-THE-PAYMENT-MADE-MULTI-SOLIDARITY-HEALTH-FUND'
}

const postInformThePaymentMadeSolidarityHealthFund = {
  SolidarityHealthFund: 'DELIVERY-OFFICE-SOLIDARITY-HEALTH-FUND'
}

const postDeliveryOfficeSolidarityHealthFund = {
  SolidarityHealthFund: 'VERIFICATION-DELIVERY-OFFICE-SOLIDARITY-HEALTH-FUND'
}

const postInformThePaymentMadeMultiSolidarityHealthFund = {
  SolidarityHealthFund:
    'VERIFICATION-DELIVERY-OFFICE-MULTI-SOLIDARITY-HEALTH-FUND'
}

const postVerificationDeliveryOfficeMultiSolidarityHealthFund = {
  SolidarityHealthFund:
    'VERIFICATION-PAYMENT-MADE-DELIVERY-OFFICE-MULTI-SOLIDARITY-HEALTH-FUND'
}

const postVerificationDeliveryOfficeSolidarityHealthFund = {
  SolidarityHealthFund:
    'VERIFICATION-PAYMENT-MADE-DELIVERY-OFFICE-SOLIDARITY-HEALTH-FUND'
}

const postDeliveryAndReceiptCertificateSignSolidarityHealthFund = {
  SolidarityHealthFund: 'ALL-INVOICES-AND-TRADES-SOLIDARITY-HEALTH-FUND'
}

const postAllInvoicesAndTradesSolidarityHealthFund = {
  SolidarityHealthFund: 'END-PROCESS'
}

const postPurchaseOrderAutorizationUniversity = {
  UniversityScholarships: 'VERIFICATION-PURCHASE-ORDER-AUTORIZATION-UNIVERSITY'
}

const postGeneratePurchaseOrderUniversity = {
  UniversityScholarships: 'SIGN-PURCHASE-ORDER-UNIVERSITY'
}

const postSignPurchaseOrderUniversity = {
  UniversityScholarships: 'VERIFICATION-SIGN-PURCHASE-ORDER-UNIVERSITY'
}

const postPaymentMadeUniversity = {
  UniversityScholarships: 'INFORM-THE-UNIVERSITY-PAYMENT-MADE-UNIVERSITY'
}

const postInformTheUniversityPaymentMade = {
  UniversityScholarships: 'DELIVERY-OFFICE-UNIVERSITY'
}

const postDeliveryOfficeUniversity = {
  UniversityScholarships: 'VERIFICATION-DELIVERY-OFFICE-UNIVERSITY'
}

const postVerificationDeliveryOfficeUniversity = {
  UniversityScholarships: 'VERIFICATION-PAYMENT-MADE-DELIVERY-OFFICE-UNIVERSITY'
}

const postSocialAnalysisUniversity = {
  UniversityScholarships: 'EVALUATION-COMMISSION-UNIVERSITY'
}

const postAmountToCancel = {
  TechnicalProfessionalScholarships: 'OFFICE-AND-INVOICE-TECHNICAL-PROFESSIONAL'
}

const postAmountToCancelNewSemesters = {
  TechnicalProfessionalScholarships: 'OFFICE-AND-INVOICE-NEW-SEMESTERS'
}

const postOfficeAndInvoice = {
  TechnicalProfessionalScholarships:
    'VERIFICATION-PURCHASE-ORDER-REQUEST-TECHNICAL-PROFESSIONAL'
}
const postOfficeAndInvoiceNewSemesters = {
  TechnicalProfessionalScholarships:
    'VERIFICATION-PURCHASE-ORDER-REQUEST-NEW-SEMESTERS'
}

const postPurchaseOrderAutorizationTechnicalProfessional = {
  TechnicalProfessionalScholarships:
    'VERIFICATION-PURCHASE-ORDER-AUTORIZATION-TECHNICAL-PROFESSIONAL'
}

const postPurchaseOrderAutorizationTechnicalProfessionalNewSemesters = {
  TechnicalProfessionalScholarships:
    'VERIFICATION-PURCHASE-ORDER-AUTORIZATION-TECHNICAL-PROFESSIONAL-NEW-SEMESTERS'
}

const postPurchaseOrderAutorizationApplicantOfficer = {
  ScholarshipsApplicantOfficer:
    'VERIFICATION-PURCHASE-ORDER-AUTORIZATION-APPLICANT-OFFICER',
  ScholarshipsApplicantOfficerDac:
    'VERIFICATION-PURCHASE-ORDER-AUTORIZATION-APPLICANT-OFFICER-DAC'
}

const postCorrectRequestTechnicalProfessional = {
  TechnicalProfessionalScholarships:
    'PURCHASE-ORDER-AUTORIZATION-TECHNICAL-PROFESSIONAL'
}
const postCorrectRequestTechnicalProfessionalNewSemesters = {
  TechnicalProfessionalScholarships:
    'PURCHASE-ORDER-AUTORIZATION-TECHNICAL-PROFESSIONAL-NEW-SEMESTERS'
}

const postPaymentMadeTechnicalProfessional = {
  TechnicalProfessionalScholarships: 'TRANSFERRED-AMOUNT-TECHNICAL-PROFESSIONAL'
}

const postPaymentMadeTechnicalProfessionalNewSemesters = {
  TechnicalProfessionalScholarships:
    'TRANSFERRED-AMOUNT-TECHNICAL-PROFESSIONAL-NEWS-SEMESTERS'
}

const postTransferredAmountTechnicalProfessional = {
  TechnicalProfessionalScholarships:
    'INFORM-THE-INSTITUTE-PAYMENT-MADE-TECHNICAL-PROFESSIONAL'
}

const postTransferredAmountTechnicalProfessionalNewSemesters = {
  TechnicalProfessionalScholarships:
    'INFORM-THE-INSTITUTE-PAYMENT-MADE-NEW-SEMESTERS'
}

const postInformTheInstitutePaymentMade = {
  TechnicalProfessionalScholarships:
    'QUESTION-ADD-NEW-SEMESTERS-TECHNICAL-PROFESSIONAL'
}

const postInformTheInstitutePaymentMadeNewSemesters = {
  TechnicalProfessionalScholarships:
    'QUESTION-ADD-NEW-SEMESTERS-TECHNICAL-PROFESSIONAL'
}

const postQuestionAddNewSemestersTechnicalProfessional = {
  TechnicalProfessionalScholarships:
    'VALIDATION-ADD-NEW-SEMESTERS-TECHNICAL-PROFESSIONAL'
}

const postPurchaseOrderRequestApplicantOfficer = {
  /* ScholarshipsApplicantOfficer: 'SING-PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER' */
  ScholarshipsApplicantOfficer:
    'VERIFICATION-PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER',
  ScholarshipsApplicantOfficerDac:
    'VERIFICATION-PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER-DAC'
}

const postPurchaseOrderGenerateApplicantOfficer = {
  ScholarshipsApplicantOfficer: 'SING-PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER',
  ScholarshipsApplicantOfficerDac:
    'SING-PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER-DAC'
}

const postSingPurchaseOrderRequestApplicantOfficer = {
  ScholarshipsApplicantOfficer: 'PAYMENT-MADE-APPLICANT-OFFICER',
  ScholarshipsApplicantOfficerDac: 'PAYMENT-MADE-APPLICANT-OFFICER-DAC'
}

const postPaymentMadeApplicantOfficer = {
  ScholarshipsApplicantOfficer:
    'TRANSFERRED-AMOUNT-SCHOLARSHIPS-APPLICANT-OFFICER',
  ScholarshipsApplicantOfficerDac:
    'TRANSFERRED-AMOUNT-SCHOLARSHIPS-APPLICANT-OFFICER-DAC'
}

const postTransferredAmountScholarshipsApplicantOfficer = {
  ScholarshipsApplicantOfficer: 'RECEIPT-PAYMENT-CERTIFICATE-APPLICANT-OFFICER',
  ScholarshipsApplicantOfficerDac:
    'RECEIPT-PAYMENT-CERTIFICATE-APPLICANT-OFFICER-DAC'
}

const postReceiptPaymentCertificateApplicantOfficer = {
  ScholarshipsApplicantOfficer:
    'SING-RECEIPT-PAYMENT-CERTIFICATE-APPLICANT-OFFICER',
  ScholarshipsApplicantOfficerDac:
    'SING-RECEIPT-PAYMENT-CERTIFICATE-APPLICANT-OFFICER-DAC'
}

const postSingReceiptPaymentCertificateApplicantOfficer = {
  ScholarshipsApplicantOfficer: 'OFFICE-RECEIVED-BY-FINANCE-APPLICANT-OFFICER',
  ScholarshipsApplicantOfficerDac:
    'OFFICE-RECEIVED-BY-FINANCE-APPLICANT-OFFICER-DAC'
}

const postOfficeReceivedByFinanceApplicantOfficer = {
  ScholarshipsApplicantOfficer: 'END-PROCESS',
  ScholarshipsApplicantOfficerDac: 'END-PROCESS'
}

const postCorrectRequestApplicantOfficer = {
  ScholarshipsApplicantOfficer: 'PURCHASE-ORDER-AUTORIZATION-APPLICANT-OFFICER',
  ScholarshipsApplicantOfficerDac:
    'PURCHASE-ORDER-AUTORIZATION-APPLICANT-OFFICER-DAC'
}

const postManagementDeterminationWorkshopsAndTraining = {
  WorkshopsAndTraining: 'VALIDATE-NEW-STATE-WORKSHOPS-AND-TRAINING'
}

const postAddPartnersAndSpousesWorkshopsAndTraining = {
  WorkshopsAndTraining: 'REVISION-OF-INFORMATION-WORKSHOPS-AND-TRAINING'
}

const postRevisionOfInformationWorkshopsAndTraining = {
  WorkshopsAndTraining: 'REQUEST-FOR-PAYMENT-TO-MONITORS-WORKSHOPS-AND-TRAINING'
}

const postRequestForPaymentToMonitorsWorkshopsAndTraining = {
  WorkshopsAndTraining: 'VERIFICATION-PAYMENT-MADE-WORKSHOPS-AND-TRAINING'
}

const postVerificationPaymentMadeWorkshopsAndTraining = {
  WorkshopsAndTraining:
    'RESPONSE-VERIFICATION-PAYMENT-MADE-WORKSHOPS-AND-TRAINING'
}

const postPaymentMadeWorkshopsAndTraining = {
  WorkshopsAndTraining: 'END-PROCESS'
}

const postAddOfficeOrderInjuredInLinesOfServiceKit = {
  InjuredInLinesOfServiceKit: 'END-PROCESS'
}

const postAddStayCertificateHospitalAccommodation = {
  HospitalAccommodation: 'FIRST-EXTENSION-OR-OUTLET-HOSPITAL-ACCOMMODATION'
}

const postFirstExtensionOrOutletHospitalAccommodation = {
  HospitalAccommodation: 'VALIDATION-EXTENSION-OR-OUTLET-HOSPITAL-ACCOMMODATION'
}

const postDeterminationExtensionHospitalAccommodation = {
  HospitalAccommodation:
    'VALIDATION-DETERMINATION-EXTENSION-HOSPITAL-ACCOMMODATION'
}

const postToPurchaseOrderRequest = {
  SolidarityHealthFund: 'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND'
}

const postToAddDebtCertificate = {
  SolidarityHealthFund: 'ADD-DEBT-CERTIFICATE-SOLIDARITY-HEALTH-FUND'
}

const postToDebtCertificate = {
  SolidarityHealthFund: 'ADD-DEBT-CERTIFICATE-TWO-SOLIDARITY-HEALTH-FUND'
}

const postToAddDocumentationForPaymentsAndOrPurchases = {
  SolidarityHealthFund:
    'ADD-DOCUMENTATION-FOR-PAYMENTS-AND-OR-PURCHASES-SOLIDARITY-HEALTH-FUND'
}

const getPostNotifyStatus = (
  state,
  type,
  currentKey,
  program,
  typeProcess = null
) => {
  if (
    state === 'APPROVED' &&
    currentKey === 'NOTIFY-NEW-STATUS' &&
    type === 'SolidarityHealthFund'
  ) {
    if (
      (typeProcess.medicines &&
        typeProcess.tariffDifference &&
        typeProcess.service === 'Dipreca') ||
      (typeProcess.supplies &&
        typeProcess.tariffDifference &&
        typeProcess.service === 'Dipreca')
    ) {
      return postToAddDocumentationForPaymentsAndOrPurchases[type]
    }

    if (
      typeProcess.medicines ||
      typeProcess.supplies ||
      typeProcess.milkFormula ||
      typeProcess.others
    ) {
      return postToPurchaseOrderRequest[type]
    }

    if (
      (typeProcess.tariffDifference && typeProcess.service === 'Dipreca') ||
      (typeProcess.tariffDifference && typeProcess.service === 'Otras')
    ) {
      return postToAddDebtCertificate[type]
    }
  }
  if (state === 'INREVIEW') {
    return PostNotifyRegistration[type]
  }
  if (state === 'REJECTED' || state === 'REVOKED') {
    return PostNewStatusRejected[type]
  }
  if (state === 'APPROVED') {
    if (currentKey === 'NOTIFY-NEW-STATUS-PHONE') {
      return PostNewStatusApprovedByPhone[type]
    }
    if (program?.completedSemesters > 0) {
      return PostNewStatusApprovedForSemesters[type]
    }
    return PostNewStatusApproved[type]
  }

  return null
}

const getNextStep = (currentKey, type, history, typeProcess = null) => {
  if (currentKey === 'STOCK-RECIEVED-NEWBORN-BENEFIT') {
    return postGiftCardStockRevieved[type]
  }
  if (currentKey === 'GIFTCARD-DENOMINATION-REQUEST-NEWBORN-BENEFIT') {
    return postGiftcardDenominationRequest[type]
  }
  if (currentKey === 'RE-UPLOAD-DENOMINATION-REQUEST-NEWBORN-BENEFIT') {
    return postReUploadDenominationRequest[type]
  }
  if (currentKey === 'GIFTCARD-DELIVERY-NEWBORN-BENEFIT') {
    return PostGiftcardDelivery[type]
  }
  if (currentKey === 'GIFTCARD-RECEPTION-NEWBORN-BENEFIT') {
    return postReceptionGiftcard[type]
  }
  if (currentKey === 'GIFTCARD-ACTIVATION-NEWBORN-BENEFIT') {
    return postGiftCardActivation[type]
  }
  if (
    currentKey === 'REQUEST-PAYMENT-ONE-DEATH-OF-MEMBER' ||
    currentKey === 'REQUEST-PAYMENT-ONE-EDIT-DEATH-OF-MEMBER'
  ) {
    return postRequestPaymentOne[type]
  }
  if (currentKey === 'VERIFICATION-REQUEST-PAYMENT-ONE-DEATH-OF-MEMBER') {
    return postVerificationPaymentOne[type]
  }
  if (currentKey === 'AUTHORIZE-REQUEST-PAYMENT-ONE-DEATH-OF-MEMBER') {
    return postAuthorizationPaymentOne[type]
  }
  if (currentKey === 'PAYMENT-ONE-MADE-DEATH-OF-MEMBER') {
    return postPaymentOneMade[type]
  }
  if (currentKey === 'REGISTER-NOTIFICATION-DEATH-OF-MEMBER') {
    return postRegisterNotification[type]
  }
  if (currentKey === 'REGISTER-NOTIFICATION-TWO-DEATH-OF-MEMBER') {
    return postRegisterNotificationTwo[type]
  }
  if (
    currentKey === 'REQUEST-PAYMENT-TWO-DEATH-OF-MEMBER' ||
    currentKey === 'REQUEST-PAYMENT-TWO-EDIT-DEATH-OF-MEMBER'
  ) {
    return postRequestPaymentTwo[type]
  }
  if (currentKey === 'VERIFICATION-REQUEST-PAYMENT-TWO-DEATH-OF-MEMBER') {
    return postVerificationPaymentTwo[type]
  }
  if (currentKey === 'AUTHORIZE-REQUEST-PAYMENT-TWO-DEATH-OF-MEMBER') {
    return postAuthorizationPaymentTwo[type]
  }
  if (currentKey === 'PAYMENT-TWO-MADE-DEATH-OF-MEMBER') {
    return postPaymentTwoMade[type]
  }
  if (
    currentKey === 'COMMISSION-PREPARATION-APPLICANT-OFFICER' ||
    currentKey === 'COMMISSION-PREPARATION-APPLICANT-OFFICER-DAC'
  ) {
    return postCommissionPreparation[type]
  }
  if (currentKey === 'COMMISSION-PREPARATION-PRE-UNIVERSITY') {
    return postCommissionPreparation[type]
  }
  if (currentKey === 'COMMISSION-PREPARATION-TECHNICAL-PROFESSIONAL') {
    return postCommissionPreparationTechnicalProfessional[type]
  }
  if (
    currentKey ===
    'COMMISSION-PREPARATION-TECHNICAL-PROFESSIONAL-NEWS-SEMESTERS'
  ) {
    return postCommissionPreparationTechnicalProfessionalNewsSemesters[type]
  }
  if (
    currentKey === 'INFORM-PRE-UNIVERSITY-AND-REQUEST-PAYMENT-PRE-UNIVERSITY'
  ) {
    return postInformPreUniversityAndRequestPayment[type]
  }
  if (currentKey === 'PURCHASE-ORDER-REQUEST') {
    return postPurshaseOrderRequest[type]
  }
  if (currentKey === 'PURCHASE-ORDER-REQUEST-PRE-UNIVERSITY') {
    return postPurshaseOrderRequest[type]
  }
  if (currentKey === 'PURCHASE-ORDER-REQUEST-EDIT') {
    return postPurshaseOrderRequest[type]
  }
  if (currentKey === 'PURCHASE-ORDER-REQUEST-EDIT-PRE-UNIVERSITY') {
    return postPurshaseOrderRequest[type]
  }
  if (currentKey === 'VERIFICATION-PURCHASE-ORDER-REQUEST') {
    return postVerificationPurchaseOrderRequest[type]
  }
  if (
    currentKey === 'VERIFICATION-PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER' ||
    currentKey === 'VERIFICATION-PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER-DAC'
  ) {
    return postVerificationPurchaseOrderRequest[type]
  }
  if (
    currentKey === 'VERIFICATION-PURCHASE-ORDER-REQUEST-TECHNICAL-PROFESSIONAL'
  ) {
    return postVerificationPurchaseOrderRequest[type]
  }
  if (currentKey === 'VERIFICATION-PURCHASE-ORDER-REQUEST-NEW-SEMESTERS') {
    return postVerificationPurchaseOrderRequestNewSemesters[type]
  }
  if (currentKey === 'VERIFICATION-PURCHASE-ORDER-REQUEST-PRE-UNIVERSITY') {
    return postVerificationPurchaseOrderRequest[type]
  }
  if (currentKey === 'PURCHASE-ORDER-AUTORIZATION-PRE-UNIVERSITY') {
    return postPurchaseOrderAutorization[type]
  }
  if (currentKey === 'CORRECT-REQUEST-PRE-UNIVERSITY') {
    return postCorrectRequest[type]
  }
  if (currentKey === 'GENERATE-PURCHASE-ORDER-PRE-UNIVERSITY') {
    return postGeneratePurchaseOrderPreUniversity[type]
  }
  if (currentKey === 'GENERATE-PURCHASE-ORDER-PRE-UNIVERSITY-EDIT') {
    return postGeneratePurchaseOrderPreUniversity[type]
  }

  if (currentKey === 'SIGN-PURCHASE-ORDER-PRE-UNIVERSITY') {
    return postSignPurchaseOrderPreUniversity[type]
  }

  if (currentKey === 'SOCIAL-ANALYSIS-UNIVERSITY') {
    return postSocialAnalysisUniversity[type]
  }
  if (currentKey === 'PAYMENT-MADE-PRE-UNIVERSITY') {
    return postPaymentMadePreUniversity[type]
  }
  if (currentKey === 'TRANSFERRED-AMOUNT-PRE-UNIVERSITY') {
    return postTransferredAmountPreUniversity[type]
  }
  if (currentKey === 'TRANSFERRED-AMOUNT-SOLIDARITY-HEALTH-FUND') {
    return postTransferredAmountSolidarityHealthFund[type]
  }
  if (currentKey === 'REPORT-PAYMENT-REQUEST-INVOICE-PRE-UNIVERSITY') {
    return postReportPaymentRequestInvoicePreUniversity[type]
  }
  if (currentKey === 'ATTACH-INVOICE-BY-PRE-UNIVERSITY') {
    // aqui el siguiente paso es endProcess
    return postAttachInvoiceByPreUniversity[type]
  }
  if (currentKey === 'OFFICE-FOR-FINANCES-PRE-UNIVERSITY') {
    return postOfficeForFinancesPreUniversity[type]
  }

  if (currentKey === 'VERIFICATION-OFFICE-FOR-FINANCES-PRE-UNIVERSITY') {
    return postVerificationOfficeForFinancesPreUniversity[type] // este es el paso siguiente
  }
  if (currentKey === 'OFFICE-FOR-FINANCES-PRE-UNIVERSITY-EDIT') {
    return postOfficeForFinancesPreUniversity[type]
  }
  if (currentKey === 'ADD-PROPOSAL-DISASTER-SUPPORT') {
    return postAddProposal[type]
  }
  if (currentKey === 'ADD-PROPOSAL-FIRE-SUPPORT') {
    return postAddProposal[type]
  }
  if (currentKey === 'LEGAL-REPORT-DISASTER-SUPPORT') {
    return postLegalReport[type]
  }
  if (currentKey === 'LEGAL-REPORT-FIRE-SUPPORT') {
    return postLegalReport[type]
  }
  if (currentKey === 'MANAGEMENT-DETERMINATION-DISASTER-SUPPORT') {
    return postManagementDetermination[type]
  }
  if (currentKey === 'MANAGEMENT-DETERMINATION-FIRE-SUPPORT') {
    return postManagementDetermination[type]
  }
  if (currentKey === 'PRESIDENSY-DETERMINATION-DISASTER-SUPPORT') {
    return postPresidencyDetermination[type]
  }
  if (currentKey === 'PRESIDENSY-DETERMINATION-FIRE-SUPPORT') {
    return postPresidencyDetermination[type]
  }
  if (currentKey === 'INFORM-SERVICE-SOCIAL-DETERMINATION') {
    return postInformServiceSocialDetermination[type]
  }
  if (currentKey === 'OFFICE-WITH-RESOLUTION') {
    return postOfficeWithResolution[type]
  }
  if (currentKey === 'AID-APPROVAL-DISASTER-SUPPORT') {
    return postAidApproval[type]
  }
  if (currentKey === 'AID-APPROVAL-FIRE-SUPPORT') {
    return postAidApproval[type]
  }
  if (currentKey === 'VALIDATE-AID-APPROVAL-DISASTER-SUPPORT') {
    return postValidateAddProposal[type]
  }
  if (currentKey === 'VALIDATE-AID-APPROVAL-FIRE-SUPPORT') {
    return postValidateAddProposal[type]
  }
  if (currentKey === 'AID-APPROVAL-EDIT-DISASTER-SUPPORT') {
    return postAidApproval[type]
  }
  if (currentKey === 'AID-APPROVAL-EDIT-FIRE-SUPPORT') {
    return postAidApproval[type]
  }
  if (currentKey === 'AID-DEPOSIT-DISASTER-SUPPORT') {
    return postAidDeposit[type]
  }
  if (currentKey === 'AID-DEPOSIT-FIRE-SUPPORT') {
    return postAidDeposit[type]
  }
  if (currentKey === 'NOTIFY-TO-SOCIAL-SERVICE-DISASTER-SUPPORT') {
    return postNotifyToSocialService[type]
  }
  if (currentKey === 'NOTIFY-TO-SOCIAL-SERVICE-FIRE-SUPPORT') {
    return postNotifyToSocialService[type]
  }
  // if (currentKey === 'ACCOUNT-PERFORMANCE-DISASTER-SUPPORT') {
  //   return accountPerformance[type]
  // }
  // if (currentKey === 'ACCOUNT-PERFORMANCE-FIRE-SUPPORT') {
  //   return accountPerformance[type]
  // }
  // if (currentKey === 'CONFIRM-RECEIPT-SURRENDE-DISASTER-SUPPORT') {
  //   return postConfirmReceiptSurrender[type]
  // }
  // if (currentKey === 'CONFIRM-RECEIPT-SURRENDE-FIRE-SUPPORT') {
  //   return postConfirmReceiptSurrender[type]
  // }
  // if (currentKey === 'CONFIRM-RECEIPT-SURRENDER-DISASTER-SUPPORT') {
  //   return postConfirmReceiptSurrender[type]
  // }
  // if (currentKey === 'CONFIRM-RECEIPT-SURRENDER-FIRE-SUPPORT') {
  //   return postConfirmReceiptSurrender[type]
  // }
  // if (currentKey === 'USED-AMOUNT-DISASTER-SUPPORT') {
  //   return postUsedAmount[type]
  // }
  // if (currentKey === 'USED-AMOUNT-FIRE-SUPPORT') {
  //   return postUsedAmount[type]
  // }
  if (currentKey === 'NOTIFY-NEW-STATUS-PHONE') {
    return PostNewStatusApprovedByPhone[type]
  }
  if (
    currentKey ===
      'NOTIFICATION-ACT-SIGNED-BY-PARTNER-AND-SCHOLARSHIP-RECIPIENT-TECHNICAL-PROFESSIONAL' ||
    currentKey ===
      'NOTIFICATION-ACT-SIGNED-BY-PARTNER-AND-SCHOLARSHIP-RECIPIENT-UNIVERSITY'
  ) {
    return postNotificationActSignedByPartnerAndScholarshipRecipient[type]
  }
  if (currentKey === 'THE-UNIVERSITY-WAS-INFORMED-UNIVERSITY') {
    return postTheUniversityWasInformed[type]
  }
  if (
    currentKey === 'PURCHASE-ORDER-REQUEST-UNIVERSITY' ||
    currentKey === 'PURCHASE-ORDER-REQUEST-UNIVERSITY-EDIT-UNIVERSITY' ||
    currentKey === 'CORRECT-REQUEST-UNIVERSITY'
  ) {
    return postPurshaseOrderRequestUniversity[type]
  }
  if (currentKey === 'VERIFICATION-PURCHASE-ORDER-REQUEST-UNIVERSITY') {
    return postVerificationPurchaseOrderRequestUniversity[type]
  }
  if (currentKey === 'PURCHASE-ORDER-AUTORIZATION-UNIVERSITY') {
    return postPurchaseOrderAutorizationUniversity[type]
  }
  if (currentKey === 'GENERATE-PURCHASE-ORDER-UNIVERSITY') {
    return postGeneratePurchaseOrderUniversity[type]
  }
  if (currentKey === 'GENERATE-PURCHASE-ORDER-UNIVERSITY-EDIT-UNIVERSITY') {
    return postGeneratePurchaseOrderUniversity[type]
  }
  if (currentKey === 'SIGN-PURCHASE-ORDER-UNIVERSITY') {
    return postSignPurchaseOrderUniversity[type]
  }
  if (currentKey === 'PAYMENT-MADE-UNIVERSITY') {
    return postPaymentMadeUniversity[type]
  }
  if (currentKey === 'INFORM-THE-UNIVERSITY-PAYMENT-MADE-UNIVERSITY') {
    return postInformTheUniversityPaymentMade[type]
  }
  if (
    currentKey === 'DELIVERY-OFFICE-UNIVERSITY' ||
    currentKey === 'DELIVERY-OFFICE-UNIVERSITY-EDIT-UNIVERSITY'
  ) {
    return postDeliveryOfficeUniversity[type]
  }
  if (currentKey === 'VERIFICATION-DELIVERY-OFFICE-UNIVERSITY') {
    return postVerificationDeliveryOfficeUniversity[type]
  }
  if (currentKey === 'AMOUNT-TO-CANCEL-TECHNICAL-PROFESSIONAL') {
    return postAmountToCancel[type]
  }
  if (currentKey === 'AMOUNT-TO-CANCEL-NEW-SEMESTERS') {
    return postAmountToCancelNewSemesters[type]
  }
  if (currentKey === 'OFFICE-AND-INVOICE-TECHNICAL-PROFESSIONAL') {
    return postOfficeAndInvoice[type]
  }
  if (currentKey === 'OFFICE-AND-INVOICE-NEW-SEMESTERS') {
    return postOfficeAndInvoiceNewSemesters[type]
  }
  if (currentKey === 'OFFICE-AND-INVOICE-EDIT-TECHNICAL-PROFESSIONAL') {
    return postOfficeAndInvoice[type]
  }
  if (currentKey === 'OFFICE-AND-INVOICE-NEW-SEMESTERS-EDIT') {
    return postOfficeAndInvoiceNewSemesters[type]
  }
  if (currentKey === 'PURCHASE-ORDER-AUTORIZATION-TECHNICAL-PROFESSIONAL') {
    return postPurchaseOrderAutorizationTechnicalProfessional[type]
  }
  if (
    currentKey ===
    'PURCHASE-ORDER-AUTORIZATION-TECHNICAL-PROFESSIONAL-NEW-SEMESTERS'
  ) {
    return postPurchaseOrderAutorizationTechnicalProfessionalNewSemesters[type]
  }
  if (
    currentKey === 'PURCHASE-ORDER-AUTORIZATION-APPLICANT-OFFICER' ||
    currentKey === 'PURCHASE-ORDER-AUTORIZATION-APPLICANT-OFFICER-DAC'
  ) {
    return postPurchaseOrderAutorizationApplicantOfficer[type]
  }
  if (currentKey === 'CORRECT-REQUEST-TECHNICAL-PROFESSIONAL') {
    return postCorrectRequestTechnicalProfessional[type]
  }
  if (currentKey === 'CORRECT-REQUEST-TECHNICAL-PROFESSIONAL-NEW-SEMESTERS') {
    return postCorrectRequestTechnicalProfessionalNewSemesters[type]
  }
  if (currentKey === 'PAYMENT-MADE-TECHNICAL-PROFESSIONAL') {
    return postPaymentMadeTechnicalProfessional[type]
  }

  if (currentKey === 'TRANSFERRED-AMOUNT-TECHNICAL-PROFESSIONAL') {
    return postTransferredAmountTechnicalProfessional[type]
  }

  if (
    currentKey === 'TRANSFERRED-AMOUNT-TECHNICAL-PROFESSIONAL-NEWS-SEMESTERS'
  ) {
    return postTransferredAmountTechnicalProfessionalNewSemesters[type]
  }

  if (currentKey === 'PAYMENT-MADE-TECHNICAL-PROFESSIONAL-NEW-SEMESTERS') {
    return postPaymentMadeTechnicalProfessionalNewSemesters[type]
  }
  if (
    currentKey === 'INFORM-THE-INSTITUTE-PAYMENT-MADE-TECHNICAL-PROFESSIONAL'
  ) {
    return postInformTheInstitutePaymentMade[type]
  }
  if (currentKey === 'INFORM-THE-INSTITUTE-PAYMENT-MADE-NEW-SEMESTERS') {
    return postInformTheInstitutePaymentMadeNewSemesters[type]
  }
  if (currentKey === 'QUESTION-ADD-NEW-SEMESTERS-TECHNICAL-PROFESSIONAL') {
    return postQuestionAddNewSemestersTechnicalProfessional[type]
  }
  if (currentKey === 'REVIEW-DOCUMENTS-FOR-COMMISSION') {
    return postReviewDocumentsForCommission[type]
  }
  if (
    currentKey === 'REVIEW-DOCUMENTS-FOR-COMMISSION-APPLICANT-OFFICER' ||
    currentKey === 'REVIEW-DOCUMENTS-FOR-COMMISSION-APPLICANT-OFFICER-DAC'
  ) {
    return postReviewDocumentsForCommission[type]
  }
  if (
    currentKey === 'PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER' ||
    currentKey === 'PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER-DAC'
  ) {
    return postPurchaseOrderRequestApplicantOfficer[type]
  }
  if (
    currentKey === 'PURCHASE-ORDER-GENERATE-APPLICANT-OFFICER' ||
    currentKey === 'PURCHASE-ORDER-GENERATE-APPLICANT-OFFICER-DAC'
  ) {
    return postPurchaseOrderGenerateApplicantOfficer[type]
  }
  if (
    currentKey === 'SING-PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER' ||
    currentKey === 'SING-PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER-DAC'
  ) {
    return postSingPurchaseOrderRequestApplicantOfficer[type]
  }
  if (
    currentKey === 'TRANSFERRED-AMOUNT-SCHOLARSHIPS-APPLICANT-OFFICER' ||
    currentKey === 'TRANSFERRED-AMOUNT-SCHOLARSHIPS-APPLICANT-OFFICER-DAC'
  ) {
    return postTransferredAmountScholarshipsApplicantOfficer[type]
  }
  if (
    currentKey === 'PAYMENT-MADE-APPLICANT-OFFICER' ||
    currentKey === 'PAYMENT-MADE-APPLICANT-OFFICER-DAC'
  ) {
    return postPaymentMadeApplicantOfficer[type]
  }
  if (
    currentKey === 'RECEIPT-PAYMENT-CERTIFICATE-APPLICANT-OFFICER' ||
    currentKey === 'RECEIPT-PAYMENT-CERTIFICATE-APPLICANT-OFFICER-DAC'
  ) {
    return postReceiptPaymentCertificateApplicantOfficer[type]
  }
  if (
    currentKey === 'SING-RECEIPT-PAYMENT-CERTIFICATE-APPLICANT-OFFICER' ||
    currentKey === 'SING-RECEIPT-PAYMENT-CERTIFICATE-APPLICANT-OFFICER-DAC'
  ) {
    return postSingReceiptPaymentCertificateApplicantOfficer[type]
  }
  if (
    currentKey === 'OFFICE-RECEIVED-BY-FINANCE-APPLICANT-OFFICER' ||
    currentKey === 'OFFICE-RECEIVED-BY-FINANCE-APPLICANT-OFFICER-DAC'
  ) {
    return postOfficeReceivedByFinanceApplicantOfficer[type]
  }
  if (
    currentKey === 'CORRECT-REQUEST-APPLICANT-OFFICER' ||
    currentKey === 'CORRECT-REQUEST-APPLICANT-OFFICER-DAC'
  ) {
    return postCorrectRequestApplicantOfficer[type]
  }
  if (currentKey === 'MANAGEMENT-DETERMINATION-WORKSHOPS-AND-TRAINING') {
    return postManagementDeterminationWorkshopsAndTraining[type]
  }
  if (currentKey === 'ADD-PARTNERS-AND-SPOUSES-WORKSHOPS-AND-TRAINING') {
    return postAddPartnersAndSpousesWorkshopsAndTraining[type]
  }
  if (currentKey === 'REVISION-OF-INFORMATION-WORKSHOPS-AND-TRAINING') {
    return postRevisionOfInformationWorkshopsAndTraining[type]
  }
  if (currentKey === 'REQUEST-FOR-PAYMENT-TO-MONITORS-WORKSHOPS-AND-TRAINING') {
    return postRequestForPaymentToMonitorsWorkshopsAndTraining[type]
  }
  if (currentKey === 'VERIFICATION-PAYMENT-MADE-WORKSHOPS-AND-TRAINING') {
    return postVerificationPaymentMadeWorkshopsAndTraining[type]
  }
  if (currentKey === 'PAYMENT-MADE-WORKSHOPS-AND-TRAINING') {
    return postPaymentMadeWorkshopsAndTraining[type]
  }
  if (currentKey === 'ADD-OFFICE-ORDER-INUJRED-IN-LINES-OF-SERVICE-KIT') {
    return postAddOfficeOrderInjuredInLinesOfServiceKit[type]
  }
  if (currentKey === 'REVIEW-DOCUMENTS-SOLIDARITY-HEALTH-FUND') {
    return postReviewDocuments[type]
  }
  if (currentKey === 'NOTIFY-CONTACT-PARTNER-PHONE-SOLIDARITY-HEALTH-FUND') {
    return postNotifyContactPartnerPhone[type]
  }
  if (
    currentKey ===
    'CONTINUE-PROCESS-NOTIFY-CONTACT-PARTNER-PHONE-SOLIDARITY-HEALTH-FUND'
  ) {
    return postNotifyContactPartnerPhone[type]
  }
  if (currentKey === 'COMMISSION-PREPARATION-SOLIDARITY-HEALTH-FUND') {
    return postReviewDocumentsForCommission[type]
  }
  if (
    currentKey === 'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND' ||
    currentKey ===
      'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND-EDIT-SOLIDARITY-HEALTH-FUND'
  ) {
    return postPurshaseOrderRequest[type]
  }
  if (
    currentKey ===
      'VERIFICATION-PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND' ||
    currentKey ===
      'VERIFICATION-PURCHASE-ORDER-EDIT-REQUEST-SOLIDARITY-HEALTH-FUND'
  ) {
    return postVerificationPurchaseOrderRequestSolidarityHealthFund[type]
  }
  if (currentKey === 'PURCHASE-ORDER-AUTORIZATION-SOLIDARITY-HEALTH-FUND') {
    return postPurchaseOrderAutorizationSolidarityHealthFund[type]
  }
  if (currentKey === 'GENERATE-PURCHASE-ORDER-SOLIDARITY-HEALTH-FUND') {
    return postGeneratePurchaseOrderSolidarityHealthFund[type]
  }
  if (currentKey === 'SIGN-PURCHASE-ORDER-SOLIDARITY-HEALTH-FUND') {
    /* if (
      typeProcess.tariffDifference &&
      typeProcess.service === 'Dipreca' &&
      (typeProcess.medicines || typeProcess.supplies)
    ) {
      return postSignPurchaseOrderSolidarityHealthFund[type]
    }
    if (
      typeProcess.tariffDifference &&
      typeProcess.service === 'Dipreca' &&
      (!typeProcess.medicines || !typeProcess.supplies)
    ) {
      return postSignPurchaseOrderMultiSolidarityHealthFund[type]
    } */
    return postSignPurchaseOrderSolidarityHealthFund[type]
  }
  if (currentKey === 'PAYMENT-MADE-SOLIDARITY-HEALTH-FUND') {
    /* if (typeProcess.tariffDifference && typeProcess.service === 'Otras') {
      return postPaymentMadeTwoSolidarityHealthFund[type]
      console.log('type', type)
      return PostNewStatusApproved[type]
    } */
    return postPaymentMadeSolidarityHealthFund[type]
  }
  if (currentKey === 'PAYMENT-MADE-MULTI-SOLIDARITY-HEALTH-FUND') {
    return postPaymentMadeTwoMultiSolidarityHealthFund[type]
  }
  if (currentKey === 'INFORM-THE-PAYMENT-MADE-SOLIDARITY-HEALTH-FUND') {
    return postInformThePaymentMadeSolidarityHealthFund[type]
  }
  if (
    currentKey === 'INFORM-THE-PAYMENT-MADE-MULTI-SOLIDARITY-HEALTH-FUND' ||
    currentKey ===
      'INFORM-THE-PAYMENT-MADE-MULTI-SOLIDARITY-HEALTH-FUND-EDIT-SOLIDARITY-HEALTH-FUND'
  ) {
    return postInformThePaymentMadeMultiSolidarityHealthFund[type]
  }
  if (
    currentKey === 'VERIFICATION-DELIVERY-OFFICE-MULTI-SOLIDARITY-HEALTH-FUND'
  ) {
    return postVerificationDeliveryOfficeMultiSolidarityHealthFund[type]
  }
  if (
    currentKey === 'DELIVERY-OFFICE-SOLIDARITY-HEALTH-FUND' ||
    currentKey ===
      'DELIVERY-OFFICE-SOLIDARITY-HEALTH-FUND-EDIT-SOLIDARITY-HEALTH-FUND'
  ) {
    return postDeliveryOfficeSolidarityHealthFund[type]
  }
  if (currentKey === 'VERIFICATION-DELIVERY-OFFICE-SOLIDARITY-HEALTH-FUND') {
    return postVerificationDeliveryOfficeSolidarityHealthFund[type]
  }
  if (
    currentKey === 'DELIVERY-AND-RECEIPT-CERTIFICATE-SOLIDARITY-HEALTH-FUND'
  ) {
    //   return postDeliveryAndReceiptCertificateSolidarityHealthFund[type]
    // }
    // if (
    //   currentKey ===
    //   'DELIVERY-AND-RECEIPT-CERTIFICATE-SIGN-SOLIDARITY-HEALTH-FUND'
    // ) {
    if (
      typeProcess.tariffDifference &&
      typeProcess.service === 'Dipreca' &&
      (typeProcess.medicines || typeProcess.supplies)
    ) {
      return postDeliveryAndReceiptCertificateSignSolidarityHealthFund[type]
    }
    if (
      typeProcess.tariffDifference &&
      typeProcess.service === 'Dipreca' &&
      (!typeProcess.medicines || !typeProcess.supplies)
    ) {
      return postAllInvoicesAndTradesSolidarityHealthFund[type]
    }
    return postDeliveryAndReceiptCertificateSignSolidarityHealthFund[type]
  }
  if (currentKey === 'ALL-INVOICES-AND-TRADES-SOLIDARITY-HEALTH-FUND') {
    return postAllInvoicesAndTradesSolidarityHealthFund[type]
  }
  if (currentKey === 'USED-AMOUNT-SOLIDARITY-HEALTH-FUND') {
    return postUsedAmount[type]
  }
  if (currentKey === 'ADD-DEBT-CERTIFICATE-SOLIDARITY-HEALTH-FUND') {
    return postToPurchaseOrderRequest[type]
  }
  if (
    currentKey ===
    'ADD-DOCUMENTATION-FOR-PAYMENTS-AND-OR-PURCHASES-SOLIDARITY-HEALTH-FUND'
  ) {
    return postToDebtCertificate[type]
  }
  if (
    currentKey === 'ADD-DEBT-CERTIFICATE-TWO-SOLIDARITY-HEALTH-FUND' ||
    currentKey === 'ADD-DEBT-CERTIFICATE-TWO-EDIT-SOLIDARITY-HEALTH-FUND'
  ) {
    return postPurshaseOrderRequestTwo[type]
  }
  if (
    currentKey ===
    'VERIFICATION-PURCHASE-ORDER-REQUEST-TWO-SOLIDARITY-HEALTH-FUND'
  ) {
    return postVerificationPurchaseOrderRequestTwoSolidarityHealthFund[type]
  }
  if (currentKey === 'INFORM-THE-PAYMENT-MADE-TWO-SOLIDARITY-HEALTH-FUND') {
    return postDeliveryOfficeSolidarityHealthFund[type]
  }
  if (currentKey === 'EVALUATION-COMMISSION-SOLIDARITY-HEALTH-FUND') {
    return postEvaluationCommission[type]
  }
  if (currentKey === 'NOTIFY-REJECTED-STATUS-PHONE-SOLIDARITY-HEALTH-FUND') {
    return postNotifyRejectStatusPhone[type]
  }
  if (currentKey === 'EVALUATION-COMMISSION-UNIVERSITY') {
    return postEvaluationCommission[type]
  }
  if (
    currentKey === 'EVALUATION-COMMISSION-APPLICANT-OFFICER' ||
    currentKey === 'EVALUATION-COMMISSION-APPLICANT-OFFICER-DAC'
  ) {
    return postEvaluationCommission[type]
  }
  if (currentKey === 'EVALUATION-COMMISSION-TECHNICAL-PROFESSIONAL') {
    return postEvaluationCommission[type]
  }
  if (currentKey === 'EVALUATION-COMMISSION-PRE-UNIVERSITY') {
    return postEvaluationCommission[type]
  }
  if (currentKey === 'REVIEW-CONTRIBUTIONS-SOLIDARITY-HEALTH-FUND') {
    /* return PostNewStatusApproved[type] */
    return postReviewContributionsSolidarityHealthFund[type]
  }
  if (currentKey === 'ADD-STAY-CERTIFICATE-HOSPITAL-ACCOMMODATION') {
    return postAddStayCertificateHospitalAccommodation[type]
  }
  if (currentKey === 'FIRST-EXTENSION-OR-OUTLET-HOSPITAL-ACCOMMODATION') {
    return postFirstExtensionOrOutletHospitalAccommodation[type]
  }
  if (currentKey === 'DETERMINATION-EXTENSION-HOSPITAL-ACCOMMODATION') {
    return postDeterminationExtensionHospitalAccommodation[type]
  }
  if (currentKey === 'ANY-EXTENSION-OR-OUTLET-HOSPITAL-ACCOMMODATION') {
    return postFirstExtensionOrOutletHospitalAccommodation[type]
  }
  if (currentKey === 'RESIGNATION-OR-LOSS-AUTHORIZATION-RESIGNATION') {
    return 'RESPONSE-RESIGNATION-OR-LOSS-AUTHORIZATION-RESIGNATION'
  }
  if (currentKey === 'CORRECT-REQUEST-RESIGNATION') {
    return history?.lastKeyPreviousResignation
  }

  return null
}

const getFinalKey = {
  NewbornBenefit: 'FINISHED',
  SupportInCaseOfDeathOfMember: 'FINISHED',
  PreUniversityScholarships: 'FINISHED',
  UniversityScholarships: 'FINISHED',
  DisasterSupport: 'FINISHED',
  FireSupport: 'FINISHED',
  TechnicalProfessionalScholarships: 'FINISHED',
  ScholarshipsApplicantOfficer: 'FINISHED',
  ScholarshipsApplicantOfficerDac: 'FINISHED',
  WorkshopsAndTraining: 'FINISHED',
  InjuredInLinesOfServiceKit: 'FINISHED',
  HospitalAccommodation: 'FINISHED',
  SolidarityHealthFund: 'FINISHED'
}

export default {
  PostNotifyRegistration /* object */,
  PostChangeStatus /* function */,
  getMessageDefault /* function */,
  getPostNotifyStatus /* function */,
  getFinalKey /* object */,
  PostGiftcardDelivery /* object */,
  getNextStep /* function */
}
