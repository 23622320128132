import React, { useState, useEffect } from 'react'
import {
  Box,
  Checkbox,
  Flex,
  Grid,
  GridItem,
  Td,
  Text,
  Tr
} from '@chakra-ui/react'
import GetStatusCard from '../../../../../Utils/GetStatusCard'
import { Alert, Spinner, Table } from '../../../../UI'
import { useProgramsSameStep } from '../../../../../hooks/HistoryProgram'
import Filters from './Filters'

const TableProgram = ({
  programsIds,
  addAll,
  formik,
  selectedProgramChangeCheckBox,
  type,
  state,
  currentStep,
  setStepsTitlesProgram
}) => {
  const [filters, setFilters] = useState({
    type,
    state,
    currentStep,
    startDate: '',
    endDate: ''
  })
  const { data, isLoading, isError } = useProgramsSameStep(filters)
  const headers = ['', 'Socio', 'Fase actual', 'Próxima fase', 'Estado']

  useEffect(() => {
    if (data && addAll) {
      formik.setValues({
        ...formik.values,
        programsIds: data?.programs?.map((program) => program._id)
      })
    }
    setStepsTitlesProgram({
      currentStep: data?.programs?.at(0)?.currentStep || '',
      lastStep: data?.programs?.at(0)?.lastStep || ''
    })
  }, [data])

  return (
    <>
      {isLoading && (
        <Flex w={'100%'} justify={'center'} py={3}>
          <Spinner size={'xl'} show={true} />
        </Flex>
      )}
      {isError && (
        <Flex w={'100%'} justify={'center'} py={3}>
          <Alert status={'error'} message={'Error al Cargar los Datos'} />
        </Flex>
      )}
      {!isLoading && !isError && (
        <Grid templateColumns={'repeat(1, 1fr)'} w={'100%'}>
          <GridItem>
            <Flex
              direction={{
                lg: 'row',
                md: 'column',
                sm: 'column'
              }}
              justify={'space-between'}
              my={4}
            >
              <Box>
                <Text color={'green.500'}>Programa de ayuda</Text>
                <Text color={'green.500'} fontWeight={700} fontSize={'20px'}>
                  {data?.programs?.at(0)?.programName || 'Sin Resultados'}
                </Text>
                <Text color={'green.500'}>
                  Registros Totales: {data?.total || '0'}
                </Text>
              </Box>
              <Filters filters={filters} setFilters={setFilters} />
            </Flex>
          </GridItem>
          <GridItem
            overflowX={'auto'}
            display={{
              lg: 'table',
              md: 'break-word',
              sm: 'break-word'
            }}
            css={{
              '&::-webkit-scrollbar': {
                width: '4px'
              },
              '&::-webkit-scrollbar-track': {
                width: '4px'
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#2E7423',
                borderRadius: '30px'
              }
            }}
          >
            <Table headers={headers} totalRecords={0}>
              {data?.programs?.map((program) => (
                <Tr key={program._id}>
                  <Td color={'grey.500'}>
                    <Checkbox
                      css={{ borderColor: 'green' }}
                      colorScheme="green"
                      color={'green.500'}
                      borderColor={'green.500'}
                      size={'md'}
                      fontWeight={700}
                      isChecked={programsIds.includes(program._id)}
                      onChange={(e) =>
                        selectedProgramChangeCheckBox(e, program?._id)
                      }
                    />
                  </Td>
                  <Td color={'grey.500'} fontSize={'14px'}>
                    {program.partner.name}
                  </Td>
                  <Td fontWeight={700} color={'grey.500'} fontSize={'14px'}>
                    {program?.lastStep}
                  </Td>
                  <Td fontWeight={700} color={'grey.500'} fontSize={'14px'}>
                    {program?.currentStep}
                  </Td>
                  <Td>{GetStatusCard.programTable(program?.state)}</Td>
                </Tr>
              ))}
            </Table>
          </GridItem>
        </Grid>
      )}
    </>
  )
}

export default TableProgram
