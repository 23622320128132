import React from 'react'
import { Grid, GridItem, Flex, Text, Radio, Divider } from '@chakra-ui/react'

const RadioSelect = ({ formik, initialValues }) => {
  const handleChange = (e) => {
    if (e.target.value === 'NOT') {
      formik.setValues({
        ...initialValues,
        isNot: true,
        isYes: false,
        isRequiredDescription: false,
        isRequiredDocuments: true
      })
    }
    if (e.target.value === 'YES') {
      formik.setValues({
        ...initialValues,
        isNot: false,
        isYes: true,
        isRequiredDescription: false,
        isRequiredDocuments: false
      })
    }
  }

  return (
    <>
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={4}
        mx={20}
        mb={4}
      >
        <GridItem border={'2px solid #82B378'} borderRadius={8} p={4}>
          <Flex justify={'space-between'}>
            <Text color={'green.500'} fontWeight={700}>
              No, dejar solicitud en revisión.
            </Text>
            <Radio
              colorScheme="green"
              value="NOT"
              defaultChecked
              onChange={handleChange}
              isChecked={formik.values.isNot}
            />
          </Flex>
          <Divider py={2} />
          <Text>
            Se deberá contactar al socio vía correo electrónico para continuar
            el proceso.
          </Text>
        </GridItem>
        <GridItem border={'2px solid #82B378'} borderRadius={8} p={4}>
          <Flex justify={'space-between'}>
            <Text color={'green.500'} fontWeight={700}>
              Si, continuar proceso
            </Text>
            <Radio
              colorScheme="green"
              value="YES"
              onChange={handleChange}
              isChecked={formik.values.isYes}
            />
          </Flex>
          <Divider py={2} />
          <Text>
            Actualiza documentación y añade observaciones de ser necesario.
          </Text>
        </GridItem>
      </Grid>
    </>
  )
}

export default RadioSelect
