import React, { useEffect } from 'react'
import { addDays } from 'date-fns'
import { useFormik } from 'formik'
import { Flex, GridItem, Text, Grid, Checkbox, Tooltip } from '@chakra-ui/react'
import {
  InputComtrol,
  SquareButton,
  UploadFile,
  BarFileName,
  CustomDatePicker,
  CardStatus,
  Button,
  TextAreaComtrol
} from '../../../UI'
import { steepTwoSchema } from '../Schemas'
import { Files, GetDiffDates } from '../../../../Utils'

const NewBornForm = ({
  _id,
  onClick,
  childrens,
  children,
  receptionDate,
  handleUpdateChildren
}) => {
  const initialValues = {
    _id: '',
    name: '',
    babyDateBirth: '',
    birthCertificateName: [],
    birthCertificate: null,
    isSpecialRegister: false,
    backupDocumentsName: [],
    backupDocuments: null,
    observations: '',
    state: 'READY',
    receptionDate: ''
  }

  const formik = useFormik({
    initialValues,
    validateOnMount: false,
    validateOnChange: false,
    validationSchema: steepTwoSchema,
    onSubmit: (values) => {
      handleUpdateChildren(_id, values)
    }
  })

  const handleChangeDate = (date) => {
    if (date) {
      formik.setFieldValue('babyDateBirth', date)
    }
  }

  const deleteFile = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles: resultBirthCertificate } =
      getArrayNamesAndArrayFiles(
        fileName,
        formik.values.birthCertificateName,
        formik.values.birthCertificate
      )

    formik.setValues({
      ...formik.values,
      birthCertificateName: resultNames,
      birthCertificate: resultBirthCertificate
    })
  }

  const deleteBackupDocuments = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.backupDocumentsName,
      formik.values.backupDocuments
    )

    formik.setValues({
      ...formik.values,
      backupDocumentsName: resultNames,
      backupDocuments: resultListFiles
    })
  }

  const handleChangeBirthCertificate = (e) => {
    if (!e.currentTarget.files) {
      return
    }
    const { getListNamesAndListFiles } = Files

    const {
      newListNames: newBirthCertificateName,
      newListFiles: newBirthCertificate
    } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.birthCertificateName,
      formik.values.birthCertificate
    )

    formik.setValues({
      ...formik.values,
      birthCertificateName: newBirthCertificateName,
      birthCertificate: newBirthCertificate
    })
    e.target.value = null
  }

  const handleChangeBackupDocuments = (e) => {
    if (!e.currentTarget.files) {
      return
    }
    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.backupDocumentsName,
      formik.values.backupDocuments
    )

    formik.setValues({
      ...formik.values,
      backupDocumentsName: newListNames,
      backupDocuments: newListFiles
    })
    e.target.value = null
  }

  const isValidNewBornItem = () => {
    const isValid = Boolean(childrens.find((item) => item._id === _id).updated)
    const isEqualsDate = Boolean(
      childrens.find((item) => item._id === _id).babyDateBirth ===
        formik.values.babyDateBirth
    )
    const isEqualsCertificate = Boolean(
      childrens.find((item) => item._id === _id).birthCertificateName.length ===
        formik.values.birthCertificateName.length
    )
    const isEqualsSpecialRegister = Boolean(
      childrens.find((item) => item._id === _id).isSpecialRegister ===
        formik.values.isSpecialRegister
    )

    const isEqualsBackupDocuments = Boolean(
      childrens.find((item) => item._id === _id).backupDocumentsName.length ===
        formik.values.backupDocumentsName.length
    )

    const isEqualsObservations = Boolean(
      childrens.find((item) => item._id === _id).observations ===
        formik.values.observations
    )

    if (
      isValid &&
      isEqualsDate &&
      isEqualsCertificate &&
      isEqualsSpecialRegister &&
      isEqualsBackupDocuments &&
      isEqualsObservations
    ) {
      return true
    }
    return false
  }

  useEffect(() => {
    if (!formik.values.isSpecialRegister) {
      formik.setValues({
        ...formik.values,
        backupDocumentsName: [],
        backupDocuments: null,
        observations: ''
      })
    }
  }, [formik.values.isSpecialRegister])

  useEffect(() => {
    const setErrors = Object.keys(formik.errors).length
    if (setErrors > 0 && !formik.isSubmitting) {
      handleUpdateChildren(_id, {}, false)
    }
  }, [formik.isSubmitting])

  useEffect(() => {
    if (children) {
      formik.setValues({
        _id: children._id || '',
        name: children?.name || '',
        babyDateBirth: children.babyDateBirth || '',
        birthCertificateName: children.birthCertificateName || [],
        birthCertificate: children.birthCertificate || null,
        isSpecialRegister: children.isSpecialRegister || false,
        backupDocumentsName: children.backupDocumentsName || [],
        backupDocuments: children.backupDocuments || null,
        observations: children.observations || '',
        state: 'READY',
        receptionDate
      })
    }
  }, [children])

  return (
    <Grid
      id={_id}
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      border={'1px solid #2e7423'}
      bg={isValidNewBornItem() ? '#F0FFF4' : '#FFFFF0'}
      borderRadius={8}
      p={4}
      my={2}
      gap={2}
    >
      <Flex justify={'flex-end'}>
        <SquareButton size={'xs'} onClick={() => onClick(_id)}>
          x
        </SquareButton>
      </Flex>
      <GridItem>
        <GridItem>
          <InputComtrol
            id={'name'}
            label={'Nombre completo'}
            isRequired={true}
            value={formik.values.name}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.name)}
            errorText={formik.errors.name}
          />
        </GridItem>
        <GridItem>
          <Grid
            templateColumns={{
              lg: 'repeat(2, 1fr)',
              md: 'repeat(1, 1fr)',
              sm: 'repeat(1, 1fr)'
            }}
            gap={2}
          >
            <GridItem>
              <InputComtrol label={'Fecha del recién nacido'}>
                <CustomDatePicker
                  id={`babyDateBirth${_id}`}
                  placeholder={'Fecha del recién nacido'}
                  currentDate={formik.values.babyDateBirth}
                  error={Boolean(formik.errors.babyDateBirth)}
                  errorText={formik.errors.babyDateBirth}
                  onChange={(date) => handleChangeDate(date)}
                  maxDate={addDays(new Date(receptionDate), 0)}
                />
              </InputComtrol>
            </GridItem>
            <GridItem mt={'auto'} mb={'auto'}>
              <CardStatus
                subValue={'Nacido hace'}
                value={
                  formik.values.babyDateBirth
                    ? `Hace ${GetDiffDates(
                        formik.values.babyDateBirth,
                        'days',
                        receptionDate
                      )}`
                    : '-'
                }
              />
            </GridItem>
          </Grid>
        </GridItem>
        <GridItem textAlign={'center'}>
          <Text fontSize={'sm'} color={'red.500'}>
            La solicitud no debe superar los 90 días desde el nacimiento del
            Hijo(a)
          </Text>
        </GridItem>
        <GridItem>
          <Text fontSize={'md'} color={'green.500'}>
            Certificado de nacimiento
          </Text>
          <UploadFile
            id={`birthCertificateName${_id}`}
            direction={'row'}
            sizeIcon={30}
            p={5}
            onChange={(e) => handleChangeBirthCertificate(e)}
            error={Boolean(formik.errors.birthCertificateName)}
            errorText={formik.errors.birthCertificateName}
          />
          <Flex direction={'column'} gap={1}>
            {formik.values.birthCertificateName.map((item) => (
              <BarFileName
                key={item?.name}
                fileName={item?.name}
                fileUrl={item?.url}
                onDelete={() => deleteFile(item?.name)}
              />
            ))}
          </Flex>
        </GridItem>
      </GridItem>
      <GridItem>
        <Checkbox
          css={{ borderColor: 'green' }}
          id={'isSpecialRegister'}
          size="md"
          colorScheme="green"
          value={formik.values.isSpecialRegister}
          isChecked={formik.values.isSpecialRegister}
          onChange={formik.handleChange}
          py={2}
        >
          ¿Desea ingresar un registro extraordinario?
        </Checkbox>
        {formik.values.isSpecialRegister && (
          <>
            <Text fontSize={'md'} color={'green.500'}>
              Documentos respaldatorios
            </Text>
            <UploadFile
              id={`backupDocumentsName${_id}`}
              direction={'row'}
              sizeIcon={30}
              p={5}
              onChange={(e) => handleChangeBackupDocuments(e)}
              error={Boolean(formik.errors.backupDocumentsName)}
              errorText={formik.errors.backupDocumentsName}
            />
            <Flex direction={'column'} gap={1}>
              {formik.values.backupDocumentsName.map((item) => (
                <BarFileName
                  key={item?.name}
                  fileName={item?.name}
                  fileUrl={item?.url}
                  onDelete={() => deleteBackupDocuments(item?.name)}
                />
              ))}
            </Flex>
            <TextAreaComtrol
              isRequired={false}
              id={'observations'}
              label={'Observacion (opcional)'}
              value={formik.values.observations}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.observations)}
              errorText={formik.errors.observations}
            />
          </>
        )}
      </GridItem>
      <Flex justify={'flex-end'}>
        <Tooltip
          label="Favor Validar! ->"
          placement="left"
          bg={'#d68c49'}
          isOpen={!isValidNewBornItem()}
        >
          <Flex>
            <Button
              text={isValidNewBornItem() ? 'Validado' : 'No validado'}
              py={1}
              px={2}
              onClick={formik.handleSubmit}
              size={'xs'}
            />
          </Flex>
        </Tooltip>
      </Flex>
    </Grid>
  )
}

export default NewBornForm
