import { Grid, GridItem } from '@chakra-ui/react'
import React from 'react'
import { Files } from '../../../../Utils'
import { BarFileName, InputComtrol, UploadFile } from '../../../UI'

const DocumentsUniversityOrder = ({ formik }) => {
  const deleteBackupFeeAmount = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.backupFeeAmountName,
      formik.values.backupFeeAmount
    )

    formik.setValues({
      ...formik.values,
      backupFeeAmountName: resultNames,
      backupFeeAmount: resultListFiles
    })
  }

  const deleteScholarshipCommission = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.scholarshipCommissionName,
      formik.values.scholarshipCommission
    )

    formik.setValues({
      ...formik.values,
      scholarshipCommissionName: resultNames,
      scholarshipCommission: resultListFiles
    })
  }

  const deleteBankData = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.bankDataName,
      formik.values.bankData
    )

    formik.setValues({
      ...formik.values,
      bankDataName: resultNames,
      bankData: resultListFiles
    })
  }

  const handleChangeBackupFeeAmount = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.backupFeeAmountName,
      formik.values.backupFeeAmount
    )

    formik.setValues({
      ...formik.values,
      backupFeeAmountName: newListNames,
      backupFeeAmount: newListFiles
    })
    e.target.value = null
  }

  const handleChangeScholarshipCommission = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.scholarshipCommissionName,
      formik.values.scholarshipCommission
    )

    formik.setValues({
      ...formik.values,
      scholarshipCommissionName: newListNames,
      scholarshipCommission: newListFiles
    })
    e.target.value = null
  }

  const handleChangeBankData = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.bankDataName,
      formik.values.bankData
    )

    formik.setValues({
      ...formik.values,
      bankDataName: newListNames,
      bankData: newListFiles
    })
    e.target.value = null
  }

  return (
    <>
      <Grid templateColumns={'repeat(1, 1fr)'}>
        {formik.values.isRequiredBackupFeeAmount && (
          <GridItem>
            <InputComtrol
              label={'Añade correo de respaldo con el monto de arancel'}
            >
              <UploadFile
                id={'backupFeeAmountName'}
                direction={'row'}
                sizeIcon={30}
                p={5}
                onChange={(e) => handleChangeBackupFeeAmount(e)}
                error={Boolean(formik.errors.backupFeeAmountName)}
                errorText={formik.errors.backupFeeAmountName}
              />
            </InputComtrol>
            {formik.values.backupFeeAmountName.map((item) => (
              <BarFileName
                key={item?.name}
                fileName={item?.name}
                fileUrl={item?.url}
                onDelete={() => deleteBackupFeeAmount(item?.name)}
              />
            ))}
          </GridItem>
        )}
        {formik.values.isRequiredScholarshipCommission && (
          <GridItem>
            <InputComtrol label={'Añade acta de comisión de becas'}>
              <UploadFile
                id={'scholarshipCommissionName'}
                direction={'row'}
                sizeIcon={30}
                p={5}
                onChange={(e) => handleChangeScholarshipCommission(e)}
                error={Boolean(formik.errors.scholarshipCommissionName)}
                errorText={formik.errors.scholarshipCommissionName}
              />
            </InputComtrol>
            {formik.values.scholarshipCommissionName.map((item) => (
              <BarFileName
                key={item?.name}
                fileName={item?.name}
                fileUrl={item?.url}
                onDelete={() => deleteScholarshipCommission(item?.name)}
              />
            ))}
          </GridItem>
        )}
        {formik.values.isRequiredBankData && (
          <GridItem>
            <InputComtrol
              label={'Añade documento con los datos bancarios (Opcional)'}
              isRequired={false}
            >
              <UploadFile
                id={'bankDataName'}
                direction={'row'}
                sizeIcon={30}
                p={5}
                onChange={(e) => handleChangeBankData(e)}
                error={Boolean(formik.errors.bankDataName)}
                errorText={formik.errors.bankDataName}
              />
            </InputComtrol>
            {formik.values.bankDataName.map((item) => (
              <BarFileName
                key={item?.name}
                fileName={item?.name}
                fileUrl={item?.url}
                onDelete={() => deleteBankData(item?.name)}
              />
            ))}
          </GridItem>
        )}
      </Grid>
    </>
  )
}

export default DocumentsUniversityOrder
