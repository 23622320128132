import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import { UploadFile, BarFileName, InputComtrol } from '../../UI'
import { steepTwoSchema } from './Schemas'
import { Files } from '../../../Utils'

const StepTwo = ({ stepTwoForm, setStepTwoForm, refStepTwo, next }) => {
  const initialValues = {
    salarySettlementsName: [],
    salarySettlements: null,
    medicalCertificateName: [],
    medicalCertificate: null,
    institutionDocumentationName: [],
    institutionDocumentation: null,
    partnerPersonalRequestName: [],
    partnerPersonalRequest: null,
    prescriptionName: [],
    prescription: null,
    socialReportName: [],
    socialReport: null,
    quotationName: [],
    quotation: null,
    debtCertificateName: [],
    debtCertificate: null,
    authorizationToRequestDebtName: [],
    authorizationToRequestDebt: null
    // senderOfficeName: [],
    // senderOffice: null
  }

  const formik = useFormik({
    initialValues,
    validationSchema: steepTwoSchema,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: (values) => {
      setStepTwoForm({
        ...values
      })
      next()
    }
  })

  const deleteSalarySettlements = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.salarySettlementsName,
      formik.values.salarySettlements
    )

    formik.setValues({
      ...formik.values,
      salarySettlementsName: resultNames,
      salarySettlements: resultListFiles
    })
  }

  const handleChangeSalarySettlements = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.salarySettlementsName,
      formik.values.salarySettlements
    )

    formik.setValues({
      ...formik.values,
      salarySettlementsName: newListNames,
      salarySettlements: newListFiles
    })
    e.target.value = null
  }

  const deleteMedicalCertificate = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.medicalCertificateName,
      formik.values.medicalCertificate
    )

    formik.setValues({
      ...formik.values,
      medicalCertificateName: resultNames,
      medicalCertificate: resultListFiles
    })
  }

  const handleChangeMedicalCertificate = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.medicalCertificateName,
      formik.values.medicalCertificate
    )

    formik.setValues({
      ...formik.values,
      medicalCertificateName: newListNames,
      medicalCertificate: newListFiles
    })
    e.target.value = null
  }

  const deleteInstitutionDocumentation = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.institutionDocumentationName,
      formik.values.institutionDocumentation
    )

    formik.setValues({
      ...formik.values,
      institutionDocumentationName: resultNames,
      institutionDocumentation: resultListFiles
    })
  }

  const handleChangeInstitutionDocumentation = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.institutionDocumentationName,
      formik.values.institutionDocumentation
    )

    formik.setValues({
      ...formik.values,
      institutionDocumentationName: newListNames,
      institutionDocumentation: newListFiles
    })
    e.target.value = null
  }

  const deletePartnerPersonalRequest = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.partnerPersonalRequestName,
      formik.values.partnerPersonalRequest
    )

    formik.setValues({
      ...formik.values,
      partnerPersonalRequestName: resultNames,
      partnerPersonalRequest: resultListFiles
    })
  }

  const handleChangePartnerPersonalRequest = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.partnerPersonalRequestName,
      formik.values.partnerPersonalRequest
    )

    formik.setValues({
      ...formik.values,
      partnerPersonalRequestName: newListNames,
      partnerPersonalRequest: newListFiles
    })
    e.target.value = null
  }

  const deletePrescription = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.prescriptionName,
      formik.values.prescription
    )

    formik.setValues({
      ...formik.values,
      prescriptionName: resultNames,
      prescription: resultListFiles
    })
  }

  const handleChangePrescription = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.prescriptionName,
      formik.values.prescription
    )

    formik.setValues({
      ...formik.values,
      prescriptionName: newListNames,
      prescription: newListFiles
    })
    e.target.value = null
  }

  const deleteSocialReport = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.socialReportName,
      formik.values.socialReport
    )

    formik.setValues({
      ...formik.values,
      socialReportName: resultNames,
      socialReport: resultListFiles
    })
  }

  const handleChangeSocialReport = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.socialReportName,
      formik.values.socialReport
    )

    formik.setValues({
      ...formik.values,
      socialReportName: newListNames,
      socialReport: newListFiles
    })
    e.target.value = null
  }

  const deleteQuotation = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.quotationName,
      formik.values.quotation
    )

    formik.setValues({
      ...formik.values,
      quotationName: resultNames,
      quotation: resultListFiles
    })
  }

  const handleChangeQuotation = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.quotationName,
      formik.values.quotation
    )

    formik.setValues({
      ...formik.values,
      quotationName: newListNames,
      quotation: newListFiles
    })
    e.target.value = null
  }

  const deleteDebtCertificate = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.debtCertificateName,
      formik.values.debtCertificate
    )

    formik.setValues({
      ...formik.values,
      debtCertificateName: resultNames,
      debtCertificate: resultListFiles
    })
  }

  const handleChangeDebtCertificate = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.debtCertificateName,
      formik.values.debtCertificate
    )

    formik.setValues({
      ...formik.values,
      debtCertificateName: newListNames,
      debtCertificate: newListFiles
    })
    e.target.value = null
  }

  const deleteAuthorizationToRequestDebt = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.authorizationToRequestDebtName,
      formik.values.authorizationToRequestDebt
    )

    formik.setValues({
      ...formik.values,
      authorizationToRequestDebtName: resultNames,
      authorizationToRequestDebt: resultListFiles
    })
  }

  const handleChangeAuthorizationToRequestDebt = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.authorizationToRequestDebtName,
      formik.values.authorizationToRequestDebt
    )

    formik.setValues({
      ...formik.values,
      authorizationToRequestDebtName: newListNames,
      authorizationToRequestDebt: newListFiles
    })
    e.target.value = null
  }

  // const deleteSenderOffice = (fileName) => {
  //   const { getArrayNamesAndArrayFiles } = Files

  //   const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
  //     fileName,
  //     formik.values.senderOfficeName,
  //     formik.values.senderOffice
  //   )

  //   formik.setValues({
  //     ...formik.values,
  //     senderOfficeName: resultNames,
  //     senderOffice: resultListFiles
  //   })
  // }

  // const handleChangeSenderOffice = (e) => {
  //   if (!e.currentTarget.files) {
  //     return
  //   }

  //   const { getListNamesAndListFiles } = Files

  //   const { newListNames, newListFiles } = getListNamesAndListFiles(
  //     e.currentTarget,
  //     formik.values.senderOfficeName,
  //     formik.values.senderOffice
  //   )

  //   formik.setValues({
  //     ...formik.values,
  //     senderOfficeName: newListNames,
  //     senderOffice: newListFiles
  //   })
  //   e.target.value = null
  // }

  useEffect(() => {
    refStepTwo.current = formik.handleSubmit
  }, [])

  useEffect(() => {
    formik.setValues({
      salarySettlementsName: stepTwoForm.salarySettlementsName || [],
      salarySettlements: stepTwoForm.salarySettlements || null,
      medicalCertificateName: stepTwoForm.medicalCertificateName || [],
      medicalCertificate: stepTwoForm.medicalCertificate || null,
      institutionDocumentationName:
        stepTwoForm.institutionDocumentationName || [],
      institutionDocumentation: stepTwoForm.institutionDocumentation || null,
      partnerPersonalRequestName: stepTwoForm.partnerPersonalRequestName || [],
      partnerPersonalRequest: stepTwoForm.partnerPersonalRequest || null,
      prescriptionName: stepTwoForm.prescriptionName || [],
      prescription: stepTwoForm.prescription || null,
      socialReportName: stepTwoForm.socialReportName || [],
      socialReport: stepTwoForm.socialReport || null,
      quotationName: stepTwoForm.quotationName || [],
      quotation: stepTwoForm.quotation || null,
      debtCertificateName: stepTwoForm.debtCertificateName || [],
      debtCertificate: stepTwoForm.debtCertificate || null,
      authorizationToRequestDebtName:
        stepTwoForm.authorizationToRequestDebtName || [],
      authorizationToRequestDebt: stepTwoForm.authorizationToRequestDebt || null
      // senderOfficeName: stepTwoForm.senderOfficeName || [],
      // senderOffice: stepTwoForm.senderOffice || null
    })
  }, [stepTwoForm])

  return (
    <>
      <Grid
        templateColumns={{
          lg: 'repeat(1, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          <Text fontWeight={700} color={'green.500'} fontSize={'xl'}>
            Documentación y observaciones
          </Text>
          <InputComtrol label={'Últimas 3 liquidaciones de sueldo'}>
            <UploadFile
              direction={'row'}
              sizeIcon={30}
              p={5}
              onChange={(e) => {
                handleChangeSalarySettlements(e)
              }}
              id={'salarySettlementsName'}
              error={Boolean(formik.errors.salarySettlementsName)}
              errorText={formik.errors.salarySettlementsName}
            />
            <Flex direction={'column'} gap={1}>
              {formik.values.salarySettlementsName.map((item) => (
                <BarFileName
                  key={item?.name}
                  fileName={item?.name}
                  fileUrl={item?.url}
                  onDelete={() => deleteSalarySettlements(item?.name)}
                />
              ))}
            </Flex>
          </InputComtrol>
        </GridItem>
      </Grid>
      <Grid
        templateColumns={{
          lg: 'repeat(3, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          <InputComtrol
            label={'Certificado médico o epicrisis'}
            isRequired={false}
          >
            <UploadFile
              direction={'row'}
              sizeIcon={30}
              p={5}
              onChange={(e) => {
                handleChangeMedicalCertificate(e)
              }}
              id={'medicalCertificateName'}
              error={Boolean(formik.errors.medicalCertificateName)}
              errorText={formik.errors.medicalCertificateName}
            />
            <Flex direction={'column'} gap={1}>
              {formik.values.medicalCertificateName.map((item) => (
                <BarFileName
                  key={item?.name}
                  fileName={item?.name}
                  fileUrl={item?.url}
                  onDelete={() => deleteMedicalCertificate(item?.name)}
                />
              ))}
            </Flex>
          </InputComtrol>
        </GridItem>
        <GridItem>
          <InputComtrol
            label={'Documentación de institución'}
            isRequired={false}
          >
            <UploadFile
              direction={'row'}
              sizeIcon={30}
              p={5}
              onChange={(e) => {
                handleChangeInstitutionDocumentation(e)
              }}
              id={'institutionDocumentationName'}
              error={Boolean(formik.errors.institutionDocumentationName)}
              errorText={formik.errors.institutionDocumentationName}
            />
            <Flex direction={'column'} gap={1}>
              {formik.values.institutionDocumentationName.map((item) => (
                <BarFileName
                  key={item?.name}
                  fileName={item?.name}
                  fileUrl={item?.url}
                  onDelete={() => deleteInstitutionDocumentation(item?.name)}
                />
              ))}
            </Flex>
          </InputComtrol>
        </GridItem>
        <GridItem>
          <InputComtrol label={'Solicitud personal del socio(a)'}>
            <UploadFile
              direction={'row'}
              sizeIcon={30}
              p={5}
              onChange={(e) => {
                handleChangePartnerPersonalRequest(e)
              }}
              id={'partnerPersonalRequestName'}
              error={Boolean(formik.errors.partnerPersonalRequestName)}
              errorText={formik.errors.partnerPersonalRequestName}
            />
            <Flex direction={'column'} gap={1}>
              {formik.values.partnerPersonalRequestName.map((item) => (
                <BarFileName
                  key={item?.name}
                  fileName={item?.name}
                  fileUrl={item?.url}
                  onDelete={() => deletePartnerPersonalRequest(item?.name)}
                />
              ))}
            </Flex>
          </InputComtrol>
        </GridItem>
        <GridItem>
          <InputComtrol label={'Receta médica'} isRequired={false}>
            <UploadFile
              direction={'row'}
              sizeIcon={30}
              p={5}
              onChange={(e) => {
                handleChangePrescription(e)
              }}
              id={'prescriptionName'}
              error={Boolean(formik.errors.prescriptionName)}
              errorText={formik.errors.prescriptionName}
            />
            <Flex direction={'column'} gap={1}>
              {formik.values.prescriptionName.map((item) => (
                <BarFileName
                  key={item?.name}
                  fileName={item?.name}
                  fileUrl={item?.url}
                  onDelete={() => deletePrescription(item?.name)}
                />
              ))}
            </Flex>
          </InputComtrol>
        </GridItem>
        <GridItem>
          <InputComtrol label={'Informe social'}>
            <UploadFile
              direction={'row'}
              sizeIcon={30}
              p={5}
              onChange={(e) => {
                handleChangeSocialReport(e)
              }}
              id={'socialReportName'}
              error={Boolean(formik.errors.socialReportName)}
              errorText={formik.errors.socialReportName}
            />
            <Flex direction={'column'} gap={1}>
              {formik.values.socialReportName.map((item) => (
                <BarFileName
                  key={item?.name}
                  fileName={item?.name}
                  fileUrl={item?.url}
                  onDelete={() => deleteSocialReport(item?.name)}
                />
              ))}
            </Flex>
          </InputComtrol>
        </GridItem>
        <GridItem>
          <InputComtrol label={'Cotización'} isRequired={false}>
            <UploadFile
              direction={'row'}
              sizeIcon={30}
              p={5}
              onChange={(e) => {
                handleChangeQuotation(e)
              }}
              id={'quotationName'}
              error={Boolean(formik.errors.quotationName)}
              errorText={formik.errors.quotationName}
            />
            <Flex direction={'column'} gap={1}>
              {formik.values.quotationName.map((item) => (
                <BarFileName
                  key={item?.name}
                  fileName={item?.name}
                  fileUrl={item?.url}
                  onDelete={() => deleteQuotation(item?.name)}
                />
              ))}
            </Flex>
          </InputComtrol>
        </GridItem>
        <GridItem>
          <InputComtrol
            label={
              'Certificado de deuda o historial informe detalle de prestaciones médicas'
            }
            isRequired={false}
          >
            <UploadFile
              direction={'row'}
              sizeIcon={30}
              p={5}
              onChange={(e) => {
                handleChangeDebtCertificate(e)
              }}
              id={'debtCertificateName'}
              error={Boolean(formik.errors.debtCertificateName)}
              errorText={formik.errors.debtCertificateName}
            />
            <Flex direction={'column'} gap={1}>
              {formik.values.debtCertificateName.map((item) => (
                <BarFileName
                  key={item?.name}
                  fileName={item?.name}
                  fileUrl={item?.url}
                  onDelete={() => deleteDebtCertificate(item?.name)}
                />
              ))}
            </Flex>
          </InputComtrol>
        </GridItem>
        <GridItem>
          <Text fontWeight={700} opacity={0} fontSize={'l'}>
            ---
          </Text>
          <InputComtrol
            label={'Autorización para solicitar deuda a Dipreca'}
            isRequired={false}
          >
            <UploadFile
              direction={'row'}
              sizeIcon={30}
              p={5}
              onChange={(e) => {
                handleChangeAuthorizationToRequestDebt(e)
              }}
              id={'authorizationToRequestDebtName'}
              error={Boolean(formik.errors.authorizationToRequestDebtName)}
              errorText={formik.errors.authorizationToRequestDebtName}
            />
            <Flex direction={'column'} gap={1}>
              {formik.values.authorizationToRequestDebtName.map((item) => (
                <BarFileName
                  key={item?.name}
                  fileName={item?.name}
                  fileUrl={item?.url}
                  onDelete={() => deleteAuthorizationToRequestDebt(item?.name)}
                />
              ))}
            </Flex>
          </InputComtrol>
        </GridItem>
        {/* <GridItem>
          <Text fontWeight={700} opacity={0} fontSize={'l'}>
            ---
          </Text>
          <InputComtrol label={'Oficio remisor'}>
            <UploadFile
              direction={'row'}
              sizeIcon={30}
              p={5}
              onChange={(e) => {
                handleChangeSenderOffice(e)
              }}
              id={'senderOfficeName'}
              error={Boolean(formik.errors.senderOfficeName)}
              errorText={formik.errors.senderOfficeName}
            />
            <Flex direction={'column'} gap={1}>
              {formik.values.senderOfficeName.map((item) => (
                <BarFileName
                  key={item?.name}
                  fileName={item?.name}
                  fileUrl={item?.url}
                  onDelete={() => deleteSenderOffice(item?.name)}
                />
              ))}
            </Flex>
          </InputComtrol>
        </GridItem> */}
      </Grid>
    </>
  )
}

export default StepTwo
