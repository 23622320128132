import { Skeleton, Grid } from '@chakra-ui/react'
import React from 'react'

const ResolutionOfRequestsSkeleton = () => (
  <Grid
    templateColumns={{
      lg: 'repeat(2, 70% 30%)',
      md: 'repeat(1, 100%)',
      sm: 'repeat(1, 100%)'
    }}
    h={{
      lg: '400px',
      md: '400px',
      sm: '400px'
    }}
    gap={2}
  >
    <Skeleton height="400px" borderRadius={12} />
    <Skeleton height="400px" borderRadius={12} />
  </Grid>
)

export default ResolutionOfRequestsSkeleton
