import { Flex } from '@chakra-ui/react'
import React from 'react'

const StepItem = ({ title }) => (
  <Flex border={'1px solid #82B378'} borderRadius={18} py={6} px={3}>
    {title}
  </Flex>
)

export default StepItem
