import { useEffect, useState } from 'react'
import { Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import ApplicantItem from './ApplicantItem'
import { ButtonDotted, Select2 } from '../../../UI'
import { Age, RelationshipList3 } from '../../../../Utils'
import { useFamilyMembers } from '../../../../hooks/FamilyMembers'

const Applicants = ({ formik }) => {
  const { data, isLoading } = useFamilyMembers(formik.values?.partnerId)
  const [familySelected, setFamilySelected] = useState(null)
  const [familyPartner, setFamilyPartner] = useState([])
  const add = () => {
    const newItem = {
      applicantId: Math.random().toString(),
      applicantFullName: '',
      applicantAge: Age.at(0),
      applicantRelationship: RelationshipList3.at(0),
      applicantRelationshipSpecification: '',
      diagnosis: '',
      toCreate: true
    }
    const applicantsCopy = formik.values.applicants

    if (familySelected !== 'NEW') {
      const find = data?.find((item) => item._id === familySelected)
      const addInfo = {
        ...newItem,
        applicantId: find?._id || newItem.applicantId,
        applicantFullName: find?.fullName || newItem.applicantFullName,
        applicantAge: find?.age || newItem.applicantAge,
        applicantRelationship:
          find?.relationship || newItem.applicantRelationship,
        applicantRelationshipSpecification:
          find?.applicantRelationshipSpecification ||
          newItem.applicantRelationshipSpecification,
        diagnosis: find?.diagnosis || newItem.diagnosis,
        toCreate: find ? false : newItem.toCreate
      }
      applicantsCopy.push(addInfo)
      return formik.setFieldValue('applicants', applicantsCopy)
    }

    applicantsCopy.push(newItem)
    return formik.setFieldValue('applicants', applicantsCopy)
  }

  const validAddNewItem = () => {
    const result = formik.values?.applicants?.some(
      (item) => item.applicantId === familySelected
    )

    return result
  }

  useEffect(() => {
    if (data) {
      const formatItems = data?.map((item) => ({
        key: item?._id,
        name: `${item?.fullName} - ${item?.relationship}`
      }))
      setFamilyPartner(formatItems)
    }
  }, [data])

  return (
    <>
      <Grid
        templateColumns={{
          lg: 'repeat(1, 1fr)'
        }}
        gap={2}
        mb={4}
      >
        <GridItem>
          <Text fontWeight={700} color={'green.500'} fontSize={'xl'}>
            Información de los solicitantes
          </Text>
        </GridItem>
        <GridItem>
          <Flex gap={2}>
            <Select2
              list={[...familyPartner, { key: 'NEW', name: 'Agregar Nuevo' }]}
              onChange={(key) => setFamilySelected(key)}
              disabled={isLoading}
              isLoading={isLoading}
            />

            <ButtonDotted
              text={'Añadir beneficiario'}
              onClick={add}
              p={2}
              disabled={validAddNewItem()}
            />
          </Flex>
        </GridItem>

        {formik.values?.applicants?.map((applicant, index) => (
          <GridItem>
            <ApplicantItem
              key={applicant?.applicantId}
              applicant={applicant}
              formik={formik}
              nro={index + 1}
            />
          </GridItem>
        ))}
      </Grid>
    </>
  )
}

export default Applicants
