import React from 'react'
import { Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import { CardStatus } from '../../UI'
import Form from './Form'

const WorkshopsAndTraining = ({ program }) => (
  <Grid
    templateColumns={{
      lg: 'repeat(1, 1fr)',
      md: 'repeat(1, 1fr)',
      sm: 'repeat(1, 1fr)'
    }}
    gap={2}
    px={6}
  >
    <GridItem>
      <Flex direction={'column'} gap={2}>
        <Text fontWeight={700} fontSize={'3xl'} color={'green.500'}>
          Solicitud de Beneficio
        </Text>
        <Flex gap={2} direction={'column'}>
          <CardStatus value={'Talleres y Capacitaciones'} opacity={1} />
        </Flex>
      </Flex>
    </GridItem>
    <GridItem>
      <Form program={program} />
    </GridItem>
  </Grid>
)

export default WorkshopsAndTraining
