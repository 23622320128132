import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import { Grid, GridItem, Text, Flex, useDisclosure } from '@chakra-ui/react'
import { Button } from '../../../UI'
import ModalHistory from '../../ModalHistory'
import PartnerInformation from './PartnerInformation'
import RadioSelect from './RadioSelect'
import CommentForm from './CommentForm'
import { schema } from './Schema'
import { GetFormData, GetKey, GetNotification } from '../../../../Utils'
import { useMutateHistoryProgram } from '../../../../hooks/HistoryProgram'

const textLeftButton = {
  1: 'Cancelar',
  2: 'Regresar'
}

const textRightButton = {
  1: 'Continuar',
  2: 'Aceptar'
}

const titleModal = {
  1: '¿Socio fue contactado con éxito?',
  2: 'Si, continuar proceso',
  3: 'No, dejar solicitud en revisión'
}

const ContactPartner = ({ currentStep, getColor, program }) => {
  const { mutate, isLoading, reset } =
    useMutateHistoryProgram('OTHER-PROCESSES')
  const [flowStep, setFlowStep] = useState(1)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const initialValues = {
    isYes: false,
    isNot: true,
    isRequiredDescription: false,
    descriptions: '',
    isRequiredDocuments: true,
    documentsNames: [],
    documents: [],
    state: program?.state,
    program: program?._id,
    stepProgram: currentStep?.step,
    keyHistory:
      GetKey.getNextStep(currentStep?.key, program?.type) ||
      GetKey.PostChangeStatus[program.type] /* es el siguiente paso */
  }

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    validateOnChange: false,
    onSubmit: (values) => {
      const formData = GetFormData(values, ['documents'])
      mutate(formData, {
        onSuccess: () => {
          reset()
          formik.resetForm()
          onClose()
          GetNotification.basic(
            values.isYes
              ? 'Socio contactado'
              : 'No fue posible contactar al socio',
            'Aceptar'
          )
        },
        onError: (err) => {
          console.log(err)
          GetNotification.basic(
            'Error',
            'Aceptar',
            'Error al registrar',
            'error'
          )
        }
      })
    }
  })

  const NextModalStep = () => {
    if (flowStep === 1) {
      setFlowStep(flowStep + 1)
    }

    if (flowStep === 2) {
      formik.handleSubmit()
    }

    if (flowStep < 2) {
      setFlowStep(flowStep + 1)
    }
  }

  const backModalStep = () => {
    if (flowStep === 1) {
      onClose()
    }

    if (flowStep > 1) {
      setFlowStep(flowStep - 1)
    }
  }

  const renderTitleModal = () => {
    if (flowStep === 1) {
      return titleModal[flowStep]
    }
    if (flowStep === 2 && formik.values.isYes) {
      return titleModal[flowStep]
    }
    if (flowStep === 2 && formik.values.isNot) {
      return titleModal[flowStep + 1]
    }
    return ''
  }

  useEffect(() => {
    if (isOpen) {
      setFlowStep(1)

      // formik.setValues({
      //   ...initialValues,
      //   keyHistory:
      //     GetKey.getNextStep(`NOT-${currentStep?.key}`, program?.type) ||
      //     GetKey.PostChangeStatus[program.type] /* es el siguiente paso */
      // })
    }
  }, [isOpen])

  return (
    <ModalHistory
      title={'Notificar por vía telefónica'}
      colors={getColor}
      disable={currentStep?.complete || isLoading}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      size={'4xl'}
      showButtons={false}
      isLoading={isLoading}
    >
      <Grid
        templateColumns={{
          lg: 'repeat(1, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={4}
        mx={20}
        mb={4}
      >
        <GridItem p={0}>
          <Flex w={'100%'} align={'center'} gap={2}>
            <Flex direction={'column'} align={'center'} w={'100%'}>
              <Text fontSize="lg">
                Verifica los detalles de la solicitud y solicita documentación
                faltante
              </Text>
            </Flex>
          </Flex>
        </GridItem>
      </Grid>
      {flowStep === 1 && (
        <>
          <PartnerInformation program={program} />
          <Grid
            templateColumns={{
              lg: 'repeat(1, 1fr)',
              md: 'repeat(1, 1fr)',
              sm: 'repeat(1, 1fr)'
            }}
            gap={4}
            mx={20}
            mb={4}
          >
            <GridItem p={4}>
              <Flex w={'100%'} align={'center'} gap={2}>
                <Flex direction={'column'} align={'center'} w={'100%'}>
                  <Text fontSize="2xl" color={'green.500'}>
                    {renderTitleModal()}
                  </Text>
                </Flex>
              </Flex>
            </GridItem>
          </Grid>
          <RadioSelect formik={formik} initialValues={initialValues} />
        </>
      )}
      {flowStep > 1 && (
        <>
          <Grid
            templateColumns={{
              lg: 'repeat(1, 1fr)',
              md: 'repeat(1, 1fr)',
              sm: 'repeat(1, 1fr)'
            }}
            gap={4}
            mx={20}
            mb={4}
          >
            <GridItem p={4}>
              <Flex w={'100%'} align={'center'} gap={2}>
                <Flex direction={'column'} align={'center'} w={'100%'}>
                  <Text fontSize="2xl" color={'green.500'}>
                    {renderTitleModal()}
                  </Text>
                </Flex>
              </Flex>
            </GridItem>
          </Grid>
          <CommentForm formik={formik} program={program} />
        </>
      )}
      {/* --- */}
      <Flex align={'center'} justify={'center'} gap={2}>
        <Button
          text={textLeftButton[flowStep]}
          bg={'beige.500'}
          color={'green.500'}
          bghover={'beige.600'}
          onClick={backModalStep}
          disabled={isLoading}
        />
        <Button
          text={textRightButton[flowStep]}
          bg={'green.500'}
          onClick={NextModalStep}
          isLoading={isLoading}
        />
      </Flex>
    </ModalHistory>
  )
}

export default ContactPartner
