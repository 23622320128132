import React from 'react'
import { Flex, Checkbox } from '@chakra-ui/react'
import { InputComtrol } from '../../UI'
import { FormatDate } from '../../../Utils'

const RenderAdditionalCheck = ({ program, data }) => {
  const typeProgramAllowed = [
    'UniversityScholarships',
    'TechnicalProfessionalScholarships',
    'SupportInCaseOfDeathOfMember',
    'FireSupport',
    'DisasterSupport'
  ]
  return (
    <>
      {typeProgramAllowed.includes(program?.type) &&
        program.state !== 'INREVIEW' && (
          <Flex
            direction={'column'}
            gap={2}
            border={'1px solid #82b378'}
            borderRadius={8}
            my={2}
            p={2}
          >
            {(program?.completedSemesters === 0 ||
              !program?.completedSemesters) && (
              <Checkbox
                css={{ borderColor: 'green' }}
                size="md"
                colorScheme="green"
                isChecked={data?.delegateZone}
                readOnly={true}
              >
                ¿Ha sido informado la delegada zonal?
              </Checkbox>
            )}
            {data?.delegateZone && (
              <InputComtrol
                label={'Fecha de Evento'}
                value={
                  data?.delegateZoneDate
                    ? FormatDate(data?.delegateZoneDate)
                    : 'Fecha no proporcionada'
                }
                isRequired={false}
                readOnly={true}
              />
            )}

            {(program?.completedSemesters === 0 ||
              !program?.completedSemesters) && (
              <Checkbox
                css={{ borderColor: 'green' }}
                size="md"
                colorScheme="green"
                isChecked={data?.socialAssistant}
                readOnly={true}
              >
                ¿Ha sido informado la asistente social?
              </Checkbox>
            )}

            {program?.completedSemesters > 0 && (
              <Checkbox
                css={{ borderColor: 'green' }}
                size="md"
                colorScheme="green"
                isChecked={data?.continueStudies}
                readOnly={true}
              >
                ¿Tendrá Continuidad de Estudios?
              </Checkbox>
            )}

            {data?.socialAssistant && (
              <InputComtrol
                label={'Fecha de Evento'}
                value={
                  data?.socialAssistantDate
                    ? FormatDate(data?.socialAssistantDate)
                    : 'Fecha no proporcionada'
                }
                isRequired={false}
                readOnly={true}
              />
            )}
          </Flex>
        )}
    </>
  )
}

export default RenderAdditionalCheck
