import { Grid, GridItem, Text } from '@chakra-ui/react'
import RenderInfoItem from './RenderInfoItem'
import { NODATA } from '../../../../constans'

const PROGRAM_DESC = 'Información relevante del beneficio'

const SupportInCaseOfDeathOfMember = ({ currentProgram = null }) => (
  <Grid templateAreas="repeat(1,1fr)" gap={2} textAlign="center">
    <GridItem borderBottom="1px solid" borderColor="green.500" mb={2}>
      <Text fontSize="lg" fontWeight={500}>
        {PROGRAM_DESC}:
      </Text>
    </GridItem>

    {currentProgram?.beneficiaries?.length > 0 ? (
      <GridItem>
        <Text>Listado de beneficiarios:</Text>
      </GridItem>
    ) : (
      <GridItem>
        <Text>{NODATA}</Text>
      </GridItem>
    )}
    {currentProgram?.beneficiaries?.map((item, index) => (
      <GridItem
        key={index}
        border="1px solid"
        borderRadius={8}
        p={2}
        borderColor="green.500"
      >
        <RenderInfoItem label={`Nombre`} value={item?.fullName} />
        <RenderInfoItem label={`Rut`} value={item?.rut} />
        <RenderInfoItem label={`Relación`} value={item?.relationship} />
      </GridItem>
    ))}
  </Grid>
)

export default SupportInCaseOfDeathOfMember
