import React, { useState, useEffect } from 'react'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Icon,
  Text
} from '@chakra-ui/react'
import { Search, SquareButton, Select } from '../UI'
import { ReplayIcon } from '../Icons'
import { Months, Years } from '../../Utils'

const FiltersPartnersSync = ({
  filtersPartnersSync,
  setFiltersPartnersSync,
  initialFiltersPartnersSync,
  children
}) => {
  const [monthsList, setMonthsList] = useState([])
  const [yearsList, setYearsList] = useState([])
  const handleChangeSearch = (e) => {
    setFiltersPartnersSync({
      ...filtersPartnersSync,
      search: e.target.value
    })
  }

  const handleChangeMonth = (month) => {
    setFiltersPartnersSync({
      ...filtersPartnersSync,
      month
    })
  }

  const handleChangeYear = (year) => {
    setFiltersPartnersSync({
      ...filtersPartnersSync,
      year
    })
  }

  const resetFilters = () => {
    setFiltersPartnersSync(initialFiltersPartnersSync)
  }

  useEffect(() => {
    const all_months = ['Todos los meses']
    Months.getArrayNamesMonths().forEach((month) => {
      all_months.push(month)
    })
    setMonthsList(all_months)

    const all_years = ['Todos los años']
    Years.getYears().forEach((year) => {
      all_years.push(year.toString())
    })
    setYearsList(all_years)
  }, [])

  return (
    <Flex gap={4} direction={'column'}>
      <Flex gap={3} justify={'space-between'} align={'center'}>
        <Box>
          <Search
            onChange={handleChangeSearch}
            currentValue={filtersPartnersSync.search}
            showBtn={false}
          />
        </Box>

        {children}
      </Flex>
      <Accordion allowMultiple>
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              <Text whiteSpace={'nowrap'} fontWeight={700} color={'green.500'}>
                Filtros:
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <Flex gap={3} direction={'column'}>
              <Flex
                gap={1}
                align={'center'}
                justify={'space-between'}
                direction={{
                  lg: 'row',
                  md: 'column',
                  sm: 'column'
                }}
              >
                <Flex>
                  <Box
                    w={{
                      lg: '100%',
                      md: '100%',
                      sm: '100%'
                    }}
                  >
                    <Text whiteSpace={'nowrap'} fontWeight={700}>
                      Año:
                    </Text>
                    <Select
                      list={yearsList}
                      onChange={handleChangeYear}
                      value={filtersPartnersSync?.year}
                    />
                  </Box>
                </Flex>
                <Flex>
                  <Box
                    w={{
                      lg: '100%',
                      md: '100%',
                      sm: '100%'
                    }}
                  >
                    <Text whiteSpace={'nowrap'} fontWeight={700}>
                      Mes:
                    </Text>
                    <Select
                      list={monthsList}
                      onChange={handleChangeMonth}
                      value={filtersPartnersSync?.month}
                    />
                  </Box>
                </Flex>
                <Box
                  marginRight={{
                    lg: 0,
                    md: 'auto',
                    sm: 'auto'
                  }}
                >
                  <SquareButton onClick={resetFilters}>
                    <Icon as={ReplayIcon} />
                  </SquareButton>
                </Box>
              </Flex>
            </Flex>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Flex>
  )
}

export default FiltersPartnersSync
