import React, { useEffect } from 'react'
import { addDays } from 'date-fns'
import { useFormik } from 'formik'
import { Flex, Grid, GridItem, Text, Radio, Checkbox } from '@chakra-ui/react'
import {
  UploadFile,
  BarFileName,
  InputComtrol,
  CustomDatePicker,
  Select,
  TextAreaComtrol
} from '../../UI'
import {
  GradesCarabineros,
  ZonesList,
  ZonesTwoList,
  Files,
  FormatRut,
  FormatCode
} from '../../../Utils'
import { steepOneSchema } from './Schemas'

const StepOne = ({ stepOneForm, setStepOneForm, refStepOne, state }) => {
  const initialValues = {
    alternativePhone: '',
    isPartnerZone: false,
    zone: stepOneForm.partnerZone || ZonesTwoList.getRegions().at(0),
    prefecture:
      stepOneForm.partnerPrefecture ||
      ZonesTwoList.getCommunes(ZonesTwoList.getRegions().at(0)).at(0),
    unit: stepOneForm.partnerEndowment || '',
    receptionDate: '',
    deliveryDate: '',
    region: ZonesList.getRegions().at(0),
    typeKit: '',
    observations: '',
    documentsName: [],
    documents: null,
    /* partner field */
    partnerId: '',
    partnerRut: '',
    partnerPhone: '',
    partnerGrade: GradesCarabineros.at(0),
    partnerCode: '',
    partnerZone: stepOneForm.partnerZone || ZonesTwoList.getRegions().at(0),
    partnerPrefecture:
      stepOneForm.partnerPrefecture ||
      ZonesTwoList.getCommunes(ZonesTwoList.getRegions().at(0)).at(0),
    partnerEndowment: stepOneForm.partnerEndowment || ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: steepOneSchema,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: (values) => {
      setStepOneForm({
        ...values,
        state
      })
    }
  })

  const hadleChangeZone = (value) => {
    formik.setFieldValue('zone', value)
    formik.setFieldValue('prefecture', ZonesTwoList.getCommunes(value).at(0))
  }

  const handleChangePrefecture = (value) => {
    formik.setFieldValue('prefecture', value)
  }

  const handleChangeDocumentsReceptionDate = (value) => {
    formik.setFieldValue('receptionDate', value)
  }

  const handleChangeDeliveryDate = (value) => {
    formik.setFieldValue('deliveryDate', value)
  }

  const handleChangeRegion = (value) => {
    formik.setFieldValue('region', value)
  }

  const handleChangeType = (e) => {
    formik.setFieldValue('typeKit', e.target.value)
  }

  const handleChangeDocuments = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.documentsName,
      formik.values.documents
    )

    formik.setValues({
      ...formik.values,
      documentsName: newListNames,
      documents: newListFiles
    })
    e.target.value = null
  }

  const deleteDocuments = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.documentsName,
      formik.values.documents
    )

    formik.setValues({
      ...formik.values,
      documentsName: resultNames,
      documents: resultListFiles
    })
  }

  const hadleChangePartnerZone = (value) => {
    formik.setFieldValue('partnerZone', value)
    formik.setFieldValue(
      'partnerPrefecture',
      ZonesTwoList.getCommunes(value).at(0)
    )
  }

  const handleChangePartnerPrefecture = (value) => {
    formik.setFieldValue('partnerPrefecture', value)
  }

  const handleChangePartnerGrade = (value) => {
    formik.setFieldValue('partnerGrade', value)
  }

  const handleChangeRut = () => {
    if (formik.values.partnerRut) {
      const formatedRut = FormatRut(formik.values.partnerRut)
      formik.setFieldValue('partnerRut', formatedRut)
    }
  }

  const handleChangeIsPartnerZone = (e) => {
    const isChecked = e.target.checked

    if (isChecked) {
      formik.setValues({
        ...formik.values,
        isPartnerZone: isChecked,
        zone: formik.values.partnerZone,
        prefecture: formik.values.partnerPrefecture,
        unit: formik.values.partnerEndowment
      })
    } else {
      formik.setValues({
        ...formik.values,
        isPartnerZone: isChecked
      })
    }
  }

  const formatCode = () => {
    if (formik.values.partnerCode) {
      const formatedCode = FormatCode(formik.values.partnerCode)
      formik.setFieldValue('partnerCode', formatedCode)
    }
  }

  useEffect(() => {
    refStepOne.current = formik.handleSubmit
  }, [])

  useEffect(() => {
    formik.setValues({
      alternativePhone: stepOneForm.alternativePhone || '',
      isPartnerZone: stepOneForm.isPartnerZone || false,
      zone:
        stepOneForm.zone ||
        stepOneForm.partnerZone ||
        ZonesTwoList.getRegions().at(0),
      prefecture:
        stepOneForm.prefecture ||
        stepOneForm.partnerPrefecture ||
        ZonesTwoList.getCommunes(ZonesTwoList.getRegions().at(0)).at(0),
      unit: stepOneForm.unit || stepOneForm.partnerEndowment,
      receptionDate: stepOneForm.receptionDate || '',
      deliveryDate: stepOneForm.deliveryDate || '',
      region: stepOneForm.region || ZonesList.getRegions().at(0),
      typeKit: stepOneForm.typeKit || '',
      observations: stepOneForm.observations || '',
      documentsName: stepOneForm.documentsName || [],
      documents: stepOneForm.documents || null,
      /* partner field */
      partnerId: stepOneForm.partnerId || '',
      partnerRut: stepOneForm.partnerRut || '',
      partnerPhone: stepOneForm.partnerPhone || '',
      partnerGrade: stepOneForm.partnerGrade || GradesCarabineros.at(0),
      partnerCode: stepOneForm.partnerCode || '',
      partnerZone: stepOneForm.partnerZone || ZonesTwoList.getRegions().at(0),
      partnerPrefecture:
        stepOneForm.partnerPrefecture ||
        ZonesTwoList.getCommunes(ZonesTwoList.getRegions().at(0)).at(0),
      partnerEndowment: stepOneForm.partnerEndowment || ''
    })
  }, [stepOneForm])

  // useEffect(() => {
  //   setStepOneForm({
  //     /* partner field */
  //     partnerPhone: program?.partner.phone,
  //     partnerRut: program?.partner.rut,
  //     partnerGrade: program?.partner.grade,
  //     partnerCode: program?.partner.code,
  //     partnerZone: program?.partner.zone,
  //     partnerPrefecture: program?.partner.prefecture,
  //     partnerEndowment: program?.partner.endowment
  //     /* - */
  //   })
  // }, [])

  return (
    <>
      {/* partner data */}
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          <Text fontWeight={700} color={'green.500'} fontSize={'xl'}>
            Información del Socio (Opcionales)
          </Text>
          <InputComtrol
            id={'partnerRut'}
            label={'Rut Socio'}
            isRequired={false}
            value={formik.values.partnerRut}
            onChange={formik.handleChange}
            onBlur={handleChangeRut}
            error={Boolean(formik.errors.partnerRut)}
            errorText={formik.errors.partnerRut}
          />
          <InputComtrol
            id={'partnerPhone'}
            label={'Teléfono Socio'}
            isRequired={false}
            type={'number'}
            addictionType={'phone'}
            value={formik.values.partnerPhone}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.partnerPhone)}
            errorText={formik.errors.partnerPhone}
          />

          <InputComtrol
            id={'partnerGrade'}
            label={'Grado Socio'}
            isRequired={false}
          >
            <Select
              bg={'white'}
              list={GradesCarabineros}
              value={formik.values.partnerGrade}
              onChange={handleChangePartnerGrade}
            />
          </InputComtrol>
          <InputComtrol
            id={'partnerPrefecture'}
            label={'Alta repartición / Repartición Socio'}
            isRequired={false}
            error={Boolean(formik.errors.partnerPrefecture)}
            errorText={formik.errors.partnerPrefecture}
          >
            <Select
              bg={'white'}
              list={ZonesTwoList.getCommunes(formik.values.partnerZone)}
              value={formik.values.partnerPrefecture}
              onChange={handleChangePartnerPrefecture}
            />
          </InputComtrol>
        </GridItem>
        <GridItem>
          <Text fontSize={'xl'} opacity={0}>
            -----
          </Text>

          <InputComtrol
            id={'partnerCode'}
            label={'Código Socio. ej: 001001-D'}
            isRequired={false}
            value={formik.values.partnerCode}
            onChange={formik.handleChange}
            onBlur={formatCode}
            error={Boolean(formik.errors.partnerCode)}
            errorText={formik.errors.partnerCode}
          />
          <InputComtrol
            id={'alternativePhone'}
            isRequired={false}
            label={'Teléfono alternativo'}
            type={'number'}
            addictionType={'phone'}
            value={formik.values.alternativePhone}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.alternativePhone)}
            errorText={formik.errors.alternativePhone}
          />
          <InputComtrol
            id={'partnerZone'}
            label={'Zona Socio'}
            isRequired={false}
          >
            <Select
              bg={'white'}
              list={ZonesTwoList.getRegions()}
              value={formik.values.partnerZone}
              onChange={hadleChangePartnerZone}
              error={Boolean(formik.errors.partnerZone)}
              errorText={formik.errors.partnerZone}
            />
          </InputComtrol>
          <InputComtrol
            id={'partnerEndowment'}
            label={'Unidad Socio'}
            isRequired={false}
            value={formik.values.partnerEndowment}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.partnerEndowment)}
            errorText={formik.errors.partnerEndowment}
          />
        </GridItem>
      </Grid>
      {/* end partner data */}
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          <Text fontWeight={700} color={'green.500'} fontSize={'xl'}>
            Zona de postulación
          </Text>
        </GridItem>
        <GridItem>
          <Checkbox
            css={{ borderColor: 'green' }}
            id={'isPartnerZone'}
            size="md"
            colorScheme="green"
            isChecked={formik.values.isPartnerZone}
            value={formik.values.isPartnerZone}
            onChange={handleChangeIsPartnerZone}
            py={4}
            fontWeight={700}
          >
            ¿Es la zona actual del socio?
          </Checkbox>
        </GridItem>
        <GridItem>
          <InputComtrol id={'zone'} label={'Zona'}>
            <Select
              bg={'white'}
              list={ZonesTwoList.getRegions()}
              value={formik.values.zone}
              onChange={hadleChangeZone}
            />
          </InputComtrol>
        </GridItem>
        <GridItem>
          {/* <Text fontSize={'xl'} opacity={0}>
            -----
          </Text> */}
          <InputComtrol
            id={'prefecture'}
            label={'Alta repartición / Repartición'}
            error={Boolean(formik.errors.prefecture)}
            errorText={formik.errors.prefecture}
          >
            <Select
              bg={'white'}
              list={ZonesTwoList.getCommunes(formik.values.zone)}
              value={formik.values.prefecture}
              onChange={handleChangePrefecture}
            />
          </InputComtrol>
        </GridItem>
      </Grid>
      <Grid
        templateColumns={{
          lg: 'repeat(1, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          <InputComtrol
            id={'unit'}
            label={'Unidad'}
            value={formik.values.unit}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.unit)}
            errorText={formik.errors.unit}
          />
        </GridItem>
      </Grid>
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          <Text fontWeight={700} color={'green.500'} fontSize={'xl'}>
            Información de beneficio
          </Text>
          <InputComtrol
            id={'receptionDate'}
            label={'Fecha de recepción de documentos'}
          >
            <CustomDatePicker
              placeholder={'DD/MM/AAAA'}
              currentDate={formik.values.receptionDate}
              onChange={(date) => handleChangeDocumentsReceptionDate(date)}
              error={Boolean(formik.errors.receptionDate)}
              errorText={formik.errors.receptionDate}
              maxDate={addDays(new Date(), 0)}
            />
          </InputComtrol>
        </GridItem>
        <GridItem>
          <Text fontSize={'xl'} opacity={0}>
            -----
          </Text>
          <InputComtrol id={'deliveryDate'} label={'Fecha de entrega'}>
            <CustomDatePicker
              placeholder={'DD/MM/AAAA'}
              currentDate={formik.values.deliveryDate}
              onChange={(date) => handleChangeDeliveryDate(date)}
              error={Boolean(formik.errors.deliveryDate)}
              errorText={formik.errors.deliveryDate}
              maxDate={addDays(new Date(), 0)}
            />
          </InputComtrol>
        </GridItem>
      </Grid>
      <Grid templateColumns={'repeat(2, 1fr)'} gap={2}>
        <GridItem>
          <InputComtrol id={'region'} label={'Seleccione una región'}>
            <Select
              bg={'white'}
              list={ZonesList.getRegions()}
              value={formik.values.region}
              onChange={handleChangeRegion}
            />
          </InputComtrol>
        </GridItem>
        <GridItem>
          <InputComtrol
            id={'typeKit'}
            label={'Tipo de kit'}
            error={Boolean(formik.errors.typeKit)}
            errorText={formik.errors.typeKit}
          >
            <Flex justify={'space-around'}>
              <Radio
                colorScheme="green"
                value="Hombre"
                onChange={handleChangeType}
                isChecked={formik.values.typeKit === 'Hombre'}
                isInvalid={!formik.values.typeKit}
              >
                Hombre
              </Radio>
              <Radio
                colorScheme="green"
                value="Mujer"
                onChange={handleChangeType}
                isChecked={formik.values.typeKit === 'Mujer'}
                isInvalid={!formik.values.typeKit}
              >
                Mujer
              </Radio>
            </Flex>
          </InputComtrol>
        </GridItem>
      </Grid>
      <Grid templateColumns={'repeat(2, 1fr)'} gap={2}>
        <GridItem>
          <TextAreaComtrol
            id={'observations'}
            label={'Observaciones'}
            value={formik.values.observations}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.observations)}
            errorText={formik.errors.observations}
            isRequired={false}
          />
        </GridItem>
        <GridItem>
          <InputComtrol label={'Documentación (opcional)'} isRequired={false}>
            <UploadFile
              direction={'row'}
              sizeIcon={30}
              p={5}
              onChange={(e) => {
                handleChangeDocuments(e)
              }}
              id={'documentsName'}
              error={Boolean(formik.errors.documentsName)}
              errorText={formik.errors.documentsName}
            />
            <Flex direction={'column'} gap={1}>
              {formik.values.documentsName.map((item) => (
                <BarFileName
                  key={item?.name}
                  fileName={item?.name}
                  fileUrl={item?.url}
                  onDelete={() => deleteDocuments(item?.name)}
                />
              ))}
            </Flex>
          </InputComtrol>
        </GridItem>
      </Grid>
    </>
  )
}

export default StepOne
