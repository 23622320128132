import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { Flex, Grid, GridItem } from '@chakra-ui/react'
import {
  UploadFile,
  BarFileName,
  TextAreaComtrol,
  InputComtrol
} from '../../UI'
import { steepThreeSchema } from './Schemas'
import { Files } from '../../../Utils'

const StepThree = ({
  stepThreeForm,
  setStepThreeForm,
  refStepThree,
  state
}) => {
  console.log(`ESTADO= ${state}`)
  const initialValues = {
    aditionalDocumentsName: [],
    aditionalDocuments: null,
    observations: ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: steepThreeSchema,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: (values) => {
      setStepThreeForm({
        ...values,
        state
      })
    }
  })

  const deleteAditionalDocuments = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.aditionalDocumentsName,
      formik.values.aditionalDocuments
    )

    formik.setValues({
      ...formik.values,
      aditionalDocumentsName: resultNames,
      aditionalDocuments: resultListFiles
    })
  }

  const handleChangeAditionalDocuments = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.aditionalDocumentsName,
      formik.values.aditionalDocuments
    )

    formik.setValues({
      ...formik.values,
      aditionalDocumentsName: newListNames,
      aditionalDocuments: newListFiles
    })
    e.target.value = null
  }

  useEffect(() => {
    refStepThree.current = formik.handleSubmit
  }, [])

  useEffect(() => {
    formik.setValues({
      aditionalDocumentsName: stepThreeForm.aditionalDocumentsName || [],
      aditionalDocuments: stepThreeForm.aditionalDocuments || null,
      observations: stepThreeForm.observations || ''
    })
  }, [stepThreeForm])

  return (
    <>
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
        <GridItem>
          <InputComtrol
            isRequired={false}
            label={'Documentos adicionales (opcional)'}
          >
            <UploadFile
              direction={'row'}
              multiple={true}
              sizeIcon={30}
              p={5}
              onChange={(e) => {
                handleChangeAditionalDocuments(e)
              }}
              id={'aditionalDocumentsName'}
              error={Boolean(formik.errors.aditionalDocumentsName)}
              errorText={formik.errors.aditionalDocumentsName}
            />
            <Flex direction={'column'} gap={1}>
              {formik.values.aditionalDocumentsName.map((item) => (
                <BarFileName
                  key={item?.name}
                  fileName={item?.name}
                  fileUrl={item?.url}
                  onDelete={() => deleteAditionalDocuments(item?.name)}
                />
              ))}
            </Flex>
          </InputComtrol>
        </GridItem>
      </Grid>
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
        <GridItem>
          <TextAreaComtrol
            id={'observations'}
            isRequired={false}
            label={'Observaciones de la solicitud (opcional)'}
            value={formik.values.observations}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.observations)}
            errorText={formik.errors.observations}
          />
        </GridItem>
      </Grid>
    </>
  )
}

export default StepThree
