import React from 'react'
import { Flex, Icon, Text } from '@chakra-ui/react'
import { RejectedRedIcon } from '../../../Icons'
import ButtonOptions from './ButtonOptions'

const AlterProcess = ({
  currentState,
  value,
  error,
  onClickLeft,
  onClickRight,
  buttonsPropsExtra
}) => {
  const state = {
    'NOT-AUTHORIZED': 'No autorizado'
  }
  return (
    <Flex direction={'column'} gap={8} py={2}>
      <Flex direction={'column'} justify={'flex-start'} align={'start'} gap={2}>
        <Text color={'green.500'} fontWeight={700}>
          confección de orden de compra
        </Text>
        <Flex
          gap={2}
          bg={'red.50'}
          color={'red.500'}
          justify={'center'}
          align={'center'}
          borderRadius={10}
          p={2}
        >
          <Icon as={RejectedRedIcon} fill={'red.500'} />
          {state[currentState]}
        </Flex>
      </Flex>
      <Flex direction={'column'} justify={'center'} align={'center'} gap={4}>
        <Text color={'green.500'} fontWeight={700} fontSize={'xl'}>
          ¿Cómo desea continuar el proceso?
        </Text>
        <ButtonOptions
          onClickLeft={onClickLeft}
          onClickRight={onClickRight}
          value={value}
          error={error}
          buttonsProps={buttonsPropsExtra}
        />
      </Flex>
      {value === 'BACK' && (
        <Flex
          direction={'column'}
          justify={'flex-start'}
          align={'start'}
          gap={1}
        >
          <Text color={'green.500'} fontWeight={700} fontSize={'xl'}>
            Retroceder proceso
          </Text>
          <Text fontSize={'md'}>
            Se enviará un notificación al departamento de control y gestión
            sobre el por qué se retrocede el proceso.
          </Text>
        </Flex>
      )}
    </Flex>
  )
}

export default AlterProcess
