import React from 'react'
import { CustomModal, Button } from '../UI'
import { LockIcon } from '../Icons'

const ModalHistory = ({
  colors,
  disable,
  disableButtons,
  isLoading,
  title = '',
  iconButton,
  children,
  onClick,
  isOpen,
  onOpen,
  onClose,
  size = '2xl',
  showButtons = true,
  ...props
}) => (
  <>
    {colors && (
      <Button
        icon={LockIcon}
        text={'Actualizar'}
        bg={colors()?.bg}
        bghover={colors()?.hover}
        variant={'outline'}
        disabled={disable}
        onClick={onOpen}
        py={1}
        isLoading={isLoading}
      />
    )}
    {isOpen && (
      <CustomModal
        isOpen={isOpen}
        onClose={onClose}
        onClick={onClick}
        title={title}
        iconButton={iconButton && iconButton}
        size={size}
        disableButtons={disableButtons}
        isLoading={isLoading}
        showButtons={showButtons}
        {...props}
      >
        {children}
      </CustomModal>
    )}
  </>
)

export default ModalHistory
