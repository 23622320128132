import * as Yup from 'yup'
/* eslint-disable import/prefer-default-export */
export const schema = Yup.object().shape({
  message: Yup.string(),
  program: Yup.string(),
  keyHistory: Yup.string().required('Este campo es requerido'),
  state: Yup.string(),
  isRequiredDelegateZone: Yup.boolean(),
  isRequiredSocialAssistant: Yup.boolean(),
  delegateZone: Yup.boolean(),
  socialAssistant: Yup.boolean(),
  delegateZoneDate: Yup.string().when('delegateZone', {
    is: true,
    then: Yup.string().required('Este campo es requerido')
  }),
  socialAssistantDate: Yup.string().when('socialAssistant', {
    is: true,
    then: Yup.string().required('Este campo es requerido')
  }),
  isRequiredSendOnlyWsp: Yup.boolean()
})
