import React from 'react'
import { Grid, GridItem, Box, Flex, Image, Show } from '@chakra-ui/react'
import Frame from '../../assets/images/Frame.png'
import CardLogin from '../../components/CardLogin'

const Login = () => (
  <Grid
    templateColumns={{
      lg: 'repeat(2, 60% 35%)',
      md: 'repeat(1, 1fr)',
      sm: 'repeat(1, 1fr)'
    }}
    gap={5}
    h={'100vh'}
    bg={{
      lg: 'white',
      md: 'beige.500',
      sm: 'beige.500'
    }}
  >
    <Show above="md">
      <GridItem overflowY={'hidden'} position={'relative'}>
        <Box bg={'beige.500'} position={'absolute'} top={0} w={'95%'}>
          <Box w={'105%'} position={'absolute'} top={60}>
            <Image src={Frame} alt="logo" w={'100%'} />
          </Box>
          <Flex px={'100px'} mt={'20%'}>
            <Box
              w={'100%'}
              h={'100vh'}
              bg={'beige.100'}
              rounded={'full'}
              boxShadow={'2xl'}
            />
          </Flex>
        </Box>
      </GridItem>
    </Show>
    <GridItem
      mt={'auto'}
      mb={'auto'}
      px={{
        lg: 15,
        md: 1,
        sm: 1
      }}
    >
      <CardLogin />
    </GridItem>
  </Grid>
)

export default Login
