import * as Yup from 'yup'
/* eslint-disable import/prefer-default-export */
export const schema = Yup.object().shape({
  stepProgram: Yup.string(),
  phoneNumber: Yup.string().nullable(),
  phonePostulant: Yup.string(),
  isRequiredMessage: Yup.boolean(),
  affair: Yup.string(),
  message: Yup.string().when('isRequiredMessage', {
    is: true,
    then: Yup.string().required('Este campo es requerido')
  }),
  program: Yup.string(),
  keyHistory: Yup.string().required('Este campo es requerido'),
  state: Yup.string(),
  isRequiredDelegateZone: Yup.boolean(),
  isRequiredSocialAssistant: Yup.boolean(),
  delegateZone: Yup.boolean(),
  socialAssistant: Yup.boolean(),
  delegateZoneDate: Yup.string(),
  socialAssistantDate: Yup.string(),
  isRequiredSendOnlyWsp: Yup.boolean(),
  sendOnlyWsp: Yup.boolean(),
  continueStudies: Yup.boolean()
})
