const Regions = [
  {
    region: 'DIRECCIONES  ZONA METROPOLITANA',
    comunas: [
      'DIRECCION GENERAL DE CARABINEROS',
      'SUB DIRECCION GENERAL',
      'SECRETARIA GENERAL',
      'DIRECCION DE PLANIFICACION Y DESARROLLO',
      'DIRECCION DE AUDITORIA INTERNA',
      'CONTRALORIA GENERAL',
      'DIRECCION DE JUSTICIA',
      'DIRECCION DE DERECHOS HUMANOS Y PROTECCION DE LA FAMILIA',
      'SUB CONTRALORIA GENERAL',
      'DIRECCION DE ASUNTOS INTERNOS',
      'DIRECCION DE INTELIGENCIA POLICIAL',
      'DIRECCION NACIONAL DE APOYO A LAS OPERACIONES POLICIALES',
      'DIRECCION NACIONAL DE ORDEN Y SEGURIDAD',
      'DIRECCION NACIONAL DE PERSONAL',
      'DIRECCION DE TEC. INF Y LAS COMUNICACIONES',
      'DIRECCION DE LOGISTICA',
      'DIRECCION DE COMPRAS PUBLICAS',
      'DIRECCION DE FINANZAS',
      'DIRECCION DE CONTROL DE DROGAS E INVESTIGACION CRIMINAL',
      'DIRECCION DE EDUACACION DOCTRINA E HISTORIA',
      'DIRECCION DE BIENESTAR',
      'DIRECCION DE GESTION DE PERSONAS',
      'DIRECCION DE SALUD',
      'SUB DIRECCION DE BIENESTAR',
      'SUBDIRECCION DE SALUD',
      'DIRECCION DE SANIDAD'
    ]
  },
  {
    region: 'ZONA METROPOLITANA ',
    comunas: [
      'DEPTO DE OPERACIONES',
      'DEPTO ADMINISTRATIVO',
      'CENCO',
      'DEPTO CONTROL ORDEN PUBLICO EVENTOS MASIVOS Y FUTBOL PROFESIONAL',
      'FISCALIA ADMINISTRATIVA',
      'DEPTO DE APOYO A LAS OPERACIONES POLICIALES'
    ]
  },
  {
    region: 'ZONA FRONTERAS Y SERVICIOS ESPECIALES',
    comunas: [
      'DEPTO SERV DE FRONTERAS (O.S.3)',
      'DEPTO DE SEGURIDAD PRESID (O.S.8)',
      'DEPTO PROT. PERSONAS IMPORTANTES (P.P.I)',
      'DEPTO DEL MEDIO AMBIENTE',
      'PREFECTURA AEREA',
      'PREFECTURA OPER ESPECIALES. G.O.P.E (O.S.6)'
    ]
  },
  {
    region: 'ZONA SEGURIDAD PRIV. CONTROL DE ARMAS Y EXPLOSIVOS',
    comunas: [
      'PREFECTURA DE SEGURIDAD PRIIVADA (O.S.10)',
      'DEPTO OPERACIONES',
      'DEPTO. ADMINISTRATIVO',
      'DEPTO. DE APOYO A LAS OPERACIONES POLICIALES',
      'DEPTO DE COORDINACION SEGURIDAD MUNICIPAL (O.S.14)',
      'PREFECTURA DE CONTROL DE ARMAS Y EXPLOSIVOS (O.S.11)'
    ]
  },
  {
    region: 'ZONA SANTIAGO OESTE',
    comunas: [
      'SUBCOM OOJJ',
      'PREFECTURA SANTIAGO CENTRAL',
      'PREFECTURA SANTIAGO NORTE',
      'PREFECTURA SANTIAGO RINCONADA',
      'PREFECTURA SANTIAGO OCCIDENTE',
      'PREFECTURA COSTA',
      'PREF FUERZAS ESPECIALES (FUTURA COP OESTE)',
      'PREFECTURA RADIOPATRULLAS E INTERVENCION POLICIAL OESTE'
    ]
  },
  {
    region: 'ZONA SANTIAGO ESTE',
    comunas: [
      'PREFECTURA SANTIAGO ORIENTE',
      'PREFECTURA SANTIAGO CORDILLERA',
      'PREFECTURA SANTIAGO SUR',
      'PREFECTURA SANTIAGO ANDES',
      'PREFECTURA DEL MAIPO',
      'PREFECTURA FUERZAS ESPECIALES(FUTURA COP ESTE)',
      'PREFECTURA RADIOPATRULLAS E INTERVENCION POLICIAL ESTE'
    ]
  },
  {
    region: 'ZONA TRANSITO CARRETERAS Y SEGURIDAD VIAL',
    comunas: [
      'PREFECTURAS DEL TRANSITO Y CARRETERAS',
      'PREF DE INVEST DE ACCIDENTES EN EL TRANSITO S.I.A.T.',
      'PREF TECNICA Y SEGURIDAD VIAL (O.S.2)',
      'CENTRO DE FORMACION CAPAC DE CONDUCTORES'
    ]
  },
  {
    region: 'ZONA ARICA Y PARINACOTA',
    comunas: ['PREFECTURA ARICA']
  },
  {
    region: 'ZONA TARAPACA',
    comunas: ['PREFECTURA IQUIQUE']
  },
  {
    region: 'ZONA ANTOFAGASTA',
    comunas: ['PREFECTURA DE ANTOFAGASTA', 'PREFECTURA DE EL LOA']
  },
  {
    region: 'ZONA ATACAMA',
    comunas: ['PREFECTURA ATACAMA']
  },
  {
    region: 'ZONA COQUIMBO',
    comunas: ['PREFECTURA COQUIMBO', 'PREFECTURA LIMARI']
  },
  {
    region: 'ZONA VALPARAISO',
    comunas: [
      'PREFECTURA ACONCAGUA',
      'PREF VALPARAISO',
      'PREF SAN ANTONIO',
      'PREFECTURA VIÑA DEL MAR',
      'PREFECTURA MARGA MARGAPREFECTURA FFEE (FUTURA COP) V ZONA VALAPARAISO N 33'
    ]
  },
  {
    region: 'ZONA LIB BERNARDO OHIGGINS',
    comunas: ['PREF DE CACHAPOAL', 'PREF DE COLCHAGUA']
  },

  {
    region: 'ZONA MAULE',
    comunas: [
      'PREFECTURA CURICO',
      'PREFECTURA TALCA N 14',
      'PREFECTURA LINARES N 15'
    ]
  },

  {
    region: 'ZONA ÑUBLE',
    comunas: ['PREFECTURA ÑUBLE N 17']
  },

  {
    region: 'ZONA BIO BIO',
    comunas: [
      'PREFECTURA CONCEPCION N 18',
      'PREFECTURA DE TALCAHUANO',
      'PREFECTURA ARAUCO',
      'PREFECTURA DE BIO BIO',
      'PREFECTURA FUERZAS ESPECIALES (FUTURA COP)'
    ]
  },

  {
    region: 'ZONA ARAUCANIA',
    comunas: [
      'PREFECTURA MALLECO',
      'PREFECTURA VILLARRICA',
      'PREFECTURA DE CAUTIN'
    ]
  },

  {
    region: 'ZONA ARAUCANIA CONTROL ORDEN PUBLICO',
    comunas: ['PREFECTURA FFEE (FUTURA COP ARAUCANIA)']
  },

  {
    region: 'ZONA LOS RIOS',
    comunas: ['PREFECTURA VALDIVIA']
  },

  {
    region: 'ZONA LOS LAGOS',
    comunas: [
      'PREFECTURA OSORNO',
      'PREFECTURA DE LLANQUIHUE',
      'PREFECTURA DE CHILOE'
    ]
  },

  {
    region: 'ZONA AYSEN DEL GENERAL CARLOS IBAÑEZ DEL CAMPO',
    comunas: ['PREFECTURA DE  AYSEN DEL GRAL LUIS MARCHANT GONZALEZ']
  },

  {
    region: 'ZONA MAGALLANES Y ANTARTIDA CHILENA',
    comunas: ['PREFECTURA DE MAGALLANES']
  }
]

const getRegions = () => {
  const result = Regions.map((item) => item.region)
  return result
}

const getCommunes = (regionName) => {
  const result = Regions.find((item) => item.region === regionName)?.comunas
  if (!result) {
    return []
  }
  return result
}

export default { getRegions, getCommunes }
