/* eslint-disable import/prefer-default-export */
import axios from '../Axios'

const module = '/notifications'

export const getOneNotification = async (query) => {
  const { programKey, type } = query
  const { data } = await axios.get(
    `${module}/get-one/?programKey=${programKey}&type=${type}`
  )
  return data
}

export const createAndUpdateNotification = async (formData) => {
  const { data } = await axios.post(`${module}/create-and-update`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
  return data
}
