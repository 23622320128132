import { createIcon } from '@chakra-ui/react'
/* https://freeicons.io/ui-essentials-3/ui-essentials-notification-alarm-bell-icon-44563 */
/* eslint-disable import/prefer-default-export */
const TrashIcon = createIcon({
  displayName: 'TrashIcon',
  viewBox: '0 0 16 18',
  path: [
    <path
      d="M15.8333 3.35994C15.8333 2.76297 15.35 2.27902 14.7538 2.27902H12.0362C11.5814 0.987225 10.3642 0.121579 8.99621 0.117188H6.83712C5.46917 0.121579 4.25188 0.987225 3.79713 2.27902H1.07954C0.483332 2.27902 0 2.76297 0 3.35994C0 3.95691 0.483332 4.44086 1.07954 4.44086H1.4394V13.4485C1.4394 15.6374 3.21161 17.4119 5.39772 17.4119H10.4356C12.6217 17.4119 14.3939 15.6374 14.3939 13.4485V4.44086H14.7538C15.35 4.44086 15.8333 3.95691 15.8333 3.35994ZM12.2348 13.4485C12.2348 14.4435 11.4293 15.25 10.4356 15.25H5.39772C4.40403 15.25 3.59849 14.4435 3.59849 13.4485V4.44086H12.2348V13.4485Z"
      fill="#82B378"
    />,
    <path
      d="M6.11812 13.0871C6.71433 13.0871 7.19766 12.6031 7.19766 12.0062V7.68248C7.19766 7.08551 6.71433 6.60156 6.11812 6.60156C5.52191 6.60156 5.03857 7.08551 5.03857 7.68248V12.0062C5.03857 12.6031 5.52191 13.0871 6.11812 13.0871Z"
      fill="#82B378"
    />,
    <path
      d="M9.71577 13.0871C10.312 13.0871 10.7953 12.6031 10.7953 12.0062V7.68248C10.7953 7.08551 10.312 6.60156 9.71577 6.60156C9.11956 6.60156 8.63623 7.08551 8.63623 7.68248V12.0062C8.63623 12.6031 9.11956 13.0871 9.71577 13.0871Z"
      fill="#82B378"
    />
  ]
})

export default TrashIcon
