const ProgramsList = [
  {
    key: 'HospitalAccommodation',
    name: 'Hospedaje Hospitalario'
  },
  {
    key: 'SolidarityHealthFund',
    name: 'Fondo Solidario de Salud'
  },
  {
    key: 'InjuredInLinesOfServiceKit',
    name: 'Kit Lesionados en Actos de Servicio'
  },
  {
    key: 'FireSupport',
    name: 'Apoyo en Caso de Incendio'
  },
  {
    key: 'DisasterSupport',
    name: 'Apoyo en Catástrofe'
  },
  {
    key: 'SupportInCaseOfDeathOfMember',
    name: 'Apoyo en caso de Fallecimiento de Socio'
  },
  {
    key: 'NewbornBenefit',
    name: 'Beneficio Recién Nacido'
  },
  {
    key: 'PreUniversityScholarships',
    name: 'Beca Preuniversitario'
  },
  {
    key: 'WorkshopsAndTraining',
    name: 'Talleres y Capacitaciones'
  },
  {
    key: 'TechnicalProfessionalScholarships',
    name: 'Becas Técnico-Profesional'
  },
  {
    key: 'ScholarshipsApplicantOfficer',
    name: 'Becas Aspirante a Oficial'
  },
  {
    key: 'ScholarshipsApplicantOfficerDac',
    name: 'Becas Aspirante a Oficial DAC'
  },
  {
    key: 'UniversityScholarships',
    name: 'Becas Universitarias'
  }
]

export default ProgramsList
