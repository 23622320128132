import React from 'react'
import { Grid, GridItem } from '@chakra-ui/react'
import { InputComtrol } from '../../../UI'

const RenderCommission = ({ program }) => {
  const getCommision = () => {
    const { additionalInformation } = program
    const preparationCommission = additionalInformation.filter(
      (item) => item.key === 'COMMISSION-PREPARATION-SOLIDARITY-HEALTH-FUND'
    )

    if (preparationCommission.length > 0) {
      const { commission } = preparationCommission[0]
      return (
        <Grid
          templateColumns={{
            lg: 'repeat(1, 1fr)',
            md: 'repeat(1, 1fr)',
            sm: 'repeat(1, 1fr)'
          }}
          gap={2}
        >
          <GridItem>
            <InputComtrol
              label="Comisión"
              readOnly={true}
              value={commission}
              isRequired={false}
            />
          </GridItem>
        </Grid>
      )
    }
    return <></>
  }

  return <>{getCommision()}</>
}

export default RenderCommission
