import React from 'react'
import { Grid, GridItem, Skeleton, Divider } from '@chakra-ui/react'

const DashboardSkeleton = () => (
  <>
    <Grid templateColumns={'repeat(1, 1fr)'} gap={6}>
      <GridItem>
        <Skeleton height={'50px'} borderRadius={12} />
      </GridItem>
    </Grid>
    <Divider my={4} />
    <Grid
      templateColumns={{
        lg: 'repeat(4, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      {[1, 1, 1, 1].map(() => (
        <GridItem>
          <Skeleton height={'200px'} borderRadius={12} />
        </GridItem>
      ))}
    </Grid>
    <Divider my={4} />
    <Grid templateColumns={'repeat(1, 1fr)'} gap={6}>
      <GridItem>
        <Skeleton height={'100px'} borderRadius={12} />
      </GridItem>
    </Grid>
    <Divider my={4} />
    <Grid templateColumns={'repeat(1, 1fr)'} gap={6}>
      <GridItem>
        <Skeleton height={'30px'} borderRadius={12} />
      </GridItem>
    </Grid>
    <Divider my={4} />
    <Grid templateColumns={'repeat(1, 1fr)'} gap={6}>
      <GridItem>
        <Skeleton height={'60vh'} borderRadius={12} />
      </GridItem>
    </Grid>
  </>
)

export default DashboardSkeleton
