const Roles = [
  { key: 'ADMINISTRADOR', name: 'Administrador', type: ['ALL'] },
  {
    key: 'ASISTENTE_SOCIAL',
    name: 'Asistente social',
    type: [
      'SupportInCaseOfDeathOfMember',
      'SolidarityHealthFund',
      'FireSupport',
      'DisasterSupport',
      'PreUniversityScholarships',
      'TechnicalProfessionalScholarships',
      'ScholarshipsApplicantOfficer',
      'ScholarshipsApplicantOfficerDac',
      'UniversityScholarships'
    ]
  },
  { key: 'ASESOR_JURIDICO', name: 'Asesor jurídico', type: ['ALL'] },
  { key: 'REVISION', name: 'Revisión', type: ['ALL'] },
  { key: 'GERENCIA', name: 'Director ejecutivo', type: ['ALL'] },
  { key: 'PRESIDENCIA', name: 'Presidencia', type: ['ALL'] },
  { key: 'FINANZAS', name: 'Finanzas', type: ['ALL'] },
  {
    key: 'DEPTO_CONTROL_GESTION_SOCIAL',
    name: 'Depto. Control y gestión social',
    type: ['ALL']
  },
  { key: 'ABASTECIMIENTO', name: 'Abastecimiento', type: ['ALL'] },
  {
    key: 'ADMINISTRATIVE-NEWBORN-BENEFIT',
    name: 'Administrativo Beneficio Recién Nacido',
    type: ['NewbornBenefit']
  },
  {
    key: 'ADMINISTRATIVE-WORKSHOPS-AND-TRAINING',
    name: 'Administrativo Talleres y Capacitaciones',
    type: ['WorkshopsAndTraining']
  },
  {
    key: 'ADMINISTRATIVE-INUJRED-IN-LINES-OF-SERVICE-KIT',
    name: 'Administrativo Kit Lesionados en Actos de Servicio',
    type: ['InjuredInLinesOfServiceKit']
  },
  {
    key: 'ADMINISTRATIVE-HOSPITAL-ACCOMMODATION',
    name: 'Administrativo Hospedaje Hospitalario',
    type: ['HospitalAccommodation']
  },
  {
    key: 'ADMINISTRATIVE-MULTIPLE-PROGRAMS',
    name: 'Administrativo Múltiples Programas',
    type: [
      'SupportInCaseOfDeathOfMember',
      'SolidarityHealthFund',
      'FireSupport',
      'DisasterSupport',
      'PreUniversityScholarships',
      'TechnicalProfessionalScholarships',
      'ScholarshipsApplicantOfficer',
      'ScholarshipsApplicantOfficerDac',
      'UniversityScholarships'
    ]
  }
]
export default Roles
