import React from 'react'
import { Flex } from '@chakra-ui/react'
import { TextAreaComtrol } from '../../UI'

const RenderMoreDescriptions = ({ moreDescriptions }) => (
  <Flex
    direction={'column'}
    gap={1}
    border={'1px solid #82b378'}
    borderRadius={8}
    p={2}
  >
    {moreDescriptions.map((item, index) => (
      <TextAreaComtrol
        label={`${index + 1} - Observación adicional`}
        value={item?.description}
        isRequired={false}
        readOnly={true}
      />
    ))}
  </Flex>
)

export default RenderMoreDescriptions
