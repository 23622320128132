import { GetNotification } from '../../Utils'
import { useMutateProgram } from '../../hooks/ProgramsSync'
import { Button } from '../UI'

const DownloadTemplate = ({ keyProgram }) => {
  const { mutate, isLoading, reset } = useMutateProgram(
    'GET-TEMPLATE-UPLOAD-MASIVE'
  )
  const onDownload = () => {
    const formData = { keyProgram }

    mutate(formData, {
      onSuccess: (response) => {
        reset()
        GetNotification.basic(
          response?.success?.message || 'Plantilla Descargada con Éxito',
          'Aceptar'
        )
      },
      onError: (err) => {
        const response = JSON.parse(err.request.response)
        GetNotification.basic(
          'Error',
          'Aceptar',
          response?.error?.message || 'Error al descargar plantilla',
          'error'
        )
      }
    })
  }
  return (
    <Button
      text={'Descargar Plantilla'}
      px={10}
      onClick={onDownload}
      isLoading={isLoading}
      disabled={!keyProgram}
    />
  )
}

export default DownloadTemplate
