import { Grid, GridItem } from '@chakra-ui/react'
import React from 'react'
import { Files } from '../../../../Utils'
import { BarFileName, InputComtrol, UploadFile } from '../../../UI'

const DocumentsSolidarityOrder = ({
  formik,
  program,
  typeProcess,
  currentStepKey
}) => {
  const getTitleQuotesReceived = () => {
    const steps = program?.steps.filter(
      (item) => item.key === 'ADD-DEBT-CERTIFICATE'
    )
    if (steps.length > 0) {
      return 'Añade acta resolución de comisión'
    }

    const steps2 = program?.steps.filter(
      (item) => item.key === 'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND'
    )
    if (
      steps2.length > 0 &&
      typeProcess.tariffDifference &&
      typeProcess.service !== 'Dipreca'
    ) {
      return 'Añade certificado de deuda'
    }

    return 'Añade cotizaciones recibidas'
  }

  const deleteBoxPurchase = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.boxPurchaseName,
      formik.values.boxPurchase
    )

    formik.setValues({
      ...formik.values,
      boxPurchaseName: resultNames,
      boxPurchase: resultListFiles
    })
  }

  const deleteBoxPurchaseTwo = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.boxPurchaseTwoName,
      formik.values.boxPurchaseTwo
    )

    formik.setValues({
      ...formik.values,
      boxPurchaseTwoName: resultNames,
      boxPurchaseTwo: resultListFiles
    })
  }

  const deletePurchaseRequestLetter = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.purchaseRequestLetterName,
      formik.values.purchaseRequestLetter
    )

    formik.setValues({
      ...formik.values,
      purchaseRequestLetterName: resultNames,
      purchaseRequestLetter: resultListFiles
    })
  }

  const deleteQuotesReceived = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.quotesReceivedName,
      formik.values.quotesReceived
    )

    formik.setValues({
      ...formik.values,
      quotesReceivedName: resultNames,
      quotesReceived: resultListFiles
    })
  }

  const deletePurchaseRequestLetterSigned = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.purchaseRequestLetterSignedName,
      formik.values.purchaseRequestLetterSigned
    )

    formik.setValues({
      ...formik.values,
      purchaseRequestLetterSignedName: resultNames,
      purchaseRequestLetterSigned: resultListFiles
    })
  }

  const handleChangeBoxPurchase = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.boxPurchaseName,
      formik.values.boxPurchase
    )

    formik.setValues({
      ...formik.values,
      boxPurchaseName: newListNames,
      boxPurchase: newListFiles
    })
    e.target.value = null
  }

  const handleChangeBoxPurchaseTwo = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.boxPurchaseTwoName,
      formik.values.boxPurchaseTwo
    )

    formik.setValues({
      ...formik.values,
      boxPurchaseTwoName: newListNames,
      boxPurchaseTwo: newListFiles
    })
    e.target.value = null
  }

  const handleChangePurchaseRequestLetter = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.purchaseRequestLetterName,
      formik.values.purchaseRequestLetter
    )

    formik.setValues({
      ...formik.values,
      purchaseRequestLetterName: newListNames,
      purchaseRequestLetter: newListFiles
    })
    e.target.value = null
  }

  const handleChangeQuotesReceived = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.quotesReceivedName,
      formik.values.quotesReceived
    )

    formik.setValues({
      ...formik.values,
      quotesReceivedName: newListNames,
      quotesReceived: newListFiles
    })
    e.target.value = null
  }

  const handleChangePurchaseRequestLetterSigned = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.purchaseRequestLetterSignedName,
      formik.values.purchaseRequestLetterSigned
    )

    formik.setValues({
      ...formik.values,
      purchaseRequestLetterSignedName: newListNames,
      purchaseRequestLetterSigned: newListFiles
    })
    e.target.value = null
  }

  return (
    <>
      <Grid templateColumns={'repeat(1, 1fr)'}>
        {(formik.values.isRequiredBoxPurchaseTwo ||
          currentStepKey === 'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND' ||
          currentStepKey ===
            'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND-EDIT-SOLIDARITY-HEALTH-FUND') && (
          <GridItem>
            <InputComtrol
              label={'Añade cuadros de compra'}
              isRequired={formik.values.isRequiredBoxPurchaseTwo}
            >
              <UploadFile
                id={'boxPurchaseTwoName'}
                direction={'row'}
                sizeIcon={30}
                p={5}
                onChange={(e) => handleChangeBoxPurchaseTwo(e)}
                error={Boolean(formik.errors.boxPurchaseTwoName)}
                errorText={formik.errors.boxPurchaseTwoName}
              />
            </InputComtrol>
            {formik.values.boxPurchaseTwoName.map((item) => (
              <BarFileName
                key={item?.name}
                fileName={item?.name}
                fileUrl={item?.url}
                onDelete={() => deleteBoxPurchaseTwo(item?.name)}
              />
            ))}
          </GridItem>
        )}
        {formik.values.isRequiredBoxPurchase && (
          <GridItem>
            <InputComtrol
              label={
                (typeProcess.tariffDifference &&
                  typeProcess.service === 'Dipreca') ||
                typeProcess.others
                  ? 'Añade cuadro Dipreca'
                  : 'Añade cuadro de compra'
              }
              isRequired={!typeProcess.others}
            >
              <UploadFile
                id={'boxPurchaseName'}
                direction={'row'}
                sizeIcon={30}
                p={5}
                onChange={(e) => handleChangeBoxPurchase(e)}
                error={Boolean(formik.errors.boxPurchaseName)}
                errorText={formik.errors.boxPurchaseName}
              />
            </InputComtrol>
            {formik.values.boxPurchaseName.map((item) => (
              <BarFileName
                key={item?.name}
                fileName={item?.name}
                fileUrl={item?.url}
                onDelete={() => deleteBoxPurchase(item?.name)}
              />
            ))}
          </GridItem>
        )}
        {formik.values.isRequiredPurchaseRequestLetter && (
          <GridItem>
            <InputComtrol
              label={'Añade oficio de solicitud de orden de compra'}
            >
              <UploadFile
                id={'purchaseRequestLetterName'}
                direction={'row'}
                sizeIcon={30}
                p={5}
                onChange={(e) => handleChangePurchaseRequestLetter(e)}
                error={Boolean(formik.errors.purchaseRequestLetterName)}
                errorText={formik.errors.purchaseRequestLetterName}
              />
            </InputComtrol>
            {formik.values.purchaseRequestLetterName.map((item) => (
              <BarFileName
                key={item?.name}
                fileName={item?.name}
                fileUrl={item?.url}
                onDelete={() => deletePurchaseRequestLetter(item?.name)}
              />
            ))}
          </GridItem>
        )}
        {(formik.values.isRequiredQuotesReceived ||
          currentStepKey === 'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND' ||
          currentStepKey ===
            'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND-EDIT-SOLIDARITY-HEALTH-FUND') && (
          <GridItem>
            <InputComtrol
              label={getTitleQuotesReceived()}
              isRequired={formik.values.isRequiredQuotesReceived}
            >
              <UploadFile
                id={'quotesReceivedName'}
                direction={'row'}
                sizeIcon={30}
                p={5}
                onChange={(e) => handleChangeQuotesReceived(e)}
                error={Boolean(formik.errors.quotesReceivedName)}
                errorText={formik.errors.quotesReceivedName}
              />
            </InputComtrol>
            {formik.values.quotesReceivedName.map((item) => (
              <BarFileName
                key={item?.name}
                fileName={item?.name}
                fileUrl={item?.url}
                onDelete={() => deleteQuotesReceived(item?.name)}
              />
            ))}
          </GridItem>
        )}
        {formik.values.isRequiredPurchaseRequestLetterSigned &&
          [null, 'APPROVED'].includes(formik.values.paymentVerification) && (
            <GridItem>
              <InputComtrol
                label={'Añade oficio de solicitud de orden de compra firmado'}
              >
                <UploadFile
                  id={'purchaseRequestLetterSignedName'}
                  direction={'row'}
                  sizeIcon={30}
                  p={5}
                  onChange={(e) => handleChangePurchaseRequestLetterSigned(e)}
                  error={Boolean(formik.errors.purchaseRequestLetterSignedName)}
                  errorText={formik.errors.purchaseRequestLetterSignedName}
                />
              </InputComtrol>
              {formik.values.purchaseRequestLetterSignedName.map((item) => (
                <BarFileName
                  key={item?.name}
                  fileName={item?.name}
                  fileUrl={item?.url}
                  onDelete={() => deletePurchaseRequestLetterSigned(item?.name)}
                />
              ))}
            </GridItem>
          )}
      </Grid>
    </>
  )
}

export default DocumentsSolidarityOrder
