import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Flex, Grid, GridItem } from '@chakra-ui/react'
import { TabList, Heading } from '../../components/UI'
import { ProgramsList } from '../../Utils'
import NotificationsForms from '../../components/NotificationsForms'

const noPermission = [
  'HospitalAccommodation',
  'SupportInCaseOfDeathOfMember',
  'DisasterSupport',
  'FireSupport',
  'WorkshopsAndTraining'
]

const Notifications = () => {
  const { user } = useSelector((state) => state.auth)
  const [tabIndex, setTabIndex] = useState(0)
  const [programKey, setProgramKey] = useState('')

  const filteredProgramsList = () => {
    const { userType } = user
    if (!userType?.includes('ALL')) {
      return ProgramsList?.filter((item) =>
        userType?.includes(item.key)
      ).filter((item) => item?.key !== 'WorkshopsAndTraining')
    }
    return ProgramsList.filter((item) => !noPermission.includes(item.key))
  }
  const optionsFiltered = filteredProgramsList()
  const optionsFormater = optionsFiltered.map((item) => item.name)

  useEffect(() => {
    setProgramKey(optionsFiltered[tabIndex].key)
  }, [tabIndex])
  return (
    <>
      <Flex direction={'column'} px={10}>
        <Heading title={'NOTIFICACIONES DE PROCESO'} />
      </Flex>
      <Grid
        templateColumns={{
          lg: 'repeat(2, 20% 80%)',
          md: 'repeat(1, 100%)',
          sm: 'repeat(1, 100%)'
        }}
        gap={2}
      >
        <GridItem>
          <TabList
            options={optionsFormater}
            orientation="vertical"
            getIndex={setTabIndex}
            bg={'#eef5ef'}
            bgHover={'#bcc2bd'}
            color={'back'}
          />
        </GridItem>
        <GridItem>
          <NotificationsForms programKey={programKey} />
        </GridItem>
      </Grid>
    </>
  )
}

export default Notifications
