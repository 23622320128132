import React from 'react'
import { useHistory } from 'react-router-dom'
import { MenuOptions } from '../UI'
import { useMutateUser } from '../../hooks/Users'
import { GetNotification } from '../../Utils'

const OptionsMenu = ({ user }) => {
  const history = useHistory()
  const { mutate } = useMutateUser('DELETE')

  const handleDelete = () => {
    const { _id } = user
    mutate(_id, {
      onSuccess: () => {
        GetNotification.basic('Usuario eliminado con Éxito', 'Aceptar')
      },
      onError: () => {
        GetNotification.basic(
          'Error',
          'Aceptar',
          'Error al eliminar usuario',
          'error'
        )
      }
    })
  }

  const toEdit = () => {
    history.push(`/users/edit/${user?._id}`)
  }

  const optionsMenu = [
    {
      text: 'Editar',
      function: () => toEdit()
    },
    {
      text: 'Eliminar',
      function: () => handleDelete()
    }
  ]

  return <MenuOptions optionsMenu={optionsMenu} />
}

export default OptionsMenu
