import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { Flex, Tooltip } from '@chakra-ui/react'
import { Button, SquareButton, TextAreaComtrol } from '../../../UI'

const DescriptionItem = ({ description, formik }) => {
  const initialValues = {
    id: '',
    title: '',
    description: ''
  }

  const formikItem = useFormik({
    initialValues,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: (values) => {
      formik.setValues({
        ...formik.values,
        moreDescriptions: formik.values.moreDescriptions.map((desc) => {
          if (description.id === desc.id) {
            return {
              ...values
            }
          }
          return desc
        })
      })
    }
  })

  const isValidDescriptionItem = () => {
    const isEquals = Boolean(
      formik.values.moreDescriptions.find((item) => item.id === description.id)
        .description === formikItem.values.description
    )

    const isEmpty = Boolean(formikItem.values.description !== '')

    return Boolean(isEquals && isEmpty)
  }

  const deleteNote = () => {
    formik.setValues({
      ...formik.values,
      moreDescriptions: formik.values.moreDescriptions.filter(
        (item) => item.id !== description.id
      )
    })
  }

  useEffect(() => {
    formikItem.setValues({
      ...formikItem.values,
      id: description.id,
      title: description.title,
      description: description.description
    })
  }, [description])

  return (
    <Flex
      direction={'column'}
      border={'1px solid #82b378'}
      p={2}
      my={2}
      borderRadius={5}
      bg={isValidDescriptionItem() ? '#F0FFF4' : '#FFFFF0'}
    >
      <Flex justify={'flex-end'}>
        <SquareButton onClick={deleteNote} size={'xs'}>
          x
        </SquareButton>
      </Flex>
      <TextAreaComtrol
        id={'description'}
        label={'Observación adicional'}
        isRequired={false}
        value={formikItem.values.description}
        onChange={formikItem.handleChange}
        error={Boolean(formikItem.errors.description)}
        errorText={formikItem.errors.description}
      />
      <Flex justify={'flex-end'}>
        <Tooltip
          label="Favor Validar! ->"
          placement="left"
          bg={'#d68c49'}
          isOpen={!isValidDescriptionItem()}
        >
          <Flex>
            <Button
              text={isValidDescriptionItem() ? 'Validado' : 'No validado'}
              py={1}
              px={2}
              onClick={formikItem.handleSubmit}
              size={'xs'}
            />
          </Flex>
        </Tooltip>
      </Flex>
    </Flex>
  )
}

export default DescriptionItem
