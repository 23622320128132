import { Divider, Grid, GridItem, Text } from '@chakra-ui/react'
import React from 'react'
import { FormatDate, GetDiffDates } from '../../../Utils'
import {
  CardFilePreview,
  CardStatus,
  InputComtrol,
  TextAreaComtrol
} from '../../UI'

const NewBornList = ({ program }) => (
  <>
    {program?.newbornList.map((item) => (
      <Grid
        templateColumns={'repeat(1, 1fr)'}
        border={'1px solid #2e7423'}
        borderRadius={8}
        p={4}
        my={2}
        gap={2}
      >
        {item?.isSpecialRegister && (
          <GridItem>
            <Text
              fontWeight={600}
              fontSize={'sm'}
              textAlign={'center'}
              color={'green.500'}
            >
              Registro extraordinario
            </Text>
          </GridItem>
        )}
        <GridItem>
          <Grid
            templateColumns={{
              lg: 'repeat(2, 1fr)',
              md: 'repeat(1, 1fr)',
              sm: 'repeat(1, 1fr)'
            }}
            gap={2}
          >
            <GridItem colSpan={2}>
              <InputComtrol
                label={'Nombre completo'}
                value={item?.name}
                isRequired={false}
                readOnly={true}
              />
            </GridItem>
            <GridItem>
              <InputComtrol
                label={'Fecha de nacimiento'}
                value={FormatDate(item?.babyDateBirth)}
                isRequired={false}
                readOnly={true}
              />
            </GridItem>
            <GridItem mt={'auto'} mb={'auto'}>
              <CardStatus
                subValue={'Nacido hace'}
                value={
                  item?.babyDateBirth
                    ? `Hace ${GetDiffDates(
                        item?.babyDateBirth,
                        'days',
                        program?.receptionDate
                      )}`
                    : '-'
                }
              />
            </GridItem>
          </Grid>
          <Text fontWeight={700} fontSize={'xl'}>
            Certificado de nacimiento
          </Text>
          <Grid
            templateColumns={{
              lg: 'repeat(1, 1fr)',
              md: 'repeat(1, 1fr)',
              sm: 'repeat(1, 1fr)'
            }}
            gap={2}
          >
            {item?.birthCertificate.map((doc) => (
              <GridItem key={doc.url}>
                <CardFilePreview name={doc?.name} url={doc.url} />
              </GridItem>
            ))}
          </Grid>
          {item?.isSpecialRegister && (
            <>
              <Divider p={2} />
              <Text fontWeight={700} fontSize={'xl'}>
                Documentos respaldatorios
              </Text>
              <Grid
                templateColumns={{
                  lg: 'repeat(1, 1fr)',
                  md: 'repeat(1, 1fr)',
                  sm: 'repeat(1, 1fr)'
                }}
                gap={2}
              >
                {item?.backupDocuments.map((doc) => (
                  <GridItem key={doc.url}>
                    <CardFilePreview name={doc?.name} url={doc.url} />
                  </GridItem>
                ))}
              </Grid>
              <Divider p={2} />
              <TextAreaComtrol
                label={'Observaciones'}
                value={item?.observations || 'Sin observaciones'}
                isRequired={false}
                readOnly={true}
              />
            </>
          )}
        </GridItem>
      </Grid>
    ))}
  </>
)

export default NewBornList
