import * as Yup from 'yup'
/* eslint-disable import/prefer-default-export */
export const partnerSchema = Yup.object().shape({
  name: Yup.string()
    .max(100, 'El nombre no puede tener más de 100 caracteres')
    .required('El nombre y apellido es requerido'),
  rut: Yup.string()
    .min(11, 'El Rut no es valido')
    .max(12, 'El Rut no es valido')
    .required('El Rut es Requerido'),
  code: Yup.string()
    .min(3, 'El código no puede tener menos de 3 caracteres')
    .max(8, 'El código no puede tener más de 8 caracteres')
    .required('El código es requerido')
    .test(
      'last-caracter',
      'El ultimo caracter del código debe ser una letra',
      (value) => {
        const lastChar = value.slice(-1)
        return /^[a-zA-Z]+$/.test(lastChar)
      }
    )
    .test(
      'start-code',
      'Los primeros caracteres antes del guión del código deben ser números',
      (value) => {
        const valueArray = value.split('-')
        const lengthFirstChar = valueArray[0].length
        const firstChar = value.slice(0, lengthFirstChar)
        return /^[0-9]+$/.test(firstChar)
      }
    ),
  grade: Yup.string().required('El Grado es Requerido'),
  zone: Yup.string().required('La zona es Requerida'),
  prefecture: Yup.string().required('La prefectura es Requerida'),
  endowment: Yup.string(),
  insertDate: Yup.string().required('La Fecha de Ingreso es Requerida'),
  phone: Yup.string()
    .min(8, 'El telefono no es valido')
    .max(9, 'El telefono no es valido'),
  email: Yup.string().email('Correo invalido'),
  tipcarsNames: Yup.array()
    .min(1, 'Se requieren al Menos 1 documentos')
    .required('La tipcars es Requerida'),
  registrationFormNames: Yup.array().min(
    1,
    'Se requieren al Menos 1 documentos'
  ),
  applicationNames: Yup.array()
})
