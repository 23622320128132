import { useEffect } from 'react'
import { useFormik } from 'formik'
import { addDays } from 'date-fns'
import { Grid, GridItem, Flex, Text, useToast } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import {
  InputComtrol,
  Button,
  Select,
  CustomToast,
  CustomDatePicker
} from '../UI'
import { familyMemberSchema, familyMemberEditSchema } from './Schema'
import { useMutateFamilyMember } from '../../hooks/FamilyMembers'
import {
  GetNotification,
  RelationshipList3,
  GetAge,
  FormatRut
} from '../../Utils'

const availableTo = [
  'ADMINISTRADOR',
  'ASISTENTE_SOCIAL',
  'DEPTO_CONTROL_GESTION_SOCIAL',
  'FINANZAS',
  'ABASTECIMIENTO'
]

const FormUpdateFamilyMember = ({ onClose, type, familyMember = null }) => {
  const toast = useToast()
  const { mutate, isLoading, reset } = useMutateFamilyMember(type)
  const { user } = useSelector((state) => state.auth)

  const initialValues = {
    fullName: '',
    rut: '',
    email: '',
    phone: '',
    relationship: RelationshipList3.at(0),
    age: '',
    birthDate: ''
  }

  const valuesToFormData = (values) => {
    const formData = new FormData()

    Object.keys(values).forEach((key) => {
      formData.append(key, values[key])
    })

    return formData
  }

  const formik = useFormik({
    initialValues,
    validationSchema:
      type === 'CREATE' ? familyMemberSchema : familyMemberEditSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      const formData = valuesToFormData(values)

      if (type === 'CREATE') {
        mutate(formData, {
          onSuccess: () => {
            toast.closeAll()
            onClose()
            reset()
            formik.resetForm()
            GetNotification.basic('Familiar creado con Éxito', 'Aceptar')
          },
          onError: (err) => {
            toast.closeAll()
            onClose()
            console.log(err)
            GetNotification.basic(
              'Error',
              'Aceptar',
              'Error al Crear familiar',
              'error'
            )
          }
        })
      }
      if (type === 'UPDATE') {
        const { _id: familyMemberId } = familyMember

        mutate(
          { familyMemberId, formData },
          {
            onSuccess: () => {
              toast.closeAll()
              onClose()
              reset()
              GetNotification.basic('Familiar Actualizado con Éxito', 'Aceptar')
            },
            onError: (err) => {
              toast.closeAll()
              onClose()
              console.log(err)
              GetNotification.basic(
                'Error',
                'Aceptar',
                'Error al Actualizar familiar',
                'error'
              )
            }
          }
        )
      }
    }
  })

  const handleChangeRelationship = (item) => {
    formik.setFieldValue('relationship', item)
  }

  const handleChangeDateOfBirth = (date) => {
    formik.setValues({
      ...formik.values,
      birthDate: date,
      age: GetAge(date).toString()
    })
  }

  const handleChangeRut = () => {
    if (formik.values.rut) {
      const formatedRut = FormatRut(formik.values.rut)
      formik.setFieldValue('rut', formatedRut)
    }
  }

  useEffect(() => {
    if (type === 'UPDATE' && familyMember) {
      formik.setValues({
        fullName: familyMember.fullName || '',
        rut: familyMember?.rut || '',
        email: familyMember?.email || '',
        phone: familyMember?.phone || '',
        relationship: familyMember?.relationship,
        age: familyMember?.age,
        birthDate: familyMember?.birthDate || ''
      })
    }
  }, [type])

  useEffect(() => {
    if (isLoading) {
      toast({
        duration: 9000 * 9000,
        isClosable: true,
        position: 'bottom-right',
        render: ({ onClose: onCloseT }) => (
          <CustomToast
            text={'Espere por favor...'}
            status={'info'}
            onClose={onCloseT}
          />
        )
      })
      return
    }
    toast.closeAll()
  }, [isLoading])

  return (
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem w={'100%'} color={'green.500'}>
        {type === 'UPDATE' && (
          <Text fontWeight={700} fontSize={22} color={'green.500'} mb={4}>
            INFORMACIÓN
          </Text>
        )}
        <Flex mb={3} align={{ lg: 'row', md: 'column', sm: 'column' }} gap={2}>
          <InputComtrol
            id={'fullName'}
            label={'Nombre Completo'}
            value={formik.values.fullName}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.fullName)}
            errorText={formik.errors.fullName}
          />
        </Flex>
        <Flex mb={3} align={{ lg: 'row', md: 'column', sm: 'column' }} gap={2}>
          <InputComtrol
            id={'rut'}
            label={'Rut'}
            value={formik.values.rut}
            onChange={formik.handleChange}
            onBlur={handleChangeRut}
            error={Boolean(formik.errors.rut)}
            errorText={formik.errors.rut}
          />
          <InputComtrol
            id={'email'}
            label={'Correo Electrónico'}
            type={'email'}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.email)}
            errorText={formik.errors.email}
          />
        </Flex>
        <Flex mb={3} align={{ lg: 'row', md: 'column', sm: 'column' }} gap={2}>
          <InputComtrol
            id={'phone'}
            label={'Teléfono'}
            type={'number'}
            addictionType={'phone'}
            value={formik.values.phone}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.phone)}
            errorText={formik.errors.phone}
          />
          <InputComtrol
            id={'relationship'}
            label={'Parentesco'}
            isRequired={false}
          >
            <Select
              bg={'white'}
              list={RelationshipList3}
              value={formik.values.relationship}
              onChange={handleChangeRelationship}
            />
          </InputComtrol>
        </Flex>
        <Flex mb={3} align={{ lg: 'row', md: 'column', sm: 'column' }} gap={2}>
          <InputComtrol id={'birthDate'} label={'Fecha de nacimiento'}>
            <CustomDatePicker
              placeholder={'DD/MM/AAAA'}
              currentDate={formik.values.birthDate}
              error={Boolean(formik.errors.birthDate)}
              errorText={formik.errors.birthDate}
              onChange={handleChangeDateOfBirth}
              maxDate={addDays(new Date(), 0)}
            />
          </InputComtrol>
          <InputComtrol
            id={'age'}
            label={'Edad'}
            type={'number'}
            isRequired={false}
            value={formik.values.age}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.age)}
            errorText={formik.errors.age}
            disabled={true}
          />
        </Flex>
        <Flex justify={'flex-end'} align={'end'}>
          <Button
            text={'Cancelar'}
            bg={'beige.500'}
            bghover={'beige.600'}
            color={'green.500'}
            px={10}
            onClick={onClose}
          ></Button>

          <Button
            text={type === 'CREATE' ? 'Guardar' : 'Actualizar'}
            px={10}
            onClick={formik?.handleSubmit}
            isLoading={isLoading}
            disabled={!availableTo.includes(user.role)}
          ></Button>
        </Flex>
      </GridItem>
    </Grid>
  )
}

export default FormUpdateFamilyMember
