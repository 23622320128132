import Layout from '../components/Layout/Layout'
import Partners from '../pages/Partners'
import Create from '../pages/Partners/Create'
import Upload from '../pages/Partners/UploadMasive'
import CreateNotValidate from '../pages/Partners/CreateNotValidate'
import Profile from '../pages/Partners/Profile'
/* --- */
import Can from '../auth/Can'
import Forbidden from '../pages/Forbidden'
import Login from '../pages/Login'

const rootName = 'partners'

const partnersRoutes = [
  {
    path: `/${rootName}`,
    key: 'PARTNERS',
    exact: true,
    component: ({ authenticated }) => (
      <Can
        availableTo={[
          'ADMINISTRADOR',
          'ASISTENTE_SOCIAL',
          'DEPTO_CONTROL_GESTION_SOCIAL',
          'FINANZAS',
          'ABASTECIMIENTO',
          'ASISTENTE_SOCIAL',
          'ASESOR_JURIDICO',
          'GERENCIA',
          'PRESIDENCIA',
          'ADMINISTRATIVE-MULTIPLE-PROGRAMS',
          'ADMINISTRATIVE-HOSPITAL-ACCOMMODATION',
          'ADMINISTRATIVE-INUJRED-IN-LINES-OF-SERVICE-KIT',
          'ADMINISTRATIVE-WORKSHOPS-AND-TRAINING',
          'ADMINISTRATIVE-NEWBORN-BENEFIT',
          'REVISION'
        ]}
        yes={() => (
          <Layout>
            <Partners />
          </Layout>
        )}
        no={() => (authenticated ? <Forbidden /> : <Login />)}
      />
    )
  },
  {
    path: `/${rootName}/create`,
    key: 'PARTNERS-CREATE',
    exact: true,
    component: ({ authenticated }) => (
      <Can
        availableTo={[
          'ADMINISTRADOR',
          'ASISTENTE_SOCIAL',
          'DEPTO_CONTROL_GESTION_SOCIAL',
          'FINANZAS',
          'ABASTECIMIENTO'
        ]}
        yes={() => (
          <Layout>
            <Create />
          </Layout>
        )}
        no={() => (authenticated ? <Forbidden /> : <Login />)}
      />
    )
  },
  {
    path: `/${rootName}/upload`,
    key: 'PARTNERS-UPLOAD-MASIVE',
    exact: true,
    component: ({ authenticated }) => (
      <Can
        availableTo={[
          'ADMINISTRADOR',
          'ASISTENTE_SOCIAL',
          'DEPTO_CONTROL_GESTION_SOCIAL',
          'FINANZAS',
          'ABASTECIMIENTO'
        ]}
        yes={() => (
          <Layout>
            <Upload />
          </Layout>
        )}
        no={() => (authenticated ? <Forbidden /> : <Login />)}
      />
    )
  },
  {
    path: `/${rootName}/create/not-validated`,
    key: 'PARTNERS-CREATE-NOT-VALIDATED',
    exact: true,
    component: ({ authenticated }) => (
      <Can
        availableTo={[
          'ADMINISTRADOR',
          'ASISTENTE_SOCIAL',
          'DEPTO_CONTROL_GESTION_SOCIAL',
          'FINANZAS',
          'ABASTECIMIENTO'
        ]}
        yes={() => (
          <Layout>
            <CreateNotValidate />
          </Layout>
        )}
        no={() => (authenticated ? <Forbidden /> : <Login />)}
      />
    )
  },
  {
    path: `/${rootName}/profile/:partnerId/:optionDefault`,
    key: 'PARTNERS-PROFILE',
    exact: true,
    component: ({ authenticated }) => (
      <Can
        availableTo={[
          'ADMINISTRADOR',
          'ASISTENTE_SOCIAL',
          'DEPTO_CONTROL_GESTION_SOCIAL',
          'FINANZAS',
          'ABASTECIMIENTO',
          'ASISTENTE_SOCIAL',
          'ASESOR_JURIDICO',
          'GERENCIA',
          'PRESIDENCIA',
          'ADMINISTRATIVE-MULTIPLE-PROGRAMS',
          'ADMINISTRATIVE-HOSPITAL-ACCOMMODATION',
          'ADMINISTRATIVE-INUJRED-IN-LINES-OF-SERVICE-KIT',
          'ADMINISTRATIVE-WORKSHOPS-AND-TRAINING',
          'ADMINISTRATIVE-NEWBORN-BENEFIT',
          'REVISION'
        ]}
        yes={() => (
          <Layout>
            <Profile />
          </Layout>
        )}
        no={() => (authenticated ? <Forbidden /> : <Login />)}
      />
    )
  }
]
export default partnersRoutes
