/* eslint-disable import/prefer-default-export */
import axios from '../Axios'

const module = '/auth'

export const forgotPassword = async (formData) => {
  const { data } = await axios.put(`${module}/forgot-password`, formData)
  return data
}

export const resetPassword = async (formData) => {
  const { data } = await axios.put(`${module}/reset-password`, formData)
  return data
}
