import React from 'react'
import { Stack, Skeleton } from '@chakra-ui/react'

const AveragePerYearAndMonthSkeleton = () => (
  <Stack>
    <Skeleton height="200px" borderRadius={12} />
    <Skeleton height="20px" borderRadius={12} />
  </Stack>
)

export default AveragePerYearAndMonthSkeleton
