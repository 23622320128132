import React, { useState, useEffect } from 'react'
import { Grid, GridItem, Text } from '@chakra-ui/react'
import { ButtonDotted } from '../../../UI'
import NewBornForm from './NewBornForm'

const StepTwo = ({
  refStepTwo,
  stepOneForm,
  stepTwoForm,
  setStepTwoForm,
  next
}) => {
  const [children, setChildren] = useState([])

  const handleAddChildren = () => {
    const childrenTemplate = {
      _id: Date.now().toString(),
      name: '',
      babyDateBirth: '',
      birthCertificateName: [],
      birthCertificate: null,
      isSpecialRegister: false,
      backupDocumentsName: [],
      backupDocuments: null,
      observations: '',
      relationship: 'Hijo/Hija',
      updated: false
    }
    setChildren([...children, childrenTemplate])
  }

  const validateEmptyChildren = () => {
    if (children.length > 0) {
      const exists = children.find((item) => item.updated === false)
      return Boolean(exists)
    }
    return false
  }

  const handleDeleteChildren = (_id) => {
    const index = children.findIndex((item) => item._id === _id)
    if (index !== -1) {
      const newChildren = [...children]
      newChildren.splice(index, 1)
      setChildren(newChildren)
    }
  }

  const handleUpdateChildren = (id, values, isValid = true) => {
    const index = children.findIndex((item) => item._id === id)
    const newChildren = [...children]
    if (index !== -1 && isValid) {
      const updated = {
        _id: id,
        name: values.name,
        babyDateBirth: values.babyDateBirth,
        birthCertificateName: values.birthCertificateName,
        birthCertificate: values.birthCertificate,
        state: values.state,
        isSpecialRegister: values.isSpecialRegister,
        backupDocumentsName: values.backupDocumentsName,
        backupDocuments: values.backupDocuments,
        observations: values.observations,
        relationship: 'Hijo/Hija',
        updated: true
      }

      newChildren.splice(index, 1, updated)
      setChildren(newChildren)
    }
    if (index !== -1 && !isValid) {
      newChildren[index].updated = false
      setChildren(newChildren)
    }
  }

  const handleSubmit = () => {
    if (children.length > 0 && !validateEmptyChildren()) {
      const filteredForms = children.filter((item) => item.updated === true)
      setStepTwoForm(filteredForms)
      next()
    }
  }

  useEffect(() => {
    refStepTwo.current = handleSubmit
  }, [children])

  useEffect(() => {
    if (stepTwoForm.length > 0) {
      setChildren(stepTwoForm)
    }
  }, [stepTwoForm])

  return (
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem>
        <Text fontWeight={700} color={'green.500'} fontSize={'xl'}>
          Información del Hijo/s Recién Nacido
        </Text>
        {children.map((item) => (
          <NewBornForm
            key={item._id}
            _id={item._id}
            childrens={children}
            children={item}
            receptionDate={stepOneForm?.receptionDate}
            handleUpdateChildren={handleUpdateChildren}
            onClick={(_id) => handleDeleteChildren(_id)}
          />
        ))}
        <ButtonDotted
          text={'Añadir Registro'}
          onClick={handleAddChildren}
          disabled={validateEmptyChildren()}
        />
      </GridItem>
    </Grid>
  )
}

export default StepTwo
