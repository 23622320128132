import * as Yup from 'yup'
/* eslint-disable import/prefer-default-export */
export const stepOneSchema = Yup.object().shape({
  isPartnerZone: Yup.boolean(),
  zone: Yup.string().required('La zona es requerida'),
  prefecture: Yup.string().required('La prefectura es requerida'),
  unit: Yup.string().required('La unidad es requerida'),
  receptionDate: Yup.date().required('La fecha de recepción es requerida'),
  medicalSpeciality: Yup.string().required(
    'La especialidad médica es requerida'
  ),
  incomeDate: Yup.date().required('La fecha de ingreso es requerida'),
  exitDate: Yup.date().required('La fecha de salida es requerida'),
  isRequiredExtension: Yup.boolean(),
  isPatientPartner: Yup.boolean(),
  parientId: Yup.string(),
  patientFullName: Yup.string().when('isPatientPartner', {
    is: true,
    then: Yup.string(),
    otherwise: Yup.string()
      .max(40, 'El nombre del huésped es muy largo')
      .required('El nombre del huésped es requerido')
  }),
  patientAge: Yup.string().when('isPatientPartner', {
    is: true,
    then: Yup.string(),
    otherwise: Yup.string().required('La edad del huésped es requerida')
  }),
  patientRelationship: Yup.string().when('isPatientPartner', {
    is: true,
    then: Yup.string(),
    otherwise: Yup.string().required('El parentezco del huésped es requerido')
  }),
  toCreate: Yup.boolean(),
  /* partner data */
  partnerRut: Yup.string()
    .min(11, 'El Rut no es valido')
    .max(12, 'El Rut no es valido'),
  partnerPhone: Yup.string()
    .min(8, 'El telefono no es valido')
    .max(9, 'El telefono no es valido')
    .required('El telefono es requerido'),
  partnerGrade: Yup.string(),
  partnerCode: Yup.string()
    .min(3, 'El código no puede tener menos de 3 caracteres')
    .max(8, 'El código no puede tener más de 8 caracteres')
    .test(
      'last-caracter',
      'El ultimo caracter del código debe ser una letra',
      (value) => {
        if (!value) {
          return true
        }
        const lastChar = value?.slice(-1)
        return /^[a-zA-Z]+$/.test(lastChar)
      }
    )
    .test(
      'start-code',
      'Los primeros caracteres antes del guión del código deben ser números',
      (value) => {
        if (!value) {
          return true
        }
        const valueArray = value.split('-')
        const lengthFirstChar = valueArray[0].length
        const firstChar = value.slice(0, lengthFirstChar)
        return /^[0-9]+$/.test(firstChar)
      }
    ),
  partnerZone: Yup.string(),
  partnerPrefecture: Yup.string(),
  partnerEndowment: Yup.string()
})

export const stepTwoSchema = Yup.object().shape({
  isRequiredAdditionalGuest: Yup.boolean(),
  isRequiredAddMoreGuest: Yup.boolean(),
  additionalGuest: Yup.array()
})

export const itemStepTwoSchema = Yup.object().shape({
  _id: Yup.string(),
  guestFullName: Yup.string()
    .max(40, 'El nombre del huésped es muy largo')
    .required('El nombre del huésped es requerido'),
  guestAge: Yup.string().required(' La edad del huésped es requerida'),
  guestRelationship: Yup.string().required(
    'El parentesco del huésped es requerido'
  ),
  isValid: Yup.boolean(),
  toCreate: Yup.boolean(),
  isDuplicate: Yup.boolean().test(
    'is-duplicate',
    'El huésped ya existe',
    (value) => !value
  )
})

export const stepThreeSchema = Yup.object().shape({
  observations: Yup.string(),
  backupDocumentsNames: Yup.array()
    .min(1, 'Debe existir al menos un documento')
    .required('Debe existir al menos un documento')
})
