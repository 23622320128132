import * as Yup from 'yup'
/* eslint-disable import/prefer-default-export */
export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Ingrese un Email Valido')
    .required('El Correo es Requerido'),
  password: Yup.string().required()
})

export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().required('Este campo es requerido'),
  repeatEmail: Yup.string()
    .required('Este campo es requerido')
    .when('email', (email, schema) =>
      schema.test({
        test: (repeatEmail) => email === repeatEmail,
        message: 'Los correos no coinciden'
      })
    )
})
