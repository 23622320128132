/* eslint-disable import/prefer-default-export */
import axios from '../../Axios'
import { module } from './module'

const typeForm = '/support-case-death-member'

export const createNewProgram = async (formData) => {
  const { data } = await axios.post(`${module}${typeForm}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
  return data
}

export const updateProgram = async ({ programId, formData }) => {
  const { data } = await axios.patch(
    `${module}/${typeForm}/${programId}`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
  )
  return data
}
