import React from 'react'
import { Checkbox, Grid, GridItem, Text } from '@chakra-ui/react'
import { InputComtrol } from '../../UI'

const ApplicantInformation = ({ program }) => (
  <>
    <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
      <GridItem>
        <Text fontWeight={600}>Solicitante:</Text>
        <Checkbox
          css={{ borderColor: 'green' }}
          size="md"
          colorScheme="green"
          value={program?.isPartnerApplicant}
          isChecked={program?.isPartnerApplicant}
          py={2}
          readOnly={true}
        >
          ¿El solicitante es el socio?
        </Checkbox>
      </GridItem>

      {!program?.isPartnerApplicant &&
        program?.applicants?.map((item) => (
          <GridItem border={'1px solid #82b378'} borderRadius={8} p={2}>
            <Grid templateColumns={'repeat(1, 1fr)'}>
              <GridItem>
                <InputComtrol
                  label={'Nombres y apellidos'}
                  isRequired={false}
                  value={item?.applicant.fullName}
                  readOnly={true}
                />
              </GridItem>
            </Grid>
            {/* --- */}
            <Grid
              templateColumns={{
                lg: 'repeat(2, 1fr)',
                md: 'repeat(1, 1fr)',
                sm: 'repeat(1, 1fr)'
              }}
              gap={2}
            >
              <GridItem>
                <InputComtrol
                  label={'Edad'}
                  isRequired={false}
                  value={item?.applicant.age}
                  readOnly={true}
                />
              </GridItem>
              <GridItem>
                <InputComtrol
                  label={'Parentesco'}
                  isRequired={false}
                  value={item?.applicant.relationship}
                  readOnly={true}
                />
              </GridItem>
            </Grid>
          </GridItem>
        ))}
    </Grid>
  </>
)

export default ApplicantInformation
