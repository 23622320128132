import Roles from '../resources/Roles'

const getUserType = (type) => {
  let roleName = ''
  Roles.map((rol) => {
    if (type === rol.key) {
      roleName = rol.name
    }
    return rol
  })
  return roleName
}

export default getUserType
