import { useEffect } from 'react'
import { Box, Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import {
  InputComtrol,
  Select,
  SquareButton,
  TextAreaComtrol
} from '../../../UI'
import { Age, RelationshipList3 } from '../../../../Utils'

const ApplicantItem = ({ applicant, formik, nro }) => {
  const relationShipFiltered = () => {
    const wifeInApplicants = formik.values.applicants?.find(
      (item) =>
        item.applicantRelationship === 'Cónyuge' &&
        item.applicantId !== applicant.applicantId
    )

    if (wifeInApplicants) {
      return RelationshipList3.filter((item) => item !== 'Cónyuge')
    }

    return RelationshipList3
  }

  const handleChange = (fieldName, value) => {
    const applicantsCopy = formik.values.applicants?.map((item) => {
      if (item.applicantId === applicant.applicantId) {
        const editItem = {
          [fieldName]: value
        }
        item = {
          ...item,
          ...editItem
        }
        return item
      }
      return item
    })

    formik.setFieldValue('applicants', applicantsCopy)
  }

  const deleteItem = () => {
    const newApplicants = formik.values.applicants?.filter(
      (item) => item.applicantId !== applicant.applicantId
    )
    formik.setFieldValue('applicants', newApplicants)
  }

  useEffect(() => {
    if (applicant.applicantRelationship !== 'Otro') {
      handleChange('applicantRelationshipSpecification', '')
    }
  }, [applicant.applicantRelationship])

  return (
    <Box p={2} border="1px solid #82B378" borderRadius={8}>
      <Grid
        templateColumns={{
          lg: 'repeat(1, 1fr)'
        }}
        gap={2}
        mb={4}
      >
        <GridItem>
          <Flex justify="space-between">
            <Text fontWeight={700} color={'green.500'} fontSize={'xl'}>
              Información del solicitante - {nro}
            </Text>
            <SquareButton size={'xs'} onClick={deleteItem}>
              x
            </SquareButton>
          </Flex>
        </GridItem>
      </Grid>
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          <InputComtrol
            id={'applicantFullName'}
            label={'Nombre completo del familiar'}
            value={applicant.applicantFullName}
            onChange={(e) => handleChange('applicantFullName', e.target.value)}
            /* error={Boolean(formik.errors.applicantFullName)}
                  errorText={formik.errors.applicantFullName} */
          />
          <InputComtrol id={'applicantRelationship'} label={'Parentesco'}>
            <Select
              bg={'white'}
              list={relationShipFiltered()}
              value={applicant.applicantRelationship}
              onChange={(v) => handleChange('applicantRelationship', v)}
            />
          </InputComtrol>
        </GridItem>
        <GridItem>
          <InputComtrol id={'applicantAge'} label={'Edad del familiar'}>
            <Select
              bg={'white'}
              list={Age}
              value={applicant.applicantAge}
              onChange={(v) => handleChange('applicantAge', v)}
            />
          </InputComtrol>
          {applicant.applicantRelationship === 'Otro' && (
            <InputComtrol
              id={'applicantRelationshipSpecification'}
              label={'Carga familiar'}
              value={applicant.applicantRelationshipSpecification}
              onChange={(e) =>
                handleChange(
                  'applicantRelationshipSpecification',
                  e.target.value
                )
              }
              /* error={Boolean(formik.errors.applicantFullName)}
                  errorText={formik.errors.applicantFullName} */
            />
          )}
        </GridItem>
        <GridItem colSpan={{ lg: 2, md: undefined, sm: undefined }}>
          <TextAreaComtrol
            label={'Diagnóstico'}
            value={applicant?.diagnosis}
            id={'diagnosis'}
            onChange={(e) => handleChange('diagnosis', e.target.value)}
          />
        </GridItem>
      </Grid>
    </Box>
  )
}

export default ApplicantItem
