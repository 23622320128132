const recidenceCondition = [
  'Propietario',
  'Allegado',
  'Arrendatario',
  'Fiscal',
  'Adquirente',
  'Otros'
]

export default recidenceCondition
