import * as Yup from 'yup'
/* eslint-disable import/prefer-default-export */

export const applicant = Yup.object().shape({
  applicantId: Yup.string(),
  applicantFullName: Yup.string().when('isPartnerApplicant', {
    is: true,
    then: Yup.string(),
    otherwise: Yup.string()
      .max(40, 'El nombre del familiar es muy largo')
      .required('El nombre del familiar es requerido')
  }),
  applicantAge: Yup.string().when('isPartnerApplicant', {
    is: true,
    then: Yup.string(),
    otherwise: Yup.string().required('La edad del familiar es requerida')
  }),
  applicantRelationship: Yup.string().when('isPartnerApplicant', {
    is: true,
    then: Yup.string(),
    otherwise: Yup.string().required('El parentezco del familiar es requerido')
  }),
  applicantRelationshipSpecification: Yup.string().when(
    'applicantRelationship',
    {
      is: 'Otro',
      then: Yup.string().required('La carga es requerido'),
      otherwise: Yup.string()
    }
  ),
  diagnosis: Yup.string().required('El diagnóstico es requerido'),
  toCreate: Yup.boolean()
})

export const steepOneSchema = Yup.object().shape({
  receptionDate: Yup.date().required('La fecha de recepción es requerida'),
  alternativePhone: Yup.string()
    .min(8, 'El telefono no es valido')
    .max(9, 'El telefono no es valido'),
  isPartnerZone: Yup.boolean(),
  zone: Yup.string().required('La zona es requerida'),
  prefecture: Yup.string().required('La prefectura es requerida'),
  unit: Yup.string().required('La unidad es requerida').nullable(),
  /* partner data */
  partnerRut: Yup.string()
    .min(11, 'El Rut no es valido')
    .max(12, 'El Rut no es valido'),
  partnerPhone: Yup.string()
    .min(8, 'El telefono no es valido')
    .max(9, 'El telefono no es valido')
    .required('El telefono es requerido'),
  partnerGrade: Yup.string(),
  partnerCode: Yup.string()
    .min(3, 'El código no puede tener menos de 3 caracteres')
    .max(8, 'El código no puede tener más de 8 caracteres')
    .test(
      'last-caracter',
      'El ultimo caracter del código debe ser una letra',
      (value) => {
        if (!value) {
          return true
        }
        const lastChar = value?.slice(-1)
        return /^[a-zA-Z]+$/.test(lastChar)
      }
    )
    .test(
      'start-code',
      'Los primeros caracteres antes del guión del código deben ser números',
      (value) => {
        if (!value) {
          return true
        }
        const valueArray = value.split('-')
        const lengthFirstChar = valueArray[0].length
        const firstChar = value.slice(0, lengthFirstChar)
        return /^[0-9]+$/.test(firstChar)
      }
    ),
  partnerZone: Yup.string(),
  partnerPrefecture: Yup.string(),
  partnerEndowment: Yup.string(),
  isPartnerApplicant: Yup.boolean(),
  applicants: Yup.array()
    .of(applicant)
    .when(
      ('isPartnerApplicant',
      {
        is: false,
        then: Yup.array()
          .of(applicant)
          .min(1, 'Es requerido agregar al menos 1 familiar')
          .required(),
        otherwise: Yup.array()
      })
    )
})

export const steepTwoSchema = Yup.object().shape({
  salarySettlementsName: Yup.array()
    .min(1, 'Se requieren al menos un archivo')
    .max(3, 'Máximo pueden ser 3 archivos')
    .required('Las últimas 3 liquidaciones de sueldo son requeridas'),
  medicalCertificateName: Yup.array(),
  institutionDocumentationName: Yup.array(),
  partnerPersonalRequestName: Yup.array()
    .min(1, 'Se requieren al menos un archivo')
    .required('La solicitud personal del socio es requerida'),
  prescriptionName: Yup.array(),
  socialReportName: Yup.array()
    .min(1, 'Se requieren al menos un archivo')
    .required('El informe social es requerido'),
  quotationName: Yup.array(),
  debtCertificateName: Yup.array(),
  authorizationToRequestDebtName: Yup.array()
  // senderOfficeName: Yup.array()
  //   .min(1, 'Se requieren al menos un archivo')
  //   .required('El oficio remisor es requerido')
})

export const steepThreeSchema = Yup.object().shape({
  observations: Yup.string()
})
