import React from 'react'
import { Grid, GridItem, Tr, Td, Flex } from '@chakra-ui/react'
import { Table, Spinner, Alert, Button } from '../UI'
import GetStatusCard from '../../Utils/GetStatusCard'
import { DownloadIcon } from '../Icons'

import WithoutResults from '../WithoutResults'

const TablePartners = ({
  page,
  setPage,
  limit,
  partnersSync,
  isLoading,
  isFetching,
  isError
}) => {
  const headers = ['#', 'Año', 'Mes', 'Status', 'Reporte']

  const downloadReport = (url) => window.open(url, '_blank')

  return (
    <>
      {partnersSync?.currentPages?.length === 0 && (
        <WithoutResults type={'SYNC-TABLE'} />
      )}
      {partnersSync?.currentPages?.length > 0 && (
        <>
          <Grid templateColumns={'repeat(1, 1fr)'} w={'100%'}>
            <Flex w={'100%'} justify={'flex-end'} py={3}>
              <Spinner size={'lg'} show={isLoading || isFetching} />
            </Flex>
            {isError && (
              <Flex w={'100%'} justify={'flex-end'} py={3}>
                <Alert status={'error'} message={'Error al Cargar los Datos'} />
              </Flex>
            )}
            <GridItem
              overflowX={'auto'}
              display={{
                lg: 'table',
                md: 'break-word',
                sm: 'break-word'
              }}
              css={{
                '&::-webkit-scrollbar': {
                  width: '4px'
                },
                '&::-webkit-scrollbar-track': {
                  width: '4px'
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#2E7423',
                  borderRadius: '30px'
                }
              }}
            >
              <Table
                headers={headers}
                totalRecords={partnersSync?.totalRecords}
                page={page}
                setPage={setPage}
              >
                {partnersSync?.currentPages?.map((item, index) => (
                  <Tr key={item._id}>
                    <Td
                      fontWeight={700}
                      color={'grey.500'}
                      textAlign={'center'}
                    >
                      {index + 1 + page * limit}
                    </Td>
                    <Td color={'grey.500'} w={'25%'}>
                      {item?.year}
                    </Td>
                    <Td color={'grey.500'} w={'25%'}>
                      {item?.month}
                    </Td>
                    <Td w={'25%'}>{GetStatusCard.sync(item?.state)}</Td>
                    <Td color={'grey.500'}>
                      {item?.report?.length > 0 && (
                        <Button
                          text={'Descargar Reporte'}
                          icon={DownloadIcon}
                          onClick={() => downloadReport(item?.report[0].url)}
                        />
                      )}
                    </Td>
                  </Tr>
                ))}
              </Table>
            </GridItem>
          </Grid>
        </>
      )}
    </>
  )
}

export default TablePartners
