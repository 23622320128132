import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import { BarFileName, UploadFile, TextAreaComtrol } from '../../UI'
import { steepFourSchema } from './Schemas'
import { Files } from '../../../Utils'

const StepFour = ({ stepFourForm, setStepFourForm, refStepFour, state }) => {
  const initialValues = {
    aditionalDocumentsNames: [],
    aditionalDocuments: null,
    observations: ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: steepFourSchema,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: (values) => {
      setStepFourForm({
        ...values,
        state
      })
    }
  })

  const deleteFile = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.aditionalDocumentsNames,
      formik.values.aditionalDocuments
    )

    formik.setValues({
      ...formik.values,
      aditionalDocumentsNames: resultNames,
      aditionalDocuments: resultListFiles
    })
  }

  const handleChangeAditionalsDocuments = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.aditionalDocumentsNames,
      formik.values.aditionalDocuments
    )

    formik.setValues({
      ...formik.values,
      aditionalDocumentsNames: newListNames,
      aditionalDocuments: newListFiles
    })
    e.target.value = null
  }

  useEffect(() => {
    refStepFour.current = formik.handleSubmit
  }, [])

  useEffect(() => {
    formik.setValues({
      aditionalDocumentsNames: stepFourForm.aditionalDocumentsNames || [],
      aditionalDocuments: stepFourForm.aditionalDocuments || null,
      observations: stepFourForm.observations || ''
    })
  }, [stepFourForm])

  return (
    <>
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
        <GridItem>
          <Text fontWeight={700}>Documentos adicionales (opcional)</Text>
          <UploadFile
            direction={'row'}
            multiple={true}
            sizeIcon={30}
            p={5}
            onChange={(e) => {
              handleChangeAditionalsDocuments(e)
            }}
            error={Boolean(formik.errors.aditionalDocumentsNames)}
            errorText={formik.errors.aditionalDocumentsNames}
          />
          <Flex direction={'column'} gap={1}>
            {formik.values.aditionalDocumentsNames.map((item) => (
              <BarFileName
                key={item?.name}
                fileName={item?.name}
                fileUrl={item?.url}
                onDelete={() => deleteFile(item?.name)}
              />
            ))}
          </Flex>
        </GridItem>
        <GridItem>
          <TextAreaComtrol
            id={'observations'}
            label={'Observaciones de la solicitud (opcional)'}
            value={formik.values.observations}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.observations)}
            errorText={formik.errors.observations}
          />
        </GridItem>
      </Grid>
    </>
  )
}
export default StepFour
