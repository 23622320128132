import React from 'react'
import { useParams } from 'react-router-dom'
import { Flex } from '@chakra-ui/react'
import {
  BackButton,
  Heading,
  Spinner,
  Alert,
  Progress
} from '../../../components/UI'
import FormCreateUsers from '../../../components/FormCreateUsers'
import { useUser } from '../../../hooks/Users'

/* eslint arrow-body-style: ["error", "always"] */
const Edit = () => {
  const { userId } = useParams()
  const { data: user, isFetching, isLoading, error } = useUser(userId)

  return (
    <>
      {user ? (
        <Flex direction={'column'} px={10}>
          <BackButton path={'/users'} />
          <Heading title={'Editar Usuario'} />
          {error && (
            <Flex align={'center'} ml={'auto'}>
              <Alert status={'error'} message={'Error al Cargar Los Datos'} />
            </Flex>
          )}
          {(isLoading || isFetching) && (
            <Flex align={'center'} ml={'auto'}>
              <Spinner size={'lg'} />
            </Flex>
          )}
          <FormCreateUsers type={'UPDATE'} user={user} />
        </Flex>
      ) : (
        <Progress />
      )}
    </>
  )
}

export default Edit
