import React, { useEffect } from 'react'
import { addDays } from 'date-fns'
import { useFormik } from 'formik'
import { Grid, GridItem, Box, Text, Flex } from '@chakra-ui/react'
import {
  InputComtrol,
  CustomDatePicker,
  UploadFile,
  BarFileName,
  Select
} from '../../UI'
import { steepTwoSchema } from './Schemas'
import { Files, GetAge, ScoreAvg } from '../../../Utils'

const StepTwo = ({ refStepTwo, stepTwoForm, setStepTwoForm, next }) => {
  const initialValues = {
    postulantName: '',
    postulantDateOfBirth: '',
    postulantAverage: ScoreAvg.at(0),
    postulantPhone: '',
    postulantScoreName: [],
    postulantScore: null,
    schoolName: '',
    postulantAge: '',
    postulantAdress: '',
    postulantEmail: '',
    postulantCertificateName: [],
    postulantCertificate: null
  }

  const formik = useFormik({
    initialValues,
    validateOnMount: false,
    validateOnChange: false,
    validationSchema: steepTwoSchema,
    onSubmit: (values) => {
      setStepTwoForm({
        ...values
      })
      next()
    }
  })

  const handleChangeDate = (date) => {
    formik.setValues({
      ...formik.values,
      postulantDateOfBirth: date,
      postulantAge: GetAge(date).toString() || ''
    })
  }

  const handleChangePostulantAverage = (value) => {
    formik.setFieldValue('postulantAverage', value)
  }

  const deletePostulantScore = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.postulantScoreName,
      formik.values.postulantScore
    )

    formik.setValues({
      ...formik.values,
      postulantScoreName: resultNames,
      postulantScore: resultListFiles
    })
  }

  const deletePostulantCertificate = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.postulantCertificateName,
      formik.values.postulantCertificate
    )

    formik.setValues({
      ...formik.values,
      postulantCertificateName: resultNames,
      postulantCertificate: resultListFiles
    })
  }

  const handleChangeScore = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames: resultNames, newListFiles: files } =
      getListNamesAndListFiles(
        e.currentTarget,
        formik.values.postulantScoreName,
        formik.values.postulantScore
      )

    formik.setValues({
      ...formik.values,
      postulantScoreName: resultNames,
      postulantScore: files
    })
    e.target.value = null
  }
  const handleChangeCertificate = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames: resultNames, newListFiles: files } =
      getListNamesAndListFiles(
        e.currentTarget,
        formik.values.postulantCertificateName,
        formik.values.postulantCertificate
      )
    formik.setValues({
      ...formik.values,
      postulantCertificateName: resultNames,
      postulantCertificate: files
    })
    e.target.value = null
  }

  useEffect(() => {
    refStepTwo.current = formik.handleSubmit
  }, [])

  useEffect(() => {
    formik.setValues({
      postulantName: stepTwoForm?.postulantName || '',
      postulantDateOfBirth: stepTwoForm?.postulantDateOfBirth || '',
      postulantAverage: stepTwoForm?.postulantAverage || ScoreAvg.at(0),
      postulantPhone: stepTwoForm?.postulantPhone || '',
      postulantScoreName: stepTwoForm?.postulantScoreName || [],
      postulantScore: stepTwoForm?.postulantScore || null,
      schoolName: stepTwoForm?.schoolName || '',
      postulantAge: stepTwoForm?.postulantAge || '',
      postulantAdress: stepTwoForm?.postulantAdress || '',
      postulantEmail: stepTwoForm?.postulantEmail || '',
      postulantCertificateName: stepTwoForm?.postulantCertificateName || [],
      postulantCertificate: stepTwoForm?.postulantCertificate || null
    })
  }, [stepTwoForm])

  return (
    <Grid
      templateColumns={{
        lg: 'repeat(2, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem>
        <Text fontWeight={700} color={'green.500'} fontSize={'xl'}>
          Información del postulante a beca
        </Text>
        <InputComtrol
          id={'postulantName'}
          label={'Nombre Completo del postulante'}
          value={formik.values.postulantName}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.postulantName)}
          errorText={formik.errors.postulantName}
        />
        <InputComtrol id={'postulantDateOfBirth'} label={'Fecha de Nacimiento'}>
          <CustomDatePicker
            placeholder={'Fecha de Nacimiento'}
            currentDate={formik.values.postulantDateOfBirth}
            onChange={(date) => handleChangeDate(date)}
            error={Boolean(formik.errors.postulantDateOfBirth)}
            errorText={formik.errors.postulantDateOfBirth}
            maxDate={addDays(new Date(), 0)}
          />
        </InputComtrol>
        <InputComtrol
          id={'postulantAverage'}
          label={'Promedio general Educ. Media'}
        >
          <Select
            bg={'white'}
            list={ScoreAvg}
            value={formik.values.postulantAverage}
            onChange={handleChangePostulantAverage}
          />
        </InputComtrol>
        <InputComtrol
          id={'schoolName'}
          label={'Colegio donde estudia'}
          value={formik.values.schoolName}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.schoolName)}
          errorText={formik.errors.schoolName}
        />
        <InputComtrol
          id={'postulantPhone'}
          label={'Teléfono de contacto (postulante)'}
          type={'number'}
          addictionType={'phone'}
          value={formik.values.postulantPhone}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.postulantPhone)}
          errorText={formik.errors.postulantPhone}
        />

        <Text color={'green.100'} fontSize={'sm'}>
          Certificados de concentración de notas de 1°, 2° y 3° medio*
        </Text>
        <UploadFile
          direction={'row'}
          sizeIcon={20}
          p={3}
          id={`postulantScoreName`}
          onChange={(e) => {
            handleChangeScore(e)
          }}
          error={Boolean(formik.errors.postulantScoreName)}
          errorText={formik.errors.postulantScoreName}
        />
        <Flex direction={'column'} gap={1}>
          {formik.values.postulantScoreName.map((item) => (
            <BarFileName
              key={item?.name}
              fileName={item?.name}
              fileUrl={item?.url}
              onDelete={() => deletePostulantScore(item?.name)}
            />
          ))}
        </Flex>
      </GridItem>

      <GridItem>
        <Text fontSize={'xl'} opacity={0}>
          -----
        </Text>

        <InputComtrol
          id={'postulantAdress'}
          label={'Domicilio'}
          placeholder={'Calle, número, región'}
          value={formik.values.postulantAdress}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.postulantAdress)}
          errorText={formik.errors.postulantAdress}
        />
        <InputComtrol
          id={'postulantAge'}
          label={'Edad'}
          type={'number'}
          value={formik.values.postulantAge}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.postulantAge)}
          errorText={formik.errors.postulantAge}
          disabled={true}
        />
        <InputComtrol
          id={'postulantEmail'}
          label={'Correo electrónico'}
          type={'email'}
          value={formik.values.postulantEmail}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.postulantEmail)}
          errorText={formik.errors.postulantEmail}
        />
        <Box
          opacity={0}
          display={{
            lg: 'block',
            sm: 'none',
            md: 'none'
          }}
        >
          <InputComtrol id={''} label={''} value={''} readOnly={true} />
        </Box>
        <Box
          opacity={0}
          display={{
            lg: 'block',
            sm: 'none',
            md: 'none'
          }}
        >
          <InputComtrol id={''} label={''} value={''} readOnly={true} />
        </Box>

        <Text color={'green.100'} fontSize={'sm'}>
          Adjuntar certificado de nacimiento del postulante*
        </Text>
        <UploadFile
          direction={'row'}
          sizeIcon={20}
          p={3}
          id={`postulantCertificateName`}
          onChange={(e) => {
            handleChangeCertificate(e)
          }}
          error={Boolean(formik.errors.postulantCertificateName)}
          errorText={formik.errors.postulantCertificateName}
        />
        <Flex direction={'column'} gap={1}>
          {formik.values.postulantCertificateName.map((item) => (
            <BarFileName
              key={item?.name}
              fileName={item?.name}
              fileUrl={item?.url}
              onDelete={() => deletePostulantCertificate(item?.name)}
            />
          ))}
        </Flex>
      </GridItem>
    </Grid>
  )
}

export default StepTwo
