import React, { useState, useEffect } from 'react'
import { Portal } from 'react-overlays'
import {
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Tooltip
} from '@chakra-ui/react'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './styles.css'
import es from 'date-fns/locale/es'
import { getMonth, getYear } from 'date-fns'
import range from 'lodash/range'
import { CalendarIcon } from '../../Icons'

registerLocale('es', es)

const CustomDatePicker = ({
  id,
  placeholder,
  onChange,
  currentDate,
  disabled = false,
  error,
  errorText,
  ...props
}) => {
  const [startDate, setStartDate] = useState(new Date())
  const years = range(1950, getYear(new Date()) + 2, 1)
  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ]

  const CalendarContainer = ({ children }) => {
    const el = document.getElementById('calendar-portal')

    return <Portal container={el}>{children}</Portal>
  }

  const handleChange = (date) => {
    setStartDate(date)
    onChange(date.toISOString())
  }

  const getPlaceholder = () => {
    if (errorText) return errorText
    if (placeholder) return placeholder
    return 'DD/MM/AAAA'
  }

  useEffect(() => {
    if (currentDate) {
      setStartDate(new Date(currentDate))
    } else {
      setStartDate(null)
    }
  }, [currentDate])

  return (
    <DatePicker
      id={id || 'date-pickerID'}
      popperContainer={CalendarContainer}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled
      }) => (
        <Flex mb={5} justify={'center'} gap={2}>
          <Button
            p={0}
            bg={'transparent'}
            _hover={{ bg: 'green.100' }}
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
          >
            {'<'}
          </Button>
          <Select
            value={getYear(date)}
            onChange={({ target: { value } }) => changeYear(value)}
            focusBorderColor={'green.500'}
          >
            {years.map((option) => (
              <option
                key={option}
                value={option}
                style={{ background: '#F1ECE2', color: '#2E7423' }}
              >
                {option}
              </option>
            ))}
          </Select>

          <Select
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
            focusBorderColor={'green.500'}
          >
            {months.map((option) => (
              <option
                key={option}
                value={option}
                style={{ background: '#F1ECE2', color: '#2E7423' }}
              >
                {option}
              </option>
            ))}
          </Select>

          <Button
            p={0}
            bg={'transparent'}
            _hover={{ bg: 'green.100' }}
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
          >
            {'>'}
          </Button>
        </Flex>
      )}
      selected={startDate}
      onChange={(date) => {
        handleChange(date)
      }}
      customInput={
        <InputGroup>
          <Tooltip hasArrow label={errorText} bg="red.600" borderRadius={4}>
            <Input
              value={startDate ? startDate.toLocaleDateString() : ''}
              readOnly={true}
              focusBorderColor={'green.100'}
              isInvalid={error}
              _placeholder={errorText && { color: '#C53030' }}
              disabled={disabled}
              placeholder={getPlaceholder()}
            />
          </Tooltip>

          <InputRightElement
            children={<Icon as={CalendarIcon} />}
            opacity={disabled ? 0.3 : 1}
          />
        </InputGroup>
      }
      locale={'es'}
      dateFormat={"dd 'de' MMMM 'de' yyyy"}
      disabled={disabled}
      {...props}
    />
  )
}

export default CustomDatePicker
