import React from 'react'
import { Grid, GridItem, Checkbox, Flex } from '@chakra-ui/react'
import { CustomDatePicker, InputComtrol } from '../../../UI'
import RenderArrayDateSubmissionReceipts from './RenderArrayDateSubmissionReceipts'

const RenderVouchersRequired = ({ formik }) => {
  const handleChangeDateSubmissionReceipts = (date) => {
    formik.setFieldValue('dateSubmissionReceipts', date)
  }

  const handleChangeDateSend = (date) => {
    formik.setFieldValue('dateSend', date)
  }

  return (
    <Flex direction={'column'} gap={2} my={2}>
      {formik.values.isRequiredCheckVouchersSend && (
        <>
          <InputComtrol
            id={'isRequiredCheckVouchersSend'}
            label={''}
            isRequired={false}
          >
            <Grid
              templateColumns={{
                lg: 'repeat(1, 1fr)',
                md: 'repeat(1, 1fr)',
                sm: 'repeat(1, 1fr)'
              }}
              gap={2}
            >
              <GridItem>
                <Checkbox
                  css={{ borderColor: 'green' }}
                  id={'vouchersSend'}
                  size="md"
                  colorScheme="green"
                  value={formik.values.vouchersSend}
                  isChecked={formik.values.vouchersSend}
                  onChange={formik.handleChange}
                >
                  Comprobantes enviados
                </Checkbox>
              </GridItem>
              <GridItem>
                <Checkbox
                  css={{ borderColor: 'green' }}
                  id={'invoiceRequest'}
                  size="md"
                  colorScheme="green"
                  value={formik.values.invoiceRequest}
                  isChecked={formik.values.invoiceRequest}
                  onChange={formik.handleChange}
                >
                  Se solicitó factura
                </Checkbox>
              </GridItem>
            </Grid>
          </InputComtrol>
        </>
      )}
      {formik.values.isRequiredDateSubmissionReceipts && (
        <InputComtrol
          id={'dateSubmissionReceipts'}
          label={'Fecha de envío de comprobantes'}
        >
          <CustomDatePicker
            placeholder={'Fecha de envío de comprobantes'}
            currentDate={formik.values.dateSubmissionReceipts}
            onChange={(date) => handleChangeDateSubmissionReceipts(date)}
            error={Boolean(formik.errors.dateSubmissionReceipts)}
            errorText={formik.errors.dateSubmissionReceipts}
          />
        </InputComtrol>
      )}
      {formik.values.isRequiredDateSubmissionReceiptsArr && (
        <InputComtrol
          id={'dateSubmissionReceiptsArr'}
          label={'Fecha de envío de comprobantes'}
        >
          <RenderArrayDateSubmissionReceipts formik={formik} />
        </InputComtrol>
      )}
      {formik.values.isRequiredDateSend && (
        <InputComtrol id={'dateSend'} label={'Fecha de envío'}>
          <CustomDatePicker
            placeholder={'Fecha de envío'}
            currentDate={formik.values.dateSend}
            onChange={(date) => handleChangeDateSend(date)}
            error={Boolean(formik.errors.dateSend)}
            errorText={formik.errors.dateSend}
          />
        </InputComtrol>
      )}
    </Flex>
  )
}

export default RenderVouchersRequired
