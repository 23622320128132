import React from 'react'
import { Grid, GridItem, Text } from '@chakra-ui/react'
import { TextAreaComtrol, CardFilePreview } from '../../UI'

const AdditionalInformation = ({ program }) => (
  <>
    <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
      <GridItem>
        <TextAreaComtrol
          label={'Observaciones de la solicitud'}
          value={program?.observations || 'Sin observaciones'}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
    </Grid>
    {program?.aditionalDocuments.length > 0 && (
      <>
        <Text>Documentos adicionales</Text>
        <Grid
          templateColumns={{
            lg: 'repeat(1, 1fr)',
            md: 'repeat(1, 1fr)',
            sm: 'repeat(1, 1fr)'
          }}
          gap={2}
        >
          {program?.aditionalDocuments.map((item) => (
            <GridItem>
              <CardFilePreview name={item.name} url={item.url} />
            </GridItem>
          ))}
        </Grid>
      </>
    )}
  </>
)

export default AdditionalInformation
