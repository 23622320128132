import React from 'react'
import { Flex, Text } from '@chakra-ui/react'
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts'
import { FormatMoney } from '../../../../Utils'

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Flex
        bg={'white'}
        p={2}
        border={'1px solid #cccccc'}
        direction={'column'}
        textAlign={'center'}
        className="custom-tooltip"
      >
        <Text fontWeight={700}>{`$ ${FormatMoney(
          payload[0]?.payload?.total
        )}`}</Text>
        <Text>{label}</Text>
      </Flex>
    )
  }

  return null
}

const MonthAreaChart = ({ data }) => (
  <ResponsiveContainer width="100%" height="100%">
    <AreaChart width={400} height={400} data={data}>
      <defs>
        <linearGradient id="fillGradient" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#0077B6" stopOpacity={0.8} />
          <stop offset="95%" stopColor="#0077B6" stopOpacity={0} />
        </linearGradient>
      </defs>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="month" />
      <YAxis tick={false} />
      <Tooltip content={<CustomTooltip />} />
      <Area
        type="monotone"
        dataKey="total"
        stroke="#0077B6"
        fillOpacity={1}
        fill="url(#fillGradient)"
      />
    </AreaChart>
  </ResponsiveContainer>
)

export default MonthAreaChart
