import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Flex, Text, Avatar, Icon, Box } from '@chakra-ui/react'
import { RedirectIcon } from '../Icons'
import { GetDiffDates } from '../../Utils'
import { useUpdateAlerts } from '../../hooks/Alerts'

const AlertItem = ({ alert, user }) => {
  const history = useHistory()
  const location = useLocation()
  const { mutate } = useUpdateAlerts('UPDATE')

  const toProgramDetail = () => {
    const { _id, program, type } = alert
    mutate(_id, {
      onSuccess: () => {
        if (type === 'SYSTEM') {
          history.push('/partners/upload')
        }

        if (type === 'UPLOAD-MASSIVE-PROGRAM') {
          history.push('/programs')
        }

        if (type === 'USER') {
          const redirectTo = `/programs/view/${program?._id}/${program?.type}`
          if (location.pathname === redirectTo) {
            window.location.reload()
            return
          }
          history.push(redirectTo)
        }
      },
      onError: () => {
        console.log('Error in update alert')
      }
    })
  }

  return (
    <Box w={'100%'} position={'relative'} py={1}>
      <Flex
        bg={!alert?.seenBy?.includes(user?._id) ? 'beige.50' : 'white'}
        border={'1px solid #D5D5D5'}
        direction={'column'}
        w={'-webkit-fill-available'}
        p={2}
        borderRadius={12}
        onClick={toProgramDetail}
      >
        <Flex justify={'space-between'}>
          <Flex gap={2}>
            <Avatar
              borderRadius={5}
              size={'sm'}
              bg={'green.100'}
              color={'white'}
              name={`${alert?.user?.name} ${alert?.user?.lastName}`}
            />
            <Box fontSize={'md'} color={'black'}>
              <Text noOfLines={1} fontWeight={700}>
                {`${alert?.user?.name} ${alert?.user?.lastName}`}
              </Text>
              <Text noOfLines={1}>{`${alert?.description}`}</Text>
              <Text noOfLines={1} fontWeight={700}>{`${alert?.nextStep}`}</Text>
            </Box>
          </Flex>
          <Icon as={RedirectIcon} size={'md'} color={'#9E9E9E'} />
        </Flex>
        {/* --- */}

        <Flex justify={'space-between'} pl={10} mb={4}>
          <Flex direction={'column'} color={'#9E9E9E'} fontSize={'xs'}>
            {alert?.type !== 'SYSTEM' && (
              <>
                <Text
                  noOfLines={1}
                >{`Beneficio: ${alert?.program?.programName}`}</Text>
                <Text noOfLines={1}>
                  {`Socio: ${alert?.program?.partner?.name || 'NO APLICA'}`}
                </Text>
              </>
            )}
          </Flex>
          <Text color={'#9E9E9E'} fontSize={'xs'} noOfLines={1} mt={'auto'}>
            {GetDiffDates(alert?.createdAt)}
          </Text>
        </Flex>
      </Flex>
      {!alert?.seenBy?.includes(user?._id) && (
        <Avatar
          position={'absolute'}
          top={'-5px'}
          right={'-5px'}
          bg={'red.500'}
          borderRadius={'50%'}
          size={'2xs'}
          name={' '}
        />
      )}
    </Box>
  )
}

export default AlertItem
