import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { Checkbox, Grid, GridItem, Text } from '@chakra-ui/react'
import { InputComtrol, Select, TextAreaComtrol } from '../../UI'
import { steepTwoSchema } from './Schemas'
import { RelationshipList3 } from '../../../Utils'

const StepTwo = ({ stepTwoForm, setStepTwoForm, refStepTwo, next }) => {
  const initialValues = {
    isPartnerAffected: false,
    fullName: '',
    relationship: RelationshipList3.at(0),
    phoneDirectMember: '',
    description: ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: steepTwoSchema,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: (values) => {
      setStepTwoForm({
        ...values
      })
      next()
    }
  })

  const handleChangeRelationship = (value) => {
    formik.setFieldValue('relationship', value)
  }

  useEffect(() => {
    refStepTwo.current = formik.handleSubmit
  }, [])

  useEffect(() => {
    formik.setValues({
      isPartnerAffected: stepTwoForm.isPartnerAffected || false,
      fullName: stepTwoForm.fullName || '',
      relationship: stepTwoForm.relationship || RelationshipList3.at(0),
      phoneDirectMember: stepTwoForm.phoneDirectMember || '',
      description: stepTwoForm.description || ''
    })
  }, [stepTwoForm])

  return (
    <>
      <Grid gridTemplateColumns={'repeat(1, 1fr)'} gap={2} my={2}>
        <GridItem>
          <Text fontWeight={700} color={'green.500'} fontSize={'xl'}>
            Individualización de afectado
          </Text>
        </GridItem>
        <GridItem>
          <Checkbox
            css={{ borderColor: 'green' }}
            size="md"
            colorScheme="green"
            id={'isPartnerAffected'}
            value={formik.values.isPartnerAffected}
            isChecked={formik.values.isPartnerAffected}
            onChange={formik.handleChange}
          >
            ¿El afectado es el Socio?
          </Checkbox>
        </GridItem>
      </Grid>
      {!formik.values.isPartnerAffected && (
        <>
          <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
            <GridItem>
              <InputComtrol
                id={'fullName'}
                label={'Nombres y apellidos'}
                value={formik.values.fullName}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.fullName)}
                errorText={formik.errors.fullName}
              />
            </GridItem>
          </Grid>
          <Grid
            templateColumns={{
              lg: 'repeat(2, 1fr)',
              md: 'repeat(1, 1fr)',
              sm: 'repeat(1, 1fr)'
            }}
            gap={2}
          >
            <GridItem>
              <InputComtrol id={'relationship'} label={'Parentesco'}>
                <Select
                  bg={'white'}
                  list={RelationshipList3}
                  value={formik.values.relationship}
                  onChange={handleChangeRelationship}
                />
              </InputComtrol>
            </GridItem>
            <GridItem>
              <InputComtrol
                id={'phoneDirectMember'}
                label={'Teléfono de contacto del familiar o pariente directo'}
                value={formik.values.phoneDirectMember}
                type={'number'}
                addictionType={'phone'}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.phoneDirectMember)}
                errorText={formik.errors.phoneDirectMember}
              />
            </GridItem>
          </Grid>
        </>
      )}
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
        <GridItem>
          <TextAreaComtrol
            id={'description'}
            label={'Descripción breve de la emergencia'}
            value={formik.values.description}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.description)}
            errorText={formik.errors.description}
          />
        </GridItem>
      </Grid>
    </>
  )
}

export default StepTwo
