import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addDays } from 'date-fns'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Icon,
  Text
} from '@chakra-ui/react'
import { CustomDatePicker, Search, SquareButton, Select2 } from '../UI'
import { ReplayIcon } from '../Icons'
import { GetStatus, ProgramsList, TypeCreationList } from '../../Utils'
import { usePrograms, useProgramsHistory } from '../../hooks/Programs'
import { updateFilters, resetFilters } from '../../store/slices/programs'

const FiltersPrograms = ({ children }) => {
  const { user } = useSelector((state) => state.auth)
  const { filters } = useSelector((state) => state.programs)
  const dispatch = useDispatch()

  const { data: history } = useProgramsHistory(filters.program)

  console.log(filters.program)

  const filteredProgramsList = () => {
    const { userType } = user
    const newProgramsList = [...ProgramsList]

    if (!userType?.includes('ALL')) {
      return newProgramsList?.filter((item) => userType?.includes(item.key))
    }
    return newProgramsList
  }

  const handleChangeSearch = (e) => {
    dispatch(updateFilters({ ...filters, search: e.target.value }))
  }

  const handleChangeStartDate = (date) => {
    dispatch(updateFilters({ ...filters, startDate: date }))
  }

  const handleChangeEndDate = (date) => {
    dispatch(updateFilters({ ...filters, endDate: date }))
  }

  const handleChangeStatus = (state) => {
    dispatch(updateFilters({ ...filters, state }))
  }

  const handleChangeProgram = (program) => {
    dispatch(updateFilters({ ...filters, step: '', program }))
  }

  const handleChangeTypeCreation = (typeCreation) => {
    dispatch(updateFilters({ ...filters, typeCreation }))
  }

  const handleChangeStep = (step) => {
    dispatch(updateFilters({ ...filters, step }))
  }

  return (
    <Flex gap={4} direction={'column'}>
      <Flex
        gap={3}
        justify={'space-between'}
        align={'center'}
        direction={{
          lg: 'row',
          md: 'column',
          sm: 'column'
        }}
      >
        <Box>
          <Search
            onChange={handleChangeSearch}
            currentValue={filters.search}
            showBtn={false}
          />
        </Box>

        {children}
      </Flex>
      <Accordion defaultIndex={[0]} allowToggle>
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              <Text whiteSpace={'nowrap'} fontWeight={700} color={'green.500'}>
                Filtros:
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <Flex gap={3} direction={'column'}>
              <Flex
                gap={4}
                align={'center'}
                justify={'space-between'}
                direction={{
                  lg: 'row',
                  md: 'column-reverse',
                  sm: 'column-reverse'
                }}
              >
                <Flex
                  gap={4}
                  direction={{
                    lg: 'row',
                    md: 'column',
                    sm: 'column'
                  }}
                >
                  <Flex w={400} gap={2} align={'center'}>
                    <Text whiteSpace={'nowrap'} fontWeight={700}>
                      Fecha desde:
                    </Text>
                    <CustomDatePicker
                      placeholder={'DD/MM/AAAA'}
                      currentDate={filters.startDate}
                      onChange={(date) => {
                        handleChangeStartDate(date)
                      }}
                      maxDate={addDays(new Date(), 0)}
                    />
                  </Flex>
                  <Flex w={400} gap={2} align={'center'}>
                    <Text whiteSpace={'nowrap'} fontWeight={700}>
                      Fecha hasta:
                    </Text>
                    <CustomDatePicker
                      placeholder={'DD/MM/AAAA'}
                      currentDate={filters.endDate}
                      onChange={(date) => {
                        handleChangeEndDate(date)
                      }}
                      maxDate={addDays(new Date(), 1)}
                    />
                  </Flex>
                </Flex>
                <Box
                  marginRight={{
                    lg: 0,
                    md: 'auto',
                    sm: 'auto'
                  }}
                >
                  <SquareButton onClick={() => dispatch(resetFilters())}>
                    <Icon as={ReplayIcon} />
                  </SquareButton>
                </Box>
              </Flex>
              <Flex gap={2}>
                <Box
                  w={{
                    lg: '25%',
                    md: '100%',
                    sm: '100%'
                  }}
                >
                  <Text whiteSpace={'nowrap'} fontWeight={700}>
                    Programa:
                  </Text>
                  <Select2
                    list={[
                      { key: '', name: 'TODOS' },
                      ...filteredProgramsList()
                    ]}
                    onChange={handleChangeProgram}
                    value={filters?.program}
                  />
                </Box>
                <Box
                  w={{
                    lg: '25%',
                    md: '100%',
                    sm: '100%'
                  }}
                >
                  <Text whiteSpace={'nowrap'} fontWeight={700}>
                    Estado de la solicitud:
                  </Text>
                  <Select2
                    list={GetStatus.list}
                    onChange={handleChangeStatus}
                    value={filters?.state}
                  />
                </Box>
                <Box
                  w={{
                    lg: '25%',
                    md: '100%',
                    sm: '100%'
                  }}
                >
                  <Text whiteSpace={'nowrap'} fontWeight={700}>
                    Tipo de creación:
                  </Text>
                  <Select2
                    list={TypeCreationList}
                    onChange={handleChangeTypeCreation}
                    value={filters?.typeCreation}
                  />
                </Box>
                {filters?.program && (
                  <Box
                    w={{
                      lg: '25%',
                      md: '100%',
                      sm: '100%'
                    }}
                  >
                    <Text whiteSpace={'nowrap'} fontWeight={700}>
                      Pasos del programa:
                    </Text>
                    <Select2
                      list={[
                        { key: '', name: 'TODOS' },
                        ...(history?.history?.map((item) => ({
                          key: item.key,
                          name: item.title
                        })) || [])
                      ]}
                      onChange={handleChangeStep}
                      value={filters?.step}
                    />
                  </Box>
                )}
              </Flex>
            </Flex>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Flex>
  )
}

export default FiltersPrograms
