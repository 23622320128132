import React from 'react'
import { Grid, GridItem, Text } from '@chakra-ui/react'
import { CardFilePreview } from '../../UI'

const Documents = ({ program }) => (
  <>
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      {program?.registerSocial.map((item) => (
        <GridItem key={item?.name} py={3}>
          <Text fontWeight={700} fontSize={'xl'}>
            Ficha social
          </Text>
          <CardFilePreview name={item?.name} url={item.url} />
        </GridItem>
      ))}
    </Grid>
  </>
)

export default Documents
