import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { Flex, Text, CloseButton, Image } from '@chakra-ui/react'

import Logo from '../../assets/images/logo.png'
import Logout from '../../assets/images/logout.svg'
import MadeWithLove from '../../assets/images/made-with-love-foreach.gif'

import Menu from './Menu'

import { logoutUser } from '../../store/slices/auth'
import { GetNotification } from '../../Utils'

const SidebarContent = ({ onClose, ...rest }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const handleLogout = () => {
    dispatch(logoutUser())
    history.push('/login')
  }

  return (
    <Flex
      borderTopRightRadius="40"
      borderBottomRightRadius="40"
      bgColor="#F1ECE2"
      w={{ base: 'full', md: '60' }}
      pos="fixed"
      h="100vh"
      align={'felx-end'}
      justify={'center'}
      {...rest}
    >
      <Flex direction={'column'} h="98vh">
        <Flex h="40" alignItems="center" mx="8" justifyContent="center">
          <img src={Logo} width="150" />
          <CloseButton
            display={{ base: 'flex', md: 'none' }}
            onClick={onClose}
          />
        </Flex>
        <Flex direction={'column'}>
          <Menu />
        </Flex>
        <Flex
          h="20"
          align={'flex-end'}
          justify={'center'}
          _hover={{ cursor: 'pointer' }}
          mt={'auto'}
          onClick={() =>
            GetNotification.question(
              '¿Seguro que deseas cerrar sesión?',
              () => handleLogout(),
              null,
              '',
              'warning'
            )
          }
        >
          <img src={Logout} />
          <Text
            fontSize="16px"
            color="#365531"
            fontWeight="bold"
            paddingLeft="2"
          >
            Cerrar sesión
          </Text>
        </Flex>
        <Flex mt={'5'} align={'flex-end'} justify={'center'}>
          <Image src={MadeWithLove} alt="logo" w={110} />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default SidebarContent
