import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import { BarFileName, UploadFile, TextAreaComtrol } from '../../UI'
import { steepSixSchema } from './Schemas'
import { Files } from '../../../Utils'

const StepFive = ({ stepSixForm, setStepSixForm, refStepSix, state }) => {
  const initialValues = {
    registerSocialName: [],
    registerSocial: null,
    additionalDocumentsNames: [],
    additionalDocuments: null,
    observationsRequest: ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: steepSixSchema,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: (values) => {
      if (!state) {
        setStepSixForm({
          ...values
        })
      }
      if (state) {
        setStepSixForm({
          ...values,
          state
        })
      }
    }
  })

  const deleteRegisterSocial = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.registerSocialName,
      formik.values.registerSocial
    )

    formik.setValues({
      ...formik.values,
      registerSocialName: resultNames,
      registerSocial: resultListFiles
    })
  }

  const deleteAdditionalsDocuments = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.additionalDocumentsNames,
      formik.values.additionalDocuments
    )

    formik.setValues({
      ...formik.values,
      additionalDocumentsNames: resultNames,
      additionalDocuments: resultListFiles
    })
  }

  const handleChangeRegisterSocial = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.registerSocialName,
      formik.values.registerSocial
    )

    formik.setValues({
      ...formik.values,
      registerSocialName: newListNames,
      registerSocial: newListFiles
    })
    e.target.value = null
  }

  const handleChangeAdditionalsDocuments = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.additionalDocumentsNames,
      formik.values.additionalDocuments
    )

    formik.setValues({
      ...formik.values,
      additionalDocumentsNames: newListNames,
      additionalDocuments: newListFiles
    })
    e.target.value = null
  }

  useEffect(() => {
    refStepSix.current = formik.handleSubmit
  }, [])

  useEffect(() => {
    formik.setValues({
      registerSocialName: stepSixForm.registerSocialName || [],
      registerSocial: stepSixForm.registerSocial || null,
      additionalDocumentsNames: stepSixForm.additionalDocumentsNames || [],
      additionalDocuments: stepSixForm.additionalDocuments || null,
      observationsRequest: stepSixForm.observationsRequest || ''
    })
  }, [stepSixForm])

  return (
    <>
      <Grid
        templateColumns={{
          lg: 'repeat(1, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          <Text fontWeight={700}>Formulario social</Text>
          <UploadFile
            id={'registerSocialNames'}
            direction={'row'}
            sizeIcon={30}
            p={5}
            onChange={(e) => {
              handleChangeRegisterSocial(e)
            }}
            error={Boolean(formik.errors.registerSocialName)}
            errorText={formik.errors.registerSocialName}
          />
          <Flex direction={'column'} gap={1}>
            {formik.values.registerSocialName.map((item) => (
              <BarFileName
                key={item?.name}
                fileName={item?.name}
                fileUrl={item?.url}
                onDelete={() => deleteRegisterSocial(item?.name)}
              />
            ))}
          </Flex>
        </GridItem>
        <GridItem>
          <Text fontWeight={700}>Documentos adicionales (opcional)</Text>
          <UploadFile
            id={'additionalDocumentsNames'}
            direction={'row'}
            multiple={true}
            sizeIcon={30}
            p={5}
            onChange={(e) => {
              handleChangeAdditionalsDocuments(e)
            }}
            error={Boolean(formik.errors.additionalDocumentsNames)}
            errorText={formik.errors.additionalDocumentsNames}
          />
          <Flex direction={'column'} gap={1}>
            {formik.values.additionalDocumentsNames.map((item) => (
              <BarFileName
                key={item?.name}
                fileName={item?.name}
                fileUrl={item?.url}
                onDelete={() => deleteAdditionalsDocuments(item?.name)}
              />
            ))}
          </Flex>
        </GridItem>
      </Grid>
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
        <GridItem>
          <TextAreaComtrol
            id={'observationsRequest'}
            isRequired={false}
            label={'Observaciones de la solicitud (opcional)'}
            value={formik.values.observationsRequest}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.observationsRequest)}
            errorText={formik.errors.observationsRequest}
          />
        </GridItem>
      </Grid>
    </>
  )
}
export default StepFive
