import { Flex, Icon, Text } from '@chakra-ui/react'
import React from 'react'
import { PhoneIcon } from '../../../Icons'

const PhoneContainer = ({
  partnerFullName,
  phone,
  alternativePhone = null,
  phonePostulant = null
}) => (
  <Flex direction={'column'} gap={5}>
    <Flex align={'center'} justify={'flex-start'} gap={5}>
      <Text fontWeight={700} fontSize={'md'}>
        Socio:
      </Text>
      <Text fontSize={'sm'}>{partnerFullName}</Text>
    </Flex>
    <Flex gap={8}>
      <Flex direction={'column'} align={'start'} justify={'flex-start'} gap={1}>
        <Text fontWeight={700} fontSize={'md'}>
          Teléfono Principal:
        </Text>
        <Flex
          gap={4}
          bg={'green.50'}
          justify={'center'}
          align={'center'}
          borderRadius={10}
          p={1}
        >
          <Icon as={PhoneIcon} />
          +56 {phone}
        </Flex>
      </Flex>
      {alternativePhone && (
        <Flex
          direction={'column'}
          align={'start'}
          justify={'flex-start'}
          gap={1}
        >
          <Text fontWeight={700} fontSize={'md'}>
            Teléfono Alternativo:
          </Text>
          <Flex
            gap={4}
            bg={'green.50'}
            justify={'center'}
            align={'center'}
            borderRadius={10}
            p={1}
          >
            <Icon as={PhoneIcon} />
            +569 {alternativePhone}
          </Flex>
        </Flex>
      )}
    </Flex>
    {phonePostulant && (
      <Flex direction={'column'} align={'start'} justify={'flex-start'} gap={1}>
        <Flex
          direction={'column'}
          align={'start'}
          justify={'flex-start'}
          gap={1}
        >
          <Text fontWeight={700} fontSize={'md'}>
            Teléfono Postulante:
          </Text>
          <Flex
            gap={4}
            bg={'green.50'}
            justify={'center'}
            align={'center'}
            borderRadius={10}
            p={1}
          >
            <Icon as={PhoneIcon} />
            +569 {phonePostulant}
          </Flex>
        </Flex>
      </Flex>
    )}
  </Flex>
)

export default PhoneContainer
