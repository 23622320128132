import { createIcon } from '@chakra-ui/react'
/* https://freeicons.io/ui-essentials-3/ui-essentials-notification-alarm-bell-icon-44563 */
/* eslint-disable import/prefer-default-export */
const FileIcon = createIcon({
  displayName: 'FileIcon',
  viewBox: '0 0 28 28',
  path: [
    <path
      d="M16.5 10.6667H21.95C21.6591 9.89581 21.2071 9.19581 20.6242 8.61342L17.7209 5.70842C17.1378 5.1262 16.4376 4.67452 15.6667 4.38342V9.83342C15.6667 10.2936 16.0398 10.6667 16.5 10.6667Z"
      fill="#82B378"
    />,
    <path
      d="M22.3133 12.3333H16.5C15.1193 12.3333 14 11.214 14 9.83332V4.02C13.8658 4.01082 13.7317 4 13.5958 4H9.83333C7.53329 4.00277 5.66942 5.86664 5.66669 8.16668V19.8334C5.66942 22.1334 7.53329 23.9973 9.83333 24H18.1666C20.4667 23.9973 22.3306 22.1334 22.3333 19.8333V12.7375C22.3333 12.6017 22.3225 12.4675 22.3133 12.3333Z"
      fill="#82B378"
    />
  ]
})

export default FileIcon
