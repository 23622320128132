import { useEffect, useState } from 'react'
import { Switch, useHistory } from 'react-router-dom'
import { renderRoutes } from 'react-router-config'
import { useSelector, useDispatch } from 'react-redux'
import { logoutUser } from '../store/slices/auth'
import { useMutateMe } from '../hooks/login'

import routes from './routes'

function RenderRoutes() {
  const history = useHistory()
  const dispatch = useDispatch()
  const { isLoggedIn } = useSelector((state) => state.auth)
  const { mutate } = useMutateMe()
  const [token, setToken] = useState(window.localStorage.getItem('token'))

  useEffect(
    () => {
      setToken(window.localStorage.getItem('token'))
    },
    // eslint-disable-next-line
    [window.localStorage.getItem('token')]
  )

  useEffect(
    () => {
      if (token && isLoggedIn) {
        mutate()
      } else if (token && !isLoggedIn) {
        dispatch(logoutUser())
        window.localStorage.clear()
        history.push('/login')
      } else if (!token && isLoggedIn) {
        dispatch(logoutUser())
        window.localStorage.clear()
        history.push('/login')
      }
    },
    // eslint-disable-next-line
    []
  )

  return <Switch>{renderRoutes(routes, { authenticated: isLoggedIn })}</Switch>
}

export default RenderRoutes
