import React from 'react'
import { Grid, GridItem, Flex, Text } from '@chakra-ui/react'
import { Files } from '../../../../Utils'
import {
  BarFileName,
  CustomDatePicker,
  InputComtrol,
  UploadFile
} from '../../../UI'

const RenderDebtCertificateRequired = ({
  formik,
  typeProcess,
  currentStepKey
}) => {
  const handleChangeDateCertificateRequest = (date) => {
    formik.setFieldValue('dateCertificateRequest', date)
  }

  const handleChangeDateCertificateReception = (date) => {
    formik.setFieldValue('dateCertificateReception', date)
  }

  const deleteDocumentsSentMedicalInstitution = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.documentsSentMedicalInstitutionName,
      formik.values.documentsSentMedicalInstitution
    )

    formik.setValues({
      ...formik.values,
      documentsSentMedicalInstitutionName: resultNames,
      documentsSentMedicalInstitution: resultListFiles
    })
  }

  const handleChangeDocumentsSentMedicalInstitution = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.documentsSentMedicalInstitutionName,
      formik.values.documentsSentMedicalInstitution
    )

    formik.setValues({
      ...formik.values,
      documentsSentMedicalInstitutionName: newListNames,
      documentsSentMedicalInstitution: newListFiles
    })
    e.target.value = null
  }

  const deleteDebtCertificate = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.debtCertificateName,
      formik.values.debtCertificate
    )

    formik.setValues({
      ...formik.values,
      debtCertificateName: resultNames,
      debtCertificate: resultListFiles
    })
  }

  const handleChangeDebtCertificate = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.debtCertificateName,
      formik.values.debtCertificate
    )

    formik.setValues({
      ...formik.values,
      debtCertificateName: newListNames,
      debtCertificate: newListFiles
    })
    e.target.value = null
  }

  return (
    <Flex direction={'column'} gap={2} my={2}>
      {formik.values.isRequiredDocumentsSentMedicalInstitution && (
        <>
          <InputComtrol
            label={
              typeProcess.tariffDifference && typeProcess.service === 'Dipreca'
                ? 'Añade certificados de deuda enviado por Dipreca'
                : 'Añade documentos enviados por la institución médica.'
            }
          >
            {typeProcess.tariffDifference &&
              typeProcess.service !== 'Dipreca' && (
                <Text>
                  Debe contener datos para la factura y datos bancarios para el
                  pago.
                </Text>
              )}
            <UploadFile
              id={'documentsSentMedicalInstitutionName'}
              direction={'row'}
              sizeIcon={30}
              p={5}
              onChange={(e) => handleChangeDocumentsSentMedicalInstitution(e)}
              error={Boolean(formik.errors.documentsSentMedicalInstitutionName)}
              errorText={formik.errors.documentsSentMedicalInstitutionName}
            />
          </InputComtrol>
          {formik.values.documentsSentMedicalInstitutionName.map((item) => (
            <BarFileName
              key={item?.name}
              fileName={item?.name}
              fileUrl={item?.url}
              onDelete={() => deleteDocumentsSentMedicalInstitution(item?.name)}
            />
          ))}
        </>
      )}
      {(formik.values.isRequiredDebtCertificate ||
        currentStepKey === 'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND' ||
        currentStepKey ===
          'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND-EDIT-SOLIDARITY-HEALTH-FUND') && (
        <>
          <InputComtrol
            label={'Añade certificados de deudas'}
            isRequired={formik.values.isRequiredDebtCertificate}
          >
            <UploadFile
              id={'debtCertificateName'}
              direction={'row'}
              sizeIcon={30}
              p={5}
              onChange={(e) => handleChangeDebtCertificate(e)}
              error={Boolean(formik.errors.debtCertificateName)}
              errorText={formik.errors.debtCertificateName}
            />
          </InputComtrol>
          {formik.values.debtCertificateName.map((item) => (
            <BarFileName
              key={item?.name}
              fileName={item?.name}
              fileUrl={item?.url}
              onDelete={() => deleteDebtCertificate(item?.name)}
            />
          ))}
        </>
      )}
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        {formik.values.isRequiredDateCertificateRequest && (
          <GridItem>
            <InputComtrol
              id={'dateCertificateRequest'}
              label={'Fecha de solicitud de certificado'}
            >
              <CustomDatePicker
                placeholder={'Fecha de solicitud de certificado'}
                currentDate={formik.values.dateCertificateRequest}
                onChange={(date) => handleChangeDateCertificateRequest(date)}
                error={Boolean(formik.errors.dateCertificateRequest)}
                errorText={formik.errors.dateCertificateRequest}
              />
            </InputComtrol>
          </GridItem>
        )}
        {formik.values.isRequiredDateCertificateReception && (
          <GridItem>
            <InputComtrol
              id={'dateCertificateReception'}
              label={'Fecha de recepción de certificado'}
            >
              <CustomDatePicker
                placeholder={'Fecha de recepción de certificado'}
                currentDate={formik.values.dateCertificateReception}
                onChange={(date) => handleChangeDateCertificateReception(date)}
                error={Boolean(formik.errors.dateCertificateReception)}
                errorText={formik.errors.dateCertificateReception}
              />
            </InputComtrol>
          </GridItem>
        )}
      </Grid>
    </Flex>
  )
}

export default RenderDebtCertificateRequired
