import React, { useEffect, useState } from 'react'
import { Flex, Grid, GridItem } from '@chakra-ui/react'
import { InputComtrol, Select, SquareButton } from '../../../../UI'
import { Schedule } from '../../../../../Utils'

const ScheduleItem = ({ schedule, formik, setIsErrorSchedules }) => {
  const [errorMessage, setErrorMessage] = useState(null)
  const { daysWeek, hours, minutes, periods } = Schedule

  const getIndexAndCopy = () => {
    const { schedules } = formik.values
    const { id } = schedule

    const index = schedules.findIndex((item) => item.id === id)
    const newSchedules = [...schedules]

    return { index, newSchedules }
  }

  const handleChangeDay = (value) => {
    const { index, newSchedules } = getIndexAndCopy()

    if (index !== -1) {
      const updated = {
        ...schedule,
        day: value
      }

      newSchedules.splice(index, 1, updated)
      formik.setFieldValue('schedules', newSchedules)
    }
  }

  const handleChangeStartHour = (value) => {
    const { index, newSchedules } = getIndexAndCopy()

    if (index !== -1) {
      const updated = {
        ...schedule,
        startHour: value
      }

      newSchedules.splice(index, 1, updated)
      formik.setFieldValue('schedules', newSchedules)
    }
  }

  const handleChangeStartMinutes = (value) => {
    const { index, newSchedules } = getIndexAndCopy()

    if (index !== -1) {
      const updated = {
        ...schedule,
        startMinutes: value
      }

      newSchedules.splice(index, 1, updated)
      formik.setFieldValue('schedules', newSchedules)
    }
  }

  const handleChangeStartPeriod = (value) => {
    const { index, newSchedules } = getIndexAndCopy()

    if (index !== -1) {
      const updated = {
        ...schedule,
        startPeriod: value
      }

      newSchedules.splice(index, 1, updated)
      formik.setFieldValue('schedules', newSchedules)
    }
  }

  const handleChangeEndHour = (value) => {
    const { index, newSchedules } = getIndexAndCopy()

    if (index !== -1) {
      const updated = {
        ...schedule,
        endHour: value
      }

      newSchedules.splice(index, 1, updated)
      formik.setFieldValue('schedules', newSchedules)
    }
  }

  const handleChangeEndMinutes = (value) => {
    const { index, newSchedules } = getIndexAndCopy()

    if (index !== -1) {
      const updated = {
        ...schedule,
        endMinutes: value
      }

      newSchedules.splice(index, 1, updated)
      formik.setFieldValue('schedules', newSchedules)
    }
  }

  const handleChangeEndPeriod = (value) => {
    const { index, newSchedules } = getIndexAndCopy()

    if (index !== -1) {
      const updated = {
        ...schedule,
        endPeriod: value
      }

      newSchedules.splice(index, 1, updated)
      formik.setFieldValue('schedules', newSchedules)
    }
  }

  const deleteItem = () => {
    const { index, newSchedules } = getIndexAndCopy()
    if (index !== -1) {
      newSchedules.splice(index, 1)
      formik.setFieldValue('schedules', newSchedules)
    }
  }

  useEffect(() => {
    const { schedules } = formik.values
    const {
      id,
      day,
      startHour,
      startMinutes,
      startPeriod,
      endHour,
      endMinutes,
      endPeriod
    } = schedule

    const isDuplicateDay = schedules.some(
      (item) => item.day === day && item.id !== id
    )

    if (
      startHour === endHour &&
      startMinutes === endMinutes &&
      startPeriod === endPeriod
    ) {
      setIsErrorSchedules(true)
      return setErrorMessage(
        'La hora de inicio y termino no pueden ser iguales'
      )
    }

    if (startHour >= endHour && startPeriod === 'PM' && endPeriod === 'AM') {
      setIsErrorSchedules(true)
      return setErrorMessage(
        'La hora de inicio no puede ser mayor a la hora de termino'
      )
    }

    if (
      startHour >= endHour &&
      startMinutes > endMinutes &&
      startPeriod === endPeriod
    ) {
      setIsErrorSchedules(true)
      return setErrorMessage(
        'La hora de inicio no puede ser mayor a la hora de termino'
      )
    }

    if (isDuplicateDay) {
      setIsErrorSchedules(true)
      return setErrorMessage('El día ya está agendado')
    }
    setIsErrorSchedules(false)
    return setErrorMessage(null)
  }, [schedule])

  return (
    <>
      <Grid
        templateColumns={'repeat(1, 1fr)'}
        gap={1}
        border={'1px solid #82b378'}
        borderRadius={8}
        p={1}
        my={2}
      >
        <GridItem>
          <Flex justify={'flex-end'}>
            <SquareButton onClick={deleteItem} size={'xs'}>
              X
            </SquareButton>
          </Flex>
        </GridItem>
        <GridItem>
          <Grid
            templateColumns={{
              lg: 'repeat(3, 1fr)',
              md: 'repeat(1, 1fr)',
              sm: 'repeat(1, 1fr)'
            }}
            gap={1}
          >
            <GridItem>
              <InputComtrol id={'day'} label={'Día'}>
                <Select
                  list={daysWeek}
                  value={schedule.day}
                  bg={'white'}
                  onChange={(value) => handleChangeDay(value)}
                />
              </InputComtrol>
            </GridItem>
            {/* --- */}
            <GridItem>
              <InputComtrol label={'Hora de inicio'}>
                <Flex gap={1} align={'center'}>
                  <Select
                    list={hours}
                    value={schedule.startHour}
                    bg={'white'}
                    onChange={(value) => handleChangeStartHour(value)}
                  />
                  {':'}
                  <Select
                    list={minutes}
                    value={schedule.startMinutes}
                    bg={'white'}
                    onChange={(value) => handleChangeStartMinutes(value)}
                  />
                  <Select
                    list={periods}
                    value={schedule.startPeriod}
                    bg={'white'}
                    onChange={(value) => handleChangeStartPeriod(value)}
                  />
                </Flex>
              </InputComtrol>
            </GridItem>
            {/* --- */}
            <GridItem>
              <InputComtrol label={'Hora de termino'}>
                <Flex gap={1} align={'center'}>
                  <Select
                    list={hours}
                    value={schedule.endHour}
                    bg={'white'}
                    onChange={(value) => handleChangeEndHour(value)}
                  />
                  {':'}
                  <Select
                    list={minutes}
                    value={schedule.endMinutes}
                    bg={'white'}
                    onChange={(value) => handleChangeEndMinutes(value)}
                  />
                  <Select
                    list={periods}
                    value={schedule.endPeriod}
                    bg={'white'}
                    onChange={(value) => handleChangeEndPeriod(value)}
                  />
                </Flex>
              </InputComtrol>
            </GridItem>
          </Grid>
        </GridItem>
        <GridItem>
          <Flex justify={'center'} color={'red.500'}>
            {errorMessage}
          </Flex>
        </GridItem>
      </Grid>
    </>
  )
}

export default ScheduleItem
