/* eslint-disable import/prefer-default-export */
import { saveAs } from 'file-saver'
import axios from '../Axios'

const module = '/reportability'

export const getAverageAmountsPerYearAndMonth = async (filters) => {
  const { year, month, grade } = filters
  const { data } = await axios.get(
    `${module}/avg-by-year-and-month?year=${year}&month=${month}&grade=${grade}`
  )
  return data
}

export const getYearPrograms = async (arrayState) => {
  const { data } = await axios.get(
    `${module}/years-programs?arrayState=${arrayState}`
  )
  return data
}

export const getTotalProgramsPerStateAndZoneAndMonthAndYear = async (
  filters
) => {
  const { year, state, zone, type } = filters
  const { data } = await axios.get(
    `${module}/total-programs-per-state-zone-month-year?year=${year}&state=${state}&zone=${zone}&type=${type}`
  )
  return data
}

export const generateResolutionOfRequestsReport = async (filters) => {
  const response = await axios.post(
    `${module}/generate-resolution-of-request`,
    filters,
    {
      responseType: 'arraybuffer'
    }
  )
  const dirtyFileName = response.headers['content-disposition']
  const regex =
    /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/
  const fileName = dirtyFileName.match(regex)[3]

  const blob = new Blob([response.data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  })
  saveAs(blob, fileName)

  return response
}

export const getAmountApprovedPerBenefit = async (filters) => {
  const { year, grade, zone, type } = filters
  const { data } = await axios.get(
    `${module}/amount-approved-per-benefit?year=${year}&grade=${grade}&zone=${zone}&type=${type}`
  )
  return data
}

export const generateAmountApprovedPerBenefitReport = async (filters) => {
  const response = await axios.post(
    `${module}/generate-amount-approved-per-benefit`,
    filters,
    {
      responseType: 'arraybuffer'
    }
  )
  const dirtyFileName = response.headers['content-disposition']
  const regex =
    /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/
  const fileName = dirtyFileName.match(regex)[3]

  const blob = new Blob([response.data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  })
  saveAs(blob, fileName)

  return response
}

export const getRequestsApprovedPerGrade = async (filters) => {
  const { year, grade, zone, indexMonth } = filters
  const { data } = await axios.get(
    `${module}/request-approved-per-grade?year=${year}&grade=${grade}&zone=${zone}&indexMonth=${indexMonth}`
  )
  return data
}

export const generateApprovedPerGradeReport = async (filters) => {
  const response = await axios.post(
    `${module}/generate-request-approved-per-grade`,
    filters,
    {
      responseType: 'arraybuffer'
    }
  )
  const dirtyFileName = response.headers['content-disposition']
  const regex =
    /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/
  const fileName = dirtyFileName.match(regex)[3]

  const blob = new Blob([response.data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  })
  saveAs(blob, fileName)

  return response
}

export const getRequestsApprovedPerBeneficiary = async (filters) => {
  const { year, zone, beneficiary, grade, type } = filters
  const { data } = await axios.get(
    `${module}/request-approved-per-beneficiary?year=${year}&&zone=${zone}&beneficiary=${beneficiary}&grade=${grade}&type=${type}`
  )
  return data
}

export const generateRequestApprovedPerBeneficiaryReport = async (filters) => {
  const response = await axios.post(
    `${module}/generate-request-approved-per-beneficiary`,
    filters,
    {
      responseType: 'arraybuffer'
    }
  )
  const dirtyFileName = response.headers['content-disposition']
  const regex =
    /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/
  const fileName = dirtyFileName.match(regex)[3]

  const blob = new Blob([response.data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  })
  saveAs(blob, fileName)

  return response
}
