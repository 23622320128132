import React from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { InputComtrol } from '../../UI'
import { FormatDate } from '../../../Utils'

const RenderInput = ({ title, value, type }) => (
  <Flex gap={4} direction={'column'}>
    {title && <Text fontWeight={700}>{title}</Text>}
    <InputComtrol
      isRequired={false}
      value={type === 'date' ? FormatDate(value) : value}
      readOnly={true}
    />
  </Flex>
)

export default RenderInput
