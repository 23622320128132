import React from 'react'
import { Flex, Text, Icon } from '@chakra-ui/react'
import { PdfIcon } from '../../Icons'
import MenuOptions from '../MenuOptions'

const CardFilePreview = ({ name, url = null, onDelete = null }) => {
  const optionsMenuUpdate = [
    {
      text: 'Visualizar/Descargar documento',
      function: () => window.open(url, '_blank')
    }
  ]
  const optionsMenuCreate = [
    {
      text: 'Eliminar',
      function: () => onDelete()
    }
  ]

  const validatelargeName = () => name
  // {
  //   if (name.length > 40) {
  //     return `${name.substring(0, 40)}...`
  //   }
  //   return name
  // }

  return (
    <Flex
      w={'-webkit-fill-available'}
      direction={'column'}
      minH={120}
      border="1px solid #82B378"
      borderRadius={10}
      overflow={'hidden'}
      _hover={{ bg: '#F1ECE2' }}
    >
      <Flex align={'end'} justify={'flex-end'} mr={6} mt={5}>
        {url ? (
          <MenuOptions optionsMenu={optionsMenuUpdate} />
        ) : (
          <MenuOptions optionsMenu={optionsMenuCreate} />
        )}
      </Flex>

      <Flex
        direction={'column'}
        align={'center'}
        justify={'center'}
        mt={'auto'}
        mb={'auto'}
      >
        <Icon fontSize="60" as={PdfIcon} pb={1} />
        <Flex
          as="h4"
          mb={4}
          fontWeight={'semibold'}
          lineHeight={'tight'}
          justify={'center'}
          align={'center'}
          color={'green.100'}
          isTruncated
        >
          <Text noOfLines={1}>{validatelargeName()}</Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default CardFilePreview
