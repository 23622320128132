/* eslint-disable import/prefer-default-export */
import axios from '../Axios'

const module = '/discounts'

export const getDiscountsByPartner = async (
  filtersDiscounts,
  page = 0,
  limit = 6
) => {
  const { id, order } = filtersDiscounts
  const { data } = await axios.get(
    `${module}/?id=${id}&page=${page}&limit=${limit}&order=${order}`
  )
  return data
}
