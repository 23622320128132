/* eslint-disable import/prefer-default-export */
import { useQuery, useQueryClient } from 'react-query'

import {
  getPrograms,
  getProgramHistory,
  getProgramById,
  getProgramsByPartnerPaginate
} from '../../api/programs'

const key = 'Allprograms'

export function usePrograms(filtersPrograms, page, limit, props = {}) {
  return useQuery([key, filtersPrograms, page, limit], () =>
    getPrograms(filtersPrograms, page, limit, {
      ...props
    })
  )
}

export function useProgramsHistory(filtersPrograms) {
  return useQuery([key, filtersPrograms], () =>
    getProgramHistory(filtersPrograms)
  )
}

export function useProgram(programId, type) {
  return useQuery(
    [key, programId, type],
    () => getProgramById(programId, type),
    {
      refetchOnWindowFocus: false
    }
  )
}

export const invalidateQueries = () => {
  const queryClient = useQueryClient()
  queryClient.invalidateQueries([key])
}

export function useProgramsPaginate(filtersPrograms, page, limit, props = {}) {
  return useQuery(
    [key, filtersPrograms, page, limit],
    () => getProgramsByPartnerPaginate(filtersPrograms, page, limit),
    {
      ...props
    }
  )
}
