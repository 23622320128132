import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import './styles.css'

const CustomSlider = ({ children, onChange = null, selectedItem = 0 }) => (
  <Carousel
    infiniteLoop
    selectedItem={selectedItem}
    centerMode={true}
    centerSlidePercentage={64}
    showIndicators={false}
    transitionTime={700}
    showStatus={false}
    showThumbs={false}
    onChange={(e) => onChange(e)}
  >
    {children}
  </Carousel>
)

export default CustomSlider
