import { createIcon } from '@chakra-ui/react'
/* https://freeicons.io/ui-essentials-3/ui-essentials-notification-alarm-bell-icon-44563 */
/* eslint-disable import/prefer-default-export */
const ArrowStepIcon = createIcon({
  displayName: 'ArrowStepIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      d="M14.707,11.293l-4-4A1,1,0,0,0,9,8v8a1,1,0,0,0,1.707.707l4-4A1,1,0,0,0,14.707,11.293Z"
      fill="white"
    />
  ]
})

export default ArrowStepIcon
