import React, { useEffect } from 'react'
import { addDays } from 'date-fns'
import { useFormik } from 'formik'
import { Grid, GridItem, Text, Checkbox } from '@chakra-ui/react'
import { InputComtrol, CustomDatePicker, CardStatus, Select } from '../../UI'
import {
  CivilStatusList,
  GetDiffDates,
  RecidenceCondition,
  Numbers,
  FormatRut,
  GradesCarabineros,
  FormatCode,
  ZonesTwoList
} from '../../../Utils'
import { steepOneSchema } from './Schemas'

const StepOne = ({ stepOneForm, setStepOneForm, refStepOne, next }) => {
  const initialValues = {
    receptionDate: '',
    isPartnerZone: false,
    zone: stepOneForm.partnerZone || ZonesTwoList.getRegions().at(0),
    prefecture:
      stepOneForm.partnerPrefecture ||
      ZonesTwoList.getCommunes(ZonesTwoList.getRegions().at(0)).at(0),
    unit: stepOneForm.partnerEndowment || '',
    dateOfDisaster: '',
    damagedProperty: RecidenceCondition.at(0),
    civilStatus: CivilStatusList.at(0),
    numberChildren: Numbers.at(0),
    alternativePhone: '',
    /* partner field */
    partnerRut: '',
    partnerPhone: '',
    partnerGrade: GradesCarabineros.at(0),
    partnerCode: '',
    partnerZone: stepOneForm.partnerZone || ZonesTwoList.getRegions().at(0),
    partnerPrefecture:
      stepOneForm.partnerPrefecture ||
      ZonesTwoList.getCommunes(ZonesTwoList.getRegions().at(0)).at(0),
    partnerEndowment: stepOneForm.partnerEndowment || ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: steepOneSchema,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: (values) => {
      setStepOneForm({
        ...values
      })
      next()
    }
  })

  const hadleChangeZone = (value) => {
    formik.setFieldValue('zone', value)
    formik.setFieldValue('prefecture', ZonesTwoList.getCommunes(value).at(0))
  }

  const handleChangePrefecture = (value) => {
    formik.setFieldValue('prefecture', value)
  }

  const hadleChangePartnerZone = (value) => {
    formik.setFieldValue('partnerZone', value)
    formik.setFieldValue(
      'partnerPrefecture',
      ZonesTwoList.getCommunes(value).at(0)
    )
  }

  const handleChangePartnerPrefecture = (value) => {
    formik.setFieldValue('partnerPrefecture', value)
  }

  const handleChangeReceptionDate = (value) => {
    formik.setFieldValue('receptionDate', value)
  }

  const handleChangeDateOfDisaster = (value) => {
    formik.setFieldValue('dateOfDisaster', value)
  }

  const handleChangeCivilStatus = (value) => {
    formik.setFieldValue('civilStatus', value)
  }

  const handleChangeDamagedProperty = (value) => {
    formik.setFieldValue('damagedProperty', value)
  }

  const handleChangeNumberChildren = (value) => {
    formik.setFieldValue('numberChildren', value)
  }

  const handleChangePartnerGrade = (value) => {
    formik.setFieldValue('partnerGrade', value)
  }

  const formatCode = () => {
    if (formik.values.partnerCode) {
      const formatedCode = FormatCode(formik.values.partnerCode)
      formik.setFieldValue('partnerCode', formatedCode)
    }
  }

  const handleChangeRut = () => {
    if (formik.values.partnerRut) {
      const formatedRut = FormatRut(formik.values.partnerRut)
      formik.setFieldValue('partnerRut', formatedRut)
    }
  }

  const handleChangeIsPartnerZone = (e) => {
    const isChecked = e.target.checked

    if (isChecked) {
      formik.setValues({
        ...formik.values,
        isPartnerZone: isChecked,
        zone: formik.values.partnerZone,
        prefecture: formik.values.partnerPrefecture,
        unit: formik.values.partnerEndowment
      })
    } else {
      formik.setValues({
        ...formik.values,
        isPartnerZone: isChecked
      })
    }
  }

  useEffect(() => {
    refStepOne.current = formik.handleSubmit
  }, [])

  useEffect(() => {
    formik.setValues({
      receptionDate: stepOneForm.receptionDate || '',
      isPartnerZone: stepOneForm.isPartnerZone || false,
      zone:
        stepOneForm.zone ||
        stepOneForm.partnerZone ||
        ZonesTwoList.getRegions().at(0),
      prefecture:
        stepOneForm.prefecture ||
        stepOneForm.partnerPrefecture ||
        ZonesTwoList.getCommunes(ZonesTwoList.getRegions().at(0)).at(0),
      unit: stepOneForm.unit || stepOneForm.partnerEndowment,
      dateOfDisaster: stepOneForm.dateOfDisaster || '',
      damagedProperty: stepOneForm.damagedProperty || RecidenceCondition.at(0),
      civilStatus: stepOneForm.civilStatus || CivilStatusList.at(0),
      numberChildren: stepOneForm.numberChildren || Numbers.at(0),
      alternativePhone: stepOneForm.alternativePhone || '',
      /* partner field */
      partnerRut: stepOneForm.partnerRut || '',
      partnerPhone: stepOneForm.partnerPhone || '',
      partnerGrade: stepOneForm.partnerGrade || GradesCarabineros.at(0),
      partnerCode: stepOneForm.partnerCode || '',
      partnerZone: stepOneForm.partnerZone || ZonesTwoList.getRegions().at(0),
      partnerPrefecture:
        stepOneForm.partnerPrefecture ||
        ZonesTwoList.getCommunes(ZonesTwoList.getRegions().at(0)).at(0),
      partnerEndowment: stepOneForm.partnerEndowment || ''
    })
  }, [stepOneForm])

  return (
    <>
      <Grid
        templateColumns={{
          lg: 'repeat(3, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          <Text fontWeight={700} color={'green.500'} fontSize={'xl'}>
            Información de solicitud
          </Text>
          <InputComtrol
            id={'receptionDate'}
            label={'Fecha de recepción de solicitud'}
          >
            <CustomDatePicker
              placeholder={'DD/MM/AAAA'}
              currentDate={formik.values.receptionDate}
              onChange={(date) => handleChangeReceptionDate(date)}
              error={Boolean(formik.errors.receptionDate)}
              errorText={formik.errors.receptionDate}
              maxDate={addDays(new Date(), 0)}
            />
          </InputComtrol>
        </GridItem>
        <GridItem>
          <Text fontSize={'xl'} opacity={0}>
            -----
          </Text>
          <InputComtrol id={'dateOfDisaster'} label={'Fecha de la catástrofe'}>
            <CustomDatePicker
              placeholder={'DD/MM/AAAA'}
              currentDate={formik.values.dateOfDisaster}
              onChange={(date) => handleChangeDateOfDisaster(date)}
              error={Boolean(formik.errors.dateOfDisaster)}
              errorText={formik.errors.dateOfDisaster}
              maxDate={addDays(new Date(), 0)}
            />
          </InputComtrol>
        </GridItem>
        <GridItem mt={'auto'} mb={'auto'}>
          <Text fontSize={'xl'} opacity={0}>
            -----
          </Text>
          <CardStatus
            subValue={'Ocurrido'}
            value={
              formik.values.dateOfDisaster
                ? `Hace ${GetDiffDates(formik.values.dateOfDisaster)}`
                : '-'
            }
          />
        </GridItem>
      </Grid>
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
        <GridItem>
          <InputComtrol id={'damagedProperty'} label={'Inmueble siniestrado'}>
            <Select
              list={RecidenceCondition}
              value={formik.values.damagedProperty}
              bg={'white'}
              onChange={(value) => handleChangeDamagedProperty(value)}
            />
          </InputComtrol>
        </GridItem>
      </Grid>
      {/* partner data */}
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          <Text fontWeight={700} color={'green.500'} fontSize={'xl'}>
            Información del Socio (Opcionales)
          </Text>
          <InputComtrol
            id={'partnerRut'}
            label={'Rut Socio'}
            isRequired={false}
            value={formik.values.partnerRut}
            onChange={formik.handleChange}
            onBlur={handleChangeRut}
            error={Boolean(formik.errors.partnerRut)}
            errorText={formik.errors.partnerRut}
          />
          <InputComtrol
            id={'partnerPhone'}
            label={'Teléfono Socio'}
            isRequired={false}
            type={'number'}
            addictionType={'phone'}
            value={formik.values.partnerPhone}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.partnerPhone)}
            errorText={formik.errors.partnerPhone}
          />
          <InputComtrol
            id={'partnerZone'}
            label={'Zona Socio'}
            isRequired={false}
          >
            <Select
              bg={'white'}
              list={ZonesTwoList.getRegions()}
              value={formik.values.partnerZone}
              onChange={hadleChangePartnerZone}
              error={Boolean(formik.errors.partnerZone)}
              errorText={formik.errors.partnerZone}
            />
          </InputComtrol>
        </GridItem>
        <GridItem>
          <Text fontSize={'xl'} opacity={0}>
            -----
          </Text>
          <InputComtrol
            id={'partnerCode'}
            label={'Código Socio. ej: 001001-D'}
            isRequired={false}
            value={formik.values.partnerCode}
            onChange={formik.handleChange}
            onBlur={formatCode}
            error={Boolean(formik.errors.partnerCode)}
            errorText={formik.errors.partnerCode}
          />
          <InputComtrol
            id={'partnerGrade'}
            label={'Grado Socio'}
            isRequired={false}
          >
            <Select
              bg={'white'}
              list={GradesCarabineros}
              value={formik.values.partnerGrade}
              onChange={handleChangePartnerGrade}
            />
          </InputComtrol>
          <InputComtrol
            id={'partnerPrefecture'}
            label={'Alta repartición / Repartición Socio'}
            isRequired={false}
            error={Boolean(formik.errors.partnerPrefecture)}
            errorText={formik.errors.partnerPrefecture}
          >
            <Select
              bg={'white'}
              list={ZonesTwoList.getCommunes(formik.values.partnerZone)}
              value={formik.values.partnerPrefecture}
              onChange={handleChangePartnerPrefecture}
            />
          </InputComtrol>
        </GridItem>
      </Grid>
      <Grid
        templateColumns={{
          lg: 'repeat(1, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          <InputComtrol
            id={'partnerEndowment'}
            label={'Unidad Socio'}
            isRequired={false}
            value={formik.values.partnerEndowment}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.partnerEndowment)}
            errorText={formik.errors.partnerEndowment}
          />
        </GridItem>
      </Grid>
      {/* end partner data */}
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          <Text fontWeight={700} color={'green.500'} fontSize={'xl'}>
            Información adicional de socio
          </Text>

          <InputComtrol id={'civilStatus'} label={'Estado civil'}>
            <Select
              list={CivilStatusList}
              value={formik.values.civilStatus}
              bg={'white'}
              onChange={handleChangeCivilStatus}
            />
          </InputComtrol>
          <InputComtrol id={'numberChildren'} label={'Número de hijos'}>
            <Select
              list={Numbers}
              value={formik.values.numberChildren}
              bg={'white'}
              onChange={(value) => handleChangeNumberChildren(value)}
            />
          </InputComtrol>
        </GridItem>
        <GridItem>
          <Text fontSize={'xl'} opacity={0}>
            -----
          </Text>

          <InputComtrol
            id={'alternativePhone'}
            isRequired={false}
            label={'Teléfono alternativo'}
            type={'number'}
            addictionType={'phone'}
            value={formik.values.alternativePhone}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.alternativePhone)}
            errorText={formik.errors.alternativePhone}
          />
        </GridItem>
      </Grid>

      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          <Text fontWeight={700} color={'green.500'} fontSize={'xl'}>
            Zona de postulación
          </Text>
        </GridItem>
        <GridItem>
          <Checkbox
            css={{ borderColor: 'green' }}
            id={'isPartnerZone'}
            size="md"
            colorScheme="green"
            isChecked={formik.values.isPartnerZone}
            value={formik.values.isPartnerZone}
            onChange={handleChangeIsPartnerZone}
            py={4}
            fontWeight={700}
          >
            ¿Es la zona actual del socio?
          </Checkbox>
        </GridItem>
        <GridItem>
          <InputComtrol id={'zone'} label={'Zona'}>
            <Select
              bg={'white'}
              list={ZonesTwoList.getRegions()}
              value={formik.values.zone}
              onChange={hadleChangeZone}
            />
          </InputComtrol>
        </GridItem>
        <GridItem>
          {/* <Text fontSize={'xl'} opacity={0}>
            -----
          </Text> */}
          <InputComtrol
            id={'prefecture'}
            label={'Alta repartición / Repartición'}
            error={Boolean(formik.errors.prefecture)}
            errorText={formik.errors.prefecture}
          >
            <Select
              bg={'white'}
              list={ZonesTwoList.getCommunes(formik.values.zone)}
              value={formik.values.prefecture}
              onChange={handleChangePrefecture}
            />
          </InputComtrol>
        </GridItem>
      </Grid>
      <Grid
        templateColumns={{
          lg: 'repeat(1, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          <InputComtrol
            id={'unit'}
            label={'Unidad'}
            value={formik.values.unit}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.unit)}
            errorText={formik.errors.unit}
          />
        </GridItem>
      </Grid>
    </>
  )
}

export default StepOne
