import React from 'react'
import { Flex, IconButton, useColorModeValue } from '@chakra-ui/react'
import { FiMenu } from 'react-icons/fi'

import Logo from '../../assets/images/logo.png'

const MobileNav = ({ onOpen, ...rest }) => (
  <Flex
    ml={{ base: 0, md: 60 }}
    px={{ base: 4, md: 24 }}
    height="20"
    alignItems="center"
    bg="#F1ECE2"
    borderBottomWidth="1px"
    borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
    justifyContent="flex-start"
    {...rest}
  >
    <IconButton
      variant="outline"
      colorScheme="green"
      onClick={onOpen}
      aria-label="open menu"
      icon={<FiMenu />}
    />

    <Flex h="40" w="100%" alignItems="center" mx="8" justifyContent="center">
      <img src={Logo} width="100" />
    </Flex>
  </Flex>
)

export default MobileNav
