import Layout from '../components/Layout/Layout'
import Notifications from '../pages/Notifications'
/* --- */
import Can from '../auth/Can'
import Forbidden from '../pages/Forbidden'
import Login from '../pages/Login'

const rootName = 'notifications'

const notificationsRoutes = [
  {
    path: `/${rootName}`,
    key: 'NOTIFICATIONS',
    exact: true,
    component: ({ authenticated }) => (
      <Can
        availableTo={[
          'ADMINISTRADOR',
          'ASISTENTE_SOCIAL',
          'ADMINISTRATIVE-NEWBORN-BENEFIT',
          'ADMINISTRATIVE-INUJRED-IN-LINES-OF-SERVICE-KIT',
          'ADMINISTRATIVE-HOSPITAL-ACCOMMODATION',
          'ADMINISTRATIVE-MULTIPLE-PROGRAMS'
        ]}
        yes={() => (
          <Layout>
            <Notifications />
          </Layout>
        )}
        no={() => (authenticated ? <Forbidden /> : <Login />)}
      />
    )
  }
]
export default notificationsRoutes
