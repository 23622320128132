import { Grid, GridItem, Flex } from '@chakra-ui/react'
import React from 'react'
import { Files } from '../../../../../Utils'
import { BarFileName, InputComtrol, UploadFile } from '../../../../UI'

const VoucherIntructive = ({ formik }) => {
  const deleteVoucher = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.voucherName,
      formik.values.voucher
    )

    formik.setValues({
      ...formik.values,
      voucherName: resultNames,
      voucher: resultListFiles
    })
  }
  const deleteInstructive = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.instructiveName,
      formik.values.instructive
    )

    formik.setValues({
      ...formik.values,
      instructiveName: resultNames,
      instructive: resultListFiles
    })
  }

  const handleChangeVoucher = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.voucherName,
      formik.values.voucher
    )

    formik.setValues({
      ...formik.values,
      voucherName: newListNames,
      voucher: newListFiles
    })
    e.target.value = null
  }

  const handleChangeInstructive = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.instructiveName,
      formik.values.instructive
    )

    formik.setValues({
      ...formik.values,
      instructiveName: newListNames,
      instructive: newListFiles
    })
    e.target.value = null
  }

  return (
    <Grid
      templateColumns={{
        lg: 'repeat(2, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem>
        <InputComtrol label={'Comprobante de depósito'}>
          <UploadFile
            id={'voucherName'}
            direction={'row'}
            sizeIcon={30}
            p={5}
            onChange={(e) => handleChangeVoucher(e)}
            error={Boolean(formik.errors.voucherName)}
            errorText={formik.errors.voucherName}
          />
        </InputComtrol>
        <Flex direction={'column'} gap={1}>
          {formik.values.voucherName.map((item) => (
            <BarFileName
              key={item?.name}
              fileName={item?.name}
              fileUrl={item?.url}
              onDelete={() => deleteVoucher(item?.name)}
            />
          ))}
        </Flex>
      </GridItem>
      <GridItem>
        <InputComtrol label={'Instructivo de rendición de cuentas'}>
          <UploadFile
            id={'instructiveName'}
            direction={'row'}
            sizeIcon={30}
            p={5}
            onChange={(e) => handleChangeInstructive(e)}
            error={Boolean(formik.errors.instructiveName)}
            errorText={formik.errors.instructiveName}
          />
        </InputComtrol>
        <Flex direction={'column'} gap={1}>
          {formik.values.instructiveName.map((item) => (
            <BarFileName
              key={item?.name}
              fileName={item?.name}
              fileUrl={item?.url}
              onDelete={() => deleteInstructive(item?.name)}
            />
          ))}
        </Flex>
      </GridItem>
    </Grid>
  )
}

export default VoucherIntructive
