import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Grid, GridItem, Flex, Box, Text } from '@chakra-ui/react'
import {
  BackButton,
  CardStatus,
  Button,
  Alert,
  Progress
} from '../../../components/UI'
import { RedirectWhiteIcon } from '../../../components/Icons'
import FormContainerPrograms from '../../../components/FormContainerPrograms'
import { GetDiffDates } from '../../../Utils'
import GetStatusCard from '../../../Utils/GetStatusCard'
import { useProgram } from '../../../hooks/Programs'

const Edit = () => {
  const { programId, type } = useParams()
  const history = useHistory()
  const {
    data: program,
    isLoading,
    isFetching,
    isError
  } = useProgram(programId, type)

  const handleRedirect = () => {
    history.push(`/partners/profile/${program?.partner._id}/Perfil de Socio`)
  }

  useEffect(() => {
    if (!program) {
      history.push(`/programs`)
    }
  }, [program])

  return (
    <>
      {!isLoading || !isFetching ? (
        <Grid
          templateColumns={{
            lg: 'repeat(2, 25% 75%)',
            md: 'repeat(1, 1fr)',
            sm: 'repeat(1, 1fr)'
          }}
          gap={2}
        >
          <GridItem>
            <>
              {isError && (
                <Alert status={'error'} message={'Error al cargar los Datos'} />
              )}
              {!isFetching && !isLoading && (
                <Flex direction={'column'} gap={10}>
                  <BackButton />
                  <Flex
                    direction={'column'}
                    gap={8}
                    border={'solid 1px #2E7423'}
                    borderRadius={18}
                    p={10}
                  >
                    <Flex direction={'column'} gap={1}>
                      <Text
                        fontWeight={700}
                        fontSize={'2xl'}
                        color={'green.500'}
                      >
                        {program?.partner.name}
                      </Text>
                      {GetStatusCard.partner(program?.partner?.state)}
                      <CardStatus
                        subValue={'Antiguedad'}
                        value={GetDiffDates(program?.partner?.createdAt)}
                      />
                    </Flex>
                    <Flex direction={'column'} gap={1}>
                      <Box>
                        <Text color={'grey.100'}>Grado</Text>
                        <Text fontWeight={700} color={'grey.500'}>
                          {program?.partner?.grade}
                        </Text>
                      </Box>
                      <Box>
                        <Text color={'grey.100'}>Correo Electronico</Text>
                        <Text fontWeight={700} color={'grey.500'}>
                          {program?.partner?.email}
                        </Text>
                      </Box>
                      <Box>
                        <Text color={'grey.100'}>Teléfono</Text>
                        <Text fontWeight={700} color={'grey.500'}>
                          {program?.partner?.phone}
                        </Text>
                      </Box>
                    </Flex>
                    <Flex direction={'column'} gap={1}>
                      <Button
                        text={'Ver perfil'}
                        icon={RedirectWhiteIcon}
                        onClick={handleRedirect}
                      />
                    </Flex>
                  </Flex>
                </Flex>
              )}
            </>
          </GridItem>
          <GridItem>
            {!isError && (
              <FormContainerPrograms
                partner={program?.partner}
                program={program}
              />
            )}
          </GridItem>
        </Grid>
      ) : (
        <Progress />
      )}
    </>
  )
}

export default Edit
