const grades = [
  'Sin definir',
  'General Director',
  'General Inspector',
  'General',
  'Coronel',
  'Teniente Coronel',
  'Mayor',
  'Capitán',
  'Teniente',
  'SubTeniente',
  'SubOficial Mayor',
  'SubOficial',
  'Sargento 1º',
  'Sargento 2º',
  'Cabo 1º',
  'Cabo 2º',
  'Carabinero',
  'C.P.R. 1',
  'C.P.R. 2'
]

export default grades
