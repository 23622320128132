/* eslint-disable import/prefer-default-export */
import { useQuery, useMutation, useQueryClient } from 'react-query'

import {
  uploadMasivePartners,
  getPartners,
  getPartnersWithFamilyMembers,
  getPartnersById,
  createNewPartner,
  updatePartner,
  addResignationPartner,
  deletePartner
} from '../api/Partners'

const key = 'partners'

const getAction = (type) => {
  if (type === 'UPLOAD-MASIVE') return uploadMasivePartners
  if (type === 'CREATE') return createNewPartner
  if (type === 'UPDATE') return updatePartner
  if (type === 'RESIGNATION') return addResignationPartner
  if (type === 'DELETE') return deletePartner
  return null
}

export function usePartners(filtersPartners, page, limit, props = {}) {
  return useQuery(
    [key, filtersPartners, page, limit],
    () => getPartners(filtersPartners, page, limit),
    {
      ...props
    }
  )
}
export function usePartnersWithFamilyMembers(
  filtersPartners,
  page,
  limit,
  props = {}
) {
  return useQuery(
    [key, filtersPartners, page, limit],
    () => getPartnersWithFamilyMembers(filtersPartners, page, limit),
    {
      ...props
    }
  )
}

export function usePartner(partnerId) {
  return useQuery([key, partnerId], () => getPartnersById(partnerId), {
    refetchOnWindowFocus: false
  })
}

export function useMutatePartner(type) {
  const queryClient = useQueryClient()
  const actionFn = getAction(type)
  return useMutation(actionFn, {
    onSuccess: () => {
      queryClient.invalidateQueries([key])
    }
  })
}
