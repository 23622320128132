import { createIcon } from '@chakra-ui/react'
/* https://freeicons.io/ui-essentials-3/ui-essentials-notification-alarm-bell-icon-44563 */
/* eslint-disable import/prefer-default-export */
const AccordionIcon = createIcon({
  displayName: 'AccordionIcon',
  viewBox: '0 0 32 32',

  path: [
    <path
      d="M28.3,24.3L26,26.6V19c0-0.6-0.4-1-1-1s-1,0.4-1,1v7.6l-2.3-2.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l4,4
      c0.1,0.1,0.2,0.2,0.3,0.2C24.7,30,24.9,30,25,30s0.3,0,0.4-0.1c0.1-0.1,0.2-0.1,0.3-0.2l4-4c0.4-0.4,0.4-1,0-1.4
      S28.7,23.9,28.3,24.3z"
      fill="#FFFFFF"
    />,
    <path
      d="M21.7,7.7L24,5.4V13c0,0.6,0.4,1,1,1s1-0.4,1-1V5.4l2.3,2.3C28.5,7.9,28.7,8,29,8s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4
      l-4-4c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.5-0.1-0.8,0c-0.1,0.1-0.2,0.1-0.3,0.2l-4,4c-0.4,0.4-0.4,1,0,1.4S21.3,8.1,21.7,7.7z"
      fill="#FFFFFF"
    />,
    <path
      d="M17,3H3C2.4,3,2,3.4,2,4s0.4,1,1,1h14c0.6,0,1-0.4,1-1S17.6,3,17,3z"
      fill="#FFFFFF"
      str
    />,
    <path
      d="M17,11H3c-0.6,0-1,0.4-1,1s0.4,1,1,1h14c0.6,0,1-0.4,1-1S17.6,11,17,11z"
      fill="#FFFFFF"
    />,
    <path
      d="M17,19H3c-0.6,0-1,0.4-1,1s0.4,1,1,1h14c0.6,0,1-0.4,1-1S17.6,19,17,19z"
      fill="#FFFFFF"
    />,
    <path
      d="M17,27H3c-0.6,0-1,0.4-1,1s0.4,1,1,1h14c0.6,0,1-0.4,1-1S17.6,27,17,27z"
      fill="#FFFFFF"
    />
  ]
})

export default AccordionIcon
