import React, { useState, useEffect } from 'react'
import { Grid, GridItem, Flex, useDisclosure, useToast } from '@chakra-ui/react'
import { Button, Heading, CustomModal } from '../../components/UI'
import { AddUserIcon } from '../../components/Icons'
import TableUsers from '../../components/TableUsers'
import FormCreateUsers from '../../components/FormCreateUsers'
import FiltersUsers from '../../components/FiltersUsers'

const Users = () => {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  /* table */
  const initialFiltersUsers = {
    search: '',
    startDate: '',
    endDate: '',
    rol: ''
  }
  const [filtersUsers, setFiltersUsers] = useState(initialFiltersUsers)
  /* table */

  useEffect(() => {
    toast.closeAll()
  }, [])

  useEffect(() => {
    toast.closeAll()
  }, [isOpen])

  return (
    <>
      <Flex direction={'column'} px={10}>
        <Heading title={'USUARIOS'} />

        <Grid templateColumns={'repeat(1, 1fr)'} py={10}>
          <GridItem borderBottom={'1px solid #2E7423'} pb={4}>
            <FiltersUsers
              filtersUsers={filtersUsers}
              setFiltersUsers={setFiltersUsers}
              initialFiltersUsers={initialFiltersUsers}
            >
              <Button
                icon={AddUserIcon}
                text={'Añadir usuario'}
                onClick={onOpen}
              />
            </FiltersUsers>
          </GridItem>
          <GridItem>
            <TableUsers filtersUsers={filtersUsers} />
          </GridItem>
        </Grid>
      </Flex>
      {isOpen && (
        <CustomModal
          isOpen={isOpen}
          onClose={onClose}
          title={'Añadir usuario'}
          size={'2xl'}
          showButtons={false}
        >
          <FormCreateUsers type={'CREATE'} onClose={onClose} />
        </CustomModal>
      )}
    </>
  )
}

export default Users
