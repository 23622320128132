import React, { useEffect } from 'react'
import { Flex } from '@chakra-ui/react'
import { Select, InputComtrol } from '../../../UI'

const RenderSelectServiceRequired = ({ formik }) => {
  const list = ['Dipreca', 'Otras']

  const handleChangeService = (value) => {
    if (value === 'Otras') {
      formik.setFieldValue('isRequiredOtherService', true)
    } else {
      formik.setFieldValue('isRequiredOtherService', false)
    }
    formik.setFieldValue('service', value)
  }

  useEffect(() => {
    if (formik.values.tariffDifference) {
      formik.setFieldValue('service', 'Dipreca')
    } else {
      formik.setFieldValue('service', '')
    }
  }, [formik.values.tariffDifference])

  return (
    <Flex direction={'column'} gap={2} my={2}>
      {formik.values.tariffDifference && (
        <InputComtrol
          id={'isRequiredSelectServiceActive'}
          label={'Seleccione el servicio'}
          isRequired={formik.values.tariffDifference}
        >
          <Select
            list={list}
            value={formik.values.service}
            bg={'white'}
            onChange={(value) => handleChangeService(value)}
          />
        </InputComtrol>
      )}
    </Flex>
  )
}

export default RenderSelectServiceRequired
