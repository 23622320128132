import React from 'react'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import { Grid, GridItem, Flex } from '@chakra-ui/react'
import {
  BackButton,
  BarFileName,
  Button,
  Heading,
  InputComtrol,
  Select,
  UploadFile
} from '../UI'
import { partnerSchema } from './Schema'
import {
  ZonesTwoList,
  Files,
  FormatCode,
  FormatRut,
  GetFormData,
  GetNotification,
  GradesCarabineros
} from '../../Utils'
import { useMutatePartner } from '../../hooks/Partners'

const FormCreatePartnerNotValidate = () => {
  const history = useHistory()
  const { mutate, isLoading, reset } = useMutatePartner('CREATE')
  const initialValues = {
    name: '',
    rut: '',
    code: '',
    grade: GradesCarabineros.at(0),
    zone: ZonesTwoList.getRegions().at(0),
    prefecture: ZonesTwoList.getCommunes(ZonesTwoList.getRegions().at(0)).at(0),
    endowment: '',
    phone: '',
    email: '',
    tipcarsNames: [],
    tipcars: null,
    applicationNames: [],
    application: null,
    state: 'NOT-VALIDATED'
  }

  const formik = useFormik({
    initialValues,
    validationSchema: partnerSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      const formData = GetFormData(
        values,
        ['tipcars', 'application'],
        ['tipcarsNames', 'applicationNames']
      )

      mutate(formData, {
        onSuccess: (data) => {
          const { partner } = data
          reset()
          history.push(`/programs/create/${partner?._id}`)
        },
        onError: (err) => {
          console.log(err)
          GetNotification.basic(
            'Error',
            'Aceptar',
            'Error al enviar el socio a revisión',
            'error'
          )
        }
      })
    }
  })

  const handleChangeRut = () => {
    if (formik.values.rut) {
      const formatedRut = FormatRut(formik.values.rut)
      formik.setFieldValue('rut', formatedRut)
    }
  }

  const formatCode = () => {
    if (formik.values.code) {
      const formatedCode = FormatCode(formik.values.code)
      formik.setFieldValue('code', formatedCode)
    }
  }

  const handleChangegrade = (value) => {
    formik.setFieldValue('grade', value)
  }

  const deleteApplication = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.applicationNames,
      formik.values.application
    )

    formik.setValues({
      ...formik.values,
      applicationNames: resultNames,
      application: resultListFiles
    })
  }

  const deleteTipcars = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.tipcarsNames,
      formik.values.tipcars
    )

    formik.setValues({
      ...formik.values,
      tipcarsNames: resultNames,
      tipcars: resultListFiles
    })
  }

  const handleChangeApplication = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.applicationNames,
      formik.values.application
    )

    formik.setValues({
      ...formik.values,
      applicationNames: newListNames,
      application: newListFiles
    })
    e.target.value = null
  }

  const handleChangeTipcars = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.tipcarsNames,
      formik.values.tipcars
    )

    formik.setValues({
      ...formik.values,
      tipcarsNames: newListNames,
      tipcars: newListFiles
    })
    e.target.value = null
  }

  const handleChangeZone = (value) => {
    formik.setFieldValue('zone', value)
    formik.setFieldValue('prefecture', ZonesTwoList.getCommunes(value).at(0))
  }

  const handleChangePrefecture = (value) => {
    formik.setFieldValue('prefecture', value)
  }

  return (
    <>
      <Grid
        templateColumns={{
          lg: 'repeat(2, 10% 90%)',
          md: 'repeat(1, 100%)',
          sm: 'repeat(1, 100%)'
        }}
        gap={2}
      >
        <GridItem>
          <BackButton showText={false} />
        </GridItem>
        <GridItem>
          <Heading title={'Registrar nuevo socio (Pendiente validación)'} />
          <Grid
            templateColumns={{
              lg: 'repeat(2, 1fr)',
              md: 'repeat(1, 1fr)',
              sm: 'repeat(1, 1fr)'
            }}
            gap={2}
          >
            <GridItem>
              <InputComtrol
                id={'name'}
                label={'Nombre'}
                value={formik.values.name}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.name)}
                errorText={formik.errors.name}
              />
              <InputComtrol
                id={'rut'}
                label={'Rut'}
                value={formik.values.rut}
                onChange={formik.handleChange}
                onBlur={handleChangeRut}
                error={Boolean(formik.errors.rut)}
                errorText={formik.errors.rut}
              />
              <InputComtrol
                id={'phone'}
                label={'Teléfono'}
                addictionType={'phone'}
                type={'number'}
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.phone)}
                errorText={formik.errors.phone}
              />
            </GridItem>
            <GridItem>
              <InputComtrol
                id={'code'}
                label={'Código Func. ej: 001001-D'}
                value={formik.values.code}
                onBlur={formatCode}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.code)}
                errorText={formik.errors.code}
              />
              <InputComtrol
                id={'email'}
                label={'Correo Electrónico'}
                type={'email'}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.email)}
                errorText={formik.errors.email}
              />
              <InputComtrol id={'grade'} label={'Grado'}>
                <Select
                  bg={'white'}
                  list={GradesCarabineros}
                  value={formik.values.grade}
                  onChange={handleChangegrade}
                />
              </InputComtrol>
            </GridItem>
          </Grid>
          <Grid
            templateColumns={{
              lg: 'repeat(2, 1fr)',
              md: 'repeat(1, 1fr)',
              sm: 'repeat(1, 1fr)'
            }}
            gap={2}
          >
            <GridItem>
              <InputComtrol
                id={'zone'}
                label={'Zona'}
                error={Boolean(formik.errors.zone)}
                errorText={formik.errors.zone}
              >
                <Select
                  bg={'white'}
                  list={ZonesTwoList.getRegions()}
                  value={formik.values.zone}
                  onChange={handleChangeZone}
                />
              </InputComtrol>
            </GridItem>
            <GridItem>
              <InputComtrol
                id={'prefecture'}
                label={'Alta repartición / Repartición'}
                error={Boolean(formik.errors.prefecture)}
                errorText={formik.errors.prefecture}
              >
                <Select
                  bg={'white'}
                  list={ZonesTwoList.getCommunes(
                    formik.values.zone || ZonesTwoList.getRegions().at(0)
                  )}
                  value={formik.values.prefecture}
                  onChange={handleChangePrefecture}
                />
              </InputComtrol>
            </GridItem>
          </Grid>
          <Grid
            templateColumns={{
              lg: 'repeat(1, 1fr)',
              md: 'repeat(1, 1fr)',
              sm: 'repeat(1, 1fr)'
            }}
            gap={2}
          >
            <GridItem>
              <InputComtrol
                id={'endowment'}
                label={'Unidad'}
                value={formik.values.endowment}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.endowment)}
                errorText={formik.errors.endowment}
              />
            </GridItem>
          </Grid>
          <Grid
            templateColumns={{
              lg: 'repeat(2, 1fr)',
              md: 'repeat(1, 1fr)',
              sm: 'repeat(1, 1fr)'
            }}
            gap={2}
          >
            <GridItem>
              <InputComtrol label={'Documento de postulación'}>
                <UploadFile
                  direction={'row'}
                  multiple={true}
                  sizeIcon={30}
                  p={5}
                  onChange={(e) => {
                    handleChangeApplication(e)
                  }}
                  id={'applicationNames'}
                  error={Boolean(formik.errors.applicationNames)}
                  errorText={formik.errors.applicationNames}
                />
                <Flex direction={'column'} gap={1}>
                  {formik.values.applicationNames.map((item) => (
                    <BarFileName
                      key={item?.name}
                      fileName={item?.name}
                      fileUrl={item?.url}
                      onDelete={() => deleteApplication(item?.name)}
                    />
                  ))}
                </Flex>
              </InputComtrol>
            </GridItem>
            <GridItem>
              <InputComtrol label={'Tipcar (opcional)'} isRequired={false}>
                <UploadFile
                  direction={'row'}
                  multiple={true}
                  sizeIcon={30}
                  p={5}
                  onChange={(e) => {
                    handleChangeTipcars(e)
                  }}
                  id={'tipcarsNames'}
                  error={Boolean(formik.errors.tipcarsNames)}
                  errorText={formik.errors.tipcarsNames}
                />
                <Flex direction={'column'} gap={1}>
                  {formik.values.tipcarsNames.map((item) => (
                    <BarFileName
                      key={item?.name}
                      fileName={item?.name}
                      fileUrl={item?.url}
                      onDelete={() => deleteTipcars(item?.name)}
                    />
                  ))}
                </Flex>
              </InputComtrol>
              <Flex justify={'flex-end'} align={'end'}>
                <Button
                  text={'Continuar'}
                  px={10}
                  onClick={formik?.handleSubmit}
                  isLoading={isLoading}
                  disabled={isLoading}
                />
              </Flex>
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </>
  )
}

export default FormCreatePartnerNotValidate
