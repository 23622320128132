import React, { useState, useEffect } from 'react'
import { Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import { InputComtrol, InfoCard, CardFilePreview } from '../../UI'
import { ClearFormatMoney, FormatMoney } from '../../../Utils'

const EconomicsFamilyGroup = ({ program }) => {
  const [totalFamily, setTotalFamily] = useState(0)
  const [totalExpenses, setTotalExpenses] = useState(0)

  useEffect(() => {
    if (program) {
      const { salatyPartner, otherSalaty, salarySpouse, totalExpensesGroup } =
        program

      let total_family = parseFloat(
        ClearFormatMoney(salatyPartner) + ClearFormatMoney(otherSalaty)
      )
      if (salarySpouse) {
        total_family += parseFloat(ClearFormatMoney(salarySpouse))
      }

      const total_expenses = parseFloat(
        total_family - ClearFormatMoney(totalExpensesGroup)
      )
      setTotalFamily(FormatMoney(total_family))
      setTotalExpenses(FormatMoney(total_expenses))
    }
  }, [program])
  return (
    <>
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
        <Grid
          templateColumns={{
            lg: 'repeat(2, 1fr)',
            md: 'repeat(1, 1fr)',
            sm: 'repeat(1, 1fr)'
          }}
          gap={2}
        >
          <GridItem>
            <InputComtrol
              isRequired={false}
              label={'Alcance líquidos del/la funcionario'}
              value={program?.salatyPartner}
              readOnly={true}
            />
            <InputComtrol
              isRequired={false}
              label={'Ingresos de cónyuge'}
              value={program?.salarySpouse || 'No aplica'}
              readOnly={true}
            />
            <InputComtrol
              isRequired={false}
              label={'Otros ingresos (arriendos, pensiones, otros)'}
              value={program?.otherSalaty}
              readOnly={true}
            />
            <InputComtrol
              isRequired={false}
              label={
                <Flex direction={'column'}>
                  <strong>Total egresos mensuales del grupo familiar.</strong>
                  Gastos básicos y de manutención (alimentación, cuentas
                  básicas, educación, salud, vivienda, otros)
                </Flex>
              }
              value={program?.totalExpensesGroup}
              readOnly={true}
            />
          </GridItem>
          <GridItem mt={'auto'} mb={'auto'}>
            <InfoCard px={10} py={10} bg={'beige.50'}>
              <Text fontWeight={700}>Total ingresos familiares</Text>
              <Text fontWeight={700} fontSize={'2xl'}>
                $ {totalFamily}
              </Text>
            </InfoCard>
            <InfoCard px={5} py={10} my={2} bg={'beige.50'}>
              <Text fontWeight={700}>Remanente mensual</Text>
              <Text fontWeight={700} fontSize={'2xl'}>
                $ {totalExpenses}
              </Text>
              <Text fontWeight={700} fontSize={'sm'}>
                Saldo a favor una vez pagadas todas sus responsabilidades y
                compromisos económicos y/o familiares
              </Text>
            </InfoCard>
          </GridItem>
        </Grid>
      </Grid>
      <Text fontWeight={700} mb={2}>
        Liquidaciones de sueldo del socio/conyugue
      </Text>
      <Grid
        templateColumns={{
          lg: 'repeat(1, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        {program?.salarySettlements.map((item) => (
          <GridItem key={item?.name}>
            <CardFilePreview name={item?.name} url={item?.url} />
          </GridItem>
        ))}
      </Grid>
    </>
  )
}

export default EconomicsFamilyGroup
