import React from 'react'
import { Grid, GridItem } from '@chakra-ui/react'
import { InputComtrol } from '../../UI'

const PartnerInformation = ({ program }) => (
  <Grid
    templateColumns={{
      lg: 'repeat(2, 1fr)',
      md: 'repeat(1, 1fr)',
      sm: 'repeat(1, 1fr)'
    }}
    gap={2}
  >
    <GridItem>
      <InputComtrol
        label={'Zona socio'}
        value={program?.partner.zone}
        isRequired={false}
        readOnly={true}
      />
      <InputComtrol
        label={'Unidad socio'}
        value={program?.partner.endowment}
        isRequired={false}
        readOnly={true}
      />
      <InputComtrol
        label={'Telefono'}
        addictionType={'phone'}
        value={program?.partner.phone}
        isRequired={false}
        readOnly={true}
      />
      <InputComtrol
        label={'Rut del socio'}
        value={program?.partner.rut}
        isRequired={false}
        readOnly={true}
      />
    </GridItem>
    <GridItem>
      <InputComtrol
        label={'Alta repartición / Repartición socio'}
        value={program?.partner.prefecture}
        isRequired={false}
        readOnly={true}
      />
      <InputComtrol
        label={'Teléfono alternativo'}
        addictionType={'phone'}
        value={program?.alternativePhone || 'No disponible'}
        isRequired={false}
        readOnly={true}
      />
      <InputComtrol
        label={'Correo'}
        value={program?.partner.email}
        isRequired={false}
        readOnly={true}
      />
      <InputComtrol
        label={'Grado del socio'}
        value={program?.partner.grade}
        isRequired={false}
        readOnly={true}
      />
    </GridItem>
  </Grid>
)

export default PartnerInformation
