import React from 'react'
import { Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import { InputComtrol, TextAreaComtrol } from '../../UI'
import { FormatDate } from '../../../Utils'
import { NODATA } from '../../../constans'

const TrainingInformation = ({ program }) => (
  <>
    <Grid
      templateColumns={{
        lg: 'repeat(2, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem>
        <InputComtrol
          isRequired={false}
          label={'Tema'}
          value={program.topic}
          readOnly={true}
        />
      </GridItem>
      <GridItem>
        <InputComtrol
          isRequired={false}
          label={'Modalidad'}
          value={program.modality}
          readOnly={true}
        />
      </GridItem>
    </Grid>
    {/* --- */}
    <Grid
      templateColumns={{
        lg: 'repeat(2, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem>
        <InputComtrol
          isRequired={false}
          label={'Fecha de inicio'}
          value={program.startDate ? FormatDate(program.startDate) : NODATA}
          readOnly={true}
        />
      </GridItem>
      <GridItem>
        <InputComtrol
          isRequired={false}
          label={'Fecha de cierre'}
          value={program.endDate ? FormatDate(program.endDate) : NODATA}
          readOnly={true}
        />
      </GridItem>
    </Grid>
    {/* --- */}
    <Grid
      templateColumns={{
        lg: 'repeat(2, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem>
        <InputComtrol
          isRequired={false}
          label={'Zona de Postulación'}
          value={program.zone}
          readOnly={true}
        />
      </GridItem>
      <GridItem>
        <InputComtrol
          isRequired={false}
          label={'Alta repartición / Repartición'}
          value={program.prefecture}
          readOnly={true}
        />
      </GridItem>
    </Grid>
    <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
      <GridItem>
        <InputComtrol
          isRequired={false}
          label={'Unidad'}
          value={program.unit}
          readOnly={true}
        />
      </GridItem>
      <GridItem>
        <TextAreaComtrol
          isRequired={false}
          label={'Descripción breve'}
          value={program.description}
          readOnly={true}
        />
      </GridItem>
    </Grid>
    {/* --- */}
    <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
      <GridItem>
        <Text>Horarios:</Text>
      </GridItem>
      {program?.schedules?.map((schedule) => (
        <GridItem key={schedule.id}>
          <Flex gap={1} justify={'center'} align={'center'}>
            <InputComtrol
              isRequired={false}
              label={'Día'}
              value={schedule.day}
              readOnly={true}
            />
            <InputComtrol
              isRequired={false}
              label={'Hora de inicio'}
              value={`${schedule.startHour}:${schedule.startMinutes}    ${schedule.startPeriod}`}
              readOnly={true}
            />
            <InputComtrol
              isRequired={false}
              label={'Hora de termino'}
              value={`${schedule.endHour}:${schedule.endMinutes}    ${schedule.endPeriod}`}
              readOnly={true}
            />
          </Flex>
        </GridItem>
      ))}
    </Grid>
  </>
)

export default TrainingInformation
