import { Grid, GridItem, Text } from '@chakra-ui/react'
import RenderInfoItem from './RenderInfoItem'
import { NODATA } from '../../../../constans'

const PROGRAM_DESC = 'Información relevante del beneficio'

const HospitalAccommodation = ({ currentProgram = null }) => {
  const stepData_1 = currentProgram.flowData
    ?.reverse()
    ?.find(
      (sp) => sp.key === 'FIRST-EXTENSION-OR-OUTLET-HOSPITAL-ACCOMMODATION'
    )

  return (
    <Grid templateAreas="repeat(1,1fr)" gap={2}>
      <GridItem borderBottom="1px solid" borderColor="green.500" mb={2}>
        <Text fontSize="lg" fontWeight={500}>
          {PROGRAM_DESC}:
        </Text>
      </GridItem>
      <GridItem>
        <RenderInfoItem
          label="Total de días hospedado"
          value={stepData_1?.totalDays || NODATA}
        />
      </GridItem>
    </Grid>
  )
}
export default HospitalAccommodation
