import React from 'react'
import { Progress as ProgressCha, Grid, GridItem } from '@chakra-ui/react'

const Progress = () => (
  <Grid templateColumns={'repeat(1, 1fr)'}>
    <GridItem>
      <ProgressCha borderRadius={10} colorScheme={'green'} isIndeterminate />
    </GridItem>
  </Grid>
)

export default Progress
