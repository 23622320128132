import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { Grid, GridItem, Text, Flex } from '@chakra-ui/react'
import { UploadFile, BarFileName } from '../../UI'
import { steepThreeSchema } from './Schemas'
import { Files } from '../../../Utils'

const StepThree = ({
  refStepThree,
  stepThreeForm,
  setStepThreeForm,
  state
}) => {
  const initialValues = {
    lastSalaryPaymentName: [],
    tipcarName: [],
    lastSalaryPayment: null,
    tipcar: null
  }

  const formik = useFormik({
    initialValues,
    validationSchema: steepThreeSchema,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: (values) => {
      if (!state) {
        setStepThreeForm({
          ...values
        })
      }
      if (state) {
        setStepThreeForm({
          ...values,
          state
        })
      }
    }
  })

  const deleteTipcar = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles: resultTipcar } =
      getArrayNamesAndArrayFiles(
        fileName,
        formik.values.tipcarName,
        formik.values.tipcar
      )

    formik.setValues({
      ...formik.values,
      tipcarName: resultNames,
      tipcar: resultTipcar
    })
  }

  const deleteLastSalaryPayment = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles: resultlastSalaryPayment } =
      getArrayNamesAndArrayFiles(
        fileName,
        formik.values.lastSalaryPaymentName,
        formik.values.lastSalaryPayment
      )

    formik.setValues({
      ...formik.values,
      lastSalaryPaymentName: resultNames,
      lastSalaryPayment: resultlastSalaryPayment
    })
  }

  const handleChangeLastSalaryPayment = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const {
      newListNames: newLastSalaryPaymentName,
      newListFiles: newLastSalaryPayment
    } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.lastSalaryPaymentName,
      formik.values.lastSalaryPayment
    )

    formik.setValues({
      ...formik.values,
      lastSalaryPaymentName: newLastSalaryPaymentName,
      lastSalaryPayment: newLastSalaryPayment
    })
  }

  const handleChangeTipcar = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames: newTipcarName, newListFiles: newTipcar } =
      getListNamesAndListFiles(
        e.currentTarget,
        formik.values.tipcarName,
        formik.values.tipcar
      )

    formik.setValues({
      ...formik.values,
      tipcarName: newTipcarName,
      tipcar: newTipcar
    })
  }

  useEffect(() => {
    formik.setValues({
      lastSalaryPaymentName: stepThreeForm.lastSalaryPaymentName || [],
      tipcarName: stepThreeForm.tipcarName || [],
      lastSalaryPayment: stepThreeForm.lastSalaryPayment || null,
      tipcar: stepThreeForm.tipcar || null
    })
  }, [stepThreeForm])

  useEffect(() => {
    refStepThree.current = formik.handleSubmit
  }, [])

  return (
    <Grid
      templateColumns={{
        lg: 'repeat(2, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem>
        <Text fontWeight={700} color={'green.500'} fontSize={'xl'}>
          Documentos Requeridos
        </Text>

        <Text fontSize={'md'} color={'green.500'}>
          Copia de Tipcar del funcionario(a)
        </Text>
        <UploadFile
          id={'tipcarName'}
          direction={'row'}
          sizeIcon={30}
          p={5}
          onChange={(e) => handleChangeTipcar(e)}
          error={Boolean(formik.errors.tipcarName)}
          errorText={formik.errors.tipcarName}
        />
        <Flex direction={'column'} gap={1}>
          {formik.values.tipcarName.map((item) => (
            <BarFileName
              key={item?.name}
              fileName={item?.name}
              fileUrl={item?.url}
              onDelete={() => deleteTipcar(item?.name)}
            />
          ))}
        </Flex>
      </GridItem>
      <GridItem>
        <Text fontSize={'xl'} opacity={0}>
          -----
        </Text>
        <Text fontSize={'md'} color={'green.500'}>
          Agregue última liquidación de Sueldo
        </Text>
        <UploadFile
          id={'lastSalaryPaymentName'}
          direction={'row'}
          sizeIcon={30}
          p={5}
          onChange={(e) => handleChangeLastSalaryPayment(e)}
          error={Boolean(formik.errors.lastSalaryPaymentName)}
          errorText={formik.errors.lastSalaryPaymentName}
        />
        <Flex direction={'column'} gap={1}>
          {formik.values.lastSalaryPaymentName.map((item) => (
            <BarFileName
              key={item?.name}
              fileName={item?.name}
              fileUrl={item?.url}
              onDelete={() => deleteLastSalaryPayment(item?.name)}
            />
          ))}
        </Flex>
      </GridItem>
    </Grid>
  )
}

export default StepThree
