import React from 'react'
import { Grid, GridItem, Text } from '@chakra-ui/react'
import { CardFilePreview } from '../../UI'

const Documents = ({ program }) => (
  <>
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      {program?.birthCertificate.map((item) => (
        <GridItem py={3}>
          <Text fontWeight={700} fontSize={'xl'}>
            Certificado de nacimiento
          </Text>
          <CardFilePreview key={item?.name} name={item?.name} url={item.url} />
        </GridItem>
      ))}
    </Grid>
    {/* --- */}

    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      {program?.scoreCertificate.map((item) => (
        <GridItem py={3}>
          <Text fontWeight={700} fontSize={'xl'}>
            Conc. de notas de enseñanza media
          </Text>
          <CardFilePreview key={item?.name} name={item?.name} url={item.url} />
        </GridItem>
      ))}
    </Grid>
    {/* --- */}

    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      {program?.applicationSignedByPartner.map((item) => (
        <GridItem py={3}>
          <Text fontWeight={700} fontSize={'xl'}>
            Solicitud de postulación
          </Text>
          <CardFilePreview key={item?.name} name={item?.name} url={item.url} />
        </GridItem>
      ))}
    </Grid>
    {/* --- */}

    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      {program?.motivationLetter.map((item) => (
        <GridItem py={3}>
          <Text fontWeight={700} fontSize={'xl'}>
            Carta de motivación manuscrita
          </Text>
          <CardFilePreview key={item?.name} name={item?.name} url={item.url} />
        </GridItem>
      ))}
    </Grid>
    {/* --- */}

    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      {program?.parentsLastSalary.map((item) => (
        <GridItem py={3}>
          <Text fontWeight={700} fontSize={'xl'}>
            Liquidación de los padres
          </Text>
          <CardFilePreview key={item?.name} name={item?.name} url={item.url} />
        </GridItem>
      ))}
    </Grid>
    {/* --- */}
    {program?.socialReport.length > 0 && (
      <>
        <Text fontWeight={700} fontSize={'xl'}>
          Informe Social
        </Text>
        <Grid
          templateColumns={{
            lg: 'repeat(1, 1fr)',
            md: 'repeat(1, 1fr)',
            sm: 'repeat(1, 1fr)'
          }}
          gap={2}
        >
          {program?.socialReport?.map((item) => (
            <GridItem py={3}>
              <CardFilePreview
                key={item?.name}
                name={item?.name}
                url={item.url}
              />
            </GridItem>
          ))}
        </Grid>
      </>
    )}

    <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
      {program?.scoreCertificateSantoTomas.length > 0 && (
        <>
          <Text fontWeight={700} fontSize={'xl'}>
            Concentración de notas de Santo Tomás
          </Text>
          <GridItem>
            {program?.scoreCertificateSantoTomas.map((item) => (
              <CardFilePreview
                key={item?.name}
                name={item?.name}
                url={item?.url}
              />
            ))}
          </GridItem>
        </>
      )}
    </Grid>
  </>
)

export default Documents
