import React, { useEffect, useState } from 'react'
import { Grid, GridItem } from '@chakra-ui/react'
import StepItem from './StepItem'

const documentsKey = [
  'documents',
  'voucher',
  'instructive',
  'backupFeeAmount',
  'scholarshipCommission',
  'bankData',
  'office',
  'invoice',
  'classSchedule',
  'feeBill',
  'attendanceSheet',
  'amountCancel',
  'purchaseDetail',
  'acta',
  'reportedEmail',
  'boxPurchase',
  'boxPurchaseTwo',
  'purchaseRequestLetter',
  'quotesReceived',
  'debtCertificate',
  'purchaseRequestLetterSigned',
  'documentsSentMedicalInstitution'
]

const RenderFlowDocuments = ({ program }) => {
  const [additionalInformationFiltered, setAdditionalInformationFiltered] =
    useState([])
  const [typeProcess, setTypeProcess] = useState({})

  const validateDocumentsInProperty = (record) => {
    const result = []
    Object.keys(record).forEach((key) => {
      if (documentsKey.includes(key)) {
        if (record[key] && record[key]?.length > 0) {
          result.push(key)
        }
      }
    })

    return Boolean(result.length > 0)
  }

  useEffect(() => {
    const { additionalInformation } = program

    if (program?.type === 'SolidarityHealthFund') {
      const preparationCommission = additionalInformation.filter(
        (item) => item.key === 'COMMISSION-PREPARATION-SOLIDARITY-HEALTH-FUND'
      )
      if (preparationCommission.length > 0) {
        setTypeProcess(preparationCommission[0])
      }
    }

    const result = additionalInformation.map((item) => {
      if (validateDocumentsInProperty(item)) {
        return item
      }
      return null
    })
    const filtered = result.filter((item) => item !== null)
    setAdditionalInformationFiltered(filtered)
  }, [program])

  return (
    <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
      {additionalInformationFiltered?.length > 0
        ? additionalInformationFiltered.map((item) => (
            <GridItem
              bg={'#ebffed'}
              borderRadius={8}
              border={'4px dotted #e6e6e6'}
              p={2}
            >
              <StepItem step={item} typeProcess={typeProcess} />
            </GridItem>
          ))
        : 'Sin documentos actualmente'}
    </Grid>
  )
}

export default RenderFlowDocuments
