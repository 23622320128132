import { createIcon } from '@chakra-ui/react'
/* https://freeicons.io/ui-essentials-3/ui-essentials-notification-alarm-bell-icon-44563 */
/* eslint-disable import/prefer-default-export */
const MinusIcon = createIcon({
  displayName: 'MinusIcon',
  viewBox: '0 0 20 20',
  path: [
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.0004 19.5984C15.3023 19.5984 19.6004 15.3004 19.6004 9.99844C19.6004 4.6965 15.3023 0.398438 10.0004 0.398438C4.69846 0.398438 0.400391 4.6965 0.400391 9.99844C0.400391 15.3004 4.69846 19.5984 10.0004 19.5984ZM4.24039 9.03844C3.7102 9.03844 3.28039 9.46824 3.28039 9.99844C3.28039 10.5286 3.7102 10.9584 4.24039 10.9584H15.7604C16.2906 10.9584 16.7204 10.5286 16.7204 9.99844C16.7204 9.46824 16.2906 9.03844 15.7604 9.03844H4.24039Z"
      fill="white"
    />
  ]
})

export default MinusIcon
