/* eslint-disable import/prefer-default-export */
import { useContext, useEffect } from 'react'
import addNotification from 'react-push-notification'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { SocketContext } from '../services/socket'

import { getAlerts, updateAlert, updateTotalAlert } from '../api/Alerts'

const key = 'alerts'
const ALERTSONG_URL =
  'https://public-resourses-files.s3.us-east-2.amazonaws.com/AUDIO/alertSong.mp3'

const getAction = (type) => {
  if (type === 'UPDATE-TOTAL') return updateTotalAlert
  if (type === 'UPDATE') return updateAlert
  return null
}

export function useAlerts(page) {
  return useQuery([key, page], () => getAlerts(page), {
    refetchOnWindowFocus: false
  })
}
export function useUpdateAlerts(type) {
  const queryClient = useQueryClient()
  const actionFn = getAction(type)
  return useMutation(actionFn, {
    onSuccess: () => {
      queryClient.invalidateQueries([key])
      document.title = 'FAFC'
    }
  })
}

const notificationPush = () => {
  addNotification({
    title: 'Corporación de ayuda',
    message: 'Tiene una nueva notificación, verifique el detalle en sistema.',
    duration: 15000,
    native: true
  })
  document.title = 'Nueva notificación'
}

export function useInvalidateAlerts(user, setPage) {
  const audio = new Audio(ALERTSONG_URL)
  const socket = useContext(SocketContext)
  const queryClient = useQueryClient()

  useEffect(() => {
    socket.on('REFRESH_ALERTS', (data) => {
      if (user?._id !== data?.userId && data?.to?.includes(user?.role)) {
        audio.play()
        notificationPush()
        setPage(0)
        queryClient.invalidateQueries([key])
      }
    })
    return () => {
      socket.off('REFRESH_ALERTS')
    }
  }, [socket])
}
