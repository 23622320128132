import { createIcon } from '@chakra-ui/react'
/* eslint-disable import/prefer-default-export */
const RedirectWhiteIcon = createIcon({
  displayName: 'RedirectWhiteIcon',
  viewBox: '0 0 25 24',
  path: [
    <path
      d="M18.5 19H6.5C5.95 19 5.5 18.55 5.5 18V6C5.5 5.45 5.95 5 6.5 5H11.5C12.05 5 12.5 4.55 12.5 4C12.5 3.45 12.05 3 11.5 3H5.5C4.39 3 3.5 3.9 3.5 5V19C3.5 20.1 4.4 21 5.5 21H19.5C20.6 21 21.5 20.1 21.5 19V13C21.5 12.45 21.05 12 20.5 12C19.95 12 19.5 12.45 19.5 13V18C19.5 18.55 19.05 19 18.5 19ZM14.5 4C14.5 4.55 14.95 5 15.5 5H18.09L8.96 14.13C8.57 14.52 8.57 15.15 8.96 15.54C9.35 15.93 9.98 15.93 10.37 15.54L19.5 6.41V9C19.5 9.55 19.95 10 20.5 10C21.05 10 21.5 9.55 21.5 9V4C21.5 3.45 21.05 3 20.5 3H15.5C14.95 3 14.5 3.45 14.5 4Z"
      fill="#FFFFFF"
      stroke="#FFFFFF"
      strokeWidth="0.5"
    />
  ]
})

export default RedirectWhiteIcon
