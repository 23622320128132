import { createIcon } from '@chakra-ui/react'
/* https://freeicons.io/ui-essentials-3/ui-essentials-notification-alarm-bell-icon-44563 */
/* eslint-disable import/prefer-default-export */
const PlusIcon = createIcon({
  displayName: 'PlusIcon',
  viewBox: '0 0 12 12',
  path: [
    <path
      d="M10.5 4.5H7.5V1.5C7.5 1.10218 7.34196 0.720644 7.06066 0.43934C6.77936 0.158035 6.39782 0 6 0C5.60218 0 5.22064 0.158035 4.93934 0.43934C4.65804 0.720644 4.5 1.10218 4.5 1.5V4.5H1.5C1.10218 4.5 0.720644 4.65804 0.43934 4.93934C0.158035 5.22064 0 5.60218 0 6C0 6.39782 0.158035 6.77936 0.43934 7.06066C0.720644 7.34196 1.10218 7.5 1.5 7.5H4.5V10.5C4.5 10.8978 4.65804 11.2794 4.93934 11.5607C5.22064 11.842 5.60218 12 6 12C6.39782 12 6.77936 11.842 7.06066 11.5607C7.34196 11.2794 7.5 10.8978 7.5 10.5V7.5H10.5C10.8978 7.5 11.2794 7.34196 11.5607 7.06066C11.842 6.77936 12 6.39782 12 6C12 5.60218 11.842 5.22064 11.5607 4.93934C11.2794 4.65804 10.8978 4.5 10.5 4.5Z"
      fill="#82B378"
    />
  ]
})

export default PlusIcon
