/* eslint-disable import/prefer-default-export */
import axios from '../Axios'

const module = '/auth'

export const login = async (formData) => {
  const { data } = await axios.post(`${module}/login`, formData)
  return data
}

export const get_authentication = async () => {
  const { data } = await axios.get(`${module}/me`)
  return data
}
