import React from 'react'
import { Grid, GridItem } from '@chakra-ui/react'
import { BackButton } from '../../../../components/UI'
import WorkshopsAndTraining from '../../../../components/FormContainerPrograms/WorkshopsAndTraining'

const Create = () => (
  <Grid
    templateColumns={{
      lg: 'repeat(2, 15% 85%)',
      md: 'repeat(1, 1fr)',
      sm: 'repeat(1, 1fr)'
    }}
    gap={2}
  >
    <GridItem>
      <BackButton />
    </GridItem>
    <GridItem>
      <WorkshopsAndTraining />
    </GridItem>
  </Grid>
)

export default Create
