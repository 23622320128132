import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import {
  Avatar,
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  Icon,
  Text,
  Tooltip,
  useToast
} from '@chakra-ui/react'
import { CheckIcon, EditIcon, TrashIcon } from '../../../Icons'
import { Button, CustomToast, InputComtrol, SquareButton } from '../../../UI'
import { itemSchema } from './Schemas'

const PartnerItem = ({ partner, formik, type = 'primary' }) => {
  const toast = useToast()
  const initialValues = {
    isRequiredPartnerInfo: false,
    partnersEmail: '',
    partnersPhone: '',
    /* spouse data */
    isRequiredSpouseInfo: false,
    spouseFullName: '',
    spouseEmail: '',
    spousePhone: ''
  }
  const formikItem = useFormik({
    initialValues,
    validationSchema: itemSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      if (values.isRequiredSpouseInfo) {
        const copy = [...formik.values.partnersData]
        const newPartnes = copy.map((item) => {
          if (item._id === partner._id) {
            return {
              ...item,
              familyMembers: {
                ...item.familyMembers,
                _id: item.familyMembers._id ? item.familyMembers._id : '',
                fullName: values.spouseFullName,
                email: values.spouseEmail,
                phone: values.spousePhone,
                partnerId: partner._id,
                showForm: false,
                isValid: true
              }
            }
          }
          return item
        })
        formik.setValues({
          ...formik.values,
          partnersData: newPartnes
        })
        formikItem.resetForm()
      }
    }
  })

  const addPartner = () => {
    formik.setValues({
      ...formik.values,
      partnersIds: [...formik.values.partnersIds, partner._id],
      partnersData: [
        ...formik.values.partnersData,
        {
          ...partner,
          familyMembers: partner.familyMembers
            ? { ...partner.familyMembers, toEdit: true, showForm: false }
            : {},
          familyMembersBD: partner.familyMembers ? partner.familyMembers : null
        }
      ]
    })
  }

  const deletePartner = () => {
    formik.setValues({
      ...formik.values,
      partnersIds: formik.values.partnersIds.filter(
        (item) => item !== partner._id
      ),
      partnersData: formik.values.partnersData.filter(
        (item) => item._id !== partner._id
      )
    })
  }

  const deleteSpouse = () => {
    const copy = [...formik.values.partnersData]
    const newPartnes = copy.map((item) => {
      if (item._id === partner._id) {
        return {
          ...item,
          familyMembers: {}
        }
      }
      return item
    })

    formik.setValues({
      ...formik.values,
      partnersData: newPartnes
    })
    formikItem.setValues({
      ...formikItem.values,
      isRequiredSpouseInfo: false,
      spouseFullName: '',
      spouseEmail: '',
      spousePhone: ''
    })
  }

  const addSpouse = () => {
    const copy = [...formik.values.partnersData]
    const newPartnes = copy.map((item) => {
      if (item._id === partner._id) {
        if (item.familyMembersBD) {
          return {
            ...item,
            familyMembers: {
              ...item.familyMembersBD,
              toEdit: true,
              isValid: true
            }
          }
        }
        return {
          ...item,
          familyMembers: {
            _id: '',
            fullName: '',
            email: '',
            phone: '',
            partnerId: partner._id,
            toCreate: true,
            showForm: true,
            isValid: false
          }
        }
      }
      return item
    })

    formik.setValues({
      ...formik.values,
      partnersData: newPartnes
    })
    formikItem.setValues({
      ...formikItem.values,
      isRequiredSpouseInfo: true
    })
  }

  const updateSpose = () => {
    const copy = [...formik.values.partnersData]
    const newPartnes = copy.map((item) => {
      if (item._id === partner._id) {
        return {
          ...item,
          familyMembers: {
            ...item.familyMembers,
            showForm: true
          }
        }
      }
      return item
    })

    formikItem.setValues({
      ...formikItem.values,
      isRequiredSpouseInfo: true,
      spouseFullName: partner.familyMembers.fullName,
      spouseEmail: partner.familyMembers.email,
      spousePhone: partner.familyMembers.phone
    })

    formik.setValues({
      ...formik.values,
      partnersData: newPartnes
    })
  }

  useEffect(() => {
    if (Object.keys(formikItem.errors).length > 0) {
      Object.keys(formikItem.errors).forEach((key) => {
        toast({
          duration: 2000,
          isClosable: true,
          position: 'top-right',
          render: ({ onClose }) => (
            <CustomToast
              text={formikItem.errors[key]}
              showSpinner={false}
              status={'error'}
              onClose={onClose}
            />
          )
        })
      })

      const copy = [...formik.values.partnersData]
      const newPartnes = copy.map((item) => {
        if (item._id === partner._id) {
          return {
            ...item,
            familyMembers: {
              ...item.familyMembers,
              isValid: false
            }
          }
        }
        return item
      })
      formik.setValues({
        ...formik.values,
        partnersData: newPartnes
      })
    }
  }, [formikItem.errors])

  return (
    <>
      {type === 'primary' && (
        <Grid
          templateColumns={{
            lg: 'repeat(4, 1fr)',
            md: 'repeat(4, 1fr)',
            sm: 'repeat(4, 1fr)'
          }}
          borderRadius={18}
          fontSize={14}
          py={2}
          px={6}
          gap={4}
          w={'100%'}
        >
          <GridItem>
            <Box>
              <Text fontWeight={700}>{partner?.name}</Text>
            </Box>
          </GridItem>
          <GridItem ml={'auto'} mr={'auto'}>
            <Box>
              <Text fontWeight={700} color={'green.100'}>
                Cód. funcionario
              </Text>
              <Text>{partner?.code}</Text>
            </Box>
          </GridItem>
          <GridItem ml={'auto'} mr={'auto'}>
            <Box>
              <Text fontWeight={700} color={'green.100'}>
                RUT
              </Text>
              <Text>{partner?.rut}</Text>
            </Box>
          </GridItem>
          <GridItem ml={'auto'}>
            {!formik.values.partnersIds.includes(partner?._id) ? (
              <Button
                py={1}
                px={2}
                fontSize={14}
                text={'Seleccionar'}
                onClick={addPartner}
              />
            ) : (
              <Button
                py={1}
                px={2}
                fontSize={14}
                bg={'beige.600'}
                bghover={'beige.500'}
                color={'green.500'}
                text={'Quitar'}
                onClick={deletePartner}
              />
            )}
          </GridItem>
        </Grid>
      )}
      {type === 'secondary' && (
        <Grid
          templateColumns={{
            lg: 'repeat(1, 1fr)'
          }}
          border={'1px solid #82b378'}
          borderRadius={12}
          fontSize={14}
          py={2}
          px={6}
          gap={2}
          w={'-webkit-fill-available'}
          alignItems={'center'}
        >
          <Grid
            templateColumns={{
              lg: 'auto repeat(3, 25%) auto'
            }}
            alignItems={'center'}
            gap={2}
          >
            <GridItem mr={'auto'}>
              <Avatar
                name={'1'}
                borderRadius={8}
                size={'sm'}
                bg={'grey.50'}
                color={'green.500'}
                fontWeight={700}
              />
            </GridItem>
            <GridItem mb={'auto'}>
              <Text
                fontWeight={700}
                fontSize={10}
                color={'green.100'}
                textAlign={'start'}
              >
                Nombre del Socio(a)
              </Text>
              <Text fontWeight={700}>{partner?.name}</Text>
            </GridItem>
            <GridItem mb={'auto'}>
              <Text
                fontWeight={700}
                fontSize={10}
                color={'green.100'}
                textAlign={'start'}
              >
                Correo electrónico
              </Text>
              <Text>{partner?.email || 'No disponible'}</Text>
            </GridItem>
            <GridItem mb={'auto'}>
              <Text
                fontWeight={700}
                fontSize={10}
                color={'green.100'}
                textAlign={'start'}
              >
                Teléfono
              </Text>
              <Text>+569 {partner?.phone || 'No disponible'}</Text>
            </GridItem>
            <GridItem ml={'auto'}>
              <SquareButton
                bg={'grey.50'}
                bghover={'grey.100'}
                size={'sm'}
                onClick={deletePartner}
              >
                <Icon as={TrashIcon} />
              </SquareButton>
            </GridItem>
          </Grid>
          {/* spouse data */}
          {Object.keys(partner?.familyMembers).length > 0 ? (
            <>
              <Divider />
              <Grid
                templateColumns={{
                  lg: 'auto repeat(3, 25%) auto'
                }}
                alignItems={'center'}
                gap={2}
              >
                <GridItem mr={'auto'}>
                  <Avatar
                    name={'2'}
                    borderRadius={8}
                    size={'sm'}
                    bg={'grey.50'}
                    color={'green.500'}
                    fontWeight={700}
                  />
                </GridItem>
                {!partner?.familyMembers.showForm ? (
                  <GridItem mb={'auto'}>
                    <Text
                      fontWeight={700}
                      fontSize={10}
                      color={'green.100'}
                      textAlign={'start'}
                    >
                      Nombre del cónyuge
                    </Text>
                    <Text fontWeight={700}>
                      {partner?.familyMembers?.fullName}
                    </Text>
                  </GridItem>
                ) : (
                  <GridItem mb={'auto'}>
                    <Text
                      fontWeight={700}
                      fontSize={10}
                      color={'green.100'}
                      textAlign={'start'}
                    >
                      Nombre del cónyuge
                    </Text>
                    <InputComtrol
                      id={'spouseFullName'}
                      isRequired={false}
                      value={formikItem.values.spouseFullName}
                      onChange={formikItem.handleChange}
                      error={Boolean(formikItem.errors.spouseFullName)}
                      errorText={formikItem.errors.spouseFullName}
                      size={'sm'}
                    />
                  </GridItem>
                )}
                {/* --- */}
                {!partner?.familyMembers.showForm ? (
                  <GridItem mb={'auto'}>
                    <Text
                      fontWeight={700}
                      fontSize={10}
                      type={'email'}
                      color={'green.100'}
                      textAlign={'start'}
                    >
                      Correo electrónico
                    </Text>
                    <Text>
                      {partner?.familyMembers?.email || 'No disponible'}
                    </Text>
                  </GridItem>
                ) : (
                  <GridItem mb={'auto'}>
                    <Text
                      fontWeight={700}
                      fontSize={10}
                      color={'green.100'}
                      textAlign={'start'}
                    >
                      Correo electrónico
                    </Text>
                    <InputComtrol
                      id={'spouseEmail'}
                      isRequired={false}
                      value={formikItem.values.spouseEmail}
                      onChange={formikItem.handleChange}
                      error={Boolean(formikItem.errors.spouseEmail)}
                      errorText={formikItem.errors.spouseEmail}
                      size={'sm'}
                    />
                  </GridItem>
                )}
                {/* ---- */}
                {!partner?.familyMembers.showForm ? (
                  <GridItem mb={'auto'}>
                    <Text
                      fontWeight={700}
                      fontSize={10}
                      color={'green.100'}
                      textAlign={'start'}
                    >
                      Teléfono
                    </Text>
                    <Text>
                      +569 {partner?.familyMembers?.phone || 'No disponible'}
                    </Text>
                  </GridItem>
                ) : (
                  <GridItem mb={'auto'}>
                    <Text
                      fontWeight={700}
                      fontSize={10}
                      color={'green.100'}
                      textAlign={'start'}
                    >
                      Teléfono
                    </Text>
                    <InputComtrol
                      id={'spousePhone'}
                      isRequired={false}
                      type={'number'}
                      addictionType={'phone'}
                      value={formikItem.values.spousePhone}
                      onChange={formikItem.handleChange}
                      error={Boolean(formikItem.errors.spousePhone)}
                      errorText={formikItem.errors.spousePhone}
                      size={'sm'}
                    />
                  </GridItem>
                )}
                <GridItem ml={'auto'}>
                  {!partner?.familyMembers.showForm ? (
                    <Tooltip
                      hasArrow
                      label={'Para editar valores, haz click aquí'}
                      placement={'top'}
                      bg={'green.500'}
                    >
                      <Box>
                        <SquareButton
                          bg={'grey.50'}
                          bghover={'grey.100'}
                          size={'sm'}
                          onClick={updateSpose}
                        >
                          <Icon as={EditIcon} color={'green.100'} />
                        </SquareButton>
                      </Box>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      hasArrow
                      label={'Favor validar familiar antes de continuar'}
                      placement={'top'}
                      bg={'yellow.500'}
                      isOpen={true}
                    >
                      <Box>
                        <SquareButton
                          size={'sm'}
                          onClick={formikItem.handleSubmit}
                        >
                          <Icon as={CheckIcon} />
                        </SquareButton>
                      </Box>
                    </Tooltip>
                  )}
                </GridItem>
              </Grid>
              <Flex justify={'flex-end'}>
                <Button
                  text={'Eliminar cónyuge'}
                  size={'sm'}
                  py={2}
                  bg={'grey.50'}
                  bghover={'grey.100'}
                  color={'green.500'}
                  onClick={deleteSpouse}
                />
              </Flex>
            </>
          ) : (
            <>
              <Divider />
              <Flex justify={'flex-end'}>
                <Button
                  text={'Agregar cónyuge'}
                  size={'sm'}
                  py={2}
                  onClick={addSpouse}
                />
              </Flex>
            </>
          )}
        </Grid>
      )}
    </>
  )
}

export default PartnerItem
