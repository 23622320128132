import React, { useState, useEffect } from 'react'
import { addDays } from 'date-fns'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Icon,
  Text
} from '@chakra-ui/react'
import { CustomDatePicker, Search, SquareButton, Select2 } from '../UI'
import { ReplayIcon } from '../Icons'
import Roles from '../../resources/Roles'

const FiltersUsers = ({
  filtersUsers,
  setFiltersUsers,
  initialFiltersUsers,
  children
}) => {
  const [rolesList, setRolesList] = useState([])
  const handleChangeSearch = (e) => {
    setFiltersUsers({
      ...filtersUsers,
      search: e.target.value
    })
  }

  const handleChangeStartDate = (date) => {
    setFiltersUsers({
      ...filtersUsers,
      startDate: date
    })
  }

  const handleChangeEndDate = (date) => {
    setFiltersUsers({
      ...filtersUsers,
      endDate: date
    })
  }

  const handleChangeRol = (rol) => {
    setFiltersUsers({
      ...filtersUsers,
      rol
    })
  }

  const resetFilters = () => {
    setFiltersUsers(initialFiltersUsers)
  }

  useEffect(() => {
    const all_roles = [{ key: '', name: 'Todos los Roles' }]
    Roles.map((rol) => {
      all_roles.push(rol)
      return rol
    })
    setRolesList(all_roles)
  }, [])

  return (
    <Flex gap={4} direction={'column'}>
      <Flex gap={3} justify={'space-between'} align={'center'}>
        <Box>
          <Search
            onChange={handleChangeSearch}
            currentValue={filtersUsers.search}
            showBtn={false}
          />
        </Box>

        {children}
      </Flex>
      <Accordion allowMultiple>
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              <Text whiteSpace={'nowrap'} fontWeight={700} color={'green.500'}>
                Filtros:
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <Flex gap={3} direction={'column'}>
              <Flex
                gap={4}
                align={'center'}
                justify={'space-between'}
                direction={{
                  lg: 'row',
                  md: 'column-reverse',
                  sm: 'column-reverse'
                }}
              >
                <Flex
                  gap={4}
                  direction={{
                    lg: 'row',
                    md: 'column',
                    sm: 'column'
                  }}
                >
                  <Flex w={400} gap={2} align={'center'}>
                    <Text whiteSpace={'nowrap'} fontWeight={700}>
                      Fecha desde:
                    </Text>
                    <CustomDatePicker
                      placeholder={'DD/MM/AAAA'}
                      currentDate={filtersUsers.startDate}
                      onChange={(date) => {
                        handleChangeStartDate(date)
                      }}
                      maxDate={addDays(new Date(), 0)}
                    />
                  </Flex>
                  <Flex w={400} gap={2} align={'center'}>
                    <Text whiteSpace={'nowrap'} fontWeight={700}>
                      Fecha hasta:
                    </Text>
                    <CustomDatePicker
                      placeholder={'DD/MM/AAAA'}
                      currentDate={filtersUsers.endDate}
                      onChange={(date) => {
                        handleChangeEndDate(date)
                      }}
                      maxDate={addDays(new Date(), 1)}
                    />
                  </Flex>
                </Flex>
                <Box
                  marginRight={{
                    lg: 0,
                    md: 'auto',
                    sm: 'auto'
                  }}
                >
                  <SquareButton onClick={resetFilters}>
                    <Icon as={ReplayIcon} />
                  </SquareButton>
                </Box>
              </Flex>
              <Flex>
                <Box
                  w={{
                    lg: '25%',
                    md: '100%',
                    sm: '100%'
                  }}
                >
                  <Text whiteSpace={'nowrap'} fontWeight={700}>
                    Rol de usuario:
                  </Text>
                  <Select2
                    list={rolesList}
                    onChange={handleChangeRol}
                    value={filtersUsers?.rol}
                  />
                </Box>
              </Flex>
            </Flex>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Flex>
  )
}

export default FiltersUsers
