import { createIcon } from '@chakra-ui/react'
/* https://freeicons.io/ui-essentials-3/ui-essentials-notification-alarm-bell-icon-44563 */
/* eslint-disable import/prefer-default-export */
const WspIcon = createIcon({
  displayName: 'WspIcon',
  viewBox: '0 0 16 16',
  path: [
    <path
      d="M14.6796 4.97213C13.2674 1.88703 9.96683 0.132308 6.53695 0.814321C5.37657 1.04516 4.32856 1.53738 3.41236 2.28852C1.78926 3.61953 0.869971 5.33659 0.69309 7.42707C0.569613 8.88769 0.877689 10.2693 1.58706 11.558C1.61917 11.6164 1.62781 11.7059 1.61052 11.771C1.43426 12.4385 1.25059 13.1041 1.06846 13.7701C1.04315 13.863 1.01753 13.9562 0.992213 14.0494C0.821198 14.6752 1.39074 15.252 2.01892 15.0885C2.81288 14.8817 3.76396 14.6345 4.37487 14.4771C4.44494 14.4589 4.53847 14.4682 4.60268 14.5006C6.19862 15.3103 7.87081 15.5387 9.61924 15.1496C12.7204 14.4592 15.0624 11.7636 15.3091 8.59761C15.4072 7.33881 15.2072 6.12538 14.6793 4.97244L14.6796 4.97213ZM9.2377 13.4214C7.77079 13.7513 6.38816 13.5248 5.09566 12.7591C4.99133 12.6974 4.90057 12.6789 4.7808 12.7116C4.54496 12.7761 4.1986 12.8671 3.86367 12.9548C3.40372 13.0748 2.98575 12.6517 3.11262 12.1938C3.16417 12.0071 3.21881 11.8222 3.27932 11.6392C3.38427 11.3213 3.35309 11.0769 3.17405 10.7847C2.60914 9.86134 2.37145 8.83955 2.42023 7.76036C2.53815 5.1502 4.41067 3.08163 6.77248 2.56842C9.63776 1.94597 12.4231 3.56243 13.3035 6.35097C14.2703 9.41355 12.3731 12.7165 9.2377 13.4214Z"
      fill="#2E7423"
    />,
    <path
      d="M10.0721 8.6414C9.62197 8.6414 9.2272 8.87012 9.00414 9.21418C8.95918 9.26651 8.90552 9.3329 8.84026 9.41927C8.75005 9.53855 8.63228 9.55627 8.49363 9.5031C7.46942 9.11065 6.68829 8.44532 6.12644 7.53045C6.0516 7.40864 6.05944 7.31046 6.15139 7.19961C6.30251 7.01788 6.45305 6.83361 6.57952 6.63584C6.59141 6.61727 6.60127 6.59561 6.60939 6.57198C6.72513 6.38349 6.79184 6.16321 6.79184 5.92802C6.79184 5.23203 6.21027 4.66797 5.49266 4.66797C5.27453 4.66797 5.06917 4.7203 4.88875 4.81229C4.81333 4.84239 4.7443 4.88712 4.68048 4.94479C4.55518 5.04213 4.44901 5.16169 4.36809 5.29701C4.13517 5.62166 4.00754 5.99188 4 6.41725C4.02553 6.58548 4.03945 6.75625 4.07832 6.92138C4.2071 7.46969 4.51138 7.93162 4.84437 8.38062C5.85204 9.73857 7.10714 10.7668 8.79704 11.2288C9.45577 11.4088 10.0756 11.3759 10.6659 11.0003C10.8379 10.8909 10.9679 10.7637 11.0624 10.6219C11.2318 10.4138 11.3333 10.151 11.3333 9.86489C11.3333 9.18915 10.7686 8.6414 10.0719 8.6414H10.0721Z"
      fill="#2E7423"
    />
  ]
})

export default WspIcon
