import { React, useEffect, useState } from 'react'
import { Grid, GridItem, Flex, useToast } from '@chakra-ui/react'
import { Heading, BackButton } from '../../../components/UI'
import FormUploadMasivePartners from '../../../components/FormUploadMasivePartners'
import TablePartnersSync from '../../../components/TablePartnersSync'
import FiltersPartnersSync from '../../../components/FiltersPartnersSync'
import { usePartnersSync } from '../../../hooks/PartnersSync'

const Partners = () => {
  const toast = useToast()

  /* table */
  const initialFiltersPartnersSync = {
    search: '',
    month: 'Todos los meses',
    year: 'Todos los años'
  }
  const [filtersPartnersSync, setFiltersPartnersSync] = useState(
    initialFiltersPartnersSync
  )

  const [page, setPage] = useState(0)
  const limit = 6
  const {
    data: partnersSync,
    isLoading,
    isFetching,
    isError,
    refetch
  } = usePartnersSync(filtersPartnersSync, page, limit)
  const headers = ['#', 'Año', 'Mes', 'Status', 'Reporte']

  useEffect(() => {
    setPage(0)
  }, [filtersPartnersSync])
  /* table */

  useEffect(() => {
    toast.closeAll()
  }, [])

  return (
    <>
      <Flex direction={'column'} px={10}>
        <Flex
          minW={'100%'}
          align={{
            lg: 'center',
            md: 'start',
            sm: 'start'
          }}
          direction={{
            lg: 'row',
            md: 'column',
            sm: 'column'
          }}
        >
          <BackButton showText={false} />
          <Heading title={'ACTUALIZACIÓN DE SOCIOS'} />
        </Flex>

        <Grid templateColumns={'repeat(1, 1fr)'} py={10}>
          <GridItem borderBottom={'1px solid #2E7423'} pb={4}>
            <FiltersPartnersSync
              filtersPartnersSync={filtersPartnersSync}
              setFiltersPartnersSync={setFiltersPartnersSync}
              initialFiltersPartnersSync={initialFiltersPartnersSync}
            />
            <br />
            <FormUploadMasivePartners refetch={refetch} />
          </GridItem>
          <GridItem>
            <TablePartnersSync
              page={page}
              setPage={setPage}
              limit={limit}
              partnersSync={partnersSync}
              isLoading={isLoading}
              isFetching={isFetching}
              isError={isError}
            />
          </GridItem>
        </Grid>
      </Flex>
    </>
  )
}

export default Partners
