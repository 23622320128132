import * as Yup from 'yup'
/* eslint-disable import/prefer-default-export */
export const steepOneSchema = Yup.object().shape({
  receptionDate: Yup.string().required('La fecha de recepción es requerida'),
  isPartnerZone: Yup.boolean(),
  zone: Yup.string().required('La zona es requerida'),
  prefecture: Yup.string().required('La prefectura es requerida'),
  unit: Yup.string().required('La unidad es requerida'),
  address: Yup.string().required('La Dirección es Requerida'),
  /* partner data */
  partnerRut: Yup.string()
    .min(11, 'El Rut no es valido')
    .max(12, 'El Rut no es valido'),
  partnerPhone: Yup.string()
    .min(8, 'El telefono no es valido')
    .max(9, 'El telefono no es valido')
    .required('El telefono es requerido'),
  partnerGrade: Yup.string(),
  partnerCode: Yup.string()
    .min(3, 'El código no puede tener menos de 3 caracteres')
    .max(8, 'El código no puede tener más de 8 caracteres')
    .test(
      'last-caracter',
      'El ultimo caracter del código debe ser una letra',
      (value) => {
        if (!value) {
          return true
        }
        const lastChar = value?.slice(-1)
        return /^[a-zA-Z]+$/.test(lastChar)
      }
    )
    .test(
      'start-code',
      'Los primeros caracteres antes del guión del código deben ser números',
      (value) => {
        if (!value) {
          return true
        }
        const valueArray = value.split('-')
        const lengthFirstChar = valueArray[0].length
        const firstChar = value.slice(0, lengthFirstChar)
        return /^[0-9]+$/.test(firstChar)
      }
    ),
  partnerZone: Yup.string(),
  partnerPrefecture: Yup.string(),
  partnerEndowment: Yup.string()
})

export const steepTwoSchema = Yup.object().shape({
  postulantName: Yup.string()
    .max(40, 'El nombre no debe exceder los 40 caracteres')
    .required('El nombre del postulante es Requerido'),
  postulantDateOfBirth: Yup.string().required('Seleccione fecha de Nacimiento'),
  postulantAverage: Yup.string().required('El Promedio general es Requerido'),
  postulantPhone: Yup.string()
    .min(8, 'El telefono no es valido')
    .max(9, 'El telefono no es valido')
    .required('El Teléfono es Requerido'),
  postulantScoreName: Yup.array()
    .min(1, 'Seleccione al menos un Documento')
    .required('Seleccione al menos un Documento'),
  schoolName: Yup.string().required('El Colegio es Requerido'),
  postulantAge: Yup.string().required('La edad es Requerida'),
  postulantAdress: Yup.string().required('La Dirección es Requerida'),
  postulantEmail: Yup.string()
    .email('Ingrese un Email Valido')
    .required('El correo es Requerido'),
  postulantCertificateName: Yup.array()
    .min(1, 'Seleccione al menos un Documento')
    .required('Seleccione al menos un Documento')
})

export const steepThreeSchema = Yup.object().shape({
  memberName: Yup.string()
    .max(40, 'El nombre no debe exceder los 40 caracteres')
    .required('El nombre es Requerido'),
  memberAge: Yup.string().required('La Edad es Requerida'),
  memberRelationship: Yup.string().required('El Parentesco es Requerido'),
  memberActivity: Yup.string().required('La Actividad es Requerida'),
  memberIncomes: Yup.string(),
  memberSwornDeclarationName: Yup.array(),
  /* --- health data --- */
  haveMedicalHistory: Yup.boolean(),
  doctorName: Yup.string().when('haveMedicalHistory', {
    is: true,
    then: Yup.string()
  }),
  hospitalName: Yup.string().when('haveMedicalHistory', {
    is: true,
    then: Yup.string()
  }),
  monthlyExpenditure: Yup.string().when('haveMedicalHistory', {
    is: true,
    then: Yup.string().required('El gasto mensual es Requerido')
  }),
  diagnostic: Yup.string().when('haveMedicalHistory', {
    is: true,
    then: Yup.string()
  }),
  evolutionTime: Yup.string().when('haveMedicalHistory', {
    is: true,
    then: Yup.string()
  }),
  observation: Yup.string().when('haveMedicalHistory', {
    is: true,
    then: Yup.string()
  })
})

export const stepFourSchema = Yup.object().shape({
  salatyPartner: Yup.string().required('Los Haberes funcionario es Requeridos'),
  isSalatySpouse: Yup.boolean(),
  salarySpouse: Yup.string().when('isSalatySpouse', {
    is: true,
    then: Yup.string().required('El ingresos del cónyuge es Requerido')
  }),
  otherSalaty: Yup.string().required('Los otros ingresos son Requeridos'),
  totalExpensesGroup: Yup.string().required(
    'El Total de egresos mensuales es Requerido'
  ),
  salarySettlementsNames: Yup.array()
    .min(1, 'Seleccione al menos un Documento')
    .required('Seleccione al menos un Documento')
})

export const stepFiveSchema = Yup.object().shape({
  reasonApplicationName: Yup.array()
    .min(1, 'Seleccione al menos un Documento')
    .required('Seleccione al menos un Documento'),
  socialReportName: Yup.array()
    .min(1, 'Seleccione al menos un Documento')
    .required('Seleccione al menos un Documento'),
  additionalDocumentsName: Yup.array()
})
