import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

const SwalModal = withReactContent(Swal)

const question = (
  title,
  resultSuccess,
  resultCancel,
  text = '',
  icon = 'success',
  showCancelButton = true
) => {
  SwalModal.fire({
    title,
    text,
    icon,
    color: '#2E7423',
    showCancelButton,
    confirmButtonColor: '#2E7423',
    cancelButtonColor: '#F56767',
    confirmButtonText: 'Confirmar',
    cancelButtonText: 'Cancelar',
    reverseButtons: true
  }).then((result) => {
    if (result.isConfirmed) {
      resultSuccess()
    } else {
      resultCancel()
    }
  })
}

const basic = (title, textButton, text = '', icon = 'success') => {
  SwalModal.fire({
    title,
    text,
    icon,
    color: '#2E7423',
    showCancelButton: false,
    confirmButtonColor: icon !== 'error' ? '#2E7423' : '#F56767',
    confirmButtonText: textButton,
    reverseButtons: true
  })
}

const warning = (title, textButton, funt, text = '', icon = 'warning') => {
  SwalModal.fire({
    title,
    text,
    icon,
    color: '#2E7423',
    showCancelButton: false,
    confirmButtonColor: icon !== 'error' ? '#FF9633' : '#F56767',
    confirmButtonText: textButton,
    reverseButtons: true
  }).then(() => {
    funt()
  })
}
export default { question, basic, warning }
