const createNewFile = (file, name) => {
  const newNameFile = `${name}.${file.name.split('.')?.at(1)}`
  const newFile = new File([file], newNameFile, {
    type: file?.type,
    size: file?.size,
    lastModified: file?.lastModified,
    lastModifiedDate: file?.lastModifiedDate
  })

  return newFile
}

export default createNewFile
