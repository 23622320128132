import Layout from '../components/Layout/Layout'
import Programs from '../pages/Programs'
import Create from '../pages/Programs/Create'
import Upload from '../pages/Programs/UploadMasive'
import Edit from '../pages/Programs/Edit'
import View from '../pages/Programs/View'
import CreateWorkshopsAndTraining from '../pages/Programs/WorkshopsAndTraining/Create'
import EditWorkshopsAndTraining from '../pages/Programs/WorkshopsAndTraining/Edit'
/* --- */
import Can from '../auth/Can'
import Forbidden from '../pages/Forbidden'
import Login from '../pages/Login'

const rootName = 'programs'

const programsRoutes = [
  {
    path: `/${rootName}`,
    key: 'PROGRAMS',
    exact: true,
    component: ({ authenticated }) => (
      <Can
        availableTo={[
          'ADMINISTRADOR',
          'ABASTECIMIENTO',
          'ASISTENTE_SOCIAL',
          'ASESOR_JURIDICO',
          'GERENCIA',
          'PRESIDENCIA',
          'FINANZAS',
          'DEPTO_CONTROL_GESTION_SOCIAL',
          'ADMINISTRATIVE-MULTIPLE-PROGRAMS',
          'ADMINISTRATIVE-HOSPITAL-ACCOMMODATION',
          'ADMINISTRATIVE-INUJRED-IN-LINES-OF-SERVICE-KIT',
          'ADMINISTRATIVE-WORKSHOPS-AND-TRAINING',
          'ADMINISTRATIVE-NEWBORN-BENEFIT',
          'REVISION'
        ]}
        yes={() => (
          <Layout>
            <Programs />
          </Layout>
        )}
        no={() => (authenticated ? <Forbidden /> : <Login />)}
      />
    )
  },
  {
    path: `/${rootName}/create/:partnerId`,
    key: 'PROGRAMS-CREATE',
    exact: true,
    component: ({ authenticated }) => (
      <Can
        availableTo={[
          'ADMINISTRADOR',
          'ASISTENTE_SOCIAL',
          'ADMINISTRATIVE-MULTIPLE-PROGRAMS',
          'ADMINISTRATIVE-HOSPITAL-ACCOMMODATION',
          'ADMINISTRATIVE-INUJRED-IN-LINES-OF-SERVICE-KIT',
          'ADMINISTRATIVE-WORKSHOPS-AND-TRAINING',
          'ADMINISTRATIVE-NEWBORN-BENEFIT'
        ]}
        yes={() => (
          <Layout>
            <Create />
          </Layout>
        )}
        no={() => (authenticated ? <Forbidden /> : <Login />)}
      />
    )
  },
  {
    path: `/${rootName}/edit/:programId/:type`,
    key: 'PROGRAMS-UPDATE',
    exact: true,
    component: ({ authenticated }) => (
      <Can
        availableTo={[
          'ADMINISTRADOR',
          'ASISTENTE_SOCIAL',
          'ADMINISTRATIVE-MULTIPLE-PROGRAMS',
          'ADMINISTRATIVE-HOSPITAL-ACCOMMODATION',
          'ADMINISTRATIVE-INUJRED-IN-LINES-OF-SERVICE-KIT',
          'ADMINISTRATIVE-WORKSHOPS-AND-TRAINING',
          'ADMINISTRATIVE-NEWBORN-BENEFIT'
        ]}
        yes={() => (
          <Layout>
            <Edit />
          </Layout>
        )}
        no={() => (authenticated ? <Forbidden /> : <Login />)}
      />
    )
  },
  {
    path: `/${rootName}/view/:programId/:type`,
    key: 'PROGRAMS-VIEW',
    exact: true,
    component: ({ authenticated }) => (
      <Can
        availableTo={[
          'ADMINISTRADOR',
          'ABASTECIMIENTO',
          'ASISTENTE_SOCIAL',
          'ASESOR_JURIDICO',
          'GERENCIA',
          'PRESIDENCIA',
          'FINANZAS',
          'DEPTO_CONTROL_GESTION_SOCIAL',
          'ADMINISTRATIVE-MULTIPLE-PROGRAMS',
          'ADMINISTRATIVE-HOSPITAL-ACCOMMODATION',
          'ADMINISTRATIVE-INUJRED-IN-LINES-OF-SERVICE-KIT',
          'ADMINISTRATIVE-WORKSHOPS-AND-TRAINING',
          'ADMINISTRATIVE-NEWBORN-BENEFIT',
          'ABASTECIMIENTO',
          'REVISION'
        ]}
        yes={() => (
          <Layout>
            <View />
          </Layout>
        )}
        no={() => (authenticated ? <Forbidden /> : <Login />)}
      />
    )
  },
  {
    path: `/${rootName}/create-workshops-and-training`,
    key: 'CREATE-WORKSHOPS-AND-TRAINING',
    exact: true,
    component: ({ authenticated }) => (
      <Can
        availableTo={[
          'ADMINISTRADOR',
          'ASISTENTE_SOCIAL',
          'ADMINISTRATIVE-WORKSHOPS-AND-TRAINING'
        ]}
        yes={() => (
          <Layout>
            <CreateWorkshopsAndTraining />
          </Layout>
        )}
        no={() => (authenticated ? <Forbidden /> : <Login />)}
      />
    )
  },
  {
    path: `/${rootName}/edit-workshops-and-training/:programId`,
    key: 'UPDATE-WORKSHOPS-AND-TRAINING',
    exact: true,
    component: ({ authenticated }) => (
      <Can
        availableTo={[
          'ADMINISTRADOR',
          'ASISTENTE_SOCIAL',
          'ADMINISTRATIVE-WORKSHOPS-AND-TRAINING'
        ]}
        yes={() => (
          <Layout>
            <EditWorkshopsAndTraining />
          </Layout>
        )}
        no={() => (authenticated ? <Forbidden /> : <Login />)}
      />
    )
  },
  {
    path: `/${rootName}/upload`,
    key: 'PROGRAMS-UPLOAD-MASIVE',
    exact: true,
    component: ({ authenticated }) => (
      <Can
        availableTo={[
          'ADMINISTRADOR',
          'ASISTENTE_SOCIAL',
          'ADMINISTRATIVE-MULTIPLE-PROGRAMS',
          'ADMINISTRATIVE-HOSPITAL-ACCOMMODATION',
          'ADMINISTRATIVE-INUJRED-IN-LINES-OF-SERVICE-KIT',
          'ADMINISTRATIVE-WORKSHOPS-AND-TRAINING',
          'ADMINISTRATIVE-NEWBORN-BENEFIT'
        ]}
        yes={() => (
          <Layout>
            <Upload />
          </Layout>
        )}
        no={() => (authenticated ? <Forbidden /> : <Login />)}
      />
    )
  }
]
export default programsRoutes
