import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import React from 'react'
import { FormatMoney } from '../../../../Utils'
import { DocIcon, MoneyIcon } from '../../../Icons'

const renderCard = (title, icon, value) => (
  <Flex
    direction={'column'}
    justify={'center'}
    gap={4}
    boxShadow={'xl'}
    borderRadius={8}
    py={10}
    px={5}
  >
    <Icon as={icon} color={'green.100'} />
    <Box>
      <Text fontSize={'md'} color={'green.500'} noOfLines={1}>
        {title}
      </Text>
      <Text fontSize={'xl'} color={'green.500'} fontWeight={700} noOfLines={1}>
        {value}
      </Text>
    </Box>
  </Flex>
)

const AmountAndCount = ({ data }) => (
  <Flex direction={'column'} gap={2} justify={'center'} h={'100%'}>
    {renderCard('TOTAL', MoneyIcon, `$ ${FormatMoney(data?.totalByYear) || 0}`)}
    {renderCard('Solicitudes en total', DocIcon, data?.totalRecords || 0)}
  </Flex>
)

export default AmountAndCount
