import { React, useState, Fragment } from 'react'
import {
  Tabs,
  Grid,
  GridItem,
  Tab,
  Hide,
  Show,
  TabList as TabListCha
} from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import Accordion from './Accordion'
import { updateFilters } from '../../store/slices/programs'

const TabListHeader = ({
  options = [],
  children,
  orientation = 'horizontal'
}) => {
  const [tabIndex, setTabIndex] = useState(0)
  const { filters } = useSelector((state) => state.programs)
  const dispatch = useDispatch()

  const handleTabsChange = (index) => {
    setTabIndex(index)
    dispatch(updateFilters({ ...filters, category: options[index].key }))
  }

  return (
    <Fragment>
      <Show below="md">
        <Grid gap={2}>
          <Accordion>
            {options.map((item, index) => (
              <GridItem
                key={item.key}
                bg={'beige.500'}
                _hover={{ bg: 'beige.600', cursor: 'pointer' }}
                fontWeight={500}
                color={'green.500'}
                borderRadius={17}
                py={5}
                textAlign={'center'}
                mb={2}
                onClick={() => {
                  handleTabsChange(index)
                }}
              >
                {item.component}
              </GridItem>
            ))}
          </Accordion>
        </Grid>
      </Show>
      <Tabs
        index={
          tabIndex || options.findIndex((item) => item.key === filters.category)
        }
        defaultIndex={tabIndex}
        onChange={handleTabsChange}
        orientation={orientation}
        variant="soft-rounded"
        colorScheme="green"
        py={4}
        align="center"
      >
        <TabListCha
          gap={2}
          pr={{
            lg: 2
          }}
        >
          <Hide below={'md'}>
            {options.map((item) => (
              <Tab key={item.key} color={'green.500'} borderRadius={12} py={2}>
                {item.component}
              </Tab>
            ))}
          </Hide>
        </TabListCha>
      </Tabs>
      {children}
    </Fragment>
  )
}

export default TabListHeader
