/* eslint-disable import/prefer-default-export */
import axios from '../Axios'

const module = '/partners'

export const uploadMasivePartners = async (formData) => {
  const { data } = await axios.post(`${module}/upload-masive/`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
  return data
}

export const getPartners = async (filtersPartners, page = 0, limit = 6) => {
  const { search, startDate, endDate, grade, state, from } = filtersPartners
  const { data } = await axios.get(
    `${module}/?search=${search}&page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&grade=${grade}&from=${from}&state=${state}`
  )
  return data
}

export const getPartnersWithFamilyMembers = async (
  filtersPartners,
  page = 0,
  limit = 6
) => {
  const { search, relationship } = filtersPartners
  const { data } = await axios.get(
    `${module}/family-members/?search=${search}&page=${page}&limit=${limit}&relationship=${relationship}`
  )
  return data
}

export const getPartnersById = async (partnerId) => {
  const { data } = await axios.get(`${module}/${partnerId}`)
  return data
}

export const createNewPartner = async (formData) => {
  const { data } = await axios.post(`${module}/`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
  return data
}

export const updatePartner = async ({ partnerId, formData }) => {
  const { data } = await axios.patch(`${module}/${partnerId}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
  return data
}

export const addResignationPartner = async ({ partnerId, formData }) => {
  console.log('partnerId', partnerId)
  const { data } = await axios.patch(
    `${module}/${partnerId}/add-resignation`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
  )
  return data
}

export const deletePartner = async (partnerId) => {
  const { data } = await axios.delete(`${module}/${partnerId}`)
  return data
}
