import React from 'react'
import { useSelector } from 'react-redux'
import { Grid, GridItem, Flex, Avatar, Text } from '@chakra-ui/react'
import { InputComtrol } from '../../../components/UI'
import { GetUserType } from '../../../Utils'

const Profile = () => {
  const { user } = useSelector((state) => state.auth)

  return (
    <Grid
      templateColumns={{
        lg: 'repeat(2, 10% 90%)',
        md: 'repeat(1, 100%)',
        sm: 'repeat(1, 100%)'
      }}
      py={{
        lg: '5%',
        md: 0,
        sm: 0
      }}
      gap={20}
    >
      <GridItem display={'flex'} justifyContent={'center'}>
        <Avatar
          name={`${user?.name} ${user?.lastName}`}
          color={'white'}
          size={'2xl'}
          borderRadius={12}
          bg={'green.100'}
        />
      </GridItem>
      <GridItem
        w={{
          lg: '50%',
          md: '100%',
          sm: '100%'
        }}
      >
        <Flex bg={'green.50'} borderRadius={12} gap={5} p={5} mb={5}>
          <Text fontWeight={700} color={'green.500'}>
            Rol
          </Text>
          <Text color={'green.500'}>{GetUserType(user?.role)}</Text>
        </Flex>
        <InputComtrol
          isRequired={false}
          label={'Nombre Completo'}
          value={`${user?.name} ${user?.lastName}`}
          readOnly={true}
        />
        <InputComtrol
          isRequired={false}
          label={'Correo'}
          value={user?.email}
          readOnly={true}
        />
        <InputComtrol
          isRequired={false}
          label={'Teléfono'}
          addictionType={'phone'}
          value={user?.phone}
          readOnly={true}
        />
      </GridItem>
    </Grid>
  )
}

export default Profile
