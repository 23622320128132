import React from 'react'
import { Checkbox } from '@chakra-ui/react'

const RenderCheck = ({ title, value }) => (
  <Checkbox
    css={{ borderColor: 'green' }}
    size="md"
    colorScheme="green"
    isChecked={value}
    readOnly={true}
  >
    {title}
  </Checkbox>
)

export default RenderCheck
