import { createIcon } from '@chakra-ui/react'
/* https://freeicons.io/ui-essentials-3/ui-essentials-notification-alarm-bell-icon-44563 */
/* eslint-disable import/prefer-default-export */
const DownloadIcon = createIcon({
  displayName: 'DownloadIcon',
  viewBox: '0 0 20 21',
  path: [
    <path
      d="M10.0016 13.6504C9.80156 13.6504 9.6059 13.6127 9.41456 13.5374C9.22256 13.4627 9.05156 13.3504 8.90156 13.2004L4.92656 9.22539C4.62656 8.92539 4.47656 8.55872 4.47656 8.12539C4.47656 7.69206 4.62656 7.32539 4.92656 7.02539C5.22656 6.72539 5.59323 6.57539 6.02656 6.57539C6.4599 6.57539 6.82656 6.72539 7.12656 7.02539L8.42656 8.32539V2.22539C8.42656 1.79206 8.5809 1.42106 8.88956 1.11239C9.19756 0.804391 9.56823 0.650391 10.0016 0.650391C10.4349 0.650391 10.8059 0.804391 11.1146 1.11239C11.4226 1.42106 11.5766 1.79206 11.5766 2.22539V8.32539L12.8766 7.02539C13.1766 6.72539 13.5432 6.57539 13.9766 6.57539C14.4099 6.57539 14.7766 6.72539 15.0766 7.02539C15.3766 7.32539 15.5266 7.69206 15.5266 8.12539C15.5266 8.55872 15.3766 8.92539 15.0766 9.22539L11.1016 13.2004C10.9516 13.3504 10.7809 13.4627 10.5896 13.5374C10.3976 13.6127 10.2016 13.6504 10.0016 13.6504ZM3.50156 20.6004C2.61823 20.6004 1.87256 20.2964 1.26456 19.6884C0.655896 19.0797 0.351562 18.3337 0.351562 17.4504V14.5004C0.351562 14.0671 0.505563 13.6961 0.813563 13.3874C1.12223 13.0794 1.49323 12.9254 1.92656 12.9254C2.3599 12.9254 2.73056 13.0794 3.03856 13.3874C3.34723 13.6961 3.50156 14.0671 3.50156 14.5004V17.4504H16.4516V14.5004C16.4516 14.0671 16.6059 13.6961 16.9146 13.3874C17.2226 13.0794 17.5932 12.9254 18.0266 12.9254C18.4599 12.9254 18.8309 13.0794 19.1396 13.3874C19.4476 13.6961 19.6016 14.0671 19.6016 14.5004V17.4504C19.6016 18.3337 19.2976 19.0797 18.6896 19.6884C18.0809 20.2964 17.3349 20.6004 16.4516 20.6004H3.50156Z"
      fill={'currentColor' || 'white'}
    />
  ]
})

export default DownloadIcon
