import React, { useEffect, useState } from 'react'
import { addDays } from 'date-fns'
import { useFormik } from 'formik'
import { Grid, GridItem, Text, Checkbox } from '@chakra-ui/react'
import { CustomDatePicker, InputComtrol, Select } from '../../UI'
import {
  GradesCarabineros,
  FormatRut,
  FormatCode,
  RelationshipList3,
  Age,
  ZonesTwoList
} from '../../../Utils'
import { stepOneSchema } from './Schemas'
import SelectFamilyMember from '../../SelectFamilyMember'

const StepOne = ({ stepOneForm, setStepOneForm, refStepOne, next }) => {
  /* const [showFieldsMember, setShowFieldsMember] = useState(false) */
  const [hasSpouse, setHasSpouse] = useState(false)
  const initialValues = {
    /* partner field */
    partnerId: '',
    partnerRut: '',
    partnerPhone: '',
    partnerGrade: GradesCarabineros.at(0),
    partnerCode: '',
    partnerZone: stepOneForm.partnerZone || ZonesTwoList.getRegions().at(0),
    partnerPrefecture:
      stepOneForm.partnerPrefecture ||
      ZonesTwoList.getCommunes(ZonesTwoList.getRegions().at(0)).at(0),
    partnerEndowment: stepOneForm.partnerEndowment || '',
    /* end partner field */
    receptionDate: '',
    isPartnerZone: false,
    zone: stepOneForm.partnerZone || ZonesTwoList.getRegions().at(0),
    prefecture:
      stepOneForm.partnerPrefecture ||
      ZonesTwoList.getCommunes(ZonesTwoList.getRegions().at(0)).at(0),
    unit: stepOneForm.partnerEndowment || '',
    medicalSpeciality: '',
    incomeDate: '',
    exitDate: '',
    isRequiredExtension: false, // por si lo piden
    /* pacient field */
    isPatientPartner: false,
    patientId: '',
    patientFullName: '',
    patientAge: Age.at(0),
    patientRelationship: RelationshipList3.at(0),
    toCreate: false
    /* end pacient field */
  }

  const formik = useFormik({
    initialValues,
    validationSchema: stepOneSchema,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: (values) => {
      setStepOneForm({
        ...values
      })
      next()
    }
  })

  const handleChangeSelectFamilyMember = (dataMember) => {
    /* setShowFieldsMember(true) */
    if (dataMember === 'ADD_FAMILY_MEMBER') {
      formik.setValues({
        ...formik.values,
        patientId: Math.random()?.toString(),
        patientFullName: '',
        patientAge: Age.at(0),
        patientRelationship: RelationshipList3.at(0),
        toCreate: true
      })
      return
    }
    formik.setValues({
      ...formik.values,
      patientId: dataMember?._id || '',
      patientFullName: dataMember?.fullName || '',
      patientAge: dataMember.age?.toString() || '',
      patientRelationship: dataMember?.relationship || '',
      toCreate: false
    })
  }

  console.log(formik.values.patientRelationship)

  const hadleChangePartnerZone = (value) => {
    formik.setFieldValue('partnerZone', value)
    formik.setFieldValue(
      'partnerPrefecture',
      ZonesTwoList.getCommunes(value).at(0)
    )
  }

  const handleChangePartnerPrefecture = (value) => {
    formik.setFieldValue('partnerPrefecture', value)
  }

  const handleChangePartnerGrade = (value) => {
    formik.setFieldValue('partnerGrade', value)
  }

  const handleChangeRut = () => {
    if (formik.values.partnerRut) {
      const formatedRut = FormatRut(formik.values.partnerRut)
      formik.setFieldValue('partnerRut', formatedRut)
    }
  }

  const formatCode = () => {
    if (formik.values.partnerCode) {
      const formatedCode = FormatCode(formik.values.partnerCode)
      formik.setFieldValue('partnerCode', formatedCode)
    }
  }

  const handleChangeReceptionDate = (date) => {
    formik.setFieldValue('receptionDate', date)
  }

  const handleChangeIncomeDate = (date) => {
    formik.setFieldValue('incomeDate', date)
  }

  const handleChangeExitDate = (date) => {
    formik.setFieldValue('exitDate', date)
  }

  const handleChangeParientAge = (value) => {
    formik.setFieldValue('patientAge', value)
  }

  const handleChangeParientRelationship = (value) => {
    formik.setFieldValue('patientRelationship', value)
  }

  const hadleChangeZone = (value) => {
    formik.setFieldValue('zone', value)
    formik.setFieldValue('prefecture', ZonesTwoList.getCommunes(value).at(0))
  }

  const handleChangePrefecture = (value) => {
    formik.setFieldValue('prefecture', value)
  }
  const handleChangeIsPartnerZone = (e) => {
    const isChecked = e.target.checked

    if (isChecked) {
      formik.setValues({
        ...formik.values,
        isPartnerZone: isChecked,
        zone: formik.values.partnerZone,
        prefecture: formik.values.partnerPrefecture,
        unit: formik.values.partnerEndowment
      })
    } else {
      formik.setValues({
        ...formik.values,
        isPartnerZone: isChecked
      })
    }
  }

  useEffect(() => {
    refStepOne.current = formik.handleSubmit
  }, [])

  useEffect(() => {
    formik.setValues({
      /* partner field */
      partnerId: stepOneForm.partnerId || '',
      partnerRut: stepOneForm.partnerRut || '',
      partnerPhone: stepOneForm.partnerPhone || '',
      partnerGrade: stepOneForm.partnerGrade || GradesCarabineros.at(0),
      partnerCode: stepOneForm.partnerCode || '',
      partnerZone: stepOneForm.partnerZone || ZonesTwoList.getRegions().at(0),
      partnerPrefecture:
        stepOneForm.partnerPrefecture ||
        ZonesTwoList.getCommunes(ZonesTwoList.getRegions().at(0)).at(0),
      partnerEndowment: stepOneForm.partnerEndowment || '',
      /* end partner field */
      receptionDate: stepOneForm.receptionDate || '',
      isPartnerZone: stepOneForm.isPartnerZone || false,
      zone:
        stepOneForm.zone ||
        stepOneForm.partnerZone ||
        ZonesTwoList.getRegions().at(0),
      prefecture:
        stepOneForm.prefecture ||
        stepOneForm.partnerPrefecture ||
        ZonesTwoList.getCommunes(ZonesTwoList.getRegions().at(0)).at(0),
      unit: stepOneForm.unit || stepOneForm.partnerEndowment,
      medicalSpeciality: stepOneForm.medicalSpeciality || '',
      incomeDate: stepOneForm.incomeDate || '',
      exitDate: stepOneForm.exitDate || '',
      isRequiredExtension: stepOneForm.isRequiredExtension || false,
      isPatientPartner: stepOneForm.isPatientPartner || false,
      patientId: stepOneForm.patientId || '',
      patientFullName: stepOneForm.patientFullName || '',
      patientAge: stepOneForm.patientAge || Age.at(0),
      patientRelationship:
        stepOneForm.patientRelationship || RelationshipList3.at(0),
      toCreate: stepOneForm.toCreate || false
    })
  }, [stepOneForm])

  return (
    <>
      {/* partner data */}
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          <Text fontWeight={700} color={'green.500'} fontSize={'xl'}>
            Información del Socio (Opcionales)
          </Text>
          <InputComtrol
            id={'partnerRut'}
            label={'Rut Socio'}
            isRequired={false}
            value={formik.values.partnerRut}
            onChange={formik.handleChange}
            onBlur={handleChangeRut}
            error={Boolean(formik.errors.partnerRut)}
            errorText={formik.errors.partnerRut}
          />
          <InputComtrol
            id={'partnerPhone'}
            label={'Teléfono Socio'}
            isRequired={false}
            type={'number'}
            addictionType={'phone'}
            value={formik.values.partnerPhone}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.partnerPhone)}
            errorText={formik.errors.partnerPhone}
          />
          <InputComtrol
            id={'partnerZone'}
            label={'Zona Socio'}
            isRequired={false}
          >
            <Select
              bg={'white'}
              list={ZonesTwoList.getRegions()}
              value={formik.values.partnerZone}
              onChange={hadleChangePartnerZone}
              error={Boolean(formik.errors.partnerZone)}
              errorText={formik.errors.partnerZone}
            />
          </InputComtrol>
        </GridItem>
        <GridItem>
          <Text fontSize={'xl'} opacity={0}>
            -----
          </Text>
          <InputComtrol
            id={'partnerCode'}
            label={'Código Socio. ej: 001001-D'}
            isRequired={false}
            value={formik.values.partnerCode}
            onChange={formik.handleChange}
            onBlur={formatCode}
            error={Boolean(formik.errors.partnerCode)}
            errorText={formik.errors.partnerCode}
          />
          <InputComtrol
            id={'partnerGrade'}
            label={'Grado Socio'}
            isRequired={false}
          >
            <Select
              bg={'white'}
              list={GradesCarabineros}
              value={formik.values.partnerGrade}
              onChange={handleChangePartnerGrade}
            />
          </InputComtrol>

          <InputComtrol
            id={'partnerPrefecture'}
            label={'Alta repartición / Repartición Socio'}
            isRequired={false}
            error={Boolean(formik.errors.partnerPrefecture)}
            errorText={formik.errors.partnerPrefecture}
          >
            <Select
              bg={'white'}
              list={ZonesTwoList.getCommunes(formik.values.partnerZone)}
              value={formik.values.partnerPrefecture}
              onChange={handleChangePartnerPrefecture}
            />
          </InputComtrol>
        </GridItem>
      </Grid>
      <Grid
        templateColumns={{
          lg: 'repeat(1, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          <InputComtrol
            id={'partnerEndowment'}
            label={'Unidad Socio'}
            isRequired={false}
            value={formik.values.partnerEndowment}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.partnerEndowment)}
            errorText={formik.errors.partnerEndowment}
          />
        </GridItem>
      </Grid>
      {/* end partner data */}
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          <Text fontWeight={700} color={'green.500'} fontSize={'xl'}>
            Información de hospedaje
          </Text>
          <InputComtrol
            id={'receptionDate'}
            label={'Fecha de recepción de solicitud'}
          >
            <CustomDatePicker
              placeholder={'DD/MM/AAAA'}
              currentDate={formik.values.receptionDate}
              onChange={(date) => handleChangeReceptionDate(date)}
              error={Boolean(formik.errors.receptionDate)}
              errorText={formik.errors.receptionDate}
              maxDate={addDays(new Date(), 0)}
            />
          </InputComtrol>
          <InputComtrol id={'incomeDate'} label={'Fecha de ingreso'}>
            <CustomDatePicker
              placeholder={'DD/MM/AAAA'}
              currentDate={formik.values.incomeDate}
              onChange={(date) => handleChangeIncomeDate(date)}
              error={Boolean(formik.errors.incomeDate)}
              errorText={formik.errors.incomeDate}
            />
          </InputComtrol>
        </GridItem>
        <GridItem>
          <Text fontSize={'xl'} opacity={0}>
            -----
          </Text>
          <InputComtrol
            id={'medicalSpeciality'}
            label={'Especialidad médica'}
            value={formik.values.medicalSpeciality}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.medicalSpeciality)}
            errorText={formik.errors.medicalSpeciality}
          />

          <InputComtrol id={'exitDate'} label={'Fecha de salida'}>
            <CustomDatePicker
              placeholder={
                formik.values.incomeDate !== ''
                  ? 'DD/MM/AAAA'
                  : 'Es necesario ingresar la fecha de ingreso'
              }
              currentDate={formik.values.exitDate}
              onChange={(date) => handleChangeExitDate(date)}
              error={Boolean(formik.errors.exitDate)}
              errorText={formik.errors.exitDate}
              minDate={addDays(new Date(formik.values.incomeDate), 0)}
              disabled={formik.values.incomeDate === ''}
            />
          </InputComtrol>
        </GridItem>
      </Grid>
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
        <GridItem ml={'auto'}>
          <Text color={'green.500'} fontSize={'sm'}>
            ● El tiempo máximo de hospedaje es de 5 días.
          </Text>
        </GridItem>
      </Grid>
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          <Text fontWeight={700} color={'green.500'} fontSize={'xl'}>
            Zona de postulación
          </Text>
        </GridItem>
        <GridItem>
          <Checkbox
            css={{ borderColor: 'green' }}
            id={'isPartnerZone'}
            size="md"
            colorScheme="green"
            isChecked={formik.values.isPartnerZone}
            value={formik.values.isPartnerZone}
            onChange={handleChangeIsPartnerZone}
            py={4}
            fontWeight={700}
          >
            ¿Es la zona actual del socio?
          </Checkbox>
        </GridItem>
        <GridItem>
          <InputComtrol
            id={'zone'}
            label={'Zona'}
            error={Boolean(formik.errors.zone)}
            errorText={formik.errors.zone}
          >
            <Select
              bg={'white'}
              list={ZonesTwoList.getRegions()}
              value={formik.values.zone}
              onChange={hadleChangeZone}
            />
          </InputComtrol>
        </GridItem>
        <GridItem>
          {/* <Text fontSize={'xl'} opacity={0}>
            -----
          </Text> */}
          <InputComtrol
            id={'prefecture'}
            label={'Alta repartición / Repartición'}
            error={Boolean(formik.errors.prefecture)}
            errorText={formik.errors.prefecture}
          >
            <Select
              bg={'white'}
              list={ZonesTwoList.getCommunes(formik.values.zone)}
              value={formik.values.prefecture}
              onChange={handleChangePrefecture}
            />
          </InputComtrol>
        </GridItem>
      </Grid>
      <Grid
        templateColumns={{
          lg: 'repeat(1, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          <InputComtrol
            id={'unit'}
            label={'Unidad'}
            value={formik.values.unit}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.unit)}
            errorText={formik.errors.unit}
          />
        </GridItem>
      </Grid>
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
        <GridItem ml={'auto'} mr={'auto'}>
          <Checkbox
            css={{ borderColor: 'green' }}
            id={'isPatientPartner'}
            size="md"
            colorScheme="green"
            isChecked={formik.values.isPatientPartner}
            value={formik.values.isPatientPartner}
            onChange={formik.handleChange}
            py={4}
            fontWeight={700}
          >
            ¿El huésped es el actual socio?
          </Checkbox>
        </GridItem>
      </Grid>
      {!formik.values.isPatientPartner && (
        <>
          <Grid
            templateColumns={{
              lg: 'repeat(2, 1fr)',
              md: 'repeat(1, 1fr)',
              sm: 'repeat(1, 1fr)'
            }}
            gap={2}
            mb={4}
          >
            <GridItem>
              <Text fontWeight={700} color={'green.500'} fontSize={'xl'}>
                Información del huésped a hospedar
              </Text>
              {formik.values.partnerId && (
                <InputComtrol label={'Seleccione un familiar ya registrado'}>
                  <SelectFamilyMember
                    partnerId={stepOneForm?.partnerId}
                    familyMemberId={formik.values.patientId}
                    setHasSpouse={setHasSpouse}
                    addNewFamilyMember={formik.values.toCreate}
                    onChange={handleChangeSelectFamilyMember}
                  />
                </InputComtrol>
              )}
            </GridItem>
          </Grid>
          {formik.values.patientId && (
            <Grid
              templateColumns={{
                lg: 'repeat(2, 1fr)',
                md: 'repeat(1, 1fr)',
                sm: 'repeat(1, 1fr)'
              }}
              gap={2}
            >
              <GridItem>
                <InputComtrol
                  id={'patientFullName'}
                  label={'Nombre completo del huésped'}
                  value={formik.values.patientFullName}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.patientFullName)}
                  errorText={formik.errors.patientFullName}
                />
                <InputComtrol id={'patientRelationship'} label={'Parentesco'}>
                  <Select
                    bg={'white'}
                    list={RelationshipList3}
                    value={formik.values.patientRelationship}
                    onChange={handleChangeParientRelationship}
                  />
                </InputComtrol>
              </GridItem>
              <GridItem>
                <InputComtrol id={'patientAge'} label={'Edad del huésped'}>
                  <Select
                    bg={'white'}
                    list={Age}
                    value={formik.values.patientAge}
                    onChange={handleChangeParientAge}
                  />
                </InputComtrol>
              </GridItem>
            </Grid>
          )}
        </>
      )}
    </>
  )
}

export default StepOne
