import React from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { useTypeModal } from '../../../../hooks/TypeModal'

const Question = ({ currentStep }) => {
  const { question } = useTypeModal(currentStep?.key)
  return (
    <Flex
      direction={'column'}
      align={'center'}
      justify={'center'}
      textAlign={'center'}
      gap={2}
    >
      <Text fontWeight={700} fontSize={'22px'} color={'green.500'}>
        {question}
      </Text>
    </Flex>
  )
}

export default Question
