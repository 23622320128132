import React from 'react'
import { Grid, GridItem } from '@chakra-ui/react'
import CardData from './CardData'

const TotalAmountDashboard = ({ data }) => (
  <Grid
    templateColumns={{
      lg: 'repeat(4, minmax(0, 1fr))',
      md: 'repeat(1, minmax(0, 1fr))',
      sm: 'repeat(1, minmax(0, 1fr))'
    }}
    gap={2}
  >
    <GridItem>
      <CardData
        title={'Socios activos'}
        count={data?.partnersActive}
        typeCard={'count'}
        mb={2}
      />
      <CardData
        title={'Solicitudes por aprobar'}
        count={data?.programToApprove?.totalRecords}
        typeCard={'count'}
      />
    </GridItem>
    <GridItem>
      <CardData
        title={'Asistencia'}
        totalRecords={data?.totalApprovedAttendence}
        amount={data?.totalAmountAttendence}
        colorCard={'#FFDDC7'}
        typeCard={'amount'}
      />
    </GridItem>
    <GridItem>
      <CardData
        title={'Salud'}
        totalRecords={data?.totalApprovedHealth}
        amount={data?.totalAmountHealth}
        colorCard={'#CEF6FF'}
        typeCard={'amount'}
      />
    </GridItem>
    <GridItem>
      <CardData
        title={'Educación'}
        totalRecords={data?.totalApprovedEducation}
        amount={data?.totalAmountEducation}
        colorCard={'#FDF1C5'}
        typeCard={'amount'}
      />
    </GridItem>
  </Grid>
)

export default TotalAmountDashboard
