const scholarshipList = [
  '1ro basico',
  '2do basico',
  '3ro basico',
  '4to basico',
  '5to basico',
  '6to basico',
  '7mo basico',
  '8vo basico',
  '1ro medio',
  '2do medio',
  '3ro medio',
  '4to medio',
  'Tecnico profesional',
  'Tecnico universitario',
  'Universitario',
  'Otros'
]

export default scholarshipList
