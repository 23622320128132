import React from 'react'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Icon,
  Text
} from '@chakra-ui/react'
import { SquareButton, Select2 } from '../UI'
import { ReplayIcon } from '../Icons'
import { ProgramsList } from '../../Utils'

const FiltersProgramsSync = ({
  filtersProgramsSync,
  setFiltersProgramsSync,
  initialFiltersProgramsSync
}) => {
  const filteredProgramsList = () => {
    const newProgramsList = [
      { key: 'Todos los programas', name: 'Todos los programas' },
      ...ProgramsList
    ]
    return newProgramsList?.filter(
      (item) => item?.key !== 'WorkshopsAndTraining'
    )
  }

  const handleChangeProgram = (program) => {
    setFiltersProgramsSync({
      ...filtersProgramsSync,
      program
    })
  }

  const resetFilters = () => {
    setFiltersProgramsSync(initialFiltersProgramsSync)
  }

  return (
    <Flex gap={4} direction={'column'}>
      <Accordion allowMultiple>
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              <Text whiteSpace={'nowrap'} fontWeight={700} color={'green.500'}>
                Filtros:
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <Flex gap={3} direction={'column'}>
              <Flex
                gap={1}
                align={'center'}
                justify={'space-between'}
                direction={{
                  lg: 'row',
                  md: 'column',
                  sm: 'column'
                }}
              >
                <Flex>
                  <Box
                    w={{
                      lg: '100%',
                      md: '100%',
                      sm: '100%'
                    }}
                  >
                    <Text whiteSpace={'nowrap'} fontWeight={700}>
                      Programa:
                    </Text>
                    <Select2
                      list={filteredProgramsList()}
                      onChange={handleChangeProgram}
                      value={filtersProgramsSync?.program}
                    />
                  </Box>
                </Flex>
                <Box
                  marginRight={{
                    lg: 0,
                    md: 'auto',
                    sm: 'auto'
                  }}
                >
                  <SquareButton onClick={resetFilters}>
                    <Icon as={ReplayIcon} />
                  </SquareButton>
                </Box>
              </Flex>
            </Flex>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Flex>
  )
}

export default FiltersProgramsSync
