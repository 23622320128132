import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { Flex, Text, Checkbox, Tooltip } from '@chakra-ui/react'
import {
  InputComtrol,
  SquareButton,
  Select,
  UploadFile,
  BarFileName,
  Button
} from '../../../UI'
import { steepThreeSchema } from '../Schemas'
import {
  Age,
  ClearFormatMoney,
  Files,
  FormatMoney,
  RelationshipList3
} from '../../../../Utils'
import HealthForm from './HealthForm'

const FamilyMemberCard = ({
  _id,
  onClick,
  members,
  member,
  handleUpdateMember
}) => {
  const listFamilyBurden = ['SI', 'NO']

  const initialValues = {
    _id: '',
    memberName: '',
    memberAge: Age.at(0),
    memberRelationship: RelationshipList3.at(0),
    isFamilyBurden: listFamilyBurden.at(0),
    memberActivity: '',
    memberIncomes: '',
    memberSwornDeclarationName: [],
    memberSwornDeclaration: null,
    /* --- health data --- */
    haveMedicalHistory: false,
    doctorName: '',
    hospitalName: '',
    monthlyExpenditure: '',
    diagnostic: '',
    evolutionTime: '',
    observation: '',
    state: 'READY'
  }

  const formik = useFormik({
    initialValues,
    validateOnMount: false,
    validateOnChange: false,
    validationSchema: steepThreeSchema,
    onSubmit: (values) => {
      handleUpdateMember(_id, values)
    }
  })

  const handleChangeFamilyBurden = (value) => {
    formik.setFieldValue('isFamilyBurden', value)
  }

  const handleChangeAge = (value) => {
    formik.setFieldValue('memberAge', value.toString())
  }

  const handleChangeRelationship = (value) => {
    formik.setFieldValue('memberRelationship', value)
  }

  const formatIncomes = () => {
    const format = FormatMoney(ClearFormatMoney(formik.values.memberIncomes))
    formik.setFieldValue('memberIncomes', format)
  }

  const deleteFile = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.memberSwornDeclarationName,
      formik.values.memberSwornDeclaration
    )

    formik.setValues({
      ...formik.values,
      memberSwornDeclarationName: resultNames,
      memberSwornDeclaration: resultListFiles
    })
  }

  const handleChangeFile = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.memberSwornDeclarationName,
      formik.values.memberSwornDeclaration
    )

    formik.setValues({
      ...formik.values,
      memberSwornDeclarationName: newListNames,
      memberSwornDeclaration: newListFiles
    })
    e.target.value = null
  }

  const isValidMemberItem = () => {
    const isValid = Boolean(members.find((item) => item._id === _id).updated)
    const isEqualsMemberName = Boolean(
      members.find((item) => item._id === _id).memberName ===
        formik.values.memberName
    )
    const isEqualsMemberAge = Boolean(
      members.find((item) => item._id === _id).memberAge ===
        formik.values.memberAge
    )
    const isEqualsMemberRelationship = Boolean(
      members.find((item) => item._id === _id).memberRelationship ===
        formik.values.memberRelationship
    )
    const isEqualsIsFamilyBurden = Boolean(
      members.find((item) => item._id === _id).isFamilyBurden ===
        formik.values.isFamilyBurden
    )
    const isEqualsMemberActivity = Boolean(
      members.find((item) => item._id === _id).memberActivity ===
        formik.values.memberActivity
    )
    const isEqualsMemberIncomes = Boolean(
      members.find((item) => item._id === _id).memberIncomes ===
        formik.values.memberIncomes
    )
    const isEqualsMemberSwornDeclarationName = Boolean(
      members.find((item) => item._id === _id).memberSwornDeclarationName
        .length === formik.values.memberSwornDeclarationName.length
    )
    /* --- health data --- */
    const isEqualsHaveMedicalHistory = Boolean(
      members.find((item) => item._id === _id).haveMedicalHistory ===
        formik.values.haveMedicalHistory
    )
    const isEqualsDoctorName = Boolean(
      members.find((item) => item._id === _id).doctorName ===
        formik.values.doctorName
    )
    const isEqualsHospitalName = Boolean(
      members.find((item) => item._id === _id).hospitalName ===
        formik.values.hospitalName
    )
    const isEqualsMonthlyExpenditure = Boolean(
      members.find((item) => item._id === _id).monthlyExpenditure ===
        formik.values.monthlyExpenditure
    )
    const isEqualsDiagnostic = Boolean(
      members.find((item) => item._id === _id).diagnostic ===
        formik.values.diagnostic
    )
    const isEqualsEvolutionTime = Boolean(
      members.find((item) => item._id === _id).evolutionTime ===
        formik.values.evolutionTime
    )
    const isEqualsObservation = Boolean(
      members.find((item) => item._id === _id).observation ===
        formik.values.observation
    )

    if (
      isValid &&
      isEqualsMemberName &&
      isEqualsMemberAge &&
      isEqualsMemberRelationship &&
      isEqualsIsFamilyBurden &&
      isEqualsMemberActivity &&
      isEqualsMemberIncomes &&
      isEqualsMemberSwornDeclarationName &&
      isEqualsHaveMedicalHistory &&
      isEqualsDoctorName &&
      isEqualsHospitalName &&
      isEqualsMonthlyExpenditure &&
      isEqualsDiagnostic &&
      isEqualsEvolutionTime &&
      isEqualsObservation
    ) {
      return true
    }
    return false
  }

  useEffect(() => {
    const setErrors = Object.keys(formik.errors).length
    if (setErrors > 0 && !formik.isSubmitting) {
      handleUpdateMember(_id, {}, false)
    }
  }, [formik.isSubmitting])

  useEffect(() => {
    if (member) {
      formik.setValues({
        _id: member?._id || Date.now(),
        memberName: member?.memberName || '',
        memberAge: member?.memberAge || Age.at(0),
        memberRelationship:
          member?.memberRelationship || RelationshipList3.at(0),
        isFamilyBurden: member?.isFamilyBurden || listFamilyBurden?.at(0),
        memberActivity: member?.memberActivity || '',
        memberIncomes: member?.memberIncomes || '',
        memberSwornDeclarationName: member?.memberSwornDeclarationName || [],
        memberSwornDeclaration: member?.memberSwornDeclaration || null,
        /* --- health data --- */
        haveMedicalHistory: member?.haveMedicalHistory || false,
        doctorName: member?.doctorName || '',
        hospitalName: member?.hospitalName || '',
        monthlyExpenditure: member?.monthlyExpenditure || '',
        diagnostic: member?.diagnostic || '',
        evolutionTime: member?.evolutionTime || '',
        observation: member?.observation || '',
        state: member?.state || 'READY'
      })
    }
  }, [member])

  return (
    <Flex
      direction={'column'}
      border={'solid 1px #2E7423'}
      bg={isValidMemberItem() ? '#F0FFF4' : '#FFFFF0'}
      borderRadius={18}
      p={6}
      my={1}
      gap={1}
    >
      <Flex justify={'flex-end'}>
        <SquareButton onClick={() => onClick(_id)} size={'xs'}>
          x
        </SquareButton>
      </Flex>
      <Flex
        gap={1}
        direction={{
          lg: 'row',
          md: 'row',
          sm: 'column'
        }}
      >
        <InputComtrol
          id={'memberName'}
          label={'Nombre Completo'}
          value={formik.values.memberName}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.memberName)}
          errorText={formik.errors.memberName}
        />
        <InputComtrol id={'memberAge'} label={'Edad'}>
          <Select
            bg={'white'}
            list={Age}
            value={formik.values.memberAge}
            onChange={handleChangeAge}
          />
        </InputComtrol>

        <InputComtrol id={'memberRelationship'} label={'Parentesco'}>
          <Select
            bg={'white'}
            list={RelationshipList3}
            value={formik.values.memberRelationship}
            onChange={handleChangeRelationship}
          />
        </InputComtrol>
      </Flex>
      <Flex
        gap={1}
        direction={{
          lg: 'row',
          md: 'row',
          sm: 'column'
        }}
      >
        <InputComtrol id={'isFamilyBurden'} label={'Carga Familiar'}>
          <Select
            bg={'white'}
            list={listFamilyBurden}
            value={formik.values.isFamilyBurden}
            onChange={handleChangeFamilyBurden}
          />
        </InputComtrol>
        <InputComtrol
          id={'memberActivity'}
          label={'Actividad'}
          value={formik.values.memberActivity}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.memberActivity)}
          errorText={formik.errors.memberActivity}
        />
        <InputComtrol
          id={'memberIncomes'}
          label={'Ingresos'}
          placeholder={'$'}
          type={'currency'}
          isRequired={false}
          value={formik.values.memberIncomes}
          onChange={formik.handleChange}
          onBlur={formatIncomes}
          error={Boolean(formik.errors.memberIncomes)}
          errorText={formik.errors.memberIncomes}
        />
      </Flex>
      {/* <Text fontWeight={700} color={'green.500'} fontSize={'md'}>
        Declaración jurada simple
      </Text>
      <Text color={'green.100'} fontSize={'sm'}>
        Se solicita una declaración jurada simple por parte de los integrantes
        del grupo familiar, en donde se detallen sus ingresos percibidos de
        forma mensual. (Solo es requerido cuando presenta ingresos)
      </Text>
      <UploadFile
        id={`memberSwornDeclaration${_id}`}
        onChange={(e) => {
          handleChangeFile(e)
        }}
        error={Boolean(formik.errors.memberSwornDeclarationName)}
        errorText={formik.errors.memberSwornDeclarationName}
        direction={'row'}
        sizeIcon={30}
        p={5}
      /> */}
      <Flex direction={'column'} gap={1}>
        {formik.values.memberSwornDeclarationName.map((item) => (
          <BarFileName
            key={item?.name}
            fileName={item?.name}
            fileUrl={item?.url}
            onDelete={() => deleteFile(item?.name)}
          />
        ))}
      </Flex>
      {/* --- health data --- */}
      <Checkbox
        css={{ borderColor: 'green' }}
        id={'haveMedicalHistory'}
        size="md"
        colorScheme="green"
        isChecked={formik.values.haveMedicalHistory}
        value={formik.values.haveMedicalHistory}
        onChange={formik.handleChange}
        py={4}
      >
        ¿Este miembro posee algún antecedente médico de relevancia?
      </Checkbox>
      {formik.values.haveMedicalHistory && <HealthForm formik={formik} />}
      <Flex justify={'flex-end'}>
        <Tooltip
          label="Favor Validar! ->"
          placement="left"
          bg={'#d68c49'}
          isOpen={!isValidMemberItem()}
        >
          <Flex>
            <Button
              text={isValidMemberItem() ? 'Validado' : 'No validado'}
              py={1}
              px={2}
              onClick={formik.handleSubmit}
              size={'xs'}
            />
          </Flex>
        </Tooltip>
      </Flex>
    </Flex>
  )
}

export default FamilyMemberCard
