import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { Box, Flex, Icon } from '@chakra-ui/react'
import { useReportability } from '../../../hooks/Reportability'
import { ZonesTwoList, ProgramsList, GradesCarabineros } from '../../../Utils'
import { Button, InputComtrol, Select, Select2 } from '../../UI'
import { SearchIcon } from '../../Icons'

const Filters = ({ setFilters, isLoadingData }) => {
  const {
    data: arrayYears,
    isLoading,
    isFetching
  } = useReportability('YEARS-PROGRAMS', [])

  const initialValues = {
    grade: ['TODOS', ...GradesCarabineros].at(0),
    year: new Date().getFullYear(),
    zone: ['TODOS', ...ZonesTwoList.getRegions()].at(0),
    type: [{ key: 'TODOS', name: 'TODOS' }, ...ProgramsList].at(0).key
  }

  const formik = useFormik({
    initialValues,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: (values) => {
      setFilters(values)
    }
  })

  const handleChangeSelect = (value, type) => {
    if (type === 'ZONE') {
      formik.setValues({
        ...formik.values,
        zone: value
      })
    }
    if (type === 'YEAR') {
      formik.setValues({
        ...formik.values,
        year: value
      })
    }
    if (type === 'GRADE') {
      formik.setValues({
        ...formik.values,
        grade: value
      })
    }
    if (type === 'TYPE') {
      formik.setValues({
        ...formik.values,
        type: value
      })
    }
  }

  useEffect(() => {
    if (arrayYears?.length > 0) {
      formik.setValues({
        ...formik.values,
        year: Math?.max(...arrayYears) || new Date().getFullYear()
      })
    }
  }, [arrayYears])

  return (
    <>
      {!isLoading && !isFetching && (
        <Flex
          direction={{
            lg: 'row',
            md: 'column',
            sm: 'column'
          }}
          justify={'center'}
          align={{
            lg: 'center',
            md: 'flex-end',
            sm: 'flex-end'
          }}
          gap={2}
        >
          <InputComtrol id={'type'} label={'Beneficio'} isRequired={false}>
            <Select2
              list={[{ key: 'TODOS', name: 'TODOS' }, ...ProgramsList]}
              value={formik.values.type}
              bg={'white'}
              onChange={(value) => handleChangeSelect(value, 'TYPE')}
            />
          </InputComtrol>
          <InputComtrol id={'grade'} label={'Grado'} isRequired={false}>
            <Select
              list={['TODOS', ...GradesCarabineros]}
              value={formik.values.grade}
              bg={'white'}
              onChange={(value) => handleChangeSelect(value, 'GRADE')}
            />
          </InputComtrol>
          <InputComtrol id={'zone'} label={'Zona'} isRequired={false}>
            <Select
              list={['TODOS', ...ZonesTwoList.getRegions()]}
              value={formik.values.zone}
              bg={'white'}
              onChange={(value) => handleChangeSelect(value, 'ZONE')}
            />
          </InputComtrol>
          <InputComtrol id={'year'} label={'Año'} isRequired={false}>
            <Select
              list={arrayYears}
              value={formik.values.year}
              bg={'white'}
              onChange={(value) => handleChangeSelect(value, 'YEAR')}
            />
          </InputComtrol>
          <Box>
            <Button
              text={'Buscar'}
              rightIcon={<Icon as={SearchIcon} color={'white'} />}
              disabled={isLoading || isFetching || arrayYears?.length === 0}
              isLoading={isLoadingData}
              onClick={formik.handleSubmit}
            />
          </Box>
        </Flex>
      )}
    </>
  )
}

export default Filters
