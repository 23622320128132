import React from 'react'
import { Grid, GridItem, Text } from '@chakra-ui/react'
import { CardFilePreview, InputComtrol } from '../../UI'
import { FormatDate } from '../../../Utils'

const MonitorInformation = ({ program }) => (
  <>
    <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
      <GridItem>
        <InputComtrol
          isRequired={false}
          label={'Nombres y apellidos'}
          value={program?.monitorData?.fullName}
          readOnly={true}
        />
      </GridItem>
    </Grid>
    {/* --- */}
    <Grid
      templateColumns={{
        lg: 'repeat(2, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem>
        <InputComtrol
          isRequired={false}
          label={'RUT'}
          value={program?.monitorData?.rut}
          readOnly={true}
        />
      </GridItem>
      <GridItem>
        <InputComtrol
          isRequired={false}
          label={'Fecha de nacimiento'}
          value={FormatDate(program?.monitorData?.birthDate)}
          readOnly={true}
        />
      </GridItem>
    </Grid>
    <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
      <GridItem>
        <InputComtrol
          isRequired={false}
          label={'Dirección'}
          value={program?.monitorData?.address}
          readOnly={true}
        />
      </GridItem>
    </Grid>
    {/* --- */}
    <Grid
      templateColumns={{
        lg: 'repeat(2, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem>
        <InputComtrol
          isRequired={false}
          label={'Teléfono principal'}
          addictionType={'phone'}
          value={program?.monitorData?.phone}
          readOnly={true}
        />
      </GridItem>
      <GridItem>
        <InputComtrol
          isRequired={false}
          label={'Teléfono secundario'}
          addictionType={'phone'}
          value={program?.monitorData?.alternativePhone || 'No Disponible'}
          readOnly={true}
        />
      </GridItem>
    </Grid>
    {/* --- */}
    <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
      <GridItem>
        <InputComtrol
          isRequired={false}
          label={'Correo electrónico'}
          type={'email'}
          value={program?.monitorData?.email}
          readOnly={true}
        />
      </GridItem>
      <GridItem>
        <Text fontWeight={700} fontSize={'xl'}>
          Ficha del monitor
        </Text>
      </GridItem>
      {program?.monitorData?.certificate?.map((item) => (
        <GridItem key={item.url} py={1}>
          <CardFilePreview name={item?.name} url={item.url} />
        </GridItem>
      ))}
    </Grid>
  </>
)

export default MonitorInformation
