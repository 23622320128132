import React from 'react'
import { useHistory } from 'react-router-dom'
import ButtonHistory from '../ButtonHistory'
import SendWsp from './SendWsp'
import FinalRemark from './FinalRemark'
import FinalStatus from './FinalStatus'
import OtherProcesses from './OtherProcesses'
import ContactPartner from './ContactPartner'
import MultiProgramProcesses from './MultiProgramProcesses'
import SearchPartnersAndSpouse from './SearchPartnersAndSpouse'
import ExtensionOrExit from './ExtensionOrExit'
import RegisterNotification from './RegisterNotification'

const ActionStep = ({ currentStep, getColor, program }) => {
  const history = useHistory()

  const redirectProgramTypeDictionary = {
    HospitalAccommodation: () =>
      history.push(`/programs/edit/${program?._id}/${program?.type}`),
    SolidarityHealthFund: () =>
      history.push(`/programs/edit/${program?._id}/${program?.type}`),
    InjuredInLinesOfServiceKit: () =>
      history.push(`/programs/edit/${program?._id}/${program?.type}`),
    FireSupport: () =>
      history.push(`/programs/edit/${program?._id}/${program?.type}`),
    DisasterSupport: () =>
      history.push(`/programs/edit/${program?._id}/${program?.type}`),
    SupportInCaseOfDeathOfMember: () =>
      history.push(`/programs/edit/${program?._id}/${program?.type}`),
    NewbornBenefit: () =>
      history.push(`/programs/edit/${program?._id}/${program?.type}`),
    PreUniversityScholarships: () =>
      history.push(`/programs/edit/${program?._id}/${program?.type}`),
    TechnicalProfessionalScholarships: () =>
      history.push(`/programs/edit/${program?._id}/${program?.type}`),
    ScholarshipsApplicantOfficer: () =>
      history.push(`/programs/edit/${program?._id}/${program?.type}`),
    ScholarshipsApplicantOfficerDac: () =>
      history.push(`/programs/edit/${program?._id}/${program?.type}`),
    UniversityScholarships: () =>
      history.push(`/programs/edit/${program?._id}/${program?.type}`),
    WorkshopsAndTraining: () =>
      history.push(`/programs/edit-workshops-and-training/${program?._id}`)
  }

  const discAction = {
    'NOTIFY-REGISTRATION': (
      <SendWsp
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'CONTINUE-PROCESS': (
      <ButtonHistory
        disable={currentStep?.complete}
        colors={getColor}
        onClick={redirectProgramTypeDictionary[program?.type]}
      />
    ),
    'EVALUATION-CONTROL-SOCIAL-MANAGEMENT': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'STOCK-RECIEVED-NEWBORN-BENEFIT': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'EVALUATION-CONTROL-SOCIAL-MANAGEMENT-NEWBORN-BENEFIT': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'EVALUATION-CONTROL-SOCIAL-MANAGEMENT-DEATH-OF-MEMBER': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'NOTIFY-NEW-STATUS': (
      <SendWsp
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'GIFTCARD-DENOMINATION-REQUEST-NEWBORN-BENEFIT': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'RE-UPLOAD-DENOMINATION-REQUEST-NEWBORN-BENEFIT': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'GIFTCARD-DELIVERY-NEWBORN-BENEFIT': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'GIFTCARD-RECEPTION-NEWBORN-BENEFIT': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'GIFTCARD-ACTIVATION-NEWBORN-BENEFIT': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'REQUEST-PAYMENT-ONE-DEATH-OF-MEMBER': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'VERIFICATION-REQUEST-PAYMENT-ONE-DEATH-OF-MEMBER': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'AUTHORIZE-REQUEST-PAYMENT-ONE-DEATH-OF-MEMBER': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'AUTHORIZE-REQUEST-PAYMENT-TWO-DEATH-OF-MEMBER': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'REQUEST-PAYMENT-ONE-EDIT-DEATH-OF-MEMBER': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'PAYMENT-ONE-MADE-DEATH-OF-MEMBER': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'REQUEST-PAYMENT-TWO-DEATH-OF-MEMBER': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'REQUEST-PAYMENT-TWO-EDIT-DEATH-OF-MEMBER': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'VERIFICATION-REQUEST-PAYMENT-TWO-DEATH-OF-MEMBER': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'PAYMENT-TWO-MADE-DEATH-OF-MEMBER': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'END-PROCESS': (
      <FinalRemark
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'COMMISSION-PREPARATION': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'COMMISSION-PREPARATION-APPLICANT-OFFICER': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'COMMISSION-PREPARATION-APPLICANT-OFFICER-DAC': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'COMMISSION-PREPARATION-PRE-UNIVERSITY': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'COMMISSION-PREPARATION-TECHNICAL-PROFESSIONAL': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'COMMISSION-PREPARATION-TECHNICAL-PROFESSIONAL-NEWS-SEMESTERS': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'EVALUATION-COMMISSION-SOLIDARITY-HEALTH-FUND': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'EVALUATION-COMMISSION-UNIVERSITY': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'EVALUATION-COMMISSION-APPLICANT-OFFICER': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'EVALUATION-COMMISSION-APPLICANT-OFFICER-DAC': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'EVALUATION-COMMISSION-TECHNICAL-PROFESSIONAL': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'EVALUATION-COMMISSION-PRE-UNIVERSITY': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'EVALUATION-COMMISSION-NEW-SEMESTERS': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'NOTIFY-NEW-STATUS-PHONE': (
      <SendWsp
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'NOTIFY-REJECTED-STATUS-PHONE-SOLIDARITY-HEALTH-FUND': (
      <SendWsp
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'INFORM-PRE-UNIVERSITY-AND-REQUEST-PAYMENT-PRE-UNIVERSITY': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'SOCIAL-ANALYSIS-UNIVERSITY': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'PURCHASE-ORDER-REQUEST': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'PURCHASE-ORDER-REQUEST-PRE-UNIVERSITY': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'PURCHASE-ORDER-REQUEST-EDIT': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'PURCHASE-ORDER-REQUEST-EDIT-PRE-UNIVERSITY': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'VERIFICATION-PURCHASE-ORDER-REQUEST': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'VERIFICATION-PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'VERIFICATION-PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER-DAC': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'VERIFICATION-PURCHASE-ORDER-REQUEST-TECHNICAL-PROFESSIONAL': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'VERIFICATION-PURCHASE-ORDER-REQUEST-NEW-SEMESTERS': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'VERIFICATION-PURCHASE-ORDER-REQUEST-PRE-UNIVERSITY': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'PURCHASE-ORDER-AUTORIZATION': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'PURCHASE-ORDER-AUTORIZATION-PRE-UNIVERSITY': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'CORRECT-REQUEST': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'CORRECT-REQUEST-PRE-UNIVERSITY': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'GENERATE-PURCHASE-ORDER-PRE-UNIVERSITY': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'GENERATE-PURCHASE-ORDER-PRE-UNIVERSITY-EDIT': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'SIGN-PURCHASE-ORDER-PRE-UNIVERSITY': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'PAYMENT-MADE-PRE-UNIVERSITY': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'REPORT-PAYMENT-REQUEST-INVOICE-PRE-UNIVERSITY': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'ATTACH-INVOICE-BY-PRE-UNIVERSITY': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'OFFICE-FOR-FINANCES-PRE-UNIVERSITY': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'VERIFICATION-OFFICE-FOR-FINANCES-PRE-UNIVERSITY': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'OFFICE-FOR-FINANCES-PRE-UNIVERSITY-EDIT': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'ADD-PROPOSAL-DISASTER-SUPPORT': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'ADD-PROPOSAL-FIRE-SUPPORT': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'LEGAL-REPORT-DISASTER-SUPPORT': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'LEGAL-REPORT-FIRE-SUPPORT': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'MANAGEMENT-DETERMINATION-DISASTER-SUPPORT': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'MANAGEMENT-DETERMINATION-FIRE-SUPPORT': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'PRESIDENSY-DETERMINATION-DISASTER-SUPPORT': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'PRESIDENSY-DETERMINATION-FIRE-SUPPORT': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'INFORM-SERVICE-SOCIAL-DETERMINATION': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'OFFICE-WITH-RESOLUTION': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'AID-APPROVAL-DISASTER-SUPPORT': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'AID-APPROVAL-FIRE-SUPPORT': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'VALIDATE-AID-APPROVAL-DISASTER-SUPPORT': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'VALIDATE-AID-APPROVAL-FIRE-SUPPORT': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'AID-APPROVAL-EDIT-DISASTER-SUPPORT': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'AID-APPROVAL-EDIT-FIRE-SUPPORT': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'AID-DEPOSIT-DISASTER-SUPPORT': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'AID-DEPOSIT-FIRE-SUPPORT': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'NOTIFY-TO-SOCIAL-SERVICE-DISASTER-SUPPORT': (
      <SendWsp
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'NOTIFY-TO-SOCIAL-SERVICE-FIRE-SUPPORT': (
      <SendWsp
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'NOTIFICATION-ACT-SIGNED-BY-PARTNER-AND-SCHOLARSHIP-RECIPIENT': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'NOTIFICATION-ACT-SIGNED-BY-PARTNER-AND-SCHOLARSHIP-RECIPIENT-TECHNICAL-PROFESSIONAL':
      (
        <OtherProcesses
          currentStep={currentStep}
          getColor={getColor}
          program={program}
        />
      ),
    'NOTIFICATION-ACT-SIGNED-BY-PARTNER-AND-SCHOLARSHIP-RECIPIENT-UNIVERSITY': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'THE-UNIVERSITY-WAS-INFORMED-UNIVERSITY': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'PURCHASE-ORDER-REQUEST-UNIVERSITY': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'VERIFICATION-PURCHASE-ORDER-REQUEST-UNIVERSITY': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'PURCHASE-ORDER-REQUEST-UNIVERSITY-EDIT-UNIVERSITY': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'PURCHASE-ORDER-AUTORIZATION-UNIVERSITY': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'GENERATE-PURCHASE-ORDER-UNIVERSITY': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'GENERATE-PURCHASE-ORDER-UNIVERSITY-EDIT-UNIVERSITY': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'SIGN-PURCHASE-ORDER-UNIVERSITY': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'CORRECT-REQUEST-UNIVERSITY': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'PAYMENT-MADE-UNIVERSITY': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'INFORM-THE-UNIVERSITY-PAYMENT-MADE-UNIVERSITY': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'DELIVERY-OFFICE-UNIVERSITY': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'VERIFICATION-DELIVERY-OFFICE-UNIVERSITY': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'DELIVERY-OFFICE-UNIVERSITY-EDIT-UNIVERSITY': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'AMOUNT-TO-CANCEL-TECHNICAL-PROFESSIONAL': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'AMOUNT-TO-CANCEL-NEW-SEMESTERS': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'REVIEW-OF-SUBMITTED-INFORMATION': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),

    'OFFICE-AND-INVOICE-TECHNICAL-PROFESSIONAL': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'OFFICE-AND-INVOICE-NEW-SEMESTERS': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'OFFICE-AND-INVOICE-EDIT-TECHNICAL-PROFESSIONAL': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'OFFICE-AND-INVOICE-NEW-SEMESTERS-EDIT': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'PURCHASE-ORDER-AUTORIZATION-TECHNICAL-PROFESSIONAL': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'PURCHASE-ORDER-AUTORIZATION-TECHNICAL-PROFESSIONAL-NEW-SEMESTERS': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'CORRECT-REQUEST-TECHNICAL-PROFESSIONAL': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'CORRECT-REQUEST-TECHNICAL-PROFESSIONAL-NEW-SEMESTERS': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'PAYMENT-MADE-TECHNICAL-PROFESSIONAL': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'PAYMENT-MADE-TECHNICAL-PROFESSIONAL-NEW-SEMESTERS': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'INFORM-THE-INSTITUTE-PAYMENT-MADE-TECHNICAL-PROFESSIONAL': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'INFORM-THE-INSTITUTE-PAYMENT-MADE-NEW-SEMESTERS': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'REVIEW-DOCUMENTS-FOR-COMMISSION': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'REVIEW-DOCUMENTS-FOR-COMMISSION-APPLICANT-OFFICER': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'PURCHASE-ORDER-AUTORIZATION-APPLICANT-OFFICER': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'PURCHASE-ORDER-GENERATE-APPLICANT-OFFICER': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'SING-PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'PAYMENT-MADE-APPLICANT-OFFICER': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'RECEIPT-PAYMENT-CERTIFICATE-APPLICANT-OFFICER': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'SING-RECEIPT-PAYMENT-CERTIFICATE-APPLICANT-OFFICER': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'OFFICE-RECEIVED-BY-FINANCE-APPLICANT-OFFICER': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'CORRECT-REQUEST-APPLICANT-OFFICER': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'REVIEW-DOCUMENTS-FOR-COMMISSION-APPLICANT-OFFICER-DAC': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'PURCHASE-ORDER-AUTORIZATION-APPLICANT-OFFICER-DAC': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER-DAC': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'PURCHASE-ORDER-GENERATE-APPLICANT-OFFICER-DAC': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'SING-PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER-DAC': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'PAYMENT-MADE-APPLICANT-OFFICER-DAC': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'RECEIPT-PAYMENT-CERTIFICATE-APPLICANT-OFFICER-DAC': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'SING-RECEIPT-PAYMENT-CERTIFICATE-APPLICANT-OFFICER-DAC': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'OFFICE-RECEIVED-BY-FINANCE-APPLICANT-OFFICER-DAC': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'CORRECT-REQUEST-APPLICANT-OFFICER-DAC': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'MANAGEMENT-DETERMINATION-WORKSHOPS-AND-TRAINING': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'ADD-PARTNERS-AND-SPOUSES-WORKSHOPS-AND-TRAINING': (
      <SearchPartnersAndSpouse
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'REVISION-OF-INFORMATION-WORKSHOPS-AND-TRAINING': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'REQUEST-FOR-PAYMENT-TO-MONITORS-WORKSHOPS-AND-TRAINING': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'VERIFICATION-PAYMENT-MADE-WORKSHOPS-AND-TRAINING': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'PAYMENT-MADE-WORKSHOPS-AND-TRAINING': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'ADD-OFFICE-ORDER-INUJRED-IN-LINES-OF-SERVICE-KIT': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'REVIEW-DOCUMENTS-SOLIDARITY-HEALTH-FUND': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'NOTIFY-CONTACT-PARTNER-PHONE-SOLIDARITY-HEALTH-FUND': (
      <ContactPartner
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'CONTINUE-PROCESS-NOTIFY-CONTACT-PARTNER-PHONE-SOLIDARITY-HEALTH-FUND': (
      <ContactPartner
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'COMMISSION-PREPARATION-SOLIDARITY-HEALTH-FUND': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'VERIFICATION-PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'VERIFICATION-PURCHASE-ORDER-EDIT-REQUEST-SOLIDARITY-HEALTH-FUND': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND-EDIT-SOLIDARITY-HEALTH-FUND':
      (
        <OtherProcesses
          currentStep={currentStep}
          getColor={getColor}
          program={program}
        />
      ),
    'PURCHASE-ORDER-AUTORIZATION-SOLIDARITY-HEALTH-FUND': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'CORRECT-REQUEST-SOLIDARITY-HEALTH-FUND': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'GENERATE-PURCHASE-ORDER-SOLIDARITY-HEALTH-FUND': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'SIGN-PURCHASE-ORDER-SOLIDARITY-HEALTH-FUND': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'PAYMENT-MADE-SOLIDARITY-HEALTH-FUND': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'PAYMENT-MADE-MULTI-SOLIDARITY-HEALTH-FUND': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'INFORM-THE-PAYMENT-MADE-MULTI-SOLIDARITY-HEALTH-FUND': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'INFORM-THE-PAYMENT-MADE-MULTI-SOLIDARITY-HEALTH-FUND-EDIT-SOLIDARITY-HEALTH-FUND':
      (
        <MultiProgramProcesses
          currentStep={currentStep}
          getColor={getColor}
          program={program}
        />
      ),
    'VERIFICATION-DELIVERY-OFFICE-MULTI-SOLIDARITY-HEALTH-FUND': (
      <MultiProgramProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'INFORM-THE-PAYMENT-MADE-SOLIDARITY-HEALTH-FUND': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'DELIVERY-OFFICE-SOLIDARITY-HEALTH-FUND': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'VERIFICATION-DELIVERY-OFFICE-SOLIDARITY-HEALTH-FUND': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'DELIVERY-OFFICE-SOLIDARITY-HEALTH-FUND-EDIT-SOLIDARITY-HEALTH-FUND': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'DELIVERY-AND-RECEIPT-CERTIFICATE-SOLIDARITY-HEALTH-FUND': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'DELIVERY-AND-RECEIPT-CERTIFICATE-SIGN-SOLIDARITY-HEALTH-FUND': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'ALL-INVOICES-AND-TRADES-SOLIDARITY-HEALTH-FUND': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'ADD-DEBT-CERTIFICATE-SOLIDARITY-HEALTH-FUND': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'ADD-DEBT-CERTIFICATE-TWO-SOLIDARITY-HEALTH-FUND': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'ADD-DEBT-CERTIFICATE-TWO-EDIT-SOLIDARITY-HEALTH-FUND': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'VERIFICATION-PURCHASE-ORDER-REQUEST-TWO-SOLIDARITY-HEALTH-FUND': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'ADD-DOCUMENTATION-FOR-PAYMENTS-AND-OR-PURCHASES-SOLIDARITY-HEALTH-FUND': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'INFORM-THE-PAYMENT-MADE-TWO-SOLIDARITY-HEALTH-FUND': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'REVIEW-CONTRIBUTIONS-SOLIDARITY-HEALTH-FUND': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'ADD-STAY-CERTIFICATE-HOSPITAL-ACCOMMODATION': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'FIRST-EXTENSION-OR-OUTLET-HOSPITAL-ACCOMMODATION': (
      <ExtensionOrExit
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'ANY-EXTENSION-OR-OUTLET-HOSPITAL-ACCOMMODATION': (
      <ExtensionOrExit
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'DETERMINATION-EXTENSION-HOSPITAL-ACCOMMODATION': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'RESIGNATION-OR-LOSS-AUTHORIZATION-RESIGNATION': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'CORRECT-REQUEST-RESIGNATION': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'REGISTER-NOTIFICATION-DEATH-OF-MEMBER': (
      <RegisterNotification
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'REGISTER-NOTIFICATION-TWO-DEATH-OF-MEMBER': (
      <RegisterNotification
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'QUESTION-ADD-NEW-SEMESTERS-TECHNICAL-PROFESSIONAL': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'TRANSFERRED-AMOUNT-SCHOLARSHIPS-APPLICANT-OFFICER': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'TRANSFERRED-AMOUNT-SCHOLARSHIPS-APPLICANT-OFFICER-DAC': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'TRANSFERRED-AMOUNT-TECHNICAL-PROFESSIONAL': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'TRANSFERRED-AMOUNT-TECHNICAL-PROFESSIONAL-NEWS-SEMESTERS': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'TRANSFERRED-AMOUNT-PRE-UNIVERSITY': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'TRANSFERRED-AMOUNT-SOLIDARITY-HEALTH-FUND': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    'USED-AMOUNT-SOLIDARITY-HEALTH-FUND': (
      <OtherProcesses
        currentStep={currentStep}
        getColor={getColor}
        program={program}
      />
    ),
    FINISHED: <FinalStatus currentStep={currentStep} program={program} />
  }

  return <>{currentStep.key && discAction[currentStep.key]}</>
}

export default ActionStep
