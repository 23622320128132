import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Flex, useToast } from '@chakra-ui/react'
import { Step, Steps, useSteps } from 'chakra-ui-steps'
import { Button, CustomToast } from '../../UI'
import StepOne from './StepOne'
import StepTwo from './StepTwo'
import StepThree from './StepThree'
import { useMutateProgram } from '../../../hooks/Programs/NewbornBenefit'
import { CreateNewFile, GetFormData, GetNotification } from '../../../Utils'

const NewbornBenefit = ({ partner, program = null }) => {
  const toast = useToast()
  const refStepOne = useRef(null)
  const refStepTwo = useRef(null)
  const refStepThree = useRef(null)
  const { nextStep, prevStep, activeStep } = useSteps({
    initialStep: 0
  })

  const history = useHistory()
  const [stepOneForm, setStepOneForm] = useState({
    partnerPhone: partner?.phone,
    partnerRut: partner?.rut,
    partnerGrade: partner?.grade,
    partnerCode: partner?.code,
    partnerZone: partner?.zone,
    partnerPrefecture: partner?.prefecture,
    partnerEndowment: partner?.endowment
  })
  const [stepTwoForm, setStepTwoForm] = useState([])
  const [stepThreeForm, setStepThreeForm] = useState({})
  const [finalForm, setFinalForm] = useState({
    partnerId: partner?._id
  })
  const [state, setState] = useState(null)
  const { mutate, isLoading, reset } = useMutateProgram('CREATE')
  const {
    mutate: mutateUpdate,
    isLoading: isLoadingUpdate,
    reset: resetUpdate
  } = useMutateProgram('UPDATE')

  const steps = [
    {
      label: 1,
      content: (
        <StepOne
          stepOneForm={stepOneForm}
          setStepOneForm={setStepOneForm}
          refStepOne={refStepOne}
          next={nextStep}
        />
      )
    },
    {
      label: 2,
      content: (
        <StepTwo
          stepOneForm={stepOneForm}
          stepTwoForm={stepTwoForm}
          setStepTwoForm={setStepTwoForm}
          refStepTwo={refStepTwo}
          next={nextStep}
        />
      )
    },
    {
      label: 3,
      content: (
        <StepThree
          stepThreeForm={stepThreeForm}
          setStepThreeForm={setStepThreeForm}
          refStepThree={refStepThree}
          state={state}
        />
      )
    }
  ]

  const handleNext = () => {
    if (activeStep === 0) {
      refStepOne.current()
    }
    if (activeStep === 1) {
      refStepTwo.current()
    }
    if (activeStep === 2) {
      refStepThree.current()
    }
  }

  useEffect(() => {
    let jsonStepTwo = ''
    const beneficiariesHelped = []
    const arrayDocumentsNewBorn = []
    const arrayDocumentsNewBornBackup = []

    if (stepTwoForm?.length > 0) {
      stepTwoForm.forEach((item) => {
        const { birthCertificate, backupDocuments, _id } = item

        if (birthCertificate) {
          if (Object.keys(birthCertificate).length > 0) {
            Object.values(birthCertificate).forEach((doc) => {
              const result = CreateNewFile(doc, _id)
              arrayDocumentsNewBorn.push(result)
            })
          }
        }
        if (backupDocuments) {
          if (Object.keys(backupDocuments).length > 0) {
            Object.values(backupDocuments).forEach((doc) => {
              const resultBackup = CreateNewFile(doc, _id)
              arrayDocumentsNewBornBackup.push(resultBackup)
            })
          }
        }
      })

      const formaterStepTwo = stepTwoForm.map((item) => {
        // eslint-disable-next-line
        const { birthCertificate, backupDocuments, ...rest } = item
        return rest
      })
      jsonStepTwo = {
        newBornListData: JSON.stringify(formaterStepTwo)
      }

      if (formaterStepTwo.length > 0) {
        formaterStepTwo?.forEach((_) => {
          beneficiariesHelped.push('Hijo(a)')
        })
      }
    }

    setFinalForm({
      ...finalForm,
      ...stepOneForm,
      ...jsonStepTwo,
      arrayDocumentsNewBorn,
      arrayDocumentsNewBornBackup,
      ...stepThreeForm,
      beneficiariesHelped
    })
  }, [stepThreeForm])

  useEffect(() => {
    // si tiene mas valores aparte de los IDS de partner y usuario
    // y los el paso activo es el 1
    const test = true

    if (test) {
      if (Object.keys(finalForm).length > 2 && activeStep === 2) {
        if (!program) {
          const formData = GetFormData(
            finalForm,
            [
              'arrayDocumentsNewBorn',
              'arrayDocumentsNewBornBackup',
              'lastSalaryPayment',
              'tipcar'
            ],
            [
              'birthCertificateName',
              'lastSalaryPaymentName',
              'tipcarName',
              'beneficiariesHelped'
            ]
          )

          mutate(formData, {
            onSuccess: () => {
              reset()
              GetNotification.basic('Programa registrado con Éxito', 'Aceptar')
              history.push('/programs')
            },
            onError: (err) => {
              console.log(err)
              GetNotification.basic(
                'Error',
                'Aceptar',
                'Error al Registrar programa',
                'error'
              )
            }
          })
        }

        if (program) {
          const { _id: programId } = program
          const formData = GetFormData(
            finalForm,
            [
              'arrayDocumentsNewBorn',
              'arrayDocumentsNewBornBackup',
              'lastSalaryPayment',
              'tipcar'
            ],
            [
              'birthCertificateName',
              'lastSalaryPaymentName',
              'tipcarName',
              'beneficiariesHelped'
            ]
          )
          mutateUpdate(
            { programId, formData },
            {
              onSuccess: () => {
                resetUpdate()
                GetNotification.basic(
                  'Programa actualizado con Éxito',
                  'Aceptar'
                )
                history.push('/programs')
              },
              onError: (err) => {
                console.log(err)
                GetNotification.basic(
                  'Error',
                  'Aceptar',
                  'Error al actualizar programa',
                  'error'
                )
              }
            }
          )
        }
      }
    }
  }, [finalForm])

  useEffect(() => {
    if (program) {
      setStepOneForm({
        receptionDate: program?.receptionDate,
        isPartnerZone: program?.isPartnerZone,
        zone: program?.zone,
        prefecture: program?.prefecture,
        unit: program?.unit,
        relationship: program?.relationship,
        alternativePhone: program?.alternativePhone,
        /* partner field */
        partnerPhone: program?.partner.phone,
        partnerRut: program?.partner.rut,
        partnerGrade: program?.partner.grade,
        partnerCode: program?.partner.code,
        partnerZone: program?.partner.zone,
        partnerPrefecture: program?.partner.prefecture,
        partnerEndowment: program?.partner.endowment
      })
      const newStepTwoForm = program?.newbornList.map((item) => ({
        ...item,
        birthCertificateName: item.birthCertificate?.map((doc) => doc),
        birthCertificate: null,
        backupDocumentsName: item.backupDocuments
          ? item.backupDocuments?.map((doc) => doc)
          : [],
        BackupDocuments: null
      }))
      setStepTwoForm(newStepTwoForm)
      setStepThreeForm({
        lastSalaryPaymentName: program?.lastSalaryPayment?.map((item) => item),
        tipcarName: program?.tipcar?.map((item) => item)
      })
    }
  }, [program])

  useEffect(() => {
    if (isLoading || isLoadingUpdate) {
      toast({
        duration: 9000 * 9000,
        isClosable: true,
        position: 'bottom-right',
        render: ({ onClose }) => (
          <CustomToast
            text={'Espere por favor...'}
            status={'info'}
            onClose={onClose}
          />
        )
      })
      return
    }
    toast.closeAll()
  }, [isLoading, isLoadingUpdate])

  return (
    <Flex direction={'column'}>
      <Steps
        activeStep={activeStep}
        orientation="horizontal"
        responsive
        mb={{
          lg: 5,
          md: 0
        }}
      >
        {steps.map(({ label, content }) => (
          <Step key={label}>{content}</Step>
        ))}
      </Steps>
      <Flex justify={'flex-end'} mt={4}>
        <Button
          text={'Regresar'}
          bg={'green.50'}
          color={'green.500'}
          disabled={activeStep === 0 || isLoading || isLoadingUpdate}
          onClick={prevStep}
        />
        {activeStep === steps.length - 1 && (
          <Button
            text={'Guardar como incompleta'}
            bg={'green.50'}
            color={'green.500'}
            disabled={activeStep === 0 || isLoading || isLoadingUpdate}
            onClick={() => {
              setState('INCOMPLETE')
              handleNext()
            }}
            isLoading={isLoading || isLoadingUpdate}
          />
        )}
        <Button
          text={activeStep === steps.length - 1 ? 'Finalizar' : 'Siguiente'}
          onClick={() => {
            setState('INREVIEW')
            handleNext()
          }}
          isLoading={isLoading || isLoadingUpdate}
          disabled={isLoading || isLoadingUpdate}
        />
      </Flex>
    </Flex>
  )
}

export default NewbornBenefit
