import React from 'react'
import { useFormik } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, GridItem, Flex } from '@chakra-ui/react'
import {
  CustomModal,
  CustomDatePicker,
  InputComtrol,
  Select,
  TextAreaComtrol,
  UploadFile,
  Button,
  BarFileName
} from '../../../components/UI'
import { Files, GetFormData, GetNotification, ReasonList } from '../../../Utils'
import { schema } from './Schema'
import { useMutateHistoryProgram } from '../../../hooks/HistoryProgram'
import { closeResignationModal } from '../../../store/slices/modal'

const ResignationOrLossModal = ({ program }) => {
  const { resignationOrLossModalOpen } = useSelector((state) => state.modal)
  const dispatch = useDispatch()

  const { mutate, isLoading, reset } = useMutateHistoryProgram(
    'RESIGNATION-OR-LOSS-REQUEST'
  )
  const initialValues = {
    receptionDate: '',
    reason: ReasonList.at(0),
    descriptions: '',
    documentsNames: [],
    documents: null,
    program: program?._id,
    state: program?.state
  }

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    validateOnChange: false,
    onSubmit: (values, { resetForm }) => {
      const formData = GetFormData(values, ['documents'], ['documentsNames'])
      mutate(formData, {
        onSuccess: () => {
          reset()
          resetForm()
          dispatch(closeResignationModal())
          GetNotification.basic('Petición enviada con éxito', 'Aceptar')
        },
        onError: (err) => {
          console.log(err)
          GetNotification.basic(
            'Error',
            'Aceptar',
            'Error al enviar la petición',
            'error'
          )
        }
      })
    }
  })

  const onClose = () => {
    formik.resetForm()
    dispatch(closeResignationModal())
  }

  const handleChangeReceptionDate = (date) => {
    formik.setFieldValue('receptionDate', date)
  }

  const handleChangeReason = (value) => {
    formik.setFieldValue('reason', value)
    if (value === 'Desistió') {
      formik.setFieldValue('state', 'DESISTED')
    }
  }

  const deleteDocuments = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.documentsNames,
      formik.values.documents
    )

    formik.setValues({
      ...formik.values,
      documentsNames: resultNames,
      documents: resultListFiles
    })
  }

  const handleChangeDocuments = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.documentsNames,
      formik.values.documents
    )

    formik.setValues({
      ...formik.values,
      documentsNames: newListNames,
      documents: newListFiles
    })
    e.target.value = null
  }

  const handleConfirmation = () => {
    GetNotification.question(
      '¿Estás seguro de querer avanzar?',
      () => formik.handleSubmit(),
      onClose,
      'Al confirmar esta acción se pedirá una aprobación de gerencia para que se pueda finalizar el proceso. Esta acción no se puede deshacer.',
      'question'
    )
  }

  return (
    <CustomModal
      title={'Renuncia o perdida de beneficio'}
      isOpen={resignationOrLossModalOpen}
      onClose={onClose}
      showButtons={false}
      size={'2xl'}
    >
      <>
        <Grid
          templateColumns={{
            lg: 'repeat(2, 1fr)',
            md: 'repeat(1, 1fr)',
            sm: 'repeat(1, 1fr)'
          }}
          gap={2}
        >
          <GridItem>
            <InputComtrol
              id={'receptionDate'}
              label={'Fecha de recepción de renuncia'}
            >
              <CustomDatePicker
                placeholder={'DD/MM/AAAA'}
                currentDate={formik.values.receptionDate}
                onChange={(date) => handleChangeReceptionDate(date)}
                error={Boolean(formik.errors.receptionDate)}
                errorText={formik.errors.receptionDate}
              />
            </InputComtrol>
          </GridItem>
          <GridItem>
            <InputComtrol id={'reason'} label={'Renuncia o perdida'}>
              <Select
                bg={'white'}
                list={ReasonList}
                value={formik.values.reason}
                onChange={handleChangeReason}
              />
            </InputComtrol>
          </GridItem>
        </Grid>
        <Grid templateColumns={'repeat(1, 1fr)'}>
          <GridItem>
            <TextAreaComtrol
              id={'descriptions'}
              label={'Argumente el motivo'}
              value={formik.values.descriptions}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.descriptions)}
              errorText={formik.errors.descriptions}
            />
          </GridItem>
          <GridItem>
            <InputComtrol
              label={'Añade documentación de evidencia (opcional)'}
              isRequired={false}
            >
              <UploadFile
                id={'documentsNames'}
                direction={'row'}
                sizeIcon={30}
                p={5}
                onChange={(e) => handleChangeDocuments(e)}
                error={Boolean(formik.errors.documentsNames)}
                errorText={formik.errors.documentsNames}
              />
            </InputComtrol>
            <Flex direction={'column'} gap={1}>
              {formik.values.documentsNames.map((item) => (
                <BarFileName
                  key={item?.name}
                  fileName={item?.name}
                  fileUrl={item?.url}
                  onDelete={() => deleteDocuments(item?.name)}
                />
              ))}
            </Flex>
          </GridItem>
          <GridItem>
            <Flex justify={'flex-end'} align={'end'}>
              <Button
                text={'Cancelar'}
                bg={'beige.500'}
                bghover={'beige.600'}
                color={'green.500'}
                px={10}
                py={2}
                onClick={onClose}
                isLoading={isLoading}
                disabled={isLoading}
              />
              <Button
                text={'Continuar'}
                px={10}
                py={2}
                onClick={handleConfirmation}
                isLoading={isLoading}
                disabled={isLoading}
              />
            </Flex>
          </GridItem>
        </Grid>
      </>
    </CustomModal>
  )
}

export default ResignationOrLossModal
