import React from 'react'
import { Flex } from '@chakra-ui/react'
import { ButtonDotted } from '../../../UI'
import DescriptionItem from './DescriptionItem'

const RenderArrayDescriptions = ({ formik }) => {
  const newDescription = () => {
    formik.setValues({
      ...formik.values,
      moreDescriptions: [
        ...formik.values.moreDescriptions,
        {
          id: Math.random().toString(),
          title: '',
          description: ''
        }
      ]
    })
  }

  return (
    <>
      {formik.values.moreDescriptions.map((desc) => (
        <DescriptionItem description={desc} formik={formik} />
      ))}
      <Flex justify={'flex-end'}>
        <ButtonDotted
          text={'Crear observación'}
          p={1}
          onClick={newDescription}
        />
      </Flex>
    </>
  )
}

export default RenderArrayDescriptions
