import React from 'react'
import { Grid, GridItem, Text } from '@chakra-ui/react'
import PartnerInformation from './PartnerInformation'
import ApplicationInformation from './ApplicationInformation'
import BeneficiaryInformation from './BeneficiaryInformation'
import FamilyMembers from './FamilyMembers'
import EconomicsFamilyGroup from './EconomicsFamilyGroup'
import AdditionalsInformation from './AdditionalsInformation'
import Documents from './Documents'
import RenderFlowDocuments from '../RenderFlowDocuments'
import { SectionAccordion } from '../../UI'
import { haveDocuments } from '../../../Utils'

const SupportInCaseOfDeathOfMember = ({ program }) => {
  const renderViewTitle = (title) => (
    <Text
      fontWeight={700}
      bg={'green.100'}
      color={'white'}
      fontSize={20}
      borderRadius={8}
      px={5}
      py={1}
      my={4}
    >
      {title}
    </Text>
  )

  const documentsInProgram = haveDocuments(['registerSocial'], program)

  return (
    <Grid templateColumns={'repeat(1, minmax(0, 1fr))'} gap={2}>
      <GridItem>
        <PartnerInformation program={program} />
      </GridItem>
      <GridItem>
        {renderViewTitle('Información de la solicitud')}
        <ApplicationInformation program={program} />
      </GridItem>
      <GridItem>
        {renderViewTitle('Información del postulante a beca')}
        <BeneficiaryInformation program={program} />
      </GridItem>
      <GridItem>
        {renderViewTitle('Antecedentes del grupo familiar')}
        <FamilyMembers program={program} />
      </GridItem>
      <GridItem>
        {renderViewTitle('Antecedentes económicos del grupo familiar')}
        <EconomicsFamilyGroup program={program} />
      </GridItem>
      <GridItem>
        {renderViewTitle('Documentos y observaciones adicionales')}
        <AdditionalsInformation program={program} />
      </GridItem>
      <GridItem>
        {documentsInProgram && renderViewTitle('Documentación adjunta')}
        <Documents program={program} />
      </GridItem>
      <SectionAccordion title={'Documentos agregados dentro del flujo'}>
        <GridItem>
          <RenderFlowDocuments program={program} />
        </GridItem>
      </SectionAccordion>
    </Grid>
  )
}

export default SupportInCaseOfDeathOfMember
