import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Grid,
  GridItem,
  Flex,
  useToast,
  Text,
  Icon,
  Box
} from '@chakra-ui/react'
import { Alert, Heading, TabListHeader } from '../../components/UI'
import TotalAmountDashboard from '../../components/TotalAmountDashboard'
import TableDashboard from '../../components/TableDashboard'
import SkeletonToPage from '../../components/SkeletonToPage'
import { useDashboard } from '../../hooks/Dashboard'
import {
  AssistanceIcon,
  EducationIcon,
  HealthIcon
} from '../../components/Icons'

const Dashboard = () => {
  const { user, isLoggedIn } = useSelector((state) => state.auth)
  const toast = useToast()
  const initialFilters = {
    page: 0,
    limit: 6,
    category: '',
    types: isLoggedIn ? JSON.stringify(user.userType) : null
  }
  const [filters, setFilters] = useState(initialFilters)
  const { data, isError, isLoading, isFetching } = useDashboard(filters)

  const optionsTabComptrol = [
    {
      component: (
        <Flex align={'center'} px={7} justify={'center'}>
          Todos
          <Box bg={'white'} borderRadius={3} px={1} ml={2}>
            {data?.allToApprove ? data?.allToApprove : 0}
          </Box>
        </Flex>
      ),
      key: ''
    },
    {
      component: (
        <Flex align={'center'} px={7} justify={'center'}>
          <Icon mr={2} fontSize={23} as={AssistanceIcon} />
          Asistencia
          <Box bg={'white'} borderRadius={3} px={1} ml={2}>
            {data?.attendanceToApprove ? data?.attendanceToApprove : 0}
          </Box>
        </Flex>
      ),
      key: 'ATTENDANCE'
    },
    {
      component: (
        <Flex align={'center'} px={7} justify={'center'}>
          <Icon mr={2} fontSize={23} as={EducationIcon} />
          Educación
          <Box bg={'white'} borderRadius={3} px={1} ml={2}>
            {data?.educationToApprove ? data?.educationToApprove : 0}
          </Box>
        </Flex>
      ),
      key: 'EDUCATION'
    },
    {
      component: (
        <Flex align={'center'} px={7} justify={'center'}>
          <Icon mr={2} fontSize={23} as={HealthIcon} />
          Salud
          <Box bg={'white'} borderRadius={3} px={1} ml={2}>
            {data?.healthToApprove ? data?.healthToApprove : 0}
          </Box>
        </Flex>
      ),
      key: 'HEALTH'
    }
  ]

  useEffect(() => {
    toast.closeAll()
  }, [])

  return (
    <>
      {(isLoading || isFetching) && <SkeletonToPage type="dashboard" />}

      {isError && (
        <Flex w={'100%'} justify={'flex-end'} py={3}>
          <Alert status={'error'} message={'Error al Cargar los Datos'} />
        </Flex>
      )}

      {!isError && !isLoading && !isFetching && data && (
        <>
          <Flex direction={'column'} px={10}>
            <Heading title={'DASHBOARD'} />
            <Grid templateColumns={'repeat(1, 1fr)'} py={10} gap={6}>
              <GridItem>
                <TotalAmountDashboard data={data} />
              </GridItem>
              <GridItem>
                <Text fontWeight={700} fontSize={'2xl'} color={'green.500'}>
                  Solicitudes por aprobar
                </Text>
                <Text fontSize={'md'} color={'green.500'}>
                  Agregadas recientemente
                </Text>
              </GridItem>
              <GridItem>
                <TabListHeader
                  options={optionsTabComptrol}
                  filtersPrograms={filters}
                  setFiltersPrograms={setFilters}
                >
                  <TableDashboard
                    data={data}
                    filters={filters}
                    updateFilters={setFilters}
                  />
                </TabListHeader>
              </GridItem>
            </Grid>
          </Flex>
        </>
      )}
    </>
  )
}

export default Dashboard
