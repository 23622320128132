import React from 'react'
import { useParams } from 'react-router-dom'
import { Grid, GridItem, Flex, Text } from '@chakra-ui/react'
import { Heading, BackButton, Progress, Alert } from '../../../components/UI'
import DataViewPrograms from '../../../components/DataViewPrograms'
import ProgramHistory from '../../../components/ProgramHistory'
import { useProgram } from '../../../hooks/Programs'
import OptionsMenu from './OptionsMenu'
import ResignationOrLossModal from './ResignationOrLossModal'

const View = () => {
  const { programId, type } = useParams()
  const {
    data: program,
    isLoading,
    isFetching,
    isError
  } = useProgram(programId, type)

  return (
    <>
      {isError && (
        <Flex align={'center'} justify={'center'}>
          <Alert status={'error'} message={'Error al cargar los datos'} />
        </Flex>
      )}

      {(isLoading || isFetching) && <Progress />}
      {program && (
        <>
          <Grid
            templateColumns={{
              lg: 'repeat(2, 60% 40%)',
              md: 'repeat(1, 100%)',
              sm: 'repeat(1, 100%)'
            }}
            gap={2}
          >
            <GridItem
              h={'calc(100vh - 85px)'}
              overflowY={'scroll'}
              pr={2}
              css={{
                '&::-webkit-scrollbar': {
                  width: '8px'
                },
                '&::-webkit-scrollbar-track': {
                  width: '6px'
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#2E7423',
                  borderRadius: '24px'
                }
              }}
            >
              <Flex
                minW={'100%'}
                align={{
                  lg: 'center',
                  md: 'start',
                  sm: 'start'
                }}
                direction={{
                  lg: 'row',
                  md: 'column',
                  sm: 'column'
                }}
              >
                <BackButton showText={false} />
                <Heading title={program?.programName} backButton={true}>
                  <OptionsMenu program={program} />
                </Heading>
              </Flex>
              <DataViewPrograms type={program?.type} program={program} />
            </GridItem>
            <GridItem
              h={'calc(100vh - 85px)'}
              overflowY={'scroll'}
              pr={2}
              css={{
                '&::-webkit-scrollbar': {
                  width: '8px'
                },
                '&::-webkit-scrollbar-track': {
                  width: '6px'
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#2E7423',
                  borderRadius: '24px'
                }
              }}
              position={'relative'}
            >
              <ProgramHistory
                program={program}
                history={program?.steps}
                state={program?.state}
                type={program?.type}
              />
              {program?.state === 'DELETED' && (
                <Flex
                  position={'absolute'}
                  direction={'column'}
                  justify={'center'}
                  align={'center'}
                  textAlign={'center'}
                  bg={'blackAlpha.900'}
                  borderRadius={'md'}
                  minW={'100%'}
                  minH={'100%'}
                  top={0}
                  opacity={0.8}
                  zIndex={99}
                >
                  <Text color={'white'} fontWeight={700} fontSize={'xl'}>
                    El Programa actual se encuentra en estado Eliminado
                  </Text>
                  <Text color={'white'} fontWeight={700} fontSize={'md'}>
                    Flujo no disponible
                  </Text>
                </Flex>
              )}
            </GridItem>
          </Grid>
          <ResignationOrLossModal program={program} />
        </>
      )}
    </>
  )
}

export default View
