import { Flex, Grid, GridItem } from '@chakra-ui/react'
import React from 'react'
import { Files } from '../../../../Utils'
import {
  BarFileName,
  InputComtrol,
  TextAreaComtrol,
  UploadFile
} from '../../../UI'

const CommentForm = ({ formik }) => {
  const deleteFile = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.documentsNames,
      formik.values.documents
    )

    formik.setValues({
      ...formik.values,
      documentsNames: resultNames,
      documents: resultListFiles
    })
  }

  const handleChangeDocuments = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.documentsNames,
      formik.values.documents
    )

    formik.setValues({
      ...formik.values,
      documentsNames: newListNames,
      documents: newListFiles
    })
    e.target.value = null
  }

  return (
    <>
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2} mb={4}>
        <GridItem>
          <TextAreaComtrol
            id={'descriptions'}
            label={'Observaciones (opcional)'}
            isRequired={formik.values.isRequiredDescription}
            value={formik.values.descriptions}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.descriptions)}
            errorText={formik.errors.descriptions}
          />
        </GridItem>
        <GridItem>
          <InputComtrol
            id={'documentsNames'}
            label={'Agrega documentación (opcional)'}
            isRequired={formik.values.isRequiredDocuments}
          >
            <UploadFile
              id={'documentsNames'}
              direction={'row'}
              sizeIcon={30}
              p={5}
              multiple={true}
              onChange={(e) => handleChangeDocuments(e)}
              error={Boolean(formik.errors.documentsNames)}
              errorText={formik.errors.documentsNames}
            />
          </InputComtrol>
          <Flex direction={'column'} gap={1}>
            {formik.values.documentsNames.map((item) => (
              <BarFileName
                key={item?.name}
                fileName={item?.name}
                fileUrl={item?.url}
                onDelete={() => deleteFile(item?.name)}
              />
            ))}
          </Flex>
        </GridItem>
      </Grid>
    </>
  )
}

export default CommentForm
