import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Grid, GridItem, Flex, Box, Text } from '@chakra-ui/react'
import {
  BackButton,
  CardStatus,
  Button,
  Alert,
  Spinner
} from '../../../components/UI'
import { RedirectWhiteIcon } from '../../../components/Icons'
import FormContainerPrograms from '../../../components/FormContainerPrograms'
import { GetDiffDates } from '../../../Utils'
import GetStatusCard from '../../../Utils/GetStatusCard'
import { usePartner } from '../../../hooks/Partners'

const Create = () => {
  const { partnerId } = useParams()
  const history = useHistory()
  const { data: partner, isFetching, isLoading, error } = usePartner(partnerId)

  const handleRedirect = () => {
    history.push(`/partners/profile/${partnerId}/Perfil de Socio`)
  }

  return (
    <>
      {error && (
        <Alert status={'error'} message={'Error al cargar los Datos'} />
      )}
      {(isFetching || isLoading) && (
        <Flex justify={'center'} align={'center'}>
          <Spinner size="xl" show={true} />
        </Flex>
      )}
      {!isFetching && !isLoading && partner && (
        <Grid
          templateColumns={{
            lg: 'repeat(2, 25% 75%)',
            md: 'repeat(1, 1fr)',
            sm: 'repeat(1, 1fr)'
          }}
          gap={2}
        >
          <GridItem>
            <>
              <Flex direction={'column'} gap={10}>
                <BackButton />
                <Flex
                  direction={'column'}
                  gap={8}
                  border={'solid 1px #2E7423'}
                  borderRadius={18}
                  p={10}
                >
                  <Flex direction={'column'} gap={1}>
                    <Text fontWeight={700} fontSize={'2xl'} color={'green.500'}>
                      {partner.name}
                    </Text>
                    {GetStatusCard.partner(partner?.state)}
                    {partner.state !== 'NOT-VALIDATED' && (
                      <CardStatus
                        subValue={'Antiguedad'}
                        value={GetDiffDates(partner?.createdAt)}
                      />
                    )}
                  </Flex>
                  <Flex direction={'column'} gap={1}>
                    <Box>
                      <Text color={'grey.100'}>Rut</Text>
                      <Text fontWeight={700} color={'grey.500'}>
                        {partner?.rut}
                      </Text>
                    </Box>
                    <Box>
                      <Text color={'grey.100'}>Grado</Text>
                      <Text fontWeight={700} color={'grey.500'}>
                        {partner?.grade}
                      </Text>
                    </Box>
                    <Box>
                      <Text color={'grey.100'}>Correo Electronico</Text>
                      <Text fontWeight={700} color={'grey.500'}>
                        {partner?.email}
                      </Text>
                    </Box>
                    <Box>
                      <Text color={'grey.100'}>Teléfono</Text>
                      <Text fontWeight={700} color={'grey.500'}>
                        {partner?.phone}
                      </Text>
                    </Box>
                  </Flex>
                  <Flex direction={'column'} gap={1}>
                    <Button
                      text={'Ver perfil'}
                      icon={RedirectWhiteIcon}
                      onClick={handleRedirect}
                    />
                  </Flex>
                </Flex>
              </Flex>
            </>
          </GridItem>
          <GridItem>
            {!error && <FormContainerPrograms partner={partner} />}
          </GridItem>
        </Grid>
      )}
    </>
  )
}

export default Create
