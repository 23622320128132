import React from 'react'
import { Grid, GridItem, Icon, Flex, Text } from '@chakra-ui/react'
import { PhoneIcon } from '../../../Icons'

const PartnerInformation = ({ program }) => (
  <>
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={4}
      mx={20}
      mb={4}
    >
      <GridItem p={4}>
        <Flex w={'100%'} align={'center'} gap={2}>
          <Flex direction={'column'} justify={'flex-start'} w={'100%'}>
            <Flex align={'center'} gap={1}>
              <Text fontWeight={700} fontSize={'md'} color={'green.500'}>
                Socio
              </Text>
            </Flex>
            <Text fontSize={'md'} color={'grey.500'}>
              {program?.partner?.name || 'No disponible'}
            </Text>
          </Flex>
        </Flex>
        <Flex w={'100%'} align={'center'} gap={2}>
          <Flex direction={'column'} justify={'flex-start'} w={'50%'}>
            <Flex align={'center'} gap={1}>
              <Icon as={PhoneIcon} size={'md'} />
              <Text fontWeight={700} fontSize={'md'} color={'green.500'}>
                Teléfono principal
              </Text>
            </Flex>
            <Text fontSize={'md'} color={'grey.500'}>
              {program?.partner?.phone
                ? `+569 ${program?.partner?.phone}`
                : 'No disponible'}{' '}
            </Text>
          </Flex>
          <Flex direction={'column'} justify={'flex-end'} w={'50%'}>
            <Flex align={'center'} gap={1}>
              <Icon as={PhoneIcon} size={'md'} />
              <Text fontWeight={700} fontSize={'md'} color={'green.500'}>
                Teléfono alternativo
              </Text>
            </Flex>
            <Text fontSize={'md'} color={'grey.500'}>
              {program?.alternativePhone
                ? `+569 ${program?.alternativePhone}`
                : 'No disponible'}
            </Text>
          </Flex>
        </Flex>
      </GridItem>
    </Grid>
  </>
)

export default PartnerInformation
