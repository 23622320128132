import { Grid, GridItem, Text } from '@chakra-ui/react'
import React from 'react'
import { CardFilePreview, TextAreaComtrol } from '../../UI'

const AdditionalsInformation = ({ program }) => (
  <>
    <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
      <GridItem>
        <TextAreaComtrol
          isRequired={false}
          label={'Observaciones de la solicitud'}
          value={program?.observationsRequest || 'No hay observaciones'}
          readOnly={true}
        />
      </GridItem>
    </Grid>
    <Text fontWeight={700} fontSize={'xl'}>
      Documentos adicionales
    </Text>
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      {program?.additionalDocuments.map((item) => (
        <GridItem key={item?.name}>
          <CardFilePreview name={item?.name} url={item?.url} />
        </GridItem>
      ))}
    </Grid>
  </>
)

export default AdditionalsInformation
