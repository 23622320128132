import { Grid, GridItem, Text, Avatar, Divider } from '@chakra-ui/react'
import React from 'react'

const ParticipantsList = ({ program }) => (
  <>
    {program?.registeredPartners.length > 0 &&
    Object.keys(program?.registeredPartners.at(0)).length > 0 ? (
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
        {program?.registeredPartners.map((item) => (
          <GridItem
            key={item?.partner?._id}
            border={'1px solid #82b378'}
            borderRadius={12}
            py={2}
            px={4}
          >
            <>
              <Grid templateColumns={'repeat(3, auto 45% 45%)'} gap={2}>
                <GridItem mt={'auto'} mb={'auto'}>
                  <Avatar
                    name={'1'}
                    borderRadius={8}
                    size={'sm'}
                    bg={'grey.50'}
                    color={'green.500'}
                    fontWeight={700}
                  />
                </GridItem>
                <GridItem>
                  <Text color={'green.100'} fontWeight={700} fontSize={12}>
                    Nombre del Socio(a)
                  </Text>
                  <Text fontWeight={700}>{item?.partner?.name}</Text>
                </GridItem>
              </Grid>
              {/* --- */}
              <Grid templateColumns={'repeat(3, auto 45% 45%)'} gap={2}>
                <GridItem mt={'auto'} mb={'auto'}>
                  <Avatar
                    name={'1'}
                    borderRadius={8}
                    size={'sm'}
                    bg={'grey.50'}
                    color={'green.500'}
                    fontWeight={700}
                    opacity={0}
                  />
                </GridItem>
                <GridItem>
                  <Text color={'green.100'} fontWeight={700} fontSize={12}>
                    Correo electrónico
                  </Text>
                  <Text>{item?.partner?.email}</Text>
                </GridItem>
                <GridItem>
                  <Text color={'green.100'} fontWeight={700} fontSize={12}>
                    Teléfono
                  </Text>
                  <Text>
                    {`+569 ${item?.partner?.phone}` || 'No registrado'}
                  </Text>
                </GridItem>
              </Grid>
              {/* --- */}
              {item?.spouse && (
                <>
                  <Divider my={2} />
                  <Grid templateColumns={'repeat(3, auto 45% 45%)'} gap={2}>
                    <GridItem mt={'auto'} mb={'auto'}>
                      <Avatar
                        name={'2'}
                        borderRadius={8}
                        size={'sm'}
                        bg={'grey.50'}
                        color={'green.500'}
                        fontWeight={700}
                      />
                    </GridItem>
                    <GridItem>
                      <Text color={'green.100'} fontWeight={700} fontSize={12}>
                        Nombre y apellido del cónyuge
                      </Text>
                      <Text fontWeight={700}>{item?.spouse?.fullName}</Text>
                    </GridItem>
                  </Grid>
                  {/* --- */}
                  <Grid templateColumns={'repeat(3, auto 45% 45%)'} gap={2}>
                    <GridItem mt={'auto'} mb={'auto'}>
                      <Avatar
                        name={'1'}
                        borderRadius={8}
                        size={'sm'}
                        bg={'grey.50'}
                        color={'green.500'}
                        fontWeight={700}
                        opacity={0}
                      />
                    </GridItem>
                    <GridItem>
                      <Text color={'green.100'} fontWeight={700} fontSize={12}>
                        Correo electrónico
                      </Text>
                      <Text>{item?.spouse?.email}</Text>
                    </GridItem>
                    <GridItem>
                      <Text color={'green.100'} fontWeight={700} fontSize={12}>
                        Teléfono
                      </Text>
                      <Text>
                        {`+569 ${item?.spouse?.phone}` || 'No registrado'}
                      </Text>
                    </GridItem>
                  </Grid>
                </>
              )}
            </>
          </GridItem>
        ))}
      </Grid>
    ) : (
      'No hay participantes registrados'
    )}
  </>
)

export default ParticipantsList
