import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { Flex, Grid, GridItem, Tooltip } from '@chakra-ui/react'
import { Button, InputComtrol, Select, SquareButton } from '../../../UI'
import { Age, RelationshipList3 } from '../../../../Utils'
import { itemStepTwoSchema } from '../Schemas'
import SelectFamilyMember from '../../../SelectFamilyMember'

const FamilyMemberItem = ({ formik, member, partner }) => {
  const [hasSpouse, setHasSpouse] = useState(false)
  const initialValues = {
    _id: '',
    guestFullName: '',
    guestAge: Age.at(0),
    guestRelationship: RelationshipList3.at(0),
    isValid: false,
    guestToCreate: false
  }

  const formikItem = useFormik({
    initialValues,
    validationSchema: itemStepTwoSchema,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: (values) => {
      formik.setValues({
        ...formik.values,
        additionalGuest: formik.values.additionalGuest?.map((item) => {
          if (item?.ID_CARD === member?.ID_CARD) {
            return {
              ...item,
              _id: values._id,
              guestFullName: values.guestFullName,
              guestAge: values.guestAge,
              guestRelationship: values.guestRelationship,
              isValid: true,
              guestToCreate: values.guestToCreate
            }
          }
          return item
        })
      })
    }
  })

  const handleChangeGuestAge = (value) => {
    formikItem.setFieldValue('guestAge', value)
  }

  const handleChangeGuestRelationship = (value) => {
    formikItem.setFieldValue('guestRelationship', value)
  }

  const handleChangeSelectFamilyMember = (dataMember) => {
    if (dataMember === 'ADD_FAMILY_MEMBER') {
      formikItem.setValues({
        ...formikItem.values,
        _id: Math.random().toString(),
        guestFullName: '',
        guestAge: Age.at(0),
        guestRelationship: RelationshipList3.at(0),
        guestToCreate: true,
        isDuplicate: false
      })
      return
    }
    formikItem.setValues({
      ...formikItem.values,
      _id: dataMember?._id || '',
      guestFullName: dataMember?.fullName || '',
      guestAge: dataMember?.age?.toString() || Age.at(0),
      guestRelationship: dataMember?.relationship || RelationshipList3.at(0),
      guestToCreate: false,
      isDuplicate: Boolean(
        formik.values.additionalGuest.filter(
          (item) =>
            item._id === dataMember?._id && item.ID_CARD !== member.ID_CARD
        ).length > 0
      )
    })
  }

  const deleteMember = () => {
    formik.setValues({
      ...formik.values,
      additionalGuest: formik.values.additionalGuest.filter(
        (item) => item.ID_CARD !== member.ID_CARD
      )
    })
  }

  const isValidMemberItem = () => {
    const current = formik.values.additionalGuest.find(
      (item) => item.ID_CARD === member.ID_CARD
    )
    const isValid = Boolean(current.isValid)
    const isEqualsGuestFullName = Boolean(
      current.guestFullName === formikItem.values.guestFullName
    )
    const isEqualsGuestAge = Boolean(
      current.guestAge === formikItem.values.guestAge
    )
    const isEqualsGuestRelationship = Boolean(
      current.guestRelationship === formikItem.values.guestRelationship
    )

    if (
      isValid &&
      isEqualsGuestFullName &&
      isEqualsGuestAge &&
      isEqualsGuestRelationship
    ) {
      return true
    }
    return false
  }

  const returnListRelationship = () => {
    if (hasSpouse && formikItem.values.guestRelationship === 'Cónyuge') {
      return RelationshipList3
    }
    if (hasSpouse && formikItem.values.guestRelationship !== 'Cónyuge') {
      return RelationshipList3.filter((item) => item !== 'Cónyuge')
    }
    if (!hasSpouse) {
      return RelationshipList3
    }
    return RelationshipList3
  }

  useEffect(() => {
    if (!formikItem.isValid) {
      formik.setValues({
        ...formik.values,
        additionalGuest: formik.values.additionalGuest.map((item) => {
          if (item.ID_CARD === member.ID_CARD) {
            return {
              ...item,
              _id: formikItem.values._id,
              guestFullName: formikItem.values.guestFullName,
              guestAge: formikItem.values.guestAge,
              guestRelationship: formikItem.values.guestRelationship,
              isValid: false,
              guestToCreate: formikItem.values.guestToCreate,
              isDuplicate: formikItem.values.isDuplicate
            }
          }
          return item
        })
      })
    }
  }, [formikItem.isValid])

  useEffect(() => {
    formikItem.setValues({
      ...formikItem.values,
      isDuplicate: Boolean(
        formik.values.additionalGuest.filter(
          (item) =>
            item?._id === formikItem.values?._id &&
            item?.ID_CARD !== member?.ID_CARD
        ).length > 0 || formikItem.values?._id === formik.values.patientId
      )
    })
  }, [formik.values.additionalGuest, formikItem.values._id])

  useEffect(() => {
    if (member) {
      formikItem.setValues({
        _id: member._id || '',
        guestFullName: member.guestFullName || member.fullName || '',
        guestAge: member.guestAge || member.age || Age.at(0),
        guestRelationship:
          member.guestRelationship ||
          member.relationship ||
          RelationshipList3.at(0),
        isValid: member.isValid || false,
        guestToCreate: member.guestToCreate || false,
        isDuplicate: member.isDuplicate || false
      })
    }
  }, [member])

  return (
    <Grid
      templateColumns={'repeat(1, 1fr)'}
      border={'1px solid #82b378'}
      borderRadius={8}
      gap={2}
      py={1}
      px={4}
    >
      <GridItem ml={'auto'}>
        <SquareButton onClick={() => deleteMember()} size={'xs'}>
          x
        </SquareButton>
      </GridItem>
      <GridItem
        w={{
          lg: '50%',
          md: '100%',
          sm: '100%'
        }}
      >
        <InputComtrol
          label={'Seleccione un familiar ya registrado'}
          errorText={formikItem.errors.isDuplicate}
        >
          <SelectFamilyMember
            familyMemberId={formikItem.values._id}
            partnerId={partner?._id}
            setHasSpouse={setHasSpouse}
            addNewFamilyMember={formikItem.values.guestToCreate}
            onChange={handleChangeSelectFamilyMember}
            error={Boolean(formikItem.errors.isDuplicate)}
          />
        </InputComtrol>
      </GridItem>
      {formikItem.values._id && (
        <GridItem>
          <Grid templateColumns={'repeat(1, 1fr)'}>
            <GridItem>
              <InputComtrol
                id={'guestFullName'}
                label={'Nombres y apellidos'}
                value={formikItem.values.guestFullName}
                onChange={formikItem.handleChange}
                error={Boolean(formikItem.errors.guestFullName)}
                errorText={formikItem.errors.guestFullName}
              />
            </GridItem>
          </Grid>
          {/* --- */}
          <Grid
            templateColumns={{
              lg: 'repeat(2, 1fr)',
              md: 'repeat(1, 1fr)',
              sm: 'repeat(1, 1fr)'
            }}
            gap={2}
          >
            <GridItem>
              <InputComtrol id={'guestAge'} label={'Edad del huésped'}>
                <Select
                  bg={'white'}
                  list={Age}
                  value={formikItem.values.guestAge}
                  onChange={handleChangeGuestAge}
                />
              </InputComtrol>
            </GridItem>
            <GridItem>
              <InputComtrol id={'guestRelationship'} label={'Parentesco'}>
                <Select
                  bg={'white'}
                  list={[...RelationshipList3, 'Otro']}
                  value={formikItem.values.guestRelationship}
                  onChange={handleChangeGuestRelationship}
                />
              </InputComtrol>
            </GridItem>
          </Grid>
          <Grid templateColumns={'repeat(1, 1fr)'}>
            <GridItem ml={'auto'}>
              <Flex justify={'flex-end'}>
                <Tooltip
                  label={`Favor Validar! ->`}
                  placement="left"
                  bg={'#d68c49'}
                  isOpen={!isValidMemberItem()}
                >
                  <Flex>
                    <Button
                      text={isValidMemberItem() ? 'Validado' : 'No validado'}
                      py={1}
                      px={2}
                      onClick={formikItem.handleSubmit}
                      size={'xs'}
                    />
                  </Flex>
                </Tooltip>
              </Flex>
            </GridItem>
          </Grid>
        </GridItem>
      )}
    </Grid>
  )
}

export default FamilyMemberItem
