import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import { BarFileName, UploadFile } from '../../UI'
import { steepThreeSchema } from './Schemas'
import { Files } from '../../../Utils'

const StepThree = ({ stepThreeForm, setStepThreeForm, refStepThree, next }) => {
  const initialValues = {
    birthCertificateName: [],
    birthCertificate: null,
    scoreCertificateName: [],
    scoreCertificate: null,
    parentsLastSalaryNames: [],
    parentsLastSalary: [],
    motivationLetterName: [],
    motivationLetter: null,
    applicationSignedByPartnerName: [],
    applicationSignedByPartner: null,
    scoreCertificateSantoTomasName: [],
    scoreCertificateSantoTomas: null,
    socialReportName: [],
    socialReport: null
  }

  const formik = useFormik({
    initialValues,
    validationSchema: steepThreeSchema,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: (values) => {
      setStepThreeForm({
        ...values
      })
      next()
    }
  })

  const deleteBirthCertificate = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.birthCertificateName,
      formik.values.birthCertificate
    )

    formik.setValues({
      ...formik.values,
      birthCertificateName: resultNames,
      birthCertificate: resultListFiles
    })
  }

  const deleteScoreCertificate = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.scoreCertificateName,
      formik.values.scoreCertificate
    )

    formik.setValues({
      ...formik.values,
      scoreCertificateName: resultNames,
      scoreCertificate: resultListFiles
    })
  }

  const deleteMotivationLetter = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.motivationLetterName,
      formik.values.motivationLetter
    )

    formik.setValues({
      ...formik.values,
      motivationLetterName: resultNames,
      motivationLetter: resultListFiles
    })
  }

  const deleteApplicationSignedByPartner = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.applicationSignedByPartnerName,
      formik.values.applicationSignedByPartner
    )

    formik.setValues({
      ...formik.values,
      applicationSignedByPartnerName: resultNames,
      applicationSignedByPartner: resultListFiles
    })
  }

  const deleteScoreCertificateSantoTomas = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.scoreCertificateSantoTomasName,
      formik.values.scoreCertificateSantoTomas
    )

    formik.setValues({
      ...formik.values,
      scoreCertificateSantoTomasName: resultNames,
      scoreCertificateSantoTomas: resultListFiles
    })
  }

  const deleteParentsLastSalary = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.parentsLastSalaryNames,
      formik.values.parentsLastSalary
    )

    formik.setValues({
      ...formik.values,
      parentsLastSalaryNames: resultNames,
      parentsLastSalary: resultListFiles
    })
  }

  const deleteSocialReport = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.socialReportName,
      formik.values.socialReport
    )

    formik.setValues({
      ...formik.values,
      socialReportName: resultNames,
      socialReport: resultListFiles
    })
  }

  const handleChangeBirthCertificate = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.birthCertificateName,
      formik.values.birthCertificate
    )

    formik.setValues({
      ...formik.values,
      birthCertificateName: newListNames,
      birthCertificate: newListFiles
    })
    e.target.value = null
  }

  const handleChangeScoreCertificate = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.scoreCertificateName,
      formik.values.scoreCertificate
    )

    formik.setValues({
      ...formik.values,
      scoreCertificateName: newListNames,
      scoreCertificate: newListFiles
    })
    e.target.value = null
  }

  const handleChangeMotivationLetter = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.motivationLetterName,
      formik.values.motivationLetter
    )

    formik.setValues({
      ...formik.values,
      motivationLetterName: newListNames,
      motivationLetter: newListFiles
    })
    e.target.value = null
  }

  const handleChangeApplicationSignedByPartner = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.applicationSignedByPartnerName,
      formik.values.applicationSignedByPartner
    )

    formik.setValues({
      ...formik.values,
      applicationSignedByPartnerName: newListNames,
      applicationSignedByPartner: newListFiles
    })
    e.target.value = null
  }

  const handleChangeScoreCertificateSantoTomas = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.scoreCertificateSantoTomasName,
      formik.values.scoreCertificateSantoTomas
    )

    formik.setValues({
      ...formik.values,
      scoreCertificateSantoTomasName: newListNames,
      scoreCertificateSantoTomas: newListFiles
    })
    e.target.value = null
  }

  const handleChangeParentsLastSalary = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.parentsLastSalaryNames,
      formik.values.parentsLastSalary
    )

    formik.setValues({
      ...formik.values,
      parentsLastSalaryNames: newListNames,
      parentsLastSalary: newListFiles
    })
    e.target.value = null
  }

  const handleChangeSocialReport = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.socialReportName,
      formik.values.socialReport
    )

    formik.setValues({
      ...formik.values,
      socialReportName: newListNames,
      socialReport: newListFiles
    })
    e.target.value = null
  }

  useEffect(() => {
    refStepThree.current = formik.handleSubmit
  }, [])

  useEffect(() => {
    formik.setValues({
      birthCertificateName: stepThreeForm.birthCertificateName || [],
      birthCertificate: stepThreeForm.birthCertificate || null,
      scoreCertificateName: stepThreeForm.scoreCertificateName || [],
      scoreCertificate: stepThreeForm.scoreCertificate || null,
      parentsLastSalaryNames: stepThreeForm.parentsLastSalaryNames || [],
      parentsLastSalary: stepThreeForm.parentsLastSalary || null,
      motivationLetterName: stepThreeForm.motivationLetterName || [],
      motivationLetter: stepThreeForm.motivationLetter || null,
      applicationSignedByPartnerName:
        stepThreeForm.applicationSignedByPartnerName || [],
      applicationSignedByPartner:
        stepThreeForm.applicationSignedByPartner || null,
      scoreCertificateSantoTomasName:
        stepThreeForm.scoreCertificateSantoTomasName || [],
      scoreCertificateSantoTomas:
        stepThreeForm.scoreCertificateSantoTomas || null,
      socialReportName: stepThreeForm.socialReportName || [],
      socialReport: stepThreeForm.socialReport || null
    })
  }, [stepThreeForm])

  return (
    <>
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          <Text fontWeight={700}>Certificado de nacimiento</Text>
          <UploadFile
            direction={'row'}
            sizeIcon={30}
            p={5}
            onChange={(e) => {
              handleChangeBirthCertificate(e)
            }}
            id={'birthCertificateName'}
            error={Boolean(formik.errors.birthCertificateName)}
            errorText={formik.errors.birthCertificateName}
          />
          <Flex direction={'column'} gap={1}>
            {formik.values.birthCertificateName.map((item) => (
              <BarFileName
                key={item?.name}
                fileName={item?.name}
                fileUrl={item?.url}
                onDelete={() => deleteBirthCertificate(item?.name)}
              />
            ))}
          </Flex>
        </GridItem>
        <GridItem>
          <Text fontWeight={700}>
            Concentración de notas de enseñanza media
          </Text>
          <UploadFile
            direction={'row'}
            sizeIcon={30}
            p={5}
            onChange={(e) => {
              handleChangeScoreCertificate(e)
            }}
            id={'scoreCertificateName'}
            error={Boolean(formik.errors.scoreCertificateName)}
            errorText={formik.errors.scoreCertificateName}
          />
          <Flex direction={'column'} gap={1}>
            {formik.values.scoreCertificateName.map((item) => (
              <BarFileName
                key={item?.name}
                fileName={item?.name}
                fileUrl={item?.url}
                onDelete={() => deleteScoreCertificate(item?.name)}
              />
            ))}
          </Flex>
        </GridItem>
      </Grid>
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2} pb={12}>
        <GridItem>
          <Text fontWeight={700}>
            Última liquidación de sueldo de los padres
          </Text>
          <UploadFile
            direction={'row'}
            sizeIcon={30}
            p={5}
            onChange={(e) => {
              handleChangeParentsLastSalary(e)
            }}
            multiple={true}
            id={'parentsLastSalaryNames'}
            error={Boolean(formik.errors.parentsLastSalaryNames)}
            errorText={formik.errors.parentsLastSalaryNames}
          />
          <Flex direction={'column'} gap={1}>
            {formik.values.parentsLastSalaryNames.map((item) => (
              <BarFileName
                key={item?.name}
                fileName={item?.name}
                fileUrl={item?.url}
                onDelete={() => deleteParentsLastSalary(item?.name)}
              />
            ))}
          </Flex>
        </GridItem>
      </Grid>
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          <Text fontWeight={700}>Carta de motivación manuscrita</Text>
          <UploadFile
            direction={'row'}
            sizeIcon={30}
            p={5}
            onChange={(e) => {
              handleChangeMotivationLetter(e)
            }}
            id={'motivationLetterName'}
            error={Boolean(formik.errors.motivationLetterName)}
            errorText={formik.errors.motivationLetterName}
          />
          <Flex direction={'column'} gap={1}>
            {formik.values.motivationLetterName.map((item) => (
              <BarFileName
                key={item?.name}
                fileName={item?.name}
                fileUrl={item?.url}
                onDelete={() => deleteMotivationLetter(item?.name)}
              />
            ))}
          </Flex>
        </GridItem>
        <GridItem>
          <Text fontWeight={700}>
            Solicitud de postulación suscrita por el socio
          </Text>
          <UploadFile
            direction={'row'}
            sizeIcon={30}
            p={5}
            onChange={(e) => {
              handleChangeApplicationSignedByPartner(e)
            }}
            id={'applicationSignedByPartnerName'}
            error={Boolean(formik.errors.applicationSignedByPartnerName)}
            errorText={formik.errors.applicationSignedByPartnerName}
          />
          <Flex direction={'column'} gap={1}>
            {formik.values.applicationSignedByPartnerName.map((item) => (
              <BarFileName
                key={item?.name}
                fileName={item?.name}
                fileUrl={item?.url}
                onDelete={() => deleteApplicationSignedByPartner(item?.name)}
              />
            ))}
          </Flex>
        </GridItem>
      </Grid>
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2} pb={12}>
        <GridItem>
          <Text fontWeight={700}>Formulario Social</Text>
          <UploadFile
            direction={'row'}
            sizeIcon={30}
            p={5}
            onChange={(e) => {
              handleChangeSocialReport(e)
            }}
            id={'socialReportName'}
            error={Boolean(formik.errors.socialReportName)}
            errorText={formik.errors.socialReportName}
          />
          <Flex direction={'column'} gap={1}>
            {formik.values.socialReportName.map((item) => (
              <BarFileName
                key={item?.name}
                fileName={item?.name}
                fileUrl={item?.url}
                onDelete={() => deleteSocialReport(item?.name)}
              />
            ))}
          </Flex>
        </GridItem>
        <GridItem>
          <Text fontWeight={700}>Concentración de notas de Santo Tomás</Text>
          <Text fontSize={12}>
            Sólo para los que estudian en el Instituto Santo tomás*
          </Text>
          <UploadFile
            direction={'row'}
            sizeIcon={30}
            p={5}
            onChange={(e) => {
              handleChangeScoreCertificateSantoTomas(e)
            }}
            id={'scoreCertificateSantoTomasName'}
            error={Boolean(formik.errors.scoreCertificateSantoTomasName)}
            errorText={formik.errors.scoreCertificateSantoTomasName}
          />
          <Flex direction={'column'} gap={1}>
            {formik.values.scoreCertificateSantoTomasName.map((item) => (
              <BarFileName
                key={item?.name}
                fileName={item?.name}
                fileUrl={item?.url}
                onDelete={() => deleteScoreCertificateSantoTomas(item?.name)}
              />
            ))}
          </Flex>
        </GridItem>
      </Grid>
    </>
  )
}
export default StepThree
