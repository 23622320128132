import React from 'react'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Text
} from '@chakra-ui/react'
import FormUpload from './formUpload'

const FormUploadMasivePartners = ({ refetch }) => (
  <Flex gap={4} direction={'column'}>
    <Accordion allowMultiple>
      <AccordionItem>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            <Text whiteSpace={'nowrap'} fontWeight={700} color={'green.500'}>
              Carga masiva de socios:
            </Text>
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          <Flex gap={3} direction={'column'}>
            <FormUpload refetch={refetch} />
          </Flex>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  </Flex>
)

export default FormUploadMasivePartners
