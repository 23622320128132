import React from 'react'
import { LockIcon } from '../Icons'
import { Button } from '../UI'

const ButtonHistory = ({ disable, colors, onClick }) => (
  <Button
    icon={LockIcon}
    text={'Completar'}
    bg={colors().bg}
    bghover={colors().hover}
    variant={'outline'}
    disabled={disable}
    onClick={onClick}
    py={1}
  />
)

export default ButtonHistory
