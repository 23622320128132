import React from 'react'
import { Flex, Square, Text } from '@chakra-ui/react'
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Label,
  Cell,
  Tooltip
} from 'recharts'

const TotalsPieChart = ({ data, state }) => {
  const CustomLabel = ({ viewBox, total }) => {
    const { cx, cy } = viewBox
    return (
      <text
        x={cx}
        y={cy}
        fill="#3d405c"
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
        direction="column"
      >
        <tspan x={cx} dy={-20} fontSize="12">
          TOTAL
        </tspan>
        <tspan x={cx} dy={20} fontSize="26" fontWeight={700}>
          {total}
        </tspan>
        <tspan x={cx} dy={20} fontSize="12">
          Solicitudes
        </tspan>
      </text>
    )
  }

  return (
    <Flex
      direction={'column'}
      justify={'center'}
      align={'center'}
      w={'100%'}
      h={'100%'}
    >
      <Text fontWeight={500}>Resolución de solicitudes</Text>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={data}
            cx={'50%'}
            cy={'50%'}
            innerRadius={55}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={-10}
            cornerRadius={18}
            dataKey="value"
          >
            <Label
              width={30}
              position="center"
              content={
                <CustomLabel
                  total={
                    data?.find((item) => item?.name === 'TOTAL')?.value || 0
                  }
                />
              }
            ></Label>
            {data.map((item, index) => (
              <Cell key={`cell-${index}`} fill={item?.color} strokeWidth={1} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
      <Flex justify={'space-between'} align={'center'} w={'100%'} px={10}>
        <Flex gap={1} align={'center'}>
          <Square
            size="20px"
            bg={data?.find((item) => item?.name === state)?.color || '#bdb8b7'}
            borderRadius={5}
          />
          <Text>{data?.find((item) => item?.name === state)?.name || ''}</Text>
        </Flex>
        <Text fontWeight={500}>
          {data?.find((item) => item?.name === state)?.value || 0}
        </Text>
      </Flex>
    </Flex>
  )
}

export default TotalsPieChart
