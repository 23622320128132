import React, { useState, useEffect } from 'react'
import { Grid, GridItem, Flex, Text, Divider } from '@chakra-ui/react'
import {
  InputComtrol,
  CardFilePreview,
  TextAreaComtrol,
  InfoCard
} from '../../UI'
import { ClearFormatMoney, FormatMoney } from '../../../Utils'

const FamilyBackground = ({ program }) => {
  const [total, setTotal] = useState(0)

  useEffect(() => {
    if (program) {
      const totalAcc = program?.familyGroupBackground?.reduce(
        (acc, item) => acc + Number(ClearFormatMoney(item.monthlyExpenditure)),
        0
      )
      setTotal(FormatMoney(totalAcc))
    }
  }, [])

  const renderViewCard = (member) => (
    <Flex
      direction={'column'}
      border={'solid 1px #2E7423'}
      borderRadius={8}
      p={6}
      my={1}
      gap={1}
    >
      <Flex
        gap={1}
        direction={{
          lg: 'row',
          md: 'row',
          sm: 'column'
        }}
      >
        <InputComtrol
          isRequired={false}
          label={'Nombre Completo'}
          value={member?.memberName}
          readOnly={true}
        />
        <InputComtrol
          isRequired={false}
          label={'Edad'}
          value={member?.memberAge}
          readOnly={true}
        />
        <InputComtrol
          isRequired={false}
          label={'Parentesco'}
          value={member?.memberRelationship}
          readOnly={true}
        />
      </Flex>
      <Flex
        gap={1}
        direction={{
          lg: 'row',
          md: 'row',
          sm: 'column'
        }}
      >
        <InputComtrol
          isRequired={false}
          label={'Carga Familiar'}
          value={member?.isFamilyBurden}
          readOnly={true}
        />
        <InputComtrol
          isRequired={false}
          label={'Actividad'}
          value={member?.memberActivity}
          readOnly={true}
        />
        <InputComtrol
          isRequired={false}
          label={'Ingresos'}
          value={member?.memberIncomes || 'No Presenta Ingresos'}
          readOnly={true}
        />
      </Flex>
      {member?.memberSwornDeclaration && (
        <>
          <Text fontWeight={700}>Declaración jurada simple</Text>
          {member?.memberSwornDeclaration?.map((item) => (
            <CardFilePreview name={item?.name} url={item?.url} />
          ))}
        </>
      )}
      {/* --- health data --- */}

      {member?.haveMedicalHistory && (
        <Flex
          direction={'column'}
          border={'solid 1px #2E7423'}
          borderRadius={8}
          p={6}
          my={4}
          gap={2}
        >
          <Text fontWeight={700}>Información médica</Text>
          <Divider py={1} />

          <Grid
            templateColumns={{
              lg: 'repeat(2, 1fr)',
              md: 'repeat(1, 1fr)',
              sm: 'repeat(1, 1fr)'
            }}
            gap={2}
          >
            <GridItem>
              <InputComtrol
                isRequired={false}
                label={'Médico tratante'}
                value={member?.doctorName || 'No Presenta'}
                readOnly={true}
              />
              <InputComtrol
                isRequired={false}
                label={'Hospital y/o clínica donde se trata'}
                value={member?.hospotalName || 'No Presenta'}
                readOnly={true}
              />
              <InputComtrol
                isRequired={false}
                label={'Gasto mensual'}
                value={member?.monthlyExpenditure}
                readOnly={true}
              />
            </GridItem>
            <GridItem>
              <InputComtrol
                isRequired={false}
                label={'Diagnostico médico'}
                value={member?.diagnostic || 'No Presenta'}
                readOnly={true}
              />
              <InputComtrol
                isRequired={false}
                label={'Tiempo evolución enfermedad'}
                value={member?.evolutionTime || 'No Presenta'}
                readOnly={true}
              />
            </GridItem>
          </Grid>

          <TextAreaComtrol
            isRequired={false}
            label={'Observaciones'}
            value={member?.observation || 'No Presenta'}
            readOnly={true}
          />
        </Flex>
      )}
    </Flex>
  )

  return (
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={4}
    >
      <InfoCard bg={'beige.50'}>
        <Text fontWeight={700}>Subtotal gastos médicos grupo familiar</Text>
        <Text fontWeight={700} fontSize={'4xl'}>
          $ {total}
        </Text>
      </InfoCard>
      <GridItem>
        {program?.familyGroupBackground?.map((member) =>
          renderViewCard(member)
        )}
      </GridItem>
    </Grid>
  )
}

export default FamilyBackground
