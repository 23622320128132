import React, { useEffect } from 'react'
import { Grid, GridItem, Flex, useToast } from '@chakra-ui/react'
import { Heading } from '../../components/UI'
import AveragePerYearAndMonth from '../../components/ReportabilityGraphs/AveragePerYearAndMonth'
import ResolutionOfRequests from '../../components/ReportabilityGraphs/ResolutionOfRequests'
import AmountApprovedPerBenefit from '../../components/ReportabilityGraphs/AmountApprovedPerBenefit'
import RequestApprovedPerGrade from '../../components/ReportabilityGraphs/RequestApprovedPerGrade'
import RequestApprovedPerBeneficiary from '../../components/ReportabilityGraphs/RequestApprovedPerBeneficiary'

const Reportability = () => {
  const toast = useToast()
  useEffect(() => {
    toast.closeAll()
  }, [])

  return (
    <>
      <Flex direction={'column'} px={10}>
        <Heading title={'REPORTES GENERALES'} />
        <Grid templateColumns={'repeat(1, minmax(0, 1fr))'} py={10} gap={4}>
          <GridItem borderBlockEnd={'2px dotted #adadad'} py={4}>
            <AveragePerYearAndMonth />
          </GridItem>
          <GridItem borderBlockEnd={'2px dotted #adadad'} py={4}>
            <ResolutionOfRequests />
          </GridItem>
          <GridItem borderBlockEnd={'2px dotted #adadad'} py={4}>
            <AmountApprovedPerBenefit />
          </GridItem>
          <GridItem borderBlockEnd={'2px dotted #adadad'} py={4}>
            <RequestApprovedPerGrade />
          </GridItem>
          <GridItem borderBlockEnd={'2px dotted #adadad'} py={4}>
            <RequestApprovedPerBeneficiary />
          </GridItem>
        </Grid>
      </Flex>
    </>
  )
}

export default Reportability
