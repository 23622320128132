import React, { useState, useEffect } from 'react'
import { Flex } from '@chakra-ui/react'
import { SectionAccordion, TextAreaComtrol } from '../../../UI'
import RenderDocument from '../../ActionStepEdit/RenderDocument'
import { useTypeModalView } from '../../../../hooks/TypeModal'

const documentsKey = [
  'documents',
  'voucher',
  'instructive',
  'backupFeeAmount',
  'scholarshipCommission',
  'bankData',
  'office',
  'invoice',
  'classSchedule',
  'feeBill',
  'attendanceSheet',
  'amountCancel',
  'purchaseDetail',
  'acta',
  'reportedEmail',
  'boxPurchase',
  'boxPurchaseTwo',
  'purchaseRequestLetter',
  'quotesReceived',
  'debtCertificate',
  'purchaseRequestLetterSigned',
  'documentsSentMedicalInstitution'
]

const RenderPreviewPastStep = ({ program, keyToSearch }) => {
  const [data, setData] = useState({})
  const { titleDocument } = useTypeModalView(data?.key)

  const getTitleQuotesReceived = () => {
    const additionalInformation = program?.additionalInformation.filter(
      (item) => item.key === 'ADD-DEBT-CERTIFICATE'
    )
    if (additionalInformation.length > 0) {
      return 'Acta resolución de comisión'
    }

    const additionalInformation2 = program?.additionalInformation.filter(
      (item) => item.key === 'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND'
    )
    if (additionalInformation2.length > 0) {
      const additionalInformation3 = program?.additionalInformation.filter(
        (item) => item.key === 'COMMISSION-PREPARATION-SOLIDARITY-HEALTH-FUND'
      )
      if (
        additionalInformation3.length > 0 &&
        additionalInformation3[0].tariffDifference &&
        additionalInformation3[0].service !== 'Dipreca'
      ) {
        return 'Certificado de deuda'
      }
    }

    return 'Cotizaciones recibidas'
  }

  const getTitleInvoice = () => {
    const additionalInformation = program?.additionalInformation.filter(
      (item) =>
        item.key === 'INFORM-THE-PAYMENT-MADE-MULTI-SOLIDARITY-HEALTH-FUND'
    )
    if (additionalInformation.length > 0) {
      return 'Certificado de pago enviado por Dipreca'
    }
    return 'Factura'
  }

  const getTitleBoxPurchase = () => {
    const { additionalInformation } = program

    if (program?.type === 'SolidarityHealthFund') {
      const preparationCommission = additionalInformation.filter(
        (item) => item.key === 'COMMISSION-PREPARATION-SOLIDARITY-HEALTH-FUND'
      )

      if (preparationCommission.length > 0) {
        const search = additionalInformation.filter(
          (item) => item.key === 'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND'
        )
        if (search) {
          if (
            (preparationCommission[0]?.tariffDifference &&
              preparationCommission[0]?.service === 'Dipreca') ||
            preparationCommission[0]?.others
          ) {
            return 'Cuadro Dipreca'
          }
          return 'Cuadro de compra'
        }
      }
    }

    return 'Cuadro de compra'
  }

  const renderTittleTab = (key) => {
    if (key?.includes('EDIT')) {
      return '(Corregidos)'
    }
    if (key?.includes('CORRECT-REQUEST')) {
      return '(Subsanado)'
    }
    return ''
  }

  const validateDocumentsInProperty = (record) => {
    const result = []
    Object.keys(record).forEach((key) => {
      if (documentsKey.includes(key)) {
        if (record[key] && record[key]?.length > 0) {
          result.push(key)
        }
      }
    })

    return Boolean(result.length > 0)
  }

  useEffect(() => {
    const stepFiltered = program?.additionalInformation.filter(
      (item) =>
        item.key === keyToSearch.one ||
        item.key === keyToSearch.two ||
        item.key === keyToSearch.three ||
        item.key === keyToSearch.four ||
        item.key === keyToSearch.five
    )

    // stepFiltered = stepFiltered.map((item) => {
    //   if (validateDocumentsInProperty(item)) {
    //     return item
    //   }
    //   return null
    // })

    // stepFiltered = stepFiltered.filter((item) => item !== null)

    const lastNumberStep = Math.max(
      ...(stepFiltered?.map((item) => item.stepProgram) || [])
    )

    if (stepFiltered.length > 0) {
      const lastStep = program?.additionalInformation.filter(
        (item) => item.stepProgram === lastNumberStep
      )

      setData(lastStep.at(0))
    }
  }, [program])

  return (
    <Flex direction={'column'} gap={2}>
      {Object.keys(data).length > 0 ? (
        <SectionAccordion
          title={`Información a evaluar ${renderTittleTab(data?.key)}`}
        >
          <Flex
            direction={'column'}
            justify={'center'}
            gap={1}
            border={'1px solid #82b378'}
            borderRadius={8}
            p={2}
          >
            {data?.voucher?.length > 0 && (
              <RenderDocument
                titleDocument={'Comprobante de depósito'}
                arrayDocuments={data?.voucher}
              />
            )}
            {data?.stockOffice?.length > 0 && (
              <RenderDocument
                titleDocument={'Oficio inventario'}
                arrayDocuments={data?.stockOffice}
              />
            )}
            {data?.deliveryProof?.length > 0 && (
              <RenderDocument
                titleDocument={'Cuadro demostrativo de entrega'}
                arrayDocuments={data?.deliveryProof}
              />
            )}
            {data?.documents?.length > 0 && (
              <RenderDocument
                titleDocument={titleDocument || 'Documento adjunto'}
                arrayDocuments={data?.documents}
              />
            )}
            {data?.classSchedule?.length > 0 && (
              <RenderDocument
                titleDocument={'Calendario de clases'}
                arrayDocuments={data?.classSchedule}
              />
            )}
            {data?.feeBill?.length > 0 && (
              <RenderDocument
                titleDocument={'Boleta de honorarios'}
                arrayDocuments={data?.feeBill}
              />
            )}
            {data?.attendanceSheet?.length > 0 && (
              <RenderDocument
                titleDocument={'Planilla de asistencia'}
                arrayDocuments={data?.attendanceSheet}
              />
            )}
            {data?.instructive?.length > 0 && (
              <RenderDocument
                titleDocument={'Instructivo de rendición'}
                arrayDocuments={data?.instructive}
              />
            )}
            {data?.amountCancel?.length > 0 && (
              <RenderDocument
                titleDocument={'Documento de monto a cancelar'}
                arrayDocuments={data?.amountCancel}
              />
            )}
            {data?.purchaseDetail?.length > 0 && (
              <RenderDocument
                titleDocument={'Documento de detalle de compra'}
                arrayDocuments={data?.purchaseDetail}
              />
            )}
            {data?.backupFeeAmount?.length > 0 && (
              <RenderDocument
                titleDocument={'Correo de respaldo con el monto de arancel'}
                arrayDocuments={data?.backupFeeAmount}
              />
            )}
            {data?.scholarshipCommission?.length > 0 && (
              <RenderDocument
                titleDocument={'Acta de comisión de becas'}
                arrayDocuments={data?.scholarshipCommission}
              />
            )}
            {data?.bankData?.length > 0 && (
              <RenderDocument
                titleDocument={'Documento con los datos bancarios'}
                arrayDocuments={data?.bankData}
              />
            )}
            {data?.office?.length > 0 && (
              <RenderDocument
                titleDocument={'Oficio'}
                arrayDocuments={data?.office}
              />
            )}
            {data?.invoice?.length > 0 && (
              <RenderDocument
                titleDocument={getTitleInvoice()}
                arrayDocuments={data?.invoice}
              />
            )}
            {data?.acta?.length > 0 && (
              <RenderDocument
                titleDocument={'Acta de comisión'}
                arrayDocuments={data?.acta}
              />
            )}
            {data?.boxPurchase?.length > 0 && (
              <RenderDocument
                titleDocument={getTitleBoxPurchase()}
                arrayDocuments={data?.boxPurchase}
              />
            )}
            {data?.boxPurchaseTwo?.length > 0 && (
              <RenderDocument
                titleDocument={'Cuadros de compra'}
                arrayDocuments={data?.boxPurchaseTwo}
              />
            )}
            {data?.purchaseRequestLetter?.length > 0 && (
              <RenderDocument
                titleDocument={'Oficio de solicitud de orden de compra'}
                arrayDocuments={data?.purchaseRequestLetter}
              />
            )}
            {data?.quotesReceived?.length > 0 && (
              <RenderDocument
                titleDocument={getTitleQuotesReceived()}
                arrayDocuments={data?.quotesReceived}
              />
            )}
            {data?.purchaseRequestLetterSigned?.length > 0 && (
              <RenderDocument
                titleDocument={'Oficio de solicitud de orden de compra firmada'}
                arrayDocuments={data?.purchaseRequestLetterSigned}
              />
            )}
            {data?.documentsSentMedicalInstitution?.length > 0 && (
              <RenderDocument
                titleDocument={'Certificados de deuda enviado'}
                arrayDocuments={data?.documentsSentMedicalInstitution}
              />
            )}
            {data?.debtCertificate?.length > 0 && (
              <RenderDocument
                titleDocument={'Certificados de deudas'}
                arrayDocuments={data?.debtCertificate}
              />
            )}
            {data?.descriptions && (
              <TextAreaComtrol
                label={'Observaciones'}
                value={data?.descriptions}
                readOnly={true}
                isRequired={false}
              />
            )}
          </Flex>
        </SectionAccordion>
      ) : (
        <SectionAccordion
          title={`Información a evaluar ${renderTittleTab(data?.key)}`}
        >
          Sin información para mostrar
        </SectionAccordion>
      )}
    </Flex>
  )
}

export default RenderPreviewPastStep
