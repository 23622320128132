import { createIcon } from '@chakra-ui/react'
/* https://freeicons.io/ui-essentials-3/ui-essentials-notification-alarm-bell-icon-44563 */
/* eslint-disable import/prefer-default-export */
const UsersIcon = createIcon({
  displayName: 'UsersIcon',
  viewBox: '0 0 22 22',
  path: [
    <path
      d="M8.14313 10.9466C5.60192 10.9318 3.6449 8.91381 3.66681 6.34137C3.69602 3.86503 5.76257 1.81003 8.20155 1.83221C10.677 1.85439 12.6706 3.8872 12.6706 6.39312C12.6706 8.96556 10.6916 10.9614 8.14313 10.9466ZM10.2243 6.38572C10.2097 5.28431 9.26039 4.31594 8.18694 4.30855C7.08429 4.30116 6.09848 5.30648 6.10578 6.43008C6.11309 7.56846 7.08429 8.49986 8.23076 8.47029C9.34801 8.44072 10.2389 7.50932 10.2243 6.37833V6.38572Z"
      fill="#82B378"
    />,
    <path
      d="M17.5996 6.45225C17.585 8.47768 16.2998 10.2148 14.3939 10.7692C13.6418 10.991 13.043 10.7249 12.7874 10.0596C12.5318 9.3943 12.8093 8.72901 13.5395 8.47029C14.5181 8.12286 15.1388 7.49454 15.1461 6.42269C15.1461 5.35084 14.54 4.72251 13.5541 4.36769C12.8239 4.10897 12.5391 3.44368 12.7801 2.77839C13.0211 2.11311 13.6199 1.83221 14.3866 2.03919C16.256 2.53446 17.6069 4.39726 17.5923 6.45225H17.5996Z"
      fill="#82B378"
    />,
    <path
      d="M19.2427 17.9477C19.2427 18.2212 19.2427 18.5021 19.2427 18.7757C19.2134 19.7144 18.7826 20.1875 17.994 20.1654C17.2491 20.1432 16.8256 19.6627 16.811 18.7904C16.8037 18.3469 16.811 17.9034 16.811 17.4673C16.6641 16.2492 16.2331 15.8138 15.3359 15.3236C14.4961 14.9983 14.1675 14.37 14.4377 13.6751C14.7079 12.9729 15.3505 12.7363 16.2049 13.0246C18.213 13.6973 19.25 15.3679 19.25 17.9625L19.2427 17.9477Z"
      fill="#82B378"
    />,
    <path
      d="M7.81082 11.9329C4.3927 12.2507 1.78858 15.2216 1.83396 18.7514V18.9305C1.83396 19.6139 2.3726 20.1679 3.03703 20.1679C3.70146 20.1679 4.2401 19.6139 4.2401 18.9305V18.7019C4.20393 16.5346 5.7589 14.6864 7.84932 14.412C10.0559 14.187 12.0222 15.8445 12.241 18.1142C12.2539 18.2484 12.2605 18.3831 12.2606 18.518V18.9305C12.2606 19.6139 12.7992 20.168 13.4636 20.168C14.1281 20.168 14.6667 19.6139 14.6667 18.9305V18.518C14.6628 14.8689 11.7837 11.914 8.23603 11.918C8.09414 11.9181 7.95233 11.9231 7.81082 11.9329Z"
      fill="#82B378"
    />
  ]
})

export default UsersIcon
