import React from 'react'
import { Notifications } from 'react-push-notification'
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import { BrowserRouter as Router } from 'react-router-dom'
/* redux */
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import store from './store'

import { SocketContext, socket } from './services/socket'

import RenderRoutes from './routes/RenderRoutes'
import { light } from './themes'
import './themes/styles.css'

const persistor = persistStore(store)
function App() {
  return (
    <SocketContext.Provider value={socket}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ChakraProvider theme={light}>
            <ColorModeScript initialColorMode={light.config.initialColorMode} />
            <Router>
              <RenderRoutes style={{ zIndex: 1000 }} />
            </Router>
          </ChakraProvider>
        </PersistGate>
      </Provider>
    </SocketContext.Provider>
  )
}

export default App
