import React from 'react'
import { Flex, Text } from '@chakra-ui/react'

const CardStatus = ({
  value,
  subValue = null,
  bg = 'green.50',
  color = 'green.500',
  py = 2,
  px = 8,
  opacity = 1
}) => (
  <Flex
    direction={'column'}
    align={'center'}
    justify={'center'}
    textAlign={'center'}
    bg={bg}
    color={color}
    borderRadius={12}
    py={py}
    px={px}
    opacity={opacity}
  >
    {subValue && <Text fontSize={14}>{subValue}</Text>}
    <Text fontWeight={700} fontSize={18}>
      {value}
    </Text>
  </Flex>
)

export default CardStatus
