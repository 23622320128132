import React from 'react'
import { Grid, GridItem } from '@chakra-ui/react'
import { InputComtrol } from '../../UI'
import { FormatDate } from '../../../Utils'

const AccommodationInformation = ({ program }) => (
  <>
    <Grid
      templateColumns={{
        lg: 'repeat(2, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem>
        <InputComtrol
          label={'Fecha inicial de ingreso'}
          isRequired={false}
          value={FormatDate(program?.incomeDate)}
          readOnly={true}
        />
      </GridItem>
      <GridItem>
        <InputComtrol
          label={'Fecha inicial de salida'}
          isRequired={false}
          value={FormatDate(program?.exitDate)}
          readOnly={true}
        />
      </GridItem>
    </Grid>
    {/* --- */}
    {program?.isRequiredExtension && (
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
        <GridItem>
          <InputComtrol
            label={'Fecha final de salida'}
            isRequired={false}
            value={FormatDate(program?.extensionDate)}
            readOnly={true}
          />
        </GridItem>
      </Grid>
    )}
    {/* --- */}
    <Grid
      templateColumns={{
        lg: 'repeat(2, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem>
        <InputComtrol
          label={'Zona de postulación'}
          value={program?.zone}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
      <GridItem>
        <InputComtrol
          label={'Alta repartición / Repartición'}
          value={program?.prefecture}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
    </Grid>
    <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
      <GridItem>
        <InputComtrol
          label={'Unidad'}
          isRequired={false}
          value={program?.unit}
          readOnly={true}
        />
      </GridItem>
    </Grid>
    {/* --- */}
    <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
      <GridItem>
        <InputComtrol
          label={'Especialidad Médica'}
          isRequired={false}
          value={program?.medicalSpeciality}
          readOnly={true}
        />
      </GridItem>
    </Grid>
  </>
)

export default AccommodationInformation
