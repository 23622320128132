import React from 'react'
import { Flex, Icon, useDisclosure } from '@chakra-ui/react'
import { Alert, CustomModal } from '../../UI'
import { DetailsIcon } from '../../Icons'
import {
  HospitalAccommodation,
  NewbornBenefit,
  PreUniversityScholarships,
  ScholarshipsApplicantOfficer,
  ScholarshipsApplicantOfficerDac,
  SolidarityHealthFund,
  SupportInCaseOfDeathOfMember,
  TechnicalProfessionalScholarships,
  UniversityScholarships,
  WorkshopsAndTraining
} from './DetailsByProgram'

const DetailsModal = ({ currentProgram = null }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const programDetailsComponentsDict = {
    InjuredInLinesOfServiceKit: null,
    HospitalAccommodation: (
      <HospitalAccommodation currentProgram={currentProgram} />
    ),
    SolidarityHealthFund: (
      <SolidarityHealthFund currentProgram={currentProgram} />
    ),
    FireSupport: null,
    DisasterSupport: null,
    SupportInCaseOfDeathOfMember: (
      <SupportInCaseOfDeathOfMember currentProgram={currentProgram} />
    ),
    NewbornBenefit: <NewbornBenefit currentProgram={currentProgram} />,
    TechnicalProfessionalScholarships: (
      <TechnicalProfessionalScholarships currentProgram={currentProgram} />
    ),
    ScholarshipsApplicantOfficer: (
      <ScholarshipsApplicantOfficer currentProgram={currentProgram} />
    ),
    ScholarshipsApplicantOfficerDac: (
      <ScholarshipsApplicantOfficerDac currentProgram={currentProgram} />
    ),
    UniversityScholarships: (
      <UniversityScholarships currentProgram={currentProgram} />
    ),
    WorkshopsAndTraining: (
      <WorkshopsAndTraining currentProgram={currentProgram} />
    ),
    PreUniversityScholarships: (
      <PreUniversityScholarships currentProgram={currentProgram} />
    )
  }
  return (
    <>
      {programDetailsComponentsDict[currentProgram?.type] && (
        <Icon
          fontSize="26"
          as={DetailsIcon}
          _hover={{ cursor: 'pointer' }}
          onClick={onOpen}
        />
      )}
      {isOpen && (
        <CustomModal
          title={`Detalles ${currentProgram && currentProgram?.programName}`}
          size="4xl"
          showButtons={false}
          isOpen={isOpen}
          onClose={onClose}
        >
          <>
            {programDetailsComponentsDict[currentProgram?.type] ? (
              programDetailsComponentsDict[currentProgram?.type]
            ) : (
              <Flex w={'100%'} align={'center'} justify={'center'} py={3}>
                <Alert
                  status={'warning'}
                  message={'Error al Cargar los datos'}
                />
              </Flex>
            )}
          </>
        </CustomModal>
      )}
    </>
  )
}

export default DetailsModal
