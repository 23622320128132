const relationshipList = [
  'Madre',
  'Padre',
  'Hijo(a)',
  'Nieto(a)',
  'Cónyuge',
  'Hermano(a)',
  'Socio',
  'Otro'
]

export default relationshipList
