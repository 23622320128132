import Layout from '../components/Layout/Layout'
import Users from '../pages/Users'
import Edit from '../pages/Users/Edit'
import Profile from '../pages/Users/Profile'
/* import Create from '../pages/Partners/Create'
 */

/* --- */
import Can from '../auth/Can'
import Forbidden from '../pages/Forbidden'
import Login from '../pages/Login'

const rootName = 'users'

const usersRoutes = [
  {
    path: `/${rootName}`,
    key: 'USERS',
    exact: true,
    component: ({ authenticated }) => (
      <Can
        availableTo={['ADMINISTRADOR', 'GERENCIA']}
        yes={() => (
          <Layout>
            <Users />
          </Layout>
        )}
        no={() => (authenticated ? <Forbidden /> : <Login />)}
      />
    )
  },
  {
    path: `/${rootName}/profile/:userId`,
    key: 'USERS-PROFILE',
    exact: true,
    component: ({ authenticated }) => (
      <Can
        availableTo={[
          'ADMINISTRADOR',
          'ASISTENTE_SOCIAL',
          'ASESOR_JURIDICO',
          'GERENCIA',
          'PRESIDENCIA',
          'FINANZAS',
          'DEPTO_CONTROL_GESTION_SOCIAL',
          'ADMINISTRATIVE-MULTIPLE-PROGRAMS',
          'ADMINISTRATIVE-HOSPITAL-ACCOMMODATION',
          'ADMINISTRATIVE-INUJRED-IN-LINES-OF-SERVICE-KIT',
          'ADMINISTRATIVE-WORKSHOPS-AND-TRAINING',
          'ADMINISTRATIVE-NEWBORN-BENEFIT',
          'ABASTECIMIENTO'
        ]}
        yes={() => (
          <Layout>
            <Profile />
          </Layout>
        )}
        no={() => (authenticated ? <Forbidden /> : <Login />)}
      />
    )
  },
  {
    path: `/${rootName}/edit/:userId`,
    key: 'USERS-EDIT',
    exact: true,
    component: ({ authenticated }) => (
      <Can
        availableTo={[
          'ADMINISTRADOR',
          'ASISTENTE_SOCIAL',
          'ASESOR_JURIDICO',
          'GERENCIA',
          'PRESIDENCIA',
          'FINANZAS',
          'DEPTO_CONTROL_GESTION_SOCIAL',
          'ADMINISTRATIVE-MULTIPLE-PROGRAMS',
          'ADMINISTRATIVE-HOSPITAL-ACCOMMODATION',
          'ADMINISTRATIVE-INUJRED-IN-LINES-OF-SERVICE-KIT',
          'ADMINISTRATIVE-WORKSHOPS-AND-TRAINING',
          'ADMINISTRATIVE-NEWBORN-BENEFIT',
          'ABASTECIMIENTO'
        ]}
        yes={() => (
          <Layout>
            <Edit />
          </Layout>
        )}
        no={() => (authenticated ? <Forbidden /> : <Login />)}
      />
    )
  }
]
export default usersRoutes
