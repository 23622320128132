import React, { useState, useEffect } from 'react'
import { Grid, GridItem, Tr, Td, Flex } from '@chakra-ui/react'
import { Table, Spinner, Alert } from '../UI'
import { GetUserType } from '../../Utils'
import OptionsMenu from './OptionsMenu'
import { useUsers } from '../../hooks/Users'
import WithoutResults from '../WithoutResults'

const TableUsers = ({ filtersUsers }) => {
  const [page, setPage] = useState(0)
  const limit = 6
  const {
    data: users,
    isLoading,
    isFetching,
    isError
  } = useUsers(filtersUsers, page, limit)
  const headers = [
    '#',
    'Nombres y apellidos',
    'Correo electrónico',
    'Rol',
    'Opciones'
  ]

  useEffect(() => {
    setPage(0)
  }, [filtersUsers])

  return (
    <>
      {users?.currentPages?.length === 0 && (
        <WithoutResults type={'USERS-TABLE'} />
      )}
      {users?.currentPages?.length > 0 && (
        <Grid templateColumns={'repeat(1, 1fr)'} w={'100%'}>
          <Flex w={'100%'} justify={'flex-end'} py={3}>
            <Spinner size={'lg'} show={isLoading || isFetching} />
          </Flex>
          {isError && (
            <Flex w={'100%'} justify={'flex-end'} py={3}>
              <Alert status={'error'} message={'Error al Cargar los Datos'} />
            </Flex>
          )}
          <GridItem
            overflowX={'auto'}
            display={{
              lg: 'table',
              md: 'break-word',
              sm: 'break-word'
            }}
            css={{
              '&::-webkit-scrollbar': {
                width: '4px'
              },
              '&::-webkit-scrollbar-track': {
                width: '6px'
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#2E7423',
                borderRadius: '20px'
              }
            }}
          >
            <Table
              headers={headers}
              totalRecords={users?.totalRecords}
              setPage={setPage}
              page={page}
            >
              {users?.currentPages?.map((user, index) => (
                <Tr key={user._id}>
                  <Td fontWeight={700} color={'grey.500'} textAlign={'center'}>
                    {index + 1 + page * limit}
                  </Td>
                  <Td
                    fontWeight={700}
                    color={'grey.500'}
                  >{`${user?.name} ${user?.lastName}`}</Td>
                  <Td color={'grey.500'}>{user?.email}</Td>
                  <Td color={'grey.500'}>{GetUserType(user?.role)}</Td>
                  <Td textAlign={'center'}>
                    <OptionsMenu user={user} />
                  </Td>
                </Tr>
              ))}
            </Table>
          </GridItem>
        </Grid>
      )}
    </>
  )
}

export default TableUsers
