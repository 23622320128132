import * as Yup from 'yup'
/* eslint-disable import/prefer-default-export */
export const itemSchema = Yup.object().shape({
  isRequiredPartnerInfo: Yup.boolean(),
  partnersEmail: Yup.string().when('isRequiredPartnerInfo', {
    is: true,
    then: Yup.string()
      .email('El correo no es válido')
      .required('El correo es requerido')
  }),
  partnersPhone: Yup.string().when('isRequiredPartnerInfo', {
    is: true,
    then: Yup.string()
      .min(8, 'El número de teléfono debe tener al menos 8 dígitos')
      .max(9, 'El número de teléfono debe tener máximo 9 dígitos')
      .required('El número de teléfono es requerido')
  }),
  /* spouse data */
  isRequiredSpouseInfo: Yup.boolean(),
  spouseFullName: Yup.string().when('isRequiredSpouseInfo', {
    is: true,
    then: Yup.string()
      .max(40, 'El nombre completo debe tener máximo 40 caracteres')
      .required('El nombre completo es requerido')
  }),
  spouseEmail: Yup.string().when('isRequiredSpouseInfo', {
    is: true,
    then: Yup.string()
      .email('El correo no es válido')
      .required('El correo es requerido')
  }),
  spousePhone: Yup.string().when('isRequiredSpouseInfo', {
    is: true,
    then: Yup.string()
      .min(8, 'El número de teléfono debe tener al menos 8 dígitos')
      .max(9, 'El número de teléfono debe tener máximo 9 dígitos')
      .required('El número de teléfono es requerido')
  })
})
