import { Grid, GridItem, Text } from '@chakra-ui/react'
import RenderInfoItem from './RenderInfoItem'
import { NODATA } from '../../../../constans'

const PROGRAM_DESC = 'Información relevante del beneficio'

const SolidarityHealthFund = ({ currentProgram = null }) => {
  const stepData_1 = currentProgram.flowData?.find(
    (sp) => sp.key === 'COMMISSION-PREPARATION-SOLIDARITY-HEALTH-FUND'
  )
  const stepData_2 = currentProgram.flowData?.find(
    (sp) => sp.key === 'ALL-INVOICES-AND-TRADES-SOLIDARITY-HEALTH-FUND'
  )

  const getProcessesNames = () => {
    const names = []
    if (stepData_2) {
      const {
        medicines,
        supplies,
        milkFormula,
        tariffDifference,
        assistanceBenefits,
        others
      } = stepData_2

      if (medicines) {
        names.push('Medicamentos')
      }
      if (supplies) {
        names.push('Insumos')
      }
      if (milkFormula) {
        names.push('Formula lactea')
      }
      if (tariffDifference) {
        names.push('Diferencia de arancel')
      }
      if (assistanceBenefits) {
        names.push('Prestaciones asistenciales')
      }
      if (others) {
        names.push('Othos')
      }
    }
    return names.join(', ')
  }

  return (
    <Grid templateAreas="repeat(1,1fr)" gap={2}>
      <GridItem borderBottom="1px solid" borderColor="green.500" mb={2}>
        <Text fontSize="lg" fontWeight={500}>
          {PROGRAM_DESC}:
        </Text>
      </GridItem>
      <GridItem>
        <RenderInfoItem
          label="Comisión"
          value={stepData_1?.commission || NODATA}
        />
      </GridItem>
      <GridItem>
        <RenderInfoItem
          label="Procesos"
          value={getProcessesNames() || NODATA}
        />
      </GridItem>
      <GridItem>
        <RenderInfoItem
          label="Observaciones"
          value={stepData_2?.descriptions || NODATA}
        />
      </GridItem>
    </Grid>
  )
}
export default SolidarityHealthFund
