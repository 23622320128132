import { createSlice } from '@reduxjs/toolkit'

const initialFiltersPrograms = {
  search: '',
  category: '',
  startDate: '',
  endDate: '',
  state: '',
  program: '',
  step: '',
  typeCreation: 'ALL'
}

export const programsSlice = createSlice({
  name: 'programs',
  initialState: {
    filters: initialFiltersPrograms,
    page: 0
  },
  reducers: {
    updateFilters: (state, action) => {
      const { payload } = action
      state.filters = payload
      state.page = 0
    },
    resetFilters: (state) => {
      state.filters = initialFiltersPrograms
      state.page = 0
    },
    updatePage: (state, action) => {
      state.page = action.payload
    }
  }
})
export const { updateFilters, resetFilters, updatePage } = programsSlice.actions

export default programsSlice.reducer
