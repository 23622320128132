import { createIcon } from '@chakra-ui/react'
/* eslint-disable import/prefer-default-export */
const RejectedIcon = createIcon({
  displayName: 'RejectedIcon',
  viewBox: '0 0 20 20',
  path: [
    <path
      d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM14.707 13.293C15.098 13.684 15.098 14.316 14.707 14.707C14.512 14.902 14.256 15 14 15C13.744 15 13.488 14.902 13.293 14.707L10 11.414L6.707 14.707C6.512 14.902 6.256 15 6 15C5.744 15 5.488 14.902 5.293 14.707C4.902 14.316 4.902 13.684 5.293 13.293L8.586 10L5.293 6.707C4.902 6.316 4.902 5.684 5.293 5.293C5.684 4.902 6.316 4.902 6.707 5.293L10 8.586L13.293 5.293C13.684 4.902 14.316 4.902 14.707 5.293C15.098 5.684 15.098 6.316 14.707 6.707L11.414 10L14.707 13.293Z"
      fill={'currentColor' || 'white'}
    />
  ]
})

export default RejectedIcon
