import React, { useState, useEffect } from 'react'
import { Flex, Text, Tooltip, Avatar } from '@chakra-ui/react'
import { Step, Steps, useSteps } from 'chakra-ui-steps'
import { CustomSlider } from '../UI'
import { ArrowStepIcon } from '../Icons'
import { FormatDate } from '../../Utils'
import CardItemProgram from './CardItemProgram'
import GetStatusCard from '../../Utils/GetStatusCard'

const ProgramHistory = ({ history = [], state, type, program }) => {
  const [currentCard, setCurrentCard] = useState(0)
  const [itemFocus, setItemFocus] = useState(0)
  const [historyStep, setHistoryStep] = useState([])
  const { activeStep } = useSteps({
    initialStep: 0
  })

  const validateHasIncompleteSteps = () => {
    if (!program?.stepsIncomplete.length > 0) {
      return GetStatusCard.program(state)
    }
    return (
      <Flex position={'relative'} display={'block'}>
        {GetStatusCard.program(state)}
        <Tooltip
          hasArrow
          label={'Tienes Pasos Incompletos'}
          bg={'red.500'}
          fontSize={'md'}
          placement={'left'}
        >
          <Avatar
            position={'absolute'}
            top={'-5px'}
            right={'-5px'}
            bg={'red.500'}
            borderRadius={'50%'}
            size={'xs'}
            name={'!'}
          />
        </Tooltip>
      </Flex>
    )
  }

  useEffect(() => {
    if (history.length > 0) {
      // para ordenar el historial por tarea completada
      setHistoryStep(
        [...history].reverse().sort((a, b) => a.complete - b.complete)
      )

      setItemFocus(history.findIndex((item) => item.complete === false))
    }
  }, [history])

  const getColor = (CurrentState) => {
    if (CurrentState?.incompleteStep) {
      return 'blue'
    }

    if (CurrentState === 'APPROVED' || CurrentState === 'COMPLETED-APPROVED')
      return 'green'
    if (
      CurrentState === 'REJECTED' ||
      CurrentState === 'COMPLETED-REJECTED' ||
      CurrentState === 'DELETED'
    )
      return 'red'
    if (CurrentState === 'INREVIEW') return 'orange'
    if (CurrentState === 'INCOMPLETE') return 'grey'

    if (
      CurrentState === 'REVOKED' ||
      CurrentState === 'COMPLETED-REVOKED' ||
      CurrentState === 'DESISTED'
    ) {
      return 'yellow'
    }

    return ''
  }

  const labelSteep = (title, createdBy, updatedBy) => (
    <Flex direction={'column'} justify={'flex-start'} align={'start'}>
      <Tooltip label={title} placement="top-start" bg={'blue.100'}>
        <Text fontSize="md" fontWeight={700} noOfLines={1} textAlign={'left'}>
          {title}
        </Text>
      </Tooltip>
      <Text fontSize="sm" fontWeight={700}>
        {updatedBy
          ? `${updatedBy?.name} ${updatedBy?.lastName}`
          : `${createdBy?.name} ${createdBy?.lastName}`}
      </Text>
    </Flex>
  )

  return (
    <>
      <Flex justify={'space-between'} py={5} px={2}>
        <Text fontWeight={700} fontSize={'2xl'} color={'grey.500'}>
          Estado de solicitud
        </Text>
        {validateHasIncompleteSteps()}
      </Flex>
      <CustomSlider
        selectedItem={itemFocus}
        onChange={(e) => setCurrentCard(e)}
      >
        {history?.map((item, index) => (
          <CardItemProgram
            program={program}
            state={state}
            key={item?.step}
            currentStep={item}
            index={index}
            currentCard={currentCard}
            type={type}
          />
        ))}
      </CustomSlider>
      <Flex direction={'column'} my={3} px={7}>
        <Steps
          activeStep={activeStep}
          orientation="vertical"
          responsive
          mb={{
            lg: 5,
            md: 0
          }}
          colorScheme={getColor(state)}
        >
          {historyStep.map(
            ({
              step,
              title,
              complete,
              createdBy,
              updatedBy,
              updatedAt,
              date,
              key
            }) => (
              <Step
                opacity={complete && 0.4}
                icon={ArrowStepIcon}
                key={step}
                label={labelSteep(title, createdBy, updatedBy)}
                description={FormatDate(updatedAt || date)}
                labelOrientation={'horizontal'}
                isCompletedStep={complete || key === 'FINISHED'}
              ></Step>
            )
          )}
        </Steps>
      </Flex>
    </>
  )
}

export default ProgramHistory
