import moment from 'moment'

// eslint-disable-next-line default-param-last
const getDiff = (dateStr, format = 'default', startDate) => {
  const today = moment(startDate)
  const someday = moment(dateStr)

  const diffYear = today.diff(someday, 'year')
  const diffMonth = today.diff(someday, 'months')
  const diffWeeks = today.diff(someday, 'weeks')
  const diffDays = today.diff(someday, 'days')
  const diffHours = today.diff(someday, 'hours')
  const diffMinutes = today.diff(someday, 'minutes')
  const diffSeconds = today.diff(someday, 'seconds')
  const diffMilliseconds = today.diff(someday, 'milliseconds')

  if (format === 'days') {
    return `${diffDays} día/s`
  }
  if (diffYear > 0 && diffMonth > 0) {
    return `${diffYear} año/s - ${diffMonth % 12} mes/es`
  }
  if (diffYear > 0) {
    return `${diffYear} año/s`
  }
  if (diffMonth > 0) {
    return `${diffMonth} mes/es`
  }
  if (diffWeeks > 0) {
    return `${diffWeeks} semana/s`
  }
  if (diffDays > 0) {
    return `${diffDays} día/s`
  }
  if (diffHours > 0) {
    return `${diffHours} hora/s`
  }
  if (diffMinutes > 0) {
    return `${diffMinutes} minuto/s`
  }
  if (diffSeconds > 0) {
    return `${diffSeconds} segundo/s`
  }
  if (diffMilliseconds > 0) {
    return `${diffMilliseconds} milisegundo/s`
  }
  return dateStr
}

export default getDiff
