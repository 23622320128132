import React from 'react'
import { Flex } from '@chakra-ui/react'
import { BackButton, Heading } from '../../../components/UI'
import FormCreatePartners from '../../../components/FormCreatePartners'

/* eslint arrow-body-style: ["error", "always"] */
const Create = () => {
  return (
    <Flex direction={'column'} px={10}>
      <BackButton path={'/partners'} />
      <Heading title={'Agregar Nuevo Socio'} />
      <FormCreatePartners type={'CREATE'} />
    </Flex>
  )
}

export default Create
