import * as Yup from 'yup'
/* eslint-disable import/prefer-default-export */
export const schema = Yup.object().shape({
  isNot: Yup.boolean(),
  isYes: Yup.boolean(),
  isRequiredDescription: Yup.boolean(),
  descriptions: Yup.string().when('isRequiredDescription', {
    is: true,
    then: Yup.string().required('El campo es requerido')
  }),
  isRequiredDocuments: Yup.boolean(),
  documentsNames: Yup.array().when('isRequiredDocuments', {
    is: true,
    then: Yup.array().required('El campo es requerido')
  })
})
