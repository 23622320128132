import { createIcon } from '@chakra-ui/react'
/* https://freeicons.io/ui-essentials-3/ui-essentials-notification-alarm-bell-icon-44563 */
/* eslint-disable import/prefer-default-export */
const CircleIcon = createIcon({
  displayName: 'CircleIcon',
  viewBox: '0 0 13 12',
  path: [<circle cx="6.5" cy="6" r="6" fill="lightgrey" />]
})

export default CircleIcon
