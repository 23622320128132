/* eslint-disable import/prefer-default-export */
import axios from '../Axios'

const module = '/dashboard'

export const getDataToDashboard = async (filtersPrograms) => {
  const { page, limit, category, types } = filtersPrograms
  const { data } = await axios.get(
    `${module}/?page=${page}&limit=${limit}&category=${category}&types=${types}`
  )
  return data
}
