import React from 'react'
import { Grid, GridItem } from '@chakra-ui/react'
import { InputComtrol } from '../../UI'
import { FormatDate } from '../../../Utils'

const PartnerInformation = ({ program }) => (
  <>
    <Grid
      templateColumns={{
        lg: 'repeat(2, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem>
        <InputComtrol
          label={'Fecha de registro de solicitud'}
          value={FormatDate(program?.createdAt)}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Rut del socio'}
          value={program?.partner.rut}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Telefono'}
          addictionType={'phone'}
          value={program?.partner.phone}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Parentesco'}
          value={program?.relationship}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
      <GridItem>
        <InputComtrol
          label={'Fecha de recepción de solicitud'}
          value={FormatDate(program?.receptionDate)}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Grado del socio'}
          value={program?.partner.grade}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Teléfono alternativo'}
          addictionType={'phone'}
          value={program?.alternativePhone || 'No disponible'}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
    </Grid>
    <Grid
      templateColumns={{
        lg: 'repeat(2, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem>
        <InputComtrol
          label={'Zona de postulación'}
          value={program?.zone}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
      <GridItem>
        <InputComtrol
          label={'Alta repartición / Repartición'}
          value={program?.prefecture}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
    </Grid>
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem>
        <InputComtrol
          label={'Unidad'}
          value={program?.unit}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
    </Grid>
  </>
)

export default PartnerInformation
