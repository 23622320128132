import React, { useState } from 'react'
import { Flex, Grid, GridItem, Icon, Text, useToast } from '@chakra-ui/react'
import {
  useReportability,
  useMutateReportability
} from '../../../hooks/Reportability'
import Filters from './Filters'
import Metrics from './Metrics'
import { Button, CustomToast, Spinner } from '../../UI'
import { ProgramsList } from '../../../Utils'
import { DownloadIcon } from '../../Icons'
import ResolutionOfRequestsSkeleton from '../../SkeletonToPage/ResolutionOfRequestsSkeleton'

const initialFilters = {
  grade: 'TODOS',
  year: new Date().getFullYear(),
  zone: 'TODOS',
  type: 'TODOS'
}

const AmountApprovedPerBenefit = () => {
  const toast = useToast()
  const [filters, setFilters] = useState(initialFilters)
  const {
    data,
    isLoading: isLoadingData,
    isFetching: isFetchingData
  } = useReportability('AMOUNT-APPROVED-PER-GRADE-YEAR-ZONE-TYPE', filters)

  const {
    mutate,
    reset,
    isLoading: isLoadingExport
  } = useMutateReportability(
    'GENERATE-AMOUNT-APPROVED-PER-GRADE-YEAR-ZONE-TYPE'
  )

  const downloadDataExcel = () => {
    toast({
      duration: 9000 * 9000,
      isClosable: true,
      position: 'bottom-right',
      render: ({ onClose }) => (
        <CustomToast
          text={'Espere por favor...'}
          status={'info'}
          onClose={onClose}
        />
      )
    })
    mutate(data, {
      onSuccess: () => {
        toast.closeAll()
        reset()
        toast({
          duration: 5000,
          isClosable: true,
          position: 'bottom-right',
          render: ({ onClose }) => (
            <CustomToast
              text={'Descarga exitosa'}
              status={'success'}
              showSpinner={false}
              onClose={onClose}
            />
          )
        })
      },
      onError: (err) => {
        toast.closeAll()
        reset()
        toast({
          duration: 5000,
          isClosable: true,
          position: 'bottom-right',
          render: ({ onClose }) => (
            <CustomToast
              text={'Error al descargar reporte'}
              status={'error'}
              showSpinner={false}
              onClose={onClose}
            />
          )
        })
        console.log(err)
      }
    })
  }

  return (
    <Grid templateColumns={'repeat(1, minmax(0, 1fr))'} gap={2}>
      <GridItem>
        <Text
          fontSize={'2xl'}
          color={'green.500'}
          fontWeight={700}
          noOfLines={1}
        >
          Montos aprobados por beneficio y grado
        </Text>
      </GridItem>
      <GridItem>
        <Filters setFilters={setFilters} isLoadingData={isLoadingData} />
      </GridItem>
      {/* --- */}
      <GridItem>
        <Flex
          direction={{
            lg: 'row',
            md: 'column',
            sm: 'column'
          }}
          justify={'space-between'}
          gap={2}
        >
          <Flex
            gap={2}
            w={{
              lg: '50%',
              md: '100%',
              sm: '100%'
            }}
          >
            <Flex
              borderRadius={8}
              bg={'green.50'}
              p={2}
              w={'50%'}
              justify={'center'}
              align={'center'}
            >
              {!isLoadingData && !isFetchingData ? (
                <Text
                  fontSize={'xl'}
                  color={'green.500'}
                  fontWeight={700}
                  noOfLines={1}
                >
                  {ProgramsList.find((p) => p.key === data?.type)?.name ||
                    data?.type}
                </Text>
              ) : (
                <Spinner size={'md'} show={true} />
              )}
            </Flex>
            <Flex
              borderRadius={8}
              bg={'green.50'}
              p={2}
              w={'50%'}
              justify={'center'}
              align={'center'}
            >
              {!isLoadingData && !isFetchingData ? (
                <Text
                  fontSize={'xl'}
                  color={'green.500'}
                  fontWeight={700}
                  noOfLines={1}
                >
                  {data?.year}
                </Text>
              ) : (
                <Spinner size={'md'} show={true} />
              )}
            </Flex>
          </Flex>
          <Button
            text={'Descargar datos'}
            rightIcon={<Icon as={DownloadIcon} color={'white'} />}
            disabled={isLoadingData || isLoadingData || isLoadingExport}
            isLoading={isLoadingExport}
            onClick={() => downloadDataExcel()}
          />
        </Flex>
      </GridItem>
      {/* --- */}
      <GridItem>
        {!isLoadingData && !isFetchingData ? (
          <Metrics data={data} />
        ) : (
          <ResolutionOfRequestsSkeleton />
        )}
      </GridItem>
    </Grid>
  )
}

export default AmountApprovedPerBenefit
