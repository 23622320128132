import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Menu as MenuCha,
  MenuButton,
  MenuList,
  MenuItem,
  Icon
} from '@chakra-ui/react'
import { OptionsIcons } from '../Icons'

const OptionsMenu = ({ program }) => {
  const history = useHistory()
  const optionsMenu = [
    {
      text: 'Solicita Programa',
      function: () => history.push(`/programs/create/${program?.partner?._id}`)
    },
    {
      text: 'Ver Perfil',
      function: () =>
        history.push(
          `/partners/profile/${program?.partner?._id}/Perfil de Socio`
        )
    },
    {
      text: 'Histórico de Programas',
      function: () =>
        history.push(
          `/partners/profile/${program?.partner?._id}/Histórico de Programas`
        )
    },
    {
      text: 'Historial de descuentos',
      function: () =>
        history.push(`/partners/profile/${program?.partner?._id}/Descuentos`)
    }
  ]

  return (
    <MenuCha isLazy>
      <MenuButton>
        <Icon fontSize="26" as={OptionsIcons} />
      </MenuButton>
      <MenuList borderRadius={20} py={8} px={3} color={'green.500'}>
        {optionsMenu.map((item) => (
          <MenuItem
            key={item}
            _hover={{ bg: 'green.50' }}
            onClick={item?.function}
          >
            {item?.text}
          </MenuItem>
        ))}
      </MenuList>
    </MenuCha>
  )
}

export default OptionsMenu
