import { React, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Grid, GridItem, Flex, useToast } from '@chakra-ui/react'
import { Heading, Button } from '../../components/UI'
import { AddUserIcon, CheckUserIcon } from '../../components/Icons'
import TablePartners from '../../components/TablePartners'
import FiltersPartners from '../../components/FiltersPartners'

const Partners = () => {
  const toast = useToast()

  /* table */
  const initialFiltersPartners = {
    search: '',
    startDate: '',
    endDate: '',
    grade: 'Todos los Grados',
    state: '',
    from: 'PRIMARY-LIST'
  }
  const [filtersPartners, setFiltersPartners] = useState(initialFiltersPartners)
  /* table */

  useEffect(() => {
    toast.closeAll()
  }, [])

  return (
    <>
      <Flex direction={'column'} px={10}>
        <Heading title={'SOCIOS'} />

        <Grid templateColumns={'repeat(1, 1fr)'} py={10}>
          <GridItem borderBottom={'1px solid #2E7423'} pb={4}>
            <FiltersPartners
              filtersPartners={filtersPartners}
              setFiltersPartners={setFiltersPartners}
              initialFiltersPartners={initialFiltersPartners}
            >
              <Flex
                direction={{
                  lg: 'row',
                  md: 'column',
                  sm: 'column'
                }}
                gap={{
                  lg: 0,
                  md: 0,
                  sm: 2
                }}
              >
                <Link to={'/partners/create'}>
                  <Button text={'Nuevo Socio'} icon={AddUserIcon} />
                </Link>
                <Link to={'/partners/upload'}>
                  <Button
                    text={'Actualización de socios'}
                    icon={CheckUserIcon}
                  />
                </Link>
              </Flex>
            </FiltersPartners>
          </GridItem>
          <GridItem>
            <TablePartners filtersPartners={filtersPartners} />
          </GridItem>
        </Grid>
      </Flex>
    </>
  )
}

export default Partners
