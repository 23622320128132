import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import { Flex, useDisclosure } from '@chakra-ui/react'
import { Button } from '../../../UI'
import ModalHistory from '../../ModalHistory'
import RadioSelect from './RadioSelect'
import ExtensionForm from './ExtensionForm'
import ExitForm from './ExitForm'
import { schema } from './Schema'
import {
  GetFormData,
  GetKey,
  GetNotification,
  Schedule
} from '../../../../Utils'
import { useMutateHistoryProgram } from '../../../../hooks/HistoryProgram'

const textLeftButton = {
  1: 'Cancelar',
  2: 'Regresar'
}

const textRightButton = {
  1: 'Continuar',
  2: 'Aceptar'
}

const titleModal = {
  1: '¿Cómo desea continuar con el proceso de solicitud?',
  2: 'Solicitud de extensión de hospedaje',
  3: 'Proceso de salida'
}

const ExtensionOrExit = ({ currentStep, getColor, program }) => {
  const { mutate, isLoading, reset } =
    useMutateHistoryProgram('OTHER-PROCESSES')
  const [flowStep, setFlowStep] = useState(1)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const initialValues = {
    isExtension: true,
    isExit: false,
    isRequiredExtensionDays: true,
    extensionDays: '',
    totalDays: undefined,
    newExitDate: '',
    isRequiredDescription: true,
    descriptions: '',
    isRequiredDocuments: true,
    documentsNames: [],
    documents: [],
    exitHour: Schedule.hours.at(0),
    exitMinute: Schedule.minutes.at(0),
    exitPeriod: Schedule.periods.at(0),
    state: program?.state,
    program: program?._id,
    stepProgram: currentStep?.step,
    keyHistory:
      GetKey.getNextStep(currentStep?.key, program?.type) ||
      GetKey.PostChangeStatus[program.type] /* es el siguiente paso */
  }

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    validateOnChange: false,
    onSubmit: (values) => {
      const formData = GetFormData(values, ['documents'])
      mutate(formData, {
        onSuccess: () => {
          reset()
          formik.resetForm()
          onClose()
          GetNotification.basic(
            values.isExtension
              ? 'La petición de extensión fue enviada con éxito'
              : 'La salida fue enviada con éxito',
            'Aceptar'
          )
        },
        onError: (err) => {
          console.log(err)
          GetNotification.basic(
            'Error',
            'Aceptar',
            values.isExtension
              ? 'Error al enviar petición de extensión'
              : 'Error al registrar salida',
            'error'
          )
        }
      })
    }
  })

  const NextModalStep = () => {
    if (flowStep === 1) {
      setFlowStep(flowStep + 1)
    }

    if (flowStep === 2) {
      formik.handleSubmit()
    }

    if (flowStep < 2) {
      setFlowStep(flowStep + 1)
    }
  }

  const backModalStep = () => {
    if (flowStep === 1) {
      onClose()
    }

    if (flowStep > 1) {
      setFlowStep(flowStep - 1)
    }
  }

  const renderTitleModal = () => {
    if (flowStep === 1) {
      return titleModal[flowStep]
    }
    if (flowStep === 2 && formik.values.isExtension) {
      return titleModal[flowStep]
    }
    if (flowStep === 2 && formik.values.isExit) {
      return titleModal[flowStep + 1]
    }
    return ''
  }

  useEffect(() => {
    if (isOpen) {
      setFlowStep(1)

      formik.setValues({
        ...initialValues,
        isRequiredDescription: Boolean(
          currentStep.key === 'FIRST-EXTENSION-OR-OUTLET-HOSPITAL-ACCOMMODATION'
        ),
        isRequiredDocuments: Boolean(
          currentStep.key === 'FIRST-EXTENSION-OR-OUTLET-HOSPITAL-ACCOMMODATION'
        )
      })
    }
  }, [isOpen])

  return (
    <ModalHistory
      title={renderTitleModal()}
      colors={getColor}
      disable={currentStep?.complete || isLoading}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      size={'4xl'}
      showButtons={false}
      isLoading={isLoading}
    >
      {flowStep === 1 && (
        <RadioSelect formik={formik} initialValues={initialValues} />
      )}
      {flowStep > 1 && formik.values.isExtension && (
        <ExtensionForm
          formik={formik}
          program={program}
          currentStep={currentStep}
        />
      )}
      {flowStep > 1 && formik.values.isExit && (
        <ExitForm formik={formik} program={program} />
      )}
      {/* --- */}
      <Flex align={'center'} justify={'center'} gap={2}>
        <Button
          text={textLeftButton[flowStep]}
          bg={'beige.500'}
          color={'green.500'}
          bghover={'beige.600'}
          onClick={backModalStep}
          disabled={isLoading}
        />
        <Button
          text={textRightButton[flowStep]}
          bg={'green.500'}
          onClick={NextModalStep}
          isLoading={isLoading}
        />
      </Flex>
    </ModalHistory>
  )
}

export default ExtensionOrExit
