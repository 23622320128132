import React from 'react'
import DetailsNotifyRegistration from './DetailsNotifyRegistration'
import DetailsAdditionalInformation from './DetailsAdditionalInformation'

const ActionStepEdit = ({ currentStep, program, isOpen, onClose }) => {
  const discActionEdit = {
    'NOTIFY-REGISTRATION': (
      <DetailsNotifyRegistration
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'NOTIFY-NEW-STATUS': (
      <DetailsNotifyRegistration
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'NOTIFY-NEW-STATUS-PHONE': (
      <DetailsNotifyRegistration
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'NOTIFY-REJECTED-STATUS-PHONE-SOLIDARITY-HEALTH-FUND': (
      <DetailsNotifyRegistration
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'EVALUATION-CONTROL-SOCIAL-MANAGEMENT': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'EVALUATION-CONTROL-SOCIAL-MANAGEMENT-NEWBORN-BENEFIT': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'EVALUATION-CONTROL-SOCIAL-MANAGEMENT-DEATH-OF-MEMBER': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'GIFTCARD-DENOMINATION-REQUEST-NEWBORN-BENEFIT': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'RE-UPLOAD-DENOMINATION-REQUEST-NEWBORN-BENEFIT': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'GIFTCARD-DELIVERY-NEWBORN-BENEFIT': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'GIFTCARD-RECEPTION-NEWBORN-BENEFIT': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'GIFTCARD-ACTIVATION-NEWBORN-BENEFIT': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'STOCK-RECIEVED-NEWBORN-BENEFIT': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'REQUEST-PAYMENT-ONE-DEATH-OF-MEMBER': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'REQUEST-PAYMENT-ONE-EDIT-DEATH-OF-MEMBER': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'VERIFICATION-REQUEST-PAYMENT-ONE-DEATH-OF-MEMBER': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'AUTHORIZE-REQUEST-PAYMENT-ONE-DEATH-OF-MEMBER': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'AUTHORIZE-REQUEST-PAYMENT-TWO-DEATH-OF-MEMBER': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'PAYMENT-ONE-MADE-DEATH-OF-MEMBER': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'REQUEST-PAYMENT-TWO-DEATH-OF-MEMBER': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'REQUEST-PAYMENT-TWO-EDIT-DEATH-OF-MEMBER': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'VERIFICATION-REQUEST-PAYMENT-TWO-DEATH-OF-MEMBER': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'PAYMENT-TWO-MADE-DEATH-OF-MEMBER': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'END-PROCESS': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'COMMISSION-PREPARATION': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'COMMISSION-PREPARATION-APPLICANT-OFFICER': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'COMMISSION-PREPARATION-APPLICANT-OFFICER-DAC': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'COMMISSION-PREPARATION-PRE-UNIVERSITY': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'COMMISSION-PREPARATION-TECHNICAL-PROFESSIONAL': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'COMMISSION-PREPARATION-TECHNICAL-PROFESSIONAL-NEWS-SEMESTERS': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'EVALUATION-COMMISSION-SOLIDARITY-HEALTH-FUND': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'EVALUATION-COMMISSION-UNIVERSITY': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'EVALUATION-COMMISSION-APPLICANT-OFFICER': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'EVALUATION-COMMISSION-APPLICANT-OFFICER-DAC': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'EVALUATION-COMMISSION-TECHNICAL-PROFESSIONAL': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'EVALUATION-COMMISSION-NEW-SEMESTERS': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'EVALUATION-COMMISSION-PRE-UNIVERSITY': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'INFORM-PRE-UNIVERSITY-AND-REQUEST-PAYMENT-PRE-UNIVERSITY': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'PURCHASE-ORDER-REQUEST': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'PURCHASE-ORDER-REQUEST-PRE-UNIVERSITY': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'PURCHASE-ORDER-REQUEST-EDIT': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'PURCHASE-ORDER-REQUEST-EDIT-PRE-UNIVERSITY': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'VERIFICATION-PURCHASE-ORDER-REQUEST': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'VERIFICATION-PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'VERIFICATION-PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER-DAC': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'VERIFICATION-PURCHASE-ORDER-REQUEST-TECHNICAL-PROFESSIONAL': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'VERIFICATION-PURCHASE-ORDER-REQUEST-NEW-SEMESTERS': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'VERIFICATION-PURCHASE-ORDER-REQUEST-PRE-UNIVERSITY': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'PURCHASE-ORDER-AUTORIZATION': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'PURCHASE-ORDER-AUTORIZATION-PRE-UNIVERSITY': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'GENERATE-PURCHASE-ORDER-PRE-UNIVERSITY': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'GENERATE-PURCHASE-ORDER-PRE-UNIVERSITY-EDIT': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'SIGN-PURCHASE-ORDER-PRE-UNIVERSITY': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'PAYMENT-MADE-PRE-UNIVERSITY': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'REPORT-PAYMENT-REQUEST-INVOICE-PRE-UNIVERSITY': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'ATTACH-INVOICE-BY-PRE-UNIVERSITY': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'OFFICE-FOR-FINANCES-PRE-UNIVERSITY': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'VERIFICATION-OFFICE-FOR-FINANCES-PRE-UNIVERSITY': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'OFFICE-FOR-FINANCES-PRE-UNIVERSITY-EDIT': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'ADD-PROPOSAL-DISASTER-SUPPORT': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'ADD-PROPOSAL-FIRE-SUPPORT': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'LEGAL-REPORT-DISASTER-SUPPORT': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'LEGAL-REPORT-FIRE-SUPPORT': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'MANAGEMENT-DETERMINATION-DISASTER-SUPPORT': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'MANAGEMENT-DETERMINATION-FIRE-SUPPORT': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'PRESIDENSY-DETERMINATION-DISASTER-SUPPORT': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'PRESIDENSY-DETERMINATION-FIRE-SUPPORT': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'INFORM-SERVICE-SOCIAL-DETERMINATION': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'OFFICE-WITH-RESOLUTION': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'AID-APPROVAL-DISASTER-SUPPORT': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'AID-APPROVAL-EDIT-DISASTER-SUPPORT': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'AID-APPROVAL-FIRE-SUPPORT': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'AID-APPROVAL-EDIT-FIRE-SUPPORT': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'VALIDATE-AID-APPROVAL-DISASTER-SUPPORT': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'VALIDATE-AID-APPROVAL-FIRE-SUPPORT': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'AID-DEPOSIT-DISASTER-SUPPORT': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'AID-DEPOSIT-FIRE-SUPPORT': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'NOTIFY-TO-SOCIAL-SERVICE-DISASTER-SUPPORT': (
      <DetailsNotifyRegistration
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'NOTIFY-TO-SOCIAL-SERVICE-FIRE-SUPPORT': (
      <DetailsNotifyRegistration
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'NOTIFICATION-ACT-SIGNED-BY-PARTNER-AND-SCHOLARSHIP-RECIPIENT': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'NOTIFICATION-ACT-SIGNED-BY-PARTNER-AND-SCHOLARSHIP-RECIPIENT-TECHNICAL-PROFESSIONAL':
      (
        <DetailsAdditionalInformation
          currentStep={currentStep}
          program={program}
          isOpen={isOpen}
          onClose={onClose}
        />
      ),
    'NOTIFICATION-ACT-SIGNED-BY-PARTNER-AND-SCHOLARSHIP-RECIPIENT-UNIVERSITY': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'THE-UNIVERSITY-WAS-INFORMED-UNIVERSITY': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'PURCHASE-ORDER-REQUEST-UNIVERSITY': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'VERIFICATION-PURCHASE-ORDER-REQUEST-UNIVERSITY': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'PURCHASE-ORDER-REQUEST-UNIVERSITY-EDIT-UNIVERSITY': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'PURCHASE-ORDER-AUTORIZATION-UNIVERSITY': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'SOCIAL-ANALYSIS-UNIVERSITY': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'GENERATE-PURCHASE-ORDER-UNIVERSITY': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'GENERATE-PURCHASE-ORDER-UNIVERSITY-EDIT-UNIVERSITY': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'SIGN-PURCHASE-ORDER-UNIVERSITY': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'CORRECT-REQUEST': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'CORRECT-REQUEST-PRE-UNIVERSITY': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'CORRECT-REQUEST-UNIVERSITY': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'PAYMENT-MADE-UNIVERSITY': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'INFORM-THE-UNIVERSITY-PAYMENT-MADE-UNIVERSITY': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'DELIVERY-OFFICE-UNIVERSITY': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'VERIFICATION-DELIVERY-OFFICE-UNIVERSITY': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'DELIVERY-OFFICE-UNIVERSITY-EDIT-UNIVERSITY': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'AMOUNT-TO-CANCEL-TECHNICAL-PROFESSIONAL': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'AMOUNT-TO-CANCEL-NEW-SEMESTERS': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'REVIEW-OF-SUBMITTED-INFORMATION': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'OFFICE-AND-INVOICE-TECHNICAL-PROFESSIONAL': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'OFFICE-AND-INVOICE-NEW-SEMESTERS': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'OFFICE-AND-INVOICE-EDIT-TECHNICAL-PROFESSIONAL': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'OFFICE-AND-INVOICE-NEW-SEMESTERS-EDIT': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'PURCHASE-ORDER-AUTORIZATION-TECHNICAL-PROFESSIONAL': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'PURCHASE-ORDER-AUTORIZATION-TECHNICAL-PROFESSIONAL-NEW-SEMESTERS': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'CORRECT-REQUEST-TECHNICAL-PROFESSIONAL': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'CORRECT-REQUEST-TECHNICAL-PROFESSIONAL-NEW-SEMESTERS': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'PAYMENT-MADE-TECHNICAL-PROFESSIONAL': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'PAYMENT-MADE-TECHNICAL-PROFESSIONAL-NEW-SEMESTERS': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'INFORM-THE-INSTITUTE-PAYMENT-MADE-TECHNICAL-PROFESSIONAL': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'INFORM-THE-INSTITUTE-PAYMENT-MADE-NEW-SEMESTERS': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'REVIEW-DOCUMENTS-FOR-COMMISSION': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'REVIEW-DOCUMENTS-FOR-COMMISSION-APPLICANT-OFFICER': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'PURCHASE-ORDER-AUTORIZATION-APPLICANT-OFFICER': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'PURCHASE-ORDER-GENERATE-APPLICANT-OFFICER': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'SING-PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'PAYMENT-MADE-APPLICANT-OFFICER': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'RECEIPT-PAYMENT-CERTIFICATE-APPLICANT-OFFICER': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'SING-RECEIPT-PAYMENT-CERTIFICATE-APPLICANT-OFFICER': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'OFFICE-RECEIVED-BY-FINANCE-APPLICANT-OFFICER': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'CORRECT-REQUEST-APPLICANT-OFFICER': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'REVIEW-DOCUMENTS-FOR-COMMISSION-APPLICANT-OFFICER-DAC': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'PURCHASE-ORDER-AUTORIZATION-APPLICANT-OFFICER-DAC': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER-DAC': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'PURCHASE-ORDER-GENERATE-APPLICANT-OFFICER-DAC': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'SING-PURCHASE-ORDER-REQUEST-APPLICANT-OFFICER-DAC': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'PAYMENT-MADE-APPLICANT-OFFICER-DAC': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'RECEIPT-PAYMENT-CERTIFICATE-APPLICANT-OFFICER-DAC': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'SING-RECEIPT-PAYMENT-CERTIFICATE-APPLICANT-OFFICER-DAC': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'OFFICE-RECEIVED-BY-FINANCE-APPLICANT-OFFICER-DAC': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'CORRECT-REQUEST-APPLICANT-OFFICER-DAC': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'MANAGEMENT-DETERMINATION-WORKSHOPS-AND-TRAINING': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'REVISION-OF-INFORMATION-WORKSHOPS-AND-TRAINING': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'REQUEST-FOR-PAYMENT-TO-MONITORS-WORKSHOPS-AND-TRAINING': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'VERIFICATION-PAYMENT-MADE-WORKSHOPS-AND-TRAINING': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'PAYMENT-MADE-WORKSHOPS-AND-TRAINING': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'ADD-OFFICE-ORDER-INUJRED-IN-LINES-OF-SERVICE-KIT': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'REVIEW-DOCUMENTS-SOLIDARITY-HEALTH-FUND': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'REVIEW-CONTRIBUTIONS-SOLIDARITY-HEALTH-FUND': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'NOTIFY-CONTACT-PARTNER-PHONE-SOLIDARITY-HEALTH-FUND': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'CONTINUE-PROCESS-NOTIFY-CONTACT-PARTNER-PHONE-SOLIDARITY-HEALTH-FUND': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'COMMISSION-PREPARATION-SOLIDARITY-HEALTH-FUND': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'VERIFICATION-PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND-EDIT-SOLIDARITY-HEALTH-FUND':
      (
        <DetailsAdditionalInformation
          currentStep={currentStep}
          program={program}
          isOpen={isOpen}
          onClose={onClose}
        />
      ),
    'PURCHASE-ORDER-AUTORIZATION-SOLIDARITY-HEALTH-FUND': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'GENERATE-PURCHASE-ORDER-SOLIDARITY-HEALTH-FUND': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'SIGN-PURCHASE-ORDER-SOLIDARITY-HEALTH-FUND': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'PAYMENT-MADE-SOLIDARITY-HEALTH-FUND': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'INFORM-THE-PAYMENT-MADE-SOLIDARITY-HEALTH-FUND': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'PAYMENT-MADE-MULTI-SOLIDARITY-HEALTH-FUND': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'INFORM-THE-PAYMENT-MADE-MULTI-SOLIDARITY-HEALTH-FUND': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'INFORM-THE-PAYMENT-MADE-MULTI-SOLIDARITY-HEALTH-FUND-EDIT-SOLIDARITY-HEALTH-FUND':
      (
        <DetailsAdditionalInformation
          currentStep={currentStep}
          program={program}
          isOpen={isOpen}
          onClose={onClose}
        />
      ),
    'VERIFICATION-DELIVERY-OFFICE-MULTI-SOLIDARITY-HEALTH-FUND': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'INFORM-THE-PAYMENT-MADE-TWO-SOLIDARITY-HEALTH-FUND': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'DELIVERY-OFFICE-SOLIDARITY-HEALTH-FUND': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'VERIFICATION-DELIVERY-OFFICE-SOLIDARITY-HEALTH-FUND': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'DELIVERY-AND-RECEIPT-CERTIFICATE-SOLIDARITY-HEALTH-FUND': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'DELIVERY-AND-RECEIPT-CERTIFICATE-SIGN-SOLIDARITY-HEALTH-FUND': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'ALL-INVOICES-AND-TRADES-SOLIDARITY-HEALTH-FUND': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'ADD-DEBT-CERTIFICATE-SOLIDARITY-HEALTH-FUND': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'ADD-DEBT-CERTIFICATE-TWO-SOLIDARITY-HEALTH-FUND': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'ADD-DEBT-CERTIFICATE-TWO-EDIT-SOLIDARITY-HEALTH-FUND': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'ADD-DOCUMENTATION-FOR-PAYMENTS-AND-OR-PURCHASES': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'VERIFICATION-PURCHASE-ORDER-REQUEST-TWO-SOLIDARITY-HEALTH-FUND': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'ADD-DOCUMENTATION-FOR-PAYMENTS-AND-OR-PURCHASES-SOLIDARITY-HEALTH-FUND': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'ADD-STAY-CERTIFICATE-HOSPITAL-ACCOMMODATION': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'FIRST-EXTENSION-OR-OUTLET-HOSPITAL-ACCOMMODATION': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'ANY-EXTENSION-OR-OUTLET-HOSPITAL-ACCOMMODATION': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'DETERMINATION-EXTENSION-HOSPITAL-ACCOMMODATION': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'RESIGNATION-OR-LOSS-BENEFIT-RESIGNATION': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'RESIGNATION-OR-LOSS-AUTHORIZATION-RESIGNATION': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'CORRECT-REQUEST-RESIGNATION': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'REGISTER-NOTIFICATION-DEATH-OF-MEMBER': (
      <DetailsNotifyRegistration
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'REGISTER-NOTIFICATION-TWO-DEATH-OF-MEMBER': (
      <DetailsNotifyRegistration
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'QUESTION-ADD-NEW-SEMESTERS-TECHNICAL-PROFESSIONAL': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'TRANSFERRED-AMOUNT-SCHOLARSHIPS-APPLICANT-OFFICER': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'TRANSFERRED-AMOUNT-SCHOLARSHIPS-APPLICANT-OFFICER-DAC': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'TRANSFERRED-AMOUNT-TECHNICAL-PROFESSIONAL': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'TRANSFERRED-AMOUNT-TECHNICAL-PROFESSIONAL-NEWS-SEMESTERS': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'TRANSFERRED-AMOUNT-PRE-UNIVERSITY': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'TRANSFERRED-AMOUNT-SOLIDARITY-HEALTH-FUND': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'USED-AMOUNT-SOLIDARITY-HEALTH-FUND': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    ),
    'ONLY-DESCRIPTIONS-HOSPITAL-ACCOMMODATION': (
      <DetailsAdditionalInformation
        currentStep={currentStep}
        program={program}
        isOpen={isOpen}
        onClose={onClose}
      />
    )
  }

  return <>{currentStep.key && discActionEdit[currentStep.key]}</>
}

export default ActionStepEdit
