import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import {
  Box,
  Flex,
  Text,
  useDisclosure,
  useToast,
  Button,
  HStack
} from '@chakra-ui/react'
import { CustomToast, TextAreaComtrol } from '../../../UI'
import ModalHistory from '../../ModalHistory'
import { useMutateHistoryProgram } from '../../../../hooks/HistoryProgram'
import { useNotifications } from '../../../../hooks/Notifications'
import {
  GetNotification,
  GetFormData,
  GetKey,
  CondicionalModal
} from '../../../../Utils'
import { useTypeModalView } from '../../../../hooks/TypeModal'
import { openResignationModal } from '../../../../store/slices/modal'
import { schema } from './Schema'
import { SendIcon } from '../../../Icons'
import PhoneContainer from './PhoneContainer'
import EmailWspContainer from './EmailWspContainer'
import RenderAdditionalCheck from './RenderAdditionalCheck'

const typeProgramAllowed = [
  'UniversityScholarships',
  'TechnicalProfessionalScholarships',
  'PreUniversityScholarships',
  'FireSupport',
  'DisasterSupport',
  'SolidarityHealthFund'
]

const SendWsp = ({ currentStep, getColor, program }) => {
  const toast = useToast()
  const dispatch = useDispatch()
  const { data } = useNotifications({
    programKey: program?.type,
    type: program?.state
  })
  const { canDesistProgram } = CondicionalModal
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { title, subTitle, succesMessage, errorMessage, titleObservation } =
    useTypeModalView(currentStep?.key, program?.state)
  const {
    mutate: sendWsp,
    isLoading,
    reset
  } = useMutateHistoryProgram('SEND-WSP')

  const getTypeProcess = () => {
    const additionalInformation = program?.additionalInformation.filter(
      (item) => item.key === 'COMMISSION-PREPARATION-SOLIDARITY-HEALTH-FUND'
    )

    if (additionalInformation.length > 0) {
      return {
        medicines: additionalInformation[0]?.medicines,
        supplies: additionalInformation[0]?.supplies,
        tariffDifference: additionalInformation[0]?.tariffDifference,
        milkFormula: additionalInformation[0]?.milkFormula,
        others: additionalInformation[0]?.others,
        service: additionalInformation[0]?.service,
        otherService: additionalInformation[0]?.otherService,
        commission: additionalInformation[0]?.commission
      }
    }

    return {
      medicines: false,
      supplies: false,
      tariffDifference: false,
      milkFormula: false,
      others: false,
      service: null,
      otherService: null,
      commission: null
    }
  }

  const isNotRequiredMessageText = [
    'NOTIFY-NEW-STATUS-PHONE',
    'NOTIFY-TO-SOCIAL-SERVICE-DISASTER-SUPPORT',
    'NOTIFY-TO-SOCIAL-SERVICE-FIRE-SUPPORT'
  ]

  const initialValues = {
    stepProgram: currentStep?.step,
    emails: [],
    phoneNumber: program?.partner?.phone,
    phonePostulant: program?.phonePostulant || program?.postulantPhone || '',
    isRequiredMessage: Boolean(
      !isNotRequiredMessageText.includes(currentStep?.key)
    ),
    affair: '',
    message: '',
    program: program?._id,
    keyHistory:
      GetKey.getPostNotifyStatus(
        program?.state,
        program?.type,
        currentStep?.key,
        program,
        getTypeProcess()
      ) || '',
    completedSemesters: program?.completedSemesters || 0,
    state: program?.state,
    isRequiredDelegateZone: false,
    isRequiredSocialAssistant: false,
    delegateZone: false,
    socialAssistant: false,
    continueStudies: false,
    delegateZoneDate: '',
    socialAssistantDate: '',
    isRequiredSendOnlyWsp: true,
    sendOnlyWsp: false,
    canDesistProgram: canDesistProgram[program.type]
      ? canDesistProgram[program.type].includes(currentStep?.key)
      : false
  }
  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    validateOnChange: false,
    onSubmit: (values) => {
      const formData = GetFormData(values)

      sendWsp(formData, {
        onSuccess: () => {
          reset()
          formik.resetForm()
          onClose()
          GetNotification.basic(succesMessage, 'Aceptar')
        },
        onError: (err) => {
          console.log(err)
          const response = err?.response?.data

          GetNotification.basic(
            'Error',
            'Aceptar',
            response?.type === 'validateLastStep'
              ? response.message
              : errorMessage,
            'error'
          )
        }
      })
    }
  })

  useEffect(() => {
    if (isLoading) {
      toast({
        duration: 9000 * 9000,
        isClosable: true,
        position: 'bottom-right',
        render: ({ onClose: onCloseT }) => (
          <CustomToast
            text={'Espere por favor...'}
            status={'info'}
            onClose={onCloseT}
          />
        )
      })
      return
    }
    toast.closeAll()
  }, [isLoading])

  const updateNotification = () => {
    if (
      data?.notifications?.type === program?.state &&
      data?.typeProgram === program?.type
    ) {
      return {
        affair: data?.notifications?.affair || '',
        message: data?.notifications?.message || ''
      }
    }
    return {}
  }

  useEffect(() => {
    formik.resetForm()
    const emails = []
    if (isOpen) {
      if (program?.partner?.email) {
        emails.push(program?.partner?.email)
      }

      if (program?.postulantEmail) {
        emails.push(program?.postulantEmail)
      }

      if (program?.email) {
        emails.push(program?.email)
      }

      formik.setValues({
        ...formik.values,
        emails: JSON.stringify(emails),
        ...updateNotification()
      })

      if (
        (typeProgramAllowed.includes(program?.type) &&
          program?.state === 'APPROVED') ||
        (program?.type === 'SolidarityHealthFund' &&
          program?.state === 'REJECTED')
      ) {
        formik.setValues({
          ...formik.values,
          emails: JSON.stringify(emails),
          isRequiredDelegateZone: true,
          isRequiredSocialAssistant: true,
          ...updateNotification()
        })
      }
    }
    toast.closeAll()
  }, [isOpen])

  return (
    <ModalHistory
      title={title}
      iconButton={SendIcon}
      colors={getColor}
      disable={currentStep?.complete}
      disableButtons={
        currentStep?.key === 'NOTIFY-NEW-STATUS' &&
        formik.values.emails === '[]' &&
        !formik.values.sendOnlyWsp
      }
      isLoading={isLoading}
      onClick={formik.handleSubmit}
      onClose={onClose}
      isOpen={isOpen}
      onOpen={onOpen}
    >
      <Flex align={'center'} justify={'center'} direction={'column'} gap={5}>
        {subTitle && <Text>{subTitle}</Text>}
        <Box w={'100%'}>
          {formik.values.canDesistProgram && (
            <HStack justify="flex-end">
              <Button
                colorScheme="red"
                onClick={() => {
                  onClose()
                  dispatch(openResignationModal())
                }}
              >
                Desistir
              </Button>
            </HStack>
          )}

          {currentStep?.key === 'NOTIFY-NEW-STATUS' ||
          currentStep?.key === 'NOTIFY-REGISTRATION' ||
          currentStep?.key === 'NOTIFY-TO-SOCIAL-SERVICE-FIRE-SUPPORT' ||
          currentStep?.key === 'NOTIFY-TO-SOCIAL-SERVICE-DISASTER-SUPPORT' ||
          currentStep?.key ===
            'NOTIFY-REJECTED-STATUS-PHONE-SOLIDARITY-HEALTH-FUND' ? (
            <>
              <EmailWspContainer program={program} formik={formik} />
              <RenderAdditionalCheck
                formik={formik}
                typeProgramAllowed={typeProgramAllowed}
                program={program}
              />
            </>
          ) : (
            <PhoneContainer
              partnerFullName={program?.partner?.name}
              phone={program?.partner?.phone}
              alternativePhone={program?.alternativePhone}
              phonePostulant={program?.phonePostulant || null}
            />
          )}

          <Box minW={'100%'} pt={4}>
            <TextAreaComtrol
              id={'message'}
              label={`${titleObservation}:`}
              value={formik.values.message}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.message)}
              errorText={formik.errors.message}
              isRequired={formik.values.isRequiredMessage}
            />
          </Box>
        </Box>
      </Flex>
    </ModalHistory>
  )
}

export default SendWsp
