import React from 'react'
import { Grid, GridItem } from '@chakra-ui/react'
import { InputComtrol } from '../../UI'
import { FormatDate } from '../../../Utils'
import { NODATA } from '../../../constans'

const PostulantInformation = ({ program }) => (
  <>
    <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
      <GridItem>
        <InputComtrol
          label={'Nombre completo'}
          value={program?.postulantName}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
    </Grid>
    <Grid
      templateColumns={{
        lg: 'repeat(2, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem>
        <InputComtrol
          label={'Telefono postulante'}
          addictionType={'phone'}
          value={program?.phonePostulant}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Fecha de nacimiendo'}
          value={
            program?.dateOfBirth ? FormatDate(program?.dateOfBirth) : NODATA
          }
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Universidad'}
          value={program?.university}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Año que cursa'}
          value={program?.currentYear}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Arancel anual'}
          value={program?.annualFee}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
      <GridItem>
        <InputComtrol
          label={'Correo electrónico'}
          value={program?.email}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Edad'}
          value={program?.age}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Carrera'}
          value={program?.universityCareer}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Promedio de notas'}
          value={program?.scoreAverage}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
    </Grid>
    <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
      <GridItem>
        <InputComtrol
          label={'¿Con quién vive?'}
          value={program?.livesWith}
          isRequired={false}
          readOnly={true}
        />
        {program?.specification && (
          <InputComtrol
            label={'Especificación'}
            value={program?.specification}
            isRequired={false}
            readOnly={true}
          />
        )}
      </GridItem>
    </Grid>
  </>
)

export default PostulantInformation
