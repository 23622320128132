import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Flex, useToast } from '@chakra-ui/react'
import { Step, Steps, useSteps } from 'chakra-ui-steps'
import { Button, CustomToast } from '../../UI'
import StepOne from './StepOne'
import StepTwo from './StepTwo'
import StepThree from './StepThree'
import StepFour from './StepFour'
import { useMutateProgram } from '../../../hooks/Programs/TechnicalProfessionalScholarships'
import { GetFormData, GetNotification } from '../../../Utils'

const TechnicalProfessionalScholarships = ({ partner, program = null }) => {
  const toast = useToast()
  const refStepOne = useRef(null)
  const refStepTwo = useRef(null)
  const refStepThree = useRef(null)
  const refStepFour = useRef(null)
  const { nextStep, prevStep, activeStep } = useSteps({
    initialStep: 0
  })

  const history = useHistory()
  const [stepOneForm, setStepOneForm] = useState({
    partnerPhone: partner?.phone,
    partnerRut: partner?.rut,
    partnerGrade: partner?.grade,
    partnerCode: partner?.code,
    partnerZone: partner?.zone,
    partnerPrefecture: partner?.prefecture,
    partnerEndowment: partner?.endowment
  })
  const [stepTwoForm, setStepTwoForm] = useState({})
  const [stepThreeForm, setStepThreeForm] = useState({})
  const [stepFourForm, setStepFourForm] = useState({})
  const [finalForm, setFinalForm] = useState({
    partnerId: partner?._id
  })
  const [state, setState] = useState(null)
  const { mutate, isLoading, reset } = useMutateProgram('CREATE')
  const {
    mutate: mutateUpdate,
    isLoading: isLoadingUpdate,
    reset: resetUpdate
  } = useMutateProgram('UPDATE')

  const steps = [
    {
      label: 1,
      content: (
        <StepOne
          stepOneForm={stepOneForm}
          setStepOneForm={setStepOneForm}
          refStepOne={refStepOne}
          next={nextStep}
        />
      )
    },
    {
      label: 2,
      content: (
        <StepTwo
          stepTwoForm={stepTwoForm}
          setStepTwoForm={setStepTwoForm}
          refStepTwo={refStepTwo}
          next={nextStep}
        />
      )
    },
    {
      label: 3,
      content: (
        <StepThree
          stepThreeForm={stepThreeForm}
          setStepThreeForm={setStepThreeForm}
          refStepThree={refStepThree}
          next={nextStep}
        />
      )
    },
    {
      label: 4,
      content: (
        <StepFour
          stepFourForm={stepFourForm}
          setStepFourForm={setStepFourForm}
          refStepFour={refStepFour}
          state={state}
        />
      )
    }
  ]

  const handleNext = () => {
    if (activeStep === 0) {
      refStepOne.current()
    }
    if (activeStep === 1) {
      refStepTwo.current()
    }
    if (activeStep === 2) {
      refStepThree.current()
    }
    if (activeStep === 3) {
      refStepFour.current()
    }
  }

  useEffect(() => {
    const beneficiariesHelped = ['Hijo(a)']

    setFinalForm({
      ...finalForm,
      ...stepOneForm,
      ...stepTwoForm,
      ...stepThreeForm,
      ...stepFourForm,
      beneficiariesHelped
    })
  }, [stepFourForm])

  useEffect(() => {
    // si tiene mas valores aparte de los ID de partner
    // y los el paso activo es el 2
    console.log(finalForm)
    const test = true
    if (test) {
      if (Object.keys(finalForm).length > 1 && activeStep === 3) {
        if (!program) {
          const formData = GetFormData(
            finalForm,
            [
              'birthCertificate',
              'scoreCertificate',
              'parentsLastSalary',
              'motivationLetter',
              'applicationSignedByPartner',
              'scoreCertificateSantoTomas',
              'aditionalDocuments',
              'socialReport'
            ],
            [
              'birthCertificateName',
              'scoreCertificateName',
              'parentsLastSalaryNames',
              'motivationLetterName',
              'applicationSignedByPartnerName',
              'scoreCertificateSantoTomasName',
              'aditionalDocumentsNames',
              'socialReportName',
              'beneficiariesHelped'
            ]
          )

          mutate(formData, {
            onSuccess: () => {
              reset()
              GetNotification.basic('Programa registrado con Éxito', 'Aceptar')
              history.push('/programs')
            },
            onError: (err) => {
              console.log(err)
              GetNotification.basic(
                'Error',
                'Aceptar',
                'Error al Registrar programa',
                'error'
              )
            }
          })
        }

        if (program) {
          const { _id: programId } = program
          const formData = GetFormData(
            finalForm,
            [
              'birthCertificate',
              'scoreCertificate',
              'parentsLastSalary',
              'motivationLetter',
              'applicationSignedByPartner',
              'scoreCertificateSantoTomas',
              'aditionalDocuments',
              'socialReport'
            ],
            [
              'birthCertificateName',
              'scoreCertificateName',
              'parentsLastSalaryNames',
              'motivationLetterName',
              'applicationSignedByPartnerName',
              'scoreCertificateSantoTomasName',
              'aditionalDocumentsNames',
              'socialReportName',
              'beneficiariesHelped'
            ]
          )
          mutateUpdate(
            { programId, formData },
            {
              onSuccess: () => {
                resetUpdate()
                GetNotification.basic(
                  'Programa actualizado con Éxito',
                  'Aceptar'
                )
                history.push('/programs')
              },
              onError: (err) => {
                console.log(err)
                GetNotification.basic(
                  'Error',
                  'Aceptar',
                  'Error al actualizar programa',
                  'error'
                )
              }
            }
          )
        }
      }
    }
  }, [finalForm])

  useEffect(() => {
    if (program) {
      setStepOneForm({
        receptionDate: program.receptionDate,
        isPartnerZone: program?.isPartnerZone,
        zone: program?.zone,
        prefecture: program?.prefecture,
        unit: program?.unit,
        civilStatus: program.civilStatus,
        region: program.region,
        commune: program.commune,
        address: program.address,
        residenceCondition: program.residenceCondition,
        alternativePhone: program.alternativePhone,
        /* partner field */
        partnerPhone: program?.partner.phone,
        partnerRut: program?.partner.rut,
        partnerGrade: program?.partner.grade,
        partnerCode: program?.partner.code,
        partnerZone: program?.partner.zone,
        partnerPrefecture: program?.partner.prefecture,
        partnerEndowment: program?.partner.endowment
      })
      setStepTwoForm({
        postulantName: program.postulantName,
        phonePostulant: program.phonePostulant,
        dateOfBirth: program.dateOfBirth,
        currentInstitution: program.currentInstitution,
        institutionToStudy: program.institutionToStudy,
        currentYear: program.currentYear,
        annualFee: program.annualFee,
        addressPostulant: program.addressPostulant,
        email: program.email,
        age: program.age,
        institutionCareer: program.institutionCareer,
        scoreAverage: program.scoreAverage,
        livesWith: program.livesWith,
        specification: program.specification
      })
      setStepThreeForm({
        birthCertificateName: program?.birthCertificate,
        scoreCertificateName: program?.scoreCertificate,
        parentsLastSalaryNames: program?.parentsLastSalary,
        motivationLetterName: program?.motivationLetter,
        applicationSignedByPartnerName: program?.applicationSignedByPartner,

        scoreCertificateSantoTomasName: program?.scoreCertificateSantoTomas,
        socialReportName: program?.socialReport
      })
      setStepFourForm({
        aditionalDocumentsNames: program?.aditionalDocuments?.map(
          (item) => item
        ),
        observations: program?.observations
      })
    }
  }, [program])

  useEffect(() => {
    if (isLoading || isLoadingUpdate) {
      toast({
        duration: 9000 * 9000,
        isClosable: true,
        position: 'bottom-right',
        render: ({ onClose }) => (
          <CustomToast
            text={'Espere por favor...'}
            status={'info'}
            onClose={onClose}
          />
        )
      })
      return
    }
    toast.closeAll()
  }, [isLoading, isLoadingUpdate])

  return (
    <Flex direction={'column'}>
      <Steps
        activeStep={activeStep}
        orientation="horizontal"
        responsive
        mb={{
          lg: 5,
          md: 0
        }}
      >
        {steps.map(({ label, content }) => (
          <Step key={label}>{content}</Step>
        ))}
      </Steps>
      <Flex justify={'flex-end'} mt={4}>
        <Button
          text={'Regresar'}
          bg={'green.50'}
          color={'green.500'}
          disabled={activeStep === 0 || isLoading || isLoadingUpdate}
          onClick={prevStep}
        />
        {activeStep === steps.length - 1 && (
          <Button
            text={'Guardar como incompleta'}
            bg={'green.50'}
            color={'green.500'}
            disabled={activeStep === 0 || isLoading || isLoadingUpdate}
            onClick={() => {
              setState('INCOMPLETE')
              handleNext()
            }}
            isLoading={isLoading || isLoadingUpdate}
          />
        )}
        <Button
          text={activeStep === steps.length - 1 ? 'Finalizar' : 'Siguiente'}
          onClick={() => {
            setState('INREVIEW')
            handleNext()
          }}
          isLoading={isLoading || isLoadingUpdate}
          disabled={isLoading || isLoadingUpdate}
        />
      </Flex>
    </Flex>
  )
}

export default TechnicalProfessionalScholarships
