/* eslint-disable import/prefer-default-export */
import { useMutation, useQuery /* , useQueryClient */ } from 'react-query'
import {
  getAverageAmountsPerYearAndMonth,
  getYearPrograms,
  getTotalProgramsPerStateAndZoneAndMonthAndYear,
  generateResolutionOfRequestsReport,
  getAmountApprovedPerBenefit,
  generateAmountApprovedPerBenefitReport,
  getRequestsApprovedPerGrade,
  generateApprovedPerGradeReport,
  getRequestsApprovedPerBeneficiary,
  generateRequestApprovedPerBeneficiaryReport
} from '../api/Reportability'

const key = 'reportability'

const getAction = (type) => {
  if (type === 'GENERATE-RESOLUTION-OF-REQUESTS-REPORT')
    return generateResolutionOfRequestsReport
  if (type === 'GENERATE-AMOUNT-APPROVED-PER-GRADE-YEAR-ZONE-TYPE')
    return generateAmountApprovedPerBenefitReport
  if (type === 'GENERATE-APPOVED-PER-GRADE-REPORT')
    return generateApprovedPerGradeReport
  if (type === 'GENERATE-REQUEST-APPROVED-PER-BENEFICIARY')
    return generateRequestApprovedPerBeneficiaryReport
  return null
}

export function useReportability(type, filters) {
  if (type === 'AVG-YEAR-MONTH') {
    return useQuery(
      [`${key}/${type}`, filters],
      () => getAverageAmountsPerYearAndMonth(filters),
      {
        refetchOnWindowFocus: false
      }
    )
  }
  if (type === 'YEARS-PROGRAMS') {
    return useQuery(
      [`${key}/${type}`, filters],
      () => getYearPrograms(filters),
      {
        refetchOnWindowFocus: false
      }
    )
  }
  if (type === 'TOTAL-PROGRAMS-PER-STATE-ZONE-YEAR-MONTH') {
    return useQuery(
      [`${key}/${type}`, filters],
      () => getTotalProgramsPerStateAndZoneAndMonthAndYear(filters),
      {
        refetchOnWindowFocus: false
      }
    )
  }
  if (type === 'AMOUNT-APPROVED-PER-GRADE-YEAR-ZONE-TYPE') {
    return useQuery(
      [`${key}/${type}`, filters],
      () => getAmountApprovedPerBenefit(filters),
      {
        refetchOnWindowFocus: false
      }
    )
  }
  if (type === 'REQUESTS-APPOVED-PER-GRADE-ZONE-YEAR-MONTH') {
    return useQuery(
      [`${key}/${type}`, filters],
      () => getRequestsApprovedPerGrade(filters),
      {
        refetchOnWindowFocus: false
      }
    )
  }
  if (type === 'REQUEST-APPROVED-PER-BENEFICIARY') {
    return useQuery(
      [`${key}/${type}`, filters],
      () => getRequestsApprovedPerBeneficiary(filters),
      {
        refetchOnWindowFocus: false
      }
    )
  }
  return null
}

export function useMutateReportability(type) {
  const actionFn = getAction(type)
  return useMutation(actionFn, {
    onSuccess: () => {
      /* queryClient.invalidateQueries([key]) */
    }
  })
}
