import React from 'react'
import { Grid, GridItem, Box } from '@chakra-ui/react'
import { CardStatus, InputComtrol } from '../../UI'
import { FormatDate, GetDiffDates } from '../../../Utils'

const ApplicationInformation = ({ program }) => (
  <>
    <Grid
      templateColumns={{
        lg: 'repeat(2, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem>
        <InputComtrol
          label={'Fecha de registro del programa'}
          value={FormatDate(program?.createdAt)}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Fecha del deceso'}
          value={FormatDate(program?.dateOfDeath)}
          isRequired={false}
          readOnly={true}
        />
        <InputComtrol
          label={'Zona de postulación'}
          value={program?.zone}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
      <GridItem>
        <InputComtrol
          label={'Fecha de recepción de solicitud'}
          value={FormatDate(program?.receptionDate)}
          isRequired={false}
          readOnly={true}
        />
        <Box
          opacity={0}
          mb={8}
          display={{
            lg: 'block',
            md: 'none',
            sm: 'none'
          }}
        ></Box>
        <CardStatus
          subValue={'Ocurrido'}
          value={GetDiffDates(program?.dateOfDeath)}
        />
        <InputComtrol
          label={'Alta repartición / Repartición'}
          value={program?.prefecture}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
    </Grid>
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem>
        <InputComtrol
          label={'Unidad'}
          value={program?.unit}
          isRequired={false}
          readOnly={true}
        />
      </GridItem>
    </Grid>
  </>
)

export default ApplicationInformation
