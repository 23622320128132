import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Flex, useToast } from '@chakra-ui/react'
import { Step, Steps, useSteps } from 'chakra-ui-steps'
import { Button, CustomToast } from '../../../UI'
import StepOne from './StepOne'
import StepTwo from './StepTwo'
import { useMutateProgram } from '../../../../hooks/Programs/WorkshopsAndTraining'
import { GetFormData, GetNotification } from '../../../../Utils'

const Form = ({ program }) => {
  const toast = useToast()
  const history = useHistory()

  const refStepOne = useRef(null)
  const refStepTwo = useRef(null)
  const { nextStep, prevStep, activeStep } = useSteps({
    initialStep: 0
  })

  const [stepOneForm, setStepOneForm] = useState({})
  const [stepTwoForm, setStepTwoForm] = useState({})
  const [finalForm, setFinalForm] = useState({})
  const [state, setState] = useState(null)
  const { mutate, isLoading, reset } = useMutateProgram('CREATE')
  const {
    mutate: mutateUpdate,
    isLoading: isLoadingUpdate,
    reset: resetUpdate
  } = useMutateProgram('UPDATE')

  const steps = [
    {
      label: 1,
      content: (
        <StepOne
          stepOneForm={stepOneForm}
          setStepOneForm={setStepOneForm}
          refStepOne={refStepOne}
          next={nextStep}
        />
      )
    },
    {
      label: 2,
      content: (
        <StepTwo
          stepTwoForm={stepTwoForm}
          setStepTwoForm={setStepTwoForm}
          refStepTwo={refStepTwo}
          state={state}
        />
      )
    }
  ]

  const handleNext = () => {
    if (activeStep === 0) {
      refStepOne.current()
    }
    if (activeStep === 1) {
      refStepTwo.current()
    }
  }

  useEffect(() => {
    setFinalForm({
      ...finalForm,
      ...stepOneForm,
      ...stepTwoForm
    })
  }, [stepTwoForm])

  useEffect(() => {
    // si tiene mas valores aparte de los ID de partner
    // y los el paso activo es el 2
    const test = true
    if (test) {
      if (Object.keys(finalForm).length > 1 && activeStep === 1) {
        if (!program) {
          const formData = GetFormData(
            finalForm,
            ['certificateMonitor', 'accountBackup'],
            ['schedules', 'certificateMonitorName', 'accountBackupName']
          )

          mutate(formData, {
            onSuccess: () => {
              reset()
              GetNotification.basic('Programa registrado con Éxito', 'Aceptar')
              history.push('/programs')
            },
            onError: (err) => {
              console.log(err)
              GetNotification.basic(
                'Error',
                'Aceptar',
                'Error al Registrar programa',
                'error'
              )
            }
          })
        }

        if (program) {
          const { _id: programId } = program
          const formData = GetFormData(
            finalForm,
            ['certificateMonitor', 'accountBackup'],
            ['schedules', 'certificateMonitorName', 'accountBackupName']
          )
          mutateUpdate(
            { programId, formData },
            {
              onSuccess: () => {
                resetUpdate()
                GetNotification.basic(
                  'Programa actualizado con Éxito',
                  'Aceptar'
                )
                history.push('/programs')
              },
              onError: (err) => {
                console.log(err)
                GetNotification.basic(
                  'Error',
                  'Aceptar',
                  'Error al actualizar programa',
                  'error'
                )
              }
            }
          )
        }
      }
    }
  }, [finalForm])

  useEffect(() => {
    if (isLoading || isLoadingUpdate) {
      toast({
        duration: 9000 * 9000,
        isClosable: true,
        position: 'bottom-right',
        render: ({ onClose }) => (
          <CustomToast
            text={'Espere por favor...'}
            status={'info'}
            onClose={onClose}
          />
        )
      })
      return
    }
    toast.closeAll()
  }, [isLoading, isLoadingUpdate])

  useEffect(() => {
    if (program) {
      setStepOneForm({
        topic: program?.topic,
        zone: program?.zone,
        prefecture: program?.prefecture,
        unit: program?.unit,
        startDate: program?.startDate,
        endDate: program?.endDate,
        description: program?.description,
        schedules: program?.schedules,
        modality: program?.modality
      })
      setStepTwoForm({
        fullNameMonitor: program?.monitorData?.fullName,
        rutMonitor: program?.monitorData?.rut,
        birthDateMonitor: program?.monitorData?.birthDate,
        addressMonitor: program?.monitorData?.address,
        phonePrimaryMonitor: program?.monitorData?.phone,
        phoneSecondaryMonitor: program?.monitorData?.alternativePhone,
        emailMonitor: program?.monitorData?.email,
        certificateMonitorName: program?.monitorData?.certificate,
        isFreeProgram: program?.isFreeProgram,
        /* -bank data- */
        accountNumber: program?.bankDataMonitor?.accountNumber,
        accountBackupName: program?.bankDataMonitor?.accountBackup,
        typeAccount: program?.bankDataMonitor?.typeAccount,
        typeAccountOther: program?.bankDataMonitor?.typeAccountOther,
        bank: program?.bankDataMonitor?.bank,
        bankOther: program?.bankDataMonitor?.bankOther
      })
    }
  }, [program])

  return (
    <Flex direction={'column'}>
      <Steps
        activeStep={activeStep}
        orientation="horizontal"
        responsive
        mb={{
          lg: 5,
          md: 0
        }}
      >
        {steps.map(({ label, content }) => (
          <Step key={label}>{content}</Step>
        ))}
      </Steps>
      <Flex justify={'flex-end'} mt={4}>
        <Button
          text={'Regresar'}
          bg={'green.50'}
          color={'green.500'}
          disabled={activeStep === 0 || isLoading || isLoadingUpdate}
          onClick={prevStep}
        />
        {activeStep === steps.length - 1 && (
          <Button
            text={'Guardar como incompleta'}
            bg={'green.50'}
            color={'green.500'}
            disabled={activeStep === 0 || isLoading || isLoadingUpdate}
            onClick={() => {
              setState('INCOMPLETE')
              handleNext()
            }}
            isLoading={isLoading || isLoadingUpdate}
          />
        )}
        <Button
          text={activeStep === steps.length - 1 ? 'Finalizar' : 'Siguiente'}
          onClick={() => {
            setState('INREVIEW')
            handleNext()
          }}
          isLoading={isLoading || isLoadingUpdate}
          disabled={isLoading || isLoadingUpdate}
        />
      </Flex>
    </Flex>
  )
}

export default Form
