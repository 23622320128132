import React from 'react'
import { Menu, MenuButton, MenuList, MenuItem, Icon } from '@chakra-ui/react'
import { OptionsIcons } from '../Icons'

const MenuOptions = ({ optionsMenu }) => (
  <Menu isLazy>
    <MenuButton>
      <Icon fontSize="26" as={OptionsIcons} />
    </MenuButton>
    <MenuList
      borderRadius={20}
      py={1}
      px={2}
      color={'green.500'}
      zIndex={999999999}
    >
      {optionsMenu.map((item) => (
        <MenuItem
          key={item}
          _hover={{ bg: 'green.50', borderRadius: 10 }}
          onClick={item?.function}
        >
          {item?.text}
        </MenuItem>
      ))}
    </MenuList>
  </Menu>
)

export default MenuOptions
