import React, { useEffect, useState } from 'react'
import { addDays } from 'date-fns'
import { useFormik } from 'formik'
import { Grid, GridItem, Text } from '@chakra-ui/react'
import {
  ButtonDotted,
  CustomDatePicker,
  InputComtrol,
  Select,
  TextAreaComtrol
} from '../../../../UI'
import { steepOneSchema } from '../Schemas'
import ScheduleItem from './ScheduleItem'
import { Modality, ZonesTwoList } from '../../../../../Utils'

const StepOne = ({ stepOneForm, setStepOneForm, refStepOne, next }) => {
  const [isErrorSchedules, setIsErrorSchedules] = useState(false)
  const templateDay = {
    id: Date.now().toString(),
    day: 'Lunes',
    startHour: '00',
    startMinutes: '00',
    startPeriod: 'AM',
    endHour: '00',
    endMinutes: '00',
    endPeriod: 'PM'
  }

  const initialValues = {
    topic: '',
    zone: ZonesTwoList.getRegions().at(0),
    // prefecture: ZonesTwoList.getCommunes(ZonesTwoList.getRegions().at(0)).at(0),
    unit: '',
    startDate: '',
    endDate: '',
    description: '',
    schedules: [],
    modality: Modality.at(0)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: steepOneSchema,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: (values) => {
      if (!isErrorSchedules) {
        setStepOneForm({
          ...values
        })
        next()
      }
    }
  })

  const addNewDay = () => {
    const newDay = {
      ...templateDay,
      id: Date.now().toString()
    }
    formik.setFieldValue('schedules', [...formik.values.schedules, newDay])
  }

  const handleChangeStartDate = (date) => {
    formik.setFieldValue('startDate', date)
  }
  const handleChangeEndDate = (date) => {
    formik.setFieldValue('endDate', date)
  }

  const handleChangeModality = (value) => {
    formik.setFieldValue('modality', value)
  }

  const hadleChangeZone = (value) => {
    formik.setFieldValue('zone', value)
    formik.setFieldValue('prefecture', ZonesTwoList.getCommunes(value).at(0))
  }

  const handleChangePrefecture = (value) => {
    formik.setFieldValue('prefecture', value)
  }

  useEffect(() => {
    refStepOne.current = formik.handleSubmit
  }, [])

  useEffect(() => {
    formik.setValues({
      topic: stepOneForm.topic || '',
      zone: stepOneForm.zone || ZonesTwoList.getRegions().at(0),
      prefecture:
        stepOneForm.prefecture ||
        ZonesTwoList.getCommunes(ZonesTwoList.getRegions().at(0)).at(0),
      unit: stepOneForm.unit || '',
      startDate: stepOneForm.startDate || '',
      endDate: stepOneForm.endDate || '',
      description: stepOneForm.description || '',
      schedules: stepOneForm.schedules || [],
      modality: stepOneForm.modality || Modality.at(0)
    })
  }, [stepOneForm])

  return (
    <>
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          <Text fontWeight={700} color={'green.500'} fontSize={'xl'}>
            Información del taller o capacitación
          </Text>
          <InputComtrol
            id={'topic'}
            label={'Tema'}
            value={formik.values.topic}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.topic)}
            errorText={formik.errors.topic}
          />
        </GridItem>
        <GridItem mt={'auto'}>
          <InputComtrol id={'modality'} label={'Modalidad'}>
            <Select
              bg={'white'}
              list={Modality}
              value={formik.values.modality}
              onChange={handleChangeModality}
            />
          </InputComtrol>
        </GridItem>
      </Grid>
      {/* --- */}
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          <InputComtrol id={'startDate'} label={'Fecha de inicio'}>
            <CustomDatePicker
              placeholder={'DD/MM/AAAA'}
              currentDate={formik.values.startDate}
              onChange={(date) => handleChangeStartDate(date)}
              error={Boolean(formik.errors.startDate)}
              errorText={formik.errors.startDate}
              minDate={addDays(new Date(), 0)}
            />
          </InputComtrol>
        </GridItem>
        <GridItem>
          <InputComtrol id={'endDate'} label={'Fecha de cierre'}>
            <CustomDatePicker
              placeholder={'DD/MM/AAAA'}
              currentDate={formik.values.endDate}
              onChange={(date) => handleChangeEndDate(date)}
              error={Boolean(formik.errors.endDate)}
              errorText={formik.errors.endDate}
              minDate={addDays(new Date(), 1)}
            />
          </InputComtrol>
        </GridItem>
      </Grid>
      {/* --- */}
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
        <GridItem>
          <TextAreaComtrol
            id={'description'}
            label={'Descripción breve'}
            value={formik.values.description}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.description)}
            errorText={formik.errors.description}
          />
        </GridItem>
      </Grid>
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          <Text fontWeight={700} color={'green.500'} fontSize={'xl'}>
            Zona de postulación
          </Text>
          <InputComtrol id={'zone'} label={'Zona'} isRequired={false}>
            <Select
              bg={'white'}
              list={ZonesTwoList.getRegions()}
              value={formik.values.zone}
              onChange={hadleChangeZone}
            />
          </InputComtrol>
        </GridItem>
        {/* <GridItem>
          <Text fontSize={'xl'} opacity={0}>
            -----
          </Text>
          <InputComtrol
            id={'prefecture'}
            label={'Alta repartición / Repartición'}
            error={Boolean(formik.errors.prefecture)}
            errorText={formik.errors.prefecture}
          >
            <Select
              bg={'white'}
              list={ZonesTwoList.getCommunes(formik.values.zone)}
              value={formik.values.prefecture}
              onChange={handleChangePrefecture}
            />
          </InputComtrol>
        </GridItem> */}
      </Grid>
      {/* <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
        <GridItem>
          <InputComtrol
            id={'unit'}
            label={'Unidad'}
            value={formik.values.unit}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.unit)}
            errorText={formik.errors.unit}
          />
        </GridItem>
      </Grid> */}
      {/* --- */}
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
        <GridItem>
          <Text>Horarios:</Text>
        </GridItem>
        {Boolean(formik.errors.schedules) && (
          <GridItem ml={'auto'} mr={'auto'}>
            <Text color={'red.500'}>{formik.errors.schedules}</Text>
          </GridItem>
        )}
        <GridItem>
          {formik.values.schedules.map((item) => (
            <ScheduleItem
              key={item.id}
              schedule={item}
              formik={formik}
              setIsErrorSchedules={setIsErrorSchedules}
            />
          ))}
        </GridItem>
        <GridItem>
          <ButtonDotted text={'Agregar día'} onClick={addNewDay} />
        </GridItem>
      </Grid>
    </>
  )
}

export default StepOne
