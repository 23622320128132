import React from 'react'
import { Grid, GridItem, Text } from '@chakra-ui/react'
import { CardFilePreview } from '../../UI'

const Documents = ({ program }) => (
  <>
    {program?.policePart.length > 0 && (
      <>
        <Text>Parte policial</Text>
        <Grid
          templateColumns={{
            lg: 'repeat(1, 1fr)',
            md: 'repeat(1, 1fr)',
            sm: 'repeat(1, 1fr)'
          }}
          gap={4}
          pb={8}
        >
          {program?.policePart.map((item) => (
            <GridItem>
              <CardFilePreview name={item?.name} url={item?.url} />
            </GridItem>
          ))}
        </Grid>
      </>
    )}
    <Text>Última liquidación de sueldo</Text>
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={4}
      pb={8}
    >
      {program?.lastSalarySettlement.map((item) => (
        <GridItem>
          <CardFilePreview name={item?.name} url={item?.url} />
        </GridItem>
      ))}
    </Grid>
    <Text>
      Certificado de la unidad dando cuenta del domicilio del afectado
    </Text>
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={4}
      pb={8}
    >
      {program?.unitCertificate.map((item) => (
        <GridItem>
          <CardFilePreview name={item?.name} url={item?.url} />
        </GridItem>
      ))}
    </Grid>
    <Text>Relación de especies siniestradas confeccionada por el socio</Text>
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={4}
      pb={8}
    >
      {program?.objectList.map((item) => (
        <GridItem>
          <CardFilePreview name={item?.name} url={item?.url} />
        </GridItem>
      ))}
    </Grid>
    <Text>Fotos de evidencia de la residencia siniestrada</Text>
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      {program?.evidencePhotos.map((item) => (
        <GridItem>
          <CardFilePreview name={item.name} url={item.url} />
        </GridItem>
      ))}
    </Grid>
    <Text>Minuta</Text>
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      {program?.minuta.map((item) => (
        <GridItem>
          <CardFilePreview name={item.name} url={item.url} />
        </GridItem>
      ))}
    </Grid>
  </>
)

export default Documents
