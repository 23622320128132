import React, { useEffect } from 'react'
import { Flex, useDisclosure } from '@chakra-ui/react'
import { useFormik } from 'formik'
import { useMutateProgram } from '../../../../hooks/Programs/WorkshopsAndTraining'
import ModalHistory from '../../ModalHistory'
import PartnerItem from './PartnerItem'
import SearchBar from './SearchBar'
import { GetFormData, GetKey, GetNotification } from '../../../../Utils'

const SearchPartnersAndSpouse = ({ currentStep, getColor, program }) => {
  const { mutate, isLoading, reset } = useMutateProgram('REGISTER-TO-PROGRAM')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const initialValues = {
    partnersIds: [],
    partnersData: [],
    state: program?.state,
    keyHistory: GetKey.getNextStep(
      currentStep?.key,
      program?.type
    ) /* es el siguiente paso */
  }

  const formik = useFormik({
    initialValues,
    validateOnChange: false,
    onSubmit: (values) => {
      console.log(values)
      const { _id: programId } = program
      const formData = GetFormData(values, [], ['partnersIds', 'partnersData'])

      mutate(
        { programId, formData },
        {
          onSuccess: () => {
            reset()
            GetNotification.basic('Socios agregados correctamente', 'Aceptar')
            onClose()
          },
          onError: (err) => {
            console.log(err)
            GetNotification.basic(
              'Error',
              'Aceptar',
              'Error al agregar socios',
              'error'
            )
            onClose()
          }
        }
      )
    }
  })

  const validateFromItems = () => {
    const isValid = formik.values.partnersData.filter(
      (partner) => partner.familyMembers.isValid === false
    ).length

    return Boolean(isValid === 0)
  }

  useEffect(() => {
    if (isOpen) {
      formik.resetForm(initialValues)
    }
  }, [isOpen])

  return (
    <ModalHistory
      title={'Agregar socios y/o sus cónyuges inscritos'}
      colors={getColor}
      disable={currentStep?.complete}
      disableButtons={
        !validateFromItems() ||
        formik.values.partnersData.length === 0 ||
        isLoading
      }
      isLoading={isLoading}
      onClick={() => formik.submitForm()}
      onClose={onClose}
      isOpen={isOpen}
      onOpen={onOpen}
      size={'4xl'}
    >
      <SearchBar formik={formik} />
      <Flex direction={'column'} justify={'center'} align={'center'} gap={1}>
        {formik.values?.partnersData?.map((item) => (
          <PartnerItem
            key={item._id}
            partner={item}
            formik={formik}
            type={'secondary'}
          />
        ))}
      </Flex>
    </ModalHistory>
  )
}

export default SearchPartnersAndSpouse
