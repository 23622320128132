import React from 'react'
import { Flex } from '@chakra-ui/react'
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Label,
  Cell,
  Tooltip
} from 'recharts'

const COLORS = {
  'Socio(a)': '#F56767',
  Madre: '#2e7423',
  Padre: '#22B378',
  'Hijo(a)': '#18A0FB',
  'Nieto(a)': '#4A75CB',
  Cónyuge: '#D1AEFF',
  'Hermano(a)': '#8408cc',
  Otro: '#5e5e5c'
}

const TotalsPieChart = ({ data, total }) => {
  const CustomLabel = ({ viewBox }) => {
    const { cx, cy } = viewBox
    return (
      <text
        x={cx}
        y={cy}
        fill="#3d405c"
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
        direction="column"
      >
        <tspan x={cx} dy={-20} fontSize="12">
          TOTAL
        </tspan>
        <tspan x={cx} dy={20} fontSize="26" fontWeight={700}>
          {total}
        </tspan>
        <tspan x={cx} dy={20} fontSize="12">
          Solicitudes
        </tspan>
      </text>
    )
  }

  return (
    <Flex
      direction={'column'}
      justify={'center'}
      align={'center'}
      w={'100%'}
      h={'100%'}
    >
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={data}
            cx={'50%'}
            cy={'50%'}
            innerRadius={60}
            outerRadius={100}
            cornerRadius={18}
            dataKey="value"
          >
            <Label
              width={30}
              position="center"
              content={<CustomLabel />}
            ></Label>
            {data?.map((item, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[item?.name]}
                strokeWidth={10}
              />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </Flex>
  )
}

export default TotalsPieChart
