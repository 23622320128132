import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import {
  Grid,
  GridItem,
  Flex,
  Text,
  useToast,
  Checkbox
} from '@chakra-ui/react'
import { InputComtrol, Button, Select2 } from '../UI'
import { userSchema, userEditSchema } from './Schema'
import { useMutateUser } from '../../hooks/Users'
import { GetFormData, GetNotification, ProgramsList } from '../../Utils'
import Roles from '../../resources/Roles'

const FormCreateUsers = ({ onClose, type, user = null }) => {
  // const [userRole, setUserRole] = useState(null)
  const [permissions, setPermissions] = useState(null)
  const [showPasswordField, setShowPasswordField] = useState(type === 'CREATE')
  const [rolesList, setRolesList] = useState([])
  const { mutate, isLoading, reset } = useMutateUser(type)

  const initialValues = {
    name: '',
    lastName: '',
    email: '',
    password: '',
    role: '',
    phone: ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: type === 'CREATE' ? userSchema : userEditSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      const formatValues = { ...values }

      if (!showPasswordField) {
        Reflect.deleteProperty(formatValues, 'password')
      }

      const formData = GetFormData(formatValues)

      if (type === 'CREATE') {
        mutate(formData, {
          onSuccess: () => {
            onClose()
            reset()
            formik.resetForm()
            GetNotification.basic('Usuario creado con Éxito', 'Aceptar')
          },
          onError: (err) => {
            onClose()
            console.log(err)
            GetNotification.basic(
              'Error',
              'Aceptar',
              'Error al Crear usuario',
              'error'
            )
          }
        })
      }
      if (type === 'UPDATE') {
        const { _id: userId } = user

        mutate(
          { userId, formData },
          {
            onSuccess: () => {
              reset()
              GetNotification.basic('Usuario Actualizado con Éxito', 'Aceptar')
            },
            onError: (err) => {
              console.log(err)
              GetNotification.basic(
                'Error',
                'Aceptar',
                'Error al Actualizar usuario',
                'error'
              )
            }
          }
        )
      }
    }
  })

  const getFormaterPermission = (roleKey) => {
    const currentRole = Roles.find((role) => role.key === roleKey)

    const formater = currentRole.type.map(
      (perm) => ProgramsList.find((item) => item.key === perm)?.name || 'Todos'
    )
    setPermissions(formater?.length > 0 ? formater : null)
  }

  const handleChangeRole = (item) => {
    formik.setFieldValue('role', item)
    getFormaterPermission(item)
  }

  useEffect(() => {
    if (type === 'UPDATE' && user) {
      // setUserRole(user.role)
      formik.setValues({
        name: user.name || '',
        lastName: user.lastName || '',
        email: user.email || '',
        role: user.role || '',
        phone: user.phone || ''
      })
      getFormaterPermission(user.role || '')
    }
  }, [type])

  useEffect(() => {
    const all_roles = [{ key: '', name: '<Seleccione>' }]
    Roles.map((rol) => {
      all_roles.push(rol)
      return rol
    })
    setRolesList(all_roles)
  }, [])

  return (
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem w={'100%'} color={'green.500'}>
        {type === 'UPDATE' && (
          <Text fontWeight={700} fontSize={22} color={'green.500'} mb={4}>
            INFORMACIÓN
          </Text>
        )}
        <Flex mb={3} align={{ lg: 'row', md: 'column', sm: 'column' }} gap={2}>
          <InputComtrol
            id={'name'}
            label={'Nombre'}
            value={formik.values.name}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.name)}
            errorText={formik.errors.name}
          />
          <InputComtrol
            id={'lastName'}
            label={'Apellido'}
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.lastName)}
            errorText={formik.errors.lastName}
          />
        </Flex>
        <Flex mb={3} align={{ lg: 'row', md: 'column', sm: 'column' }} gap={2}>
          <InputComtrol
            id={'email'}
            label={'Correo Electrónico'}
            type={'email'}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.email)}
            errorText={formik.errors.email}
          />
          <InputComtrol
            isRequired={false}
            id={'phone'}
            label={'Telefono'}
            type={'number'}
            value={formik.values.phone}
            addictionType={'phone'}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.phone)}
            errorText={formik.errors.phone}
          />
        </Flex>

        <Flex mb={3} align={{ lg: 'row', md: 'column', sm: 'column' }} gap={2}>
          <InputComtrol id={'role'} label={'Rol'}>
            <Select2
              bg={'white'}
              list={rolesList}
              value={formik.values.role}
              onChange={handleChangeRole}
              // placeHolder={'<Seleccione>'}
            />
          </InputComtrol>
        </Flex>
        {permissions && (
          <Flex
            direction={'column'}
            my={2}
            p={2}
            bg={'yellow.400'}
            borderRadius={5}
          >
            <Text fontWeight={600}>Permisos para el Rol Seleccionado:</Text>
            {permissions.map((perm) => (
              <Text key={perm}>- {perm}</Text>
            ))}
          </Flex>
        )}
        <Checkbox
          css={{
            borderColor: 'green',
            display: type === 'CREATE' ? 'none' : 'block',
            paddingBlock: 4
          }}
          size="md"
          colorScheme="green"
          isChecked={showPasswordField}
          onChange={() => setShowPasswordField(!showPasswordField)}
        >
          ¿Desea cambiar la contraseña?
        </Checkbox>
        {showPasswordField && (
          <Flex
            mb={3}
            align={{ lg: 'row', md: 'column', sm: 'column' }}
            gap={2}
          >
            <InputComtrol
              id={'password'}
              label={'Contraseña'}
              type={'password'}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.password)}
              errorText={formik.errors.password}
            />
          </Flex>
        )}
        <Flex justify={'flex-end'} align={'end'}>
          {type === 'CREATE' && (
            <Button
              text={'Cancelar'}
              bg={'beige.500'}
              bghover={'beige.600'}
              color={'green.500'}
              px={10}
              onClick={onClose}
            ></Button>
          )}
          <Button
            text={type === 'CREATE' ? 'Guardar' : 'Actualizar'}
            px={10}
            onClick={formik?.handleSubmit}
            isLoading={isLoading}
          ></Button>
        </Flex>
      </GridItem>
    </Grid>
  )
}

export default FormCreateUsers
