import React from 'react'
import { Grid, GridItem, Text } from '@chakra-ui/react'
import { useTypeModalView } from '../../../hooks/TypeModal'
import { CardFilePreview } from '../../UI'

const StepItem = ({ step, typeProcess }) => {
  const { title, titleDocument } = useTypeModalView(step?.key)

  const getTitleInvoice = () => {
    if (step?.key === 'INFORM-THE-PAYMENT-MADE-MULTI-SOLIDARITY-HEALTH-FUND') {
      return 'Certificado de pago enviado por Dipreca'
    }
    return 'Factura'
  }

  const getTitleQuotesReceived = () => {
    if (step?.key === 'ADD-DEBT-CERTIFICATE') {
      return 'Acta resolución de comisión'
    }
    if (step?.key === 'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND') {
      return 'Certificado de deuda'
    }
    return 'Cotizaciones recibidas'
  }

  const getTitleBoxPurchase = () => {
    if (step?.key === 'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND') {
      if (
        (typeProcess?.tariffDifference && typeProcess?.service === 'Dipreca') ||
        typeProcess?.others
      ) {
        return 'Cuadro Dipreca'
      }
      return 'Cuadro de compra'
    }
    return 'Cuadro de compra'
  }

  return (
    <>
      {step?.documents?.length > 0 && (
        <>
          <Text fontWeight={700} fontSize={'md'}>
            ◍ Paso Nº
            {step?.stepProgram} {title}
          </Text>
          <Text fontWeight={700} fontSize={'sm'}>
            {titleDocument || 'Documento Adjunto'}
          </Text>
          <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
            {step?.documents?.map((doc) => (
              <GridItem py={3} key={doc?.name}>
                <CardFilePreview name={doc?.name} url={doc.url} />
              </GridItem>
            ))}
          </Grid>
        </>
      )}
      {/* - */}
      {step?.voucher?.length > 0 && (
        <>
          <Text fontWeight={700} fontSize={'md'}>
            ◍ Paso Nº
            {step?.stepProgram} {title}
          </Text>
          <Text fontWeight={700} fontSize={'sm'}>
            Comprobante de depósito (Voucher)
          </Text>
          <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
            {step?.voucher?.map((doc) => (
              <GridItem py={3} key={doc?.name}>
                <CardFilePreview name={doc?.name} url={doc.url} />
              </GridItem>
            ))}
          </Grid>
        </>
      )}
      {/* - */}
      {step?.instructive?.length > 0 && (
        <>
          <Text fontWeight={700} fontSize={'md'}>
            ◍ Paso Nº
            {step?.stepProgram} {title}
          </Text>
          <Text fontWeight={700} fontSize={'sm'}>
            Instructivo de rendición de cuentas
          </Text>
          <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
            {step?.instructive?.map((doc) => (
              <GridItem py={3} key={doc?.name}>
                <CardFilePreview name={doc?.name} url={doc.url} />
              </GridItem>
            ))}
          </Grid>
        </>
      )}
      {/* - */}
      {step?.amountCancel?.length > 0 && (
        <>
          <Text fontWeight={700} fontSize={'md'}>
            ◍ Paso Nº
            {step?.stepProgram} {title}
          </Text>
          <Text fontWeight={700} fontSize={'sm'}>
            Documento con el monto a cancelar
          </Text>
          <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
            {step?.amountCancel?.map((doc) => (
              <GridItem py={3} key={doc?.name}>
                <CardFilePreview name={doc?.name} url={doc.url} />
              </GridItem>
            ))}
          </Grid>
        </>
      )}
      {/* - */}
      {step?.purchaseDetail?.length > 0 && (
        <>
          <Text fontWeight={700} fontSize={'md'}>
            ◍ Paso Nº
            {step?.stepProgram} {title}
          </Text>
          <Text fontWeight={700} fontSize={'sm'}>
            Detalle de compra
          </Text>
          <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
            {step?.purchaseDetail?.map((doc) => (
              <GridItem py={3} key={doc?.name}>
                <CardFilePreview name={doc?.name} url={doc.url} />
              </GridItem>
            ))}
          </Grid>
        </>
      )}
      {/* - */}
      {step?.backupFeeAmount?.length > 0 && (
        <>
          <Text fontWeight={700} fontSize={'md'}>
            ◍ Paso Nº
            {step?.stepProgram} {title}
          </Text>
          <Text fontWeight={700} fontSize={'sm'}>
            Monto de arancel de respaldo
          </Text>
          <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
            {step?.backupFeeAmount?.map((doc) => (
              <GridItem py={3} key={doc?.name}>
                <CardFilePreview name={doc?.name} url={doc.url} />
              </GridItem>
            ))}
          </Grid>
        </>
      )}
      {/* - */}
      {step?.scholarshipCommission?.length > 0 && (
        <>
          <Text fontWeight={700} fontSize={'md'}>
            ◍ Paso Nº
            {step?.stepProgram} {title}
          </Text>
          <Text fontWeight={700} fontSize={'sm'}>
            Acta de comisión de becas
          </Text>
          <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
            {step?.scholarshipCommission?.map((doc) => (
              <GridItem py={3} key={doc?.name}>
                <CardFilePreview name={doc?.name} url={doc.url} />
              </GridItem>
            ))}
          </Grid>
        </>
      )}
      {/* - */}
      {step?.bankData?.length > 0 && (
        <>
          <Text fontWeight={700} fontSize={'md'}>
            ◍ Paso Nº
            {step?.stepProgram} {title}
          </Text>
          <Text fontWeight={700} fontSize={'sm'}>
            Datos bancarios
          </Text>
          <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
            {step?.bankData?.map((doc) => (
              <GridItem py={3} key={doc?.name}>
                <CardFilePreview name={doc?.name} url={doc.url} />
              </GridItem>
            ))}
          </Grid>
        </>
      )}
      {/* - */}
      {step?.office?.length > 0 && (
        <>
          <Text fontWeight={700} fontSize={'md'}>
            ◍ Paso Nº
            {step?.stepProgram} {title}
          </Text>
          <Text fontWeight={700} fontSize={'sm'}>
            Oficio
          </Text>
          <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
            {step?.office?.map((doc) => (
              <GridItem py={3} key={doc?.name}>
                <CardFilePreview name={doc?.name} url={doc.url} />
              </GridItem>
            ))}
          </Grid>
        </>
      )}
      {/* - */}
      {step?.invoice?.length > 0 && (
        <>
          <Text fontWeight={700} fontSize={'md'}>
            ◍ Paso Nº
            {step?.stepProgram} {title}
          </Text>
          <Text fontWeight={700} fontSize={'sm'}>
            {getTitleInvoice()}
          </Text>
          <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
            {step?.invoice?.map((doc) => (
              <GridItem py={3} key={doc?.name}>
                <CardFilePreview name={doc?.name} url={doc.url} />
              </GridItem>
            ))}
          </Grid>
        </>
      )}
      {/* - */}
      {step?.classSchedule?.length > 0 && (
        <>
          <Text fontWeight={700} fontSize={'md'}>
            ◍ Paso Nº
            {step?.stepProgram} {title}
          </Text>
          <Text fontWeight={700} fontSize={'sm'}>
            Horario de clase
          </Text>
          <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
            {step?.classSchedule?.map((doc) => (
              <GridItem py={3} key={doc?.name}>
                <CardFilePreview name={doc?.name} url={doc.url} />
              </GridItem>
            ))}
          </Grid>
        </>
      )}
      {/* - */}
      {step?.feeBill?.length > 0 && (
        <>
          <Text fontWeight={700} fontSize={'md'}>
            ◍ Paso Nº
            {step?.stepProgram} {title}
          </Text>
          <Text fontWeight={700} fontSize={'sm'}>
            Factura de arancel
          </Text>
          <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
            {step?.feeBill?.map((doc) => (
              <GridItem py={3} key={doc?.name}>
                <CardFilePreview name={doc?.name} url={doc.url} />
              </GridItem>
            ))}
          </Grid>
        </>
      )}
      {/* - */}
      {step?.attendanceSheet?.length > 0 && (
        <>
          <Text fontWeight={700} fontSize={'md'}>
            ◍ Paso Nº
            {step?.stepProgram} {title}
          </Text>
          <Text fontWeight={700} fontSize={'sm'}>
            Hoja de asistencia
          </Text>
          <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
            {step?.attendanceSheet?.map((doc) => (
              <GridItem py={3} key={doc?.name}>
                <CardFilePreview name={doc?.name} url={doc.url} />
              </GridItem>
            ))}
          </Grid>
        </>
      )}
      {/* - */}
      {step?.acta?.length > 0 && (
        <>
          <Text fontWeight={700} fontSize={'md'}>
            ◍ Paso Nº
            {step?.stepProgram} {title}
          </Text>
          <Text fontWeight={700} fontSize={'sm'}>
            Acta de comisión
          </Text>
          <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
            {step?.acta?.map((doc) => (
              <GridItem py={3} key={doc?.name}>
                <CardFilePreview name={doc?.name} url={doc.url} />
              </GridItem>
            ))}
          </Grid>
        </>
      )}
      {step?.reportedEmail?.length > 0 && (
        <>
          <Text fontWeight={700} fontSize={'md'}>
            ◍ Paso Nº
            {step?.stepProgram} {title}
          </Text>
          <Text fontWeight={700} fontSize={'sm'}>
            Correo electrónico respaldados
          </Text>
          <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
            {step?.reportedEmail?.map((doc) => (
              <GridItem py={3} key={doc?.name}>
                <CardFilePreview name={doc?.name} url={doc.url} />
              </GridItem>
            ))}
          </Grid>
        </>
      )}
      {step?.boxPurchase?.length > 0 && (
        <>
          <Text fontWeight={700} fontSize={'md'}>
            ◍ Paso Nº
            {step?.stepProgram} {title}
          </Text>
          <Text fontWeight={700} fontSize={'sm'}>
            {getTitleBoxPurchase()}
          </Text>
          <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
            {step?.boxPurchase?.map((doc) => (
              <GridItem py={3} key={doc?.name}>
                <CardFilePreview name={doc?.name} url={doc.url} />
              </GridItem>
            ))}
          </Grid>
        </>
      )}
      {step?.boxPurchaseTwo?.length > 0 && (
        <>
          <Text fontWeight={700} fontSize={'md'}>
            ◍ Paso Nº
            {step?.stepProgram} {title}
          </Text>
          <Text fontWeight={700} fontSize={'sm'}>
            Cuadros de compra
          </Text>
          <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
            {step?.boxPurchaseTwo?.map((doc) => (
              <GridItem py={3} key={doc?.name}>
                <CardFilePreview name={doc?.name} url={doc.url} />
              </GridItem>
            ))}
          </Grid>
        </>
      )}
      {step?.quotesReceived?.length > 0 && (
        <>
          <Text fontWeight={700} fontSize={'md'}>
            ◍ Paso Nº
            {step?.stepProgram} {title}
          </Text>
          <Text fontWeight={700} fontSize={'sm'}>
            Cotizaciones recibidas
          </Text>
          <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
            {step?.quotesReceived?.map((doc) => (
              <GridItem py={3} key={doc?.name}>
                <CardFilePreview name={doc?.name} url={doc.url} />
              </GridItem>
            ))}
          </Grid>
        </>
      )}
      {step?.purchaseRequestLetter?.length > 0 && (
        <>
          <Text fontWeight={700} fontSize={'md'}>
            ◍ Paso Nº
            {step?.stepProgram} {title}
          </Text>
          <Text fontWeight={700} fontSize={'sm'}>
            Oficio de solicitud de orden de compra
          </Text>
          <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
            {step?.purchaseRequestLetter?.map((doc) => (
              <GridItem py={3} key={doc?.name}>
                <CardFilePreview name={doc?.name} url={doc.url} />
              </GridItem>
            ))}
          </Grid>
        </>
      )}
      {step?.quotesReceived?.length > 0 && (
        <>
          <Text fontWeight={700} fontSize={'md'}>
            ◍ Paso Nº
            {step?.stepProgram} {title}
          </Text>
          <Text fontWeight={700} fontSize={'sm'}>
            {getTitleQuotesReceived()}
          </Text>
          <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
            {step?.quotesReceived?.map((doc) => (
              <GridItem py={3} key={doc?.name}>
                <CardFilePreview name={doc?.name} url={doc.url} />
              </GridItem>
            ))}
          </Grid>
        </>
      )}
      {step?.purchaseRequestLetterSigned?.length > 0 && (
        <>
          <Text fontWeight={700} fontSize={'md'}>
            ◍ Paso Nº
            {step?.stepProgram} {title}
          </Text>
          <Text fontWeight={700} fontSize={'sm'}>
            Oficio de solicitud de orden de compra firmada
          </Text>
          <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
            {step?.purchaseRequestLetterSigned?.map((doc) => (
              <GridItem py={3} key={doc?.name}>
                <CardFilePreview name={doc?.name} url={doc.url} />
              </GridItem>
            ))}
          </Grid>
        </>
      )}
      {step?.documentsSentMedicalInstitution?.length > 0 && (
        <>
          <Text fontWeight={700} fontSize={'md'}>
            ◍ Paso Nº
            {step?.stepProgram} {title}
          </Text>
          <Text fontWeight={700} fontSize={'sm'}>
            Certificados de deuda enviado
          </Text>
          <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
            {step?.documentsSentMedicalInstitution?.map((doc) => (
              <GridItem py={3} key={doc?.name}>
                <CardFilePreview name={doc?.name} url={doc.url} />
              </GridItem>
            ))}
          </Grid>
        </>
      )}
      {step?.tariffDifferenceDocuments?.length > 0 && (
        <>
          <Text fontWeight={700} fontSize={'md'}>
            ◍ Paso Nº
            {step?.stepProgram} {title}
          </Text>
          <Text fontWeight={700} fontSize={'sm'}>
            Certificado de pago enviado por Dipreca
          </Text>
          <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
            {step?.tariffDifferenceDocuments?.map((doc) => (
              <GridItem py={3} key={doc?.name}>
                <CardFilePreview name={doc?.name} url={doc.url} />
              </GridItem>
            ))}
          </Grid>
        </>
      )}
    </>
  )
}

export default StepItem
