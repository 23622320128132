import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import { Grid, GridItem } from '@chakra-ui/react'
import ResetPasswordForm from '../../components/ResetPasswordForm'

const ResetPassword = () => {
  const { resetToken } = useParams()

  const validateToken = () => {
    try {
      const decoded = jwt_decode(resetToken)
      return decoded
    } catch (err) {
      return false
    }
  }

  useEffect(() => {
    document.title = 'Restaurar contraseña 🔑'
  }, [])

  return (
    <Grid>
      <GridItem>
        <>{validateToken() ? <ResetPasswordForm /> : 'Token is invalid'}</>
      </GridItem>
    </Grid>
  )
}

export default ResetPassword
