import { createSlice } from '@reduxjs/toolkit'
import Roles from '../../../resources/Roles'

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoggedIn: false,
    user: null
  },
  reducers: {
    login_success: (state, action) => {
      const { user } = action.payload

      state.isLoggedIn = true
      state.user = user
    },
    logout: (state) => {
      state.isLoggedIn = false
      state.user = null
    },
    get_authentication: (state, action) => {
      const { user } = action.payload

      state.isLoggedIn = true
      state.user = user
    }
  }
})
export const { login_success, logout, get_authentication } = authSlice.actions
/* --- */
export default authSlice.reducer
/* --- */
export const loginUser = (loginData) => (dispatch) => {
  window.localStorage.setItem('token', loginData.accessToken)
  window.localStorage.setItem('refreshToken', loginData.refreshToken)

  const userType = Roles.find(
    (role) => role?.key === loginData?.user?.role
  )?.type
  console.log('userType', userType)
  dispatch(
    login_success({
      ...loginData,
      user: {
        ...loginData.user,
        userType
      }
    })
  )
}
export const logoutUser = () => (dispatch) => {
  localStorage.removeItem('token')
  localStorage.removeItem('refreshToken')
  localStorage.clear()
  /* window.location.reload() */
  dispatch(logout())
}
export const me = (userData) => (dispatch) => {
  const userType = Roles.find(
    (role) => role?.key === userData?.user?.role
  )?.type

  const result = {
    user: {
      ...userData.user,
      userType
    }
  }

  dispatch(get_authentication(result))
}
